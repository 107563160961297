import React from 'react';
import PropTypes from 'prop-types';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import styles from './toggleSwitchButton.module.scss';

const DailyHourlySwitchButton = ({ isDailyMode, setIsDailyMode, automationid}) => (
    <div className={styles.switchButtonWrapper}>
        <div
            className={`${styles.switchButton} ${isDailyMode && styles.selectedOption}`}
            onClick={() => setIsDailyMode(!isDailyMode)}
            automation-id={`${automationid}Daily`}
        >
            <GenerateIcon iconName={ICONS.calendarDay.name}/>
            <span>Daily</span>
        </div>
        <div
            className={`${styles.switchButton} ${!isDailyMode && styles.selectedOption}`}
            onClick={() => setIsDailyMode(!isDailyMode)}
            automation-id={`${automationid}Hourly`}
        >
            <GenerateIcon iconName={ICONS.clock.name}/>
            <span>Hourly</span>
        </div>
    </div>
);

DailyHourlySwitchButton.propTypes = {
    isDailyMode: PropTypes.bool.isRequired,
    setIsDailyMode: PropTypes.func.isRequired,
    automationid: PropTypes.string.isRequired,
};
export default DailyHourlySwitchButton;
