import React from 'react';
import SaveAsDashboardPanel from './saveAsDashboardPanel';
import ViewsPanel from './views/viewsPanel.jsx';
import SavingsTracker from './savingsTracker';
import CostModeToggle from 'recommendationsNew/components/heatMap/heatMapActions/costModeToggle.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import checkFeatureFlag from 'shared/utils/featureFlagUtil.js';

import classes from './heatMapActions.module.scss';

const HeatMapActions = () => {
  const { usersStore } = useRootStore();
  const isNetAmortizedSupported = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.SUPPORT_NET_AMORTIZE);
  return (
    <div className={classes.actions}>
      {(isNetAmortizedSupported && !usersStore.isCurrentUserReCustomer
        && usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) && <CostModeToggle />}
      <SavingsTracker />
      <ViewsPanel />
      <SaveAsDashboardPanel />
    </div>
  );
};
export default HeatMapActions;
