import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import toast from 'shared/components/andtComponents/Toast';
import { COST_CENTER_ACTIONS } from '../../../consts';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';

import styles from './DeleteCostCenterModal.module.scss';

const DeleteCostCenterModal = ({ isOpen, setIsOpen, costCenter, hasChildren }) => {
  const [saveClicked] = useState(false);

  const { deleteDataAccessCostCenter } = useDataAccessAccounts();

  const onDelete = async () => {
    try {
      await deleteDataAccessCostCenter.mutateAsync(costCenter?.id);
      setIsOpen(false);
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while deleting cost center');
      }
    }
  };

  return (
    <CustomModal
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.SIMPLE}
      footerDisabled={saveClicked}
      onSave={onDelete}
      open={isOpen}
      overrideStyles={hasChildren ? { width: 630 } : {}}
      automationid="delete-cost-center-modal"
      saveTitle="Delete"
      saveWarning
      saveDisabled={false}
      title={`${COST_CENTER_ACTIONS.DELETE.label} Cost Center`}
    >
      <div className={styles.deleteContainer}>
        <div className={styles.mainText}>
          <div>You are about to delete {costCenter.name}.</div>
          <div>The accounts within the cost center will be unassigned.</div>
          {hasChildren && (
            <div>The Cost Centers that are children of this Cost Center will be moved to its parent.</div>
          )}
        </div>
        <div className={styles.subText}>
          <div>This action cannot be undone.</div>
          <div>Are you sure?</div>
        </div>
      </div>
    </CustomModal>
  );
};

DeleteCostCenterModal.propTypes = {
  costCenter: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  hasChildren: PropTypes.bool,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default DeleteCostCenterModal;
