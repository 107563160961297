import React, { useEffect, useState } from 'react';
import {
  ICONS,
  GenerateIcon,
  SelectMultiItem,
  customStyles,
  EmptyComponent,
  MenuList,
} from '@pileus-cloud/anodot-frontend-common';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import Select, { components } from 'react-select';
import Pagination from 'shared/components/andtComponents/Pagination';
import classes from './recommendationsTableHeader.module.scss';
import { MAX_EXPORTED_ROWS } from 'shared/constants/appConstants';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import styles from '~/app/containers/MainDashboard/components/filterField.module.scss';

const ZERO_SPACE_BETWEEN_SEARCH_AND_INPUT = 0;

const filterSelectStyles = (menuWidth, menuHeight, menuMinWidth) => {
  const styles = customStyles(menuWidth, menuHeight, menuMinWidth);
  return {
    ...styles,
    control: (base) => ({
      ...base,
      ...styles.control(base),
      zIndex: 1,
      background: '#ebf1ff',
    }),
    menu: (base) => ({
      ...base,
      marginTop: ZERO_SPACE_BETWEEN_SEARCH_AND_INPUT,
      maxHeight: menuHeight,
      overflowY: 'auto',
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: menuHeight,
      padding: 0,
    }),
  };
};
const optionComponent = ({ isSelected = false, label, isFocused = false, data, ...props }) => (
  <components.Option {...props}>
    <div className={`${styles.option} ${isFocused || isSelected ? styles.focused : null}`}>
      <label
        className={`${isSelected ? styles.activeOption : ''}`}
        style={{
          width: `${label.length * 8}px`,
          fontWeight: data?.bold ? 'bold' : 'normal',
        }}
        htmlFor="cb-item"
      >
        {label}
      </label>
    </div>
  </components.Option>
);

const InputComponent = (props) => (
  <div className={styles.inputWrapper}>
    <GenerateIcon iconName={ICONS.search.name} className={styles.searchIcon} />
    <span className={styles.placeholder}>{!props.selectProps.inputValue ? 'Search' : null}</span>
    <components.Input {...props} className={styles.searchInput} />
  </div>
);

InputComponent.propTypes = {
  selectProps: PropTypes.object.isRequired,
};
const customComponents = () => ({
  Input: InputComponent,
  DropdownIndicator: EmptyComponent,
  MultiValue: EmptyComponent,
  SingleValue: EmptyComponent,
  MultiValueRemove: EmptyComponent,
  Placeholder: EmptyComponent,
  ClearIndicator: EmptyComponent,
  Option: optionComponent,
  MenuListFooter: MenuList,
});

function OptionMulti(props) {
  const { isSelected, label, isFocused, data } = props;
  const { recTypes } = data;
  return (
    <components.Option {...props}>
      <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
        <input className={classes.checkbox} type="checkbox" checked={isSelected} onChange={() => null} />{' '}
        <Tooltip title={label}>
          <span className={`${classes.label} ${isSelected ? classes.activeOption : ''}`}>{label}</span>
        </Tooltip>
        {recTypes?.length > 0 && (
          <Tooltip
            placement="bottom"
            title={
              <div className={classes.tagTooltipWrap}>
                {recTypes.map((tag) => (
                  <div key={`tagTP-${tag}`}>
                    <span className={classes.tagName}>{tag}</span>
                    <br />
                  </div>
                ))}
              </div>
            }
            classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}
          >
            <div className={classes.chipWrapper}>
              <span className={classes.chip}>{`${recTypes.length} Types`}</span>
            </div>
          </Tooltip>
        )}
      </div>
    </components.Option>
  );
}
OptionMulti.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    recTypes: PropTypes.arrayOf(PropTypes.string), // Added propTypes validation for recTypes
  }).isRequired,
};

const penIcon = () => <GenerateIcon iconName={ICONS.penToSquare.name} />;

const rowsOptions = [
  { label: '10 rows (Default)', value: 10 },
  { label: '20 rows', value: 20 },
  { label: '50 rows', value: 50 },
  { label: '100 rows', value: 100 },
];
const RecommendationsTableHeader = React.memo(
  ({
    rowsCount = '',
    displayCount,
    searchText = null,
    setSearchText,
    showFilters = false,
    setShowFilters,
    isExpanded = false,
    setIsExpanded,
    fetchExportData,
    options,
    setHiddenColumnNames,
    pageChange,
    currentPage,
    totalPages,
    isDataExporting,
    selectedItems,
    rowsInPage,
    setRowsInPage,
    isPageLoading,
  }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [openRows, setOpenRows] = useState(false);
    const { setShowCustomColumnsNames } = useRecommendationsNewContext();
    const filterIcon = () => <GenerateIcon iconName={showFilters ? ICONS.xMark.name : ICONS.filterList.name} />;
    const expandIcon = () => <GenerateIcon iconName={isExpanded ? ICONS.collapse.name : ICONS.expand.name} />;
    useEffect(() => {
      const handleClickOutside = (event) => {
        const selectMultiItem = document.querySelector('#select-multi-item');
        if (selectMultiItem && !selectMultiItem.contains(event.target)) {
          setAnchorEl(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    return (
      <div className={classes.header}>
        <div>
          <Button
            automationid="expand-rows"
            text=""
            isSecondary
            icon={expandIcon}
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
          <span className={classes.headerText}>Recommendations</span>
          <span className={classes.count}>
            {`(${displayCount && displayCount !== rowsCount ? `${displayCount}/${rowsCount}` : rowsCount})`}
          </span>
        </div>

        <div className={classes.rightMenu}>
          <div className="position-relative d-flex align-items-center">
            <Input
              className={classes.input}
              value={searchText ?? ''}
              onChange={(e) => setSearchText(e.target.value)}
              placeholder="Search"
              name="search-recommendations"
              isSearch
              searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
              type="text"
            />
          </div>
          <div>
            <Tooltip title="Show Columns Chooser" arrow>
              <Button
                text="Columns"
                automationid="chooser-columns"
                onClick={() => setAnchorEl(!anchorEl)}
                isSecondary
                icon={penIcon}
              />
              <span />
            </Tooltip>
            <div style={{ zIndex: 10, position: 'absolute' }} id="select-multi-item">
              <SelectMultiItem
                isOpen={anchorEl}
                options={options}
                onChange={(values) => {
                  const newHidden = options.filter((column) => !values.find((v) => v.value === column.value));
                  setHiddenColumnNames(newHidden.map((column) => column.value));
                  const showCustomColumns = options.filter((column) =>
                    values.find((v) => v.value === column.value && v.recTypes?.length > 0),
                  );
                  setShowCustomColumnsNames(showCustomColumns.map((column) => column.value));
                }}
                selected={selectedItems}
                onMenuBlur={(e) => {
                  const columnsButton = document.querySelector('[automation-id="chooser-columns"]');
                  if (e.relatedTarget !== columnsButton) {
                    setAnchorEl(false);
                  }
                }}
                customOptionMultiComponent={OptionMulti}
                hideFooter
                width={'390px'}
              />
            </div>
          </div>
          <Tooltip title="Filter columns value" arrow>
            <Button
              text="Filter Results"
              automationid="filter-results"
              onClick={() => {
                setShowFilters(!showFilters);
              }}
              overrideStyles={showFilters ? { background: '#DFE9FF' } : {}}
              isSecondary
              icon={filterIcon}
            />
            <span />
          </Tooltip>
          <Tooltip
            title={
              rowsCount > MAX_EXPORTED_ROWS
                ? 'You can export up to 100,000 recommendation records, please refine your filters to enable export'
                : 'Export'
            }
            arrow
          >
            <CustomCSVDownload
              fetchData={fetchExportData}
              automationid="export-recommendations"
              showDownloadIcon
              isLoading={isDataExporting}
              filesNumber={1}
              disabled={rowsCount > MAX_EXPORTED_ROWS}
              isSecondary
              isLightButton
              hideText
              style={{
                height: 36,
              }}
            />
            <span />
          </Tooltip>
          <div>
            <Tooltip title="Rows per page" arrow>
              <Button
                text={`Rows (${(rowsInPage || rowsOptions[0]).value})`}
                automationid="chooser-rows"
                onClick={() => setOpenRows(!openRows)}
                isSecondary
              />
              <span />
            </Tooltip>
            <div style={{ zIndex: 10, position: 'absolute' }} id="select-rows-in-page">
              {openRows && (
                <Select
                  menuIsOpen={openRows}
                  onChange={(selected) => {
                    setRowsInPage(selected);
                    setOpenRows(false);
                  }}
                  options={rowsOptions}
                  components={customComponents()}
                  value={rowsInPage || rowsOptions[0]}
                  styles={filterSelectStyles(190, 200, 190)}
                  isMulti={false}
                  autoFocus
                  closeMenuOnSelect={true}
                  escapeClearsValue
                  onBlur={() => {}}
                  hideSelectedOptions={false}
                  defaultMenuIsOpen
                  placeholder="Search"
                />
              )}
            </div>
          </div>
          <Pagination
            currPageIdx={currentPage}
            onPageChange={pageChange}
            totalPages={totalPages}
            isPageLoading={isPageLoading}
          />
        </div>
      </div>
    );
  },
);

RecommendationsTableHeader.displayName = 'RecommendationsTableHeader';

RecommendationsTableHeader.propTypes = {
  rowsCount: PropTypes.number.isRequired,
  displayCount: PropTypes.number,
  searchText: PropTypes.string,
  setSearchText: PropTypes.func.isRequired,
  showFilters: PropTypes.bool,
  setShowFilters: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func.isRequired,
  fetchExportData: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setHiddenColumnNames: PropTypes.func.isRequired,
  pageChange: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  isDataExporting: PropTypes.bool.isRequired,
  selectedItems: PropTypes.array.isRequired,
  rowsInPage: PropTypes.array,
  setRowsInPage: PropTypes.func.isRequired,
};

export default RecommendationsTableHeader;
