import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import { DATA_ACCESS_TABS } from '../../../consts';
import {
  getInitialAccountsDataWithCounters,
  provideAccountsData,
} from '../../../components/LinkedAccounts/dataAccessHelperFunctions';
import {
  getCostCentersDataAccessCombinedData,
  mergeCostCentersDataWithDataAccess,
} from 'users/containers/Organization/CostCenters/costCentersHelperFunctions';
import DataAccessContent from 'users/containers/Organization/Roles/rolesExpandedRowComponents/DataAccess/DataAccessContent';
import Spinner from 'shared/components/andtComponents/Spinner';

const DataAccessContentWrapperForDuplicate = ({
  isCreateEditMode,
  isInProgress,
  isViewOnly,
  modifiedSelectionById,
  onRemoveLinkedAccounts,
  onRemoveCostCenters,
  onAccountsSelectionChange,
  onAccountsToDuplicateSet,
  onCostCentersToDuplicateSet,
  onCostCentersSelectionChange,
  role,
  roleIdForDuplicate,
  setInitialCostCentersDataAccessData,
}) => {
  const [selectedTab, setSelectedTab] = useState(DATA_ACCESS_TABS.ACCOUNTS);

  // const { usersStore } = useRootStore();
  // const organizationId = usersStore.getCurrentUser?.organizationId;

  // TODO: In case the Role data access is derived from more than one Organization, need to call the API for each?
  // TODO: Or should I only display the data for the User organization?
  // TODO: If the user is a reseller, then the data access is derived from all organizations? And then call for each?
  const { fetchDataAccessPayerAccounts, fetchDataAccessCostCenters } = useDataAccessAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();
  const { data: costCentersData, isLoading: isCostCentersDataAccessLoading } = fetchDataAccessCostCenters();

  const { fetchRoleDataAccess } = useRoles();
  const { data: roleDataAccess, isFetching: isRoleDataAccessLoading } = fetchRoleDataAccess(role?.id);
  const { data: roleDataAccessDuplicate, isFetching: isRoleDataAccessDuplicateLoading } =
    fetchRoleDataAccess(roleIdForDuplicate);

  function calculateDataAccessForDuplicatedRole(dataAccessSelection) {
    const payload = {
      allLinkedAccountsOfAllPayerAccounts: false,
      accountsToAdd: {
        fullyAssignedPayerAccounts: [],
        payerWithLinkedAccounts: {},
      },
    };
    const assignedPayerAccounts = dataAccessSelection.payerAccounts;
    if (assignedPayerAccounts?.allLinkedAccountsOfAllPayerAccounts === true) {
      payload.allLinkedAccountsOfAllPayerAccounts = true;
    } else {
      Object.entries(assignedPayerAccounts?.payerToLinkedAccounts)?.forEach(([key, value]) => {
        if (value.all === true) {
          payload.accountsToAdd.fullyAssignedPayerAccounts.push(key);
        } else {
          payload.accountsToAdd.payerWithLinkedAccounts[key] = value.items?.map((laId) => laId) || [];
        }
      });
    }
    onAccountsToDuplicateSet(payload);
  }

  function calculateCostCentersForDuplicatedRole(costCentersSelection) {
    const payload = {
      itemsToAdd: [],
    };
    const assignedCostCenters = costCentersSelection.costCenters;
    if (assignedCostCenters?.all === true) {
      payload.all = true;
    } else {
      payload.itemsToAdd = assignedCostCenters?.items?.map((laId) => laId) || [];
    }
    onCostCentersToDuplicateSet(payload);
  }

  const combinedRoleDataAccessForDuplicate = useMemo(() => {
    if (roleDataAccess && roleDataAccessDuplicate) {
      const tmpRoleDataAccess = [...(roleDataAccess || [])];

      const dataAccessFromDuplicateRole = roleDataAccessDuplicate.find(
        (dataAccess) => dataAccess.derivedFromRole.id === roleIdForDuplicate,
      );
      if (dataAccessFromDuplicateRole) {
        calculateDataAccessForDuplicatedRole(dataAccessFromDuplicateRole);
        calculateCostCentersForDuplicatedRole(dataAccessFromDuplicateRole);

        const tmpDataAccessFromDuplicateRole = {
          ...dataAccessFromDuplicateRole,
          derivedFromRole: { displayName: role?.displayName, id: role?.id },
        };
        tmpRoleDataAccess.push(tmpDataAccessFromDuplicateRole);
      }
      return tmpRoleDataAccess;
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role?.displayName, role?.id, roleDataAccess, roleDataAccessDuplicate, roleIdForDuplicate]);

  const dataToDisplay = useMemo(() => {
    if (!combinedRoleDataAccessForDuplicate) {
      return null;
    }
    if (selectedTab.id === DATA_ACCESS_TABS.ACCOUNTS.id) {
      if (!accountsData?.length || !combinedRoleDataAccessForDuplicate) {
        return null;
      }
      const combinedData = getInitialAccountsDataWithCounters(accountsData);
      const combinedPayerAccountsData = provideAccountsData({
        accountsData,
        roleDataAccess: combinedRoleDataAccessForDuplicate,
        currentRoleId: role?.id,
        isCreateEditMode,
      });

      const dataAccessWithFullAccess = roleDataAccess.find(
        (roleAccess) => roleAccess.payerAccounts?.allLinkedAccountsOfAllPayerAccounts,
      );
      return { ...combinedData, accountsData: combinedPayerAccountsData, dataAccessWithFullAccess };
    }

    if (selectedTab.id === DATA_ACCESS_TABS.COST_CENTERS.id) {
      if (!costCentersData?.length || !combinedRoleDataAccessForDuplicate) {
        return null;
      }
      const data = getCostCentersDataAccessCombinedData(
        costCentersData,
        combinedRoleDataAccessForDuplicate,
        role?.id,
        modifiedSelectionById,
        !isCreateEditMode,
      );
      if (isCreateEditMode) {
        setInitialCostCentersDataAccessData(data);
        // If createEdit mode - get the assigned together with the unassigned cost centers
        return mergeCostCentersDataWithDataAccess(costCentersData, data);
      } else {
        return data.filter((cc) => cc.costCenter.isAllowed || cc.costCenter.effectiveIsAllowed);
      }
    }
    return null;
  }, [
    combinedRoleDataAccessForDuplicate,
    selectedTab.id,
    accountsData,
    role?.id,
    isCreateEditMode,
    roleDataAccess,
    costCentersData,
    modifiedSelectionById,
    setInitialCostCentersDataAccessData,
  ]);

  return isRoleDataAccessDuplicateLoading || isRoleDataAccessLoading ? (
    <Spinner />
  ) : (
    dataToDisplay && (
      <DataAccessContent
        dataToDisplay={dataToDisplay}
        isCreateEditMode={isCreateEditMode}
        isInProgress={isInProgress}
        isViewOnly={isViewOnly}
        isLoading={
          isAccountsDataLoading ||
          (selectedTab.id === DATA_ACCESS_TABS.ACCOUNTS.id
            ? isRoleDataAccessLoading || isRoleDataAccessDuplicateLoading
            : isCostCentersDataAccessLoading)
        }
        modifiedSelectionById={modifiedSelectionById}
        onAccountsSelectionChange={onAccountsSelectionChange}
        onCostCentersSelectionChange={onCostCentersSelectionChange}
        onRemoveCostCenters={onRemoveCostCenters}
        onRemoveLinkedAccounts={onRemoveLinkedAccounts}
        roleId={role?.id}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
      />
    )
  );
};

DataAccessContentWrapperForDuplicate.propTypes = {
  isCreateEditMode: PropTypes.bool,
  isInProgress: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  modifiedSelectionById: PropTypes.object,
  onAccountsSelectionChange: PropTypes.func,
  onAccountsToDuplicateSet: PropTypes.func,
  onCostCentersSelectionChange: PropTypes.func,
  onCostCentersToDuplicateSet: PropTypes.func,
  onRemoveCostCenters: PropTypes.func,
  onRemoveLinkedAccounts: PropTypes.func,
  role: PropTypes.object,
  roleIdForDuplicate: PropTypes.string,
  setInitialCostCentersDataAccessData: PropTypes.func,
};

export default DataAccessContentWrapperForDuplicate;
