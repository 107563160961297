import React, { useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Popover, PopoverContent, PopoverTrigger } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from '@mui/material/Tooltip';
import Spinner, { SPINNER_SIZES } from '~/shared/components/andtComponents/Spinner.jsx';
import { Search } from 'users/containers/Organization/components/Search';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';

import styles from './CustomerSelection.module.scss';

const CustomerSelection = ({ onCustomerSelected, isDisabled, selectedCustomer }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const { fetchDataAccessResellerCustomers } = useDataAccessAccounts(!isOpen);
  const { data, isLoading } = fetchDataAccessResellerCustomers();

  const toggle = () => {
    if (!isDisabled) {
      setIsOpen((prev) => !prev);
    }
  };

  const customerClickedHandler = async (customer) => {
    toggle();
    onCustomerSelected({ id: customer.value, name: customer.label });
  };

  const resellerCustomers = useMemo(() => {
    if (data?.length) {
      return data.map((customer) => ({
        label: customer.resellerCustomer?.name,
        value: customer.resellerCustomer?.id,
      }));
    }
  }, [data]);

  const filteredResellerCustomers = useMemo(() => {
    if (resellerCustomers?.length) {
      let tmpCustomers = [...resellerCustomers];
      if (searchInput) {
        tmpCustomers = tmpCustomers.filter((customer) =>
          customer.label?.toLowerCase().includes(searchInput.toLowerCase()),
        );
      }
      return tmpCustomers;
    }
  }, [resellerCustomers, searchInput]);

  return (
    <Popover open={isOpen} onOpenChange={toggle}>
      <PopoverTrigger>
        <Tooltip title="Select Customer" placement="top" arrow>
          <div className={classnames(styles.topPickerEnv, { [styles.disabled]: isDisabled })}>
            <span>{selectedCustomer ? selectedCustomer?.name : 'Customer'}</span>
            <ChevronDown size={16} />
          </div>
        </Tooltip>
      </PopoverTrigger>
      <div className={styles.popoverWrapper}>
        <PopoverContent side="bottom" sideOffset={5} align="start" className={styles.popoverContent}>
          {isLoading ? (
            <div>
              <Spinner size={SPINNER_SIZES.SMALL} />
            </div>
          ) : (
            <>
              <Search search={searchInput} setSearch={setSearchInput} overrideStyle={styles.searchInput} />
              <div className={styles.menuContainer}>
                {!filteredResellerCustomers?.length ? (
                  <div className={styles.emptyState}>No results found</div>
                ) : (
                  filteredResellerCustomers?.map((option) => (
                    <div
                      key={option.value}
                      onClick={() => customerClickedHandler(option)}
                      className={classnames(styles.option, {
                        [styles.selected]: selectedCustomer?.value === option.value,
                      })}
                    >
                      {option.label}
                    </div>
                  ))
                )}
              </div>
            </>
          )}
        </PopoverContent>
      </div>
    </Popover>
  );
};

CustomerSelection.propTypes = {
  isDisabled: PropTypes.bool,
  onCustomerSelected: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
};

export default CustomerSelection;
