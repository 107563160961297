import { QueryCache, QueryClient } from '@tanstack/react-query';
import toast from 'shared/components/andtComponents/Toast';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
  // TODO: After testing, uncomment the following code and remove the onError monitoring config in App.jsx
  // queryCache: new QueryCache({
  //   onError: (error, query) => {
  //     if (query.meta?.disableErrorMessage) {
  //       return;
  //     }
  //     if (query.meta?.errorMessage) {
  //       toast.error(query.meta.errorMessage);
  //     } else if (error.clientMessage) {
  //       toast.error(error.clientMessage);
  //     } else {
  //       toast.error('General error occurred. Please try again later.');
  //     }
  //   },
  // }),
});
