import UncontrolledCostChart from 'shared/components/charts/uncontrolledCostChart/UncontrolledCostChart';
import DevExpSearchAndVisibilityTable from 'shared/components/tables/DevExpSearchAndVisibilityTable';
import TwoAxesLineChart from 'recommendations/containers/Dashboard/components/RecDashboardLineChart.jsx';
import RecommSummeryTable from 'recommendations/containers/Dashboard/components/RecsSummaryTable.jsx';
import {
  POD_DETAILS_TABLE_COLUMNS,
  POD_DETAILS_TABLE_COLUMNS_EXTENSIONS,
  POD_DETAILS_TABLE_COLUMNS_WIDTHS,
} from 'kubernetes/containers/KubernetesDashboard/components/ChartsPanel/ChartsPanel';
import UsageCost from 'app/containers/Dashboard/components/UsageCost';
import TopServicesCostChart from 'app/containers/Dashboard/components/TopServicesCostChart';
import SimpleBarChart from 'shared/components/charts/SimpleBarChart';
import CurrVsPreviousMonthCost from 'app/containers/Dashboard/components/CurrVsPreviousMonthCost';
import MainDbConstants from 'app/constants/dbConstants';
import UsageCostToggle from 'app/containers/Dashboard/components/UsageCostToggle';
import RegionActivityMap from 'shared/components/RegionActivityMap';
import YesterdayTopServicesCostChart from 'app/containers/Dashboard/components/YesterdayTopServicesCostChart';
import {
  REC_DASHB_SUMMARY_COLUMN_WIDTHS,
  REC_DASHB_SUMMARY_DEFAULT_SORTING,
  REC_DASHB_SUMMARY_TABLE_COLUMN_EXTENSIONS,
  REC_DASHB_SUMMARY_TABLE_COLUMNS,
} from 'recommendations/constants/recommendationsConstants';
import BarChart from '../components/charts/SimpleBarChart';
import LineChart from '../components/charts/SimpleLineChart';
import HalfPieChart from '../components/charts/HalfPieChart';
import AreaWithAvg from '../components/charts/AreaWithAvg';
import SavingOnDemandChart from '../components/charts/SavingOnDemand';
import Top5SavingsChart from '../components/charts/Top5SavingsChart';
import SimpleTable from '../components/tables/SimpleTable';
import ResourceTable from '../components/tables/InstancesCostFilterTable';
import AssetsPanelTable from '../components/tables/AssetsPanelTable';
import BudgetChart from '../components/charts/BudgetChartContainer';
import { DisplayMetricTypes } from '../../../constants/costAndUsageConstants';
import NewRecommendationHeatMap from '../components/charts/NewRecommendationHeatMap';
import RecommendationCloudWasteChart from '../components/charts/recommendationCloudWasteChart';
import RecommendationSavingsOpportunities from '../components/charts/recommendationsSavingsOpportunities';
import SPRecommendedChart from 'usage/containers/CustomDashboard/components/charts/SpRecommendedChart.jsx';
import SystemPanelsWrapper from 'usage/containers/CustomDashboard/components/charts/SystemPanelsWrapper.jsx';
import ForecastedCost from 'app/containers/MainDashboard/panels/ForecastedCost.jsx';
import AnnualSavings from 'app/containers/MainDashboard/panels/AnnualSavings.jsx';
import PreviousMonthCost from 'app/containers/MainDashboard/panels/PreviousMonthCost.jsx';
import MtdCost from 'app/containers/MainDashboard/panels/MtdCost.jsx';
import MtdTop10 from 'app/containers/MainDashboard/panels/MtdTop10/MtdTop10.jsx';
import RegionCost from 'app/containers/MainDashboard/panels/RegionCost.jsx';
import HistoryMonthlyWithForecast from 'app/containers/MainDashboard/panels/HistoryWithForecast/HistoryMonthlyWithForecast.jsx';
import HistoryWithForecast from 'app/containers/MainDashboard/panels/HistoryWithForecast/HistoryWithForecast.jsx';
import AnomaliesData from 'app/containers/MainDashboard/panels/MonitoringData/AnomaliesData.jsx';
import BudgetsData from 'app/containers/MainDashboard/panels/MonitoringData/BudgetsData.jsx';
import CostPanelsWrapper from 'app/containers/MainDashboard/components/CostPanelsWrapper.jsx';
import SavingsData from 'app/containers/MainDashboard/panels/MonitoringData/SavingsData.jsx';
import CommitmentData from 'app/containers/MainDashboard/panels/MonitoringData/CommitmentData.jsx';

export const PANEL_COMPONENT_TYPES = {
  BAR: 'barchart',
  LINE: 'linechart',
  USAGE_LINE: 'usagelinechart',
  PRECENT_BAR: 'precent-barchart',
  STACKED_BAR: 'stacked-barchart',
  STACKED_PRECENT_BAR: 'stacked-precent-barchart',
  COST_CHART: 'costchart',
  COST_CHART_SECONDARY: 'costchart-secondary',
  CUE_TABLE: 'cuetable',
  ASSETS_TABLE: 'assetstable',
  CUE_RESOURCE_TABLE: 'cueresourcetable',
  K8S_TABLE: 'k8stable',
  K8S_POD_DETAILS_TABLE: 'k8spoddetailstable',
  PIE: 'piechart',
  K8S_PIE: 'k8spiechart',
  TWO_AXES_LINE: 'two-axes-linechart',
  RECOMM_SUMMERY_TABLE: 'recomm-summery-table',
  HEAT_MAP: 'heat-map',
  CLOUD_WASTE: 'cloud-waste',
  SAVING_OPPORTUNITIES: 'saving-opportunities',
  RECOMM_CLOUD_WASTE: 'recomm-cloud-waste',
  HALF_PIE_CHART: 'half-piechart',
  AREA_WITH_AVG: 'areachart-with-avg',
  BUDGET_CHART: 'budget-chart',
  // SPECIAL CHARTS FOR PREDEFINED PANELS
  SAVING_ON_DEMAND: 'saving-on-demand-chart',
  TOP_5_SAVINGS: 'top-5-savings-chart',
  MONTH_TO_DATE_COST: 'month-to-date-cost',
  FORECASTED_MONTHLY_COST: 'forecasted-monthly-cost',
  REGION_MAP: 'region-map',
  DAILY_CHANGE_TOP_5_COSTS: 'daily-change-top-5-costs',
  MONTH_TO_DATE_TOP_5: 'month-to-date-top-5-services',
  COST_HISTORY: 'cost-history',
  CURR_VS_PREVIOUS_MONTH_COST: 'curr-vs-previous-month-cost',
  ANNUAL_SAVINGS: 'annual-savings',
  PREVIOUS_MONTH_COSTS: 'previous-month-costs',
  SP_RECOMMENDED_CHART: 'sp-recommended-chart',
  ANOMALY_DATA: 'anomaly-data',
  ACTIVE_BUDGET: 'active-budget',
  SUMMARY_PANEL: 'summary-panel',
  COMMITMENT_DATA: 'commitment-data',
  SAVINGS_DATA: 'savings-data',
};
export const CUSTOM_DASHBOARD_TABLE_COLUMNS = [
  { name: 'name', title: 'Dashboard' },
  { name: 'csp', title: 'CSP' },
  { name: 'createdBy', title: 'Created by' },
  { name: 'creationDate', title: 'Created Date' },
  { name: 'settings', title: 'Permissions' },
  { name: 'userAction', title: ' ' },
];
export const CUSTOM_DASHBOARD_TEMPLATES_TABLE_COLUMNS = [
  { name: 'name', title: 'Template' },
  { name: 'createdBy', title: 'Created by' },
  { name: 'creationDate', title: 'Created Date' },
  { name: 'userAction', title: ' ' },
];
export const CUSTOM_DASHBOARD_COLUMN_WIDTHS = [
  { columnName: 'name', width: '28%' },
  { columnName: 'csp', width: '9%' },
  { columnName: 'createdBy', width: '23%' },
  { columnName: 'creationDate', width: '15%' },
  { columnName: 'settings', width: '15%' },
  { columnName: 'userAction', width: '10%' },
];
export const CUSTOM_DASHBOARD_TEMPLATES_COLUMN_WIDTHS = [
  { columnName: 'name', width: '30%' },
  { columnName: 'createdBy', width: '25%' },
  { columnName: 'creationDate', width: '32%' },
  { columnName: 'userAction', width: '13%' },
];
export const CUSTOM_DASHBOARD_TABLE_COLUMN_EXTENSIONS = [
  { columnName: 'isDefault', align: 'center' },
  { columnName: 'csp', align: 'center' },
  { columnName: 'userAction', align: 'right' },
];

const getTopServicesColumns = (data) => {
  const { prepared_top_services_costs: preparedTopServices } = data[0] || {};
  if (!preparedTopServices) {
    return {};
  }
  // TODO - return forecast data once it's ready
  const isForecastedExist = false; // preparedTopServices.some((row) => row.forecasted);
  const isChangeExist = preparedTopServices.some((row) => row.change);
  const topServicesColumnWidth = [
    { columnName: 'serviceName', width: 'auto' },
    { columnName: 'prevMonthCost', width: 'auto' },
    { columnName: 'mtdCost', width: 'auto' },
    { columnName: 'forecasted', width: 'auto' },
    { columnName: 'change', width: 'auto' },
  ];
  const topServicesColumns = [
    { name: 'serviceName', title: 'Service', getCellValue: (row) => row.name },
    { name: 'prevMonthCost', title: 'Prev Month Cost', getCellValue: (row) => row['Previous month'] },
    { name: 'mtdCost', title: 'MTD Cost', getCellValue: (row) => row['Current month'] },
  ];
  if (isForecastedExist) {
    topServicesColumns.push({
      name: 'forecasted',
      title: 'Forecasted',
      getCellValue: (row) => row.forecasted && +row.forecasted.toFixed(1),
    });
  }
  if (isChangeExist && isForecastedExist) {
    topServicesColumns.push({
      name: 'change',
      title: 'Change',
      getCellValue: (row) => row.change && `${row.change.toFixed()}%`,
    });
  }
  return { columnWidth: topServicesColumnWidth, columns: topServicesColumns };
};

export const propsEvaluatedChartTypeToChartOrTableComponent = (
  chartOrTableType,
  restPanelParams,
  data,
  usageStore,
  isNewMD,
) => {
  const mapChartTypeToChartComponent = new Map([
    [
      PANEL_COMPONENT_TYPES.ANNUAL_SAVINGS,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : UsageCostToggle,
        chartOrTableProps: {
          color: 'green',
          percent: (data[0]?.expected_savings / data[0]?.forecasted_current_month_end_costs) * 100,
          potSavings: data[0]?.expected_savings,
          withHeader: false,
          xlColSize: 12,
          colSize: 12,
          containerClassName: 'p-0',
          cardBodyClassName: 'p-0',
          chartComponent: <AnnualSavings />,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.PREVIOUS_MONTH_COSTS,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : UsageCost,
        chartOrTableProps: {
          color: 'greenish',
          value: data[0]?.month_befor_total_cost,
          withHeader: false,
          xlColSize: 12,
          colSize: 12,
          containerClassName: 'p-0',
          cardBodyClassName: 'p-0',
          chartComponent: <PreviousMonthCost />,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.CURR_VS_PREVIOUS_MONTH_COST,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : CurrVsPreviousMonthCost,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_day_data,
          chartComponent: (
            <HistoryWithForecast
              isFullWidth={true}
              hideSaveDashboard={true}
              forecastMonths={restPanelParams?.state?.forecastMonths}
            />
          ),
          filters: restPanelParams?.state?.filters,
          isCustomDashboard: true,
          xLabel: MainDbConstants.CURR_VS_PREV_X_LABEL,
          yLabel: MainDbConstants.CURR_VS_PREV_Y_LABEL,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.COST_HISTORY,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : SimpleBarChart,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_month,
          chartComponent: (
            <HistoryMonthlyWithForecast
              isFullWidth={true}
              hideSaveDashboard={true}
              forecastMonths={restPanelParams?.state?.forecastMonths}
            />
          ),
          displayedMetric: DisplayMetricTypes.COST,
          dataKey: 'usageDate',
          yLabel: 'Cost',
          filters: restPanelParams?.state?.filters,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.MONTH_TO_DATE_TOP_5,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : TopServicesCostChart,
        chartOrTableProps: {
          data: data[0]?.prepared_top_services_costs,
          chartComponent: (
            <MtdTop10
              isFullWidth={true}
              hideSaveDashboard={true}
              existingGroupBy={restPanelParams?.state?.groupBy}
              existingOrderBy={restPanelParams?.state?.orderBy}
              includeChart={!restPanelParams?.rootStore?.usersStore?.isCurrentAccountSingle}
            />
          ),
          filters: restPanelParams?.state?.filters,
          isCustomDashboard: true,
          ...getTopServicesColumns(data),
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.DAILY_CHANGE_TOP_5_COSTS,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : YesterdayTopServicesCostChart,
        chartOrTableProps: {
          data: data[0]?.prepared_yesterday_top_5_services,
          chartComponent: (
            <MtdTop10
              isFullWidth={true}
              isDailyMode={true}
              hideSaveDashboard={true}
              existingGroupBy={restPanelParams?.state?.groupBy}
              existingOrderBy={restPanelParams?.state?.orderBy}
            />
          ),
          filters: restPanelParams?.state?.filters,
          isCustomDashboard: true,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.COMMITMENT_DATA,
      {
        ChartOrTable: SystemPanelsWrapper,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_region,
          chartComponent: <CommitmentData hideSaveDashboard={true} />,
          isCustomDashboard: true,
          filters: restPanelParams?.state?.filters,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.SAVINGS_DATA,
      {
        ChartOrTable: SystemPanelsWrapper,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_region,
          chartComponent: <SavingsData hideSaveDashboard={true} />,
          isCustomDashboard: true,
          filters: restPanelParams?.state?.filters,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.SUMMARY_PANEL,
      {
        ChartOrTable: SystemPanelsWrapper,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_region,
          chartComponent: <CostPanelsWrapper isDashboardPanel={true} />,
          isCustomDashboard: true,
          filters: restPanelParams?.state?.filters,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.ANOMALY_DATA,
      {
        ChartOrTable: SystemPanelsWrapper,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_region,
          chartComponent: <AnomaliesData hideSaveDashboard={true} />,
          isCustomDashboard: true,
          filters: restPanelParams?.state?.filters,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.ACTIVE_BUDGET,
      {
        ChartOrTable: SystemPanelsWrapper,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_region,
          chartComponent: <BudgetsData hideSaveDashboard={true} />,
          isCustomDashboard: true,
          filters: restPanelParams?.state?.filters,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.REGION_MAP,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : RegionActivityMap,
        chartOrTableProps: {
          data: data[0]?.prepared_cost_per_region,
          chartComponent: <RegionCost hideSaveDashboard={true} />,
          isCustomDashboard: true,
          filters: restPanelParams?.state?.filters,
        },
      },
    ],
    [PANEL_COMPONENT_TYPES.BAR, { ChartOrTable: BarChart, chartOrTableProps: { dataKey: 'divName' } }],
    [
      PANEL_COMPONENT_TYPES.TWO_AXES_LINE,
      {
        ChartOrTable: TwoAxesLineChart,
        chartOrTableProps: { height: 500, isShowActualCost: restPanelParams.routeParams.isShowActualCost },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.RECOMM_SUMMERY_TABLE,
      {
        ChartOrTable: RecommSummeryTable,
        chartOrTableProps: {
          height: 500,
          columnWidths: REC_DASHB_SUMMARY_COLUMN_WIDTHS,
          columns: REC_DASHB_SUMMARY_TABLE_COLUMNS,
          tableColumnExtensions: REC_DASHB_SUMMARY_TABLE_COLUMN_EXTENSIONS,
          defaultSorting: REC_DASHB_SUMMARY_DEFAULT_SORTING,
          currenciesColumns: ['savings'],
          data: restPanelParams.panelData,
        },
        currentRecType: restPanelParams.routeParams.recommType,
        isSelectTypeActive: restPanelParams.routeParams.isSelectTypeActive,
      },
    ],
    [
      PANEL_COMPONENT_TYPES.HEAT_MAP,
      {
        ChartOrTable: NewRecommendationHeatMap,
        chartOrTableProps: { state: restPanelParams.state },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.CLOUD_WASTE,
      {
        ChartOrTable: RecommendationCloudWasteChart,
        chartOrTableProps: { state: restPanelParams.state },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.SAVING_OPPORTUNITIES,
      {
        ChartOrTable: RecommendationSavingsOpportunities,
        chartOrTableProps: { state: restPanelParams.state, cloudType: restPanelParams.cloudType },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.SP_RECOMMENDED_CHART,
      {
        ChartOrTable: SPRecommendedChart,
        chartOrTableProps: {
          state: restPanelParams.state,
          id: restPanelParams.routeParams.id,
          viewType: restPanelParams?.state?.viewType,
        },
      },
    ],
    [PANEL_COMPONENT_TYPES.LINE, { ChartOrTable: LineChart, chartOrTableProps: { dataKey: 'groupBy' } }],
    [
      PANEL_COMPONENT_TYPES.USAGE_LINE,
      {
        ChartOrTable: LineChart,
        chartOrTableProps: {
          dataKey: 'usageDate',
          displayMetric: restPanelParams.routeParams.displayMetricTypes,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.PIE,
      {
        ChartOrTable: UncontrolledCostChart,
        chartOrTableProps: {
          dataKey: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate' ? 'groupBySecondary' : 'usageDate',
          secondaryGroupBy: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate',
          groupBy: restPanelParams.routeParams.groupByLevel,
          granLevel: restPanelParams.routeParams.periodGranLevel,
          displayedMetric: restPanelParams.routeParams.displayMetricTypes,
          isUsageChecked: restPanelParams.state?.isUsageChecked,
          SelectedUsageType:
            (restPanelParams.state && restPanelParams.state.SelectedUsageType) ||
            restPanelParams.routeParams.SelectedUsageType,
          numberOfItems: restPanelParams.numberOfItems,
          isShowOthers: restPanelParams.state && restPanelParams.state.isShowOthers,
          isPieChart: true,
          isFromCustomDashboard: true,
          hideLegendsSelector: true,
          usageStore,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.K8S_PIE,
      {
        ChartOrTable: UncontrolledCostChart,
        chartOrTableProps: {
          dataKey: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate' ? 'groupBySecondary' : 'usageDate',
          secondaryGroupBy: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate',
          groupBy: restPanelParams.routeParams.groupByLevel,
          granLevel: restPanelParams.routeParams.periodGranLevel,
          displayedMetric: restPanelParams.routeParams.displayMetricTypes,
          isUsageChecked: restPanelParams.state?.isUsageChecked,
          SelectedUsageType:
            (restPanelParams.state && restPanelParams.state.SelectedUsageType) ||
            restPanelParams.routeParams.SelectedUsageType,
          numberOfItems: restPanelParams.numberOfItems,
          isShowOthers: restPanelParams.state && restPanelParams.state.isShowOthers,
          isPieChart: true,
          isFromCustomDashboard: true,
          hideLegendsSelector: true,
          usageStore,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.PRECENT_BAR,
      {
        ChartOrTable: BarChart,
        chartOrTableProps: {
          dataKey: 'divName',
          isPrecent: true,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.STACKED_BAR,
      {
        ChartOrTable: BarChart,
        chartOrTableProps: { dataKey: 'divName', isStacked: true },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.STACKED_PRECENT_BAR,
      {
        ChartOrTable: BarChart,
        chartOrTableProps: {
          dataKey: 'divName',
          isPrecent: true,
          isStacked: true,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.COST_CHART,
      {
        ChartOrTable: UncontrolledCostChart,
        chartOrTableProps: {
          dataKey: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate' ? 'groupBySecondary' : 'usageDate',
          secondaryGroupBy: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate',
          groupBy: restPanelParams.routeParams.groupByLevel,
          granLevel: restPanelParams.routeParams.periodGranLevel,
          displayedMetric: restPanelParams.routeParams.displayMetricTypes,
          filteredKeys: restPanelParams.state && (restPanelParams.state.filteredKeys || []),
          visibleFilterKeyCount:
            restPanelParams.state &&
            restPanelParams.state.visibleFilters &&
            restPanelParams.state.visibleFilters.length,
          isTrendLine: restPanelParams.state && restPanelParams.state.isTrendLine,
          isEventsOpen: restPanelParams.state && restPanelParams.state.isEventsOpen,
          isCumulative: restPanelParams.state && restPanelParams.state.isCumulative,
          isAreaChart: restPanelParams.state && restPanelParams.state.isAreaChart,
          isLineChart: restPanelParams.state && restPanelParams.state.isLineChart,
          selectedGoal: restPanelParams.state && restPanelParams.state.selectedGoal,
          isUsageChecked: restPanelParams.state?.isUsageChecked,
          SelectedUsageType:
            (restPanelParams.state && restPanelParams.state.SelectedUsageType) ||
            restPanelParams.routeParams.SelectedUsageType,
          isShowOthers: restPanelParams.state && restPanelParams.state.isShowOthers,
          numberOfItems: restPanelParams.numberOfItems,
          startDate: restPanelParams.startDate,
          endDate: restPanelParams.endDate,
          isFromCustomDashboard: true,
          usageStore,
          hideLegendsSelector: true,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.CUE_TABLE,
      {
        ChartOrTable: SimpleTable,
        chartOrTableProps: {
          dataKey: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate' ? 'groupBySecondary' : 'usageDate',
          secondaryGroupBy: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate',
          granLevel: restPanelParams.routeParams.periodGranLevel,
          displayedMetric: restPanelParams.routeParams.displayMetricTypes,
          panelName: `${restPanelParams.name}`,
          isTableTrendRow: !!(restPanelParams.state && restPanelParams.state.isTableTrendRow),
          isTableOnlyTrendRow: !!(restPanelParams.state && restPanelParams.state.isTableOnlyTrendRow),
          isCumulative: !!(restPanelParams.state && restPanelParams.state.isCumulative),
          displayMetricTypes: !!(restPanelParams.state && restPanelParams.state.displayMetricTypes),
          selectedPageSize: (restPanelParams.state && restPanelParams.state.selectedPageSize) || 15,
          selectedUsageType: restPanelParams.state && restPanelParams.state.SelectedUsageType,
          isUsageChecked: restPanelParams.state && restPanelParams.state.isUsageChecked,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.ASSETS_TABLE,
      {
        ChartOrTable: AssetsPanelTable,
        chartOrTableProps: {
          selectedMeasure: (restPanelParams.routeParams || {}).selectedMeasure,
          selectedColumns: (restPanelParams.routeParams || {}).selectedColumns,
          isK8S: (restPanelParams.state || {}).isK8S,
          costTypes: (restPanelParams.state || {}).currCostType,
          granularity: (restPanelParams.state || {}).granularity,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.CUE_RESOURCE_TABLE,
      {
        ChartOrTable: ResourceTable,
        chartOrTableProps: {
          startDate: restPanelParams.startDate,
          endDate: restPanelParams.endDate,
          panelName: `${restPanelParams.name}`,
          selectedPageSize: (restPanelParams.state && restPanelParams.state.selectedPageSize) || 15,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.K8S_TABLE,
      {
        ChartOrTable: SimpleTable,
        chartOrTableProps: {
          dataKey: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate' ? 'groupBySecondary' : 'usageDate',
          secondaryGroupBy: restPanelParams.routeParams.secondaryGroupByLevel !== 'usagedate',
          granLevel: restPanelParams.routeParams.periodGranLevel,
          displayedMetric: restPanelParams.routeParams.displayMetricTypes,
          panelName: `${restPanelParams.name}`,
          isCumulative: !!(restPanelParams.state && restPanelParams.state.isCumulative),
          selectedUsageType: restPanelParams.state && restPanelParams.state.SelectedUsageType,
          isUsageChecked: restPanelParams.state && restPanelParams.state.isUsageChecked,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.K8S_POD_DETAILS_TABLE,
      {
        ChartOrTable: DevExpSearchAndVisibilityTable,
        chartOrTableProps: {
          columns: POD_DETAILS_TABLE_COLUMNS,
          costColumnsArray: ['cost', 'costWaste', 'totalCost'],
          currenciesColumns: ['cost', 'costWaste'],
          tableColumnExtensions: POD_DETAILS_TABLE_COLUMNS_EXTENSIONS,
          csvTitle: `${restPanelParams.name}`,
          columnWidths: POD_DETAILS_TABLE_COLUMNS_WIDTHS,
          defaultSorting: [],
          isVisibility: false,
          numCostDecimal: 3,
          isResize: 'true',
          isPaging: 'true',
          defaultPageSize: 15,
        },
      },
    ],
    [PANEL_COMPONENT_TYPES.HALF_PIE_CHART, { ChartOrTable: HalfPieChart, chartOrTableProps: {} }],
    [
      PANEL_COMPONENT_TYPES.AREA_WITH_AVG,
      {
        ChartOrTable: AreaWithAvg,
        chartOrTableProps: {
          dataKey: 'usageDate',
          dataLabelKey: 'Avg hourly cost',
          title: 'AVG Hourly Cost',
          service: restPanelParams.routeParams.service,
        },
      },
    ],
    [PANEL_COMPONENT_TYPES.SAVING_ON_DEMAND, { ChartOrTable: SavingOnDemandChart }],
    [PANEL_COMPONENT_TYPES.TOP_5_SAVINGS, { ChartOrTable: Top5SavingsChart }],
    [
      PANEL_COMPONENT_TYPES.MONTH_TO_DATE_COST,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : UsageCost,
        chartOrTableProps: {
          color: 'blue',
          id: PANEL_COMPONENT_TYPES.MONTH_TO_DATE_COST,
          value: data[0]?.month_to_date_costs,
          percent: data[0]?.month_to_date_percentages,
          percentArrowIsUp: data[0]?.month_to_date_percentages > 0,
          withHeader: false,
          showPercent: true,
          xlColSize: 12,
          colSize: 12,
          containerClassName: 'p-0',
          cardBodyClassName: 'p-0',
          chartComponent: <MtdCost />,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.FORECASTED_MONTHLY_COST,
      {
        ChartOrTable: isNewMD ? SystemPanelsWrapper : UsageCost,
        chartOrTableProps: {
          color: 'orange',
          id: PANEL_COMPONENT_TYPES.FORECASTED_MONTHLY_COST,
          value: data[0]?.forecasted_current_month_end_costs,
          percent: data[0]?.forecasted_current_month_end_percentages,
          percentArrowIsUp: data[0]?.forecasted_current_month_end_percentages > 0,
          withHeader: false,
          showPercent: true,
          xlColSize: 12,
          colSize: 12,
          containerClassName: 'p-0',
          cardBodyClassName: 'p-0',
          chartComponent: <ForecastedCost />,
        },
      },
    ],
    [
      PANEL_COMPONENT_TYPES.BUDGET_CHART,
      {
        ChartOrTable: BudgetChart,
        chartOrTableProps: {
          id: restPanelParams.routeParams.id,
          viewType: restPanelParams?.state?.viewType,
        },
      },
    ],
  ]);
  const selectedChart = mapChartTypeToChartComponent.get(chartOrTableType);
  return selectedChart;
};
