import React, { useEffect } from 'react';
import moment from 'moment';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import styles from './CountdownTimer.module.scss';

const REFRESH_INTERVAL = 1000;

interface Props {
  startTime: string;
  durationHours: number;
  message: string;
  tooltipMessage: string;
}

const CountdownTimer: React.FC<Props> = ({ startTime, durationHours, message, tooltipMessage }) => {
  const [timeLeft, setTimeLeft] = React.useState(moment(startTime).add(durationHours, 'h').diff(moment(), 'seconds'));
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(moment(startTime).add(durationHours, 'h').diff(moment(), 'seconds'));
    }, REFRESH_INTERVAL);
    return () => clearInterval(timer);
  }, [startTime]);
  if (!startTime || timeLeft <= 0) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {message}
        {tooltipMessage && (
          <InfoPopover>
            <span className={styles.tooltipText}>
              {tooltipMessage}
              You have 24 hours to complete the onboarding process before validation begins. Ensure all required
              information is provided before the countdown expires to avoid restarting the process.
            </span>
          </InfoPopover>
        )}
      </div>
      <div className={styles.counter}>{moment.utc(timeLeft * 1000).format('HH:mm:ss')}</div>
    </div>
  );
};

export default CountdownTimer;
