import React from 'react';
import { CostTypeModes } from 'usage/constants/costAndUsageConstants';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import icons from '../icons';
import styles from './CostTypeMenu.module.scss';
import Tooltip from '~/shared/components/andtComponents/Tooltip.jsx';
import tooltipStyles from '~/shared/components/andtComponents/Tooltip.module.scss';
import { palette } from '~/shared/constants/colorsConstants.js';

const { CostIcon, UsageIcon, RateIcon, CloudDotsIcon } = icons.costUsage;

export const getLabel = (value, type) => {
  if (!type) {
    return value;
  }
  if (!value || value === type) {
    return type;
  }
  return `${type}: ${value}`;
};

export const wrapOptionInTooltip = (option, tooltip) =>
  tooltip ? (
    <Tooltip title={tooltip} key={tooltip} classes={{ tooltip: tooltipStyles.darkGrayTooltip }}>
      {option}
    </Tooltip>
  ) : (
    option
  );

export const getLabelByType = (costOptionName, usageOptionName, carbonEmissionName) => {
  return {
    [CostTypeModes.COST]: costOptionName,
    [CostTypeModes.USAGE]: usageOptionName,
    [CostTypeModes.RATE]: 'Rate',
    [CostTypeModes.CARBON_EMISSIONS]: carbonEmissionName,
  };
};

export const buildCarbonEmissions = (isCarbonEmissions, carbonEmissionsOptions, onChange, carbonEmissionsValue) => {
  return {
    id: 'carbonEmissions',
    label: 'Carbon Emissions',
    selected: isCarbonEmissions,
    icon: CloudDotsIcon,
    color: palette.mint[600],
    content: carbonEmissionsOptions.map((o) => (
      <li
        onClick={() => {
          onChange(o.value, null, CostTypeModes.CARBON_EMISSIONS);
        }}
        onKeyDown={() => {
          onChange(o.value, null, CostTypeModes.CARBON_EMISSIONS);
        }}
        data-disabled={o.disabled}
        data-selected={o.value === carbonEmissionsValue && isCarbonEmissions}
        key={o.value}
        id={o.value}
        className={styles.carbonEmissionsValues}
      >
        <span>{o.name}</span>
      </li>
    )),
  };
};

export const buildUsageOption = (isUsage, usageOptionName, usageOptions, onChange, usageValue) => {
  return {
    id: 'usage',
    label: getLabel(isUsage ? usageOptionName : null, 'Usage'),
    selected: isUsage,
    icon: UsageIcon,
    content: (
      <>
        {usageOptions.map((o) => (
          <li
            onClick={() => {
              onChange(null, o.value, CostTypeModes.USAGE);
            }}
            onKeyDown={() => {
              onChange(null, o.value, CostTypeModes.USAGE);
            }}
            data-selected={o.value === usageValue && isUsage}
            key={o.value}
            id={o.value}
          >
            <span className="w-100">
              {o.name}
              {o.tooltip ? (
                <span className="ms-auto">
                  <InfoPopover isSimple>{o.tooltip}</InfoPopover>
                </span>
              ) : null}
            </span>
          </li>
        ))}
      </>
    ),
  };
};

export const buildOptions = (
  isCost,
  costDefaultType,
  isCostContainOptions,
  onChange,
  costValue,
  costReadOnlyOptions,
  costOptions = [],
) => {
  const costOptionName = (costOptions?.find((c) => c.value === costValue))?.name ?? costDefaultType ?? 'Cost';
  
  return [
    {
      id: 'cost',
      label: getLabel(isCost ? costOptionName : null, costDefaultType || 'Cost'),
      selected: isCost,
      icon: CostIcon,
      simpleOption: !isCostContainOptions,
      onClick: isCostContainOptions ? null : () => onChange(costValue, null, CostTypeModes.COST),
      content: isCostContainOptions ? (
        <>
          {costReadOnlyOptions?.length
            ? costReadOnlyOptions.map((o) =>
                wrapOptionInTooltip(
                  <li data-disabled={o.disabled} data-selected={o.selected && isCost} id={o.value}>
                    <span>{o.name}</span>
                  </li>,
                  o.tooltip,
                ),
              )
            : costOptions.map((o) =>
                wrapOptionInTooltip(
                  <li
                    onClick={() => {
                      onChange(o.value, null, CostTypeModes.COST);
                    }}
                    onKeyDown={() => {
                      onChange(o.value, null, CostTypeModes.COST);
                    }}
                    data-disabled={o.disabled}
                    data-selected={o.value === costValue && isCost}
                    key={o.value}
                    id={o.value}
                  >
                    <span>{o.name}</span>
                  </li>,
                  o.tooltip,
                ),
              )}
        </>
      ) : null,
    },
  ];
};

export const getTooltipByType = (costOptionName, usageOptionName, carbonEmissionName, costValue) => {
  return {
    [CostTypeModes.COST]: getLabel(costOptionName, 'Cost'),
    [CostTypeModes.USAGE]: getLabel(usageOptionName, 'Usage'),
    [CostTypeModes.CARBON_EMISSIONS]: getLabel(carbonEmissionName, 'Carbon Emissions'),
    [CostTypeModes.RATE]: getLabel(
      `${costOptionName === 'Cost' ? costValue : costOptionName} / ${usageOptionName}`,
      'Rate',
    ),
  };
};
