import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as NoRoles } from 'users/containers/Organization/Roles/img/no-roles.svg';
import classNames from 'classnames';
import styles from './RolesNoData.module.scss';
import EmptyState from './EmptyState';

const RolesNoData = ({ colSpan, mainText, subTexts, isSmall }) => {
  return (
    <td colSpan={colSpan} className="border-bottom-0 border-start-0 border-end-0">
      <EmptyState
        mainText={mainText || 'No Roles found'}
        subTexts={subTexts || ['Refine your filters to find the role you are looking for']}
        backgroundIcon={<NoRoles className={styles.svgImage} />}
        classes={classNames('noData', { [styles.small]: isSmall })}
      />
    </td>
  );
};

RolesNoData.propTypes = {
  colSpan: PropTypes.number,
  isSmall: PropTypes.bool,
  mainText: PropTypes.string,
  subTexts: PropTypes.array,
};

export default RolesNoData;
