import React from 'react';
import PropTypes from 'prop-types';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { ReactComponent as AlertIcon } from 'shared/img/icons/Illustration-alerts.svg';
import Input from 'shared/components/andtComponents/Input';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Button from 'shared/components/andtComponents/Button';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import { granularityMethodOptions } from 'usage/containers/Budget/budgetUtil';
import Select, { components } from 'react-select';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels';
import RecipientsSelect from 'shared/components/RecipientsSelect';
import classes from './BudgetModal.module.scss';

const selectStyles = (hasError) => ({
  control: (styles) => ({
    ...styles,
    backgroundColor: '#E9E9F0',
    borderRadius: '6px',
    borderColor: hasError ? '#D10F37' : '#E9E9F0',
    height: '36px',
    width: 200,
  }),
  menu: (styles) => ({
    ...styles,
    width: 490,
    height: 'auto',
    marginTop: 2,
    position: 'absolute',
    zIndex: 10,
  }),
  option: (styles, { isFocused, isSelected }) => {
    let backgroundColor;
    if (isSelected) {
      backgroundColor = '#ffffff';
    } else if (isFocused) {
      backgroundColor = '#F7FAFF';
    }
    return {
      ...styles,
      background: backgroundColor,
    };
  },
  indicatorSeparator: () => ({ display: 'none' }),
});
const OptionMulti = (props) => {
  const { isSelected, data, isFocused } = props;
  const { label, description } = data;
  return (
    <components.Option {...props}>
      <div className={`${classes.option} ${isFocused ? classes.focused : null}`}>
        <div className={classes.optionsWrapper}>
          <div className={classes.flex}>
            <input className={classes.checkbox} type="checkbox" checked={isSelected} onChange={() => null} />{' '}
            <div className={classes.optionLabel}>{label}</div>
          </div>
          <div className={classes.optionSubLabel}>{description}</div>
        </div>
      </div>
    </components.Option>
  );
};
OptionMulti.propTypes = {
  data: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isFocused: PropTypes.bool.isRequired,
};

function EmptyComponent() {
  return <span />;
}

const ValueContainer = (props) => {
  const { getValue, selectProps } = props;
  const renderValue = () => {
    const size = getValue().length;
    if (size === 0) {
      return 'Select';
    }
    if (size === 1) {
      return getValue()[0].label;
    }
    return `Selected (${size})`;
  };

  return (
    <input
      onBlur={selectProps.onMenuClose}
      onFocus={selectProps.onMenuOpen}
      className={`${getValue().length === 0 ? classes.placeholder : ''} ${classes.selectInput}`}
      value={renderValue()}
    />
  );
};
ValueContainer.propTypes = {
  getValue: PropTypes.func.isRequired,
  selectProps: PropTypes.object.isRequired,
};

const CBAlerts = ({ budget, setBudget, saveClicked, budgetValidationErrors = {}, isEditMode }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const { usersStore } = useRootStore();

  const { getChannels } = useChannels();
  const { data: channels, isLoading: isLoadingChannels } = getChannels();

  const updateAlert = (alert, field, value) => {
    const updatedAlert = budget.alerts.find((a) => a === alert);
    updatedAlert[field] = value;

    setBudget({ ...budget, alerts: [...budget.alerts] });
  };

  const getOptions = React.useMemo(() => {
    if (budget.budgetType.value === 'recurring') {
      return granularityMethodOptions.slice(0, 2);
    }
    if (budget.budgetType?.value === 'expiringFixed') {
      return [granularityMethodOptions[2]];
    }
    return granularityMethodOptions;
  }, []);

  return (
    <>
      <div className={classes.wrapper}>
        {budget.alerts?.map((alert) => (
          <div className={classes.alertContainer} key={alert.uuid}>
            <div className={classes.flex}>
              <div className={classes.part1}>
                <span>Budget Amount</span>
                <span
                  className={`${classes.amount} ${budget.budgetType?.value === 'expiring' ? classes.italicText : ''}`}
                >
                  {budget.budgetType?.value === 'expiring'
                    ? 'According to the planned monthly configuration'
                    : getCurrencyNumber(budget.budgetAmount)}
                </span>
              </div>
              <div className={classes.part2}>
                <div className={classes.flexRow}>
                  <div>
                    <div className={classes.granularity}>
                      <span>Alert Granularity</span>
                      <Select
                        isMulti
                        menuPosition="fixed"
                        options={getOptions}
                        value={alert.alertGranularity}
                        styles={selectStyles(
                          saveClicked && !!budgetValidationErrors?.alertGranularity && !alert.alertGranularity?.length,
                        )}
                        onChange={(selected) => {
                          updateAlert(alert, 'alertGranularity', selected);
                        }}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        placeholder="Select"
                        components={{
                          Option: OptionMulti,
                          ValueContainer,
                          ClearIndicator: EmptyComponent,
                        }}
                        automation-id={`${isEditMode ? 'edit' : 'create'}-alert-granularity`}
                        disabled={new Date(budget.endDate) < new Date()}
                      />
                    </div>
                    <ErrorMessage
                      displayError={
                        saveClicked && !!budgetValidationErrors?.alertGranularity && !alert.alertGranularity?.length
                      }
                      errMsg={budgetValidationErrors?.alertGranularity}
                      automationid={`${isEditMode ? 'edit' : 'create'}-alert-granularity-error`}
                    />
                  </div>
                  <div className={classes.thresholdWrapper}>
                    <span>Alert Threshold (%)</span>
                    <Input
                      className={`${classes.thresholdInput} ${
                        saveClicked && budgetValidationErrors?.alertGranularity && !alert.alertGranularity?.length
                          ? classes.error
                          : ''
                      }`}
                      value={alert?.alertPercent}
                      onChange={(e) => {
                        updateAlert(alert, 'alertPercent', e.target.value);
                      }}
                      isInvalid={saveClicked && !!budgetValidationErrors?.alertPercent && !alert.alertPercent}
                      disabled={new Date(budget.endDate) < new Date()}
                      automationid={`${isEditMode ? 'edit' : 'create'}-alert-percent-input`}
                      type="number"
                    />
                    <ErrorMessage
                      displayError={
                        saveClicked &&
                        !!budgetValidationErrors?.alertPercent &&
                        (!alert.alertPercent || alert.alertPercent < 0)
                      }
                      errMsg={budgetValidationErrors?.alertPercent}
                      automationid={`${isEditMode ? 'edit' : 'create'}-alert-percent-input-error`}
                    />
                  </div>
                </div>
                <div>
                  <div className={classes.emailWrapper}>
                    <span>Recipients</span>
                    <Button
                      type="button"
                      text="My Email"
                      onClick={() => {
                        updateAlert(
                          alert,
                          'alertEmail',
                          alert.alertEmail ? `${alert.alertEmail},${usersStore.currUserName}` : usersStore.currUserName,
                        );
                      }}
                      automationid={`${isEditMode ? 'edit' : 'create'}-my-email-button`}
                      disabled={new Date(budget.endDate) < new Date()}
                      overrideStyles={{
                        backgroundColor: 'transparent',
                        color: '#2671FF',
                        fontSize: '14px',
                        marginRight: '-7px',
                        marginBottom: '-7px',
                      }}
                    />
                  </div>
                  <Input
                    className={`${classes.email} mb-1`}
                    placeholder="email@example, email@example"
                    value={alert.alertEmail}
                    onChange={(e) => {
                      updateAlert(alert, 'alertEmail', e.target.value);
                    }}
                    automationid={`${isEditMode ? 'edit' : 'create'}-alert-emails`}
                    disabled={new Date(budget.endDate) < new Date()}
                    isInvalid={
                      saveClicked &&
                      !!budgetValidationErrors?.alertRecipient &&
                      !alert.alertEmail &&
                      !alert.channelRecipients?.length
                    }
                  />
                  <RecipientsSelect
                    channels={channels}
                    isLoading={isLoadingChannels}
                    savedRecipients={alert.channelRecipients}
                    onChangeHandler={(recipients) => updateAlert(alert, 'channelRecipients', recipients)}
                    menuPosition="fixed"
                  />
                  <ErrorMessage
                    displayError={saveClicked && !!budgetValidationErrors?.alertRecipient}
                    errMsg={budgetValidationErrors?.alertRecipient}
                    automationid={`${isEditMode ? 'edit' : 'create'}-alert-email-input-error`}
                  />
                </div>
              </div>
              <div className={classes.part3}>
                <span>What should trigger the alert?</span>
                <div className={classes.triggerWrapper}>
                  <input
                    className={classes.first}
                    type="checkbox"
                    checked={alert.whenToAlert?.indexOf('forecasted') > -1}
                    onChange={() => {
                      const forecastIndex = alert.whenToAlert?.indexOf('forecasted');
                      if (forecastIndex > -1) {
                        alert.whenToAlert.splice(forecastIndex, 1);
                      } else {
                        alert.whenToAlert.push('forecasted');
                      }
                      updateAlert(alert, 'whenToAlert', alert.whenToAlert);
                    }}
                    automation-id={`${isEditMode ? 'edit' : 'create'}-forecast-trigger`}
                    disabled={new Date(budget.endDate) < new Date()}
                  />
                  <span className={classes.label}>Forecasted Spend</span>
                  <input
                    className={classes.second}
                    type="checkbox"
                    checked={alert.whenToAlert?.indexOf('actualUsage') > -1}
                    onChange={() => {
                      const actualUsageIndex = alert.whenToAlert?.indexOf('actualUsage');
                      if (actualUsageIndex > -1) {
                        alert.whenToAlert.splice(actualUsageIndex, 1);
                      } else {
                        alert.whenToAlert.push('actualUsage');
                      }
                      updateAlert(alert, 'whenToAlert', alert.whenToAlert);
                    }}
                    automation-id={`${isEditMode ? 'edit' : 'create'}-actual-trigger`}
                    disabled={new Date(budget.endDate) < new Date()}
                  />
                  <span className={classes.label}>Actual Spend</span>
                </div>
                <ErrorMessage
                  displayError={saveClicked && !!budgetValidationErrors?.alertTrigger && !alert.whenToAlert?.length}
                  errMsg={budgetValidationErrors?.alertTrigger}
                  automationid={`${isEditMode ? 'edit' : 'create'}-alert-trigger-error`}
                />
              </div>
            </div>
            {budget.alerts?.length > 0 && (
              <div className={classes.part4}>
                <button
                  className={classes.close}
                  disabled={new Date(budget.endDate) < new Date()}
                  onClick={() => {
                    const alertIndex = budget.alerts.indexOf(alert);
                    const alerts = [...budget.alerts];
                    alerts.splice(alertIndex, 1);
                    setBudget({ ...budget, alerts: [...alerts] });
                  }}
                  type="button"
                >
                  X
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      {budget.alerts?.length ? (
        <Button
          type="button"
          text="Add Additional Alert"
          onClick={() => {
            setBudget({
              ...budget,
              alerts: [
                ...budget.alerts,
                {
                  whenToAlert: [],
                  alertGranularity: budget.budgetType?.value === 'expiringFixed' ? [getOptions[0]] : [getOptions[1]],
                },
              ],
            });
          }}
          icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
          disabled={new Date(budget.endDate) < new Date()}
          overrideStyles={{
            backgroundColor: 'transparent',
            color: '#2671FF',
            fontSize: '14px',
            fontWeight: '400',
          }}
        />
      ) : (
        <div className={classes.emptyAlert}>
          <AlertIcon />
          <span className={classes.addText}>Would you like to add alerts to your budget?</span>
          <Button
            text="Add Alert"
            onClick={() => {
              setBudget({
                ...budget,
                alerts: [
                  {
                    whenToAlert: [],
                    alertGranularity: budget.budgetType?.value === 'expiringFixed' ? [getOptions[0]] : [getOptions[1]],
                  },
                ],
              });
            }}
            icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
            disabled={new Date(budget.endDate) < new Date()}
            overrideStyles={{
              backgroundColor: '#E9F0FF',
              border: '2px solid #C9DBFF',
              color: '#2671FF',
              fontSize: '14px',
              fontWeight: '400',
              marginTop: '16px',
            }}
          />
        </div>
      )}
    </>
  );
};

export default CBAlerts;
CBAlerts.propTypes = {
  budget: PropTypes.object.isRequired,
  setBudget: PropTypes.func.isRequired,
  saveClicked: PropTypes.func.isRequired,
  budgetValidationErrors: PropTypes.object,
  isEditMode: PropTypes.bool,
};
