/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { ButtonToolbar, Card, CardBody, CardHeader, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { removeDecPoint } from 'shared/utils/strUtil';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class UsageCostToggle extends PureComponent {
  static propTypes = {
    percent: PropTypes.number.isRequired,
    potSavings: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    infoValue: PropTypes.string,
    iconStyle: PropTypes.object,
    xlColSize: PropTypes.number,
    colSize: PropTypes.number,
    withHeader: PropTypes.bool,
    cardBodyClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    getCurrencyNumber: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      // value: props.potSavings,
      isShowNum: true,
    };
  }

  getPercentToShow = (value) => {
    try {
      return parseFloat(value);
    } catch (error) {
      return null;
    }
  };
  toggle = () => {
    const { isShowNum } = this.state;
    this.setState({ isShowNum: !isShowNum });
  };

  render() {
    const {
      colSize = 6,
      xlColSize = 4,
      iconStyle = {
        color: '#727272',
        size: 16,
      },
      percent,
      withHeader = true,
      containerClassName = '',
      cardBodyClassName = '',
      title,
      infoValue = '',
      id,
      potSavings,
      overrideCurrency,
      getCurrencyNumber,
    } = this.props;
    const percentToShow = this.getPercentToShow(percent);
    return (
      <Col className={containerClassName} md={colSize} lg={colSize} xs={colSize} xl={xlColSize}>
        <Card className="saving-panel">
          {withHeader && (
            <CardHeader style={{ backgroundColor: '#FFFFFF' }}>
              <div className="d-flex align-items-center justify-content-between ps-2 pe-2">
                <h5 className="dashboard__description">{title}</h5>
                <ButtonToolbar style={{ margin: 0 }}>
                  <Tooltip placement="top" title={infoValue} arrow>
                    <button id={`TooltipTop${id}`} className="panel__btn_lg" type="button">
                      <IconFromPng icon={ICONS.info} size={iconStyle.size} />
                    </button>
                  </Tooltip>
                </ButtonToolbar>
              </div>
            </CardHeader>
          )}
          <CardBody className={`dashboard__card ${cardBodyClassName}`}>
            <div className="d-flex justify-content-center" style={{ alignItems: 'center', minHeight: '84px' }}>
              {/* <div className="dashboard__container"> */}
              <div>
                <h2 style={{ fontSize: '1.6vw' }} className="dashboard__title">
                  {`${getCurrencyNumber(removeDecPoint(potSavings, 10), 0, null, overrideCurrency)}`}
                </h2>
              </div>
              {percentToShow ? (
                <div className="smallKpi">
                  <span className="text-nowrap">{`${Number(percentToShow).toFixed(1)}%`}</span>
                </div>
              ) : null}
            </div>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

const ObserverUsageCostToggle = withUserSettingsConsumer(UsageCostToggle);
export default ObserverUsageCostToggle;
