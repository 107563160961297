import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import AssignRolesModal from '../userModals/AssignRolesModal/AssignRolesModal';
import RemoveButton from '../../components/RemoveButton';
import { Search } from '../../components/Search';
import { USER_ACTIONS } from '../../consts';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

import styles from './UserRolesTab.module.scss';

const UserRolesTabHeader = ({ rolesCount, selectedUserRoles, search, setSearch, removeRoleClicked, user }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        <div className={styles.header}>
          <div className={styles.title}>
            Roles <span className={styles.count}>({rolesCount})</span>
          </div>
        </div>
      </div>
      <div className={styles.headerRight}>
        <ReadOnlyDisplayWrapper
          isHide={false}
          category={OrganizationEntityCategory.UsersManagement}
          action={Action.Update}
        >
          <RemoveButton
            showRemoveComponent={selectedUserRoles?.length > 0}
            isMultiple
            selectedCounter={selectedUserRoles?.length}
            removeClicked={removeRoleClicked}
          />
        </ReadOnlyDisplayWrapper>
        <ReadOnlyDisplayWrapper
          isHide={false}
          category={OrganizationEntityCategory.UsersManagement}
          action={Action.Update}
        >
          <Button
            text={USER_ACTIONS.ASSIGN_ROLES.label}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            icon={() => <GenerateIcon iconName={USER_ACTIONS.ASSIGN_ROLES.icon} />}
            automationid="assignRole"
          />
          {isOpen && <AssignRolesModal isOpen={isOpen} setIsOpen={setIsOpen} users={[user]} />}
        </ReadOnlyDisplayWrapper>
        <Search search={search} setSearch={setSearch} />
      </div>
    </div>
  );
};

UserRolesTabHeader.propTypes = {
  rolesCount: PropTypes.number,
  selectedUserRoles: PropTypes.array,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  removeRoleClicked: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default UserRolesTabHeader;
