import React, { useMemo } from 'react';
import { useRootStore } from 'app/contexts/RootStoreContext';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import { AddClusterFields } from '../config.ts';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import { useBrand } from 'app/contexts/Brand/BrandContext.tsx';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';

export const SelectLinkedAccountField = ({ onChange, value, ...selectProps }) => {
  const { usersStore } = useRootStore();
  const { filtersValuesMap } = useInvoiceFilters();
  const linkedAccountLabel = LabelCoordinator.getFieldLabelByCloudType('linkedaccid', usersStore.currDispUserCloudAccountType)

  const linkedAccountNames = useMemo(() => {
    const linkedAccFilter = filtersValuesMap?.get(AwsCommonFields.LINKED_ACCOUNT_NAME) || [];
    return linkedAccFilter.map((ln) => ({
      label: ln.linkedAccountName,
      value: ln.linkedAccountId,
    }));
  }, [filtersValuesMap]);

  return (
    <div className={commonStyles.inputField}>
      <label htmlFor={AddClusterFields.linkedAccountId}>
        <span>{linkedAccountLabel}</span>
      </label>
      <div className={commonStyles.inputBox}>
        <SimpleSelect
          className={commonStyles.select}
          value={value}
          options={linkedAccountNames}
          onChange={onChange}
          invalidComponent={<span>{linkedAccountLabel} is required</span>}
          {...selectProps}
        />
      </div>
    </div>
  );
};

export const SelectLinkedAccountHelp = () => {
  const { usersStore } = useRootStore();
  const linkedAccountLabel = LabelCoordinator.getFieldLabelByCloudType('linkedaccid', usersStore.currDispUserCloudAccountType)
  const brand = useBrand();

  return (
    <div className={commonStyles.helpText}>
      <p>Choose the {linkedAccountLabel} where your cluster is located.</p>
      <p>
        <span className={commonStyles.notice}>Note</span>: If your {linkedAccountLabel.toLowerCase()} does not appear in
        the list, it may not be connected. To verify the connection status, navigate to Account {'>'}{' '}
        {linkedAccountLabel} page. For instructions on connecting your linked accounts,{' '}
        <a href={brand.externalLinks.ConnectLinkedAccount} target="_blank" rel="noopener noreferrer">
          click here
        </a>
      </p>
    </div>
  );
};
