import React from 'react';
import { useRootStore } from 'app/contexts/RootStoreContext';
import HolidayLogo from 'shared/components/HolidayThemedIcons/HolidayLogo';
import { useBrand } from 'app/contexts/Brand/BrandContext';
import { Themes } from 'app/contexts/Brand/enums';

const AppLogo = () => {
  const { usersStore } = useRootStore();
  const brandContext = useBrand();
  const LogoComponent = brandContext.logo;
  const currUserTheme = usersStore.getCurrDisplayedUserTheme();
  if (!currUserTheme) {
    return <div />;
  }

  if (currUserTheme === Themes.Default && usersStore.currentHoliday) {
    return (
      <div className="h-100 d-flex align-items-center">
        <HolidayLogo holiday={usersStore.currentHoliday} />
      </div>
    );
  }
  if (brandContext.logo) {
    return <LogoComponent />;
  }

  return <div id="header-logo-main" style={{ height: 34 }} />;
};

export default AppLogo;
