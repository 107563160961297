import React from 'react';
import classNames from 'classnames';
import { PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { getSearchParamsPanel } from 'app/containers/MainDashboard/utils.js';
import NavigateIcon from 'app/containers/MainDashboard/components/NavigateIcon.jsx';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import SaveDashboardPanel from 'app/containers/MainDashboard/components/SaveDashboardPanel.jsx';
import { ReportPeriodTime } from 'usage/constants/costAndUsageConstants.js';
import { PANEL_COMPONENT_TYPES } from 'usage/containers/CustomDashboard/constants/customDashboardConstants.js';
import ForecastSpecialInfo from 'app/containers/MainDashboard/panels/HistoryWithForecast/ForecastSpecialInfo.jsx';
import mainStyles from '../../mainDashboardCommon.module.scss';
import styles from './forecastHeader.module.scss';

const ForecastHeader = ({
  params,
  title,
  info,
  forecastMonths,
  forecastNumMonth,
  setForecastNumMonth,
  panelType,
  hideForecastDetails = false,
  hideSaveDashboard = false,
}) => {
  const { appStore } = useRootStore();

  return (
    <div className={mainStyles.headerRow}>
      <span className={mainStyles.header}>
        {title}
        {info && (
          <InfoPopover
            mode="outline"
            className={{
              icon: styles.infoIcon,
              tooltip: tooltipStyles.whiteTooltip,
              arrow: tooltipStyles.whiteArrow,
            }}
          >
            <span className={styles.tooltip}>{info}</span>
          </InfoPopover>
        )}
      </span>
      <div className={styles.headerWrapper}>
        {!hideForecastDetails && (
          <>
            <div className={styles.forecast}>
              <GenerateIcon iconName={ICONS.sparkles.name} className={styles.icon} />
              <span className={styles.title}>Forecast</span>
              <ForecastSpecialInfo />
            </div>
            <div className={styles.monthsWrapper}>
              {forecastMonths?.map((month) => (
                <Button
                  key={month.value}
                  text={month.label}
                  className={{ button: classNames(styles.month, forecastNumMonth === month.value && styles.selected) }}
                  onClick={() => setForecastNumMonth(month.value)}
                />
              ))}
            </div>
          </>
        )}
        {!hideSaveDashboard && (
          <>
            <NavigateIcon
              getSearchParams={() => getSearchParamsPanel(params, appStore.isPpApplied)}
              id={PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST}
              tooltipText="Open in Cost & Usage Explorer page (without Forecast)"
            />
            <SaveDashboardPanel
              panelType={panelType}
              defaultPeriod={
                panelType === PANEL_COMPONENT_TYPES.COST_HISTORY
                  ? ReportPeriodTime.PREVIOUS_6_MONTH
                  : ReportPeriodTime.SIXTY_DAYS
              }
              forecastMonths={forecastNumMonth}
              granLevel={panelType === PANEL_COMPONENT_TYPES.COST_HISTORY ? 'month' : 'day'}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ForecastHeader;
