import { useQuery } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import apiConstants from 'shared/api/apiConstants.ts';
import type { Vendor } from 'app/store/vendorsStore/vendorStore';

const fetchVendors = async (): Promise<Vendor[]> => {
  return API.get('billings', '/api/v1/users/on-boarding/v2/byod/vendors');
};

export const useVendorMappings = () => {
  return useQuery({
    queryKey: [apiConstants.QUERY_KEYS.VENDOR_MAPPINGS_QUERY_KEY],
    queryFn: fetchVendors,
    staleTime: Infinity,
  });
};
