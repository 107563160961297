/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const customStyles = {
  control: (base, state) => ({
    ...base,
    '&:hover': { borderColor: 'gray' }, // border style on hover
    border: '1px solid lightgray', // default border color
    boxShadow: 'none', // no box-shadow
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    // eslint-disable-next-line no-nested-ternary
    backgroundColor: isDisabled ? null : isSelected ? '#FFFFFF' : isFocused ? '#FFFFFF' : null,
    color: isDisabled,
    '&:hover': {
      borderColor: '#a6a7a7',
      backgroundColor: '#a6a7a7',
    },
    ':active': {
      ...styles[':active'],
      backgroundColor: isDisabled,
    },
  }),
};

class FieldFilter extends PureComponent {
  static propTypes = {
    options: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    isClearable: PropTypes.bool,
    isLoading: PropTypes.bool,
  };

  constructor() {
    super();
    this.state = {};
  }

  prepareData = (data) => {
    if (data) {
      const midifiedData = data.map((i) => {
        if (typeof i === 'string' || typeof i === 'number') {
          return { value: i, label: i };
        } else if (typeof i === 'object' && (i.label || i.value)) {
          return i;
        }
        return null;
      });
      return midifiedData;
    }
    return data;
  };

  render() {
    const {
      options,
      isClearable = true,
      isLoading = false,
      handleChange,
      type,
      value,
      isDisabled = false,
      placeholder = 'Select...',
    } = this.props;

    return (
      <Select
        styles={customStyles}
        defaultValue={options ? options[0] : ''}
        options={this.prepareData(options)}
        isClearable={isClearable}
        isLoading={isLoading}
        onChange={(selectedOptions) => handleChange(type, selectedOptions)}
        value={value}
        isDisabled={isDisabled}
        placeholder={placeholder}
      />
    );
  }
}

export default FieldFilter;
