import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Routes } from 'shared/constants/routes.js';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import LinkIconButton from 'shared/components/buttons/LinkIconButton.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import styles from './navigateIcon.module.scss';

const NavigateIcon = ({id, getSearchParams, tooltipText}) => (

    <LinkIconButton automationid="exploreDashboardPanel">
        <Link
            key={id}
            to={{
                pathname: `${Routes.COST_USAGE_EXPLORER}`,
                search: getSearchParams(),
            }}
        />
      <Tooltip placement="top" title={tooltipText ? <span className={styles.tooltip}>{tooltipText}</span> : null}
               arrow
               classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}>
        <div automation-id="investigate-cue">
          <GenerateIcon iconName={ICONS.magnifyingGlassChart.name} className={styles.navigateIcon}/>
        </div>
      </Tooltip>
    </LinkIconButton>
);

NavigateIcon.propTypes = {
    id: PropTypes.string.isRequired,
    getSearchParams: PropTypes.func.isRequired,
  tooltipText: PropTypes.string,
};
export default NavigateIcon


