import React from 'react';
import CountdownTimer from 'shared/components/CountdownTimer/CountdownTimer';

interface Props {
  startTime: string;
}

const HandshakeCountdown: React.FC<Props> = ({ startTime }) => {
  return (
    <CountdownTimer
      durationHours={24}
      startTime={startTime}
      message="Time left to start the validation "
      tooltipMessage="You have 24 hours to complete the onboarding process before validation begins. Ensure all required information is provided before the countdown expires to avoid restarting the process."
    />
  );
};

export default HandshakeCountdown;
