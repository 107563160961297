import { useCallback, useState } from 'react';
import { b64toBlob } from 'shared/utils/strUtil';
import fileSaver from 'file-saver';

export type ExportRowsPayload = { rows: any[]; type: 'pdf' | 'csv' };

const useExportRows = (fileName: string, fetcher, onDone?: (data: any) => void) => {
  const [subjectToDownload, setSubjectToDownload] = useState<ExportRowsPayload | null>(null);

  const { refetch, isFetching } = fetcher({
    rows: subjectToDownload?.rows,
    type: subjectToDownload?.type,
    enabled: false,
  });

  const exportRows = useCallback(
    (p: ExportRowsPayload) => {
      setSubjectToDownload(p);
      setTimeout(() => {
        const fetchData = async () => {
          const { data } = await refetch();
          const subjectType = subjectToDownload?.type ? `-${subjectToDownload.type}` : '';
          const fileNameWithSubject = `${fileName || 'document'}${subjectType}.zip`;
          fileSaver.saveAs(b64toBlob(data), fileNameWithSubject);
          setSubjectToDownload(null);
          onDone?.(data);
        };
        fetchData();
      }, 500);
    },
    [refetch],
  );

  return { isExporting: isFetching, exportRows };
};

export default useExportRows;
