import React from 'react';
import PropTypes from 'prop-types';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { Routes as ROUTES } from 'shared/constants/routes';
import UnauthenticatedRoute from 'app/containers/App/components/UnauthenticatedRoute';
import AuthenticatedRoute from 'app/containers/App/components/AuthenticatedRoute';
import Layout from 'app/containers/Layout/index';
import AdminAccountStatus from 'admin/containers/AccountStatus';
import LogIn from 'users/containers/LogIn/Login';
import AccountPage from 'users/containers/Account';
import OrganizationPage from 'users/containers/Organization/Organization';
import FeatureFlagsPage from 'users/containers/FeatureFlags';
import AzureCustomersPage from 'divisions/containers/Customers/azureCustomers';
import AwsCustomersPage from 'divisions/containers/Customers/awsCustomers';
import BillingSummaryPage from 'divisions/containers/BilingSummary';
import CustomersHistory from 'divisions/containers/CustomersHistory';
import ManageCustomersPage from 'divisions/containers/ManageCustomers';
import CustomersReports from 'divisions/containers/Customers/reports';
import OnboardingWelcome from 'app/containers/OnboardingWelcome';
import OnboardingAws from 'app/containers/Onboarding/AwsOnboarding';
import OnboardingAzure from 'app/containers/Onboarding/AzureOnboarding';
import OnboardingGcp from 'app/containers/Onboarding/GcpOnboarding';
import OnboardingByod from 'app/containers/Onboarding/ByodOnboarding';
import NotFoundPage from 'app/containers/NotFound/index';
import WelcomePage from 'app/containers/Welcome/index';
import LandingPage from 'app/containers/Landing/Landing';
import NotAllowedPage from 'app/containers/NotAllowed/NotAllowed';
import Ec2UsagePage from 'usage/containers/Ec2Usage';
import RdsUsagePage from 'usage/containers/RdsUsage';
import S3UsagePage from 'usage/containers/S3Usage';
import Budget from 'usage/containers/Budget';
import AssetsPage from 'usage/containers/Assets';
import CostTracking from 'usage/containers/CostAndUsageExplorer';
import MetricsExplorerPage from 'usage/containers/MetricsExplorer';
import CustomDashboard from 'usage/containers/CustomDashboard';
import CustomDashboardsPanels from 'usage/containers/CustomDashboardsPanels';
import CustomDashboardWrapper from 'usage/containers/CustomDashboard/components/CustomDashboardWrapper';
import VirtualTags from 'usage/containers/VirtualTags';
import FilterGroup from 'usage/containers/FilterGroup';
import BusinessMapping from 'usage/containers/BusinessMapping';
import BusinessMappingGroups from 'usage/containers/BusinessMappingGroups';
import BusinessMappingGlobalGroups from 'usage/containers/BusinessMappingGlobalGroups';
import AnomalyDetection from 'usage/containers/AnomalyDetection';
import ResourceExplorer from 'usage/containers/ResourceExplorer';
import BigQuery from 'usage/containers/BigQuery';
import KubernetesDashboard from 'kubernetes/containers/KubernetesDashboard';
import KubernetesPreview from 'kubernetes/containers/KubernetesPreview';
import KubernetesPreferences from 'kubernetes/containers/Preferences';
import GcpAppEngine from 'usage/containers/GcpAppEngine';
import GcpCloudSQL from 'usage/containers/GcpCloudSQL';
import GcpComputeEngine from 'usage/containers/GcpComputeEngine';
import AzureVirtualMachines from 'usage/containers/AzureVirtualMachines/AzureVirtualMachines';
import AzureStorage from 'usage/containers/AzureStorage/AzureStorage';
import AzureDbCosts from 'usage/containers/AzureDbCosts/AzureDbCosts';
import AzureDataFactory from 'usage/containers/AzureDataFactory';
import Reports from 'usage/containers/Reports/index';
import SavingsPlansAnalyzer from 'commitment/containers/SpAnalyzer';
import RiUtilizationPage from 'commitment/containers/Commitments/RiUtilizationPage';
import CommitmentDashboardPage from 'commitment/containers/Dashboard/CommitmentDashboardPage';
import SavingsPlansUtilization from 'commitment/containers/Commitments/SpUtilizationPage';
import IdleInstancesPage from 'usage/containers/IdleInstances/index';
import CostAndUsageAlerts from 'usage/containers/Alerts/index';
import SlackLandingPage from 'shared/components/slackIntegration/SlackLandingPage';
import AwsBillingRules from 'divisions/containers/Customers/AwsBillingRules';
import AzureBillingRules from 'divisions/containers/Customers/AzureBillingRules';
import BillingRulesNew from 'divisions/containers/BillingRulesNew';
import RebillingPreferences from 'divisions/containers/Preferences';
import RecommendationTrial from 'recommendations/containers/TrialRecPage';
import AwsCreditsNew from 'divisions/containers/Credits/index';
import { checkTrialUser } from 'shared/components/CheckTrialUser';
import AdminControlPanel from 'admin-panel/index';
import { CostGpt } from 'cost-gpt';
import RecommendationsRouter from 'recommendationsNew/menuItems/router';
import SpAnalyzerNew from 'commitment/containers/spAnalyzerNew';
import { PermissionsContextProvider } from 'app/contexts/PermissionsContext';
import Views from 'usage/containers/Views';
import AccountTags from 'usage/containers/AccountTags';
import MainDashboardWrapper from 'app/containers/MainDashboard/MainDashbordWrapper.jsx';
import { useClientError } from 'usage/hooks/react-query/useClientError';
import { ErrorBoundary } from './components/ErrorBoundary';

const AuthenticatedRouter = ({ props: childProps }) => {
  // used for legacy class components
  const navigate = useNavigate();
  const location = useLocation();
  const { emitClientError } = useClientError();
  const { mutateAsync: handleEmitClientError } = emitClientError();

  const isAuthenticated = childProps?.isSessionStorageAuth?.();
  if (!isAuthenticated) {
    return <UnauthenticatedRoute component={LogIn} props={childProps} />;
  }
  // routes for authenticated user
  return (
    <PermissionsContextProvider usersStore={childProps.usersStore}>
      <div>
        <Layout childProps={childProps} />
        <ErrorBoundary handleEmitClientError={handleEmitClientError}>
          <div className="container__wrap">
            <Routes>
              <Route
                exact
                path={ROUTES.WELCOME}
                element={<AuthenticatedRoute component={WelcomePage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.LANDING}
                element={<AuthenticatedRoute component={LandingPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.NOT_ALLOWED}
                element={<AuthenticatedRoute component={NotAllowedPage} props={childProps} />}
              />
              <Route exact path={ROUTES.SLACK_INTEGRATION} element={<SlackLandingPage {...childProps} />} />
              {childProps.appStore.isKeyCloakManagement ? (
                <Route
                  path={`${ROUTES.ORGANIZATION}/*`}
                  element={<AuthenticatedRoute component={OrganizationPage} props={childProps} />}
                />
              ) : (
                <Route
                  path={`${ROUTES.ACCOUNT}`}
                  element={<AuthenticatedRoute component={AccountPage} props={childProps} />}
                />
              )}
              <Route
                exact
                path={ROUTES.FEATURE_FLAGS}
                element={<AuthenticatedRoute component={FeatureFlagsPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ACCOUNTS_OVERVIEW}
                element={<AuthenticatedRoute component={OnboardingWelcome} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ONBOARDING_AWS}
                element={<AuthenticatedRoute component={OnboardingAws} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ONBOARDING_AZURE}
                element={<AuthenticatedRoute component={OnboardingAzure} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ONBOARDING_GCP}
                element={<AuthenticatedRoute component={OnboardingGcp} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ONBOARDING_BYOD}
                element={<AuthenticatedRoute component={OnboardingByod} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.DASHBOARD}
                element={<AuthenticatedRoute component={MainDashboardWrapper} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.EC2}
                element={<AuthenticatedRoute component={Ec2UsagePage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.RDS}
                element={<AuthenticatedRoute component={RdsUsagePage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.S3}
                element={<AuthenticatedRoute component={S3UsagePage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.REPORTS}
                element={<AuthenticatedRoute component={Reports} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.RI_UTILIZATION}
                element={<AuthenticatedRoute component={RiUtilizationPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.COMMITMENT_DASHBOARD}
                element={<AuthenticatedRoute component={CommitmentDashboardPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.SAVINGS_PLANS_UTILIZATION}
                element={<AuthenticatedRoute component={SavingsPlansUtilization} props={childProps} />}
              />
              <Route
                path={`${ROUTES.RECOMMENDATIONS}/*`}
                element={
                  <AuthenticatedRoute
                    component={checkTrialUser(RecommendationsRouter, RecommendationTrial, childProps)}
                    props={childProps}
                  />
                }
              />
              <Route
                exact
                path={ROUTES.BUDGET}
                element={<AuthenticatedRoute component={Budget} props={childProps} remountKey={() => ROUTES.BUDGET} />}
              />
              <Route
                exact
                path={ROUTES.COST_USAGE_EXPLORER}
                element={
                  <AuthenticatedRoute
                    component={CostTracking}
                    remountKey={() => ROUTES.COST_USAGE_EXPLORER}
                    blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                    observeResources={[
                      (prop) => prop.invoiceStore.isDistinctValuesLoading,
                      (prop) => prop.appStore.isPpApplied,
                    ]}
                    props={{ ...childProps, navigate, location }}
                  />
                }
              />
              <Route
                exact
                path={ROUTES.METRICS_EXPLORER}
                element={<AuthenticatedRoute component={MetricsExplorerPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOM_DASHBOARD_INSTANCE}
                element={<AuthenticatedRoute component={CustomDashboardWrapper} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOM_DASHBOARD_TEMPLATE_INSTANCE}
                element={
                  <AuthenticatedRoute component={CustomDashboardWrapper} props={{ ...childProps, isTemplate: true }} />
                }
              />
              <Route
                exact
                path={ROUTES.CUSTOM_DASHBOARD}
                element={<AuthenticatedRoute component={CustomDashboard} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOM_DASHBOARDS_PANELS}
                element={<AuthenticatedRoute component={CustomDashboardsPanels} props={childProps} />}
              />
              <Route exact path={ROUTES.VIEWS} element={<AuthenticatedRoute component={Views} props={childProps} />} />
              <Route
                exact
                path={ROUTES.VIRTUAL_TAGS}
                element={<AuthenticatedRoute component={VirtualTags} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.BUSINESS_MAPPING_GLOBAL_GROUPS}
                element={<AuthenticatedRoute component={BusinessMappingGlobalGroups} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.BUSINESS_MAPPING_GROUPS}
                element={<AuthenticatedRoute component={BusinessMappingGroups} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.BUSINESS_MAPPING}
                element={<AuthenticatedRoute component={BusinessMapping} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ACCOUNT_TAGS}
                element={<AuthenticatedRoute component={AccountTags} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.FILTER_GROUP}
                element={<AuthenticatedRoute component={FilterGroup} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ANOMALY_DETECTION}
                element={
                  <AuthenticatedRoute
                    component={AnomalyDetection}
                    props={childProps}
                    remountKey={() => ROUTES.ANOMALY_DETECTION}
                  />
                }
              />
              <Route
                exact
                path={ROUTES.RESOURCE_EXPLORER}
                element={<AuthenticatedRoute component={ResourceExplorer} props={{ ...childProps, location }} />}
              />
              <Route
                exact
                path={ROUTES.SAVINGS_PLANS_ANALYZER}
                element={
                  <AuthenticatedRoute
                    component={checkTrialUser(SavingsPlansAnalyzer, RecommendationTrial, childProps)}
                    props={childProps}
                  />
                }
              />
              <Route
                exact
                path={ROUTES.SAVINGS_PLANS_ANALYZER_NEW}
                element={<AuthenticatedRoute component={SpAnalyzerNew} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.BIG_QUERY}
                element={<AuthenticatedRoute component={BigQuery} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.GcpAppEngine}
                element={<AuthenticatedRoute component={GcpAppEngine} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.GcpCloudSQL}
                element={<AuthenticatedRoute component={GcpCloudSQL} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.GcpComputeEngine}
                element={<AuthenticatedRoute component={GcpComputeEngine} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AZURE_VIRTUAL_MACHINES}
                element={<AuthenticatedRoute component={AzureVirtualMachines} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AZURE_STORAGE}
                element={<AuthenticatedRoute component={AzureStorage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AZURE_DB_COSTS}
                element={<AuthenticatedRoute component={AzureDbCosts} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AzureDataFactory}
                element={<AuthenticatedRoute component={AzureDataFactory} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.K8S_PREVIEW}
                element={<AuthenticatedRoute component={KubernetesPreview} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.K8S_USAGE}
                element={
                  <AuthenticatedRoute
                    component={KubernetesDashboard}
                    remountKey={() => ROUTES.K8S_USAGE}
                    blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                    observeResources={[
                      (prop) => prop.invoiceStore.isDistinctValuesLoading,
                      (prop) => prop.appStore.isPpApplied,
                    ]}
                    props={{ ...childProps, location, navigate }}
                  />
                }
              />
              <Route
                exact
                path={ROUTES.K8S_PREFERENCES}
                element={<AuthenticatedRoute component={KubernetesPreferences} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.K8S_ASSETS}
                element={
                  <AuthenticatedRoute
                    component={AssetsPage}
                    blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                    observeResources={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                    props={{
                      ...childProps,
                      isK8S: true,
                      location,
                    }}
                  />
                }
              />
              <Route
                exact
                path={ROUTES.AZURE_CUSTOMERS}
                element={<AuthenticatedRoute component={AzureCustomersPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AWS_CUSTOMERS}
                element={<AuthenticatedRoute component={AwsCustomersPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.BILLING_SUMMARY}
                element={<AuthenticatedRoute component={BillingSummaryPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOMERS_HISTORY}
                element={<AuthenticatedRoute component={CustomersHistory} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOMERS_MANAGE}
                element={<AuthenticatedRoute component={ManageCustomersPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.CUSTOMERS_REPORTS}
                element={<AuthenticatedRoute component={CustomersReports} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.BILLING_RULES_NEW}
                element={<AuthenticatedRoute component={BillingRulesNew} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AWS_BILLING_RULES}
                element={<AuthenticatedRoute component={AwsBillingRules} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AZURE_BILLING_RULES}
                element={<AuthenticatedRoute component={AzureBillingRules} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.REBILLING_PREFERENCES}
                element={<AuthenticatedRoute component={RebillingPreferences} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.AWS_CREDITS_NEW}
                element={<AuthenticatedRoute component={AwsCreditsNew} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.IDLE_INSTANCES}
                element={<AuthenticatedRoute component={IdleInstancesPage} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.CUE_ALERTS}
                element={<AuthenticatedRoute component={CostAndUsageAlerts} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ADMIN_ACCOUNTS_STATUS}
                element={<AuthenticatedRoute component={AdminAccountStatus} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.ASSETS}
                element={
                  <AuthenticatedRoute
                    component={AssetsPage}
                    props={{ ...childProps, location }}
                    blockResource={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                    observeResources={[(prop) => prop.invoiceStore.isDistinctValuesLoading]}
                  />
                }
              />
              <Route
                exact
                path={ROUTES.ADMIN_CONTROL_PANEL}
                element={<AuthenticatedRoute component={AdminControlPanel} props={childProps} />}
              />
              <Route
                exact
                path={ROUTES.COST_GPT}
                element={<AuthenticatedRoute component={CostGpt} props={childProps} />}
              />

              {/* Finally, catch all unmatched routes */}
              <Route path="*" Component={NotFoundPage} props={childProps} />
            </Routes>
          </div>
        </ErrorBoundary>
      </div>
    </PermissionsContextProvider>
  );
};

export default AuthenticatedRouter;

AuthenticatedRouter.propTypes = {
  props: PropTypes.shape({
    isSessionStorageAuth: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
    appStore: PropTypes.shape({
      isKeyCloakManagement: PropTypes.bool.isRequired,
      isPpApplied: PropTypes.bool.isRequired,
    }).isRequired,
    invoiceStore: PropTypes.shape({
      isDistinctValuesLoading: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
};
