import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import Button from 'shared/components/andtComponents/Button';
import Input from 'shared/components/andtComponents/Input';
import { ReactComponent as MSTeamsIcon } from 'shared/img/icons/ms-teams.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from 'shared/components/andtComponents/Modal';
import styles from './AddMSTeamsChannelModal.module.scss';

export default function AddMSTeamsChannelModal({ selectedChannel = null, onClose }) {
  const [channelToAdd, setChannelToAdd] = useState({
    channelType: 'MS_TEAMS',
    connectionData: { ...selectedChannel?.connectionData, type: 'MS_TEAMS' },
  });
  const [isConnectionValid, setIsConnectionValid] = useState(true);

  const { createChannel, testRecipients } = useChannels();
  const { mutateAsync: handleTestRecipients, isLoading: isTestingRecipients } = testRecipients({
    onSuccess: () => setIsConnectionValid(true),
    onError: () => setIsConnectionValid(false),
  });
  const { mutateAsync: handleCreateChannel, isLoading: isCreatingChannel } = createChannel({
    onError: () => setIsConnectionValid(false),
  });

  return (
    <CustomModal
      open
      title="Add Microsoft Teams Channel"
      headerMode="add"
      headerIcon={<MSTeamsIcon height={36} width={36} />}
      onClose={onClose}
      saveDisabled={!channelToAdd?.name || !channelToAdd?.connectionData?.baseUrl}
      saveTitle={
        <>
          <GenerateIcon iconName={ICONS.plus.name} /> Add Channel
        </>
      }
      onSave={() => handleCreateChannel(channelToAdd)}
      isLoading={isCreatingChannel}
      className={styles.addMSTeamsChannelModal}
      footerBorder
    >
      <div className={styles.newConnectionContainer}>
        <label htmlFor="ms-teams-name">
          Name
          <Input
            value={channelToAdd?.name || ''}
            onChange={(e) =>
              setChannelToAdd({
                ...channelToAdd,
                name: e.target.value,
              })
            }
            id="ms-teams-name"
            type="text"
            placeholder="Enter Name"
          />
        </label>
        <label htmlFor="ms-teams-url">
          Webhook URL
          <Input
            value={channelToAdd?.connectionData?.baseUrl || ''}
            onChange={(e) =>
              setChannelToAdd({
                ...channelToAdd,
                connectionData: { ...channelToAdd?.connectionData, baseUrl: e.target.value },
              })
            }
            id="ms-teams-url"
            type="text"
            placeholder="Enter Webhook URL"
          />
        </label>
        <div className={styles.testButtonContainer}>
          <Button
            text="Test Connection"
            onClick={() => {
              handleTestRecipients({
                managerRequest: {
                  ...channelToAdd,
                },
              });
            }}
            icon={() => <GenerateIcon iconName={ICONS.hexagonCheck.name} />}
            isGhost
            disabled={!channelToAdd?.connectionData?.baseUrl || !channelToAdd?.name}
            isLoading={isTestingRecipients}
          />
          {!isConnectionValid && (
            <div className={styles.errorText}>Unable to connect. Verify your connection data.</div>
          )}
        </div>
      </div>
    </CustomModal>
  );
}

AddMSTeamsChannelModal.propTypes = {
  selectedChannel: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
