import React, { useState } from 'react';
import Modal from 'shared/components/andtComponents/Modal';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { ReactComponent as LogoBadExample } from './assets/logo-bad-example.svg';
import { ReactComponent as LogoExample } from './assets/logo-example.svg';
import Input from 'shared/components/andtComponents/Input';
import styles from './AddVendorModal.module.scss';

interface Vendor {
  vendorName: string;
  vendorLogoUrl: string | null;
}

interface AddVendorModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (vendor: Vendor) => Promise<void>;
  vendorToEdit?: Vendor;
}

export const AddVendorModal: React.FC<AddVendorModalProps> = ({ isOpen, onClose, onSave, vendorToEdit }) => {
  const [vendor, setVendor] = useState<Vendor>({
    vendorName: '',
    vendorLogoUrl: null,
    ...vendorToEdit,
  });

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      title={vendorToEdit ? 'Edit vendor' : 'Add vendor to list'}
      onSave={() => onSave(vendor)}
      closeOnSave={true}
      footerDisabled={false}
      isLoading={false}
    >
      <div className={styles.addVendorModal}>
        <div>
          <label htmlFor="vendorName">
            <GenerateIcon iconName={ICONS.comment.name} />
            Vendor
          </label>
          <Input
            id="vendorName"
            name="vendorName"
            placeholder="Enter Name"
            value={vendor.vendorName}
            onChange={(e) =>
              setVendor({
                ...vendor,
                vendorName: e.target.value,
              })
            }
          />
        </div>
        <div className={styles.disabled}>
          <label htmlFor="vendorType">
            <GenerateIcon iconName={ICONS.upload.name} />
            Upload Logo <span className={styles.optional}>(Coming soon)</span>
          </label>
          <Input id="vendorType" name="vendorType" placeholder="Upload logo" type="file" disabled />
          <ul>
            <li>The logo file should be a PNG, JPG, or GIF format.</li>
            <li>The maximal file size is 300KB.</li>
            <li>
              Logo perspectives should be <span>1x1 ratio</span>. Typically, a logo has a minimized version that solely
              contains an icon, this version will work best here, see example below:
            </li>
          </ul>
          <div className={styles.logoExamples}>
            <LogoBadExample />
            <LogoExample />
            <span>
              <GenerateIcon iconName={ICONS.circleExclamation.name} className={styles.red} /> Not recommended
            </span>
            <span>
              <GenerateIcon iconName={ICONS.circleCheckSolid.name} className={styles.green} /> Recommended
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};
