import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import { CategoryAction, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import IconFromPng from 'shared/components/IconFromPng';
import ICONS from 'shared/constants/assetsConstants';
import { PageNames } from 'shared/constants/appConstants';
import ReportModal from 'shared/components/reportModal/ReportModal';
import {
  ALL_CUSTOMERS,
  CUSTOMER_REPORT_MODAL_TYPE,
  CUSTOMER_REPORT_TYPES,
} from 'divisions/containers/Customers/reports/constants/customerReportsConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import CustomerReportContent from 'shared/components/reportModal/CustomerReportContent';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import Spinner from 'shared/components/andtComponents/Spinner';
import { propComparator } from 'shared/utils/sortUtil';
import CustomerReportsTable from 'divisions/containers/Customers/reports/components/CustomerReportsTable';
import useCustomerReports from 'divisions/hooks/react-query/useCustomerReports';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { getEmailFromRecipients, prepareNewRecipients } from 'shared/utils/sharedUtils';
import ShareEntityModal from '~/shared/components/ShareEntityModal/ShareEntityModal.jsx';

const CreateReportButton = ({ handler }) => (
  <Row className="dotted_box">
    <div className="dotted_box-title">
      <span>Create Report</span>
    </div>
    <br />
    <div>
      <LinkIconButton>
        <button type="button" onClick={(e) => handler(e)} className="btn-no-style" />
        <IconFromPng size="auto" icon={ICONS.plus} />
      </LinkIconButton>
    </div>
  </Row>
);

CreateReportButton.propTypes = {
  handler: PropTypes.func.isRequired,
};

const CustomerReportsPanel = ({ usersStore, getPageFilters }) => {
  const [editReport, setEditReport] = useState({});
  const [currDeleteReport, setCurrDeleteReport] = useState(null);
  const [shareReport, setShareReport] = useState(null);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const {
    fetchCustomerReports,
    updateCustomerReportMutation,
    deleteCustomerReportMutation,
    addCustomerReportMutation,
    sendCustomerReportMutation,
  } = useCustomerReports();
  const { data: reports = [], isFetching: isReportsLoading } = fetchCustomerReports();
  const getCustomersOptions = () => {
    let options = [];
    const pageFilters = getPageFilters(PageNames.CUSTOMERS_REPORTS, usersStore.currDispUserCloudAccountType);
    if (pageFilters.has('division')) {
      const tempOptions = pageFilters.get('division').map((fieldValue) => {
        const value = fieldValue.divisionName;
        const label = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', value);
        return { value, label };
      });
      options = JSON.parse(JSON.stringify(tempOptions));
    }
    options.sort(propComparator('label')).unshift(ALL_CUSTOMERS);
    return options;
  };

  const formatReportForEdit = (data) => ({
    name: data.reportName,
    email: getEmailFromRecipients(data.recipients),
    recipients: data.recipients,
    reportFreq: data.frequency,
    frequencyDeliveryTime: data.deliveryTime,
    customFrequencyInDays: data.customFrequencyInDays,
    firstDate: data.firstDate,
    reportTypes: data.reportTypes,
    period: data.period,
    id: data.uuid,
    customers: data.customers,
  });

  const openEditModal = (id) => {
    const data = reports.find((r) => r.uuid === id) || {};
    if (!data) {
      return;
    }
    const reportData = formatReportForEdit(data);
    setEditReport(reportData);
  };

  const formatReportForServer = (data) => ({
    reportName: data.reportName.value,
    email: data.emails.value,
    recipients: prepareNewRecipients({ email: data.emails.value, recipients: data.recipients }),
    frequency: data.delivery.frequency,
    period: data.period,
    customers: data.customers, // need to format
    customFrequencyInDays: data.delivery.customFrequency,
    firstDate: data.delivery.customStartDate,
    reportTypes:
      data.type === CUSTOMER_REPORT_TYPES.BOTH ? [CUSTOMER_REPORT_TYPES.CSV, CUSTOMER_REPORT_TYPES.PDF] : [data.type],
    deliveryTime: data.delivery.time,
    uuid: editReport.id,
    userKey: usersStore.getCurrUserKey,
  });

  const onSave = async (data) => {
    const reportData = formatReportForServer(data);
    setEditReport({});
    setIsNewModalOpen(false);
    if (reportData.uuid) {
      await updateCustomerReportMutation.mutate(reportData);
    } else {
      await addCustomerReportMutation.mutate(reportData);
    }
  };

  const deleteReportHandler = async (action) => {
    if (action === 'cancel') {
      setCurrDeleteReport(null);
    } else if (action === 'delete') {
      try {
        await deleteCustomerReportMutation.mutateAsync({ reportId: currDeleteReport.id });
        setCurrDeleteReport(null);
      } catch {
        setCurrDeleteReport(null);
      }
    }
  };
  const handleDeleteReportClicked = async (reportId) => {
    const { reportName } = reports.find((r) => r.uuid === reportId) || {};
    setCurrDeleteReport({ id: reportId, name: reportName });
  };

  const onSend = async (data) => {
    let reportData = null;
    if (data.reportName) {
      reportData = formatReportForServer(data);
    } else {
      reportData = reports.find((r) => r.uuid === data) || {};
    }
    await sendCustomerReportMutation.mutate(reportData);
  };
  const isLoading =
    deleteCustomerReportMutation.isLoading ||
    addCustomerReportMutation.isLoading ||
    updateCustomerReportMutation.isLoading ||
    isReportsLoading;
  return isLoading ? (
    <Spinner />
  ) : (
    <div>
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={HierarchicalEntityCategory.Reports}
        action={CategoryAction.Create}
      >
        <Row>
          <Col xs={12} md={12} lg={12} xl={12}>
            <CreateReportButton handler={() => setIsNewModalOpen(true)} />
            <CustomerReportsTable
              updateReportHandler={openEditModal}
              shareHandler={setShareReport}
              deleteHandler={handleDeleteReportClicked}
              sendReportHandler={onSend}
              reports={reports}
            />
            <ReportModal
              onClose={() => {
                setEditReport({});
                setIsNewModalOpen(false);
              }}
              modalType={CUSTOMER_REPORT_MODAL_TYPE}
              isOpen={isNewModalOpen || editReport.id}
              onSave={onSave}
              onSend={onSend}
              usersStore={usersStore}
              title="Customer report"
              data={{ ...editReport, customersOptions: getCustomersOptions() }}
            >
              <CustomerReportContent />
            </ReportModal>
          </Col>
          <DeleteWarningModal
            deletedItemName={currDeleteReport && currDeleteReport.name}
            isOpen={currDeleteReport && currDeleteReport.id}
            handleDelete={deleteReportHandler}
            warningMessage="Be advise you are about to delete "
            modalTitle="Delete Report"
          />
        </Row>
      </ReadOnlyDisplayWrapper>
      {shareReport && (
        <ShareEntityModal
          entity={HierarchicalEntityCategory.Reports}
          entityId={shareReport}
          onClose={() => {
            setShareReport(null);
          }}
        />
      )}
    </div>
  );
};

CustomerReportsPanel.propTypes = {
  usersStore: PropTypes.object.isRequired,
  getPageFilters: PropTypes.func.isRequired,
};

export default withInvoiceFiltersContextConsumer(CustomerReportsPanel);
