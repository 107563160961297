import React from 'react';
import { getCloudTypeSquareIcon } from 'app/store/vendorsStore/vendorStoreProvider';
import styles from '../CloudAccounts.module.scss';

interface CloudChipProps {
  chip: {
    cloudTypeId: number;
    selected: boolean;
    text: string;
  };
  onClick: () => void;
}
export const CloudChip: React.FC<CloudChipProps> = ({
  chip,
  onClick,
}: {
  chip: { cloudTypeId: number; selected: boolean; text: string };
  onClick: () => void;
}) => {
  const { cloudTypeId, selected, text } = chip;

  const Icon = getCloudTypeSquareIcon(cloudTypeId);
  return (
    <div onClick={onClick} className={`${styles.cloudCounter} ${selected ? styles.selectedOption : styles.unselected}`}>
      <Icon />
      <span>{text}</span>
    </div>
  );
};
