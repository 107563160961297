import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { toast } from 'react-toastify';

const root = '/api/v1/integrations/slack';

const getSlackInstallUrl = () => API.get('billings', `${root}/install`);
const getSlackChannels = () => API.get('billings', `${root}/channels`);

export default function useSlack() {
  return {
    getSlackInstallUrl: (options = {}) =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.SLACK, 'install'],
        queryFn: getSlackInstallUrl,
        onError: () =>
          toast.error('Error adding slack', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
    getSlackChannels: (options = {}) =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.SLACK, 'channels'],
        queryFn: getSlackChannels,
        onError: () =>
          toast.error('Error fetching slack channels', {
            position: toast.POSITION.BOTTOM_RIGHT,
          }),
        staleTime: 10 * 60 * 1000,
        ...options,
      }),
  };
}
