import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, SortingState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { getColumns, getDefaultSorting, getSortingColumns, SHARED_ENTITIES_COLUMNS } from '../../../consts';
import SharedRoles from './SharedRoles';

import styles from './SharedEntitiesModal.module.scss';

export const SharedEntitiesNoData = (users) => {
  if (!users) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <span className={styles.mainText}>No shared Entities for this role</span>
    </div>
  );
};

const SharedEntitiesModalTable = ({ partialPermissions }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(SHARED_ENTITIES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  return (
    partialPermissions && (
      <div className={styles.sharedEntitiesContainer}>
        <Grid
          rows={partialPermissions.filter((row) => row.entityName)}
          columns={getColumns(SHARED_ENTITIES_COLUMNS)}
          automationid="add-entities-to-role-list"
        >
          <SortingState
            columnExtensions={getSortingColumns(SHARED_ENTITIES_COLUMNS)}
            defaultSorting={getDefaultSorting(SHARED_ENTITIES_COLUMNS)}
          />
          <DataTypeProvider
            for={[SHARED_ENTITIES_COLUMNS.ENTITY_NAME.columnName]}
            formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          />
          <DataTypeProvider
            for={[SHARED_ENTITIES_COLUMNS.SHARED_WITH_ROLE.columnName]}
            formatterComponent={SharedRoles}
          />
          <TableWrapper virtual noDataCellComponent={() => SharedEntitiesNoData(partialPermissions)} height={400} />
          <TableColumnResizing
            resizingMode="nextColumn"
            defaultColumnWidths={localColumnsWidth}
            onColumnWidthsChange={setLocalColumnsWidth}
          />
          <TableHeaderRow cellComponent={CustomHeaderCell} />
        </Grid>
      </div>
    )
  );
};

SharedEntitiesModalTable.propTypes = {
  partialPermissions: PropTypes.array.isRequired,
};

export default SharedEntitiesModalTable;
