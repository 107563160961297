/* eslint-disable react-hooks/rules-of-hooks */
import { getKeycloakToken, refreshKeycloakToken } from 'shared/keycloak/keycloak.service';
import { renewToken } from 'users/api/usersApi';
import { API } from 'shared/utils/apiMiddleware';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const TOKEN_REFRESH_THRESHOLD_MS = 2000;

export const RE_CUSTOMER_ORG_TOKEN = 'reCustomerOrgToken';
export const REFRESH_RE_CUSTOMER_ORG_TOKEN = 'refreshReCustomerOrgToken';

export const getImpersonationToken = async ({ userId, orgUuid }) => {
  return API.post('billings', `/api/v1/user-management/tokens/impersonate`, {
    body: { userId, orgUuid },
  });
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

export const getValueFromStorage = (key) => {
  return window.sessionStorage.getItem(key) || window.localStorage.getItem(key);
};

export const useSessionStorage = () => {
  const authToken = getValueFromStorage('authToken');
  const authTokenPayload = parseJwt(authToken);
  return authTokenPayload['custom:useSessionStorage'] === '1';
};

export const saveResellerCustomerTokenInLocalStorage = (token, refreshToken) => {
  if (token) {
    window.sessionStorage.setItem(RE_CUSTOMER_ORG_TOKEN, token);
    window.sessionStorage.setItem(REFRESH_RE_CUSTOMER_ORG_TOKEN, refreshToken);
  }
};

export const removeResellerCustomerTokenFromLocalStorage = () => {
  window.sessionStorage.removeItem(RE_CUSTOMER_ORG_TOKEN);
  window.sessionStorage.removeItem(REFRESH_RE_CUSTOMER_ORG_TOKEN);
};

export const setLocalStorage = (key, value, isUseSessionStorage = false) => {
  if (isUseSessionStorage) {
    window.sessionStorage.setItem(key, value);
  } else {
    window.localStorage.setItem(key, value);
  }
};

const getSessionTimeLeft = (tokenPayload) => {
  try {
    const exp = tokenPayload.exp * 1000; // seconds to milliseconds
    const now = Date.now();
    return exp - now;
  } catch {
    return 0;
  }
};

export const checkIfTokenRefreshNeeded = async () => {
  const refreshToken = getValueFromStorage('refreshToken');
  const authToken = getValueFromStorage('authToken');
  if (!authToken || !refreshToken) {
    // Unauthenticated user
    return false;
  }
  try {
    const authTokenPayload = parseJwt(authToken);
    const isCognitoToken = authTokenPayload.iss.startsWith(ExternalLinks.CognitoIdpPrefix);
    const timeLeft = getSessionTimeLeft(authTokenPayload);
    if (refreshToken && timeLeft <= TOKEN_REFRESH_THRESHOLD_MS) {
      const username = window.sessionStorage.getItem('username') || window.localStorage.getItem('username');
      // call refresh token api
      let newToken;
      if (isCognitoToken && username) {
        newToken = await renewToken(username, refreshToken);
      } else if (!isCognitoToken) {
        await refreshKeycloakToken();
        const keyccloakToken = getKeycloakToken();
        newToken = { jwtToken: keyccloakToken };
      }
      const { jwtToken: authToken } = newToken || {};
      const decoded = parseJwt(authToken);
      setLocalStorage('authToken', authToken, decoded['custom:useSessionStorage'] === '1');
      return true;
    }
    if (!isCognitoToken) {
      const impersonationToken = getValueFromStorage('impersonationToken');
      const impersonationTokenPayload = impersonationToken ? parseJwt(impersonationToken) : {};
      if (getSessionTimeLeft(impersonationTokenPayload) <= TOKEN_REFRESH_THRESHOLD_MS) {
        const { accessToken } = await getImpersonationToken({ userId: impersonationTokenPayload.sub });

        setLocalStorage('impersonationToken', accessToken, useSessionStorage());
      }
    }
  } catch {
    // if refresh token fails, remove refresh tokens and logout
    window.localStorage.removeItem('authToken');
    window.localStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('authToken');
    window.sessionStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem(RE_CUSTOMER_ORG_TOKEN);
    window.sessionStorage.removeItem(REFRESH_RE_CUSTOMER_ORG_TOKEN);
    window.handleLogout();
  }
  return false;
};
