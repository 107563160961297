import React from 'react';
import CostPanelsWrapper from 'app/containers/MainDashboard/components/CostPanelsWrapper.jsx';
import MtdTop10 from 'app/containers/MainDashboard/panels/MtdTop10/MtdTop10.jsx';
import HistoryWithForecast from 'app/containers/MainDashboard/panels/HistoryWithForecast/HistoryWithForecast.jsx';
import HistoryMonthlyWithForecast from 'app/containers/MainDashboard/panels/HistoryWithForecast/HistoryMonthlyWithForecast.jsx';
import RegionCost from 'app/containers/MainDashboard/panels/RegionCost.jsx';
import MainDashboardHeader from 'app/containers/MainDashboard/components/MainDashboardHeader.jsx';
import MonitoringDataWrapper from 'app/containers/MainDashboard/panels/MonitoringData/MonitoringDataWrapper.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import styles from './mainDashboard.module.scss';

const MainDashboard = () => {
  const { usersStore } = useRootStore();

  return (
    <div className={styles.dashboardContainer}>
      <MainDashboardHeader />
      <CostPanelsWrapper />
      <HistoryWithForecast />
      <HistoryMonthlyWithForecast />
      <MtdTop10 includeChart={!usersStore.isCurrentAccountSingle} />
      {usersStore.isCurrentAccountSingle && <MonitoringDataWrapper />}
      {usersStore.isCurrentAccountSingle && <RegionCost />}
    </div>
  );
};

export default MainDashboard;
