import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  TableRowDetail,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedSelection,
  RowDetailState,
  SelectionState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import {
  FILTER_NUMBER_OR_DATE_OPERATIONS,
  FILTER_OPERATIONS,
} from 'shared/components/andtComponents/TableComponents/const';
import {
  CustomFilterCell,
  CustomFilterIcon,
  CustomFilterRow,
  CustomFormatterWithTooltipAndComponent,
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import UsersIcon from './UsersIcon';
import { useUsersContext } from './contexts/usersContext';
import {
  calculateColumnWidths,
  FILTER_OPERATIONS_MAPPING,
  GET_USERS_QUERY_PARAMS,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
  USERS_COLUMNS,
} from '../consts';
import UsersBulkOperations from './UsersBulkOperations';
import UserExpandedData from './usersExpandedRowComponents/UserExpandedData';
import UserActions from './UserActions';
import UserImpersonate from './usersTableComponents/UserImpersonate';
import UserStatusSwitchButton from '../components/UserStatusSwitchButton';
import UserRoles from './usersTableComponents/UserRoles';
import UsersNoData from 'users/containers/Organization/components/EmptyStates/UsersNoData';
import Spinner from 'shared/components/andtComponents/Spinner';
import { useRootStore } from 'app/contexts/RootStoreContext';

import styles from './UsersTable.module.scss';

const UsersTable = ({ users, isLoading }) => {
  const { usersStore } = useRootStore();
  const [localColumnsWidth, setLocalColumnsWidth] = useState();

  const { isCustomersView, selectedCustomer } = useUsersContext();

  useEffect(() => {
    const resizeFunction = () => calculateColumnWidths(setLocalColumnsWidth, Object.values(USERS_COLUMNS));
    resizeFunction();
    window.addEventListener('resize', resizeFunction);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', resizeFunction);
  }, []);

  const rightAlignedColumns = [
    USERS_COLUMNS.USER_STATUS.columnName,
    USERS_COLUMNS.IMPERSONATE.columnName,
    USERS_COLUMNS.ACTIONS.columnName,
  ];

  const { NewTableRow, NewTableSelectionBar, CustomToggleCell } = useTable();

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const { showFilters, setTableFilters, selectedRows, setSelectedRows, isAllExpanded, setIsAllExpanded, tableFilters } =
    useUsersContext();

  const updateFilters = (filters) => {
    const tempFilters = {};
    filters.forEach((f) => {
      tempFilters[f.columnName] = f.value;
      if (
        f.columnName === GET_USERS_QUERY_PARAMS.CREATED_AT.name ||
        f.columnName === GET_USERS_QUERY_PARAMS.LAST_LOGIN.name
      ) {
        tempFilters[`${f.columnName}FilterType`] = Object.values(FILTER_OPERATIONS_MAPPING).find(
          (operation) => operation.id === f.operation,
        ).apiKey;
      }
    });
    setTableFilters({ ...tableFilters, ...tempFilters });
  };

  const debouncedSetFilters = useMemo(() => {
    const setFiltersFunction = (newFilters) => {
      updateFilters(newFilters);
    };

    return debounce((newFilters) => {
      setFiltersFunction(newFilters);
    }, 1500);
  }, []);

  const onFiltersChange = (newFilters) => {
    debouncedSetFilters(newFilters);
  };

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(users?.map((row, index) => index));
    }
  }, [isAllExpanded]);

  useEffect(() => {
    if (expandedRowIds?.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds]);

  const isCurrentUserRow = (user) => {
    return user.id === usersStore.currentDisplayedUserKey;
  };

  const handleSelectionChange = (newSelection) => {
    const hiddenSelectedUsers = selectedRows.filter(
      (selectedUser) => users.find((user) => user.id === selectedUser.id) === undefined,
    );
    const visibleSelectedUsers = selectedRows.filter(
      (selectedUser) => users.find((user) => user.id === selectedUser.id) !== undefined,
    );
    const newSelectedUsers = newSelection
      ?.filter((index) => users[index]?.id !== usersStore.currentDisplayedUserKey)
      ?.map((index) => users[index]);

    const resultSelectedRows = [...hiddenSelectedUsers];
    const isSameSelection =
      visibleSelectedUsers.length === newSelectedUsers?.length &&
      visibleSelectedUsers.every((selectedRowUser) => newSelectedUsers?.find((u) => selectedRowUser?.id === u?.id));

    if (!isSameSelection) {
      resultSelectedRows.push(...newSelectedUsers);
    }

    setSelectedRows(resultSelectedRows);
  };

  const selectedIndexes = useMemo(
    () => selectedRows.map((user) => users?.findIndex((u) => u.id === user?.id)).filter((index) => index !== -1),
    [selectedRows, users],
  );

  const UserStatusSwitchButtonWrapper = ({ row }) => (
    <UserStatusSwitchButton row={row} isViewOnly={isCurrentUserRow(row)} />
  );

  const CustomSelectionCellWrapper = (props) => (
    <CustomSelectionCell {...props} isDisabled={isCurrentUserRow(props?.row)} />
  );

  return isLoading ? (
    <Spinner />
  ) : (
    users && (
      <Grid
        rows={users}
        columns={getColumns(USERS_COLUMNS, usersStore.isCurrentUserReseller)}
        automationid="users-list"
      >
        <SortingState
          columnExtensions={getSortingColumns(USERS_COLUMNS)}
          defaultSorting={getDefaultSorting(USERS_COLUMNS)}
        />
        {showFilters && <FilteringState onFiltersChange={onFiltersChange} />}
        <SelectionState selection={selectedIndexes} onSelectionChange={handleSelectionChange} />
        <IntegratedSelection />
        <RowDetailState expandedRowIds={expandedRowIds} onExpandedRowIdsChange={setExpandedRowIds} />
        <DataTypeProvider
          for={[USERS_COLUMNS.USER_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
              <UsersIcon />
            </CustomFormatterWithTooltipAndComponent>
          )}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.FIRST_NAME.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.LAST_NAME.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.ROLES.columnName]}
          formatterComponent={UserRoles}
          availableFilterOperations={[FILTER_OPERATIONS.CONTAINS]}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.createdAt ? moment(props?.row?.createdAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.LAST_LOGIN.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.lastLogin ? moment(props?.row?.lastLogin).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[USERS_COLUMNS.USER_STATUS.columnName]}
          formatterComponent={UserStatusSwitchButtonWrapper}
        />
        <DataTypeProvider for={[USERS_COLUMNS.IMPERSONATE.columnName]} formatterComponent={UserImpersonate} />
        <DataTypeProvider for={[USERS_COLUMNS.ACTIONS.columnName]} formatterComponent={UserActions} />
        <TableWrapper
          virtual
          noDataCellComponent={(props) => (
            <UsersNoData
              {...props}
              mainText={isCustomersView && !selectedCustomer?.name ? 'Customer not selected' : null}
              subTexts={
                isCustomersView && !selectedCustomer?.name ? 'Please select Customer to view their Users' : null
              }
            />
          )}
          rowComponent={(props) => (
            <NewTableRow expandedRowIds={expandedRowIds} setExpandedRowIds={setExpandedRowIds} {...props} />
          )}
          height="auto"
        />
        <TableSelection
          showSelectAll
          cellComponent={CustomSelectionCellWrapper}
          isRowSelectable={({ id }) => isCurrentUserRow(users[id])}
        />
        <TableColumnResizing resizingMode="nextColumn" columnWidths={localColumnsWidth} />
        <TableRowDetail contentComponent={UserExpandedData} toggleCellComponent={CustomToggleCell} />
        <TableHeaderRow cellComponent={CustomHeaderCell} />
        {showFilters && (
          <TableFilterRow
            showFilterSelector
            iconComponent={CustomFilterIcon}
            rowComponent={CustomFilterRow}
            cellComponent={(props) => <CustomFilterCell {...props} actionColumns={rightAlignedColumns} />}
          />
        )}
        <NewTableSelectionBar selectedRows={selectedRows} counterClass={styles.counterClass}>
          <UsersBulkOperations />
        </NewTableSelectionBar>
      </Grid>
    )
  );
};

UsersTable.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
};

export default UsersTable;
