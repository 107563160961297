import React from 'react';

import commonStyles from '../Pages.module.scss';
import styles from './InstructionsPage.module.scss';

const InstructionsPage = () => (
  <div className={styles.container}>
    <p className={commonStyles.description}>Watch the tutorial for step-by-step instructions.</p>
    <div className={styles.content}>
      <video width={560} height={316} controls="controls" className={styles.video}></video>
    </div>
  </div>
);

export default InstructionsPage;
