import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../components/Search';
import RemoveButton from '../../components/RemoveButton';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

import styles from './RoleTab.module.scss';

const RoleUsersTabHeader = ({ rolesCount, selectedUserRoles, search, setSearch, removeUserClicked }) => (
  <div className={styles.headerContainer}>
    <div className={styles.headerLeft}>
      <div className={styles.header}>
        <div className={styles.title}>
          Users <span className={styles.count}>({rolesCount})</span>
        </div>
      </div>
    </div>
    <div className={styles.headerRight}>
      <ReadOnlyDisplayWrapper
        isHide={false}
        category={OrganizationEntityCategory.UsersManagement}
        action={Action.Update}
      >
        <RemoveButton
          showRemoveComponent={selectedUserRoles?.length > 0}
          isMultiple
          selectedCounter={selectedUserRoles?.length}
          removeClicked={removeUserClicked}
        />
      </ReadOnlyDisplayWrapper>
      <Search search={search} setSearch={setSearch} />
    </div>
  </div>
);

RoleUsersTabHeader.propTypes = {
  removeUserClicked: PropTypes.func.isRequired,
  rolesCount: PropTypes.number,
  selectedUserRoles: PropTypes.array,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
};

export default RoleUsersTabHeader;
