import React from 'react';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, SortingState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltip,
  CustomHeaderCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { getColumns, getDefaultSorting, getSortingColumns } from '../../../consts';

const PREVIEW_EMAIL_COLUMN = {
  USER_NAME: {
    columnName: 'email',
    title: 'User Name',
    width: 150,
    sortingEnabled: true,
    direction: 'asc',
  },
};

const PreviewEmailsTable = ({ emails }) =>
  emails && (
    <Grid rows={emails} columns={getColumns(PREVIEW_EMAIL_COLUMN)} automationid="users-list">
      <SortingState
        defaultSorting={getDefaultSorting(PREVIEW_EMAIL_COLUMN)}
        columnExtensions={getSortingColumns(PREVIEW_EMAIL_COLUMN)}
      />
      <DataTypeProvider
        for={[PREVIEW_EMAIL_COLUMN.USER_NAME.columnName]}
        formatterComponent={(props) => <CustomFormatterWithTooltip overrideStyles={{ fontWeight: 500 }} {...props} />}
      />
      <TableWrapper virtual height="auto" />
      <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
    </Grid>
  );

PreviewEmailsTable.propTypes = {
  emails: PropTypes.array.isRequired,
};

export default PreviewEmailsTable;
