import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import Button from 'shared/components/andtComponents/Button';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import classes from './tagColumn.module.scss';

const copyIcon = () => <GenerateIcon iconName={ICONS.copy.name} />;
export const TagColumn = ({ column, row }) => {
  const tags = column.name === 'customTags' ? row.customTags || {} : row.enrichmentTags || {};
  const tagKeys = Object.keys(tags);
  const displayTags = [...Object.keys(tags)].splice(0, 1);
  const getAllTagsText = () => tagKeys.map((tag) => `${tag}: ${tags[tag]}`).join('\n');

  const getTag = (tag) => (
    <div className={`${classes.tdWrapper} ${classes.truncate}`} key={`tag-${tag}`}>
      <span className={classes.tagName}>{`${tag}:`}</span>
      <span className={classes.tagValue}>{tags[tag]}</span>
    </div>
  );
  return (
    <div className={classes.tagWrapper}>
      {displayTags.map((tag) =>
        tagKeys.length <= 2 ? (
          <Tooltip title={`${tag}: ${tags[tag]}`} arrow placement="bottom" key={`tag-${tag}`}>
            {getTag(tag)}
          </Tooltip>
        ) : (
          getTag(tag)
        ),
      )}
      {tagKeys.length > 2 && (
        <div>
          <Tooltip
            placement="bottom"
            title={
              <div className={classes.tagTooltipWrap}>
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      navigator.clipboard.writeText(getAllTagsText());
                    }}
                    text="Copy"
                    isTextButton
                    icon={copyIcon}
                  />
                </div>
                {tagKeys.map((tag) => (
                  <div className={classes.tooltipRow} key={`tagTP-${tag}`}>
                    <span className={classes.tagName}>{tag}</span>
                    <span className={classes.tagValue}>{tags[tag]}</span>
                    <br />
                  </div>
                ))}
              </div>
            }
            classes={{ tooltip: tooltipStyles.whiteTooltip }}
          >
            <span className={classes.tagsCount}>{`+${tagKeys.length - 1}`}</span>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

TagColumn.propTypes = {
  column: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
};
