import Keycloak from 'keycloak-js';
import config from 'config';
import { parseJwt } from 'shared/utils/tokenUtil';

let keycloakInstance;
const KEYCLOAK_CLIENT_ID = 'anodot-cost-login';

export function getRealmLocally() {
  try {
    let realm = document.cookie?.split('realm=')[1]?.split(';')[0];
    const authToken = window.sessionStorage.getItem('authToken') || window.localStorage.getItem('authToken');
    if (authToken) {
      const tokenPayload = parseJwt(authToken);
      realm = tokenPayload.iss.split('/realms/')[1]?.split('/')[0];
    }
    return realm;
  } catch {
    return null;
  }
}

export function persistRealm(realmValue) {
  const isHttps = window.location.protocol === 'https:';
  const expirationTime = Date.now() + 60 * 1000;
  document.cookie = `realm=${realmValue || ''}; expires=${expirationTime}; path=/; ${isHttps ? 'secure;' : ''}`;
}

export function resetKeycloakInstance() {
  keycloakInstance = null;
}

export function createKeycloakInstance(realm = 'default') {
  if (keycloakInstance) {
    return;
  }
  keycloakInstance = new Keycloak({
    realm,
    url: `${config.KEYCLOAK_URL}/auth/`,
    clientId: KEYCLOAK_CLIENT_ID,
  });
}

export function getKeycloakRealm() {
  return keycloakInstance?.realm;
}

export async function initKeycloak() {
  if (!keycloakInstance.didInitialize) {
    await keycloakInstance.init({
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
    });
  }
}

export function isKeycloakAuthenticated() {
  return keycloakInstance.authenticated;
}

export async function keycloakLogin(username = '') {
  const currentUrlPath = window.location.origin + window.location.pathname;
  const searchParams = new URLSearchParams(window?.location?.search);
  const redirectValue = searchParams.get('redirect');
  searchParams.set('redirect', encodeURIComponent(redirectValue));
  let redirectUri = currentUrlPath;
  if (window?.location?.search) {
    redirectUri += '?' + searchParams.toString();
  }
  await keycloakInstance.login({
    loginHint: username.toLowerCase(),
    // TODO - In case of multiple IDPs, or not all users use the IdP the IdP alias should be different from default_org_idp
    idpHint: 'default_org_idp',
    redirectUri,
  });
}

export function keycloakLogout() {
  keycloakInstance?.logout({ logoutMethod: 'GET' });
}

export function getKeycloakToken() {
  return keycloakInstance.token;
}

export function getKyeycloakRefreshToken() {
  return keycloakInstance.refreshToken;
}

export function setKyeycloakRefreshToken(refreshToken) {
  keycloakInstance.refreshToken = refreshToken;
}

export async function refreshKeycloakToken() {
  await keycloakInstance.updateToken(1);
}

export default keycloakInstance;
