import React, { PureComponent } from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { mapCloudTypeToDisplayLabelFunc } from 'shared/utils/cloudUtils';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import If from 'shared/components/SimpleIf';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AWS_GROUP_BY_METRICS_LOV, GroupByLovToAttributes } from 'usage/constants/costAndUsageConstants';
import GroupByFilterButton from 'shared/components/GroupByFilterButton';

class FilterBar extends PureComponent {
  static propTypes = {
    renderGroupByButton: PropTypes.bool.isRequired,
    isShowCustomTags: PropTypes.bool.isRequired,
    topLevelGroupBy: PropTypes.string.isRequired,
    selectedMetric: PropTypes.string.isRequired,
    handleGroupByChange: PropTypes.func.isRequired,
    handleMetricChange: PropTypes.func.isRequired,
    arrCustomTagsKeys: PropTypes.array,
    metricsTypes: PropTypes.array,
    isGroupByValid: PropTypes.func.isRequired,
  };

  constructor() {
    super({
      arrCustomTagsKeys: [],
      metricsTypes: [],
    })
  }

  getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };

  addCustomTagsGroupByValues = (arrCustomTagsKeys = [], groupByItems, isShowCustomTags) => {
    // TODO: enable after custom tags query is improved
    if (!arrCustomTagsKeys || arrCustomTagsKeys.length === 0 || !isShowCustomTags || true) {
      return { secondLevelItems: [], secondLevelItemsValuesMap: GroupByLovToAttributes };
    }
    const secondLevelItemsValuesMap = new Map(GroupByLovToAttributes);
    groupByItems.push(LabelCoordinator.getFieldLabelByCloudType('tags', CLOUD_TYPE_IDS.AWS));

    const secondLevelItems = [];
    arrCustomTagsKeys.forEach((item) => {
      const customTagDispaly = `customTag:${item}`;
      if (item) {
        secondLevelItemsValuesMap.set(customTagDispaly, `customtags:${item}`);
        secondLevelItems.push(customTagDispaly);
      }
    });
    return { secondLevelItems, secondLevelItemsValuesMap };
  };

  render() {
    const {
      isGroupByValid,
      topLevelGroupBy,
      handleGroupByChange,
      handleMetricChange,
      selectedMetric,
      renderGroupByButton,
      isShowCustomTags,
      metricsTypes,
      arrCustomTagsKeys,
    } = this.props;
    const displayLabelCloudBaseMap = mapCloudTypeToDisplayLabelFunc.get(CLOUD_TYPE_IDS.AWS);
    const groupByItems = Object.values(AWS_GROUP_BY_METRICS_LOV);
    const { secondLevelItems, secondLevelItemsValuesMap } = this.addCustomTagsGroupByValues(
      arrCustomTagsKeys,
      groupByItems,
      isShowCustomTags,
    );
    return (
      <If cond={renderGroupByButton}>
        <Row style={{ marginBottom: '5px' }}>
          <div className="filter-bar-inner-col-wrapper-stretch cue-filter-bar" style={{ marginLeft: '15px' }}>
            {selectedMetric && (
              <GroupByFilterButton
                title="Metric: "
                isSecondary={false}
                items={metricsTypes}
                handler={handleMetricChange}
                selectedItem={selectedMetric}
                itemsValuesMap={new Map([['CPU', 'CPU']])}
                isIconButton
                isGroupByValid={() => true}
                secondLevelItems={[]}
                currentCloudType={CLOUD_TYPE_IDS.AWS}
              />
            )}
          </div>
          <div
            className="filter-bar-inner-col-wrapper-stretch cue-filter-bar"
            style={{ marginLeft: '15px', marginRight: '15px' }}
          >
            <GroupByFilterButton
              title="Group By: "
              isSecondary={false}
              items={groupByItems}
              handler={handleGroupByChange}
              selectedItem={this.getFilterLabel(displayLabelCloudBaseMap(topLevelGroupBy))}
              itemsValuesMap={secondLevelItemsValuesMap}
              isIconButton
              secondLevelItems={secondLevelItems}
              secondLevelItemsValuesMap={secondLevelItemsValuesMap}
              isGroupByValid={isGroupByValid}
              displayLabelCloudBaseMap={displayLabelCloudBaseMap}
              currentCloudType={CLOUD_TYPE_IDS.AWS}
            />
          </div>
        </Row>
      </If>
    );
  }
}

export default FilterBar;