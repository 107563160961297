import React from 'react';
import PropTypes from 'prop-types';
import UsageCost from './UsageCost';
import UsageCostToggle from './UsageCostToggle';
import MainDbConstants from 'app/constants/dbConstants';

const UsageCostComponents = ({ mainDashBoardData, xlColSize, colSize, iconStyle }) => {
  return (
    <>
      <UsageCost
        id="1"
        title={MainDbConstants.TITLE_USG_COST_MTD}
        value={mainDashBoardData?.monthToDateCosts}
        percent={`${mainDashBoardData?.monthToDatePercentages}`}
        percentArrowIsUp={mainDashBoardData.monthToDatePercentages > 0}
        showIcon
        infoValue={MainDbConstants.TOOLTIP_USG_COST}
        iconStyle={iconStyle}
        showPercent
        xlColSize={xlColSize}
        colSize={colSize}
      />
      {!!mainDashBoardData.forecastedMonthEndTotalCost && (
        <UsageCost
          id="2"
          title={MainDbConstants.TITLE_USG_COST_FORECASTED}
          value={mainDashBoardData.forecastedMonthEndTotalCost}
          percent={`${mainDashBoardData.forecastedMonthEndPercentages}`}
          percentArrowIsUp={mainDashBoardData.forecastedMonthEndPercentages > 0}
          showIcon
          infoValue={MainDbConstants.TOOLTIP_FORCASTED}
          iconStyle={iconStyle}
          showPercent
          xlColSize={xlColSize}
          colSize={colSize}
        />
      )}
      {!!mainDashBoardData.annualExpectedSavings && (
        <UsageCostToggle
          id="3"
          title={MainDbConstants.TITLE_USG_COST_TOGGLE}
          potSavings={mainDashBoardData.annualExpectedSavings}
          percent={mainDashBoardData.expectedSavingsPercent}
          infoValue={MainDbConstants.TOOLTIP_USG_COST_TOGGLE}
          iconStyle={iconStyle}
          xlColSize={xlColSize}
          colSize={colSize}
        />
      )}
      {!!mainDashBoardData.prevMonthTotalCost && (
        <UsageCost
          id="4"
          title={MainDbConstants.TITLE_USG_COST_PREV}
          value={mainDashBoardData.prevMonthTotalCost}
          infoValue={MainDbConstants.TOOLTIP_USG_COST_PREV}
          iconStyle={iconStyle}
          xlColSize={xlColSize}
          colSize={colSize}
        />
      )}
    </>
  );
};

// Define PropTypes for UsageCostComponents
UsageCostComponents.propTypes = {
  mainDashBoardData: PropTypes.shape({
    monthToDateCosts: PropTypes.number,
    monthToDatePercentages: PropTypes.number,
    forecastedMonthEndTotalCost: PropTypes.number,
    forecastedMonthEndPercentages: PropTypes.number,
    annualExpectedSavings: PropTypes.number,
    expectedSavingsPercent: PropTypes.number,
    prevMonthTotalCost: PropTypes.number,
  }).isRequired,
  xlColSize: PropTypes.number.isRequired,
  colSize: PropTypes.number.isRequired,
  iconStyle: PropTypes.object.isRequired,
};

export default UsageCostComponents;
