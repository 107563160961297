import { CLOSED_AND_DONE_RECS_DATES_FILTERS, FILTERS } from '~/recommendationsNew/consts.js';


export const getDateRange = (recommendationFilters, showDoneCompletedOnly) => {
  const closeUpdateDate =
    recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
      CLOSED_AND_DONE_RECS_DATES_FILTERS.LAST_UPDATE_DATE
      ];
  const closeCreationDate =
    recommendationFilters?.[FILTERS.CLOSED_AND_DONE_RECS_DATES.id]?.[
      CLOSED_AND_DONE_RECS_DATES_FILTERS.CREATION_DATE
      ];
  const openCreateDate = recommendationFilters?.[FILTERS.OPEN_RECS_CREATION_DATE.id];

  let dateRange = showDoneCompletedOnly ? closeUpdateDate?.range : openCreateDate?.range;

  if (showDoneCompletedOnly && closeCreationDate) {
    dateRange = {
      ...(dateRange || {}),
      creationDate: closeCreationDate?.range,
    };
  }
  return { ...dateRange, isCloseDate: showDoneCompletedOnly };
}
