import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import classes from './statusSelection.module.scss';
import PropTypes from 'prop-types';

export const StatusSelection = ({ isSelected, onClick, automationid }) => (
  <div
    className={`${classes.selectionButton} ${isSelected ? classes.selected : ''}`}
    onClick={onClick}
    automation-id={automationid}
  >
    {isSelected ? <GenerateIcon iconName={ICONS.check.name} className={classes.checkMark} /> : ''}
  </div>
);

StatusSelection.propTypes = {
  automationid: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func.isRequired, 
};
