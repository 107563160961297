export type HeaderRecord = {key: string, value: string, id: string};
export type K8Cluster = {
  id: string;
  linkedAccountId: string;
  clusterName: string;
  agentNamespace: string;
  prometheusUrl: string;
  prometheusConnectingCredsUsername: string;
  prometheusConnectingCredsPassword: string;
  httpHeaders: HeaderRecord[];
  kubeStateMetricsJobName: string;
  kubeletJobName: string;
  otherMetricsKey: string;
  otherMetricsValue: string;
};

type AddClusterHelpingFields = {
  otherMetricsBlock: boolean;
  httpHeadersBlock: boolean;
  prometheusConnectingCredsBlock: boolean;
  haveServerBlock: boolean;
  kubeletJobNameBlock: boolean
  kubeStateMetricsJobNameBlock: boolean;
}

export type AddK8Cluster = K8Cluster & AddClusterHelpingFields

export enum GenerationResponseStatus {
  ERROR,
  IS_IN_PROGRESS,
  FIRST_TIME_GENERATED,
  PREVIOUSLY_GENERATED,
  PREVIOUSLY_ACTIVATED,
}
