import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Container, Input, InputGroup, Label, Row } from 'reactstrap';
import {
  ItemsOptionsValues,
  mapGranularityToDisplayName,
  mapItemsOptionsToDisplayName,
  mapReportDatePeriodToDisplayName,
  ReportPeriodTime,
} from 'usage/constants/costAndUsageConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import ControlledToggleButton from 'shared/components/buttons/ControlledToggleButton';
import CustomModal from './andtComponents/Modal';

class EditCustomDashboardPanelModal extends PureComponent {
  static propTypes = {
    modalIsOpen: PropTypes.bool.isRequired,
    onCloseEditPanelModal: PropTypes.func.isRequired,
    handleEditPanel: PropTypes.func.isRequired,
    selectedPanelName: PropTypes.string.isRequired,
    selectedPanelGranularity: PropTypes.string.isRequired,
    selectedPanelPeriod: PropTypes.string.isRequired,
    selectedPanelNumberOfItems: PropTypes.string.isRequired,
    isDefaultOthers: PropTypes.bool.isRequired,
    isGroupSecondaryDate: PropTypes.bool,
    mainFilterGroupBar: PropTypes.string,
    enableNumberOfItems: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const numberOfItems = props.selectedPanelNumberOfItems || ItemsOptionsValues.ALL;
    const { isDefaultOthers, selectedPanelPeriod, selectedPanelGranularity, selectedPanelName } = this.props;
    this.state = {
      isModalValid: false,
      formWasValidated: false,
      panelName: selectedPanelName,
      relativeDatesPeriod: {
        value: selectedPanelPeriod,
        label: mapReportDatePeriodToDisplayName.get(selectedPanelPeriod),
      },
      granularity: {
        value: selectedPanelGranularity,
        label: mapGranularityToDisplayName.get(selectedPanelGranularity),
      },
      numberOfItems: {
        value: numberOfItems,
        label: mapItemsOptionsToDisplayName.get(numberOfItems) || numberOfItems,
      },
      isDefaultOthers,
      panelNameError: '',
      relativeDatesPeriodError: '',
      granularityError: '',
      numberOfItemsError: '',
    };
  }

  baseState = {
    isModalValid: false,
    formWasValidated: false,
    panelName: this.props.selectedPanelName,
    relativeDatesPeriod: {
      value: this.props.selectedPanelPeriod,
      label: mapReportDatePeriodToDisplayName.get(this.props.selectedPanelPeriod),
    },
    granularity: {
      value: this.props.selectedPanelGranularity,
      label: mapGranularityToDisplayName.get(this.props.selectedPanelGranularity),
    },
    panelNameError: '',
    relativeDatesPeriodError: '',
    granularityError: '',
    numberOfItemsError: '',
  };

  handleCancel = () => {
    this.setState({ ...this.baseState });
    this.props.onCloseEditPanelModal();
  };

  handleCheckboxBtnToogle = () => {
    this.setState((prevState) => ({
      isDefaultOthers: !prevState.isDefaultOthers,
    }));
  };

  handleInputChange = (e) => {
    e.preventDefault();
    const attr = e.target.name;
    const val = e.target.value;
    const panelNameError = !val || !val.length ? 'Name is missing' : '';
    this.setState({ [attr]: val, panelNameError });
  };

  updatePanel = async () => {
    const { panelName, relativeDatesPeriod, granularity, numberOfItems, isDefaultOthers } = this.state;
    const preparedParams = {
      panelName,
      relativeDatesPeriod: relativeDatesPeriod.value,
      granularity: granularity.value,
      numberOfItems: numberOfItems.value,
      isDefaultOthers,
    };
    this.props.handleEditPanel(preparedParams);
    this.handleCancel();
  };
  handleOneChoiceFilter = (filterType, selectedOption) => {
    const isError = !selectedOption?.value || !selectedOption?.label?.length;
    let {
      relativeDatesPeriodError: relativeDatesPeriodNewError,
      granularityError: granularityNewError,
      numberOfItemsError: numberOfItemsNewError,
    } = this.state;

    if (isError) {
      if (filterType === 'relativeDatesPeriod') {
        relativeDatesPeriodNewError = 'Please select a value';
      }
      if (filterType === 'granularity') {
        granularityNewError = 'Please select a value';
      }
      if (filterType === 'numberOfItems') {
        numberOfItemsNewError = 'Please select number of items';
      }
    }
    this.setState({
      [filterType]: selectedOption,
      relativeDatesPeriodError: relativeDatesPeriodNewError,
      granularityError: granularityNewError,
      numberOfItemsError: numberOfItemsNewError,
    });
  };

  validateForm = () => {
    const { panelName, granularity, relativeDatesPeriod, numberOfItems } = this.state;
    const { isGroupSecondaryDate } = this.props;
    const panelNameError = !panelName || !panelName.length ? 'Name is missing' : '';
    const relativeDatesPeriodError =
      !relativeDatesPeriod?.value || !`${relativeDatesPeriod.value}`.length ? 'Please select a value' : '';
    const granularityError =
      isGroupSecondaryDate && (!granularity?.value || !granularity.value.length) ? 'Please select a value' : '';
    const numberOfItemsError =
      !numberOfItems?.value || !`${numberOfItems.value}`.length ? 'Please select number of items' : '';
    const isValid = !panelNameError && !relativeDatesPeriodError && !granularityError && !numberOfItemsError;
    const formWasValidated = true;
    if (!isValid) {
      this.setState({
        isValid,
        formWasValidated,
        panelNameError,
        relativeDatesPeriodError,
        granularityError,
        numberOfItemsError,
      });
    } else {
      this.updatePanel();
    }
  };

  renderErrorMessage = (attr) => {
    const { formWasValidated } = this.state;
    const isErrorMsg = !!this.state[attr];
    return formWasValidated && isErrorMsg ? (
      <p style={{ color: 'red', marginTop: '15px' }}>{this.state[attr]}</p>
    ) : (
      <></>
    );
  };

  render() {
    const { modalIsOpen, isGroupSecondaryDate, mainFilterGroupBar, enableNumberOfItems = false } = this.props;
    const { savingDashboard, isDefaultOthers, numberOfItems, granularity, relativeDatesPeriod, panelName } = this.state;
    if (savingDashboard) {
      return <Spinner />;
    }
    const periodListItems = Object.values(ReportPeriodTime).map((period) => ({
      value: period,
      label: mapReportDatePeriodToDisplayName.get(period),
    }));
    const granularityListItems = [
      { value: 'day', label: 'Daily' },
      { value: 'week', label: 'Weekly' },
      { value: 'month', label: 'Monthly' },
    ];
    const numberOfItemsListItems = [
      { value: 5, label: '5' },
      { value: 10, label: '10' },
      { value: 15, label: '15' },
      { value: 25, label: '25' },
      { value: ItemsOptionsValues.ALL, label: mapItemsOptionsToDisplayName.get(ItemsOptionsValues.ALL) },
    ];
    return (
      <CustomModal
        open={modalIsOpen}
        onClose={this.handleCancel}
        title="Update Custom Dashboard Panel"
        closeOnSave={false}
        onSave={this.validateForm}
      >
        <Container>
          <Row>
            <InputGroup size="sm">
              <h5 className="w-100 text-left">
                <Label for="panelName">Panel Name</Label>
              </h5>
              <Input
                type="text"
                bsSize="sm"
                className="general-input"
                name="panelName"
                id="panelName"
                formnovalidate="formnovalidate"
                placeholder="Enter Panel Name"
                onChange={(event) => this.handleInputChange(event)}
                value={panelName}
                autocomplete="panelName"
              />
            </InputGroup>
            {this.renderErrorMessage('panelNameError')}
          </Row>
          <Row>
            <div className="w-100 mt-sm-3">
              <h5 className="w-100 text-left mb-sm-1">Period</h5>
              <OneChoiceFieldFilter
                type="relativeDatesPeriod"
                value={relativeDatesPeriod}
                options={periodListItems}
                handleChange={this.handleOneChoiceFilter}
              />
            </div>
            {this.renderErrorMessage('relativeDatesPeriodError')}
          </Row>
          {isGroupSecondaryDate && (
            <Row>
              <div className="w-100 mt-sm-3">
                <h5 className="w-100 text-left mb-sm-1">Granularity</h5>
                <OneChoiceFieldFilter
                  type="granularity"
                  value={granularity}
                  options={granularityListItems}
                  handleChange={this.handleOneChoiceFilter}
                />
              </div>
              {this.renderErrorMessage('granularityError')}
            </Row>
          )}
          {mainFilterGroupBar && enableNumberOfItems && (
            <>
              <Row>
                <div className="w-100 mt-sm-3">
                  <h5 className="w-100 text-left mb-sm-1">
                    Number Of Items <span>{mainFilterGroupBar}</span>
                  </h5>
                  <OneChoiceFieldFilter
                    type="numberOfItems"
                    value={numberOfItems}
                    options={numberOfItemsListItems}
                    handleChange={this.handleOneChoiceFilter}
                  />
                </div>
                {this.renderErrorMessage('numberOfItemsError')}
              </Row>
              <div className="d-flex mt-sm-4 custom-dashboard-edit-modal__toggle-others-row">
                <h5 className="w-100 text-left mb-sm-1">Enable Others By Default</h5>
                <ControlledToggleButton checked={isDefaultOthers} selectedItem handler={this.handleCheckboxBtnToogle} />
              </div>
            </>
          )}
        </Container>
      </CustomModal>
    );
  }
}

export default EditCustomDashboardPanelModal;
