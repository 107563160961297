/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key,jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmptyListIcon } from 'shared/img/icons/empty-list.svg';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import Checkbox from '../../../andtComponents/Checkbox';
import ButtonMultiDropdown from '../../../andtComponents/ButtonMultiDropdown';
import icons from '../icons';
import styles from '../ChartButtonsMenu.module.scss';

const ViewMenu = ({
  trendLineHandler,
  onlyTrendRowHandler,
  trendRowHandler,
  wasteCostAllocationHandler,
  cumulativeHandler,
  goalsHandler,
  eventsHandler,
  disabled,
}) => {
  const options = [
    {
      id: 'showTrendRow',
      text: 'Show Trend Row',
      handler: trendRowHandler,
    },
    {
      id: 'showOnlyTrendRow',
      text: 'Show Only Trend Row',
      handler: onlyTrendRowHandler,
    },
    {
      id: 'showTrendline',
      text: 'Show Trendline',
      handler: trendLineHandler,
    },
    {
      id: 'showCumulative',
      text: 'Show Cumulative',
      handler: cumulativeHandler,
    },
    {
      id: 'allocateWasteCost',
      text: 'Allocate Waste Cost',
      handler: wasteCostAllocationHandler,
    },
    {
      id: 'showEvents',
      text: 'Show Events',
      handler: eventsHandler,
    },
  ];
  const menuOptions = [
    ...options
      .filter((o) => !!o.handler)
      .map(({ text, handler, id }, index) => ({
        id: id || index,
        simpleOption: true,
        simpleOptionContent: (
          <li data-disabled={disabled}>
            <Checkbox
              isChecked={handler.value}
              onChange={() => handler.onChange(!handler.value)}
              text={text}
              isDisabled={disabled}
              className={styles.checkbox}
              automationid={id}
            />
          </li>
        ),
      })),
    ...(goalsHandler
      ? [
          {
            id: 'showGoal',
            label: 'Show Goal',
            selected: !!goalsHandler.value,
            content: (
              <div>
                {isEmptyArray(goalsHandler.options) ? (
                  <div className={styles.emptyBlock}>
                    <EmptyListIcon />
                    <p>No goals</p>
                    <span>Set cost usage filters and save as new goal</span>
                  </div>
                ) : (
                  <>
                    {goalsHandler.options.map((goal) => (
                      <li
                        key={goal.uuid}
                        data-selected={goal.uuid === goalsHandler.value}
                        onClick={() => goalsHandler.onChange(goal.uuid === goalsHandler.value ? null : goal.uuid)}
                        id={goal.uuid}
                      >
                        <span>{goal.title}</span>
                      </li>
                    ))}
                  </>
                )}
              </div>
            ),
          },
        ]
      : []),
  ];

  return (
    <ButtonMultiDropdown
      position="left"
      dropdownProps={{
        text: '',
        icon: icons.menu.optionsIcon,
        tooltipText: 'View Options',
      }}
      automationid="viewMenu"
      items={menuOptions}
    />
  );
};

ViewMenu.propTypes = {
  onlyTrendRowHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  trendLineHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  trendRowHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  cumulativeHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  eventsHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  goalsHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  wasteCostAllocationHandler: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  disabled: PropTypes.bool.isRequired,
};

export default ViewMenu;
