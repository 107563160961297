import React, { useMemo, ReactNode } from 'react';
import Select, { createFilter, StylesConfig } from 'react-select';
import { palette } from 'shared/constants/colorsConstants';
import classNames from 'classnames';
import {
  titleByChannelType,
  iconByChannelType,
  groupedChannelTypes,
} from 'users/containers/Channels/channelsHelpers.js';
import styles from './RecipientsSelect.module.scss';

export interface Recipient {
  id: string;
  anodotName?: string;
  recipientData?: {
    name?: string;
    dstId?: string;
    type?: string;
  };
  dstId?: string;
}

export interface Channel {
  channelType: string;
  name: string;
  recipients: Recipient[];
}

interface GroupedChannel {
  groupedChannels: Channel[];
}

interface SelectOption extends Recipient {
  value: string;
  label: ReactNode;
}

interface RecipientsSelectProps {
  channels?: Channel[];
  isLoading?: boolean;
  savedRecipients?: Recipient[];
  onChangeHandler: (selected: Recipient | Recipient[]) => void;
  isDisabled?: boolean;
  menuPosition?: 'absolute' | 'fixed';
  menuPlacement?: 'auto' | 'bottom' | 'top';
  className?: string;
  height?: number;
  selectStylesConfig?: StylesConfig;
  isMulti?: boolean;
}

const selectStyles = ({ height }: { height?: number }): StylesConfig => ({
  container: (defaultStyles) => ({ ...defaultStyles, height, borderRadius: 5 }),
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? palette.blue[400] : 'transparent',
    backgroundColor: state.isDisabled ? palette.gray[100] : palette.gray[200],
    borderWidth: '2px',
    borderRadius: '6px',
    boxShadow: 'unset',
    minHeight: '0',
    height,
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: '2px 4px',
    height,
  }),
  clearIndicator: (baseStyle) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
    height,
  }),
  dropdownIndicator: (baseStyle) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
  }),
  indicatorsContainer: (baseStyle) => ({
    ...baseStyle,
    height,
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    color: state.isDisabled ? palette.gray[400] : palette.gray[450],
  }),
  multiValue: (baseStyle) => ({
    ...baseStyle,
    backgroundColor: 'white',
    alignItems: 'center',
    border: `1px solid ${palette.gray[300]}`,
    borderRadius: '6px',
  }),
  multiValueLabel: (baseStyle) => ({
    ...baseStyle,
    color: palette.gray[450],
    backgroundColor: 'white',
    borderRadius: '6px',
    fontSize: '13px',
    fontWeight: 500,
    paddingLeft: '4px',
    padding: '0 4px',
  }),
  multiValueRemove: (baseStyle, state) => ({
    ...baseStyle,
    color: palette.gray[300],
    padding: 0,
    marginRight: '2px',
    borderRadius: '50%',
    display: state.isDisabled ? 'none' : 'flex',
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
});

const RecipientsSelect: React.FC<RecipientsSelectProps> = ({
  channels,
  isLoading,
  savedRecipients = [],
  onChangeHandler,
  isDisabled,
  menuPosition = 'absolute',
  menuPlacement = 'auto',
  className = '',
  height,
  selectStylesConfig,
  isMulti = true,
}) => {
  const { options = [], selectedOptions = [] } = useMemo(() => {
    if (!channels || channels.length === 0) {
      return {};
    }

    const groupedChannelsList: GroupedChannel[] = groupedChannelTypes
      .map((channelType) => ({
        groupedChannels: channels.filter((c) => c.channelType === channelType),
      }))
      .filter(({ groupedChannels }) => groupedChannels.length > 0);

    const options = [
      ...channels
        .filter((c) => !groupedChannelTypes.includes(c.channelType))
        .map((channel) => ({
          label: `${titleByChannelType[channel.channelType] || channel.channelType}: ${channel.name}`,
          options: channel.recipients
            .filter(({ id }) => !savedRecipients.some((r) => id === r.id))
            .map((recipient) => ({
              ...recipient,
              value: recipient.id,
              label: (
                <>
                  {iconByChannelType[channel.channelType]}{' '}
                  {recipient.anodotName || recipient.recipientData?.name || recipient.dstId}
                </>
              ),
            })),
        })),
      ...groupedChannelsList.map(({ groupedChannels }) => ({
        label: titleByChannelType[groupedChannels[0].channelType],
        options: groupedChannels.reduce<SelectOption[]>(
          (acc, channel) => [
            ...acc,
            ...channel.recipients
              .filter(({ id }) => !savedRecipients.some((r) => id === r.id))
              .map((recipient) => ({
                ...recipient,
                value: recipient.id,
                label: (
                  <>
                    {iconByChannelType[channel.channelType]}{' '}
                    {recipient.anodotName || recipient.recipientData?.name || recipient.dstId}
                  </>
                ),
              })),
          ],
          [],
        ),
      })),
    ];

    if (!savedRecipients) {
      return {};
    }

    const selectedOptions: SelectOption[] = savedRecipients.reduce((acc: SelectOption[], recipient) => {
      const { type, name, dstId } = recipient.recipientData || {};
      if (type === 'SENDGRID') {
        return acc;
      }
      const label = (
        <>
          {iconByChannelType[type || '']} {recipient.anodotName || name || dstId}
        </>
      );
      return [...acc, { ...recipient, value: recipient.id, label }];
    }, []);

    return { options, selectedOptions };
  }, [channels, savedRecipients]);

  return (
    <Select
      isMulti={isMulti}
      isDisabled={isDisabled}
      closeMenuOnSelect={!isMulti}
      isLoading={isLoading}
      options={options}
      value={selectedOptions}
      onChange={(selected: any) => {
        const getSelectedRawData = ({ value, label, ...rawData }: SelectOption) => rawData;
        onChangeHandler(
          isMulti ? selected?.map((s: SelectOption) => getSelectedRawData(s)) || [] : getSelectedRawData(selected),
        );
      }}
      className={classNames(styles.select, className)}
      classNamePrefix="select"
      styles={selectStylesConfig || selectStyles({ height })}
      menuPosition={menuPosition}
      menuPlacement={menuPlacement}
      filterOption={createFilter({
        stringify: ({ data: { recipientData, anodotName } }: { data: Recipient }) =>
          anodotName ?? recipientData?.name ?? recipientData?.dstId ?? '',
      })}
      placeholder={`Select Channel${isMulti ? 's' : ''}`}
      components={{ IndicatorSeparator: () => null }}
      openMenuOnFocus
      inputId="recipients-select"
    />
  );
};

export default RecipientsSelect;
