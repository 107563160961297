const QUERY_KEYS = {
  PRICING_MODE_CHANGED: 'pricingModeChanged',

  MSP_CUSTOMERS: 'mspCustomers', // new version
  COST_CENTERS: 'costCenters', // new version
  SHARING_ENTITIES: 'sharingEntities',
  BILLING_ACCOUNTS: 'billingAccounts',
  OLD_COST_CENTER: 'costCenter',
  DIVISIONS: 'divisions',
  CUSTOMERS: 'customers',
  CUSTOMER_ADMIN_PANEL: 'customer-admin-panel',
  BILLING_RULES: 'billingRules',
  BILLING_STATUS: 'billingStatus',
  RESELLER_CREDITS: 'resellerCredits',
  RESELLER_RULE_RATES: 'resellerRuleRates',
  RESELLER_CREDITS_ALERTS: 'resellerCreditsAlerts',
  CUE_ALERTS: 'cueAlerts',
  ANOMALY_DETECTION: 'anomalyDetection',
  ANOMALIES_ALERT_RULES: 'anomaliesAlertRules',
  LINKED_ACCOUNTS: 'linkedAccounts',
  ROLE_BY_USER_KEY: 'roleByUserKey',
  DIVISIONS_BY_ACCOUNT_ID: 'divisionsByAccountId',
  USERS_BY_ROLE_ID: 'usersByRoleId',
  MULTI_ACCOUNTS_RESELLER_CUSTOMER_ROLES: 'usersByRoleId',
  LINKED_ACCOUNTS_BY_ACCOUNT_KEY: 'linkedAccountsByAccountKey',
  IS_MULTI_ACCOUNTS_RESELLER_CUSTOMER_ROLE_SYNC: 'isMultiAccountsResellerCustomerRoleSync',
  BUSINESS_MAPPING_PREFERENCES: 'businessMappingPreferences',
  BUSINESS_MAPPING_VIEWPOINTS: 'businessMappingViewpoints',
  BUSINESS_MAPPING_GLOBAL_GROUPS: 'businessMappingGlobalGroups',
  BUSINESS_MAPPING_VIEWPOINT: 'businessMappingViewpoint',
  BUSINESS_MAPPING_GROUPS: 'businessMappingGroups',
  BUSINESS_MAPPING_PREVIEW: 'businessMappingPreview',
  ADMIN_ACCOUNTS_STATUS: 'adminAccountsStatus',
  FINOPS_DATA: 'finOpsData',
  MAINDB_DATA: 'mainDbData',
  FILTER_GROUPS: 'filterGroups',
  VIEWS: 'views',

  COMPANIES: 'companies',
  ADMIN_ACCOUNTS: 'adminAccounts',
  ACCOUNTS_FEATURES: 'accountsFeatures',
  FEATURE_FLAGS_LIST: 'featureFlags',
  DEFAULT_FEATURES: 'defaultFeatures',
  UPDATE_FEATURES: 'updateFeatures',

  FILTERS_DISTINCT_VALUES: 'filtersDistinctValues',
  FILTERS_DISTINCT_TAGS_VALUES: 'filtersDistinctTagsValues',
  FILTERS_DISTINCT_K8S_VALUES: 'filtersDistinctK8SValues',
  FILTERS_CUSTOM_TAGS_SEARCH: 'filtersCustomTagsSearch',
  FILTERS_K8S_TAGS_SEARCH: 'filtersK8sTagsSearch',
  FILTERS_K8S_WORKLOADS_SEARCH: 'filtersK8sWorkloadsSearch',
  FILTERS_RECDB_TAGS_SEARCH: 'filtersRecDBTagsSearch',
  FILTERS_ACCOUNT_TAGS_SEARCH: 'filtersAccountTagsSearch',
  FILTERS_VIRTUAL_TAGS_SEARCH: 'filtersAccountTagsSearch',
  FILTERS_VIEWPOINT_MAPPINGS_SEARCH: 'filtersViewpointMappingsSearch',
  FILTERS_USAGE_TYPES: 'filtersUsageTypes',
  FILTERS_NAMESPACES: 'filtersNamespaces',
  FILTERS_PODS: 'filtersPods',
  FILTERS_RESOURCES_SEARCH: 'filtersResourcesSearch',
  FILTERS_ITEM_DESCRIPTION_SEARCH: 'filtersItemDescriptionSearch',
  FILTERS_SERVICE_COSTS_BY_COLUMN: 'filtersServiceCostsByColumn',

  BUDGET: 'budget',

  AZURE_VM_AND_STORAGE_COST: 'azureVmAndStorageCost',
  AZURE_VM_USAGE: 'azureVmUsage',
  RI_COVERAGE: 'riCoverage',
  AZURE_SERVICE_PLANS: 'azureServicePlans',
  CUE_GOALS: 'cueGoals',
  CUE_EVENTS: 'cueEvents',
  ROLES: 'roles',
  ALL_ROLES: 'allRoles',
  USER_NOTIFICATIONS: 'userNotifications',
  SLACK: 'slack',
  CHANNELS: 'channels',
  COST_GPT: 'costGpt',
  PREFERENCES: 'preferences',
  PREFERENCES_BY_TYPE: 'preferencesByType',
  CUSTOMER_REPORTS: 'customerReports',
  BILLING_INFO: 'billingInfo',
  BILLING_PROFILE: 'billingProfile',
  REBILLING_HISTORY: 'rebillingHistory',
  SHARE_SETTINGS: 'shareSettings',
  REALM: 'realm',
  PERMISSIONS: 'permissions',
  SUB_USERS: 'subUsers',
  SP_ANALYZER_DATA: 'spAnalyzerData',
  VIRTUAL_TAGS: 'virtualTags',
  ACCOUNT_TAGS: 'accountTags',
  REPORTS: 'reports',
  NOTIFICATIONS: 'notifications',
  DB_FLAGS: 'dbFlags',
  ACCESSIBLE_CATEGORIES: 'accessibleCategories',
  USERS_SETTINGS_NOTIFICATIONS: 'usersSettingsNotifications',
  KUBERNETES: 'kubernetes',
  KUBERNETES_WEIGHTS: 'weights',
  K8S_ONBOARDING_LINK: "k8s_onboarding_link",

  BIG_QUERY_USAGE: 'bigQueryUsage',
  BIG_QUERY_USAGE_TABLE: 'bigQueryUsageTable',
  BIG_QUERY_USAGE_DISTINCT: 'bigQueryUsageDistinct',

  IDLE_INSTANCES_TABLE: 'idleInstancesTable',
  IDLE_INSTANCES_ROW: 'idleInstancesRow',
  IDLE_INSTANCES_CRITERIA: 'idleInstancesCriteria',

  EC2_USAGE: 'ec2Usage',
  EC2_USAGE_RI_COVERAGE: 'ec2UsageRiCoverage',
  CUSTOM_DASHBOARD_DASHBOARDS: 'customDashboardDashboards',
  CUSTOM_DASHBOARD_TEMPLATES: 'customDashboardTemplates',
  CUSTOM_DASHBOARD_PANELS: 'customDashboardPanels',

  RDS_INSTANCE_COSTS: 'rdsInstanceCosts',

  RECOMMENDATION_DASHBOARD_SUMMARY: 'recommendationDashboardSummary',
  RECOMMENDATION_OPPORTUNITIES: 'recommendationOpportunities',
  RECOMMENDATION_REPORTS: 'recommendationReports',
  RECOMMENDATION_EXCLUSIONS: 'recommendationExclusions',
  RECOMMENDATION_BY_TYPE: 'recommendationByType',
  DAILY_RECOMMENDATIONS: 'dailyRecommendations',
  RECOMMENDATIONS_HISTORY: 'recommendationsHistory',

  RECOMMENDATIONS: 'recommendations',
  RECOMMENDATIONS_HEATMAP: 'recommendationsHeatMap',
  RECOMMENDATIONS_WASTE_REPORT: 'recommendationsWasteReport',
  RECOMMENDATIONS_GROUP_BY_OPTIONS: 'recommendationsGroupByOptions',
  RECOMMENDATIONS_GROUP_BY_OPTION: 'recommendationsGroupByOption',
  RECOMMENDATIONS_HEATMAP_SUMMARY: 'recommendationsHeatMapSummary',
  RECOMMENDATIONS_DYNAMIC_FILTER: 'recommendationsDynamicFilter',
  RECOMMENDATIONS_DYNAMIC_RANGES: 'recommendationsDynamicRanges',
  RECOMMENDATIONS_CUSTOM_AND_ENRICHMENT_TAGS: 'recommendationsCustomAndEnrichmentTags',
  RECOMMENDATIONS_VIRTUAL_TAGS: 'recommendationsVirtualTags',

  RECOMMENDATIONS_VIEW: 'recommendationsView',

  RECOMMENDATIONS_LIST: 'recommendationsList',
  FULL_RECOMMENDATIONS_LIST: 'fullRecommendationsList',
  RECOMMENDATION_USER_ACTION: 'recommendationUserAction',

  USER_MANAGEMENT_RESELLER_TOKENS: 'userManagementResellerTokens',
  USER_MANAGEMENT_USERS: 'userManagementUsers',
  USER_MANAGEMENT_ROLES: 'userManagementRoles',
  USER_MANAGEMENT_ROLES_AVAILABLE_ACTION_CATEGORIES: 'userManagementRolesAvailableActionCategories',
  USER_MANAGEMENT_ROLE_PERMISSIONS: 'userManagementRolePermissions',
  USER_MANAGEMENT_DATA_ACCESS: 'userManagementDataAccess',
  USER_MANAGEMENT_DATA_ACCESS_ACCOUNTS: 'userManagementDataAccessAccounts',
  USER_MANAGEMENT_DATA_ACCESS_COST_CENTERS: 'userManagementDataAccessCostCenters',
  USER_MANAGEMENT_DATA_ACCESS_RESELLER_CUSTOMERS: 'userManagementDataAccessResellerCustomers',

  REGISTRATION_CODES: 'registrationCodes',

  V2_ONBOARDING: 'v2Onboarding',
  S3_BUCKET_COSTS: 's3bucketcosts',

  MAIN_DASHBOARD: 'mainDashboard',
  FORECAST_DATA: 'forecastData',
  FORECAST_DATA_TOTAL: 'forecastDataTotal',
  FORECAST_DATA_DAILY: 'forecastDataDaily',
  FORECAST_DATA_Monthly: 'forecastDataMonthly',
  ANOMALIES_DATA: 'anomaliesData',
  COMMITMENT: 'commitment',
  MTD_COST: 'mtdCost',
  PM_COST: 'previseCost',

  VENDOR_MAPPINGS_QUERY_KEY: 'byodVendors',

  USERS_FOR_ADMIN: 'usersForAdmin',
};

export default {
  QUERY_KEYS,
};
