import React, { PureComponent } from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { mapCloudTypeToDisplayLabelFunc } from 'shared/utils/cloudUtils';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import If from 'shared/components/SimpleIf';
import {
  GroupByLovToAttributes,
  mapGroupByCloudBasedLov,
  NEW_AZURE_FOCUS_FIELDS,
  removeDoubleCostCenterInKeyCloakManagement,
  RESELLER_ONLY_FIELDS,
} from 'usage/constants/costAndUsageConstants';
import GroupByFilterButton from 'shared/components/GroupByFilterButton';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';

class FilterBar extends PureComponent {
  static propTypes = {
    renderGroupByButton: PropTypes.bool.isRequired,
    isShowCustomTags: PropTypes.bool.isRequired,
    topLevelGroupBy: PropTypes.string.isRequired,
    topLevelSecondaryGroupBy: PropTypes.string.isRequired,
    currentCloudType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    handleGroupByChange: PropTypes.func.isRequired,
    arrCustomTagsKeys: PropTypes.array,
    arrBusinessMappingViewpoints: PropTypes.array,
    arrAccountTagsKeys: PropTypes.array,
    arrVirtualTagsNames: PropTypes.array.isRequired,
    arrViewsNames: PropTypes.array,
    isGroupByValid: PropTypes.func.isRequired,
    usersStore: PropTypes.object.isRequired,
    appStore: PropTypes.object.isRequired,
    isAllAcounts: PropTypes.bool,
  };

  constructor() {
    super();
  }

  getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };

  addCustomTagsGroupByValues = (
    arrCustomTagsKeys = [],
    arrVirtualTagsNames = [],
    arrViewsNames = [],
    arrAccountTagsKeys = [],
    arrBusinessMappingViewpoints = [],
    groupByItems,
    isShowCustomTags,
  ) => {
    const { currentCloudType, isAllAcounts = false } = this.props;
    if (isAllAcounts) {
      groupByItems.push('Payer Account');
    }
    if (!isShowCustomTags) {
      return { secondLevelItems: [], secondLevelItemsValuesMap: GroupByLovToAttributes };
    }
    const secondLevelItemsValuesMap = new Map(GroupByLovToAttributes);
    if (arrBusinessMappingViewpoints?.length) {
      groupByItems.push('Business Mapping Viewpoints');
    }
    if (arrCustomTagsKeys?.length) {
      groupByItems.push(LabelCoordinator.getFieldLabelByCloudType('tags', currentCloudType));
    }
    if (arrVirtualTagsNames?.length) {
      groupByItems.push('Virtual Tags');
    }
    if (arrViewsNames?.length) {
      groupByItems.push('Views');
    }
    if ((arrAccountTagsKeys || []).length) {
      groupByItems.push('Enrichment Tags');
    }
    const secondLevelItems = [];
    (arrViewsNames || []).forEach(({ name }) => {
      const viewTagDisplay = `viewsTag:${name}`;
      secondLevelItemsValuesMap.set(viewTagDisplay, `viewscustomtags:${name}`);
      secondLevelItems.push(viewTagDisplay);
    });
    (arrVirtualTagsNames || []).forEach(({ name }) => {
      const virtualTagDisplay = `virtualTag:${name}`;
      secondLevelItemsValuesMap.set(virtualTagDisplay, `virtualcustomtags:${name}`);
      secondLevelItems.push(virtualTagDisplay);
    });
    (arrCustomTagsKeys || []).forEach((item) => {
      const customTagDispaly = `customTag:${item}`;
      if (item) {
        secondLevelItemsValuesMap.set(customTagDispaly, `customtags:${item}`);
        secondLevelItems.push(customTagDispaly);
      }
    });
    (arrAccountTagsKeys || []).forEach((item) => {
      const customTagDisplay = `accountTag:${item}`;
      if (item) {
        secondLevelItemsValuesMap.set(customTagDisplay, `accounttags:${item}`);
        secondLevelItems.push(customTagDisplay);
      }
    });
    (arrBusinessMappingViewpoints || []).forEach((item) => {
      const id = `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: ${item.id}`;
      const display = `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: ${item.name}`;
      if (item) {
        secondLevelItemsValuesMap.set(display, id);
        secondLevelItems.push(display);
      }
    });
    return { secondLevelItems, secondLevelItemsValuesMap };
  };
  addItemsToSecondaryGroupBy = (groupByItemsSecondary, accountId, arrViewsNames) => {
    if (arrViewsNames && arrViewsNames.length) {
      groupByItemsSecondary.push('Views');
    }
  };

  formatValueLabelItems = (groupByItems) => {
    const { currentCloudType = [], usersStore } = this.props;
    const filteredItems = groupByItems
      .filter((item) => usersStore.isCurrentUserReseller || !RESELLER_ONLY_FIELDS.includes(item))
      .filter(
        (item) =>
          usersStore.getIsCurrentAccountAzureFocus ||
          !NEW_AZURE_FOCUS_FIELDS.includes(GroupByLovToAttributes.get(item)),
      );
    if (currentCloudType !== CLOUD_TYPE_IDS.GCP) {
      return filteredItems;
    }
    const groupBy = filteredItems.map((item) => ({ value: item, label: GCPCommonFieldLabels.get(item) || item }));
    return groupBy;
  };

  formatSelectedItem = (selectedItem) => {
    const { currentCloudType } = this.props;
    if (currentCloudType !== CLOUD_TYPE_IDS.GCP) {
      return selectedItem;
    }
    return GCPCommonFieldLabels.get(selectedItem) || selectedItem;
  };

  render() {
    const {
      isGroupByValid,
      topLevelGroupBy,
      topLevelSecondaryGroupBy,
      handleGroupByChange,
      renderGroupByButton,
      currentCloudType,
      isShowCustomTags,
      usersStore,
      appStore,
      arrCustomTagsKeys = [],
      arrAccountTagsKeys = [],
      arrBusinessMappingViewpoints = [],
      arrVirtualTagsNames,
      arrViewsNames = [],
    } = this.props;
    if (currentCloudType == null) {
      return null;
    }
    const cloudBaseLov = mapGroupByCloudBasedLov.get(currentCloudType);
    const groupByItems = Object.values(cloudBaseLov);
    removeDoubleCostCenterInKeyCloakManagement(
      groupByItems,
      appStore.isKeyCloakManagement,
      usersStore.isCurrentUserReseller,
    );
    // CURRENTLY DISABLING CUSTOM TAGS
    const { secondLevelItems, secondLevelItemsValuesMap } = this.addCustomTagsGroupByValues(
      arrCustomTagsKeys,
      arrVirtualTagsNames,
      arrViewsNames,
      arrAccountTagsKeys,
      arrBusinessMappingViewpoints,
      groupByItems,
      isShowCustomTags,
    );
    const displayLabelCloudBaseMap = mapCloudTypeToDisplayLabelFunc.get(currentCloudType);
    const groupByItemsSecondary = groupByItems.filter(
      (item) => item !== 'Virtual Tags' && item !== 'Views' && item !== '- None -',
    );
    groupByItemsSecondary.unshift('Date');
    const { currDispUserAccountKey } = usersStore;
    this.addItemsToSecondaryGroupBy(
      groupByItemsSecondary,
      usersStore.getCurrentDisplayedAccountId(currDispUserAccountKey),
      arrViewsNames,
    );
    const groupByItemsSecondaryValueLabel = this.formatValueLabelItems(groupByItemsSecondary);
    const isDisabled = topLevelGroupBy && topLevelGroupBy.includes('customtag');
    const groupByValuesLabels = this.formatValueLabelItems(groupByItems);
    const selectedItem = this.getFilterLabel(
      displayLabelCloudBaseMap(
        topLevelGroupBy,
        secondLevelItemsValuesMap,
        usersStore.rootStore.appStore.isKeyCloakManagement,
      ),
    );
    const selectedLabel = this.formatSelectedItem(selectedItem);
    const secondarySelectedItem = this.getFilterLabel(
      displayLabelCloudBaseMap(
        topLevelSecondaryGroupBy,
        secondLevelItemsValuesMap,
        usersStore.rootStore.appStore.isKeyCloakManagement,
      ),
    );
    const secondarySelectedLabel = this.formatSelectedItem(secondarySelectedItem);
    return (
      <If cond={renderGroupByButton}>
        <Row>
          <div
            className="filter-bar-inner-col-wrapper-stretch cue-filter-bar"
            style={{ marginLeft: '15px', marginRight: '15px' }}
          >
            <GroupByFilterButton
              title="Group By: "
              isSecondary={false}
              items={groupByValuesLabels}
              handler={handleGroupByChange}
              selectedItem={selectedLabel}
              itemsValuesMap={secondLevelItemsValuesMap}
              isIconButton
              secondLevelItems={secondLevelItems}
              secondLevelItemsValuesMap={secondLevelItemsValuesMap}
              isGroupByValid={isGroupByValid}
              currentCloudType={currentCloudType}
              usersStore={usersStore}
              automationid="primaryGroupBy"
            />
          </div>
          <div className="filter-bar-inner-col-wrapper-stretch cue-filter-bar" style={{ marginRight: '15px' }}>
            <GroupByFilterButton
              title="By: "
              isSecondary
              items={groupByItemsSecondaryValueLabel}
              handler={handleGroupByChange}
              selectedItem={secondarySelectedLabel}
              itemsValuesMap={secondLevelItemsValuesMap}
              isIconButton
              secondLevelItems={secondLevelItems}
              isDisabled={isDisabled}
              isGroupByValid={isGroupByValid}
              currentCloudType={currentCloudType}
              usersStore={usersStore}
              automationid="secondaryGroupBy"
            />
          </div>
        </Row>
      </If>
    );
  }
}

export default FilterBar;
