import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { STALE_TIME } from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { API } from 'shared/utils/apiMiddleware.js';
import { AMORTIZE_COST_TYPES } from 'usage/constants/costAndUsageConstants.js';

const getCostType = (costType) => {
  switch (costType) {
    case AMORTIZE_COST_TYPES.NET_AMORTIZED:
      return 'net_amortized';
    case AMORTIZE_COST_TYPES.NET_UNBLENDED:
      return 'net_cost';
    case AMORTIZE_COST_TYPES.PUBLIC_COST:
    case AMORTIZE_COST_TYPES.DISTRIBUTED:
      return 'unblended';
    default:
      return costType;
  }
};
const getForecastQueryParams = (numMonth, costType, filters) => {
  const filtersQuery = filters ? filters.map((filter) => `&filters=${encodeURIComponent(filter)}`).join('&') : '';
  let queryParams = '';

  if (numMonth) {
    queryParams += `?numMonth=${numMonth}`;
  }
  if (costType) {
    queryParams += (queryParams ? '&' : '?') + `costType=${getCostType(costType)}`;
  }
  if (filtersQuery) {
    queryParams += filtersQuery;
  }
  return queryParams;
};
export const getForecastDataTotal = async (numMonth, costType, filters) => {
  return await API.get(
    'billings',
    `/api/v1/dashboards/forecast-dashboard-data/total${getForecastQueryParams(numMonth, costType, filters)}`,
  );
};
export const getForecastDataDaily = async (numMonth, costType, filters) => {
  return await API.get(
    'billings',
    `/api/v1/dashboards/forecast-dashboard-data/daily${getForecastQueryParams(numMonth, costType, filters)}`,
  );
};
export const getForecastDataMonthly = async (numMonth, costType, filters) => {
  return await API.get(
    'billings',
    `/api/v1/dashboards/forecast-dashboard-data/monthly${getForecastQueryParams(numMonth, costType, filters)}`,
  );
};

export default function useForecastData(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const mainQueryKey = [apiConstants.QUERY_KEYS.MAIN_DASHBOARD, apiConstants.QUERY_KEYS.FORECAST_DATA, userAccountKey];
  const useFetchForecastDataTotal = (numMonth, costType, filters, isPpApplied) => {
    const queryKey = [mainQueryKey, apiConstants.QUERY_KEYS.FORECAST_DATA_TOTAL];
    return useQuery({
      queryKey: [...queryKey, numMonth, costType, filters, isPpApplied],
      queryFn: () => getForecastDataTotal(numMonth, costType, filters),
      retry: false,
      staleTime: STALE_TIME,
      enabled: isEnabled,
      keepPreviousData: true,
    });
  };
  const useFetchForecastDataDaily = (numMonth, costType, filters, isPpApplied) => {
    const queryKey = [mainQueryKey, apiConstants.QUERY_KEYS.FORECAST_DATA_DAILY];
    return useQuery({
      queryKey: [...queryKey, numMonth, costType, filters, isPpApplied],
      queryFn: () => getForecastDataDaily(numMonth, costType, filters),
      retry: false,
      staleTime: STALE_TIME,
      enabled: isEnabled,
      keepPreviousData: true,
    });
  };
  const useFetchForecastDataMonthly = (costType, filters) => {
    const queryKey = [mainQueryKey, apiConstants.QUERY_KEYS.FORECAST_DATA_Monthly];
    return useQuery({
      queryKey: [...queryKey, costType, filters],
      queryFn: () => getForecastDataMonthly(1, costType, filters),
      retry: false,
      staleTime: STALE_TIME,
      enabled: isEnabled,
      keepPreviousData: true,
    });
  };

  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries(queryKeyToInvalidate ? [...mainQueryKey] : queryKey),
    reset: (queryKeyToInvalidate) =>
      queryClient.resetQueries(queryKeyToInvalidate ? { queryKey: [...mainQueryKey] } : { mainQueryKey }),
    fetchForecastDataTotal: useFetchForecastDataTotal,
    fetchForecastDataDaily: useFetchForecastDataDaily,
    fetchForecastDataMonthly: useFetchForecastDataMonthly,
  };
}
