import React, { useCallback, useState } from 'react';
import moment from 'moment/moment';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import classNames from 'classnames';
import RowLikeAccordion from 'shared/components/RowLikeAccordion';
import Button from 'shared/components/andtComponents/Button';
import SwitchButton from 'shared/components/andtComponents/Switch';
import Modal from 'shared/components/andtComponents/Modal';
import SinglePreferenceForm from './SinglePreferenceForm';
import styles from './preferences.module.scss';
import { RecommendationPreference } from './types.ts';

export interface SinglePreferenceProps {
  uuid: string;
  preference: RecommendationPreference;
  isExpanded?: boolean;
  actionsDisabled?: boolean;

  onExpand(e: any, expanded: boolean): void;

  onFieldChange(key: string, value: string | number | boolean): void;

  onSave?(): void;
}

export const SinglePreferenceRow: React.FC<SinglePreferenceProps> = ({
  onExpand,
  actionsDisabled,
  preference,
  isExpanded,
  onFieldChange,
  onSave,
  uuid,
}) => {
  const [preferenceCopy] = useState(preference);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const isEnabled = preference.preferences.enabled !== false;
  const isSomeInfoEmpty = Object.values(preference.preferences || {}).some((k) => k === '');

  const onSaveClick = useCallback(() => {
    onSave?.();
    onExpand(undefined, false);
  }, [onExpand, onSave]);

  const handleExpendedRowClick = useCallback(() => {
    if (JSON.stringify(preference) === JSON.stringify(preferenceCopy)) {
      onExpand(undefined, false);
    } else {
      setShowWarningModal(true);
    }
  }, [preference, onExpand, setShowWarningModal, preferenceCopy]);

  const onLocalExpend = useCallback(() => {
    if (isExpanded) {
      handleExpendedRowClick();
    } else {
      onExpand(preference, true);
    }
  }, [isExpanded, preference, onExpand, handleExpendedRowClick]);

  const onCancelEditing = useCallback(() => onExpand(undefined, false), [onExpand]);

  return (
    <>
      {isExpanded ? (
        <div key={preference.uuid} className={styles.editOverlay} onClick={handleExpendedRowClick} />
      ) : null}
      <RowLikeAccordion
        automationid={`pref-row-${preference.uuid}`}
        className={classNames(styles.preferenceRow, {
          [styles.rowExpanded]: isExpanded,
          [styles.rowEdit]: isExpanded,
        })}
        isExternalExpand={isExpanded}
        parentOverrideExpend={onLocalExpend}
        headerContent={
          <div className={styles.rowSummary}>
            <span
              style={{
                marginLeft: '5px',
              }}
            >
              {preference.formattedTitle}
            </span>
            <span>{preference.createdBy || 'Default'}</span>
            {preference.createdBy ? (
              <span>{moment(preference.updateTime).format('DD MMM HH:mm')}</span>
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginRight: '68px',
                }}
              >
                {'-'}
              </div>
            )}
          </div>
        }
        actionsBlock={
          <div className="d-flex align-items-center">
            <span className={styles.activeLabel} style={{ opacity: isExpanded ? 1 : 0.7 }}>
              {isEnabled ? 'ACTIVE' : 'INACTIVE'}
            </span>
            <SwitchButton
              automationid="pref-enable-switch"
              isChecked={isEnabled}
              onChange={(checked, e) => {
                e.preventDefault();
                e.stopPropagation();
                onFieldChange('enabled', checked);
              }}
              isDisabled={!isExpanded}
              style={{ marginLeft: 16 }}
            />
          </div>
        }
      >
        {isExpanded && (
          <div key={`container-${uuid}`} className={styles.preferenceFormContainer}>
            <div key={`title-${uuid}`} className={styles.formHeader}>
              <h6>General Preferences</h6>
            </div>
            <SinglePreferenceForm
              key={uuid}
              preference={preference}
              disabled={actionsDisabled || !isExpanded}
              onFieldChange={onFieldChange}
            />
          </div>
        )}
        <div key={`actions-${uuid}`} className="d-flex pt-3 align-items-center">
          <Button
            automationid="pref-save-button"
            className={{ button: 'me-3' }}
            text="Save Changes"
            disabled={isSomeInfoEmpty}
            onClick={onSaveClick}
          />
          <Button automationid="pref-cancel-button" text="Cancel" isSecondary onClick={onCancelEditing} />
          <div className={styles.warningMessage}>
            <GenerateIcon iconName={ICONS.circleExclamationRegular.name} /> The updated preferences will be used from
            the next invoice.
          </div>
        </div>
      </RowLikeAccordion>

      {showWarningModal && (
        <Modal
          automationid="pref-warning-modal"
          onClose={() => setShowWarningModal(false)}
          saveTitle="Discard"
          secondPrimaryTitle="Keep Editing"
          isSecondPrimaryError={false}
          cancelHidden
          onSecondPrimaryClick={() => setShowWarningModal(false)}
          open
          onSave={onCancelEditing}
          saveWarning
        >
          <div>There are unsaved changes in the preference rule. Discard them or go back and save them.</div>
        </Modal>
      )}
    </>
  );
};

export default SinglePreferenceRow;
