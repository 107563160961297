import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from '@mui/material/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import { PageNames } from 'shared/constants/appConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import FieldSidebarFilter from 'shared/components/FilterSidebar/FieldSidebarFilter';
import { FilterTypes } from 'usage/constants/usageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { useSpAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import styles from './analyzerFilters.module.scss';

const coverageInfo = `Set what percentage of your compute costs should be covered by commitments.
 For example, 80% coverage means 80% of costs will be under commitments, while 20% remains on-demand.`;

const excludeExpiringSPsDaysInfo = `Exclude SPs expiring within the selected days.
 These are treated as inactive, resulting in higher simulated on-demand cost`;
const calculatorIcon = () => <GenerateIcon iconName={ICONS.calculator.name} />;
const AnalyzerFilters = ({ analyze, disabled }) => {
  const {
    daysToCheck,
    setDaysToCheck,
    coverage,
    setCoverage,
    excludeExpiringSPsDays,
    setExcludeExpiringSPsDays,
    filterStatusType,
    setFilterStatusType,
    selectedOptions,
    setSelectedOptions,
    preferences,
    viewType,
    setViewType,
  } = useSpAnalyzerContext();
  const { getPageFilters } = useInvoiceFilters();
  const { usersStore } = useRootStore();
  const isSharedResellerCustomer = usersStore.isCurrentUserReCustomer;
  const spDistincValuesMap = getPageFilters(PageNames.SAVINGS_PLANS_ANALYZER, usersStore.currDispUserCloudAccountType);
  const [isSimulated, setIsSimulated] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const linkedAccountNames = useMemo(() => {
    const linkedAccFilter = spDistincValuesMap?.get(AwsCommonFields.LINKED_ACCOUNT_ID) || [];
    return linkedAccFilter.map((ln) => ({
      label: ln.linkedAccountName,
      value: ln.linkedAccountId,
    }));
  }, [spDistincValuesMap]);

  const isInValid = {
    linkedAccount: viewType === 'linkedAccount' && !selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length,
    daysToCheck: !daysToCheck || daysToCheck < 1 || daysToCheck > 45,
    coverage: !coverage || coverage < 0 || coverage > 100,
    excludeDays: excludeExpiringSPsDays && (excludeExpiringSPsDays < 1 || excludeExpiringSPsDays > 50),
  };

  const handleFilterChange = (fieldName, selection) => {
    setSelectedOptions({ ...selectedOptions, [fieldName]: Array.isArray(selection) ? selection : [selection] });
  };
  const handleFilterTypeChange = (field) => {
    const filterTypeValue = filterStatusType[field] === FilterTypes.INCLUDE ? FilterTypes.EXCLUDE : FilterTypes.INCLUDE;
    setFilterStatusType({ ...filterStatusType, [field]: filterTypeValue });
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.fieldsWrapper}>
        <div className={styles.groupFields}>
          {!isSharedResellerCustomer && (
            <div
              className={`${styles.fieldWrapper}  ${
                selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
              }`}
            >
              <span className={styles.headerField}>Saving Plan Scope</span>
              <div className={styles.scopeWrapper}>
                <RadioButtonList
                  className={styles.radioList}
                  optionClassName={styles.option}
                  options={[
                    {
                      label: 'Payer Account',
                      name: 'payer',
                      value: 'payer',
                      primary: true,
                      automationid: 'payerRadio',
                    },
                    {
                      label: 'Linked Account',
                      name: 'linkedAccount',
                      value: 'linkedAccount',
                      primary: true,
                      automationid: 'linkedAccountRadio',
                    },
                  ]}
                  value={viewType}
                  onChange={(val) => {
                    setViewType(val);
                    if (val === 'payer') {
                      setSelectedOptions({});
                    }
                  }}
                />
              </div>
            </div>
          )}
          <div
            className={`${styles.fieldWrapper} ${styles.linkedAccWrapper} ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            } ${isSharedResellerCustomer && styles.padding}`}
          >
            <span>Linked Account</span>
            <FieldSidebarFilter
              excludeMode={filterStatusType[AwsCommonFields.LINKED_ACCOUNT_ID] === FilterTypes.EXCLUDE}
              automation-id={`heatmap-${AwsCommonFields.LINKED_ACCOUNT_ID}-filter`}
              handleChange={handleFilterChange}
              handleChangeFilterType={handleFilterTypeChange}
              label="Linked Account"
              field={AwsCommonFields.LINKED_ACCOUNT_ID}
              isDisabled={viewType === 'payer'}
              disableExclude={true}
              className={{
                sideBarField: styles.sideBarField,
                fieldNameContainer: styles.fieldNameContainer,
                badgeContainer: styles.badgeContainer,
              }}
              isOneChoiceFieldFilter
              selectedOptions={selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]}
              options={linkedAccountNames}
              onOpen={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
              toFormatData={false}
            />
            {isSimulated && isInValid.linkedAccount && !isMenuOpen && (
              <span className={styles.error}>Select linked account</span>
            )}
          </div>
        </div>
        <div className={styles.groupFields}>
          <div
            className={`${styles.fieldWrapper} ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            }`}
          >
            <span className={styles.headerField}>
              Days To Check{' '}
              <span className={styles.originValue}>
                {preferences?.daysToCheck ? `(preferences ${preferences?.daysToCheck})` : ''}
              </span>
            </span>
            <Input
              type="number"
              min={1}
              max={45}
              automationid="daysToCheck"
              className={styles.input}
              value={daysToCheck}
              onChange={(e) => {
                setDaysToCheck(e.target.value);
              }}
            />
            {isSimulated && isInValid.daysToCheck && (
              <span className={styles.error}>Value must be between 1 and 45</span>
            )}
          </div>
          <div
            className={`${styles.fieldWrapper} ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            }`}
          >
            <div className={styles.coverageWrapper}>
              <span className={styles.headerField}>
                Coverage %
                <span className={styles.originValue}>
                  {preferences?.coveragePercentage ? ` (preferences ${preferences?.coveragePercentage * 100})` : ''}
                </span>
              </span>
              <Tooltip arrow title={coverageInfo}>
                <span className={styles.infoIcon}>
                  <GenerateIcon iconName={ICONS.circleInfo.name} />
                </span>
              </Tooltip>
            </div>
            <Input
              min={0}
              max={100}
              type="number"
              className={styles.input}
              automationid="coverage"
              value={coverage}
              onChange={(e) => {
                setCoverage(e.target.value);
              }}
            />
            {isSimulated && isInValid.coverage && <span className={styles.error}>Value must be between 1 and 100</span>}
          </div>
          <div
            className={`${styles.fieldWrapper} ${
              selectedOptions[AwsCommonFields.LINKED_ACCOUNT_ID]?.length && styles.includeChip
            }`}
          >
            <div className={styles.coverageWrapper}>
              <span className={styles.headerField}>
                Exclude Expiring SP<span className={styles.originValue}> (Optional)</span>
              </span>
              <Tooltip arrow title={excludeExpiringSPsDaysInfo}>
                <span className={styles.infoIcon}>
                  <GenerateIcon iconName={ICONS.circleInfo.name} />
                </span>
              </Tooltip>
            </div>
            <Input
              min={0}
              max={100}
              type="number"
              placeholder="Enter Days"
              className={styles.input}
              automationid="excludeExpiringSPsDays"
              value={excludeExpiringSPsDays}
              onChange={(e) => {
                setExcludeExpiringSPsDays(e.target.value);
              }}
            />
            {isSimulated && isInValid.excludeDays ? (
              <span className={styles.error}>Value must be between 1 and 50</span>
            ) : null}
          </div>
          <div className={`${styles.buttonWrapper}`}>
            <Button
              text="Simulate"
              disabled={disabled}
              className={{ button: styles.analyzeBtn }}
              icon={calculatorIcon}
              onClick={() => {
                setIsSimulated(true);
                if (!Object.values(isInValid).some((val) => val)) {
                  analyze();
                }
              }}
              automationid="analyzeBtn"
            />
          </div>
        </div>
      </div>
      {/* <div className={styles.calculateWrapper}> */}
      {/* <div> */}
      {/*  <Checkbox */}
      {/*    onChange={() => {}} */}
      {/*    text="Calculate based on savings plans that expire up to 30 days" */}
      {/*    className={styles.checkbox} */}
      {/*  /> */}
      {/*  <Tooltip arrow title={calculateInfo}> */}
      {/*    <span> */}
      {/*      <GenerateIcon iconName={ICONS.circleInfo.name} className={styles.infoIcon} /> */}
      {/*    </span> */}
      {/*  </Tooltip> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

AnalyzerFilters.propTypes = {
  analyze: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AnalyzerFilters;
