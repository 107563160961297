import React, { ComponentType, createContext, useContext, useMemo } from 'react';
import { getBrandByDomain } from './configurations/Utils';
import { observer } from 'mobx-react';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { BrandTypes, Themes } from './enums';
import { Brand } from './types';
import { ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';
import { updateAppConfigurationForChangedBrand } from 'config';

const BrandContext = createContext<Brand | null>(getBrandByDomain(Themes.Default));

export const useBrand = (): Brand => {
  const context = useContext<Brand | null>(BrandContext);
  return context ?? ({} as Brand);
};

const getBrandValue = (isLoginPage: boolean, shouldUseUmbrellaFlag: boolean, currUserTheme?: string): Brand => {
  currUserTheme = currUserTheme ?? Themes.Default;
  if (isLoginPage && !shouldUseUmbrellaFlag) {
    return getBrandByDomain(currUserTheme);
  }

  return shouldUseUmbrellaFlag
    ? getBrandByDomain(currUserTheme, BrandTypes.Umbrella)
    : getBrandByDomain(currUserTheme, BrandTypes.Anodot);
};

export const withBrandContextProvider = <P extends object>(Component: ComponentType<P>, rootStore: any) => {
  const BrandContextProvider = (props: Readonly<P>) => {
    const isLoginPage = useMemo(() => {
      const path = window.location.pathname;
      const inHomeButLoggedOut = path === '/';

      return (
        !rootStore.usersStore.authUserKey ||
        inHomeButLoggedOut ||
        path.includes(Routes.LOG_IN) ||
        path.includes(Routes.REGISTER)
      );
    }, []);

    const shouldUseUmbrellaFlag = checkFeatureFlag(rootStore.usersStore, ACCOUNT_FEATURES.NEW_REBRANDING);
    const currUserTheme = rootStore.usersStore.getCurrDisplayedUserTheme();

    const brandValue = useMemo(
      () => getBrandValue(isLoginPage, shouldUseUmbrellaFlag, currUserTheme),
      [isLoginPage, shouldUseUmbrellaFlag, currUserTheme],
    );
    updateAppConfigurationForChangedBrand(brandValue);
    return (
      <BrandContext.Provider value={brandValue}>
        <Component {...props} />
      </BrandContext.Provider>
    );
  };

  return observer(BrandContextProvider);
};
