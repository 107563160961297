import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Switch from '~/shared/components/andtComponents/SwitchButton/Switch.jsx';
import { palette } from '~/shared/constants/colorsConstants.js';
import ReadOnlyDisplayWrapper from '~/shared/components/ReadOnlyDisplayWrapper.jsx';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';

import styles from './OrgCustomersSwitchButton.module.scss';

const getOptions = (isCustomersView) => [
  {
    icon: ICONS.building.name,
    isSelected: isCustomersView === false,
    color: palette.blue[500],
    tooltip: 'My Organization',
  },
  { icon: ICONS.userGroup.name, isSelected: isCustomersView === true, color: palette.blue[500], tooltip: 'Customers' },
];

const OrgCustomersSwitchButton = ({ category, action, isCustomersView, onOrgCustomersToggle }) => {
  const optionsList = useMemo(() => Object.values(getOptions(isCustomersView)), [isCustomersView]);

  const changeView = async (e, option) => {
    e.stopPropagation();
    onOrgCustomersToggle(option.icon === ICONS.userGroup.name);
  };

  return (
    <ReadOnlyDisplayWrapper isHide={false} category={category} action={action}>
      <Switch
        isDisabled={false}
        isLoading={false}
        onChange={changeView}
        options={optionsList}
        className={styles.option}
        backgroundColor={palette.gray[200]}
      />
    </ReadOnlyDisplayWrapper>
  );
};

OrgCustomersSwitchButton.propTypes = {
  action: PropTypes.string,
  category: PropTypes.string,
  isCustomersView: PropTypes.bool,
  onOrgCustomersToggle: PropTypes.func,
};

export default OrgCustomersSwitchButton;
