import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import EditRoleLinkedAccountsModal from '../roleModals/EditRoleLinkedAccountsModal/EditRoleLinkedAccountsModal';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import styles from './AddToRoleButton.module.scss';

const EditRoleLinkedAccounts = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    setIsOpen(true);
  };

  return (
    <Tooltip title="Edit Linked Accounts" placement="top">
      <span>
        <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Roles} action={Action.Update}>
          <Button
            automationid="editRoleLinkedAccounts"
            className={{ button: styles.addUsers }}
            disabled={!row?.editable}
            icon={() => <GenerateIcon iconName={ICONS.addLinkedAccounts.name} className={styles.addUsersIcon} />}
            onClick={onClick}
            text=""
          />
          <EditRoleLinkedAccountsModal isOpen={isOpen} setIsOpen={setIsOpen} role={row} />
        </ReadOnlyDisplayWrapper>
      </span>
    </Tooltip>
  );
};

EditRoleLinkedAccounts.propTypes = {
  row: PropTypes.string.isRequired,
};

export default EditRoleLinkedAccounts;
