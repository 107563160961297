import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  getInitialFiltersStateCopy,
  isFiltersEqualToInitialState,
} from 'recommendationsNew/components/heatMap/heatMapFilters/filterStates.js';
import { FilterTypes } from 'usage/constants/usageConstants';
import { transformKeys } from 'recommendationsNew/consts.js';
import { useFilterStatus } from '../hooks/useFilterStatus';

const RecommendationsNewContext = createContext();
const defaultHiddenColumns = ['monthlySavings', 'labels', 'recId'];

export const RecommendationsNewProvider = ({ children }) => {
  const initFiltersState = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const filterContextParam = searchParams.get('filterContext');
    if (filterContextParam) {
      return transformKeys(JSON.parse(filterContextParam));
    }
    return getInitialFiltersStateCopy();
  };
  const [recommendationFilters, setRecommendationFilters] = useState(initFiltersState());
  const [recommendationExternalFilters, setRecommendationExternalFilters] = useState(null);
  const [isDashboardPanel, setIsDashboardPanel] = useState(window.location.pathname.includes('dashboards/'));
  const [panelCloudType, setPanelCloudType] = useState();
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const [hiddenColumnNames, setHiddenColumnNames] = useState([...defaultHiddenColumns]);
  const [showCustomColumnsNames, setShowCustomColumnsNames] = useState([]);
  const statusFilter = useFilterStatus(recommendationFilters);

  useEffect(() => {
    const isEqual = isFiltersEqualToInitialState(recommendationFilters);
    setIsFiltersChanged(!isEqual);
  }, [recommendationFilters]);

  const updateExcludedAdditionalFilters = (filterId) => {
    if (!filterId) {
      return;
    }
    if (!recommendationFilters?.excludedAdditionalFilters) {
      setRecommendationFilters({ ...recommendationFilters, excludedAdditionalFilters: {} });
    }

    let updatedFilters = { ...recommendationFilters.excludedAdditionalFilters };
    if (updatedFilters?.[filterId]) {
      delete updatedFilters[filterId];
    } else {
      updatedFilters = { ...updatedFilters, [filterId]: FilterTypes.EXCLUDE };
    }
    setRecommendationFilters({ ...recommendationFilters, excludedAdditionalFilters: updatedFilters });
  };

  const showOpenOnly = statusFilter.isOnlyOpen;

  const showDoneCompletedOnly = statusFilter.isDoneOrCompletedOnly;

  return (
    <RecommendationsNewContext.Provider
      value={{
        isDashboardPanel,
        isFiltersChanged,
        recommendationExternalFilters,
        recommendationFilters,
        setIsDashboardPanel,
        setRecommendationFilters,
        setRecommendationExternalFilters,
        updateExcludedAdditionalFilters,
        showOpenOnly,
        showDoneCompletedOnly,
        hiddenColumnNames,
        setHiddenColumnNames,
        showCustomColumnsNames,
        setShowCustomColumnsNames,
        initFiltersState,
        panelCloudType,
        setPanelCloudType,
      }}
    >
      {children}
    </RecommendationsNewContext.Provider>
  );
};

RecommendationsNewProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useRecommendationsNewContext = () => useContext(RecommendationsNewContext);
