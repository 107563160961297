import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import { AwsCommonFields, CUSTOM_TAGS_NOT_TAGGED, K8S_CUE_FIELDS } from 'shared/constants/awsConstants';
import { cloneDeep, groupBy, isEqual } from 'lodash';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { Collapse, IconButton } from '@mui/material';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import CustomModal from 'shared/components/andtComponents/Modal';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import Input from 'shared/components/andtComponents/Input';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import Button from 'shared/components/andtComponents/Button';
import ErrorMessage from 'shared/components/andtComponents/ErrorMessage';
import SwitchButton from 'shared/components/andtComponents/Switch';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import { k8sFiltersNodesFields, k8sFiltersPodsFields } from 'kubernetes/constants/k8sConstants';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { ALL_TAGS_FIELD, isCustomDivisionNotFinished, SPLIT_TYPES } from '../../constants/businessMappingConstants';
import FilterSelector from '../FilterSelector';
import BusinessMappingSplitBlock from '../BusinessMappingSplitBlock/BusinessMappingSplitBlock';
import styles from './CreateBusinessMappingGroupModal.module.scss';

const formatFieldLabel = (field, valuesList, value) => {
  if (field === AwsCommonFields.LINKED_ACCOUNT_ID) {
    const options = valuesList.get(field) || [];
    const selected = options.find((opt) => opt.linkedAccountId === value) || {};
    return selected.displayLabel || value;
  }
  if (field === AwsCommonFields.VIRTUAL_CUSTOM_TAGS) {
    const options = valuesList.get(field) || [];
    const selected = options.find((opt) => opt.uuid === value) || {};
    return selected.name || value;
  }
  return value;
};

export const isCauK8SFilter = (field) => K8S_CUE_FIELDS.includes(field);

export const isCauSharedWithK8SFilter = (field) =>
  [
    AwsCommonFields.REGION,
    AwsCommonFields.INSTANCE_TYPE,
    AwsCommonFields.CUSTOM_TAGS,
    AwsCommonFields.LINKED_ACCOUNT_ID,
  ].includes(field);

const GLOBAL_IMPORT_STATUSES = {
  DEFAULT: 'default',
  EDIT: 'edit',
  DUPLICATE: 'duplicate',
};

const getSettings = (editOrClone, group) => ({
  isSplit: editOrClone ? group.split : false,
  mappings: editOrClone && group.split ? group.splitOptions.mappings : [],
  splitType: editOrClone && group.split ? group.splitOptions.splitType : SPLIT_TYPES.EQUAL,
  customDivision: editOrClone && group.split ? group.splitOptions.customDivision || {} : {},
});

const getFilters = (editOrClone, group, fieldToFieldDistinctValuesMap) =>
  editOrClone
    ? group.filters.map((f) => ({
        ...f,
        ruleId: f.ruleId || 1,
        values:
          typeof f.values === 'string'
            ? f.values
            : f.values.map((value) => ({
                label: formatFieldLabel(f.field, fieldToFieldDistinctValuesMap, value),
                value,
              })),
      }))
    : [
        {
          field: '',
          values: [],
          prefix: '',
          operator: OPERATORS_KEYS.IS,
          ruleId: 1,
        },
      ];

const CreateBusinessMappingGroupModal = ({
  closeModal,
  isOpen,
  isEditMode = false,
  isImportGlobalMode: isImportGlobalModeProp = false,
  isCloneMode = false,
  onSave,
  group = null,
  groups,
  usersStore,
  isLoading = false,
  isGlobalMode = false,
  takenNames,
  globalMappings,
  isK8S: isK8SFromProps = false,
  isK8SPods: isPodsFromProps = false,
  getAccountTagsKeys,
  getPageFilters,
  getK8sCustomTagsKeys,
  getCustomTagsKeys,
  customTagsFetcher,
  virtualTagsFetcher,
  getVirtualTagsKeys,
  accountTagsFetcher,
  k8sLabelsFetcher,
  resourcesFetcher,
  usageTypesFetcher,
  namespacesFetcher,
  podsFetcher,
}) => {
  const editOrClone = isEditMode || isCloneMode;
  const getMappingDefaultName = () => {
    if (isEditMode) {
      return group.name;
    }
    if (isCloneMode) {
      return `${group.name} Copy`;
    }
    return '';
  };
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const [isK8S, setIsK8S] = useState(group ? group.k8s : isK8SFromProps);
  const [k8sPods, setK8SPods] = useState(group && group.k8sConfig ? group.k8sConfig.podsView : isPodsFromProps);
  const isGCP = usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP;
  const k8sSupport = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.K8S);
  const fieldToFieldDistinctValuesMap = (() => {
    const filters = getPageFilters(
      isK8S ? PageNames.BUSINESS_MAPPING_K8S : PageNames.BUSINESS_MAPPING,
      usersStore.currDispUserCloudAccountType,
    );
    if (!k8sSupport || isK8S) {
      K8S_CUE_FIELDS.forEach((key) => {
        filters.delete(key);
      });
    }
    if (!isK8S || !isGCP) {
      return filters;
    }
    let fieldsToRemove = [];
    if (!k8sPods) {
      fieldsToRemove = k8sFiltersPodsFields;
    } else {
      fieldsToRemove = k8sFiltersNodesFields;
    }
    fieldsToRemove.forEach((field) => {
      if (filters.has(field)) {
        filters.delete(field);
      }
    });
    return filters;
  })();
  const [name, setName] = React.useState(getMappingDefaultName());
  const [selectedGlobal, setSelectedGlobal] = React.useState(null);
  const [importWayModalOpen, setImportWayModalOpen] = React.useState(false);
  const [selectedGlobalStatus, setSelectedGlobalStatus] = React.useState(GLOBAL_IMPORT_STATUSES.DEFAULT);
  const [isImportGlobalMode, setIsImportGlobalMode] = React.useState(isImportGlobalModeProp);

  const defaultSettings = getSettings(editOrClone, group);
  const [settings, setSettings] = React.useState(defaultSettings);
  const handleUpdateSetting = (field) => (value) => setSettings({ ...settings, [field]: value });
  const defaultFilter = getFilters(editOrClone, group, fieldToFieldDistinctValuesMap);
  const [filters, setFilters] = React.useState(defaultFilter);
  useEffect(() => {
    if (!mounted) {
      return;
    }
    setFilters(getFilters(false));
  }, [isK8S]);
  const handleClose = () => {
    if (
      (isEqual(defaultFilter, filters) && isEqual(defaultSettings, settings)) ||
      window.confirm('You have unsaved changes, close anyway?')
    ) {
      closeModal();
    }
  };
  const [ruleClosedObj, setRuleClosed] = React.useState({});
  const [filterClosedObj, setFilterClosedObj] = React.useState({});

  useEffect(() => {
    if (!selectedGlobal) {
      return;
    }
    setSelectedGlobalStatus(GLOBAL_IMPORT_STATUSES.DEFAULT);
    setFilters(getFilters(true, selectedGlobal, fieldToFieldDistinctValuesMap));
    setSettings(getSettings(true, selectedGlobal));
  }, [selectedGlobal]);

  const getK8sFilterSelectedInRule = (ruleId) =>
    filters.filter((f) => f.ruleId === ruleId).some((f) => isCauK8SFilter(f.field));
  const filtersGroupedByRule = groupBy(
    filters.map((f, index) => ({ ...f, index })),
    (a) => a.ruleId,
  );
  const customDivisionNotFinished =
    settings.isSplit &&
    settings.splitType === SPLIT_TYPES.CUSTOM &&
    (isCustomDivisionNotFinished(settings.customDivision) ||
      Object.values(settings.customDivision).length !== settings.mappings.length);
  const combineData = () => ({
    name,
    viewpointId: selectedGlobal ? selectedGlobal.uuid : undefined,
    filters: filters
      .filter((f) => f.field && f.values && f.operator)
      .filter(
        (f) => !getK8sFilterSelectedInRule(f.ruleId) || isCauK8SFilter(f.field) || isCauSharedWithK8SFilter(f.field),
      )
      .map((f) => ({
        ...f,
        values: Array.isArray(f.values) ? f.values.map((filter) => filter.value) : f.values,
      })),
    creationTime: isEditMode ? group.creationTime : undefined,
    createdBy: isEditMode ? group.createdBy : undefined,
    rank: isEditMode ? group.rank : undefined,
    k8s: isK8S,
    k8s_config: { pods_view: k8sPods },
    split: settings.isSplit && !isEmptyArray(settings.mappings),
    splitOptions:
      settings.isSplit && !isEmptyArray(settings.mappings)
        ? {
            mappings: settings.mappings,
            splitType: settings.splitType,
            customDivision: settings.customDivision,
          }
        : null,
  });
  const getFiltersConfig = () => ({
    [AwsCommonFields.CUSTOM_TAGS]: {
      onLoad: customTagsFetcher.onLoad,
      isLoading: customTagsFetcher.isLoading,
      isAutoComplete: true,
      subMenu: true,
      keys: getCustomTagsKeys(),
      values: customTagsFetcher.values,
      showNotAllocated: true,
      selectAll: true,
    },
    [AwsCommonFields.ACCOUNT_TAGS]: {
      onLoad: accountTagsFetcher.onLoad,
      isLoading: accountTagsFetcher.isLoading,
      values: accountTagsFetcher.values,
      isAutoComplete: true,
      subMenu: true,
      keys: getAccountTagsKeys(),
    },
    [AwsCommonFields.K8S_LABEL_TAGS]: {
      onLoad: k8sLabelsFetcher.onLoad,
      isLoading: k8sLabelsFetcher.isLoading,
      isAutoComplete: true,
      subMenu: true,
      values: k8sLabelsFetcher.values,
      keys: getK8sCustomTagsKeys(),
      showNotAllocated: true,
    },
    [AwsCommonFields.RESOURCE]: {
      onLoad: resourcesFetcher.onLoad,
      isLoading: resourcesFetcher.isLoading,
      values: resourcesFetcher.values,
      isAutoComplete: true,
    },
    [AwsCommonFields.CUE_K8S_LABEL_TAGS]: {
      onLoad: k8sLabelsFetcher.onLoad,
      isLoading: k8sLabelsFetcher.isLoading,
      isAutoComplete: true,
      subMenu: true,
      values: k8sLabelsFetcher.values,
      keys: getK8sCustomTagsKeys(),
    },
    [AwsCommonFields.USAGE_TYPE]: {
      onLoad: usageTypesFetcher.onLoad,
      values: usageTypesFetcher.values,
      isLoading: usageTypesFetcher.isLoading,
      isAutoComplete: true,
    },
    [AwsCommonFields.NAMESPACE]: {
      onLoad: namespacesFetcher.onLoad,
      values: namespacesFetcher.values,
      isLoading: namespacesFetcher.isLoading,
      isAsyncLoad: true,
    },
    [AwsCommonFields.K8S_NAMESPACE]: {
      onLoad: namespacesFetcher.onLoad,
      values: namespacesFetcher.values,
      isLoading: namespacesFetcher.isLoading,
      isAsyncLoad: true,
    },
    [ALL_TAGS_FIELD]: {
      notStrictMatch: true,
    },
    [AwsCommonFields.ITEM_DESCRIPTION]: {
      notStrictMatch: true,
      isAutoComplete: true,
    },
    [AwsCommonFields.REGION]: {
      strictMatch: true,
    },
    [AwsCommonFields.POD]: {
      isAsyncLoadClick: true,
      onLoad: podsFetcher.onLoad,
      values: podsFetcher.values,
      isLoading: podsFetcher.isLoading,
    },
    [AwsCommonFields.PURCHASE_OPTION]: {
      strictPositiveMatch: true,
    },
    [AwsCommonFields.VIRTUAL_CUSTOM_TAGS]: {
      onLoad: virtualTagsFetcher.onLoad,
      isLoading: virtualTagsFetcher.isLoading,
      isAutoComplete: true,
      subMenu: true,
      keys: getVirtualTagsKeys(),
      values: virtualTagsFetcher.values,
    },
    [AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS]: {
      strictMatch: true,
    },
  });
  const getModalTitle = () => {
    if (isImportGlobalMode) {
      return 'Import Global Mapping';
    }
    if (isCloneMode) {
      return 'Clone Mapping';
    }
    if (isEditMode) {
      if (isGlobalMode) {
        return 'Edit Global Mapping';
      }
      return 'Edit Mapping';
    }
    if (isGlobalMode) {
      return 'Create Global Mapping';
    }
    return 'Create Mapping';
  };
  const newRuleEnabled =
    filters.length === 0 ||
    (filters.length > 0 &&
      (filters.length > 1 || filters[0].values.length > 0 || filters[0].prefix === CUSTOM_TAGS_NOT_TAGGED));
  return (
    <CustomModal
      open={isOpen}
      onClose={handleClose}
      closeOnSave={false}
      title={getModalTitle()}
      className={styles.modalContainer}
      overrideStyles={{ width: 800 }}
      saveDisabled={
        isLoading ||
        (!name && !isImportGlobalMode) ||
        !filters.length ||
        takenNames.includes(name) ||
        customDivisionNotFinished ||
        filters.some((f) => (!f.values || f.values.length === 0) && f.prefix !== CUSTOM_TAGS_NOT_TAGGED)
      }
      onSave={() => {
        segmentEvent({ type: 'click-event', target: 'create_business_mapping_group_save' }, usersStore);
        let groupId;
        if (isEditMode) {
          groupId = group.uuid;
        }
        if (isImportGlobalMode) {
          groupId = selectedGlobal.uuid;
        }
        return onSave({ group: combineData(), groupId }, isImportGlobalMode);
      }}
    >
      <div className={`${styles.container} show-scroll`}>
        <div className={styles.titleLine}>
          <label htmlFor="mapping-name" className={styles.input}>
            <span>Mapping name</span>
            {isImportGlobalMode ? (
              <SimpleSelect
                isClearable={false}
                value={selectedGlobal}
                onChange={(value) => {
                  setSelectedGlobal(value);
                  setName(value.name);
                }}
                options={globalMappings.map((m) => ({
                  label: m.name,
                  value: m,
                }))}
              />
            ) : (
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Name"
                name="mapping-name"
                type="text"
              />
            )}
          </label>
          <Button
            text="Add Rule"
            icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
            isGhost
            disabled={!newRuleEnabled || (selectedGlobalStatus === GLOBAL_IMPORT_STATUSES.DEFAULT && !!selectedGlobal)}
            onClick={() => {
              segmentEvent({ type: 'click-event', target: 'create_business_mapping_add_rule' }, usersStore);
              setFilters([
                ...filters,
                {
                  field: '',
                  values: [],
                  operator: OPERATORS_KEYS.IS,
                  ruleId: Math.max(Math.max(...Object.keys(filtersGroupedByRule)), 0) + 1,
                },
              ]);
            }}
          />
        </div>
        {isGlobalMode && k8sSupport && !isEditMode ? (
          <div className="d-flex align-items-center mb-3">
            <SwitchButton label="Kubernetes Viewpoint" isChecked={isK8S} onChange={() => setIsK8S(!isK8S)} />
            <Tooltip title="Base this Viewpoint on K8s Cost & Usage" arrow>
              <span className="ms-2">
                <InfoIcon />
              </span>
            </Tooltip>
          </div>
        ) : null}
        {isGlobalMode && isK8S && k8sSupport && !isEditMode && isGCP && (
          <Tooltip title="Choose to query data by Nodes or Pods.">
            <div className={styles.radioButtonList}>
              <RadioButtonList
                options={[
                  { value: 'nodes', label: 'Nodes', primary: true },
                  { value: 'pods', label: 'Pods', primary: true },
                ]}
                value={k8sPods ? 'pods' : 'nodes'}
                onChange={(val) => {
                  setK8SPods(val === 'pods');
                }}
              />
            </div>
          </Tooltip>
        )}
        <div className={styles.filtersContainer}>
          {/* block editing if user didnt choose how to import global mapping */}
          {selectedGlobalStatus === GLOBAL_IMPORT_STATUSES.DEFAULT && !!selectedGlobal ? (
            <div onClick={() => setImportWayModalOpen(true)} className={styles.importBlocker} />
          ) : null}
          {Object.keys(filtersGroupedByRule).map((ruleIdStr, ruleIndex) => {
            const ruleId = Number(ruleIdStr);
            return (
              <React.Fragment key={ruleId}>
                {ruleIndex > 0 && (
                  <div className={styles.divider}>
                    OR
                    <span />
                  </div>
                )}
                <div className={styles.ruleTitle} data-first={ruleIndex === 0}>
                  <div className={styles.ruleName}>Rule #{ruleIndex + 1}</div>
                  <div className={styles.ruleTitleMenu}>
                    <IconButton
                      onClick={() =>
                        setRuleClosed({
                          ...ruleClosedObj,
                          [ruleId]: !ruleClosedObj[ruleId],
                        })
                      }
                      size="large"
                    >
                      <GenerateIcon
                        iconName={ruleClosedObj[ruleId] ? ICONS.arrowsToLine.name : ICONS.arrowsFromLine.name}
                      />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setFilters(filters.filter((f) => f.ruleId !== ruleId));
                      }}
                      size="large"
                    >
                      <GenerateIcon iconName={ICONS.delete.name} />
                    </IconButton>
                  </div>
                </div>
                <Collapse in={!ruleClosedObj[ruleId]} className="ms-2">
                  {filtersGroupedByRule[ruleId].map((filter, index) => (
                    <React.Fragment key={filter.index}>
                      {index > 0 && (
                        <div className={styles.divider} style={{ marginLeft: 20 }}>
                          AND
                          <span />
                        </div>
                      )}
                      <div className={styles.condition}>
                        <FilterSelector
                          currDispUserCloudAccountType={usersStore.currDispUserCloudAccountType}
                          index={index}
                          collapsed={filterClosedObj[filter.index]}
                          filterProps={getFiltersConfig()}
                          handleChange={(newFilter) => {
                            setFilters(
                              filters.map((oldFilter, filterInd) => {
                                if (filter.index === filterInd) {
                                  return newFilter;
                                }
                                return oldFilter;
                              }),
                            );
                          }}
                          handleCollapse={() => {
                            setFilterClosedObj({
                              ...filterClosedObj,
                              [filter.index]: !filterClosedObj[filter.index],
                            });
                          }}
                          handleDuplicate={() => {
                            setFilters([...filters, cloneDeep(filter)]);
                          }}
                          handleRemove={() => {
                            setFilters(filters.filter((_, filterInd) => filter.index !== filterInd));
                          }}
                          filtersList={fieldToFieldDistinctValuesMap}
                          filterDisabledText={
                            filter.field &&
                            getK8sFilterSelectedInRule(filter.ruleId) &&
                            !isCauK8SFilter(filter.field) &&
                            !isCauSharedWithK8SFilter(filter.field)
                              ? 'Disabled, cannot apply non-k8s condition on a k8s one'
                              : ''
                          }
                          filter={filter}
                        />
                      </div>
                    </React.Fragment>
                  ))}
                  <div className="mt-2">
                    <Button
                      text="Add Condition"
                      isTextButton
                      disabled={!newRuleEnabled}
                      icon={() => <GenerateIcon iconName={ICONS.plus.name} />}
                      onClick={() =>
                        setFilters([
                          ...filters,
                          {
                            field: '',
                            values: [],
                            operator: OPERATORS_KEYS.IS,
                            ruleId,
                          },
                        ])
                      }
                    />
                  </div>
                </Collapse>
              </React.Fragment>
            );
          })}
        </div>
        <BusinessMappingSplitBlock
          groups={groups}
          isEditMode={isEditMode}
          disabled={isGlobalMode || isImportGlobalMode}
          isSplit={settings.isSplit}
          setIsSplit={(val) => {
            segmentEvent({ type: 'click-event', target: 'set_split_switch' }, usersStore);
            handleUpdateSetting('isSplit')(val);
          }}
          setMappings={handleUpdateSetting('mappings')}
          mappings={settings.mappings}
          splitType={settings.splitType}
          setSplitType={handleUpdateSetting('splitType')}
          group={group}
          customDivision={settings.customDivision}
          setCustomDivision={handleUpdateSetting('customDivision')}
        />
        {takenNames.includes(name) && <ErrorMessage displayError errMsg="Mapping with such name already exists." />}
      </div>
      <CustomModal
        open={importWayModalOpen}
        title="Are you sure you want to edit?"
        saveTitle="Duplicate"
        cancelTitle="Edit"
        onSave={() => {
          setSelectedGlobal(null);
          setIsImportGlobalMode(false);
          setName(`Copy of ${selectedGlobal.name}`);
          setImportWayModalOpen(false);
          setSelectedGlobalStatus(GLOBAL_IMPORT_STATUSES.DUPLICATE);
        }}
        onClose={() => {
          setImportWayModalOpen(false);
        }}
        onCloseClick={() => {
          setName(selectedGlobal.name);
          setSelectedGlobalStatus(GLOBAL_IMPORT_STATUSES.EDIT);
        }}
      >
        <p>
          This mapping is Global. Editing it will affect all related Viewpoints. You can continue to edit this mapping
          or duplicate it before editing.
        </p>
      </CustomModal>
    </CustomModal>
  );
};

CreateBusinessMappingGroupModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
  isCloneMode: PropTypes.bool,
  isImportGlobalMode: PropTypes.bool,
  isGlobalMode: PropTypes.bool,
  isLoading: PropTypes.bool,
  takenNames: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  groups: PropTypes.array.isRequired,
  usersStore: PropTypes.object.isRequired,
  group: PropTypes.object,
  isK8S: PropTypes.bool,
  isK8SPods: PropTypes.bool,
  globalMappings: PropTypes.array.isRequired,
  getAccountTagsKeys: PropTypes.func.isRequired,
  getPageFilters: PropTypes.func.isRequired,
  getK8sCustomTagsKeys: PropTypes.func.isRequired,
  getCustomTagsKeys: PropTypes.func.isRequired,
  getVirtualTagsKeys: PropTypes.func.isRequired,
  customTagsFetcher: PropTypes.object.isRequired,
  virtualTagsFetcher: PropTypes.object.isRequired,
  accountTagsFetcher: PropTypes.object.isRequired,
  k8sLabelsFetcher: PropTypes.object.isRequired,
  resourcesFetcher: PropTypes.object.isRequired,
  usageTypesFetcher: PropTypes.object.isRequired,
  namespacesFetcher: PropTypes.object.isRequired,
  podsFetcher: PropTypes.object.isRequired,
};

export default withInvoiceFiltersContextConsumer(observer(CreateBusinessMappingGroupModal));
