import React from 'react';
import PropTypes from 'prop-types';
import styles from './NoDataFoundComponent.module.scss';
import TooManyResultsImg from '~/shared/img/images/too-many-results.png';

export default function NoDataFoundComponent({
  callBack = null,
  height = '',
  isBorderNeeded = true,
  title = 'No Data Found',
  bodyText = 'Try changing your filter or date parameters',
}) {
  const handleCallBack = (e) => {
    e.preventDefault();
    callBack();
  };
  return (
    <div
      className={styles.container}
      style={{
        width: '100%',
        height,
        alignItems: 'center',
        textAlign: 'center',
        padding: '10px 0',
        border: `${isBorderNeeded ? '0.5px solid #d8d8d8' : ''}`,
        borderRadius: '3px',
      }}
    >
      <div>
        <p
          style={{
            fontSize: '20px',
            marginBottom: '5px',
          }}
        >
          {title}
        </p>
        <p
          style={{
            fontSize: '15px',
            marginBottom: '10px',
          }}
        >
          {callBack && (
            <>
              <a href="/" onClick={(e) => handleCallBack(e)}>
                Click here
              </a>
              <span>{` to try again, or `}</span>
            </>
          )}
          <span>{bodyText}</span>
        </p>
      </div>
      <img alt="" src={TooManyResultsImg} />
    </div>
  );
}

NoDataFoundComponent.propTypes = {
  height: PropTypes.string,
  title: PropTypes.string,
  bodyText: PropTypes.string,
  callBack: PropTypes.func,
  isBorderNeeded: PropTypes.bool,
};
