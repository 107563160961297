import React, { PureComponent } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { createDateDisplayStr, dateFormats } from 'shared/utils/dateUtil';
import { Tooltip } from '@mui/material';
import LabelCoordinator from '../modules/labelCoordinator';

export default class CustomizedDateXAxisTick extends PureComponent {
  static propTypes = {
    chartWidth: PropTypes.number,
    ticksNumber: PropTypes.number,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    payload: PropTypes.object.isRequired,
    granLevel: PropTypes.string.isRequired,
    detailedInfo: PropTypes.bool,
    isSecondaryGroupBy: PropTypes.bool,
  };

  trimAwsSyntax = (text) => {
    const searchText = String(text).includes('AWS') ? 'AWS ' : 'Amazon ';
    let newText = String(text).replace(searchText, '');
    if (newText.length > 10) {
      // to reach 135px
      newText = newText.slice(0, 10);
      newText += '...';
    }
    return newText;
  };

  modifyXAxisTickFormatter = (timeVal, granLevel) => {
    if (timeVal) {
      return createDateDisplayStr(timeVal, granLevel ?? undefined);
    }
    return '';
  };
  startAndEndOfWeek = (granLevel, weekStrDate) => {
    if (granLevel !== dateFormats.weekly) {
      return { weekDateStart: '', weekDateEnd: '' };
    }
    const [, week, year] = weekStrDate.split('-');
    const weekDateStart = moment()
      .year(+year)
      .isoWeek(+week)
      .startOf('isoweek');
    const weekDateEnd = moment()
      .year(+year)
      .isoWeek(+week + 1)
      .startOf('isoWeek')
      .add(-1, 'day');
    return { weekDateStart: weekDateStart.format('MM-DD'), weekDateEnd: weekDateEnd.format('MM-DD') };
  };

  renderBody() {
    const { payload, granLevel, detailedInfo, isSecondaryGroupBy } = this.props;
    const yGapFromXaxis = detailedInfo ? 10 : 16;
    return (
      <text
        x={0}
        y={0}
        dy={yGapFromXaxis}
        transform={detailedInfo ? 'rotate(35)' : ''}
        textAnchor={detailedInfo ? 'start' : 'middle'}
        style={{ fontSize: detailedInfo ? '10px' : '12px' }}
        className="visible-tik"
      >
        {isSecondaryGroupBy
          ? this.trimAwsSyntax(LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', payload.value))
          : this.modifyXAxisTickFormatter(payload.value, granLevel)}
      </text>
    );
  }
  render() {
    const { x, y, payload, granLevel, detailedInfo, chartWidth, ticksNumber, isSecondaryGroupBy } = this.props;
    const { weekDateStart, weekDateEnd } = this.startAndEndOfWeek(granLevel, payload.value);
    const isLastTick = ticksNumber === payload.index + 1;
    const filtrationNeeded = chartWidth && chartWidth < 400 && ticksNumber > 20;
    const shouldBeRemoved = payload.index % 3 !== 0;
    if (!isLastTick && shouldBeRemoved && filtrationNeeded) {
      return null;
    }
    return (
      <g transform={`translate(${x},${y})`}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {granLevel === dateFormats.weekly ? (
          <Tooltip title={`${weekDateStart} - ${weekDateEnd}`} arrow>
            {this.renderBody()}
          </Tooltip>
        ) : detailedInfo ? (
          <Tooltip
            title={
              isSecondaryGroupBy
                ? LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', payload.value)
                : payload.value
            }
            arrow
          >
            {this.renderBody()}
          </Tooltip>
        ) : (
          this.renderBody()
        )}
      </g>
    );
  }
}
