import { useMutation } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import { alignedStartEndDateByGranLevel, buildStartAndEndDate } from 'shared/utils/dateUtil';
import {
  buildFilterConfigParams,
  buildFilterParams,
  convertObjToMap,
  getIncludeExcludeMaps,
  sanitizeParam,
} from 'shared/utils/apiUtil';
import { FilterTypes } from 'usage/constants/usageConstants';
import { AMORTIZE_COST_TYPES, CostTypes } from 'usage/constants/costAndUsageConstants';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';

const modifyFilterMapToMapWithValueArray = (baseFiltersMap) => {
  const entries = [...baseFiltersMap.entries()];
  const tempMap = new Map();
  entries.forEach(([k, v]) => {
    const arrOfVal = v.map((item) => item.value);
    tempMap.set(k, arrOfVal);
  });
  return tempMap;
};

const buildAssetsQuery = (
  rawStartDate,
  rawEndDate,
  granLevel,
  columnsArr,
  filtersMap,
  excludedFiltersStatusMap,
  measuresMap,
  costTypes,
  isFull,
  isK8S,
  panelId,
  dashboardId,
  k8SGranularity,
  isCount,
  likeFiltersStatusProps = {},
  filtersConfig = {},
) => {
  const filtersMapWithValues = modifyFilterMapToMapWithValueArray(filtersMap);
  // prepare dates
  const { startDate, endDate } = buildStartAndEndDate(rawStartDate, rawEndDate);
  const { start, end } = alignedStartEndDateByGranLevel(startDate, endDate, granLevel);
  // prepare columns
  const formattedColumns = columnsArr.reduce((acc, item) => {
    const newItem = { ...item };
    if (item.value === AwsCommonFields.DIVISION_ID) {
      newItem.value = AwsCommonFields.DIVISION;
    }
    if (!newItem.value?.startsWith(AwsCommonFields.WORKLOAD)) {
      return [...acc, newItem];
    }
    const ind = acc.findIndex((col) => col.value?.startsWith(AwsCommonFields.WORKLOAD));
    if (ind === -1) {
      return [...acc, newItem];
    }
    acc[ind].value = `${acc[ind].value},${newItem.value.split(':')[1]}`;
    return acc;
  }, []);
  const columns = formattedColumns.map((col) => `&columns=${sanitizeParam(col.value)}`).join('');
  // prepare measures
  const usageCount = measuresMap.get('usage') ? '&isUsage=true' : '';
  const showAmortize = measuresMap.get(AMORTIZE_COST_TYPES.AMORTIZED) ? '&isAmortized=true' : '';
  const showUnblended = measuresMap.get(AMORTIZE_COST_TYPES.UNBLENDED) ? '&isUnblended=true' : '';
  const netAmortize = measuresMap.get(AMORTIZE_COST_TYPES.NET_AMORTIZED) ? '&isNetAmortized=true' : '';
  const publicCost = measuresMap.get(AMORTIZE_COST_TYPES.PUBLIC_COST) ? '&isPublicCost=true' : '';
  const distributed = measuresMap.get(AMORTIZE_COST_TYPES.DISTRIBUTED) ? '&isDistributed=true' : '';
  const listUnitPrice = measuresMap.get(AMORTIZE_COST_TYPES.LIST_UNIT_PRICE) ? '&isListUnitPrice=true' : '';
  const negotiatedSavings = measuresMap.get(AMORTIZE_COST_TYPES.NEGOTIATED_SAVINGS) ? '&isNegotiatedSavings=true' : '';
  const netUnblended = measuresMap.get(AMORTIZE_COST_TYPES.NET_UNBLENDED) ? '&isNetUnblended=true' : '';
  const arrCostType = costTypes.map((ct) => `&costType=${ct}`).join('');
  const panelIdQuery = panelId ? `&panelId=${panelId}` : '';
  const dashboardIdQuery = dashboardId ? `&dashboardId=${dashboardId}` : '';
  const k8SGranularityQuery = k8SGranularity ? `&k8SGranularity=${k8SGranularity}` : '';
  const isCountQuery = isCount ? `&isCount=1` : '';
  // prepare filter
  const { includeFiltersMap, excludeFiltersMap, likeExcludeFiltersStatus, likeFiltersStatus } = getIncludeExcludeMaps(
    filtersMapWithValues,
    excludedFiltersStatusMap,
    likeFiltersStatusProps,
  );
  const filterParams = buildFilterParams(includeFiltersMap);
  const excludeFilterParams = buildFilterParams(excludeFiltersMap, FilterTypes.EXCLUDE);
  const likeFilterParams = buildFilterParams(convertObjToMap(likeFiltersStatus), FilterTypes.LIKE);
  const likeExcludeFilterParams = buildFilterParams(convertObjToMap(likeExcludeFiltersStatus), FilterTypes.NOT_LIKE);
  const filtersConfigParams = buildFilterConfigParams(convertObjToMap(filtersConfig));
  const getUrl = () => {
    if (isCount) {
      return '/';
    }
    if (isFull) {
      return '/full';
    }
    return '/limited';
  };
  // prepare api query
  return `/api/v1/usage/assets${getUrl()}?startDate=${start || startDate}&endDate=${
    end || endDate
  }&isK8S=${+isK8S}&granLevel=${granLevel}${columns}${filterParams}${excludeFilterParams}${likeFilterParams}${likeExcludeFilterParams}${filtersConfigParams}${arrCostType}${usageCount}${showAmortize}${showUnblended}${netAmortize}${distributed}${publicCost}${listUnitPrice}${negotiatedSavings}${netUnblended}${panelIdQuery}${dashboardIdQuery}${k8SGranularityQuery}${isCountQuery}`;
};

const formatAssetData = (data, columns) => {
  let result = data;
  if (data?.length > 0) {
    const divisionColumnIsIdOnly = columns.find(
      (col) => col.value === AwsCommonFields.DIVISION && col.label?.includes(' ID'),
    );
    result = data.map((item) => ({
      ...item,
      service: item.service ? LabelCoordinator.getServiceNameDisplayValue(item.service) : undefined,
      divisionid: item.division && !divisionColumnIsIdOnly ? item.division : undefined,
      division:
        item.division && !divisionColumnIsIdOnly
          ? LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item.division)
          : item.division,
    }));
  }
  return result;
};

export const getAssetsData = async ({
  startDate,
  endDate,
  granLevel,
  columns,
  filtersMap,
  excludeFiltersMap,
  likeFiltersStatus,
  filtersConfig,
  measuresMap,
  costTypes = [CostTypes.COST, CostTypes.DISCOUNT],
  isK8S,
  panelId,
  dashboardId,
  k8SGranularity,
  isFull,
  isCount,
}) => {
  const response = await API.get(
    'billings',
    buildAssetsQuery(
      startDate,
      endDate,
      granLevel,
      columns,
      filtersMap,
      excludeFiltersMap,
      measuresMap,
      costTypes,
      isFull,
      isK8S,
      panelId,
      dashboardId,
      k8SGranularity,
      isCount,
      likeFiltersStatus,
      filtersConfig,
    ),
  );
  if (Array.isArray(response)) {
    return formatAssetData(response, columns);
  }
  response.data = formatAssetData(response.data, columns);
  return response;
};

export function useAssets() {
  const { data, isPending, reset, mutate, mutateAsync } = useMutation({
    mutationFn: async (params) => {
      try {
        const response = await getAssetsData(params);
        if (Array.isArray(response)) {
          return { data: response };
        }
        return { data: response.data, isLimited: response.isLimited && !params.isK8S };
      } catch (error) {
        const { response } = error || {};
        if (response && response.status === 403 && response.data.errorCode === 'DATA_TOO_BIG') {
          return { data: [], tooBigError: response.data.clientMessage, isLimited: false };
        }
        return { data: [], isLimited: false };
      }
    },
  });
  return {
    clearAssetData: () => reset(),
    fetchAssetData: (params) => mutate(params),
    fetchAssetDataAsync: (params) => mutateAsync(params),
    assetData: data?.data,
    assetDataLimited: data?.isLimited,
    assetDataLoading: isPending,
    assetDataTooBig: data?.tooBigError,
  };
}
