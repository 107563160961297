import { createContext, useContext, useState } from 'react';
import DateFilter from 'shared/modules/dateFilter.js';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  marketPlaceFamilyTypes,
  marketPlaceService,
  PANELS_TYPES,
  supportServices,
} from 'app/containers/MainDashboard/consts.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import { AwsCommonFields } from 'shared/constants/awsConstants.js';
import { AzureCommonFields } from 'shared/constants/azureConstants.js';

const initialPanelsState = {
  [PANELS_TYPES.MTD_COST]: {},
  [PANELS_TYPES.PREVIOUS_MONTH_COST]: {},
  [PANELS_TYPES.FORECASTED_MONTHLY_COST]: {},
  [PANELS_TYPES.OPEN_RECOMMENDATIONS]: {},
  [PANELS_TYPES.LINKED_ACCOUNT_TOTAL]: {},
  [PANELS_TYPES.ANNUAL_POTENTIAL_SAVINGS]: {},
  [PANELS_TYPES.MTD_TOP_10]: {},
  [PANELS_TYPES.HISTORY_DAILY_WITH_FORECAST]: {},
  [PANELS_TYPES.HISTORY_MONTHLY_WITH_FORECAST]: {},
  [PANELS_TYPES.REGION_COST]: {},
};

const MainDashboardContext = createContext();
export const MainDashboardProvider = ({ children }) => {
  const { usersStore } = useRootStore();
  const [panels, setPanels] = useState(initialPanelsState);
  const defaultFilters = {
    costType: [
      { value: 'cost', label: 'Cost' },
      { value: 'discount', label: 'Discount' },
    ],
    cost: [{ value: 'unblended', label: 'Unblended' }],
  };
  if (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS) {
    defaultFilters.exclude = [{ value: 'tax', label: 'Tax' }];
  }
  const [filters, setFiltersState] = useState(defaultFilters);
  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const dateFromLastMonth = moment(DateFilter.getDate()).subtract(1, 'months');
  const dateFromSixMonthsAgo = moment(DateFilter.getDate()).subtract(6, 'months');
  const updatePanelLoadingState = (panelType, isLoading) => {
    if (panels[panelType]?.isLoading === isLoading) {
      return;
    }
    setPanels((prevPanels) => ({
      ...prevPanels,
      [panelType]: {
        ...prevPanels[panelType],
        isLoading: isLoading,
      },
    }));
  };

  const getExcludeFilters = () => {
    let excludeFilters = {};
    if (!filters.exclude) {
      return excludeFilters;
    }
    const filterMap = {
      tax: { key: AwsCommonFields.CHARGE_TYPE, value: ['Tax'] },
      marketplace: {
        key:
          usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS
            ? AwsCommonFields.FAMILY_TYPE
            : AzureCommonFields.SERVICE,
        value:
          usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS ? marketPlaceFamilyTypes : marketPlaceService,
      },
      support: { key: AwsCommonFields.SERVICE, value: supportServices },
    };

    filters.exclude.forEach((option) => {
      if (filterMap[option.value]) {
        excludeFilters[filterMap[option.value].key] = filterMap[option.value].value;
      }
    });
    return excludeFilters;
  };
  const getDynamicFilters = () => {
    const costFilters = (filters.cost || []).reduce((acc, option) => {
      const key = `is${option.value.charAt(0).toUpperCase() + option.value.slice(1)}`;
      acc[key] = true;
      return acc;
    }, {});
    return {
      ...costFilters,
      costType: filters.costType.map((f) => f.value),
      exclude: { ...getExcludeFilters() },
    };
  };

  const setFilters = (newFilters) => {
    setFiltersState(newFilters);
    if (!isFiltersChanged) {
      setIsFiltersChanged(true);
    }
  };

  return (
    <MainDashboardContext.Provider
      value={{
        panels,
        updatePanelLoadingState,
        dateFromLastMonth,
        dateFromSixMonthsAgo,
        filters,
        setFilters,
        getDynamicFilters,
        isFiltersChanged,
      }}
    >
      {children}
    </MainDashboardContext.Provider>
  );
};
MainDashboardProvider.propTypes = {
  children: PropTypes.node,
};

export const useMainDashboardContext = () => useContext(MainDashboardContext);
