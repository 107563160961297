import React, { useEffect, useState } from 'react';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { PAGINATION_DIRECTION } from '../../../consts';
import AddRoles from '../../../components/AddRoles/AddRoles';

import styles from './AddSubRolesModal.module.scss';

const AddSubRolesModal = ({ isOpen, setIsOpen, role }) => {
  const [displayedSubRoles, setDisplayedSubRoles] = useState([]);
  const [localRole, setLocalRole] = useState({ ...role, subRole: [] });
  const [paginationToken, setPaginationToken] = useState(null);
  const [saveClicked] = useState(false);
  const [search, setSearch] = useState();
  const [selectedRoles, setSelectedRoles] = useState([]);

  const { fetchRoles, updateSubRoles } = useRoles(!isOpen);
  const { data, isLoading } = fetchRoles({ paginationToken, search });

  useEffect(() => {
    if (data?.roles && !isLoading) {
      const tmp = data.roles.filter((r) => !role.subRoles.find((subRole) => subRole.id === r.id) && r.id !== role.id);
      setDisplayedSubRoles(tmp || []);
    }
  }, [data, isLoading, role, isOpen]);

  useEffect(() => {
    if (displayedSubRoles) {
      setSelectedRoles(localRole?.subRoles || []);
    }
  }, [displayedSubRoles]);

  useEffect(() => {
    if (selectedRoles?.length) {
      setLocalRole((prev) => ({ ...prev, subRoles: [...selectedRoles] }));
    } else {
      setLocalRole((prev) => ({ ...prev, subRoles: [] }));
    }
  }, [selectedRoles]);

  const handleRolesAddedRemoved = ({ addedRoles, removedRoles }) => {
    setSelectedRoles((prev) => {
      const newSelectedRoles = prev.filter((role) => !removedRoles.find((r) => r.id === role.id));
      return Array.from(new Map([...newSelectedRoles, ...addedRoles].map((item) => [item.id, item])).values());
    });
  };

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  const onSave = async () => {
    await updateSubRoles.mutateAsync({
      roleId: role?.id,
      subRolesPayload: {
        subRoleIdsToAdd: localRole?.subRoles?.map((r) => r.id),
      },
    });
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.ADD}
      footerDisabled={isLoading && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationid="assign-role-modal"
      saveTitle="Add"
      saveDisabled={false}
      title="Add Roles"
    >
      <AddRoles
        isLoading={isLoading}
        roles={displayedSubRoles}
        search={search}
        setSearch={setSearch}
        pageChanged={pageChanged}
        selectedRoles={selectedRoles}
        onRolesAddedRemoved={handleRolesAddedRemoved}
      />
    </CustomModal>
  );
};

export default AddSubRolesModal;
