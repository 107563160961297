import React, { useMemo, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { GROUP_BY_OPTIONS, SORT_BY_OPTIONS } from 'recommendationsNew/consts';
import HeatMapCardGroupedByItem from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCardGroupedByItem';
import { HeatMapCardMoreList } from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCardMoreList';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner';

import classes from './heatMapCard.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const HeatMapCard = ({
  groupByOption,
  optionData,
  onCardClose,
  sortBy,
  summaryData,
  isLastPage,
  isHeatMapDataLoading,
}) => {
  const brand = useBrand();
  const {
    isDashboardPanel,
    recommendationFilters: filtersContext,
    setRecommendationFilters: setFiltersContext,
  } = useRecommendationsNewContext();
  const progressPart = (item) => {
    if (
      (sortBy === SORT_BY_OPTIONS.items.SAVINGS.property && !item?.savings) ||
      (sortBy === SORT_BY_OPTIONS.items.RECS_COUNT.property && !item?.recsCount)
    ) {
      return 0;
    }

    return (
      100 *
      (sortBy === SORT_BY_OPTIONS.items.SAVINGS.property
        ? item.savings / summaryData?.totalSavings
        : item.recsCount / summaryData?.totalCount)
    );
  };
  const getGroupByOptionIcon = useMemo(() => {
    const option = Object.values(GROUP_BY_OPTIONS.items).find((option) => option.id === groupByOption?.id);
    if (option) {
      return option.icon;
    }
    return undefined;
  }, [groupByOption?.id]);

  const cardContentRef = useRef(null);

  const cardGroupOptions = useMemo(() => (optionData ? [...optionData] : []), [optionData]);

  const onGroupedByItemClick = (item) => {
    if (isDashboardPanel) {
      return;
    }
    const newFilterValue = [{ value: item.value, label: item.label }];
    const newContext = {
      ...filtersContext,
      [groupByOption.id]: newFilterValue,
    };
    setFiltersContext(newContext);
  };

  const handleOptionAddedFromMore = (addedOption) => {
    onGroupedByItemClick(addedOption);
  };

  const isCardFiltered = useMemo(
    () => filtersContext[groupByOption.id]?.length > 0,
    [filtersContext, groupByOption.id],
  );

  const background = isHeatMapDataLoading ? (
    <Spinner size={SPINNER_SIZES.MEDIUM} position="absolute" />
  ) : (
    <brand.noData className="m-xl-auto" />
  );
  const CardContent = cardGroupOptions?.length
    ? cardGroupOptions?.map((item, index) => (
        <HeatMapCardGroupedByItem
          key={item.label}
          automation-id={`heatmap-card-${groupByOption.id}-grouped-by-item-${item.label}`}
          item={item}
          itemIndex={index}
          onGroupedByItemClick={onGroupedByItemClick}
          progressPart={progressPart(item)}
        />
      ))
    : background;

  return (
    <div className={classNames(classes.heatMapCardContainer, isCardFiltered && classes.selectedCard)}>
      <div className={classes.cardContentContainer}>
        <div className={classes.cardTitleContainer}>
          <div className={classes.cardTitle}>
            {getGroupByOptionIcon ? (
              <GenerateIcon iconName={getGroupByOptionIcon} className={classes.cardIcon} />
            ) : (
              <div />
            )}
            {groupByOption.name}
          </div>
          {groupByOption.isPinned || isDashboardPanel ? null : (
            <GenerateIcon
              automation-id={`heatmap-card-${groupByOption.id}-close-button`}
              iconName={ICONS.xMark.name}
              className={classes.cardClose}
              onClick={() => onCardClose(groupByOption)}
            />
          )}
        </div>
        <div
          className={`${classes.cardContent} ${cardGroupOptions?.length ? '' : `${classes.emptyCard}`}`}
          ref={cardContentRef}
        >
          {CardContent}
        </div>
      </div>
      {isLastPage === false && !isHeatMapDataLoading ? (
        <div className={classes.cardFooterContainer}>
          <HeatMapCardMoreList
            automation-id={`heatmap-card-${groupByOption.id}-more-button`}
            groupByOptionId={groupByOption.id}
            lastOption={optionData?.[optionData?.length - 1]}
            onChange={(addedOption) => handleOptionAddedFromMore(addedOption)}
          />
        </div>
      ) : null}
    </div>
  );
};

HeatMapCard.propTypes = {
  summaryData: PropTypes.object.isRequired,
  groupByOption: PropTypes.object.isRequired,
  isHeatMapDataLoading: PropTypes.bool.isRequired,
  isLastPage: PropTypes.bool,
  onCardClose: PropTypes.func.isRequired,
  optionData: PropTypes.array,
  sortBy: PropTypes.string.isRequired,
};

export default HeatMapCard;
