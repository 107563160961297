import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import { CLOUD_TYPE_IDS, TABS_IDS } from 'users/constants/usersConstants';
import Spinner from 'shared/components/andtComponents/Spinner';
import { TableHeaderProvider } from 'shared/components/tableHeader/TableHeaderContext';
import { usePermissionCategories } from 'usage/hooks/react-query/usePermissionCategories';
import CloudAccounts from 'users/containers/CloudAccounts/CloudAccounts';
import LinkedAccounts from './LinkedAccounts/LinkedAccounts';
import Users from './Users/Users';
import Roles from './Roles/Roles';
import Customers from './Customers/CustomersWrapper';
import CostCenters from './CostCenters/CostCenters';
import Channels from '../Channels';
import Settings from './Settings/Settings.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';
import { HierarchicalEntityCategory, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { TAB_PATHS } from './consts';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import AppRegistrationConfiguration from '../Onboarding/azure/components/AppRegistrationConfiguration.jsx';
import { useBrand } from 'app/contexts/Brand/BrandContext.jsx';
import { Routes } from 'shared/constants/routes';

const getNavObjectsMap = (usersStore) => {
  const cloudTypeId = usersStore?.currDispUserCloudAccountType;

  const tabsMap = new Map([
    [
      TABS_IDS.USERS,
      {
        component: Users,
        name: 'Users',
        title: '',
        icon: ICONS.mainUserRegular.name,
        path: TAB_PATHS.USERS,
        permissionCategories: [OrganizationEntityCategory.UsersManagement],
      },
    ],
    [
      TABS_IDS.ROLES,
      {
        component: Roles,
        name: 'Roles',
        title: '',
        icon: ICONS.userGear.name,
        path: TAB_PATHS.ROLES,
        permissionCategories: [OrganizationEntityCategory.Roles],
      },
    ],
    [
      TABS_IDS.COST_CENTER,
      {
        component: CostCenters,
        name: 'Cost Centers',
        title: '',
        icon: ICONS.listTree.name,
        path: TAB_PATHS.COST_CENTERS,
        permissionCategories: [OrganizationEntityCategory.CostCenters],
      },
    ],
    [
      TABS_IDS.CLOUD_ACCOUNTS,
      {
        component: CloudAccounts,
        name: 'Cloud Accounts',
        title: '',
        icon: ICONS.cloud.name,
        path: TAB_PATHS.CLOUD_ACCOUNTS,
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
    [
      TABS_IDS.CUSTOMERS,
      {
        component: Customers,
        name: 'Customers',
        title: '',
        icon: ICONS.users.name,
        path: TAB_PATHS.CUSTOMERS,
        permissionCategories: [OrganizationEntityCategory.ResellerCustomers],
      },
    ],
    [
      TABS_IDS.CHANNELS,
      {
        component: Channels,
        name: 'Channels',
        title: '',
        icon: ICONS.objectIntersect.name,
        path: TAB_PATHS.CHANNELS,
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
    [
      TABS_IDS.LINKED_ACCOUNTS,
      {
        component: LinkedAccounts,
        name: prepareLinkAccountLabel(cloudTypeId),
        title: '',
        icon: ICONS.linkHorizontal.name,
        path: TAB_PATHS.LINKED_ACCOUNTS,
        permissionCategories: [HierarchicalEntityCategory.Accounts],
      },
    ],
  ]);

  if (cloudTypeId === CLOUD_TYPE_IDS.AZURE) {
    tabsMap.set(TABS_IDS.AZURE_APP_REG, {
      component: AppRegistrationConfiguration,
      name: 'App Registration',
      title: 'Grant permissions to analyze specific resources needed for our savings recommendations tool',
      icon: 'pencilMechanical',
      path: TAB_PATHS.AZURE_APP_REG,
      permissionCategories: [HierarchicalEntityCategory.Accounts],
    });
  }
  tabsMap.set(TABS_IDS.SETTINGS, {
    component: Settings,
    name: 'Settings',
    title: '',
    icon: ICONS.gearRegular.name,
    path: TAB_PATHS.SETTINGS,
    permissionCategories: [OrganizationEntityCategory.UsersManagement], // Check if this is correct
  });

  return tabsMap;
};

const Organization = ({ usersStore, ...props }) => {
  const { fetchAccessibleCategories } = usePermissionCategories();
  const { data: permissionCategories, isLoading: isLoadingAccessibleCategories } = fetchAccessibleCategories();

  const location = useLocation();
  const navigate = useNavigate();

  const navAccessibleObjectsMap = useMemo(
    () =>
      new Map(
        [...getNavObjectsMap(usersStore).entries()]
          .filter(([, value]) =>
            value?.permissionCategories?.every((category) => permissionCategories?.includes(category)),
          )
          .map((item, index) => {
            return [
              item[0],
              {
                ...item[1],
                id: index,
              },
            ];
          }),
      ),
    [permissionCategories, usersStore.currDispUserCloudAccountType],
  );

  const navItems = [...navAccessibleObjectsMap.values()];
  const currentPath = location.pathname.split('/').pop();
  const currentTab = navItems.find((item) => item.path === currentPath);
  const currentTabId = currentTab?.id ?? 0;

  const handleTabChange = (tabId) => {
    const tab = navItems.find((t) => t.id === tabId);
    if (tab) {
      navigate(`${Routes.ORGANIZATION}/${tab.path}`);
    }
  };

  const RenderComponentsArray = () => {
    const brand = useBrand();
    return navItems.map((tab) => {
      const Component = tab.component;
      return (
        <TableHeaderProvider key={tab.id}>
          <Component {...props} usersStore={usersStore} brand={brand} />
        </TableHeaderProvider>
      );
    });
  };

  useEffect(() => {
    if (!currentTab && navItems.length > 0) {
      navigate(`${Routes.ORGANIZATION}/${navItems[0].path}`);
    }
  }, [currentPath, navItems]);

  if (usersStore.isLoading || isLoadingAccessibleCategories) {
    return <Spinner />;
  }
  return (
    <Container>
      <PageHeader title={PageNames.ORGANIZATION} />
      <Row>
        <ColoredTabs onTabChange={handleTabChange} arrOfNavItems={navItems} defaultAccountIndex={currentTabId}>
          {RenderComponentsArray()}
        </ColoredTabs>
      </Row>
    </Container>
  );
};

Organization.propTypes = {
  usersStore: PropTypes.object.isRequired,
  username: PropTypes.string.isRequired,
  appStore: PropTypes.object.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
};

export default observer(Organization);
