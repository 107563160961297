/* eslint-disable max-len */
/*

PLEASE DON'T ADD Route LINKS HERE

*/
class ExternalLinksFunctions {
  AwsSqlTableAddress = (params: { linkedAccountId: string; resourceGroup: string; server: string; dbName: string }) =>
    `https://management.azure.com/subscriptions/${params.linkedAccountId}/resourceGroups/${params.resourceGroup}/providerlinkedAccountIds/Microsoft.Sql/servers/${params.server}/databases/${params.dbName}`;

  AwsWhitelabelingThemesDynamic = (params: { customThemeName: string }) =>
    `https://s3.amazonaws.com/whitelabeling-themes/${params.customThemeName}/styles.css`;

  AwsBucketOverview = (params: { bucketName: string; bucketRegion: string }) =>
    `https://s3.console.aws.amazon.com/s3/buckets/${params.bucketName}/?region=${params.bucketRegion}&tab=overview`;
  AwsReservedInstancesByRegion = (params: { region: string }) =>
    `https://console.aws.amazon.com/ec2/v2/home?region=${params.region}#ReservedInstances`;
  AwsRosourceUrlByIdAndRegion = (params: { region: string; resourceId: string }) =>
    `https://console.aws.amazon.com/ec2/v2/home?region=${params.region}#Instances:instanceId=${params.resourceId};sort=tag:Name`;
  AwsRosourceUrlByNameAndRegion = (params: { region: string; resourceName: string }) =>
    `https://console.aws.amazon.com/ec2/v2/home?region=${params.region}#Instances:tag:Name=${params.resourceName};sort=tag:Name`;
  AwsOpenPlatformToConnect = (params: { externalId: string }) =>
    `https://console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/quickcreate?stackName=PileusOnBoarding&templateURL=https://pileus-cloudformation-public.s3.amazonaws.com/PileuseOnboardingCFT.json&param_ExternalId=${params.externalId}`;
}

const externalLinksFunctions = new ExternalLinksFunctions();
export default externalLinksFunctions;
