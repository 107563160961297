import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import styles from './recommPlanDetails.module.scss';
import PsPieChart from './SpPieChart';
import HourlyCommitment from './HourlyCommitment';
import { PlanPreferences } from './PlanPrefernces';
import { RECOMMENDATION_PLAN_PROPERTIES } from '../utils/consts';
import { ReactComponent as NoData } from '../img/no-calculated-data.svg';
import { calculateFieldValue, getPropertyValue } from 'commitment/containers/spAnalyzerNew/utils/savingPlansAnalyzerUtils.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import NoteModal from 'shared/components/noteModal/NoteModal.jsx';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const convertToCapital = (text) =>
  text
    .split(/(?=[A-Z])/)
    .join(' ')
    .toUpperCase();
const RecommendedPlanCube = ({
  plan,
  planKey,
  preferencePlanKey,
  shareProperties,
  selectedKey,
  setSelectedPlan,
  automationid,
  hasMultiLacsCommitments,
  hasInstanceSp,
}) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [navigateUrl, setNavigateUrl] = useState('');

  const onBuyPlan = (e) => {
    const url = ExternalLinks.AwsSavingsPlansPurchase;

    if (hasInstanceSp || hasMultiLacsCommitments) {
      e.preventDefault();
      setNavigateUrl(url);
      setShowNoteModal(true);
    }
  };


  return (
    <div
      className={`${styles.planWrapper} ${selectedKey === planKey && styles.selected}`}
      onClick={() => setSelectedPlan(planKey)}
      automation-id={automationid}
    >
      <div className={styles.top}>
        <div className={styles.detailsHeader}>
          {convertToCapital(planKey)}
          {selectedKey === planKey && (
            <a
              target="_blank"
              href={ExternalLinks.AwsSavingsPlansPurchase}
              className={styles.tag}
              onClick={onBuyPlan}
              rel="noreferrer"
            >
              BUY
            </a>
          )}
        </div>
        {preferencePlanKey === planKey && <PlanPreferences />}
      </div>
      {!plan ? (
        <div className={styles.noRecommendedWrapper}>
          <NoData />
          <span className={styles.noRecommendedText}>This plan is not recommended</span>
        </div>
      ) : (
        <div className={styles.content}>
          <div className={styles.listWrapper}>
            <ul className={styles.list}>
              {RECOMMENDATION_PLAN_PROPERTIES.map((property) => (
                <>
                  {property.groupHeader && (
                    <li className={styles.groupHeader}>
                      {property.groupHeader}:
                      {property.headerInfo && (
                        <InfoPopover isSimple mode="outline" className={{ icon: styles.infoIcon }}>
                          {property.headerInfo}
                        </InfoPopover>
                      )}
                    </li>
                  )}
                  <li key={property} className={`${property.isEndGroup && styles.endGroup}`}>
                    <div className={styles.name}>
                      {property.name}:
                      <span
                        className={classNames(styles.value, plan.potentialSavings < 0 && styles.noProfitable)}
                        automation-id={`${property.key}Value`}
                      >
                        {property.calculatedFields?.length ?
                          calculateFieldValue(property, shareProperties, plan, getCurrencyNumber) :
                          getPropertyValue(property, shareProperties, plan, getCurrencyNumber)}
                      </span>
                      {property.info && (
                        <InfoPopover isSimple mode="outline" className={{ icon: styles.infoIcon }}>
                          {property.info}
                        </InfoPopover>
                      )}
                      {property.isEditable && (
                        <HourlyCommitment planKey={planKey} paymentOption={plan.paymentOption} term={plan.term} />
                      )}
                    </div>
                  </li>
                </>
              ))}
            </ul>
          </div>
          <PsPieChart percentValue={plan?.potentialSavings} />
        </div>
      )}
      {showNoteModal && (
        <NoteModal
          close={() => {
            setShowNoteModal(false);
            window.open(navigateUrl, '_blank', 'noopener,noreferrer');
          }}
          btnText="Got it"
          message={
            <div className={styles.noteWrapper}>
              {hasInstanceSp && <span>
                {hasMultiLacsCommitments && '1. '}
                We have identified both EC2 Instance and Compute SPs. The simulation considers both types as Compute SPs.
              </span>
              }
              {hasMultiLacsCommitments && <span>
                {hasInstanceSp && '2. '}
                The simulation allocates SPs regardless of purchasing accounts.
              </span>}
            </div>
          }
          cancel={() => setShowNoteModal(false)}
        />
      )}
    </div>
  );
};

RecommendedPlanCube.propTypes = {
  plan: PropTypes.object,
  planKey: PropTypes.string.isRequired,
  preferencePlanKey: PropTypes.string,
  shareProperties: PropTypes.object,
  selectedKey: PropTypes.string,
  setSelectedPlan: PropTypes.func.isRequired,
  automationid: PropTypes.string,
  hasMultiLacsCommitments: PropTypes.bool,
  hasInstanceSp: PropTypes.bool,
};

export default RecommendedPlanCube;
