import React from 'react';
import CurrencyTypeImage from '../AppRegPage/assets/Currency.jpg';
import commonStyles from '../Pages.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const BillingExportPageHelp = {};

BillingExportPageHelp.accountStorage = (azureBillingExportAccountStorageHelp) => ({
  component: () => (
    <p className={commonStyles.helpText}>
      From the <span>Storage accounts</span> page, enter the <span>Name</span> of the storage account you created.
    </p>
  ),
  image: azureBillingExportAccountStorageHelp,
  imageHeight: 246,
});

BillingExportPageHelp.containerName = (azureBillingExportContainerHelp) => ({
  component: () => (
    <p className={commonStyles.helpText}>
      Go to <span>Data storage &gt; Containers</span> and copy the <span>name of the container</span> that holds your
      export.
    </p>
  ),
  image: azureBillingExportContainerHelp,
  imageHeight: 246,
});

BillingExportPageHelp.directoryPrefix = (azureBillingExportDirectoryPrefixHelp) => ({
  component: () => (
    <p className={commonStyles.helpText}>
      Enter the <span>directory field</span> you defined for the export.
    </p>
  ),
  image: azureBillingExportDirectoryPrefixHelp,
  imageHeight: 267,
});

BillingExportPageHelp.costType = (azureBillingExportCostTypeHelp) => ({
  component: () => {
    const brand = useBrand();
    return (
      <p className={commonStyles.helpText}>
        Choose the export <span>cost type</span> you selected while creating the export for {brand.name}.
      </p>
    );
  },
  image: azureBillingExportCostTypeHelp,
  imageHeight: 246,
});

BillingExportPageHelp.currencyType = {
  component: () => (
    <div className={commonStyles.helpText}>
      <p>
        From Azure, go to <span>Cost Management + Billing &gt; Billing profiles</span> to see <span>currency type</span>
        .
      </p>
      <p className={commonStyles.notice}>Notice:</p>
      <ol>
        <li>Once selected the currency can’t be updated by the user</li>
        <li>If currency is not USD, `All accounts` won’t be supported for this account.</li>
        <li>If currency is not USD, ‘Multi-cloud’ won’t be supported for this account.</li>
      </ol>
    </div>
  ),
  image: CurrencyTypeImage,
  imageHeight: 120,
};

export default BillingExportPageHelp;
