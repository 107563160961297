import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import App from './app/containers/App/App';
import { queryClient } from './queryClient';
// DO NOT DELETE - these polyfills are required to support stringifying of a map or a set
// (JSON.stringify(new Map()) is [] instead of {})
import 'core-js/fn/map';
import 'core-js/fn/set';
import 'core-js/fn/weak-map';

const originalError = console.error;
console.error = (...args) => {
  const str = args?.join(' ');
  if (str?.includes?.('Warning') && str?.includes?.('Support for defaultProps will be removed from')) {
    
    console.log('DevExpress warning ignored: ', args.join(', ').substring(0, 150));
    return;
  }
  if (str?.includes?.('Invalid prop') && str?.includes?.('@pileus-cloud_anodot-frontend-common')) {
    
    console.log('DevExpress warning ignored: ', args.join(', ').substring(0, 150));
    return;
  }
  originalError.apply(console, args);
};


const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Router>
    <QueryClientProvider client={queryClient}>
      <App queryClient={queryClient} />
    </QueryClientProvider>
  </Router>,
);
