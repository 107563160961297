import React, { Component } from 'react';
import { observer } from 'mobx-react';
import moment from 'moment';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Col, Container, Row } from 'reactstrap';
import Spinner from 'shared/components/andtComponents/Spinner';
import {
  alignedStartEndDateByGranLevel,
  buildStartAndEndDate,
  buildTimeDiffDateFromBaseDate,
  createDateDisplayStr,
  getStartEndDatesFromRelativeString,
  parseDateFormatFromDate,
  replaceDateByGranularity,
} from 'shared/utils/dateUtil';
import { isEmptyArray, isPrimitiveArraysEqual } from 'shared/utils/arrayUtils';
import {
  countFilters,
  getCarbonEmissionsOptions,
  getCostReadOnlyOptions,
  groupDataBySecondaryGroupBy,
  groupDataByUsageDate,
  modifiedDailyBalancesToAverage,
  prepareDataForDisplayChart,
  prepareDataForDisplayTable,
  prepareDataKeys,
  prepareSecondaryGroupDataKeys,
  splitLinkedAccountNameAndId,
} from 'shared/utils/dataPrepareUtil';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import PageHeader from 'shared/components/PageHeader';
import { AppCommonFields, OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import {
  AWS_QUANTITY_TYPE_SELECT,
  AwsCommonFields,
  AwsQuantityTypes,
  K8S_CUE_FIELDS,
} from 'shared/constants/awsConstants';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { getFullAwsServiceName } from 'shared/utils/awsUtils';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import Button from 'shared/components/andtComponents/Button';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import {
  AMORTIZE_COST_DEFAULT_STATE,
  AMORTIZE_COST_TYPE_TO_NAME,
  AMORTIZE_COST_TYPES,
  convertChartTypesFlag,
  convertFlagToChartTypes,
  convertModeToUsageRateFlags,
  convertUsageRateFlagsToMode,
  CostTrackingConstants,
  CostTypeModes,
  CostTypes,
  costTypeToFlags,
  CostUsageStates,
  DisplayMetricTypes,
  getValueFuncByMetricType,
  GROUP_BY_LOV,
  GroupByLovToAttributes,
  mapCloudBaseGroupByToCostUsageStateMap,
  mapCostUsageStateToDisplayByCloudType,
  mapDisplayUsageQuantityTypeToMetricType,
  ReportPeriodTime,
  ReportSendFrequency,
  USER_SAVED_REPORT_TYPES,
} from 'usage/constants/costAndUsageConstants';
import CustomModal from 'shared/components/andtComponents/Modal';
import EventsSideBar from 'shared/components/events/EventsSideBar';
import LegendKeysFilterHandler from 'shared/components/ChartKeysFilter/legendKeysFilterHandler';
import NewCustomDashboardPanelModal from 'shared/components/NewCustomDashboardPanelModal';
import FiltersSidebar from 'shared/components/FilterSidebar/FiltersSidebar';
import { convertObjToMap, convertStringifyMapToObj } from 'shared/utils/apiUtil';
import FiltersSidebarContainer from 'shared/components/FilterSidebar/FiltersSidebarContainer';
import DateFilter from 'shared/modules/dateFilter';
import { withCUEGoalsContextProvider } from 'users/contexts/CUEGoalsContext';
import { getCUEGoals, getGoalById } from 'users/utils/cueGoalsUtil';
import { bytesToGb, intersperse, kFormatter, strNumToSize } from 'shared/utils/strUtil';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import toast from 'shared/components/andtComponents/Toast';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants';
import { formatExcludeFilterMap } from 'shared/utils/filtersUtils';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import ReportModal from 'shared/components/reportModal/ReportModal';
import { withVirtualTagsContextProvider } from 'usage/contexts/virtualTagsContext';
import CueReportContent from 'shared/components/reportModal/CueReportContent';
import { withReportsContextProvider } from 'usage/contexts/reportsContext';
import ChartButtonsMenu from 'shared/components/chartComponents/ChartButtonsMenu';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { downloadChartAsPng, downloadTableAsPng } from 'shared/utils/downloadAsPng';
import {
  Action,
  CategoryAction,
  HierarchicalEntityCategory,
  OrganizationEntityCategory,
} from '@anodot-cost/rbac-client';
import CostChart from './components/CostChart';
import InstancesCostFilterTable from './components/InstancesCostFilterTable/InstancesCostFilterTable';
import CostTable from './components/CostTable/CostTable';
import ServiceDataState from './helpers/stateMachine/serviceDataState';
import FamilyTypeDataState from './helpers/stateMachine/familyTypeDataState';
import SubMeterCategoryDataState from './helpers/stateMachine/subMeterCategoryDataState';
import CostTypeDataState from './helpers/stateMachine/costTypeDataState';
import TypeDataState from './helpers/stateMachine/typeDataState';
import OperationDataState from './helpers/stateMachine/operationDataState';
import ResourceDataState from './helpers/stateMachine/resourceDataState';
import AvailabilityZoneDataState from './helpers/stateMachine/availabilityZoneDataState';
import RegionDataState from './helpers/stateMachine/regionDataState';
import LinkedAccountDataState from './helpers/stateMachine/linkedAccDataState';
import ProjectDataState from './helpers/stateMachine/projectDataState';
import EnvironmentDataState from './helpers/stateMachine/environmentDataState';
import DoubleGroupDataState from './helpers/stateMachine/doubleGroupDataState';
import ResourceGroupDataState from './helpers/stateMachine/resourceGroupDataState';
import SubscriptionNameDataState from './helpers/stateMachine/subscriptionNameDataState';
import DivisionDataState from './helpers/stateMachine/divisionDataState';
import AccountTagsDataState from './helpers/stateMachine/accountTagsDataState';
import CustomTagsDataState from './helpers/stateMachine/customTagsDataState';
import CategoryDataState from './helpers/stateMachine/categoryDataState';
import BusinessMappingDataState from './helpers/stateMachine/businessMappingDataState';
import UsageTypeDataState from './helpers/stateMachine/usageTypeDataState';
import VirtualCustomTagsDataState from './helpers/stateMachine/virtualCustomTagsDataState';
import ViewsCustomTagsDataState from './helpers/stateMachine/viewsCustomTagsDataState';
import PayerAccountDataState from './helpers/stateMachine/payerAccountDataState';
import CloudProviderDataState from './helpers/stateMachine/cloudProviderDataState';
import ChargeTypeDataState from './helpers/stateMachine/chargeTypeDataState';
import PurchaseOptionDataState from './helpers/stateMachine/purchaseOptionDataState';
import NoneDataStates from './helpers/stateMachine/noneDataState';
import PublisherCategoryDataState from './helpers/stateMachine/publisherCategoryDataState';
import PublisherNameDataState from './helpers/stateMachine/publisherNameDataState';
import ServiceCategoryDataState from './helpers/stateMachine/serviceCategoryDataState';
import PartnerCreditRateDataState from './helpers/stateMachine/partnerCreditRateDataState';
import AzureCostCenterDataState from './helpers/stateMachine/azureCostCenterDataState';
import CostCenterDataState from './helpers/stateMachine/costCenterDataState';
import ChargeCategoryDataState from './helpers/stateMachine/chargeCategoryDataState';
import BenefitDataState from './helpers/stateMachine/benefitDataState';
import CostCategoryDataState from './helpers/stateMachine/costCategoryDataState';
import TaxTypeDataState from './helpers/stateMachine/taxTypeDataState';
import InstanceTypeFamilyDataState from './helpers/stateMachine/instanceTypeFamilyDataState';
import NormalizationFactorDataState from './helpers/stateMachine/normalizationFactorDataState';
import BillingEntityDataState from './helpers/stateMachine/billingEntityDataState';
import LegalEntityDataState from './helpers/stateMachine/legalEntityDataState';
import PaymentOptionDataState from './helpers/stateMachine/paymentOptionDataState';
import DbEngineDataState from './helpers/stateMachine/dbEngineDataState';
import PlatformDataState from './helpers/stateMachine/platformDataState';
import MeterCategoryDataState from './helpers/stateMachine/meterCategoryDataState';
import CostTypeDescriptionDataState from './helpers/stateMachine/costTypeDescription';
import ReportsHelperMethods from './helpers/reportsHelperMethods';
import * as custDabrdHelpers from './helpers/customDashboardHelperMethods';
import { formatFiltersMapToSelectOptionsMap, getCostOptions } from './helpers/customDashboardHelperMethods';
import PrimaryFilterBar from './components/FilterBar';
import { FilterTypes } from '../../constants/usageConstants';
import SaveGoalModal from './components/SaveGoalModal';
import classes from './costAndUsageExplorer.module.scss';
import EnableCarbonEmission from './components/EnableCarbonEmission';
import withUrlParamsComponentWrapper from '~/shared/components/UrlStateHOC/UrlStateHOC.jsx';
import { Link } from 'react-router-dom';
import { Routes } from '~/shared/constants/routes.js';
import { SECONDARY_GROUP_BY_LIMIT } from '~/usage/containers/CostAndUsageExplorer/constants/limits.js';

const TABLE_ID = 'cue-full-cost-table-id';
const CHART_ID = 'full-cost-and-usage-chart';

const formatDefaultDates = () => {
  const { startDate, endDate } = buildStartAndEndDate(null, null, false);
  return { startDate, endDate };
};

const usageItemsDisplayName = {
  [AWS_QUANTITY_TYPE_SELECT]: 'Usage',
  [AwsQuantityTypes.HOURS]: 'Hours',
  [AwsQuantityTypes.REQUESTS]: 'Requests',
  [AwsQuantityTypes.BYTES]: 'Bytes',
  [AwsQuantityTypes.OTHER]: 'Other',
  [AwsQuantityTypes.QUANTITY]: 'Resources Quantity',
};

class CostTrackingPage extends Component {
  baseInternalState = {
    chartTableData: {},
    cueFetchCount: 0,
    filteredKeys: [],
    favourites: [],
    changedTableColumnWidth: [],
    isInitialDataKeyFilterLoad: true,
    isDateRangeError: false,
    isApplyFiltersButtonDisabled: true,
    isUsageFilterAlertClosed: false,
    saveModalIsOpen: false,
    saveGoalModalOpen: false,
    changeGoalModalOpen: false,
    legendInitiated: false,
    overwriteReportModalIsOpen: false,
    existingCustomDashboardPanelModalIsOpen: false,
    newCustomDashboardPanelModalIsOpen: false,
    saveModalName: '',
    saveModalUserEmail: '',
    saveModalDeliveryFreq: ReportSendFrequency.NONE,
    saveModalDeliveryTime: null,
    saveModalCustomMailDeliveryFrequency: null,
    saveModalCustomMailFrequencyStartDate: null,
    saveModalRelativeDates: ReportPeriodTime.THIRTY_DAYS,
    errorText: 'At least one service filter must be selected',
    selectedPageSize: 15,
    periodType: 'relativeDates',
    saveModalKeepDates: false,
    dataStartDate: null,
    dataEndDate: null,
    forceRefresh: false,
  };

  baseState = {};

  setBaseState = (cloudTypeId) => {
    this.baseState = {
      selectViewByAccount: false,
      isAreaChart: false,
      isDisplayTable: false,
      isPieChart: false,
      isLineChart: false,
      redirectParams: {},
      currDataState: CostUsageStates.SERVICE,
      filterBarGroupBy: GroupByLovToAttributes.get(GROUP_BY_LOV.BY_SERVICE),
      currentGroupBy: GroupByLovToAttributes.get(GROUP_BY_LOV.BY_SERVICE),
      filterBarGroupBySecondary: GroupByLovToAttributes.get('Date'),
      dataKeyToWhereParamsMap: {},
      fieldToFilterdValuesMap: [CLOUD_TYPE_IDS.AWS].includes(cloudTypeId) ? { chargetype: ['Tax'] } : {},
      excludedFiltersStatusMap: [CLOUD_TYPE_IDS.AWS].includes(cloudTypeId) ? { chargetype: 1 } : {},
      likeFiltersStatus: {},
      filtersConfig: {},
      displayMetricTypes: DisplayMetricTypes.COST,
      SelectedUsageType: AWS_QUANTITY_TYPE_SELECT,
      carbonEmissionsUsage: null,
      isUsageChecked: false,
      isCarbonEmissionsChecked: false,
      isRateUsageBased: false,
      isRateChecked: false,
      currPeriodGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
      selectedGranLevel: CostTrackingConstants.GRAN_LEVEL_DAILY,
      ...formatDefaultDates(),
      currCostType: [CostTypes.COST, CostTypes.DISCOUNT],
      selectedGoal: null,
      isFiltersOpen: false,
      isEventsOpen: false,
      isStateFromReport: false,
      isTrendLine: false,
      isCumulative: false,
      isTableTrendRow: false,
      isTableOnlyTrendRow: false,
      isShowOthers: true,
      isShowAmortizeCost: false,
      isNetAmortize: false,
      isNetUnblended: false,
      isPublicCost: false,
      isDistributed: false,
      isListUnitPrice: false,
      isNegotiatedSavings: false,
      accountKey: undefined,
      cloudAccountTypeId: undefined,
      divisionId: undefined,
      divisionName: undefined,
      isPpApplied: undefined,
    };
  };

  constructor(props) {
    super(props);
    const { urlState, usersStore, urlParams } = this.props;
    this.setBaseState(usersStore.currDispUserCloudAccountType);
    this.state = { ...this.baseInternalState, ...this.baseState, ...urlParams };

    const { panelId, startDate, endDate } = this.state;
    const sourceOrPanelIdPresent = panelId || urlState;
    if (sourceOrPanelIdPresent) {
      (async () => {
        const stateBySource = await this.getStateBySource(urlState, panelId, startDate, endDate);
        this.setDefaultState({ ...stateBySource });
      })();
    } else {
      this.initState();
    }
    this.eventsSidebarRef = React.createRef();
    this.mainLegendKeysFilterHandler = new LegendKeysFilterHandler(this);
    this.setKeysFilterHandler = this.mainLegendKeysFilterHandler.setKeysFilterHandler.bind(this);
    this.addKeysFilterHandler = this.mainLegendKeysFilterHandler.addKeysFilterHandler.bind(this);
    this.removeKeysFilterHandler = this.mainLegendKeysFilterHandler.removeKeysFilterHandler.bind(this);
    this.isShowOthersChangeHandler = this.mainLegendKeysFilterHandler.isShowOthersChangeHandler.bind(this);
    this.initDataStates();
    this.barClickHandler = this.barClickHandler.bind(this);
    this.backClickHandler = this.backClickHandler.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleApplyFiltersButtonClick = this.handleApplyFiltersButtonClick.bind(this);
    this.handleCostTypeChange = this.handleCostTypeChange.bind(this);
    this.handleSaveReportButtonClick = this.handleSaveReportButtonClick.bind(this);
    this.handleSaveDashboardPanelButtonClick = this.handleSaveDashboardPanelButtonClick.bind(this);
    this.handleTableColumnWidthChange = this.handleTableColumnWidthChange.bind(this);
    this.handleChangeGranLevel = this.handleChangeGranLevel.bind(this);
    this.changeAmortizeCostValue = this.changeAmortizeCostValue.bind(this);
    this.chartDisplayMap = new Map([
      [CostUsageStates.SERVICE, this.renderChartAndTable],
      [CostUsageStates.FAMILY_TYPE, this.renderChartAndTable],
      [CostUsageStates.TYPE, this.renderChartAndTable],
      [CostUsageStates.OPERATION, this.renderChartAndTable],
      [CostUsageStates.REGION, this.renderChartAndTable],
      [CostUsageStates.LINKED_ACC_ID, this.renderChartAndTable],
      [CostUsageStates.PROJECT, this.renderChartAndTable],
      [CostUsageStates.ENVIRONMENT, this.renderChartAndTable],
      [CostUsageStates.RESOURCE, this.renderResourceChartOrTable],
      [CostUsageStates.SECOND_GROUP_BY, this.renderSecondGroupByChartOrTable],
      [CostUsageStates.RESOURCE_GROUP, this.renderChartAndTable],
      [CostUsageStates.SUBSCRIPTION_ID, this.renderChartAndTable],
      [CostUsageStates.DIVISION, this.renderChartAndTable],
      [CostUsageStates.COST_CENTER, this.renderChartAndTable],
      [CostUsageStates.CUSTOM_TAGS, this.renderChartAndTable],
      [CostUsageStates.ACCOUNT_TAGS, this.renderChartAndTable],
      [CostUsageStates.VIRTUAL_CUSTOM_TAGS, this.renderChartAndTable],
      [CostUsageStates.VIEWS_CUSTOM_TAGS, this.renderChartAndTable],
      [CostUsageStates.CHARGE_TYPE, this.renderChartAndTable],
      [CostUsageStates.PURCHASE_OPTION, this.renderChartAndTable],
      [CostUsageStates.PAYER_ACCOUNT, this.renderChartAndTable],
      [CostUsageStates.CLOUD_PROVIDER, this.renderChartAndTable],
      [CostUsageStates.AVAILABILITY_ZONE, this.renderChartAndTable],
      [CostUsageStates.NONE, this.renderChartAndTable],
      [CostUsageStates.COST_TYPE_DESCRIPTION, this.renderChartAndTable],
      [CostUsageStates.CATEGORY, this.renderChartAndTable],
      [CostUsageStates.BUSINESS_MAPPING, this.renderChartAndTable],
      [CostUsageStates.USAGE_TYPE, this.renderChartAndTable],
      [CostUsageStates.SUB_METER_CATEGORY, this.renderChartAndTable],
      [CostUsageStates.COST_TYPE, this.renderChartAndTable],
      [CostUsageStates.PLATFORM, this.renderChartAndTable],
      [CostUsageStates.DB_ENGINE, this.renderChartAndTable],
      [CostUsageStates.LEGAL_ENTITY, this.renderChartAndTable],
      [CostUsageStates.BILLING_ENTITY, this.renderChartAndTable],
      [CostUsageStates.PAYMENT_OPTION, this.renderChartAndTable],
      [CostUsageStates.BENEFIT, this.renderChartAndTable],
      [CostUsageStates.COST_CATEGORY, this.renderChartAndTable],
      [CostUsageStates.TAX_TYPE, this.renderChartAndTable],
      [CostUsageStates.NORMALIZATION_FACTOR, this.renderChartAndTable],
      [CostUsageStates.INSTANCE_TYPE_FAMILY, this.renderChartAndTable],
      [CostUsageStates.PUBLISHER_CATEGORY, this.renderChartAndTable],
      [CostUsageStates.PUBLISHER_NAME, this.renderChartAndTable],
      [CostUsageStates.AZURE_COST_CENTER, this.renderChartAndTable],
      [CostUsageStates.PARTNER_CREDIT_RATE, this.renderChartAndTable],
      [CostUsageStates.SERVICE_CATEGORY, this.renderChartAndTable],
      [CostUsageStates.METER_CATEGORY, this.renderChartAndTable],
      [CostUsageStates.CHARGE_CATEGORY, this.renderChartAndTable],
    ]);
    this.chartRef = React.createRef();
    this.tableRef = React.createRef();
  }

  initState = () => {
    const { setUrlParams } = this.props;
    setUrlParams({}, this.state);
  };

  setState = (stateOrUpdater) => {
    const { setUrlParams } = this.props;

    // Compute the next state properly
    const nextState = typeof stateOrUpdater === 'function' ? stateOrUpdater(this.state) : stateOrUpdater;

    const onlyUrlParams = {};
    Object.keys(this.baseState).forEach((key) => {
      if (nextState[key] !== undefined && !isEqual(nextState[key], this.state[key])) {
        onlyUrlParams[key] = nextState[key];
      }
    });

    // Should not update state if Ctrl was clicked to open a new tab
    let shouldUpdateState = true;
    if (Object.keys(onlyUrlParams).length > 0) {
      shouldUpdateState = setUrlParams(onlyUrlParams, this.baseState);
    }

    if (shouldUpdateState) {
      super.setState(stateOrUpdater);
    }
  };

  initDataStates = () => {
    const { invoiceStore } = this.props;
    this.dataStates = {
      [CostUsageStates.SERVICE]: new ServiceDataState(this),
      [CostUsageStates.FAMILY_TYPE]: new FamilyTypeDataState(this),
      [CostUsageStates.TYPE]: new TypeDataState(this),
      [CostUsageStates.OPERATION]: new OperationDataState(this),
      [CostUsageStates.RESOURCE]: new ResourceDataState(this),
      [CostUsageStates.REGION]: new RegionDataState(this),
      [CostUsageStates.AVAILABILITY_ZONE]: new AvailabilityZoneDataState(this),
      [CostUsageStates.LINKED_ACC_ID]: new LinkedAccountDataState(this),
      [CostUsageStates.PROJECT]: new ProjectDataState(this),
      [CostUsageStates.ENVIRONMENT]: new EnvironmentDataState(this),
      [CostUsageStates.SECOND_GROUP_BY]: new DoubleGroupDataState(this),
      [CostUsageStates.RESOURCE_GROUP]: new ResourceGroupDataState(this),
      [CostUsageStates.SUBSCRIPTION_ID]: new SubscriptionNameDataState(this),
      [CostUsageStates.DIVISION]: new DivisionDataState(this),
      [CostUsageStates.COST_CENTER]: new CostCenterDataState(this),
      [CostUsageStates.CUSTOM_TAGS]: new CustomTagsDataState(this),
      [CostUsageStates.CHARGE_TYPE]: new ChargeTypeDataState(this),
      [CostUsageStates.PURCHASE_OPTION]: new PurchaseOptionDataState(this),
      [CostUsageStates.VIRTUAL_CUSTOM_TAGS]: new VirtualCustomTagsDataState(this),
      [CostUsageStates.VIEWS_CUSTOM_TAGS]: new ViewsCustomTagsDataState(this),
      [CostUsageStates.PAYER_ACCOUNT]: new PayerAccountDataState(this),
      [CostUsageStates.CLOUD_PROVIDER]: new CloudProviderDataState(this),
      [CostUsageStates.NONE]: new NoneDataStates(this),
      [CostUsageStates.COST_TYPE_DESCRIPTION]: new CostTypeDescriptionDataState(this),
      [CostUsageStates.CATEGORY]: new CategoryDataState(this),
      [CostUsageStates.BUSINESS_MAPPING]: new BusinessMappingDataState(this),
      [CostUsageStates.USAGE_TYPE]: new UsageTypeDataState(this),
      [CostUsageStates.SUB_METER_CATEGORY]: new SubMeterCategoryDataState(this),
      [CostUsageStates.COST_TYPE]: new CostTypeDataState(this),
      [CostUsageStates.ACCOUNT_TAGS]: new AccountTagsDataState(this),
      [CostUsageStates.BENEFIT]: new BenefitDataState(this),
      [CostUsageStates.PUBLISHER_CATEGORY]: new PublisherCategoryDataState(this),
      [CostUsageStates.PUBLISHER_NAME]: new PublisherNameDataState(this),
      [CostUsageStates.SERVICE_CATEGORY]: new ServiceCategoryDataState(this),
      [CostUsageStates.AZURE_COST_CENTER]: new AzureCostCenterDataState(this),
      [CostUsageStates.PARTNER_CREDIT_RATE]: new PartnerCreditRateDataState(this),
      [CostUsageStates.CHARGE_CATEGORY]: new ChargeCategoryDataState(this),
      [CostUsageStates.COST_CATEGORY]: new CostCategoryDataState(this),
      [CostUsageStates.TAX_TYPE]: new TaxTypeDataState(this),
      [CostUsageStates.INSTANCE_TYPE_FAMILY]: new InstanceTypeFamilyDataState(this),
      [CostUsageStates.NORMALIZATION_FACTOR]: new NormalizationFactorDataState(this),
      [CostUsageStates.BILLING_ENTITY]: new BillingEntityDataState(this),
      [CostUsageStates.LEGAL_ENTITY]: new LegalEntityDataState(this),
      [CostUsageStates.PAYMENT_OPTION]: new PaymentOptionDataState(this),
      [CostUsageStates.DB_ENGINE]: new DbEngineDataState(this),
      [CostUsageStates.PLATFORM]: new PlatformDataState(this),
      [CostUsageStates.METER_CATEGORY]: new MeterCategoryDataState(this),
    };
    invoiceStore.clearDataStates();
  };

  async componentDidMount() {
    const { selectViewByAccount, panelId, urlState } = this.state;
    const { currDispUserCloudAccountType } = this.props.usersStore;
    if (selectViewByAccount && [CLOUD_TYPE_IDS.AWS].includes(currDispUserCloudAccountType)) {
      const costType = this.props.usersStore.isCurrentUserSharedReCustomer
        ? AMORTIZE_COST_TYPES.UNBLENDED
        : AMORTIZE_COST_TYPES.AMORTIZED;
      this.handleUpdateCostUsage(costType, null, CostTypeModes.COST);
    }
    if (!panelId && !urlState) {
      this.handleDataStatesFilterChange();
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { currDispUserAccountKey, usersStore, urlParams } = this.props;
    const {
      dataKeyToWhereParamsMap,
      forceRefresh,
      selectedGoal,
      isTableTrendRow,
      currDataState,
      isCumulative,
      isTableOnlyTrendRow,
      cueFetchCount,
      filterBarGroupBy,
      filterBarGroupBySecondary,
    } = this.state;
    const isForceFlagChanged = prevState.forceRefresh !== forceRefresh && forceRefresh;
    const isDiveLevelChanged =
      prevState.dataKeyToWhereParamsMap &&
      dataKeyToWhereParamsMap &&
      JSON.stringify(dataKeyToWhereParamsMap) !== JSON.stringify(prevState.dataKeyToWhereParamsMap);
    const isGoalChanged = !!selectedGoal && selectedGoal !== prevState.selectedGoal;
    if (isForceFlagChanged) {
      this.setState({ forceRefresh: false });
    }
    if (isDiveLevelChanged) {
      this.setState({ selectedGoal: null });
    }
    if (currDispUserAccountKey !== prevProps.currDispUserAccountKey) {
      this.resetToBaseState();
    } else if (isDiveLevelChanged || isForceFlagChanged) {
      this.handleDataStatesFilterChange();
    } else if (isGoalChanged) {
      this.updateStateWhenGoalSelected();
    }
    if (
      isTableTrendRow !== prevState.isTableTrendRow ||
      filterBarGroupBy !== prevState.filterBarGroupBy ||
      filterBarGroupBySecondary !== prevState.filterBarGroupBySecondary ||
      isCumulative !== prevState.isCumulative ||
      isTableOnlyTrendRow !== prevState.isTableOnlyTrendRow ||
      cueFetchCount !== prevState.cueFetchCount
    ) {
      let baseData = this.dataStates[currDataState]?.getData();

      if (baseData) {
        const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareMonthlyData(baseData, isTableTrendRow);
        this.setState({ chartTableData: { modifiedDailyBalances, tableModifiedDailyBalance } });
      }
    }
    this.isCustomerNonEDP = usersStore.isCurrentUserSharedReCustomer;

    if (urlParams.accountKey !== prevProps.urlParams.accountKey) {
      this.setState({
        ...urlParams,
      });
    }
  }

  // is used in baseCostUsageDataState.js
  updateDataDates = () => {
    const { startDate, endDate } = this.state;
    this.setState({
      dataStartDate: startDate,
      dataEndDate: endDate,
      chartTableData: {},
      isApplyFiltersButtonDisabled: true,
    });
  };

  moveWheresToFilters = () => {
    const { dataKeyToWhereParamsMap, fieldToFilterdValuesMap, excludedFiltersStatusMap } = this.state;
    Object.keys(dataKeyToWhereParamsMap).forEach((field) => {
      let fieldFormatted = field;
      let where = dataKeyToWhereParamsMap[field];
      if (field === AwsCommonFields.LINKED_ACCOUNT_ID && where.includes('(')) {
        // get linked account id from where
        where = (where.match(/.*\((.+)\)/) || [])[1] || where;
      }
      if (where === 'no tag') {
        where = 'no_tag';
      }
      if (field.startsWith(AwsCommonFields.CUSTOM_TAGS) || field.startsWith(AwsCommonFields.ACCOUNT_TAGS)) {
        let tagKey;
        [fieldFormatted, tagKey] = field.split(':');
        where = `${tagKey}: ${where}`;
      }

      fieldToFilterdValuesMap[fieldFormatted] = [where];
      excludedFiltersStatusMap[fieldFormatted] = FilterTypes.INCLUDE;
    });
    return { fieldToFilterdValuesMap, excludedFiltersStatusMap };
  };

  updateStateWhenGoalSelected = () => {
    const { CUEGoals } = this.props;
    const { selectedGoal } = this.state;
    const goal = getGoalById(CUEGoals, selectedGoal);
    if (!goal) {
      return;
    }
    const stateByGoal = this.getStateToRestoreFromGoal(goal);
    this.setState({ ...stateByGoal, forceRefresh: true });
  };

  setDefaultState = (state = {}) => {
    const { usersStore } = this.props;
    this.setBaseState(usersStore.currDispUserCloudAccountType);
    const stateVal = {
      ...this.baseInternalState,
      ...this.baseState,
      ...state,
    };
    this.setState(stateVal);
  };

  resetToBaseState = () => {
    const { usersStore } = this.props;
    this.setBaseState(usersStore.currDispUserCloudAccountType);
    this.initDataStates();
    this.setState({ ...this.baseInternalState, ...this.baseState, forceRefresh: true });
  };

  setSelectedPageSize = (size) => {
    this.setState({ selectedPageSize: size });
  };

  getStateBySource = async (source, panelId, startDate, endDate) => {
    const { usageStore, getBusinessMappingViewpoints } = this.props;
    if (!source && !!panelId) {
      const panel = await usageStore.customDbSubStore.customDashboardModel.getPanelById(panelId);
      if (!panel) {
        return null;
      }

      source = {
        ...panel,
        startDate,
        endDate,
        isFromCustomDashboard: true,
      };
    }
    if (source.isFromCustomDashboard) {
      let state = source.state || {};
      state.redirectParams = {
        itemName: source.name,
        id: source.id || source.uuid,
        isFromCustomDashboard: !source.fromTemplate,
      };
      if (!source.state) {
        toast.warning(
          'This is a deprecated panel version, we advise you to create a new panel to achieve full functionality',
        );
        state = this.baseState;
        state.currentGroupBy = source.routeParams.groupByLevel;
        state.filterBarGroupBy =
          source.routeParams.whereParamsMap &&
          source.routeParams.whereParamsMap[0] &&
          source.routeParams.whereParamsMap[0][0]
            ? source.routeParams.whereParamsMap[0][0]
            : source.routeParams.groupByLevel;

        source.state = state;
      }
      if (source.hasDashboardFilters) {
        toast.warning('Pay attention that the global (dashboard) filters does not exist at the CUE');
      }
      if (state.filterBarGroupBy === AwsCommonFields.BUSINESS_MAPPING) {
        // it means its old version of BMs. Use default viewpoint
        const viewpoints = getBusinessMappingViewpoints();
        const defaultViewpoint = viewpoints[viewpoints.length - 1];
        if (defaultViewpoint) {
          const groupBy = `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: ${defaultViewpoint.id}`;
          state.filterBarGroupBy = groupBy;
          state.currentGroupBy = groupBy;
        } else {
          toast.error('You dont have viewpoints.');
        }
      }
      state.startDate = source.startDate || this.baseState.startDate;
      state.endDate = source.endDate || this.baseState.endDate;
      state.excludedFiltersStatusMap = convertStringifyMapToObj(source.state.excludedFiltersStatusMap || {});
      state.fieldToFilterdValuesMap = convertStringifyMapToObj(source.routeParams.filtersMap || {});
      state.dataKeyToWhereParamsMap = convertStringifyMapToObj(source.routeParams.whereParamsMap || {});
      state.likeFiltersStatus = source.routeParams.likeFiltersStatus || {};
      state.filtersConfig = source.routeParams.filtersConfig || {};
      state.legendInitiated = false;
      state.newCustomDashboardPanelModalIsOpen = false;
      state.forceRefresh = 1;
      return state;
    }
    if (source.isFromReports) {
      const reportData = this.getReportLocalyById(this.props.urlState);
      const { state } = reportData;
      state.redirectParams = { itemName: source.name, id: source.id, isFromReports: true };
      state.dataKeyToWhereParamsMap = convertStringifyMapToObj(reportData.state.dataKeyToWhereParamsMap || {});
      state.fieldToFilterdValuesMap = convertStringifyMapToObj(reportData.state.fieldToFilterdValuesMap || {});
      state.excludedFiltersStatusMap = convertStringifyMapToObj(reportData.state.excludedFiltersStatusMap || {});
      state.likeFiltersStatus = reportData.state.likeFiltersStatus || {};
      state.filtersConfig = reportData.state.filtersConfig || {};
      if (reportData.state.favourites && reportData.state.favourites.length > 0) {
        state.isInitialDataKeyFilterLoad = false;
      }
      state.selectedGranLevel = reportData.state.currPeriodGranLevel;
      // updates the dates in the report state according to the selected report period:
      if (reportData.periodType === 'relativeDate') {
        state.startDate = buildTimeDiffDateFromBaseDate(
          reportData.state.endDate,

          -parseInt(reportData.saveModalRelativeDates, 0),
          'd',
        ).startDate;
      }
      if (
        [
          ReportPeriodTime.PREVIOUS_DAY,
          ReportPeriodTime.PREVIOUS_WEEK,
          ReportPeriodTime.PREVIOUS_2_WEEK,
          ReportPeriodTime.PREVIOUS_3_WEEK,
          ReportPeriodTime.PREVIOUS_4_WEEK,
          ReportPeriodTime.PREVIOUS_5_WEEK,
          ReportPeriodTime.PREVIOUS_6_WEEK,
          ReportPeriodTime.PREVIOUS_7_WEEK,
          ReportPeriodTime.PREVIOUS_8_WEEK,
          ReportPeriodTime.PREVIOUS_MONTH,
          ReportPeriodTime.LAST_YEAR,
          ReportPeriodTime.PREVIOUS_2_MONTH,
          ReportPeriodTime.PREVIOUS_3_MONTH,
          ReportPeriodTime.PREVIOUS_6_MONTH,
          ReportPeriodTime.PREVIOUS_12_MONTH,
          ReportPeriodTime.YTD,
        ].includes(reportData.periodType)
      ) {
        const { start: startDateFormatted, end: endDateFormatted } = getStartEndDatesFromRelativeString(
          reportData.periodType,
          DateFilter.getDate(),
        );
        state.startDate = startDateFormatted;
        state.endDate = endDateFormatted;
      }
      state.legendInitiated = false;
      state.forceRefresh = true;
      return state;
    }
    return null;
  };

  getReportLocalyById = (reportData) => {
    const { reports, usageStore } = this.props;
    return usageStore.getReportLocalyById(reports, reportData.id);
  };

  prepareInitialDataKeys = () => {
    const { chartTableData, isCumulative } = this.state;
    const { modifiedDailyBalances } = chartTableData;
    if (modifiedDailyBalances) {
      return prepareDataKeys(modifiedDailyBalances, 'name', 'cost', isCumulative);
    }
    return null;
  };

  getDataKeyByField = (fieldType) => {
    let dataKey = '';
    const { dataKeyToWhereParamsMap } = this.state;
    if (dataKeyToWhereParamsMap[fieldType]) {
      dataKey = dataKeyToWhereParamsMap[fieldType];
    }
    return dataKey;
  };
  getIsGoalsVisible = () => {
    const { isDisplayTable, isPieChart, isLineChart, filterBarGroupBySecondary } = this.state;
    return !isDisplayTable && !isPieChart && !isLineChart && filterBarGroupBySecondary === 'usagedate';
  };
  getCsvTitle = () => {
    const { usersStore } = this.props;
    const { currentGroupBy, currPeriodGranLevel } = this.state;
    const serviceName = this.getDataKeyByField(AwsCommonFields.SERVICE);
    const typeName = this.getDataKeyByField(AwsCommonFields.TYPE);
    const operationName = this.getDataKeyByField(AwsCommonFields.OPERATION);
    const resourceName = this.getDataKeyByField(AwsCommonFields.RESOURCE);
    let groupByLabel =
      usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP && GCPCommonFieldLabels.has(currentGroupBy)
        ? `${GCPCommonFieldLabels.get(currentGroupBy)}-`
        : null;
    groupByLabel = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', groupByLabel);
    const currGroupBy = currentGroupBy ? `${currentGroupBy}s-` : 'services-';
    const serviceTName = serviceName ? `${serviceName}-` : '';
    const tName = typeName ? `${typeName}-` : '';
    const opName = operationName ? `${operationName}-` : '';
    const resName = resourceName ? `${resourceName}-` : '';
    const granLevel = `${currPeriodGranLevel}-`;
    const { startDate, endDate } = this.state;
    const period =
      startDate && endDate
        ? `${buildStartAndEndDate(startDate).startDate}_${buildStartAndEndDate(endDate).endDate}`
        : `${buildStartAndEndDate().startDate}_${buildStartAndEndDate().endDate}`;
    return `${groupByLabel || currGroupBy}${serviceTName}${tName}${opName}${resName}${granLevel}cost_${period}.csv`;
  };

  prepareDataForRateAvgCalculation(baseData) {
    const { isCumulative, SelectedUsageType, isRateUsageBased, currentGroupBy } = this.state;
    if (isEmptyArray(baseData) || this.getCostType() !== CostTypeModes.RATE) {
      return null;
    }
    const data = groupDataByUsageDate(baseData);
    const getValueCost = isRateUsageBased
      ? (a) => a.totalUsageSecondary
      : getValueFuncByMetricType(DisplayMetricTypes.COST);
    const getValueUsage = getValueFuncByMetricType(
      SelectedUsageType === AwsQuantityTypes.QUANTITY && !isRateUsageBased
        ? DisplayMetricTypes.QUANTITY
        : DisplayMetricTypes.USAGE,
    );
    const costData = prepareDataForDisplayChart(
      data,
      AppCommonFields.USAGE_DATE,
      AppCommonFields.GROUP_BY,
      getValueCost,
      isCumulative,
      currentGroupBy,
    );
    const usageData = prepareDataForDisplayChart(
      data,
      AppCommonFields.USAGE_DATE,
      AppCommonFields.GROUP_BY,
      getValueUsage,
      isCumulative,
      currentGroupBy,
    );
    return { costData, usageData };
  }

  prepareMonthlyData(baseData, isShowTrendRow = false) {
    if (isEmptyArray(baseData)) {
      return { modifiedDailyBalances: [], tableModifiedDailyBalance: [] };
    }
    const data = groupDataByUsageDate(baseData);
    return this.prepareData(data, isShowTrendRow);
  }

  prepareData(data, isShowTrendRow) {
    const { usersStore } = this.props;
    const { displayMetricTypes, isCumulative, SelectedUsageType, currentGroupBy, isTableOnlyTrendRow } = this.state;
    const getValue = getValueFuncByMetricType(displayMetricTypes, SelectedUsageType);
    const modifiedDailyBalances = prepareDataForDisplayChart(
      data,
      AppCommonFields.USAGE_DATE,
      AppCommonFields.GROUP_BY,
      getValue,
      isCumulative,
      currentGroupBy,
    );
    const tableModifiedDailyBalance = prepareDataForDisplayTable({
      data,
      entryAnchorFieldName: AppCommonFields.USAGE_DATE,
      keyFieldName: AppCommonFields.GROUP_BY,
      valueFieldName: getValue,
      isShowTrendRow,
      isTableOnlyTrendRow,
      isCumulative,
      usageType: SelectedUsageType,
      splitResource: true,
      currentGroupBy,
      cloudTypeId: usersStore.currDispUserCloudAccountType,
    });

    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  prepareSecondLevelGroupByData(baseData) {
    if (isEmptyArray(baseData)) {
      return { modifiedDailyBalances: [], tableModifiedDailyBalance: [] };
    }
    const data = groupDataBySecondaryGroupBy(baseData);
    return this.prepareDataBySecondGroupBy(data);
  }

  prepareDataBySecondGroupBy(baseData) {
    const { usersStore } = this.props;
    const { displayMetricTypes, SelectedUsageType, filterBarGroupBySecondary } = this.state;
    const getValue = getValueFuncByMetricType(displayMetricTypes, SelectedUsageType);
    const modifiedDailyBalances = prepareDataForDisplayChart(
      baseData,
      'groupBySecondary',
      AppCommonFields.GROUP_BY,
      getValue,
    );
    const tableModifiedDailyBalance = prepareDataForDisplayTable({
      data: baseData,
      entryAnchorFieldName: 'groupBySecondary',
      keyFieldName: AppCommonFields.GROUP_BY,
      valueFieldName: getValue,
      isShowTrendRow: false,
      currentGroupBy: filterBarGroupBySecondary,
      cloudTypeId: usersStore.currDispUserCloudAccountType,
    });
    return { modifiedDailyBalances, tableModifiedDailyBalance };
  }

  formatColumnTitleDate = (column) => {
    const format = parseDateFormatFromDate(column);
    if (format) {
      return {
        name: column,
        title: createDateDisplayStr(column, format),
      };
    }
    return { name: column, title: column };
  };

  prepareTableHeaders = (data, newWidths, currGroupBy) => {
    const { usersStore } = this.props;
    const dataKeys = data.map((row) => Object.keys(row));
    const result = [...new Set([].concat(...dataKeys))].filter((item) => item !== 'linkedAccountId');

    const columns = this.sortColumnsByDate(result.map(this.formatColumnTitleDate));

    const totalSummaryItems = result.map((elem) => {
      if (elem === 'groupBy' || elem === 'linkedAccountId' || elem === 'resourceName') {
        return { columnName: elem, type: 'count' };
      }
      return { columnName: elem, type: 'sum' };
    });
    const columnWidths =
      newWidths.length > 0 && newWidths.length === columns.length
        ? newWidths
        : result.map((elem) => ({
            columnName: elem,
            width: result.length > 5 ? 150 : 300,
          }));

    const tableColumnExtensions = result.map((elem) => ({
      columnName: elem,
      align: 'left',
    }));
    const indexOfGroupBy = columns.findIndex((col) => col.name === 'groupBy');
    if (indexOfGroupBy > -1) {
      const groupBy = columns.splice(indexOfGroupBy, 1)[0];
      let label =
        usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP && GCPCommonFieldLabels.has(currGroupBy)
          ? GCPCommonFieldLabels.get(currGroupBy)
          : null;
      if (currGroupBy.startsWith(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS)) {
        label = LabelCoordinator.getDataKeyDisplayName(
          'cueDisplayCoordinator',
          currGroupBy.replace(`${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: `, ''),
        );
      }
      groupBy.title = label || currGroupBy;
      columns.unshift(groupBy);
    }
    const indexOfResourceName = columns.findIndex((c) => c.name === 'resourceName');
    if (indexOfResourceName > -1) {
      const resourceCol = columns.splice(indexOfResourceName, 1)[0];
      resourceCol.title = 'Resource Name';
      columns.unshift(resourceCol);
    }
    return { columns, columnWidths, tableColumnExtensions, totalSummaryItems };
  };

  sortColumnsByDate = (columns) =>
    columns.sort((a, b) => new Date(replaceDateByGranularity(a.name)) - new Date(replaceDateByGranularity(b.name)));

  formattedDates = () => {
    const { startDate, endDate } = this.state;
    return { startDate, endDate };
  };

  handleTableColumnWidthChange = (newWidths) => {
    this.setState({ changedTableColumnWidth: newWidths });
  };

  handleChangeFilterType = (field, subField, type) => {
    const { excludedFiltersStatusMap = {}, likeFiltersStatus = {}, fieldToFilterdValuesMap = {} } = this.state;
    const likeState =
      type === OPERATORS_KEYS.LIKE
        ? {
            likeFiltersStatus: {
              ...likeFiltersStatus,
              [field]: +!likeFiltersStatus[field],
            },
            fieldToFilterdValuesMap: {
              ...fieldToFilterdValuesMap,
              [field]: [],
            },
          }
        : {};
    this.setState({
      excludedFiltersStatusMap:
        type === OPERATORS_KEYS.LIKE
          ? excludedFiltersStatusMap
          : convertStringifyMapToObj(
              formatExcludeFilterMap(convertObjToMap(excludedFiltersStatusMap), field, subField),
            ),
      isApplyFiltersButtonDisabled: false,
      ...likeState,
    });
  };

  changeAmortizeCostValue = (e, value) => {
    const updatedState = {
      ...Object.values(AMORTIZE_COST_TYPE_TO_NAME).reduce((acc, item) => {
        if (item) {
          return {
            ...acc,
            [item]: false,
          };
        }
        return { ...acc };
      }, {}),
      isApplyFiltersButtonDisabled: false,
    };
    if (AMORTIZE_COST_TYPE_TO_NAME[value]) {
      updatedState[AMORTIZE_COST_TYPE_TO_NAME[value]] = true;
    }
    this.setState(updatedState);
    return value;
  };

  getStateToSaveInGoal = () => {
    const {
      isAreaChart,
      isDisplayTable,
      isCumulative,
      isTrendLine,
      isPieChart,
      isLineChart,
      filterBarGroupBy,
      currentGroupBy,
      filterBarGroupBySecondary,
      dataKeyToWhereParamsMap,
      fieldToFilterdValuesMap,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      filtersConfig,
      displayMetricTypes,
      SelectedUsageType,
      carbonEmissionsUsage,
      currDataState,
      currCostType,
      isRateUsageBased,
    } = this.state;
    return {
      chartType: convertChartTypesFlag({ isPieChart, isAreaChart, isLineChart, isDisplayTable }),
      selectGroupBy: filterBarGroupBy,
      groupBy: currentGroupBy,
      groupBySecondary: filterBarGroupBySecondary,
      whereParams: convertObjToMap(dataKeyToWhereParamsMap),
      filters: fieldToFilterdValuesMap,
      excludeFilters: excludedFiltersStatusMap,
      likeFilters: likeFiltersStatus,
      filtersConfig,
      metricType: displayMetricTypes,
      usageType: SelectedUsageType,
      carbonEmissionsUsage,
      costTypeMode: this.getCostType(),
      costType: this.getAmortizeCostValue(),
      isCumulative,
      isTrendLine,
      currDataState,
      currCostType,
      isRateUsageBased,
      type: 'CUE',
    };
  };

  getStateToRestoreFromGoal = (goal) => {
    const {
      explorerState: {
        chartType,
        selectGroupBy,
        groupBy,
        groupBySecondary,
        whereParams,
        filters,
        likeFilters,
        excludeFilters,
        filtersConfig,
        metricType,
        usageType,
        costTypeMode,
        isRateUsageBased,
        costType,
        currDataState,
        currCostType,
        isCumulative,
        isTrendLine,
        carbonEmissionsUsage,
      },
      granularity,
      startDate,
      endDate,
    } = goal;
    return {
      ...convertFlagToChartTypes(chartType),
      filterBarGroupBy: selectGroupBy,
      currentGroupBy: groupBy,
      filterBarGroupBySecondary: groupBySecondary,
      dataKeyToWhereParamsMap: convertStringifyMapToObj(whereParams),
      fieldToFilterdValuesMap: filters,
      excludedFiltersStatusMap: excludeFilters,
      likeFiltersStatus: likeFilters,
      filtersConfig,
      displayMetricTypes: metricType,
      SelectedUsageType: usageType,
      carbonEmissionsUsage,
      selectedGranLevel: granularity,
      currPeriodGranLevel: granularity,
      startDate,
      endDate,
      currDataState,
      isCumulative,
      isTrendLine,
      currCostType,
      isRateUsageBased,
      ...convertModeToUsageRateFlags(costTypeMode),
      ...costTypeToFlags(costType),
    };
  };
  getCarbonEmissionValue = () => {
    const { carbonEmissionsUsage } = this.state;
    return carbonEmissionsUsage || null;
  };
  getAmortizeCostValue = () => {
    const {
      isNetUnblended,
      isPublicCost,
      isRateUsageBased,
      fieldToFilterdValuesMap,
      isNetAmortize,
      isShowAmortizeCost,
      isDistributed,
      isListUnitPrice,
      isNegotiatedSavings,
    } = this.state;
    if (isRateUsageBased) {
      return fieldToFilterdValuesMap[AwsCommonFields.QUANTITY_TYPE][1];
    }
    if (isShowAmortizeCost) {
      return AMORTIZE_COST_TYPES.AMORTIZED;
    }
    if (isNetAmortize) {
      return AMORTIZE_COST_TYPES.NET_AMORTIZED;
    }
    if (isNetUnblended) {
      return AMORTIZE_COST_TYPES.NET_UNBLENDED;
    }
    if (isPublicCost) {
      return AMORTIZE_COST_TYPES.PUBLIC_COST;
    }
    if (isDistributed) {
      return AMORTIZE_COST_TYPES.DISTRIBUTED;
    }
    if (isListUnitPrice) {
      return AMORTIZE_COST_TYPES.LIST_UNIT_PRICE;
    }
    if (isNegotiatedSavings) {
      return AMORTIZE_COST_TYPES.NEGOTIATED_SAVINGS;
    }
    return AMORTIZE_COST_TYPES.UNBLENDED;
  };

  getCostType = () => {
    const { isUsageChecked, isRateChecked, isCarbonEmissionsChecked } = this.state;
    return convertUsageRateFlagsToMode({ isUsageChecked, isRateChecked, isCarbonEmissionsChecked });
  };
  backClickHandler = (e, dataStateType) => {
    e.preventDefault();
    const { changedTableColumnWidth } = this.state;
    if (changedTableColumnWidth.length > 0) {
      this.setState({ changedTableColumnWidth: [] });
    }
    this.dataStates[dataStateType].handleBackTo();
  };

  barClickHandler = (date, dataKey) => {
    const { usersStore } = this.props;
    const prepDataKey = LabelCoordinator.getDisplayNameDataKey('cueDisplayCoordinator', dataKey);
    this.dataStates[this.state.currDataState].handleDive(date, prepDataKey, usersStore.currDispUserCloudAccountType);
  };

  handleDateChange({ startDate, endDate, isDateRangeError }) {
    const startFormatted = startDate && moment(startDate).format('YYYY-MM-DD[T]HH:mm:00');
    const endFormatted = endDate && moment(endDate).format('YYYY-MM-DD[T]HH:mm:00');
    const { startDate: startDateFromState, endDate: endDateFromState } = this.state;
    this.setState({
      startDate:
        startFormatted > endFormatted && endFormatted !== null
          ? endFormatted || endDateFromState
          : startFormatted || startDateFromState,
      endDate: endFormatted || endDateFromState,
      isDateRangeError: isDateRangeError || false,
      isApplyFiltersButtonDisabled: false,
    });
  }

  handleDateSelection = () => {
    this.setState({
      isApplyFiltersButtonDisabled: false,
    });
  };

  handleDataStatesFilterChange = async () => {
    const {
      currentGroupBy,
      selectedGranLevel,
      currCostType,
      excludedFiltersStatusMap,
      likeFiltersStatus,
      filtersConfig,
      isShowAmortizeCost,
      isApplyMargin,
      isNetAmortize,
      isNetUnblended,
      isPublicCost,
      isDistributed,
      isListUnitPrice,
      isNegotiatedSavings,
      fieldToFilterdValuesMap,
      dataKeyToWhereParamsMap,
      diveDate,
      cueFetchCount,
      isRateUsageBased,
      carbonEmissionsUsage,
    } = this.state;
    let { startDate, endDate } = this.state;
    if (
      Object.keys(dataKeyToWhereParamsMap || {}).includes(AwsCommonFields.OPERATION) &&
      diveDate &&
      selectedGranLevel === CostTrackingConstants.GRAN_LEVEL_DAILY
    ) {
      startDate = diveDate;
      endDate = diveDate;
    }
    await this.dataStates[this.state.currDataState].handleFilterChange(
      currentGroupBy,
      dataKeyToWhereParamsMap,
      startDate,
      endDate,
      selectedGranLevel,
      {
        isRateUsageBased,
        carbonEmissionsUsage,
        filtersMap: fieldToFilterdValuesMap,
        excludedFiltersStatusMap,
        likedFiltersStatus: likeFiltersStatus,
        filtersConfig,
      },
      currCostType,
      isShowAmortizeCost,
      isApplyMargin,
      isNetAmortize,
      isNetUnblended,
      isPublicCost,
      isDistributed,
      isListUnitPrice,
      isNegotiatedSavings,
    );
    this.setState({ cueFetchCount: cueFetchCount + 1 });
  };

  handleCostTypeChange = (currCostType, selectedOptions) => {
    const selectedCostsType = selectedOptions.map((costType) => costType.value);
    const toUpdateState = this.isCostTypesChanged(selectedCostsType);
    if (toUpdateState) {
      this.setState({ currCostType: [...selectedCostsType], isApplyFiltersButtonDisabled: false });
    }
  };

  isCostTypesChanged = (arrCurrCostType) => {
    const newCostType = arrCurrCostType.filter(Boolean);
    const { currCostType } = this.state;
    return !isPrimitiveArraysEqual(currCostType, newCostType);
  };

  handleFilterChange = (filterType, selectedOptions) => {
    let filteredValues = [];
    let selectedUsageOption = AWS_QUANTITY_TYPE_SELECT;
    const removedQuantityOption = selectedOptions === AwsQuantityTypes.QUANTITY ? null : selectedOptions;
    if (removedQuantityOption) {
      if (Array.isArray(removedQuantityOption)) {
        filteredValues = removedQuantityOption.map(({ value }) => value);
      } else {
        selectedUsageOption = this.validQuantityType(removedQuantityOption.label);
        filteredValues.push(selectedUsageOption);
      }
    }
    this.setState({
      isApplyFiltersButtonDisabled: false,
      ...this.updateFilterValueState(filterType, filteredValues),
    });
  };

  updateFilterValueState = (field, values) => {
    const { fieldToFilterdValuesMap } = this.state;
    const isEmptyFilter = isEmptyArray(values);
    const newFilters = { ...fieldToFilterdValuesMap };
    if (isEmptyFilter) {
      delete newFilters[field];
    } else {
      newFilters[field] = values;
    }
    return { fieldToFilterdValuesMap: newFilters };
  };

  buildStateValue = (value, newValue, newSet = null) => {
    const stateNewValue = this.state[newValue];
    const obj = {};
    if (stateNewValue) {
      obj[value] = stateNewValue;
      obj[newValue] = newSet;
    }
    return obj;
  };
  buildStateToUpdate = () => {
    const { newFilterBarGroupBy, newFilterBarGroupBySecondary, selectedGranLevel } = this.state;
    const state = {
      isApplyFiltersButtonDisabled: true,
      selectedGoal: null,
    };
    // 1. update groupbys to be the selected ones:
    const groupByFilters = this.buildStateValue('filterBarGroupBy', 'newFilterBarGroupBy');
    const secondGroupByFilters = this.buildStateValue('filterBarGroupBySecondary', 'newFilterBarGroupBySecondary');

    // 2. update gran level
    state.currPeriodGranLevel = selectedGranLevel;
    // 3. in case granularity was disabled - reset granularity to daily
    if (newFilterBarGroupBy && newFilterBarGroupBySecondary) {
      state.currPeriodGranLevel = CostTrackingConstants.GRAN_LEVEL_DAILY;
    }
    // 5. prepare new state
    return {
      ...state,
      ...groupByFilters,
      ...secondGroupByFilters,
    };
  };

  handleApplyFiltersButtonClick = async () => {
    const {
      newFilterBarGroupBy,
      newFilterBarGroupBySecondary,
      filterBarGroupBy,
      filterBarGroupBySecondary,
      currDataState,
    } = this.state;
    const { usersStore } = this.props;
    segmentEvent({ type: 'click-event', target: 'apply' }, usersStore);

    let stateToUpdate = this.buildStateToUpdate();
    if (newFilterBarGroupBy || newFilterBarGroupBySecondary) {
      const { currDispUserCloudAccountType } = usersStore;
      const mapByCloudType = mapCloudBaseGroupByToCostUsageStateMap.get(currDispUserCloudAccountType);
      const preparedNewGroupBy = this.dataStates[currDataState].getPreparedNewGroupBy(
        newFilterBarGroupBy || filterBarGroupBy,
      );
      const newDataState =
        (newFilterBarGroupBySecondary || filterBarGroupBySecondary) !== AwsCommonFields.USAGE_DATE
          ? CostUsageStates.SECOND_GROUP_BY
          : mapByCloudType.get(preparedNewGroupBy);
      stateToUpdate = {
        ...stateToUpdate,
        currDataState: newDataState,
        // selectedGroupByItem: null,
        currentGroupBy: newFilterBarGroupBy || filterBarGroupBy,
        ...this.moveWheresToFilters(),
        dataKeyToWhereParamsMap: {},
      };
      await this.dataStates[currDataState].handleGroupBy(
        newFilterBarGroupBy || filterBarGroupBy,
        newFilterBarGroupBySecondary || filterBarGroupBySecondary,
        newDataState,
      );
    } else {
      this.handleDataStatesFilterChange();
    }
    this.setState(stateToUpdate);
  };

  isGroupByValid = (groupByVal) => {
    const { usersStore } = this.props;
    const { fieldToFilterdValuesMap, dataKeyToWhereParamsMap } = this.state;
    const currCloudAccType = usersStore.currDispUserCloudAccountType;
    const whereParamsValues = Object.values(dataKeyToWhereParamsMap);
    if (
      groupByVal.toLowerCase() === 'resource' &&
      (!whereParamsValues.length || !whereParamsValues.some((value) => value.length)) &&
      (!fieldToFilterdValuesMap.service || !fieldToFilterdValuesMap.linkedaccid)
    ) {
      toast.warning(
        `In order to Group By - 'Resource', first filter by 'Service' and ${
          currCloudAccType === 1 ? "'Subscription Name'" : "'Linked Account'"
        }`,
      );
      return false;
    }
    if (groupByVal === 'Instance Type') {
      const isError = !fieldToFilterdValuesMap.service || !fieldToFilterdValuesMap.service.length;
      const errorMessage = isError ? "In order to Group By - 'Instance Type', first filter by 'Service'" : '';
      if (isError) {
        toast.warning(errorMessage);
      }
      return !isError;
    }
    return true;
  };

  handleGroupByChange = (groupByVal, isSecondaryGroupBy = false) => {
    const { isTableTrendRow, isTableOnlyTrendRow, newFilterBarGroupBySecondary, newFilterBarGroupBy } = this.state;
    const updatedTableTrendRow = isSecondaryGroupBy && groupByVal !== 'usagedate' ? false : isTableTrendRow;
    const updatedTableOnlyTrendRow = isSecondaryGroupBy && groupByVal !== 'usagedate' ? false : !!isTableOnlyTrendRow;
    const anotherGroupBy = isSecondaryGroupBy ? newFilterBarGroupBy : newFilterBarGroupBySecondary;
    this.setState({
      isTableTrendRow: updatedTableTrendRow,
      isTableOnlyTrendRow: updatedTableOnlyTrendRow,
      isApplyFiltersButtonDisabled: false,
      [isSecondaryGroupBy ? 'newFilterBarGroupBySecondary' : 'newFilterBarGroupBy']: groupByVal,
      [!isSecondaryGroupBy ? 'newFilterBarGroupBySecondary' : 'newFilterBarGroupBy']: anotherGroupBy,
    });
  };

  handleRenderChart(chartId, modifiedDailyBalances, tableModifiedDailyBalance) {
    const { invoiceStore } = this.props;
    const { currDataState, chartTableData } = this.state;
    const chartRenderFunc = this.chartDisplayMap.get(currDataState);
    if (invoiceStore.isLoading || !chartTableData.modifiedDailyBalances) {
      return (
        <div className="cue-loading-page">
          <Spinner position="relative" />
        </div>
      );
    }
    if (chartRenderFunc) {
      return (
        <div ref={this.chartRef}>{chartRenderFunc(chartId, modifiedDailyBalances, tableModifiedDailyBalance)}</div>
      );
    }

    return null;
  }

  handleChangeGranLevel = (granLevel) => {
    const { startDate, endDate } = this.state;
    const { start, end } = alignedStartEndDateByGranLevel(startDate, endDate, granLevel, true);
    this.setState({
      startDate: start,
      endDate: end,
      selectedGranLevel: granLevel,
      isApplyFiltersButtonDisabled: false,
    });
  };

  handleClickToTryAgain = () => {
    this.handleDataStatesFilterChange();
  };

  getCurrCauParamsForCustomDashboard = () =>
    custDabrdHelpers.prepareCauParamsForNewCustomDashboardPanel({
      ...this.state,
    });
  isChangesAppliedBeforeSave = () => {
    const { isApplyFiltersButtonDisabled } = this.state;
    if (!isApplyFiltersButtonDisabled) {
      toast.warning('Apply changes before saving report or dashboard');
      return false;
    }
    return true;
  };

  handleSaveDashboardPanelButtonClick(isSaveOnExistingDb = false) {
    if (!this.isChangesAppliedBeforeSave()) {
      return;
    }
    if (isSaveOnExistingDb) {
      this.setState({ existingCustomDashboardPanelModalIsOpen: true });
    } else {
      this.setState({ newCustomDashboardPanelModalIsOpen: true });
    }
  }

  handleCloseExistingCustomDashboardPanelModal = () => {
    this.setState({ existingCustomDashboardPanelModalIsOpen: false });
  };
  handleOverwriteExistingCustomDbPanel = async (action) => {
    try {
      this.handleCloseExistingCustomDashboardPanelModal();
      if (action === 'delete') {
        const { usageStore } = this.props;
        const params = this.prepareParamsForOverwriteExistingCustomDbPanel();
        await usageStore.customDbSubStore.overwriteExistingCustomDashboardPanel(params);
      }
    } catch {
      this.handleCloseExistingCustomDashboardPanelModal();
    }
  };
  handleOverwriteExistingReport = async (action) => {
    try {
      this.handleCloseOverwriteReportModal();
      if (action === 'delete') {
        const { saveCueReport } = this.props;
        const params = this.prepareParamsForOverwriteExistingReport();
        await saveCueReport(params);
      }
    } catch (error) {
      console.log(error);
      this.handleCloseOverwriteReportModal();
    }
  };
  handleCloseNewCustomDashboardPanelModal = () => {
    this.setState({ newCustomDashboardPanelModalIsOpen: false });
  };

  handleSaveReportButtonClick(isOverwriteReport = false) {
    if (!this.isChangesAppliedBeforeSave()) {
      return;
    }
    if (isOverwriteReport) {
      this.setState({ overwriteReportModalIsOpen: true });
    } else {
      this.setState({ saveModalIsOpen: true });
    }
  }

  handleCloseSaveReportModal = () => {
    this.setState({ saveModalIsOpen: false });
  };
  handleCloseOverwriteReportModal = () => {
    this.setState({ overwriteReportModalIsOpen: false });
  };

  toggleChartOrTable = (chartType) => {
    this.setState({
      ...convertFlagToChartTypes(chartType),
      isCumulative: false,
    });
  };

  handleRemoveFieldFromFiltersValuesMap = (field) => {
    this.setState({
      ...this.updateFilterValueState(field, null),
    });
  };

  handleUpdateCostUsage = (costUsageValue, usageValue, costTypeMode) => {
    const { forceRefresh } = this.state;
    const displayMetricByCostType = {
      [CostTypeModes.COST]: DisplayMetricTypes.COST,
      [CostTypeModes.USAGE]: mapDisplayUsageQuantityTypeToMetricType.get(usageValue),
      [CostTypeModes.RATE]: DisplayMetricTypes.RATE,
      [CostTypeModes.CARBON_EMISSIONS]: DisplayMetricTypes.CARBON_EMISSIONS,
    };
    const isCostBased = AMORTIZE_COST_TYPE_TO_NAME[costUsageValue] !== undefined;
    const usages = [usageValue];
    if (costTypeMode === CostTypeModes.RATE && !isCostBased) {
      usages.push(costUsageValue);
    }
    const showUsage = [CostTypeModes.USAGE, CostTypeModes.RATE].includes(costTypeMode);
    const showCost = costTypeMode === CostTypeModes.COST || (isCostBased && costTypeMode === CostTypeModes.RATE);
    const showCarbonEmissions = costTypeMode === CostTypeModes.CARBON_EMISSIONS;
    this.setState({
      ...(showCost ? costTypeToFlags(costUsageValue) : AMORTIZE_COST_DEFAULT_STATE),
      ...convertModeToUsageRateFlags(costTypeMode, isCostBased),
      displayMetricTypes: displayMetricByCostType[costTypeMode],
      forceRefresh: !forceRefresh,
      carbonEmissionsUsage: showCarbonEmissions ? costUsageValue : null,
      SelectedUsageType: showUsage ? usageValue : AWS_QUANTITY_TYPE_SELECT,
      ...this.updateFilterValueState(AwsCommonFields.QUANTITY_TYPE, showUsage ? usages : null),
      selectedGoal: null,
    });
  };

  getFiltersConfig = () => {
    const conjunctionConfig = {
      set: (key, flag) =>
        this.setState({
          filtersConfig: {
            ...this.state.filtersConfig,
            [key]: {
              ...this.state.filtersConfig[key],
              conjunction: flag,
            },
          },
          isApplyFiltersButtonDisabled: false,
        }),
      get: (key) => this.state.filtersConfig[key]?.conjunction,
    };
    return {
      [AwsCommonFields.CUSTOM_TAGS]: {
        showNotAllocated: true,
        conjunctionSelect: true,
        conjunctionConfig,
      },
      [AwsCommonFields.CATEGORIES]: {
        disableLike: true,
      },
      [AwsCommonFields.REGION]: {
        disableLike: true,
      },
      [AwsCommonFields.PURCHASE_OPTION]: {
        disableLike: true,
      },
      [AwsCommonFields.QUANTITY_TYPE]: {
        disableLike: true,
      },
      [AwsCommonFields.DIVISION]: {
        disableLike: true,
      },
      [AwsCommonFields.CLOUD_PROVIDER]: {
        disableLike: true,
      },
      [AwsCommonFields.VIRTUAL_CUSTOM_TAGS]: {
        disableLike: true,
      },
      [AwsCommonFields.SUB_VIEWS_CUSTOM_TAGS]: {
        disableLike: true,
      },
      [AwsCommonFields.BUSINESS_MAPPING]: {
        isK8S: false,
        conjunctionDivider: 'And',
      },
      [AwsCommonFields.ACCOUNT_TAGS]: {
        conjunctionDivider: 'And',
      },
    };
  };

  getCSVItemsWithoutEmptyValues = (rowRestKeysValues) =>
    Object.entries(rowRestKeysValues).reduce((acc, [key, value]) => {
      const columnTitle = key === 'resourceDescription' ? 'Item Description' : key;
      if (value) {
        return { ...acc, [columnTitle]: value };
      }
      return acc;
    }, {});

  prepareCSVData = (rows) => {
    const csvData = rows.map(({ groupBy, customTags, totalUsage, usageQuantityType, ...rest }) => {
      const customTagsObj = customTags.reduce((acc, tag, idx) => {
        acc[`tag ${idx + 1}`] = tag;
        return acc;
      }, {});
      const modifiedUsage = [totalUsage, usageQuantityType || ''];
      const columnsWithValuesOnly = this.getCSVItemsWithoutEmptyValues(rest);
      return { ...columnsWithValuesOnly, totalUsage: modifiedUsage[0], UOM: modifiedUsage[1], ...customTagsObj };
    });
    return csvData;
  };

  prepareExportTableToCsv = async () => {
    const { usersStore } = this.props;
    const { currDataState, SelectedUsageType, dataKeyToWhereParamsMap, currentGroupBy, isRateChecked } = this.state;
    const csvTitle = this.getCsvTitle();
    let baseData = [];
    let tableData = [];
    if (currDataState !== CostUsageStates.RESOURCE || !dataKeyToWhereParamsMap.operation) {
      baseData = this.dataStates[currDataState].getData();
      const { tableModifiedDailyBalance } =
        currDataState === CostUsageStates.SECOND_GROUP_BY
          ? this.prepareSecondLevelGroupByData(baseData)
          : this.prepareMonthlyData(baseData, undefined, this.state.isTableTrendRow);
      tableData = tableModifiedDailyBalance.map(({ projectName, groupBy, ...params }) => {
        const formattedGroupbyName =
          currentGroupBy === 'resourceid' || currentGroupBy?.startsWith(AwsCommonFields.ACCOUNT_TAGS)
            ? groupBy
            : LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', groupBy);
        const paramsForModify = { groupBy: formattedGroupbyName, resourceName: params.resourceName, ...params };
        if (!paramsForModify.resourceName) {
          delete paramsForModify.resourceName;
        }
        if (SelectedUsageType === 'Bytes' && !isRateChecked) {
          Object.entries(paramsForModify).forEach(([k, v]) => {
            if (+v) {
              const numToSize = strNumToSize(+v, 2, usersStore.currDispUserCloudAccountType);
              paramsForModify[k] = numToSize;
            } else {
              paramsForModify[k] = v;
            }
          });
        }
        return paramsForModify;
      });
      tableData = splitLinkedAccountNameAndId(tableData, currentGroupBy, usersStore.currDispUserCloudAccountType);
    } else {
      const resourceData = this.dataStates[this.state.currDataState].getData();
      const serviceName = this.getDataKeyByField(AwsCommonFields.SERVICE);

      baseData = resourceData.map(({ projectName, ...params }) => ({
        serviceName: getFullAwsServiceName(serviceName),
        ...params,
      }));
      tableData = this.prepareCSVData(baseData);
    }
    return [{ data: tableData, filename: csvTitle }];
  };

  prepareParamsForOverwriteExistingCustomDbPanel = () => {
    const { redirectParams } = this.state;
    const { id, itemName } = redirectParams;
    const {
      existingCustomDashboardPanelModalIsOpen,

      newCustomDashboardPanelModalIsOpen,

      saveModalName,

      saveModalRelativeDates,

      chartTableData, // avoid saving chart table data into panel
      ...rest
    } = this.state;
    const type = this.getCustomDashboardPanelType();
    const baseParams = custDabrdHelpers.prepareBaseParams({
      customDashboardPanelName: itemName,
      type,
      state: { ...rest },
    });
    const periodParams = {};
    const routeParams = this.getCurrCauParamsForCustomDashboard();
    const preparedParams = custDabrdHelpers.createNewCustomDashboardPanelObj(baseParams, periodParams, routeParams);
    preparedParams.uuid = id;
    return preparedParams;
  };
  prepareParamsForOverwriteExistingReport = () => {
    const { reports } = this.props;
    const { redirectParams } = this.state;
    const { id } = redirectParams;
    const localReport = reports.find((r) => r.id === id);
    const savedState = localReport.baseReportParams;
    savedState.dataKeyToWhereParamsMap = convertObjToMap(this.state.dataKeyToWhereParamsMap);
    savedState.excludedFiltersStatusMap = convertObjToMap(this.state.excludedFiltersStatusMap);
    savedState.fieldToFilterdValuesMap = convertObjToMap(this.state.fieldToFilterdValuesMap);
    Object.keys(savedState.state).forEach((key) => {
      savedState.state[key] = this.state[key];
    });
    savedState.state.saveModalIsOpen = false;
    savedState.state.overwriteReportModalIsOpen = false;
    savedState.state.isStateFromReport = true;
    const paramsForReportSaving = {
      id,
      type: localReport.type,
      name: localReport.name,
      email: localReport.mailParams.email,
      reportFreq: localReport.mailParams.reportFreq,
      savedState,
      isPpApplied: localReport.isMarginApplied,
      mailDeliveryFrequency: localReport.mailParams.customFrequencyInDays,
      mailFrequencyStartDate: localReport.mailParams.firstDate,
      frequencyDeliveryTime: localReport.mailParams.frequencyDeliveryTime,
      isEdit: true,
    };
    return paramsForReportSaving;
  };

  checkIfChartIsClickable = () => {
    const { currDataState, dataKeyToWhereParamsMap, newFilterBarGroupBy, filterBarGroupBy } = this.state;
    const groupByChangedDuringDive =
      Object.keys(dataKeyToWhereParamsMap) > 0 && newFilterBarGroupBy && newFilterBarGroupBy !== filterBarGroupBy;
    return (
      ![
        CostUsageStates.RESOURCE,
        CostUsageStates.NONE,
        CostUsageStates.BUSINESS_MAPPING,
        CostUsageStates.VIRTUAL_CUSTOM_TAGS,
        CostUsageStates.VIEWS_CUSTOM_TAGS,
        CostUsageStates.CATEGORY,
      ].includes(currDataState) && !groupByChangedDuringDive
    );
  };

  getFilterLabel = (field) => {
    const label = LabelCoordinator.getFieldLabel(field);
    return label;
  };

  getCustomDashboardPanelType = () => {
    const { currDataState, isDisplayTable, isPieChart, dataKeyToWhereParamsMap } = this.state;
    if (isPieChart) {
      return 'cue-pie-chart';
    }
    if (isDisplayTable) {
      return 'cue-table';
    }
    if (currDataState === CostUsageStates.RESOURCE && dataKeyToWhereParamsMap.operation) {
      return 'cue-resource-table';
    }
    return 'cue';
  };

  renderResourceChartOrTable = (chartId, modifiedDailyBalances, tableModifiedDailyBalance) => {
    const { dataKeyToWhereParamsMap } = this.state;
    if (!dataKeyToWhereParamsMap.operation) {
      return this.renderChartAndTable(chartId, modifiedDailyBalances, tableModifiedDailyBalance);
    }
    return this.renderResourceTable();
  };

  renderChartAndTable = (chartId, modifiedDailyBalances, tableModifiedDailyBalance) => {
    const { invoiceStore } = this.props;
    const {
      filterBarGroupBy,
      changedTableColumnWidth,
      isInitialDataKeyFilterLoad,
      isDisplayTable,
      filteredKeys,
      favourites,
      currDataState,
    } = this.state;
    const baseData = this.dataStates[currDataState].getData();
    const dataForAvgRateCalculation = this.prepareDataForRateAvgCalculation(baseData);
    if (invoiceStore.isTimeoutError) {
      return this.renderTimoutDataMessage();
    }
    if (!modifiedDailyBalances || !modifiedDailyBalances.length) {
      return this.renderNoDataMessage();
    }
    if (!isDisplayTable) {
      const dataKeys = this.prepareInitialDataKeys();
      if (dataKeys === null) {
        return (
          <div className="cue-loading-page">
            <Spinner position="relative" />
          </div>
        );
      }
      const isBarClickable = this.checkIfChartIsClickable();
      return this.renderChart(
        chartId,
        modifiedDailyBalances,
        favourites,
        dataKeys,
        isBarClickable,
        filteredKeys,
        isInitialDataKeyFilterLoad,
        dataForAvgRateCalculation,
      );
    }
    const { columns, columnWidths, tableColumnExtensions, totalSummaryItems } = this.prepareTableHeaders(
      tableModifiedDailyBalance,
      changedTableColumnWidth,
      this.getFilterLabel(filterBarGroupBy),
    );
    return this.renderTable(
      tableModifiedDailyBalance,
      columns,
      columnWidths,
      tableColumnExtensions,
      totalSummaryItems,
      TABLE_ID,
    );
  };

  renderResourceTable = () => {
    const { currDataState, startDate, endDate } = this.state;
    const data = this.dataStates[currDataState].getData();
    const serviceName = this.getDataKeyByField(AwsCommonFields.SERVICE);
    const localRowsStorage = (isEmptyArray(data) ? [] : data).map(({ customTags, ...params }) => ({
      serviceName: getFullAwsServiceName(serviceName),
      customTags: intersperse(customTags, ', '),
      ...params,
      totalUsage:
        params.usageQuantityType === 'Bytes' ? bytesToGb(params.totalUsage, 2, 'asNumber') : params.totalUsage,
    }));
    const { usersStore } = this.props;
    return (
      <>
        <Row>
          <InstancesCostFilterTable
            localRowsStorage={localRowsStorage}
            startDate={startDate}
            endDate={endDate}
            cloudType={usersStore.currDispUserCloudAccountType}
            setSelectedPageSize={this.setSelectedPageSize}
            carbonEmissionsValue={this.getCarbonEmissionValue()}
          />
        </Row>
      </>
    );
  };

  renderBreadCrumbs() {
    const { currDispUserCloudAccountType } = this.props.usersStore;
    const { dataKeyToWhereParamsMap, diveDate } = this.state;
    if (isEmptyArray(Object.keys(dataKeyToWhereParamsMap))) {
      return null;
    }
    const entries = [...Object.entries(dataKeyToWhereParamsMap)];
    const mapByCloudType = mapCloudBaseGroupByToCostUsageStateMap.get(currDispUserCloudAccountType);
    const getDataState = (key) => (key ? mapByCloudType.get(key) || mapByCloudType.get(key.split(':')[0]) : null);
    return entries.map(([key, value], index) => {
      const dataState = getDataState(key);
      const nextDataState = getDataState((entries[index + 1] || [])[0]);
      const bLabel = this.dataStates[dataState]?.getBreadCrumbLabel(value, key, diveDate);
      const getLabel = () => {
        if (key.includes('customtags') || key.includes('accounttags')) {
          return custDabrdHelpers.getBreadcrumbLabelDisplayName(value, dataState, key, currDispUserCloudAccountType);
        }
        return LabelCoordinator.getFieldLabel(
          mapCostUsageStateToDisplayByCloudType(currDispUserCloudAccountType, dataState),
        );
      };
      return (
        <>
          {index === 0 ? (
            <BreadcrumbItem
              className="btn-no-style text-nowrap"
              tag="button"
              onClick={(e) => this.backClickHandler(e, dataState)}
            >
              {getLabel()}
            </BreadcrumbItem>
          ) : null}
          {index + 1 === entries.length ? (
            <BreadcrumbItem className="text-nowrap" tag="u">
              <span>{bLabel}</span>
            </BreadcrumbItem>
          ) : (
            <BreadcrumbItem
              className="btn-no-style text-nowrap"
              tag="button"
              onClick={(e) => this.backClickHandler(e, nextDataState)}
            >
              <span>{bLabel}</span>
            </BreadcrumbItem>
          )}
        </>
      );
    });
  }

  renderTable(rows, columns, columnWidths, tableColumnExtensions, totalSummaryItems, tableId) {
    const { usersStore } = this.props;
    if (rows.length > 0) {
      const {
        isTableTrendRow,
        currPeriodGranLevel,
        SelectedUsageType,
        isUsageChecked,
        displayMetricTypes,
        filterBarGroupBySecondary,
        isTableOnlyTrendRow,
      } = this.state;
      return (
        <div ref={this.tableRef}>
          <CostTable
            rows={rows}
            columns={columns}
            tableId={tableId}
            cloudTypeId={usersStore.currDispUserCloudAccountType}
            renderedColumnWidths={columnWidths}
            tableColumnExtensions={tableColumnExtensions}
            displayedMetric={displayMetricTypes}
            handleTableColumnWidthChange={this.handleTableColumnWidthChange}
            totalSummaryItems={totalSummaryItems}
            isTableTrendRow={isTableTrendRow}
            isTableOnlyTrendRow={isTableOnlyTrendRow}
            setSelectedPageSize={this.setSelectedPageSize}
            selectedUsageType={SelectedUsageType}
            isUsageChecked={isUsageChecked}
            carbonEmissionsValue={this.getCarbonEmissionValue()}
            isGranLevelWeekly={currPeriodGranLevel === CostTrackingConstants.GRAN_LEVEL_WEEKLY}
            isGroupByDate={filterBarGroupBySecondary === GroupByLovToAttributes.get('Date')}
          />
        </div>
      );
    }
    return null;
  }

  renderChart(
    chartId,
    data,
    favourites,
    dataKeys,
    isBarClickable,
    filteredKeys,
    isInitialDataKeyFilterLoad,
    dataForAvgRateCalculation,
  ) {
    const { usersStore, CUEGoals } = this.props;
    const {
      visibleFilters,
      displayMetricTypes,
      SelectedUsageType,
      isEventsOpen,
      isAreaChart,
      isPieChart,
      isLineChart,
      isShowOthers,
      filterBarGroupBy,
      isFiltersOpen,
      currPeriodGranLevel,
      redirectParams,
      legendInitiated,
      isCumulative,
      isTrendLine,
      filterBarGroupBySecondary,
      selectedGoal,
      dataStartDate,
      dataEndDate,
      isUsageChecked,
    } = this.state;
    const { isFromCustomDashboard, isFromReports } = redirectParams;
    return (
      <div id="full-cost-and-usage-chart">
        <Row>
          <CostChart
            warningToast={
              this.getIsCurrentViewpointHasSplitMapping()
                ? 'Each item includes the assigned split cost allocation.'
                : undefined
            }
            handleGoalEdit={() => this.setState({ saveGoalModalOpen: selectedGoal })}
            setKeysFilterHandler={this.setKeysFilterHandler}
            addKeysFilterHandler={this.addKeysFilterHandler}
            removeKeysFilterHandler={this.removeKeysFilterHandler}
            isShowOthersChangeHandler={this.isShowOthersChangeHandler}
            barClickHandler={this.barClickHandler}
            isSecondaryGroupBy={filterBarGroupBySecondary !== AwsCommonFields.USAGE_DATE}
            secondaryGroupBy={filterBarGroupBySecondary}
            currentCloudType={usersStore.currDispUserCloudAccountType}
            data={data}
            isEventsOpen={isEventsOpen}
            onEventCollapse={(id) => {
              this.eventsSidebarRef.current.setExpanded(id);
              this.setState(() => ({
                isEventsOpen: true,
              }));
            }}
            goalLine={this.getIsGoalsVisible() && selectedGoal ? getGoalById(CUEGoals, selectedGoal) : null}
            isInitialDataKeyFilterLoad={isInitialDataKeyFilterLoad}
            legendInitiated={legendInitiated}
            mainLegendKeysFilterHandler={this.mainLegendKeysFilterHandler}
            filteredKeys={filteredKeys}
            dataKeys={dataKeys}
            favourites={favourites}
            isOthersVisible
            startDate={dataStartDate}
            endDate={dataEndDate}
            barClickable={isBarClickable}
            isRenderKeysFilter
            chartId={chartId}
            displayedMetric={displayMetricTypes}
            costValue={this.getAmortizeCostValue()}
            costTypeMode={this.getCostType()}
            costOptions={getCostOptions(this.props.usersStore)}
            carbonEmissionsOptions={getCarbonEmissionsOptions(usersStore.currDispUserCloudAccountType)}
            carbonEmissionsValue={this.getCarbonEmissionValue()}
            dataForAvgRateCalculation={dataForAvgRateCalculation}
            selectedUsageType={SelectedUsageType}
            selectedUsageTypeLabel={usageItemsDisplayName[SelectedUsageType]}
            isTrendLine={isTrendLine}
            isAreaChart={isAreaChart}
            isPieChart={isPieChart}
            isLineChart={isLineChart}
            isShowOthers={isShowOthers}
            isCumulative={isCumulative}
            groupBy={filterBarGroupBy}
            isFiltersOpen={isFiltersOpen}
            isFromReport={isFromReports}
            isFromCustomDb={isFromCustomDashboard}
            visibleFilterKeysCount={visibleFilters && visibleFilters.length}
            granLevel={currPeriodGranLevel}
            isUsageChecked={isUsageChecked}
          />
        </Row>
      </div>
    );
  }

  renderNoDataMessage = () => <NoDataFoundComponent callBack={this.handleClickToTryAgain} isBorderNeeded />;

  renderSecondaryGroupByExceedsDataMessage = () => (
    <NoDataFoundComponent
      title="Too many results"
      bodyText={
        <>
          Second group by has too many results (more than {kFormatter(SECONDARY_GROUP_BY_LIMIT)}). <br />
          Please adjust your filters or time range for more precise data. <br />
          You can also use the <Link to={Routes.ASSETS}>Asset</Link> page to explore in greater detail.
        </>
      }
    />
  );

  renderTimoutDataMessage = () => (
    <NoDataFoundComponent
      callBack={this.handleClickToTryAgain}
      title="Request timeout"
      bodyText="Try to limit your results with filters"
    />
  );

  renderSecondGroupByChartOrTable = () => {
    const { invoiceStore } = this.props;
    const { currDataState, isInitialDataKeyFilterLoad, filteredKeys, favourites, isDisplayTable } = this.state;
    const baseData = this.dataStates[currDataState].getData();
    const { modifiedDailyBalances, tableModifiedDailyBalance } = this.prepareSecondLevelGroupByData(baseData);
    const dataKeys = prepareSecondaryGroupDataKeys(modifiedDailyBalances, 'name', 'cost');
    const { columns, columnWidths, tableColumnExtensions, totalSummaryItems } = this.prepareTableHeaders(
      tableModifiedDailyBalance,
      this.state.changedTableColumnWidth,
      this.getFilterLabel(this.state.filterBarGroupBy),
    );
    const fullChartComponentsId = 'second-level-group-full-cost-and-usage-chart';
    if (invoiceStore.isTimeoutError) {
      return this.renderTimoutDataMessage();
    }
    if (invoiceStore.limitReachedError) {
      return this.renderSecondaryGroupByExceedsDataMessage();
    }
    if (!modifiedDailyBalances || !modifiedDailyBalances.length) {
      return this.renderNoDataMessage();
    }
    const dataForAvgRateCalculation = this.prepareDataForRateAvgCalculation(baseData);
    return !isDisplayTable
      ? this.renderChart(
          fullChartComponentsId,
          modifiedDailyBalances,
          favourites,
          dataKeys,
          false,
          filteredKeys,
          isInitialDataKeyFilterLoad,
          dataForAvgRateCalculation,
        )
      : this.renderTable(tableModifiedDailyBalance, columns, columnWidths, tableColumnExtensions, totalSummaryItems);
  };

  toggleFilters = (e) => {
    e.preventDefault();
    const { isFiltersOpen } = this.state;
    const { usersStore } = this.props;
    segmentEvent({ type: 'click-event', target: 'toggle_filters' }, usersStore);
    this.setState({ isFiltersOpen: !isFiltersOpen });
  };

  getWarningModalDataByDiveFrom = () => {
    const { overwriteReportModalIsOpen, existingCustomDashboardPanelModalIsOpen, redirectParams } = this.state;
    const { isFromCustomDashboard, isFromReports } = redirectParams;
    let handler = () => {};
    let warningMessage = '';
    let isOpen = false;
    if (isFromCustomDashboard) {
      handler = this.handleOverwriteExistingCustomDbPanel;
      warningMessage = 'Be advised that you are about to overwrite this chart data on the existing panel';
      isOpen = existingCustomDashboardPanelModalIsOpen;
    }
    if (isFromReports) {
      handler = this.handleOverwriteExistingReport;
      warningMessage = 'Be advised that you are about to overwrite this chart data on the existing report';
      isOpen = overwriteReportModalIsOpen;
    }
    return { handler, warningMessage, isOpen };
  };
  getFieldToFieldDistincValuesMapByUsageState = (fieldToFieldDistincValuesMap) => {
    const { usersStore } = this.props;
    const { isUsageChecked, isRateChecked, isCarbonEmissionsChecked } = this.state;
    if (
      (isUsageChecked || isRateChecked || isCarbonEmissionsChecked) &&
      fieldToFieldDistincValuesMap.has('quantitytype')
    ) {
      fieldToFieldDistincValuesMap.delete('quantitytype');
    }
    if (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP) {
      fieldToFieldDistincValuesMap.delete('project');
    }
    if (!checkFeatureFlag(usersStore, ACCOUNT_FEATURES.CUE_K8S_FILTERS)) {
      K8S_CUE_FIELDS.forEach((key) => {
        fieldToFieldDistincValuesMap.delete(key);
      });
    }
    return fieldToFieldDistincValuesMap;
  };

  getCurrentGroupByViewpoint = (groupBy, secondaryGroupBy) => {
    const viewpoints = [
      groupBy && groupBy.startsWith(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS) ? groupBy : '',
      secondaryGroupBy && secondaryGroupBy.startsWith(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS)
        ? secondaryGroupBy
        : '',
    ].filter(Boolean);
    return viewpoints.map((vp) => vp.replace(`${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: `, ''));
  };

  getIsCurrentViewpointHasSplitMapping = () => {
    const { getBusinessMappingViewpoints } = this.props;
    const { newFilterBarGroupBySecondary, newFilterBarGroupBy, filterBarGroupBy, filterBarGroupBySecondary } =
      this.state;
    const groupBy = newFilterBarGroupBy || filterBarGroupBy;
    const secondaryGroupBy = newFilterBarGroupBySecondary || filterBarGroupBySecondary;
    const viewpoints = this.getCurrentGroupByViewpoint(groupBy, secondaryGroupBy);
    if (!viewpoints.length) {
      return false;
    }
    const arrBusinessMappingViewpoints = getBusinessMappingViewpoints();
    return (arrBusinessMappingViewpoints || []).filter((v) => viewpoints.includes(v.id)).some((v) => v.hasSplit);
  };

  render() {
    const {
      getPageFilters,
      usersStore,
      usageStore,
      appStore,
      getBusinessMappingViewpoints,
      filtersValuesMap,
      getAccountTagsKeys,
      getCustomTagsKeys,
      CUEGoals,
      virtualTags,
      saveCueReport,
      sendReport,
      brandContext,
    } = this.props;

    const { existingDashboardsNamesAndIds } = usageStore.customDbSubStore.customDashboardModel;

    const customDashboardModelIsLoading = usageStore.customDbSubStore.customDashboardModel.modelIsLoading;

    if (!usersStore.currUserInitDone) {
      return <Spinner />;
    }

    const fieldToFieldDistincValuesMap = getPageFilters(
      PageNames.COST_AND_USAGE_EXPLORER,
      usersStore.currDispUserCloudAccountType,
    );

    const { currDispUserCloudAccountType } = usersStore;

    const currUserMultiCloud = currDispUserCloudAccountType === CLOUD_TYPE_IDS.MULTI;

    const {
      likeFiltersStatus,
      filtersConfig,
      saveModalIsOpen,
      SelectedUsageType,
      newCustomDashboardPanelModalIsOpen,
      isFiltersOpen,
      isEventsOpen,
      isPieChart,
      isDisplayTable,
      newFilterBarGroupBy,
      newFilterBarGroupBySecondary,
      filterBarGroupBySecondary,
      startDate,
      endDate,
      currCostType,
      currPeriodGranLevel,
      currDataState,
      fieldToFilterdValuesMap,
      redirectParams,
      isCumulative,
      isTrendLine,
      isAreaChart,
      isLineChart,
      isTableTrendRow,
      isTableOnlyTrendRow,
      saveGoalModalOpen,
      selectedGoal,
      filteredKeys,
      isShowOthers,
      changeGoalModalOpen,
      dataStartDate,
      dataEndDate,
      chartTableData,
    } = this.state;

    if (typeof currDataState !== 'number') {
      return <Spinner />;
    }

    const groupBy = newFilterBarGroupBy || this.state.filterBarGroupBy;
    const secondaryGroupBy = newFilterBarGroupBySecondary || this.state.filterBarGroupBySecondary;
    const { isFromReports, isFromCustomDashboard, id, itemName } = redirectParams || {};
    const usageLovItems = this.dataStates[this.state.currDataState].getSupportedQuantityTypes(
      usersStore.currDispUserCloudAccountType,
    );
    usageLovItems.splice(0, 1);
    const arrBusinessMappingViewpoints = getBusinessMappingViewpoints();
    const arrCustomTagsKeys = getCustomTagsKeys();
    const arrAccountTagsKeys = getAccountTagsKeys();
    const arrViewsNames = filtersValuesMap.get(AwsCommonFields.VIEWS_CUSTOM_TAGS);
    const showViewOptions = !isPieChart && filterBarGroupBySecondary === 'usagedate';
    const showEventsBar = !isDisplayTable && isEventsOpen && showViewOptions;
    // bool transforms to 0 or 1
    const infoColWidth = 12 - +isFiltersOpen * 3 - +showEventsBar * 3;
    const filtersCount = countFilters(fieldToFilterdValuesMap, currCostType);
    const isCarbonEmission = this.getCostType() === CostTypeModes.CARBON_EMISSIONS;
    const isGreenpixieEnabled = checkFeatureFlag(usersStore, ACCOUNT_FEATURES.GREENPIXIE_ENRICHMENT);

    const { modifiedDailyBalances, tableModifiedDailyBalance } = chartTableData;
    if (isCarbonEmission && !isGreenpixieEnabled) {
      return (
        <Container>
          <PageHeader title={PageNames.COST_AND_USAGE_EXPLORER} showDate barIcons />
          <EnableCarbonEmission />
        </Container>
      );
    }
    return (
      <Container>
        <PageHeader title={PageNames.COST_AND_USAGE_EXPLORER} showDate barIcons />
        <Row>
          {!!saveGoalModalOpen && (
            <SaveGoalModal
              cloudType={usersStore.currDispUserCloudAccountType}
              granularity={currPeriodGranLevel}
              cueStartDate={startDate}
              cueEndDate={endDate}
              avgTarget={modifiedDailyBalancesToAverage(modifiedDailyBalances, filteredKeys, isShowOthers)}
              usageStore={usageStore}
              item={typeof saveGoalModalOpen === 'string' ? getGoalById(CUEGoals, saveGoalModalOpen) : null}
              costTypeMode={this.getCostType()}
              usageSelected={SelectedUsageType}
              costSelected={this.getAmortizeCostValue()}
              onGoalSaved={(goalId) => {
                if (goalId === selectedGoal) {
                  this.updateStateWhenGoalSelected();
                } else {
                  this.setState({ selectedGoal: goalId });
                }
              }}
              onClose={() => this.setState({ saveGoalModalOpen: false })}
              explorerState={this.getStateToSaveInGoal()}
            />
          )}
          {changeGoalModalOpen && (
            <CustomModal
              title="Change Goal"
              open
              onClose={(value) => this.setState({ changeGoalModalOpen: !!value })}
              onSave={() => this.setState({ selectedGoal: changeGoalModalOpen })}
            >
              By choosing a different goal, the view will change accordingly.
              <br />
              Do you want continue this action?
            </CustomModal>
          )}
          <ReportModal
            modalType={USER_SAVED_REPORT_TYPES.CUE}
            title="Cost And Usage Report"
            usersStore={usersStore}
            isOpen={saveModalIsOpen}
            onSave={(params) => {
              const data = ReportsHelperMethods.getModalSaveReportHandler({
                props: this.props,
                state: this.state,
                setState: this.setState,
              })(params);
              saveCueReport(data);
            }}
            onSend={(params) => {
              const data = ReportsHelperMethods.getModalSendReportHandler(
                {
                  props: this.props,
                  state: this.state,
                  setState: this.setState,
                },
                USER_SAVED_REPORT_TYPES.CUE,
              )(params);
              sendReport(data);
            }}
            onClose={this.handleCloseSaveReportModal}
          >
            <CueReportContent
              hideCumulativeCheckBox={this.state.filterBarGroupBySecondary !== GroupByLovToAttributes.get('Date')}
            />
          </ReportModal>
          <NewCustomDashboardPanelModal
            modalIsOpen={newCustomDashboardPanelModalIsOpen}
            getCurrentCauParams={this.getCurrCauParamsForCustomDashboard}
            onClose={this.handleCloseNewCustomDashboardPanelModal}
            customDashboardStore={usageStore.customDbSubStore}
            existingDashboardsNamesAndIds={existingDashboardsNamesAndIds}
            helpers={custDabrdHelpers}
            isCustomDbModelLoading={customDashboardModelIsLoading}
            state={this.state}
            arrBreadCrumbs={{}}
            type={this.getCustomDashboardPanelType()}
            usageStore={usageStore}
          />
          <DeleteWarningModal
            modalTitle="Overwrite Warning"
            warningMessage={this.getWarningModalDataByDiveFrom().warningMessage}
            deletedItemName={` ${itemName}`}
            executeButtonTitle="Overwrite & Save"
            isOpen={this.getWarningModalDataByDiveFrom().isOpen}
            handleDelete={this.getWarningModalDataByDiveFrom().handler}
          />
        </Row>
        <Card>
          <CardBody>
            <Row style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', fontSize: '0.8 rem' }}>
                <PrimaryFilterBar
                  topLevelGroupBy={groupBy}
                  topLevelSecondaryGroupBy={secondaryGroupBy}
                  selectedGroupByItem={this.state.currentGroupBy}
                  handleGroupByChange={this.handleGroupByChange}
                  renderGroupByButton
                  currentCloudType={currDispUserCloudAccountType}
                  arrBusinessMappingViewpoints={arrBusinessMappingViewpoints}
                  arrCustomTagsKeys={arrCustomTagsKeys}
                  arrAccountTagsKeys={arrAccountTagsKeys}
                  arrVirtualTagsNames={virtualTags}
                  arrViewsNames={arrViewsNames}
                  isShowCustomTags
                  isGroupByValid={this.isGroupByValid}
                  usersStore={usersStore}
                  appStore={appStore}
                  isAllAcounts={
                    fieldToFieldDistincValuesMap.has(AwsCommonFields.PAYER_ACCOUNT) &&
                    fieldToFieldDistincValuesMap.get(AwsCommonFields.PAYER_ACCOUNT).length > 0
                  }
                />
                <div className="main-filter-bar-separator" />
                <div className="d-flex">
                  <FiltersSidebar
                    startDate={this.formattedDates().startDate}
                    endDate={this.formattedDates().endDate}
                    handleDateChange={this.handleDateChange}
                    handleDateSelection={this.handleDateSelection}
                    renderDatePickerFilter
                    isDateRangeError={this.state.isDateRangeError}
                    isShowQuarterly
                    isShowYearly
                    isShowHourly={
                      usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP ||
                      (usersStore.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AWS && !appStore.isPpApplied)
                    }
                    currPeriodGranLevel={this.state.selectedGranLevel}
                    handleChangeGranLevel={this.handleChangeGranLevel}
                    renderGranPeriodButton={
                      (this.state.newFilterBarGroupBySecondary &&
                        this.state.newFilterBarGroupBySecondary === GroupByLovToAttributes.get('Date')) ||
                      (!this.state.newFilterBarGroupBySecondary &&
                        this.state.filterBarGroupBySecondary === GroupByLovToAttributes.get('Date'))
                    }
                    renderFilters
                    handleToggleFilters={this.toggleFilters}
                    isFiltersOpen={isFiltersOpen}
                    isFilters={filtersCount > 0}
                    filtersCount={filtersCount}
                  />
                  <Col style={{ display: 'flex' }} className="ps-3">
                    <Tooltip title="Apply filters and Group by" disabled={this.state.isApplyFiltersButtonDisabled}>
                      <div>
                        <Button
                          text="Apply"
                          overrideStyles={{ width: 140, height: 35 }}
                          onClick={this.handleApplyFiltersButtonClick}
                          disabled={this.state.isApplyFiltersButtonDisabled}
                          automationid="apply-filters-button"
                        />
                      </div>
                    </Tooltip>
                  </Col>
                </div>
              </div>
            </Row>
            <div className="cue-separation-line" />
            <Row style={{ marginTop: '3vh' }}>
              {isFiltersOpen && (
                <Col style={{ paddingInline: 0 }} xs={3} md={3} lg={3} xl={3} className={classes.sideBarContainer}>
                  <FiltersSidebarContainer
                    infoLink={brandContext.externalLinks.CueFilters}
                    likeOperator
                    selectedOptionsMap={formatFiltersMapToSelectOptionsMap(
                      convertObjToMap(fieldToFilterdValuesMap),
                      fieldToFieldDistincValuesMap,
                    )}
                    fieldToFieldDistincValuesMap={this.getFieldToFieldDistincValuesMapByUsageState(
                      fieldToFieldDistincValuesMap,
                    )}
                    currDispUserCloudAccountType={this.props.usersStore.currDispUserCloudAccountType}
                    isKeyCloakManagement={this.props.appStore.isKeyCloakManagement}
                    fieldToFieldDistincValuesProps={this.getFiltersConfig()}
                    excludedFiltersStatusMap={convertObjToMap(this.state.excludedFiltersStatusMap)}
                    likeFiltersStatus={likeFiltersStatus}
                    likeCaseConfig={{
                      get: (field) => (this.state.filtersConfig || {})[field]?.caseSensitive,
                      set: (field, value) => {
                        this.setState({
                          isApplyFiltersButtonDisabled: false,
                          filtersConfig: {
                            ...this.state.filtersConfig,
                            [field]: { ...this.state.filtersConfig[field], caseSensitive: +value },
                          },
                        });
                      },
                    }}
                    filtersConfig={filtersConfig}
                    selectedCostTypes={this.state.currCostType}
                    isCostTypeFilter
                    className="cue"
                    handleRemoveFieldFromFiltersValuesMap={this.handleRemoveFieldFromFiltersValuesMap}
                    handleCostTypeChange={this.handleCostTypeChange}
                    handleChangeFilterType={this.handleChangeFilterType}
                    handleFilterChange={this.handleFilterChange}
                    handleApplyFiltersButtonClick={this.handleApplyFiltersButtonClick}
                  />
                </Col>
              )}
              <Col
                xs={infoColWidth}
                md={infoColWidth}
                lg={infoColWidth}
                xl={infoColWidth}
                className={infoColWidth < 12 && classes.chartContainer}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <Breadcrumb className="mb-0">{this.renderBreadCrumbs()}</Breadcrumb>
                  <div className="align-items-end d-flex">
                    <ChartButtonsMenu
                      costUsageHandler={{
                        usageOptions: usageLovItems.map((value) => ({
                          name: usageItemsDisplayName[value],
                          value,
                          tooltip:
                            value === AwsQuantityTypes.BYTES
                              ? 'S3 service Byte Usage will be presented in GiB (1024)'
                              : '',
                        })),
                        carbonEmissionsOptions: getCarbonEmissionsOptions(usersStore.currDispUserCloudAccountType),
                        costReadOnlyOptions: getCostReadOnlyOptions({
                          currDispUserCloudAccountType: usersStore.currDispUserCloudAccountType,
                          isFocus: usersStore.getIsCurrentAccountAzureFocus,
                          isAzureUnblended: usersStore.getIsCurrentAccountAzureUnblended,
                          isCurrentUserSharedReCustomer: usersStore.isCurrentUserSharedReCustomer,
                        }),
                        costOptions: getCostOptions(this.props.usersStore),
                        costDefaultType: this.isCustomerNonEDP ? 'Unblended' : undefined,
                        costValue: this.getAmortizeCostValue(),
                        usageValue: SelectedUsageType,
                        carbonEmissionsValue: this.getCarbonEmissionValue(),
                        costTypeMode: this.getCostType(),
                        onChange: this.handleUpdateCostUsage,
                      }}
                      resetHandler={this.resetToBaseState}
                      chartTypeHandler={{
                        onChange: this.toggleChartOrTable,
                        value: convertChartTypesFlag({
                          isDisplayTable,
                          isAreaChart,
                          isPieChart,
                          isLineChart,
                        }),
                      }}
                      viewMenuDisabled={!showViewOptions}
                      cumulativeHandler={{
                        value: isCumulative,
                        onChange: (value) => {
                          this.setState({ isCumulative: value, selectedGoal: null });
                        },
                      }}
                      trendLineHandler={
                        !isDisplayTable && {
                          value: isTrendLine,
                          onChange: (value) => {
                            this.setState({ isTrendLine: value });
                          },
                        }
                      }
                      trendRowHandler={
                        isDisplayTable && {
                          value: isTableTrendRow,
                          onChange: (value) => {
                            this.setState({ isTableTrendRow: value });
                          },
                        }
                      }
                      onlyTrendRowHandler={
                        isDisplayTable && {
                          value: isTableOnlyTrendRow,
                          onChange: (value) => {
                            this.setState({ isTableOnlyTrendRow: value });
                          },
                        }
                      }
                      eventsHandler={
                        !isDisplayTable && {
                          value: isEventsOpen,
                          onChange: (value) => {
                            this.setState({ isEventsOpen: value });
                          },
                        }
                      }
                      goalsHandler={
                        currUserMultiCloud || !this.getIsGoalsVisible()
                          ? null
                          : {
                              value: selectedGoal,
                              onChange: (value) => {
                                if (selectedGoal && value) {
                                  this.setState({ changeGoalModalOpen: value });
                                  return;
                                }
                                this.setState({ selectedGoal: value || null });
                              },
                              options: getCUEGoals(CUEGoals),
                            }
                      }
                      saveReportHandler={
                        currUserMultiCloud
                          ? null
                          : {
                              onClick: () => this.handleSaveReportButtonClick(false),
                              disabled: !usersStore.isHasPermission(
                                HierarchicalEntityCategory.Reports,
                                CategoryAction.Create,
                              ),
                            }
                      }
                      saveGoalHandler={
                        currUserMultiCloud || !this.getIsGoalsVisible()
                          ? null
                          : {
                              onClick: (goalId) => this.setState({ saveGoalModalOpen: goalId || true }),
                              options: getCUEGoals(CUEGoals),
                              disabled: !usersStore.isHasPermission(
                                OrganizationEntityCategory.CostAllocation,
                                CategoryAction.Create,
                              ),
                            }
                      }
                      overwriteReportHandler={
                        isFromReports && !!id && !currUserMultiCloud
                          ? {
                              onClick: () => this.handleSaveReportButtonClick(true),
                              disabled: !usersStore.isHasPermission(HierarchicalEntityCategory.Reports, Action.Update),
                            }
                          : null
                      }
                      saveDashboardHandler={{
                        onClick: () => this.handleSaveDashboardPanelButtonClick(false),
                        disabled: !usersStore.isHasPermission(
                          HierarchicalEntityCategory.Dashboards,
                          CategoryAction.Create,
                        ),
                      }}
                      overwriteDashboardHandler={
                        isFromCustomDashboard && !!id && !currUserMultiCloud
                          ? {
                              onClick: () => this.handleSaveDashboardPanelButtonClick(true),
                              disabled: !usersStore.isHasPermission(
                                HierarchicalEntityCategory.Dashboards,
                                Action.Update,
                              ),
                            }
                          : null
                      }
                      exportToCSVHandler={{ onClick: this.prepareExportTableToCsv }}
                      exportToPNGHandler={{
                        onClick: () => {
                          if (isDisplayTable) {
                            downloadTableAsPng(this.tableRef, TABLE_ID);
                          } else {
                            downloadChartAsPng(this.chartRef);
                          }
                        },
                      }}
                    />
                  </div>
                </div>
                {this.handleRenderChart(CHART_ID, modifiedDailyBalances, tableModifiedDailyBalance)}
              </Col>
              <Col style={{ display: showEventsBar ? '' : 'none' }} xs={3} md={3} lg={3} xl={3}>
                <EventsSideBar
                  ref={this.eventsSidebarRef}
                  granLevel={currPeriodGranLevel}
                  startDate={dataStartDate}
                  endDate={dataEndDate}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    );
  }
}

CostTrackingPage.propTypes = {
  currDispUserAccountKey: PropTypes.string.isRequired,
  getPageFilters: PropTypes.func.isRequired,
  invoiceStore: PropTypes.object.isRequired,
  usersStore: PropTypes.object.isRequired,
  usageStore: PropTypes.object.isRequired,
  appStore: PropTypes.object.isRequired,
  CUEGoals: PropTypes.array.isRequired,
  virtualTags: PropTypes.array.isRequired,
  reportsLoading: PropTypes.bool.isRequired,
  saveCueReport: PropTypes.func.isRequired,
  sendReport: PropTypes.func.isRequired,
  brandContext: PropTypes.object.isRequired,
  deleteReport: PropTypes.func.isRequired,
  setUrlParams: PropTypes.func.isRequired,
  urlState: PropTypes.object.isRequired,
  urlParams: PropTypes.object.isRequired,
};

const ObserverCostTracking = withUrlParamsComponentWrapper(
  withReportsContextProvider(
    withCUEGoalsContextProvider(
      withVirtualTagsContextProvider(
        withInvoiceFiltersContextConsumer(withUserSettingsConsumer(observer(CostTrackingPage))),
      ),
    ),
  ),
);

export default ObserverCostTracking;
