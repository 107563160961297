import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import LinkIconButton from 'shared/components/buttons/LinkIconButton.jsx';
import { Link } from 'react-router-dom';
import styles from './monitoringData.module.scss';

const MonitoringPanelAction = ({ url }) => {
  return (
    <div className={styles.actionWrapper}>
      <LinkIconButton automationid="exploreDashboardPanel">
        <Link
          key={'id'}
          to={{
            pathname: url,
          }}
        />
        <GenerateIcon iconName={ICONS.magnifyingGlassChart.name} className={styles.icon} />
      </LinkIconButton>
    </div>
  );
};

MonitoringPanelAction.propTypes = {
  url: PropTypes.string.isRequired,
};

export default MonitoringPanelAction;
