import React from 'react';
import { ReactComponent as OopsSVG } from 'shared/img/images/oops.svg';
import { v4 } from 'uuid';
import styles from './ErrorBoundary.module.scss';
import PropTypes from 'prop-types';
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true, errorURL: window.location.href };
  }

  componentDidCatch(error, info) {
    const errorUuid = v4();
    console.log(`CLIENT ERROR UUID:\n${errorUuid}`);
    const { handleEmitClientError = () => {} } = this.props;
    handleEmitClientError({ error: error.message, info, errorUuid });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError && prevState.errorURL !== window.location.href) {
      this.setState({ hasError: false });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.generalError}>
          <OopsSVG />
          <h2>Something went wrong</h2>
          <p>We couldn’t load this page, but you can still navigate using the menu.<br />
          Try again later.</p>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  handleEmitClientError: PropTypes.func,
  children: PropTypes.node.isRequired,
};
