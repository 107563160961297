import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';
import {
  DataTypeProvider,
  FilteringState,
  IntegratedFiltering,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  ColumnChooser,
  Grid,
  PagingPanel,
  TableColumnVisibility,
  TableColumnResizing,
  TableFilterRow,
  TableHeaderRow,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';
import Spinner from 'shared/components/andtComponents/Spinner';
import Panel from 'shared/components/Panel';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import NoDataFoundComponent from 'shared/components/NoDataFoundComponent';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

// eslint-disable-next-line react/prop-types
const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

class DevExpBigQueryTable extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    csvTitle: PropTypes.string,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    pageSize: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    changeColumnWidths: PropTypes.func.isRequired,
    columnWidths: PropTypes.array.isRequired,
    costColumnsArray: PropTypes.object,
    isLoading: PropTypes.bool,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      pageSizes: [5, 10, 15, 20],
      // modifiedDataForCsv: [],
      currentPage: 0,
      loadingMoreRows: false,
      hiddenColumnNames: [],
    };
  }

  setCurrentPage = async (newPage) => {
    this.setState({ currentPage: newPage });
  };
  prepareRowsForCsv = (row) =>
    Object.entries(row).reduce((acc, [key, value]) => {
      const currRelevantColumn = this.props.columns.find((col) => col.name === key);
      if (currRelevantColumn) {
        const { name, title } = currRelevantColumn;
        if (name === 'usageDate') {
          [acc.Date] = value.split(' ');
        } else if (name === 'jobQuery') {
          acc[title] = `${value}`;
        } else {
          acc[title] = value;
        }
      }
      return acc;
    }, {});

  prepareCsvModifiedRows = (data) => {
    const copyData = JSON.parse(JSON.stringify(data));
    const csvModifiedRows = copyData.map((row) => this.prepareRowsForCsv(row));
    return csvModifiedRows;
  };
  exportAsCsv = async () => {
    const { csvTitle = 'big-query', data } = this.props;
    const preparedData = this.prepareCsvModifiedRows(data);
    return [{ data: preparedData, filename: `${csvTitle}.csv` }];
  };
  currenciesFormatter = (value) => this.props.getCurrencyNumber(value.value, 3, { roundNumber: true });
  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  render() {
    const {
      pageSize,
      setPageSize,
      data,
      columns,
      startDate,
      endDate,
      columnWidths,
      changeColumnWidths,
      costColumnsArray = [],
      isLoading,
    } = this.props;
    const { hiddenColumnNames, loadingMoreRows, currentPage, pageSizes } = this.state;
    if (isLoading) {
      return (
        <Panel>
          <div style={{ height: '55px' }}>
            <Spinner />
          </div>
        </Panel>
      );
    }
    if (!data?.length) {
      return <NoDataFoundComponent />;
    }
    return (
      <div className="card">
        {startDate && endDate ? (
          <Row
            style={{
              marginLeft: '0px',
              marginTop: '10px',
              marginRight: '25px',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <CustomCSVDownload filesNumber={1} fetchData={this.exportAsCsv} classNames="btn link" />
          </Row>
        ) : null}
        {loadingMoreRows ? (
          <Spinner />
        ) : (
          <Grid rows={data} columns={columns}>
            <SortingState />
            <FilteringState />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.setCurrentPage}
              defaultCurrentPage={0}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedFiltering />
            <IntegratedSorting />
            <IntegratedPaging />
            {costColumnsArray && (
              <DataTypeProvider for={costColumnsArray} formatterComponent={this.currenciesFormatter} />
            )}
            <TableWrapper />
            <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={changeColumnWidths} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
            <TableColumnVisibility
              hiddenColumnNames={hiddenColumnNames}
              onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
            />
            <Toolbar />
            <ColumnChooser />
            <PagingPanel pageSizes={pageSizes} />
          </Grid>
        )}
      </div>
    );
  }
}

const ObserverDevExpRecommendationHistoryTable = withUserSettingsConsumer(DevExpBigQueryTable);
export default ObserverDevExpRecommendationHistoryTable;
