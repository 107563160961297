import React from 'react';
import { ReactComponent as CurrencyTypeImage } from 'shared/img/images/currency.svg';
import commonStyles from '../Pages.module.scss';
import styles from './GcpDetailsPage.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const GcpDetailsPageHelp = {};

GcpDetailsPageHelp.projectID = (gcpOnboardingBillingDatasetProjectIDHelp) => ({
  component: () => (
    <p className={commonStyles.helpText}>
      Copy the <span>ID of the project</span> that will host your BigQuery billing dataset.
    </p>
  ),
  image: gcpOnboardingBillingDatasetProjectIDHelp,
  imageHeight: 246,
});

GcpDetailsPageHelp.billingDatasetName = (gcpOnboardingBillingDatasetNameHelp) => ({
  component: () => (
    <p className={commonStyles.helpText}>
      Copy the <span>name of the dataset</span> you created and will store your billing data.
    </p>
  ),
  image: gcpOnboardingBillingDatasetNameHelp,
  imageHeight: 246,
  imageOpenWidth: 400,
});

GcpDetailsPageHelp.billingTableName = (gcpOnboardingBillingTableNameHelp) => ({
  component: () => (
    <p className={commonStyles.helpText}>
      Copy the <span>name of the billing table</span>.
    </p>
  ),
  image: gcpOnboardingBillingTableNameHelp,
  imageHeight: 267,
  imageOpenWidth: 400,
});

GcpDetailsPageHelp.secretKey = (gcpOnboardingServiceAccountKeyHelp) => ({
  component: () => (
    <p className={commonStyles.helpText}>
      Upload the <span>JSON file</span> you downloaded from the GCP console while creating a{' '}
      <span>Key to your storage account</span>.
    </p>
  ),
  image: gcpOnboardingServiceAccountKeyHelp,
  imageHeight: 246,
});

GcpDetailsPageHelp.currency = {
  component: () => (
    <div className={styles.accountNameHelp}>
      <p className={commonStyles.helpText}>
        Enter the type of currency you charge on GCP.
        <span className={commonStyles.notice}>Notice:</span>
        You will not be able to change this type after the onboarding is complete.
      </p>
      <CurrencyTypeImage />
    </div>
  ),
};

GcpDetailsPageHelp.accountName = {
  component: () => {
    const brand = useBrand();
    return (
      <div className={styles.accountNameHelp}>
        <p className={commonStyles.helpText}>Choose a display name for the account in {brand.name}.</p>
        <brand.accountName />
      </div>
    );
  },
};

export default GcpDetailsPageHelp;
