import React, { useEffect, useState } from 'react';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import classNames from 'classnames';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import useForecastData from 'app/containers/MainDashboard/hooks/react-query/useForecastData.js';
import styles from 'app/containers/MainDashboard/panels/costPanels.module.scss';
import { getFirstAndLastDayOfPreviousMonth, sumTotalCost } from 'app/containers/MainDashboard/utils.js';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import ForecastSpecialInfo from 'app/containers/MainDashboard/panels/HistoryWithForecast/ForecastSpecialInfo.jsx';
import UsageCost from '../components/UsageCost.jsx';

const ForecastedCost = () => {
  const { updatePanelLoadingState, getDynamicFilters, filters } = useMainDashboardContext();
  const [forecastPercent, setForecastPercent] = useState();
  const forecastHook = useForecastData();
  const cueHook = useCueData();
  const { appStore, usersStore } = useRootStore();

  const { data: forecastData, isLoading } = forecastHook.fetchForecastDataTotal(
    1,
    filters.cost?.[0]?.value,
    filters.exclude?.map((f) => f.label),
    appStore.isPpApplied,
  );

  const previousMonthParams = {
    ...PANELS[PANELS_TYPES.PREVIOUS_MONTH_COST].params,
    ...getFirstAndLastDayOfPreviousMonth(),
    ...getDynamicFilters(),
    isPpApplied: appStore.isPpApplied,
  };
  const { data: previousMonthData, isLoading: isPreviousMonthData } = cueHook.fetchCueData(previousMonthParams);

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.FORECASTED_MONTHLY_COST, isLoading || isPreviousMonthData);
  }, [isLoading, isPreviousMonthData]);

  useEffect(() => {
    if (!forecastData || !previousMonthData) {
      return;
    }
    const totalPreviousMonth = sumTotalCost(previousMonthData);
    const percent = totalPreviousMonth
      ? ((forecastData?.forecastTotal - totalPreviousMonth) * 100) / totalPreviousMonth
      : null;
    setForecastPercent(percent);
  }, [forecastData, previousMonthData]);

  return (
    <div className={classNames(styles.costPanel, styles.noHover)}>
      {isLoading ? (
        <Spinner className={styles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <UsageCost
          title={PANELS[PANELS_TYPES.FORECASTED_MONTHLY_COST].title}
          value={forecastData?.forecastTotal}
          specialInfo={<ForecastSpecialInfo />}
          showPercent
          percent={forecastPercent ? `${forecastPercent}` : null}
          displayComingSoon={!usersStore.isCurrentAccountSingle && !appStore.isKeyCloakManagement}
        />
      )}
    </div>
  );
};
export default ForecastedCost;
