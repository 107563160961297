import React from 'react';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import styles from 'app/containers/MainDashboard/panels/HistoryWithForecast/forecastHeader.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext.js';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';

const simpleForecastTooltip = `Filters will not affect the forecasted cost.`;

const TooltipList = ({ text }) => {
  return (
    <ul className={styles.tooltipList}>
      {text.split('\n').map((line, index) => (
        <li key={`line-${index}`}>
          {line}
          <br />
        </li>
      ))}
    </ul>
  );
};

const TooltipContent = (appStore, usersStore, mspCustomerMode) => {
  if (!appStore.isKeyCloakManagement) {
    if (mspCustomerMode) {
      return (
        <TooltipList
          text={`Filters will not affect the forecasted cost.
          The forecast doesn't reflect the customer pricing mode. Select a customer scope to view the customer-specific forecast.`}
        />
      );
    }
    return simpleForecastTooltip;
  }
  if (usersStore.isCurrentUserReseller || usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.AWS) {
    return '“Cost Type” filter will not affect the forecast cost.';
  }
  return (
    <TooltipList
      text={`Distributed Cost and Public Cost are not supported in forecasted costs. If selected in the filters, Unblended Cost will be displayed instead.
      “Cost Type” filter will not affect the forecast cost.`}
    />
  );
};
const ForecastSpecialInfo = () => {
  const { appStore, usersStore } = useRootStore();
  const brand = useBrand();
  const { isFiltersChanged } = useMainDashboardContext();

  const mspCustomerMode = usersStore.isCurrentUserReseller && appStore.isCustomerPricingMode;
  const boldForecastInfo = !appStore.isKeyCloakManagement && (mspCustomerMode || isFiltersChanged);

  return (
    <InfoPopover
      mode="outline"
      className={{
        icon: boldForecastInfo ? styles.boldInfoIcon : styles.infoIcon,
        tooltip: tooltipStyles.whiteTooltip,
        arrow: tooltipStyles.whiteArrow,
      }}
    >
      <span className={styles.tooltip}>
        <div className={styles.textWrapper}>{TooltipContent(appStore, usersStore, mspCustomerMode)}</div>
        <div>
          <a href={brand.externalLinks.ForecastArchitecture} className={styles.navigateText}>
            Learn more
          </a>{' '}
          about {brand.name}&apos;s forecasting
        </div>
      </span>
    </InfoPopover>
  );
};

export default ForecastSpecialInfo;
