import React, { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { getFirstDayOfMonth, getGroupSettingByAccount } from 'app/containers/MainDashboard/utils.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import { palette } from 'shared/constants/colorsConstants.js';
import DateFilter from 'shared/modules/dateFilter.js';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants.js';
import ForecastHeader from 'app/containers/MainDashboard/panels/HistoryWithForecast/ForecastHeader.jsx';
import { PANEL_COMPONENT_TYPES } from 'usage/containers/CustomDashboard/constants/customDashboardConstants.js';
import BarsChart from 'shared/components/dashboardCharts/BarsChart/BarsChart.jsx';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import { MonthlyChartCustomTooltip } from './MonthlyChartCustomTooltip.jsx';
import useForecastData from 'app/containers/MainDashboard/hooks/react-query/useForecastData.js';
import styles from '../../mainDashboardCommon.module.scss';

let dataKeys = [];
const singleAccountKey = 'Monthly Cost';
const singleAccountForecastKey = 'Forecast Monthly Cost';
const legendColors = [
  palette.lilach[400],
  palette.azure[500],
  palette.red[300],
  palette.blue[450],
  palette.blue[400],
  palette.teal[400],
];

const barGradient = (
  <defs>
    <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#51BCFF" />
      <stop offset="95.5%" stopColor={palette.blue[450]} />
    </linearGradient>
  </defs>
);

const colorForecastGradient = (
  <defs>
    <linearGradient id="colorForecastGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor={palette.azure[500]} stopOpacity={0.2} />
      <stop offset="100%" stopColor={palette.azure[500]} stopOpacity={1} />
    </linearGradient>
    <pattern id="patternForecastMonth" patternUnits="userSpaceOnUse" width="16" height="16">
      <rect width="100%" height="100%" fill="url(#colorForecastGradient)" />
      <path d="M 0 16 L 16 0" stroke="white" strokeWidth="0.8" />
    </pattern>
  </defs>
);

const forecastMonthsOptions = [
  { value: 1, label: '1M' },
  { value: 3, label: '3M' },
  { value: 6, label: '6M' },
  { value: 12, label: '12M' },
  { value: 13, label: '13M' },
];
const HistoryMonthlyWithForecast = ({ isFullWidth = false, hideSaveDashboard = false, forecastMonths }) => {
  const { appStore } = useRootStore();
  const { updatePanelLoadingState, dateFromSixMonthsAgo, getDynamicFilters, filters } = useMainDashboardContext();
  const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
  const { usersStore } = useRootStore();
  const [forecastNumMonth, setForecastNumMonth] = useState(forecastMonths || 1);
  const cueHook = useCueData();
  const forecastHook = useForecastData();
  const isSingleAccount = usersStore.isCurrentAccountSingle;

  const chartContainerRef = useRef(null);

  const params = {
    ...PANELS[PANELS_TYPES.HISTORY_MONTHLY_WITH_FORECAST].params,
    start: getFirstDayOfMonth(dateFromSixMonthsAgo),
    end: moment(DateFilter.getDate()).format('YYYY-MM-DD'),
    isPpApplied: appStore.isPpApplied,
    groupBy: getGroupSettingByAccount(usersStore),
    ...getDynamicFilters(),
  };
  const { data, isLoading: isFetchCueLoading } = cueHook.fetchCueData(params);

  const { data: forecastData, isLoading: isForecastDataLoading } = forecastHook.fetchForecastDataMonthly(
    filters.cost?.[0]?.value,
    filters.exclude?.map((f) => f.label),
  );
  const isLoading = isFetchCueLoading || isForecastDataLoading;

  const chartData = useMemo(() => {
    if (!data || isLoading) {
      return [];
    }
    dataKeys = [];
    if (isSingleAccount) {
      if (!dataKeys.includes(singleAccountKey)) {
        dataKeys.push(singleAccountKey);
      }
      const historyMonths = data?.map((row, index) => {
        const previousValue = data[index - 1] ? data[index - 1].total_cost : 0;
        let percentValue = 0;
        if (index > 0) {
          percentValue = previousValue ? ((row.total_cost - previousValue) * 100) / previousValue : null;
        }
        return {
          [singleAccountKey]: row.total_cost,
          usageDate: row.usage_date,
          percentChange: percentValue,
        };
      });
      const forecastMonths = forecastData?.forecastPerMonth
        ? Object.entries(forecastData?.forecastPerMonth)
            .map(([usageDate, value]) => ({
              usageDate,
              [singleAccountForecastKey]: value,
            }))
            .slice(0, forecastNumMonth)
        : [];
      if (forecastMonths.length > 0 && !dataKeys.includes(singleAccountForecastKey)) {
        dataKeys.push(singleAccountForecastKey);
      }
      return [...historyMonths, ...forecastMonths];
    } else {
      // Step 1: Accumulate the total cost per group across all usage dates
      const totalCosts = data.reduce((acc, { group_by, total_cost }) => {
        acc[group_by] = (acc[group_by] || 0) + parseFloat(total_cost);
        return acc;
      }, {});

      // Step 2: Sort the groups by total cost and determine the top 5 groups
      const sortedGroups = Object.keys(totalCosts).sort((a, b) => totalCosts[b] - totalCosts[a]);
      const top5Groups = sortedGroups.slice(0, 5);

      // add 5 groups are in dataKeys for the legend
      top5Groups.forEach((group) => {
        if (!dataKeys.includes(group)) {
          dataKeys.push(group);
        }
      });
      if (sortedGroups.length > 5 && !dataKeys.includes('other')) {
        dataKeys.push('other');
      }

      // Step 3: For each usage date, aggregate the costs for the top 5 groups and combine the rest into an "other" category
      return data.reduce((acc, { group_by, usage_date, total_cost }) => {
        const dateEntry = acc.find((entry) => entry.usageDate === usage_date);
        if (dateEntry) {
          if (top5Groups.includes(group_by)) {
            dateEntry[group_by] = (dateEntry[group_by] || 0) + parseFloat(total_cost);
          } else {
            const otherValue = (dateEntry['other'] || 0) + parseFloat(total_cost);
            if (otherValue !== 0) {
              dateEntry['other'] = otherValue;
            }
          }
        } else {
          acc.push({
            usageDate: usage_date,
            [group_by]: top5Groups.includes(group_by) ? parseFloat(total_cost) : 0,
          });
        }
        return acc;
      }, []);
    }
  }, [data, forecastData, isSingleAccount, forecastNumMonth]);

  const getBarFill = (index, key) => {
    if (isSingleAccount) {
      return key === singleAccountKey ? 'url(#barGradient)' : 'url(#patternForecastMonth)';
    }
    return legendColors[index];
  };

  const dataProperties = dataKeys.reduce((acc, key, index) => {
    acc[key] = { fill: getBarFill(index, key), legendColors: key !== singleAccountKey ? '#00b7f1' : null };
    return acc;
  }, {});

  useEffect(() => {
    updatePanelLoadingState(PANEL_COMPONENT_TYPES.COST_HISTORY, isLoading);
  }, [isLoading]);

  return (
    <div className={classNames(styles.panelWrapper, !isFullWidth && styles.halfWidthPanelWrapper)}>
      {isLoading ? (
        <Spinner className={styles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <>
          <ForecastHeader
            title={PANELS[PANELS_TYPES.HISTORY_MONTHLY_WITH_FORECAST].title}
            params={params}
            forecastMonths={forecastMonthsOptions}
            forecastNumMonth={forecastNumMonth}
            hideForecastDetails={!appStore.isKeyCloakManagement}
            setForecastNumMonth={setForecastNumMonth}
            panelType={PANEL_COMPONENT_TYPES.COST_HISTORY}
            hideSaveDashboard={hideSaveDashboard}
          />
          <div ref={chartContainerRef}>
            <BarsChart
              data={chartData}
              height={300}
              dataKeys={dataKeys}
              specialGradient={
                <>
                  {barGradient}
                  {colorForecastGradient}
                </>
              }
              width={
                forecastData?.forecastPerMonth
                  ? Math.max(chartContainerRef?.current?.offsetWidth, chartData.length * 100)
                  : null
              }
              showLabel={true}
              specialLegends={[singleAccountForecastKey]}
              isStack={!isSingleAccount}
              dataProperties={dataProperties}
              xKey="usageDate"
              isDateChart
              tooltipComponent={isSingleAccount ? <MonthlyChartCustomTooltip /> : null}
              axisSettings={{
                yTickFormatter: (value) => numStrAbriviaionByDisplayMetric(value, value, DisplayMetricTypes.COST),
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

HistoryMonthlyWithForecast.propTypes = {
  isFullWidth: PropTypes.bool,
  hideSaveDashboard: PropTypes.bool,
};

export default HistoryMonthlyWithForecast;
