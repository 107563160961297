import React, { ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { Chip } from '@mui/material';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import styles from './Input.module.scss';

interface InputProps {
  disabled?: boolean;
  isSearch?: boolean;
  clearable?: boolean;
  searchComponent?: React.ReactNode;
  value?: string | number;
  icon?: React.ReactNode;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  accept?: string;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: KeyboardEvent<HTMLInputElement>) => void;
  isAutoFocus?: boolean;
  automationid?: string;
  isInvalid?: boolean;
  invalidComponent?: React.ReactNode;
  placeholder?: string;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  className?: string;
  type?: string;
  min?: number | string;
  max?: number | string;
  id?: string;
  name?: string;
  readOnly?: boolean;
  overrideStyles?: React.CSSProperties;
  autoComplete?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled = false,
      isSearch = false,
      clearable = false,
      searchComponent = null,
      value = '',
      icon = null,
      onChange,
      onBlur,
      accept = '',
      onFocus,
      onKeyDown,
      isAutoFocus = false,
      automationid = '',
      isInvalid = false,
      invalidComponent = null,
      placeholder = '',
      onKeyPress,
      className = '',
      type = 'text',
      min,
      max,
      id,
      name = '',
      readOnly = false,
      overrideStyles = {},
      autoComplete,
    },
    ref,
  ) => {
    const classes = `${styles.andtInput} ${isInvalid ? styles.invalid : ''} ${className} ${
      isSearch ? styles.search : ''
    }`;

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      segmentEvent({
        type: 'focus',
        target: automationid || name || 'Input',
        automationid,
      });
      onFocus?.(event);
    };

    const renderInput = () => (
      <React.Fragment>
        <input
          ref={ref}
          accept={accept}
          className={classes}
          placeholder={placeholder}
          disabled={disabled}
          value={type === 'file' ? '' : (value ?? '')}
          onChange={onChange}
          onBlur={onBlur}
          autoComplete={autoComplete}
          onFocus={handleFocus}
          onKeyPress={onKeyPress}
          onKeyDown={onKeyDown}
          autoFocus={isAutoFocus}
          data-automation-id={automationid}
          type={type}
          min={min}
          max={max}
          id={id}
          name={name}
          readOnly={readOnly}
          style={{ ...overrideStyles, ...(type === 'file' ? { display: 'none' } : {}) }}
        />
        {isSearch ? <div className={styles.searchIcon}>{searchComponent}</div> : null}
        {isInvalid && <p className={styles.error}>{invalidComponent}</p>}
      </React.Fragment>
    );

    if (type === 'file') {
      return (
        <>
          <label
            htmlFor={id}
            style={{ ...overrideStyles }}
            data-placeholder={!value}
            className={classes}
            aria-disabled={disabled}
          >
            {value && clearable ? (
              <Chip
                label={value}
                onDelete={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  const syntheticEvent = {
                    target: { value: null },
                  } as unknown as ChangeEvent<HTMLInputElement>;
                  onChange?.(syntheticEvent);
                }}
              />
            ) : (
              value || placeholder
            )}
            <span className={styles.uploadButton}>
              {icon}
              Browse
            </span>
          </label>
          {renderInput()}
        </>
      );
    }

    return renderInput();
  },
);

Input.displayName = 'Input';

export default Input;
