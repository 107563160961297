import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import LinkedAccountsComponentHeader from './LinkedAccountsComponentHeader';
import LinkedAccountsComponentTable from './LinkedAccountsComponentTable';
import {
  getIsAccountSelected,
  getLinkedAccountIsSelectionEnabled,
  getLinkedAccountsIsSelectionHidden,
  getPayerIsSelectionEnabled,
} from './dataAccessHelperFunctions';
import { ACCOUNT_TYPES } from 'users/containers/Organization/consts.js';

import styles from './LinkedAccountsComponent.module.scss';

function getAllEnabledPayers(allAccountsData, isCreateEditMode, entity) {
  return allAccountsData?.accountsData
    ? Object.values(allAccountsData?.accountsData)
        .flatMap((account) => account)
        .map((account) => account.payerAccount)
        .filter((payer) => getPayerIsSelectionEnabled(payer, isCreateEditMode, entity))
    : [];
}

function getAllEnabledLinkedAccounts(allAccountsData, modifiedSelectionById, entity) {
  return allAccountsData?.accountsData
    ? Object.values(allAccountsData?.accountsData)
        .flatMap((account) => account)
        .map((account) => ({ linkedAccounts: account.linkedAccounts, payerAccount: account.payerAccount }))
        .map((pa) => ({
          linkedAccounts: pa.linkedAccounts.filter(
            (linkedAccount) =>
              getLinkedAccountIsSelectionEnabled(linkedAccount, entity) &&
              !getLinkedAccountsIsSelectionHidden(pa.payerAccount, modifiedSelectionById?.[pa.payerAccount.id], entity),
          ),
          payerAccount: pa.payerAccount,
        }))
        .filter((pa) => pa.linkedAccounts?.length)
    : [];
}

const LinkedAccountsComponent = ({
  additionalData,
  allAccountsData,
  entity,
  isCreateEditMode,
  isViewOnly,
  linkedAccountsColumns,
  modifiedSelectionById,
  payerAccountsColumns,
  onFullAccessSelectChanged,
  onRemoveLinkedAccount,
  onSelectionChange,
  onSelectAllChanged,
}) => {
  const [search, setSearch] = useState();

  const { NewTableWrapper } = useTable();

  const filteredAccountsData = useMemo(() => {
    if (!allAccountsData?.accountsData) {
      return null;
    }
    if (!search) {
      return allAccountsData;
    }
    const { accountsData } = allAccountsData;
    const filteredData = [];

    function searchArray(arr) {
      return arr
        .map((item) => {
          const newItem = { ...item };
          if (Array.isArray(newItem.linkedAccounts)) {
            newItem.linkedAccounts = searchArray(newItem.linkedAccounts);
          }
          if (
            newItem.payerAccount?.name?.toLowerCase().includes(search?.toLowerCase()) || // Search Payer accounts level
            newItem.name?.toLowerCase().includes(search?.toLowerCase()) || // Search Linked accounts level
            (newItem.linkedAccounts && newItem.linkedAccounts.length > 0)
          ) {
            return newItem;
          }
          return null;
        })
        .filter((item) => item !== null);
    }

    Object.entries(accountsData).forEach(([key, array]) => {
      filteredData[key] = searchArray(array);
    });

    return { ...allAccountsData, accountsData: filteredData };
  }, [search, allAccountsData]);

  const handleSelectAll = (isSelected) => {
    const allSelection = { [ACCOUNT_TYPES.PAYER_ACCOUNT]: [], [ACCOUNT_TYPES.LINKED_ACCOUNT]: [] };
    if (isSelected === true) {
      const payerIds = isSelected
        ? getAllEnabledPayers(allAccountsData, isCreateEditMode, entity).map((payer) => payer.id)
        : [];
      allSelection[ACCOUNT_TYPES.PAYER_ACCOUNT] = payerIds;

      if (!isCreateEditMode && modifiedSelectionById) {
        const linkedAccountPerPayer = isSelected
          ? getAllEnabledLinkedAccounts(allAccountsData, modifiedSelectionById, entity)
          : [];
        linkedAccountPerPayer.forEach((pa) => allSelection[ACCOUNT_TYPES.LINKED_ACCOUNT].push(pa));
      }
    }
    onSelectAllChanged(allSelection);
  };

  const isAllSelected = useMemo(() => {
    // If payer is not selectable, it should be considered as selected, even if it is not
    const payerAccounts = getAllEnabledPayers(allAccountsData, isCreateEditMode, entity);
    let allAccounts = [...payerAccounts];
    if (!isCreateEditMode) {
      const linkedAccounts = getAllEnabledLinkedAccounts(allAccountsData, modifiedSelectionById, entity).flatMap(
        (pa) => pa.linkedAccounts,
      );
      allAccounts = [...payerAccounts, ...linkedAccounts];
    }
    return allAccounts?.length > 0 && allAccounts.every((payer) => getIsAccountSelected(payer, modifiedSelectionById));
  }, [allAccountsData, isCreateEditMode, entity, modifiedSelectionById]);

  return filteredAccountsData?.accountsData && Object.keys(filteredAccountsData?.accountsData).length > 0 ? (
    <div className={styles.cloudProvidersContainer}>
      <NewTableWrapper>
        <LinkedAccountsComponentHeader
          allAccountsData={allAccountsData}
          entity={entity}
          isCreateEditMode={isCreateEditMode}
          isSelectAll={isAllSelected}
          isViewOnly={isViewOnly}
          modifiedSelectionById={modifiedSelectionById}
          onRemoveSelected={() => onRemoveLinkedAccount()}
          onSelectAll={handleSelectAll}
          onFullAccessSelectChanged={onFullAccessSelectChanged}
          search={search}
          setSearch={setSearch}
        />
        <LinkedAccountsComponentTable
          additionalData={additionalData}
          allAccountsData={filteredAccountsData}
          entity={entity}
          isCreateEditMode={isCreateEditMode}
          isViewOnly={isViewOnly || !!allAccountsData?.dataAccessWithFullAccess}
          linkedAccountsColumns={linkedAccountsColumns}
          modifiedSelectionById={modifiedSelectionById}
          onRemoveLinkedAccount={onRemoveLinkedAccount}
          onSelectionChange={onSelectionChange}
          payerAccountsColumns={payerAccountsColumns}
        />
      </NewTableWrapper>
    </div>
  ) : (
    <div>No data access for this Role</div>
  );
};

LinkedAccountsComponent.propTypes = {
  additionalData: PropTypes.object,
  allAccountsData: PropTypes.object,
  entity: PropTypes.string,
  isCreateEditMode: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  linkedAccountsColumns: PropTypes.array,
  modifiedSelectionById: PropTypes.object,
  onFullAccessSelectChanged: PropTypes.func,
  onRemoveLinkedAccount: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onSelectAllChanged: PropTypes.func,
  payerAccountsColumns: PropTypes.array,
};

export default LinkedAccountsComponent;
