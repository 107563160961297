import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Spinner from 'shared/components/andtComponents/Spinner';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import {
  provideAccountsData,
  getInitialAccountsDataWithCounters,
} from '../../../components/LinkedAccounts/dataAccessHelperFunctions';
import LinkedAccountsComponentWrapper from '../../../components/LinkedAccounts/LinkedAccountsComponentWrapper';
import {
  DATA_ACCESS_TOOLTIPS,
  ENTITIES,
  ROLE_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS,
  ROLE_ADD_REMOVE_PAYER_ACCOUNTS_COLUMNS,
} from '../../../consts';

import styles from './EditRoleLinkedAccountsModal.module.scss';

const EditRoleLinkedAccountsModal = ({ isOpen, setIsOpen, role }) => {
  const [saveClicked] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [payloadForSave, setPayloadForSave] = useState({});

  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts(!isOpen);
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();

  const { fetchRoleDataAccess, updateAccountsAccessibilityForRole } = useRoles(!isOpen);
  const { data: roleDataAccess, isLoading: isRoleDataAccessLoading } = fetchRoleDataAccess(role?.id);

  const dataToDisplay = useMemo(() => {
    if (!isOpen) {
      return null;
    }
    if (!accountsData || !roleDataAccess) {
      return null;
    }
    const combinedData = getInitialAccountsDataWithCounters(accountsData);
    const combinedPayerAccountsData = provideAccountsData({
      accountsData,
      roleDataAccess,
      currentRoleId: role?.id,
      isCreateEditMode: true,
    });

    const dataAccessWithFullAccess = roleDataAccess.find(
      (roleAccess) => roleAccess.payerAccounts?.allLinkedAccountsOfAllPayerAccounts,
    );
    return { ...combinedData, accountsData: combinedPayerAccountsData, dataAccessWithFullAccess };
  }, [accountsData, isOpen, roleDataAccess, role?.id]);

  const handleSelectionChange = (payload) => {
    setPayloadForSave(payload);
  };

  const onSave = async () => {
    setIsSaving(true);
    await updateAccountsAccessibilityForRole.mutateAsync({ roleId: role?.id, roleDataAccess: payloadForSave });
    setIsSaving(false);
    setIsOpen(false);
  };

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.EDIT}
      comment={DATA_ACCESS_TOOLTIPS.ACCOUNTS_TOOLTIP}
      footerDisabled={isSaving && saveClicked}
      onSave={onSave}
      open={isOpen}
      automationid="edit-role-accounts-modal"
      saveTitle="Save"
      saveDisabled={false}
      title="Edit Accounts"
    >
      {isAccountsDataLoading || isRoleDataAccessLoading ? (
        <Spinner />
      ) : (
        <LinkedAccountsComponentWrapper
          additionalData={{ roleId: role?.id }}
          allAccountsData={dataToDisplay}
          entity={ENTITIES.ROLE.id}
          isCreateEditMode
          isViewOnly={!role?.editable}
          linkedAccountsColumns={Object.values(ROLE_ADD_REMOVE_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
          onSelectionChange={handleSelectionChange}
          payerAccountsColumns={Object.values(ROLE_ADD_REMOVE_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
        />
      )}
    </CustomModal>
  );
};

EditRoleLinkedAccountsModal.propTypes = {
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  role: PropTypes.object,
};

export default EditRoleLinkedAccountsModal;
