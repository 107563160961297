/* eslint-disable max-len */
import { API } from 'shared/utils/apiMiddleware';
import { sanitizeParam } from 'shared/utils/apiUtil';

class InvoicesApi {
  getMetricsTypes = () => API.get('billings', '/api/v1/invoices/metrics/types');
  getMetricsData = ({
    groupBy,
    startDate,
    endDate,
    periodGranLevel,
    filtersMap,
    excludedFiltersMap,
    dimensions,
    metric,
  }) => {
    const formattedGroupBy = sanitizeParam(groupBy);
    const arrDimensions = dimensions.map((d) => `&dimension=${d}`).join('');
    const apiQuery = `/api/v1/invoices/metrics?groupBy=${formattedGroupBy}&startDate=${startDate}&endDate=${endDate}&periodGranLevel=${periodGranLevel}${arrDimensions}${filtersMap}${excludedFiltersMap}&metricTypeId=${metric}`;
    return API.get('billings', apiQuery);
  };
  getServiceNames = async () => {
    const apiQuery = '/api/v1/invoices/service-names/distinct';
    return API.get('billings', apiQuery, true);
  };
}

export default InvoicesApi;
