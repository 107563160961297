import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import { cloneDeep } from 'lodash';
import toast from 'shared/components/andtComponents/Toast';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { ROLE_ACTIONS, ROLES_COLUMNS } from '../../consts';

import styles from './EditRoleModal.module.scss';

const EditRoleModal = ({ isOpen, setIsOpen, role, isViewOnly = false }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [localRole, setLocalRole] = useState(cloneDeep(role));

  const { updateRole } = useRoles();

  const onSave = async () => {
    try {
      setIsSaving(true);
      await updateRole.mutateAsync({ roleId: role.id, roleDetails: localRole });
      setIsOpen(false);
    } catch (error) {
      if (error.response.status === 500) {
        toast.error('Error while updating role');
      }
    } finally {
      setIsSaving(false);
    }
  };

  const isSaveDisabled = useMemo(() => {
    return (
      isViewOnly ||
      isSaving ||
      (localRole.displayName === role.displayName && localRole.description === role.description)
    );
  }, [isSaving, isViewOnly, localRole.description, localRole.displayName, role.description, role.displayName]);

  return (
    <CustomModal
      className={styles}
      onClose={() => {
        setIsOpen(false);
      }}
      onCloseClick={() => {
        setIsOpen(false);
      }}
      headerMode={MODAL_MODES.EDIT}
      footerDisabled={isSaving}
      onSave={onSave}
      saveDisabled={isSaveDisabled}
      open={isOpen}
      automationid="edit-role-modal"
      saveTitle="Save"
      title={isViewOnly ? ROLE_ACTIONS.VIEW.label : ROLE_ACTIONS.EDIT.label}
    >
      <div className={styles.editRoleContainer}>
        <div className={styles.propertyContainer}>
          <span>{ROLES_COLUMNS.ROLE_NAME.title}</span>
          <Input
            disabled={isViewOnly}
            value={localRole?.displayName}
            placeholder={ROLES_COLUMNS.ROLE_NAME.placeholder}
            onChange={(e) => {
              setLocalRole({ ...localRole, [ROLES_COLUMNS.ROLE_NAME.columnName]: e?.target?.value });
            }}
          />
        </div>
        <div className={styles.propertyContainer}>
          <span>{ROLES_COLUMNS.ROLE_DESCRIPTION.title}</span>
          <Input
            disabled={isViewOnly}
            value={localRole?.description}
            placeholder={ROLES_COLUMNS.ROLE_DESCRIPTION.placeholder}
            onChange={(e) => {
              setLocalRole({ ...localRole, [ROLES_COLUMNS.ROLE_DESCRIPTION.columnName]: e?.target?.value });
            }}
          />
        </div>
      </div>
    </CustomModal>
  );
};

EditRoleModal.propTypes = {
  isViewOnly: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

export default EditRoleModal;
