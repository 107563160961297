import React from 'react';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import DailyHourlySwitchButton from './DailyHourlySwitchButton';
import styles from './recommendedChart.module.scss';
import DownloadChartMenu from './DownloadChartMenu';
import InfoPopover from "shared/components/andtComponents/InfoPopover/index.jsx";

const sparklesIcon = () => <GenerateIcon iconName={ICONS.sparkles.name} className={styles.sparklesIcon} />;
const ChartHeader = ({
  header,
  info,
  exportToCsv,
  exportToPng,
  includeEstimatedOption,
  isDailyMode,
  setIsDailyMode,
  isEstimatedMode,
  setIsEstimatedMode,
  automationid,
  showEvents,
  setShowEvents,
  includeEvents,
  chartType,
  hideDownload=false
}) => (
  <div className={styles.headerWrapper}>
    <div className={styles.flex}>
      <span className={styles.header}>{header}</span>
      <Tooltip arrow title={info}>
        <span>
          <GenerateIcon iconName={ICONS.circleInfo.name} className={styles.icon} />
        </span>
      </Tooltip>
      <DailyHourlySwitchButton isDailyMode={isDailyMode} setIsDailyMode={setIsDailyMode} automationid={automationid} />
    </div>
    <div className={styles.flex}>
      {includeEvents && (<div className={styles.eventWrapper}>
        <Checkbox
          text="Show Events"
          isChecked={showEvents}
          primary
          onChange={() => setShowEvents(!showEvents)}
        />
        <InfoPopover isSimple>{"Event of SP Purchase/Expiration"}</InfoPopover>
      </div>)}
      {includeEstimatedOption && (
        <Button
          text={
            <Checkbox
              text="Estimated Cost"
              isChecked={!!isEstimatedMode}
              onChange={() => setIsEstimatedMode(!isEstimatedMode)}
              noPaddings
              color="white"
            />
          }
          icon={sparklesIcon}
          iconPlat="right"
        />
      )}
      {!hideDownload && <DownloadChartMenu exportToCsv={exportToCsv}
                                           exportToPng={exportToPng}
                                           chartType={chartType}
                                           isEstimatedMode={isEstimatedMode}
                                           isDailyMode={isDailyMode} />}

    </div>
  </div>
);

ChartHeader.propTypes = {
  header: PropTypes.string.isRequired,
  info: PropTypes.string,
  exportToCsv: PropTypes.func,
  exportToPng: PropTypes.func,
  exportToSvg: PropTypes.func,
  includeEstimatedOption: PropTypes.bool,
  isDailyMode: PropTypes.bool,
  setIsDailyMode: PropTypes.func,
  isEstimatedMode: PropTypes.bool,
  setIsEstimatedMode: PropTypes.func,
  automationid: PropTypes.string,
  showEvents: PropTypes.bool,
  setShowEvents: PropTypes.func,
  includeEvents: PropTypes.bool,
  hideDownload: PropTypes.bool,
  chartType: PropTypes.string,
};

export default ChartHeader;
