// import { decorate, observable, runInAction } from 'mobx';;
import User from './user';
import Account from './account';

export default class SubUser extends User {
  constructor(args) {
    super(args);
    this.childs = [];
    this.accounts = this.setUserAccounts({ accounts: args.accounts, isKeyCloakManagement: this.isKeyCloakManagement });
  }

  setUserAccounts = ({ accounts, isKeyCloakManagement }) => {
    if (accounts) {
      const arrOfAccounst = accounts.map((acc) => new Account({ ...acc, isKeyCloakManagement }));
      return arrOfAccounst;
    }
    return [];
  };
}
