import { getLinkedAccountName } from 'shared/utils/cloudUtils';
import moment from 'moment';
import { calcNumOfMonthsToBreakEven } from 'shared/utils/calcUtil';
import { snakeCase } from 'lodash';

export const getNumericValue = (value) => {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    return !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  }
  return undefined;
};

export const getBooleanValue = (value) => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (typeof value === 'number') {
    return value === 1 ? 'Yes' : 'No';
  }
  if (typeof value === 'string') {
    return value === 'true' ? 'Yes' : 'No';
  }
  return undefined;
};

/* uuid, action, suggestion_status_id: status, is_suggestion_active: isActive, is_recommendation_active: isRecommActive,
   recommendation_status_id: recommStatus, recommendation_creation_time: creationTime,
   initial_creation_date: initialCreationDate, recommendation_update_time: updateTime, alert_id: alertId,
   rejected_reason_id: rejReason, exclude_message: excludeMessage, */
export const BASE_PROPERTIES = {
  LABELS: {
    label: 'Labels',
    getterFunction: (recommendation) => recommendation.labels,
  },
  LINKED_ACCOUNT: {
    label: 'Linked Account',
    labelGetter: (cloudType) => getLinkedAccountName(cloudType),
    getterFunction: (recommendation) => `${recommendation.linkedAccountName} (${recommendation.linkedAccountId})`,
  },
  REGION: {
    label: 'Region',
    getterFunction: (recommendation) => recommendation.region,
  },
  RESOURCE_ID: {
    label: 'Resource Id',
    getterFunction: (recommendation) => recommendation.resourceId,
  },
  RESOURCE_NAME: {
    label: 'Resource Name',
    getterFunction: (recommendation) => recommendation.resourceName || recommendation.recData.resource_arn,
  },
  RESOURCE_TAGS: {
    label: 'Resource Tags',
    getterFunction: (recommendation) =>
      !!recommendation.resourceTags && typeof recommendation.resourceTags === 'object'
        ? Object.entries(recommendation.resourceTags).map(([k, v]) => `${k}: ${v}`)
        : null,
  },
  SIGNATURE: {
    label: 'Signature',
    getterFunction: (recommendation) => recommendation.signature,
  },
  SUBSCRIPTION_ID: {
    label: 'Subscription Id',
    getterFunction: (recommendation) => recommendation.subscriptionId, // Maybe should be part of the Linked Account?
  },
  TAGS: {
    label: 'Tags',
    getterFunction: (recommendation) => {
      if (!recommendation?.customTags || !Object.keys(recommendation.customTags).length) {
        return null;
      }
      let tags = '';
      if (recommendation?.customTags) {
        tags = Object.entries(recommendation.customTags)
          ?.map(([k, v]) => `${k}: ${v}`)
          .join(', ');
      }
      return tags;
    },
  },
  ENRICHMENT_TAGS: {
    label: 'Enrichment Tags',
    getterFunction: (recommendation) => {
      if (!recommendation.enrichmentTags || !Object.keys(recommendation.enrichmentTags).length) {
        return null;
      }
      let tags = '';
      if (recommendation?.enrichmentTags) {
        const enrichmentTags = recommendation?.enrichmentTags ?? {};
        tags += Object.entries(enrichmentTags)
          .map(([k, v]) => `${k}: ${v}`)
          .join(', ');
      }
      return tags;
    },
  },
  QUANTITY: {
    label: 'Quantity',
    getterFunction: (recommendation) =>
      recommendation?.recData?.resource_quantity ||
      recommendation?.recData?.resources_quantity ||
      recommendation?.recData?.quantity,
  },
  STARTING_TIME: {
    label: 'Starting Time',
    getterFunction: (recommendation) => {
      if (recommendation?.recData?.starting_time) {
        return typeof recommendation?.recData?.starting_time === 'string'
          ? recommendation?.recData?.starting_time
          : moment(recommendation.recData.starting_time * 1000).format('YYYY-MM-DD HH:mm:ss');
      }
      return undefined;
    },
  },
  MULTI_AZ: {
    label: 'Multi-AZ',
    getterFunction: (recommendation, optionIndex) => {
      let isMultiAZ =
        getBooleanValue(recommendation?.recData?.is_multi_az) || getBooleanValue(recommendation?.recData?.multi_az);
      const alternatives = recommendation?.recData?.alternatives;
      const alternative = alternatives ? alternatives[optionIndex] : {};
      const isAlternativeMultiAz = getBooleanValue(alternative?.is_multi_az) || getBooleanValue(alternative?.multi_az);
      if (optionIndex >= 0 && isAlternativeMultiAz) {
        isMultiAZ = isAlternativeMultiAz;
      }
      return isMultiAZ;
    },
  },
  DAYS_TO_CHECK: {
    label: 'Days to Check',
    getterFunction: (recommendation) => recommendation?.recData?.days_to_check,
  },
  CPU_UTIL: {
    label: 'Max CPU Utilization (%)',
    isPercent: true,
    getterFunction: (recommendation, optionIndex) => {
      let cpuUtil = recommendation?.recData?.cpu_util;
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        cpuUtil = recommendation.recData.alternatives[optionIndex]?.max_cpu_utilization_percentage;
      }
      return getNumericValue(cpuUtil)?.toFixed(2);
    },
  },
  OPERATION_SYSTEM: {
    label: 'Operation System',
    getterFunction: (recommendation) => recommendation.recData.operation_system || recommendation.recData.os,
  },
};

export const COST_PROPERTIES = {
  POTENTIAL_SAVINGS: {
    label: 'Potential Savings',
    isNoColorInOptions: true,
    isCurrency: true,
    getterFunction: (recommendation, optionIndex, costTypeMode) => {
      if (!recommendation.annualSavings?.[costTypeMode]) {
        return undefined;
      }
      let savingValue = `${recommendation.annualSavings[costTypeMode]?.toLocaleString()}
      (${
        recommendation.annualCurrentCost?.[costTypeMode] > 0
          ? (
              (100 * recommendation.annualSavings[costTypeMode] || 1) / recommendation.annualCurrentCost[costTypeMode]
            ).toLocaleString(undefined, { maximumFractionDigits: 2 })
          : 0
      }% lower Annual cost)`;
      const alternatives = recommendation?.recData?.alternatives;
      if (optionIndex >= 0 && alternatives?.length && alternatives[optionIndex]) {
        const option = recommendation.recData.alternatives[optionIndex];
        const savings = option?.[`potential_savings_${snakeCase(costTypeMode)}`] || option?.potential_savings;
        const optionSavingAmount =
          option?.[`saving_amount_${snakeCase(costTypeMode)}`] || option?.saving_amount || option?.savings_amount || 0;
        savingValue = `${optionSavingAmount.toLocaleString()} (${savings}%)`;
      }
      return savingValue;
    },
  },
  CURRENT_ANNUAL_COST: {
    label: 'Current Annual Cost',
    labelForOption: 'Annual Cost',
    isCurrency: true,
    isNoColorInOptions: true,
    getterFunction: (recommendation, index, costTypeMode) =>
      getNumericValue(recommendation?.annualCurrentCost?.[costTypeMode])?.toLocaleString(undefined, {
        maximumFractionDigits: 2,
      }),
  },
  RECOMMENDED_ANNUAL_COST: {
    label: 'New Annual Cost',
    labelForOption: 'Annual Cost',
    isCurrency: true,
    isNoColorInOptions: true,
    getterFunction: (recommendation, optionIndex, costTypeMode) => {
      let newAnnualCost =
        (recommendation.annualCurrentCost?.[costTypeMode] ?? 0) - (recommendation.annualSavings?.[costTypeMode] ?? 0);
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        const option = recommendation.recData.alternatives[optionIndex];
        newAnnualCost = option?.[`annual_${snakeCase(costTypeMode)}`] || option?.annual_cost || option?.cost; // "cost" is to support old name
      }
      return getNumericValue(newAnnualCost)?.toLocaleString(undefined, { maximumFractionDigits: 2 });
    },
  },
  HOURLY_COST: {
    label: 'Hourly Cost',
    isCurrency: true,
    getterFunction: (recommendation, optionIndex) => {
      let hourlyCost = recommendation?.recData?.hourly_cost;
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        hourlyCost = recommendation.recData.alternatives[optionIndex]?.hourly_cost;
      }
      return getNumericValue(hourlyCost)?.toLocaleString(undefined, { maximumFractionDigits: 2 });
    },
  },
};

export const EC2_PROPERTIES = {
  ENVIRONMENT: {
    label: 'Environment',
    getterFunction: (recommendation) => recommendation.recData.environment,
  },
  PROJECT: {
    label: 'Project',
    getterFunction: (recommendation) => recommendation.recData.project,
  },
  NETWORK_PERFORMANCE: {
    label: 'Network Performance',
    getterFunction: (recommendation) => recommendation.recData.network_performance,
  },
  RECOMMENDED_NETWORK_PERFORMANCE: {
    label: 'Recommended Network Performance',
    labelForOption: 'Network Performance',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.network_performance;
      }
      return recommendation?.recData?.network_performance;
    },
  },
};

export const DB_PROPERTIES = {
  DB_TYPE: {
    label: 'DB Type',
    getterFunction: (recommendation) => recommendation?.recData?.db_type,
  },
  DB_NAME: {
    label: 'DB Name',
    getterFunction: (recommendation) => recommendation?.recData?.db_name,
  },
  NUM_OF_CONNECTION: {
    label: 'Connections in period',
    getterFunction: (recommendation) => {
      const numOfConnections = recommendation?.recData?.num_of_connections;
      const maxNumOfConnections =
        recommendation?.recData?.max_num_of_connections || recommendation?.recData?.max_connections || 0;
      if (typeof numOfConnections === 'object') {
        return maxNumOfConnections === 0 ? '0' : maxNumOfConnections;
      }
      if (numOfConnections !== undefined) {
        return numOfConnections === 0 ? '0' : numOfConnections;
      }
      return maxNumOfConnections === 0 ? '0' : maxNumOfConnections;
    },
  },
};

export const ENGINE_PROPERTIES = {
  ENGINE: {
    label: 'Engine',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.engine;
      }
      return recommendation?.recData?.engine;
    },
  },
  ENGINE_VERSION: {
    label: 'Engine Version',
    getterFunction: (recommendation) => recommendation?.recData?.engine_version,
  },
};

export const RDS_PROPERTIES = {
  CURRENT_INSTANCE_TYPE: {
    label: 'Current Instance Type',
    isBold: true,
    getterFunction: (recommendation) => {
      const instanceType =
        recommendation.instanceType ||
        recommendation?.recData?.instance_type ||
        recommendation?.recData?.instance_type_current;
      if (instanceType) {
        return instanceType;
      }
      return recommendation.recData?.instance_type_model
        ? `${recommendation.recData?.instance_type_model}.${recommendation.recData?.instance_type_size}`
        : recommendation.recData?.instance_type_size;
    },
  },
  RECOMMENDED_INSTANCE_TYPE: {
    label: 'Recommended Instance Type',
    labelForOption: 'Instance Type',
    isBold: true,
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.instance_type;
      }
      return recommendation?.recData?.recommended_instance_type || recommendation?.instanceType;
    },
  },
  CURRENT_INSTANCE_FAMILY: {
    label: 'Instance Family',
    getterFunction: (recommendation) => recommendation.recData?.instance_family,
  },
  RECOMMENDED_INSTANCE_FAMILY: {
    label: 'Recommended Instance Family',
    labelForOption: 'Instance Family',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.instance_family;
      }
      return recommendation?.recData?.recommended_instance_family;
    },
  },
  CURRENT_INSTANCE_TYPE_FAMILY: {
    label: 'Instance Type Family',
    getterFunction: (recommendation) =>
      recommendation.recData?.instance_type_family ||
      recommendation.recData?.instance_type_model ||
      recommendation.recData?.model,
  },
  RECOMMENDED_INSTANCE_TYPE_FAMILY: {
    label: 'Recommended Instance Type Family',
    labelForOption: 'Instance Type Family',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return (
          recommendation.recData.alternatives[optionIndex]?.instance_type_family ||
          recommendation.recData.alternatives[optionIndex]?.instance_type_model ||
          recommendation.recData.alternatives[optionIndex]?.model
        );
      }
      return recommendation?.recData?.recommended_instance_type_family;
    },
  },
  CPU: {
    label: 'vCPUs',
    getterFunction: (recommendation, optionIndex) => {
      let cpu = getNumericValue(recommendation?.recData?.cpu);
      const alternatives = recommendation?.recData?.alternatives;
      if (optionIndex >= 0 && alternatives) {
        const alternativeCpu = getNumericValue(alternatives[optionIndex]?.cpu);
        if (alternativeCpu) {
          cpu = alternativeCpu;
        }
      }
      return cpu;
    },
  },
  STORAGE: {
    label: 'Storage',
    getterFunction: (recommendation, optionIndex) => {
      let storageType =
        recommendation.recData?.storage_type ||
        recommendation?.recData?.instance_storage ||
        recommendation?.recData?.storage;
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        const alternativeStorageType =
          recommendation.recData.alternatives[optionIndex]?.storage_type ||
          recommendation?.recData?.alternatives[optionIndex]?.instance_storage ||
          recommendation?.recData?.alternatives[optionIndex]?.storage;
        if (alternativeStorageType) {
          storageType = alternativeStorageType;
        }
      }
      return storageType;
    },
  },
  RECOMMENDED_STORAGE: {
    label: 'Recommended Storage Type',
    getterFunction: (recommendation) => recommendation?.recData?.new_storage_type,
  },
  STORAGE_CLASS: {
    label: 'Storage Class',
    isBold: true,
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.storage_class;
      }
      return recommendation.recData?.storage_class;
    },
  },
  MEMORY: {
    label: 'Memory',
    isGBSize: true,
    getterFunction: (recommendation, optionIndex) => {
      let memory = getNumericValue(recommendation?.recData?.memory);
      const alternatives = recommendation?.recData?.alternatives;
      if (optionIndex >= 0 && alternatives && alternatives[optionIndex]?.memory) {
        memory = getNumericValue(alternatives[optionIndex]?.memory);
      }
      return memory;
    },
  },
  VCPU: {
    label: 'vCPU',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.vcpu;
      }
      return getNumericValue(recommendation?.recData?.vcpu);
    },
  },
  PHYSICAL_PROCESSOR: {
    label: 'Physical Processor',
    getterFunction: (recommendation, optionIndex) => {
      let physicalProcessor = recommendation?.recData?.physical_processor;
      const alternatives = recommendation?.recData?.alternatives;
      if (optionIndex >= 0 && alternatives && alternatives[optionIndex]?.physical_processor) {
        physicalProcessor = recommendation.recData.alternatives[optionIndex]?.physical_processor;
      }
      return physicalProcessor;
    },
  },
  RI_COVERAGE: {
    label: 'RI Compatible',
    info: `It is possible to purchase RIs for this configuration,
     please validate current RIs before choosing this option.`,
    getterFunction: (recommendation, optionIndex) => {
      let riCoverage = getBooleanValue(recommendation?.recData?.ri_coverage);
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        const alternativeRiCoverage = getBooleanValue(recommendation.recData.alternatives[optionIndex]?.ri_coverage);
        if (alternativeRiCoverage) {
          riCoverage = alternativeRiCoverage;
        }
      }
      return riCoverage;
    },
  },
};

export const RESERVED_INSTANCE_PROPERTIES = {
  RI_TYPE: {
    label: 'Current RI Type',
    getterFunction: () => 'On-Demand',
  },
  RECOMMENDED_RI_TYPE: {
    label: 'Recommended RI Plan',
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        return recommendation.recData.alternatives[optionIndex]?.payment_plan;
      }
      return recommendation?.recData?.recommended_plan;
    },
  },
  EXPECTED_ROI_PERIOD: {
    label: 'Expected ROI Period',
    getterFunction: (recommendation, optionIndex, costTypeMode) => {
      const currentCost = recommendation?.annualCurrentCost?.[costTypeMode];
      let recommendedCost =
        (recommendation?.annualCurrentCost?.[costTypeMode] ?? 0) - (recommendation?.annualSavings?.[costTypeMode] ?? 0);
      if (optionIndex >= 0 && recommendation?.recData?.alternatives) {
        recommendedCost = recommendation.recData.alternatives[optionIndex]?.annual_cost;
      }
      return currentCost && recommendedCost ? `${calcNumOfMonthsToBreakEven(recommendedCost, currentCost)} Months` : '';
    },
  },
};

export const AZURE_PROPERTIES = {
  RESOURCE_GROUP: {
    label: 'Resource Group',
    getterFunction: (recommendation) => recommendation?.recData?.resource_group,
  },
  SKU_NAME: {
    label: 'Instance Type (SKU)',
    getterFunction: (recommendation) => recommendation?.recData?.sku_name,
  },
};

export const AZURE_RESERVED_INSTANCE_PROPERTIES = {
  RECOMMENDED_PLAN: {
    label: 'Recommended Plan',
    getterFunction: (recommendation) => recommendation?.recData?.recommended_plan,
  },
  SERVICE: {
    label: 'Service',
    getterFunction: (recommendation) => recommendation?.service,
  },
};

export const DISK_PROPERTIES = {
  DISK_NAME: {
    label: 'Disk Name',
    getterFunction: (recommendation) => recommendation?.recData?.disk_name,
  },
  DISK_TYPE: {
    label: 'Disk Type',
    getterFunction: (recommendation) => recommendation?.recData?.disk_type,
  },
  DISK_SIZE: {
    label: 'Disk Size',
    isGBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.disk_size || recommendation?.recData?.disk_size_gb,
  },
};

export const VOLUMES_PROPERTIES = {
  ATTACHED_VOLUMES: {
    label: 'Attached Volumes',
    getterFunction: (recommendation) => recommendation?.recData?.attached_volumes,
  },
  ATTACHED_VOLUMES_COST: {
    label: 'Attached Volumes Cost',
    getterFunction: (recommendation) =>
      Object.entries(
        typeof recommendation.recData.attached_volumes_costs === 'string'
          ? JSON.parse(recommendation.recData.attached_volumes_costs)
          : recommendation.recData.attached_volumes_costs,
      ).map(([id, cost]) => ({ id, cost })),
    isTable: true,
    columns: [
      {
        id: 'id',
        label: 'Volume ID',
      },
      {
        id: 'cost',
        label: 'Cost',
        isCurrency: true,
      },
    ],
  },
};

export const LOAD_BALANCER_PROPERTIES = {
  LOAD_BALANCER_NAME: {
    label: 'Load Balancer Name',
    getterFunction: (recommendation) => recommendation?.recData?.load_balancer_name,
  },
  LOAD_BALANCER_TYPE: {
    label: 'Load Balancer Type',
    getterFunction: (recommendation) => recommendation?.recData?.load_balancer_type,
  },
};

export const OPTIONS_PROPERTIES = {
  POTENTIAL_SAVINGS: {
    label: 'Potential Savings',
    isNoColorInOptions: true,
    isCurrency: true,
    getterFunction: (recommendation, optionIndex, costTypeMode) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives?.length) {
        const option = recommendation.recData.alternatives[optionIndex];
        const savings = option?.[`potential_savings_${snakeCase(costTypeMode)}`] || option?.potential_savings;
        const optionSavingAmount =
          option?.[`saving_amount_${snakeCase(costTypeMode)}`] || option?.saving_amount || option?.savings_amount || 0;
        return `${optionSavingAmount.toLocaleString()} (${savings}%)`;
      }
      return undefined;
    },
  },
};
export const EXTENDED_SUPPORT_PROPERTIES = {
  VERSION: {
    label: 'Version',
    isBold: true,
    getterFunction: (recommendation) => recommendation?.recData?.version || recommendation?.recData?.current_version,
  },
  RECOMMENDED_VERSION: {
    label: 'Recommended Version',
    isBold: true,
    getterFunction: (recommendation, optionIndex) => {
      if (optionIndex >= 0 && recommendation?.recData?.alternatives?.length) {
        return recommendation.recData.alternatives[optionIndex]?.recommended_version;
      }
      return recommendation.recData?.recommended_version;
    },
  },
  START_OF_EXTENDED_SUPPORT_DATE: {
    label: 'Current version start support date',
    labelForOption: 'Start of Extended Support Date',
    isBold: true,
    getterFunction: (recommendation) => recommendation.recData?.start_support_date,
  },
  END_OF_EXTENDED_SUPPORT_DATE: {
    label: 'Current version end support date',
    labelForOption: 'End of Extended Support Date',
    isBold: true,
    getterFunction: (recommendation) => recommendation.recData?.end_support_date,
  },
  RECOMMENDED_END_OF_EXTENDED_SUPPORT_DATE: {
    label: 'Recommended version end support date',
    labelForOption: 'End Support Date',
    isBold: true,
    getterFunction: (recommendation, optionIndex) => {
      const alternatives = recommendation?.recData?.alternatives;
      if (optionIndex >= 0 && alternatives && alternatives[optionIndex]?.end_support_date) {
        return alternatives[optionIndex]?.end_support_date;
      }
      return recommendation.recData?.end_support_date;
    },
  },
  EXTENDED_SUPPORT_TYPE: {
    label: 'Extended Support Type',
    getterFunction: (recommendation) => recommendation.recData?.support_type,
  },
  RECOMMENDED_SUPPORT_TYPE: {
    label: 'Recommended Support Type',
    getterFunction: (recommendation, optionIndex) => {
      const alternatives = recommendation?.recData?.alternatives;
      if (optionIndex >= 0 && alternatives && alternatives[optionIndex]?.support_type) {
        return alternatives[optionIndex]?.support_type;
      }
      return recommendation.recData?.support_type;
    },
  },
  DB_CONFIG: {
    label: 'DB Config',
    getterFunction: (recommendation, optionIndex) => {
      const alternatives = recommendation?.recData?.alternatives;
      if (optionIndex >= 0 && alternatives?.length && alternatives[optionIndex]?.db_config) {
        return alternatives[optionIndex]?.db_config;
      }
      return recommendation.recData?.db_config;
    },
  },
};

export const K8S_PROPERTIES = {
  CLUSTER: {
    label: 'Cluster',
    getterFunction: (recommendation) => recommendation?.recData?.cluster,
  },
  NAME_SPACE: {
    label: 'Namespace',
    getterFunction: (recommendation) => recommendation?.recData?.namespace,
  },
  WORKLOAD_TYPE: {
    label: 'Workload Type',
    getterFunction: (recommendation) => recommendation?.recData?.workload_kind,
  },
  WORKLOAD: {
    label: 'Workload',
    getterFunction: (recommendation) => recommendation?.recData?.workload,
  },
  AGE: {
    label: 'Age',
    getterFunction: (recommendation) => recommendation?.age,
  },
  APPLICABLE_RESOURCE: {
    label: 'Recommended Compute Resources',
    getterFunction: (recommendation) => recommendation?.recData?.compute_resources_recommended,
  },
  CPU_LIMITS: {
    label: 'CPU Limits ',
    isMilliCores: true,
    getterFunction: (recommendation) => recommendation?.recData?.cpu_current_limit || 'N/A',
  },
  CPU_REQUEST: {
    label: 'CPU Requests',
    displayNA: 'Optimal Allocation',
    isMilliCores: true,
    isRed: (recommendation) => recommendation?.recData?.compute_resources_recommended?.includes('CPU'),
    getterFunction: (recommendation) => recommendation?.recData?.cpu_current_request,
  },
  RECOMMENDED_CPU_REQUEST: {
    label: 'Recommended CPU Requests',
    isBold: true,
    isMilliCores: true,
    displayNA: 'Optimal Allocation',
    getterFunction: (recommendation) => {
      const alternatives = recommendation?.recData?.alternatives;
      const alternative = alternatives ? alternatives[0] : {};
      return alternative.cpu_recommended_request
        ? getNumericValue(alternative.cpu_recommended_request).toFixed(2)
        : null;
    },
  },
  CPU_AVG_UTIL: {
    label: 'CPU Avg Utilization',
    isPercent: true,
    displayNA: true,
    info: `The ratio between the actual average usage of the resource and the resource quantity requested
     (above 100% when the usage is above the request value specified).`,
    getterFunction: (recommendation) =>
      recommendation?.recData?.cpu_avg_utilization
        ? getNumericValue(recommendation?.recData?.cpu_avg_utilization)?.toFixed(2)
        : null,
  },
  CPU_MAX_UTIL: {
    label: 'CPU Max Utilization',
    isPercent: true,
    displayNA: true,
    info: `The ratio between the actual max usage of the resource and the resource quantity requested
     (above 100% when the usage is above the request value specified).`,
    getterFunction: (recommendation) =>
      recommendation?.recData?.cpu_max_utilization
        ? getNumericValue(recommendation?.recData?.cpu_max_utilization)?.toFixed(2)
        : null,
  },
  CPU_95_UTIL: {
    label: 'CPU 95PCT Utilization',
    isPercent: true,
    info: `The ratio between the actual 95pct usage of the resource and the resource
     quantity requested (above 100% when the usage is above the request value specified).`,
    getterFunction: (recommendation) =>
      recommendation?.recData?.cpu_p95_utilization
        ? getNumericValue(recommendation?.recData?.cpu_p95_utilization)?.toFixed(2)
        : null,
  },
  CPU_AVG_USAGE: {
    label: 'CPU Average usage',
    info: `The average value of CPU (milliCores) quantity used by a single pod
     during its uptime (within the effective time frame)`,
    getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.cpu_avg).toFixed(2),
  },
  CPU_MAX_USAGE: {
    label: 'CPU Maximum usage',
    info: 'The maximum value of CPU (milliCores) quantity used by any pod (within the effective time frame)',
    getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.cpu_max).toFixed(2),
  },
  CPU_95_USAGE: {
    label: 'CPU 95th percentile usage',
    info: `The maximum value of the 95th percentile of hourly usage of CPU (milliCores)
     quantity used by any pod (within the effective time frame).`,
    getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.cpu_p95).toFixed(2),
  },
  MEMORY_LIMITS: {
    label: 'Memory Limits',
    isBSize: true,
    displayNA: (recommendation) => !!recommendation?.recData?.mem_current_limit,
    getterFunction: (recommendation) => recommendation?.recData?.mem_current_limit || 0,
  },
  MEMORY_REQUESTS: {
    label: 'Memory Requests',
    isBSize: true,
    isRed: (recommendation) => recommendation?.recData?.compute_resources_recommended?.includes('Memory'),
    displayNA: true,
    getterFunction: (recommendation) => recommendation?.recData?.mem_current_request || 0,
  },
  RECOMMENDED_MEMORY_REQUEST: {
    label: 'Recommended Memory Requests',
    isBold: true,
    isBSize: true,
    displayNA: 'Optimal Allocation',
    getterFunction: (recommendation) => {
      const alternatives = recommendation?.recData?.alternatives;
      const alternative = alternatives ? alternatives[0] : {};
      return alternative.mem_recommended_request ? getNumericValue(alternative.mem_recommended_request) : null;
    },
  },
  MEM_AVG_UTIL: {
    label: 'Memory Avg Utilization',
    isPercent: true,
    displayNA: true,
    info: `The ratio between the actual average usage of the resource and the resource quantity
     requested (above 100% when the usage is above the request value specified).`,
    getterFunction: (recommendation) =>
      recommendation?.recData?.mem_avg_utilization
        ? getNumericValue(recommendation?.recData?.mem_avg_utilization)?.toFixed(2)
        : null,
  },
  MEM_MAX_UTIL: {
    label: 'Memory Max Utilization',
    isPercent: true,
    displayNA: true,
    info: `The ratio between the actual max usage of the resource and the resource quantity
     requested (above 100% when the usage is above the request value specified).`,
    getterFunction: (recommendation) =>
      recommendation?.recData?.mem_max_utilization
        ? getNumericValue(recommendation?.recData?.mem_max_utilization)?.toFixed(2)
        : null,
  },
  MEM_95_UTIL: {
    label: 'Memory 95PCT Utilization',
    isPercent: true,
    info: `The ratio between the actual 95pct usage of the resource and the resource
     quantity requested (above 100% when the usage is above the request value specified).`,
    getterFunction: (recommendation) =>
      recommendation?.recData?.mem_p95_utilization
        ? getNumericValue(recommendation?.recData?.mem_p95_utilization)?.toFixed(2)
        : null,
  },
  MEM_AVG_USAGE: {
    label: 'Memory Average usage',
    isBSize: true,
    info: `The average value of memory  quantity used by a single pod
     during its uptime (within the effective time frame)`,
    getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.mem_avg)?.toFixed(2),
  },
  MEM_MAX_USAGE: {
    label: 'Memory Maximum usage',
    isBSize: true,
    info: 'The maximum value of memory  quantity used by any pod (within the effective time frame)',
    getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.max_memory)?.toFixed(2),
  },
  MEM_95_USAGE: {
    label: 'Memory 95th percentile usage',
    isBSize: true,
    info: `The maximum value of the 95th percentile of hourly usage of memory 
     quantity used by any pod (within the effective time frame).`,
    getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.mem_p95)?.toFixed(2),
  },
  EFFECTIVE_TIME_FRAME: {
    label: 'Effective Time Frame',
    info: `Number of consecutive days the workload was consistently defined with the same
     request values (within the range of days to check)`,
    getterFunction: (recommendation) => recommendation?.recData?.effective_time_frame,
  },
  DAILY_RUNNING_HOURS: {
    label: 'Daily running hours',
    info: 'Average daily running hours of a single pod (maximum: 24).',
    getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.avg_running_hours)?.toFixed(2),
  },
  DAILY_ACCUMULATED_HOURS: {
    label: 'Daily accumulated running hours',
    info: `Average daily running hours accumulated from all the workload's pods.`,
    getterFunction: (recommendation) =>
      getNumericValue(recommendation?.recData?.avg_aggregated_running_hours)?.toFixed(2),
  },
  NUM_OF_CONTAINERS: {
    label: 'Number Of Containers',
    info: 'Number of containers per pod.',
    getterFunction: (recommendation) => recommendation?.recData?.num_of_containers,
  },
  NUM_OF_PODS: {
    label: 'Number Of Pods',
    info: 'Average number of different pod instances running during a day (not necessarily simultaneously).',
    getterFunction: (recommendation) => recommendation?.recData?.num_of_pods,
  },
};

export const OUTDATED_SNAPSHOT_PROPERTIES = {
  SIZE: {
    label: 'Size',
    isGBSize: true,
    getterFunction: (recommendation) => recommendation?.recData?.snapshot_size,
  },
  SNAPSHOT_ID: {
    label: 'Snapshot ID',
    getterFunction: (recommendation) => recommendation?.resourceId,
  },
  VOLUME_ID: {
    label: 'Volume ID',
    getterFunction: (recommendation) => recommendation?.recData?.volume_id,
  },
  TIME_FROM_CREATION: {
    label: 'Time from creation',
    getterFunction: (recommendation) => recommendation?.recData?.elapsed_time_since_creation,
  },
  COST_FROM_CREATION: {
    label: 'Cost from creation',
    isCurrency: true,
    getterFunction: (recommendation) => recommendation?.recData?.cost_since_creation,
  },
};

export const IOPS_PROPERTIES = {
  MAX_IOPS: {
    label: 'Max IOPS',
    getterFunction: (recommendation) => recommendation.recData?.max_iops,
  },
  PROVISIONED_IOPS: {
    label: 'Provisioned IOPS',
    getterFunction: (recommendation) => recommendation.recData?.provisioned_iops,
  },
  RECOMMENDED_IOPS: {
    label: 'Recommended IOPS',
    getterFunction: (recommendation) => recommendation.recData?.recommended_iops,
  },
  IOPS_P95: {
    label: 'IOPS P95',
    getterFunction: (recommendation) => recommendation.recData?.iops_p95,
  },
  IOPS_P99: {
    label: 'IOPS P99',
    getterFunction: (recommendation) => recommendation.recData?.iops_p99,
  },
};
