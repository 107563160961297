import React from 'react';
import styles from '../K8sOnboarding.module.scss';
import { ReactComponent as MailSecretSVG } from 'shared/img/images/secret-mail.svg';
import { ReactComponent as KeySuccessSVG } from 'shared/img/images/secret-success.svg';
import { ReactComponent as KeySVG } from 'shared/img/images/secret.svg';
import { ReactComponent as KeyErrorSVG } from 'shared/img/images/secret-error.svg';
import Button from 'shared/components/andtComponents/Button.tsx';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useBrand } from 'app/contexts/Brand/BrandContext.tsx';

export const NewKeyCreated: React.FC<{ link: string }> = ({ link }) => (
  <div className={styles.description}>
    <KeySVG />
    <div>
      <p>
        Access your required Access Keys via this link:{' '}
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      </p>
      <p className={styles.warning}>
        <span className={styles.notice}>Note:</span> This link is one-time use only. Save the keys securely for future
        clusters.
      </p>
      <p>
        These keys are essential for uploading metrics to Anodot.
        <br />
        <b>Add the Access Keys to each YAML file under the &quot;EnvironmentSecrets&quot; section.</b>
      </p>
    </div>
  </div>
);
const KeyIconComponent = () => <GenerateIcon iconName={ICONS.key.name} />;
export const KeyWasProvided: React.FC<{ onGenerateNew(): void }> = ({ onGenerateNew }) => (
  <div className={styles.description}>
    <KeySVG />
    <div>
      <p>
        We’ve already provided you with Access Keys. Please use your existing keys and add
        <br />
        them to each YAML file under the &quot;<b>EnvironmentSecrets</b>&quot; section.
      </p>
      <p className={classNames(styles.warning, 'mb-2')}>
        <span className={styles.notice}>Note:</span> You can find your existing Access Keys in the YAML files of your
        connected clusters
      </p>
      <Button icon={KeyIconComponent} onClick={onGenerateNew} text={'Generate New Access Keys'} isTextButton />
    </div>
  </div>
);

export const KeySuccess: React.FC<{ link: string }> = ({ link }) => (
  <div className={styles.description}>
    <KeySuccessSVG />
    <div>
      <p>
        Your new Access Keys have been successfully generated. Access them via this link:{' '}
        <a href={link} target="_blank" rel="noopener noreferrer">
          {link}
        </a>
      </p>
      <p className={styles.warning}>
        <span className={styles.notice}>Note:</span> Your old keys are no longer valid. Please save the new keys for
        future use and update them in the YAML files of your connected clusters.
      </p>
    </div>
  </div>
);

export const NoKey: React.FC = () => {
  const { supportEmail } = useBrand();
  return (
    <div className={styles.description}>
      <MailSecretSVG />
      <div>
        <h5>
          We are unable to generate the Access Keys. Please contact our support team at{' '}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a> to request them.
        </h5>
        <p>
          Access Keys are required to upload metrics to Anodot. Once you receive the Access Keys, add them to the
          `&quot;environmentSecrets`&quot; section in each YAML file.
        </p>
      </div>
    </div>
  );
};

export const KeyError: React.FC = () => {
  const { supportEmail } = useBrand();
  return (
    <div className={styles.description}>
      <KeyErrorSVG />
      <div>
        We are unable to generate your Access Keys. Please reach out
        <br /> to our support team at <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </div>
    </div>
  );
};
