import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import Spinner from 'shared/components/andtComponents/Spinner';
import { palette } from 'shared/constants/colorsConstants';
import { useSpAnalyzerContext } from 'commitment/containers/spAnalyzerNew/contexts/spAnalyzerContext';
import styles from './hourlyCommitment.module.scss';
import useSpAnalyzer from '../hooks/useSpAnalyzer';
 const HourlyCommitment = ({ planKey, term, paymentOption }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hourlyValue, setHourlyValue] = useState('');
  const [modalPosition, setModalPosition] = useState({});
  const [hourlyCommitment, setHourlyCommitment] = useState(null);
  const {
    selectedOptions,
    filterStatusType,
    daysToCheck,
    coverage,
    excludeExpiringSPsDays,
    planDataByKey,
    setPlanDataByKey,
    modifiedPlanKeyHourlyCommitment,
    setModifiedPlanKeyHourlyCommitment,
  } = useSpAnalyzerContext();
  const { fetchSingleOptionPlanAnalyzerData } = useSpAnalyzer();
  const { data, isLoading } = fetchSingleOptionPlanAnalyzerData(
      {
          selectedOptions,
          daysToCheck,
          filterStatusType,
          selectedPaymentOption: paymentOption,
          selectedTerm: term,
          coverage,
          hourlyCommitment,
          excludeExpiringSPsDays,
          enabled: !!hourlyCommitment ,
      }
  );

  useEffect(() => {
    if (data?.recommendationPerPlan?.[planKey]) {
      setPlanDataByKey({ ...planDataByKey, [planKey]: data.recommendationPerPlan[planKey] });
    }
  }, [data]);
  const onEditHourly = (event) => {
    setModalPosition({ top: event.clientY - 10, left: event.clientX + 5 });
    setIsOpen(!isOpen);
  };

  const calculateSavingWithHourlyCommitment = () => {
    setIsOpen(false);
    setModifiedPlanKeyHourlyCommitment({ ...modifiedPlanKeyHourlyCommitment, [planKey]: hourlyValue });
    setHourlyCommitment(hourlyValue);
  };

  const resetHourlyCommitment = () => {
    setModifiedPlanKeyHourlyCommitment({ ...modifiedPlanKeyHourlyCommitment, [planKey]: null });
    setHourlyCommitment(null);
    setPlanDataByKey({ ...planDataByKey, [planKey]: null });
  };
  return (
    <>
      {modifiedPlanKeyHourlyCommitment?.[planKey] ? (
        <div className={styles.iconWrapper}>
          {isLoading ? (
            <div className={styles.spinnerWrapper}>
              <Spinner color={palette.blue[500]} size={20} />
            </div>
          ) : (
            <div className={styles.reset} onClick={resetHourlyCommitment}>
              <GenerateIcon
                iconName={ICONS.arrowRotateLeft.name}
                className={styles.blueIcon}
                automation-id={`${planKey}Reset`}
              />
              <span className={styles.resetText}>Reset</span>
            </div>
          )}
        </div>
      ) : (
          <GenerateIcon
              iconName={ICONS.pencil.name}
              className={styles.blueIcon}
              onClick={onEditHourly}
              automation-id={`${planKey}EditHourly`}
          />
      )}
      <CustomModal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onSave={() => calculateSavingWithHourlyCommitment()}
        overrideStyles={{ top: modalPosition.top, left: modalPosition.left }}
        className={{
          container: styles.container,
          modal: styles.modal,
          title: styles.modalTitle,
          modalHeader: styles.modalHeader,
        }}
        saveDisabled={!hourlyValue || !/^[0-9]*\.?[0-9]+$/.test(hourlyValue)}
        saveTitle="Apply"
        headerMode="edit"
        title="Edit Hourly Commitment"
      >
        <div>
          <Input
            type="number"
            automationid={`${planKey}HourlyCommitmentInput`}
            value={hourlyValue}
            onChange={(e) => {
              setHourlyValue(e.target.value);
            }}
          />
        </div>
      </CustomModal>
    </>
  );
};

HourlyCommitment.propTypes = {
  planKey: PropTypes.string.isRequired,
  term: PropTypes.string.isRequired,
  paymentOption: PropTypes.string.isRequired,
};

export default HourlyCommitment;
