import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import { usePermissions } from 'app/contexts/PermissionsContext.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import { useAnomalyDetection } from 'usage/containers/AnomalyDetection/hooks/react-query/useAnomalyDetection';
import mainStyles from 'app/containers/MainDashboard/mainDashboardCommon.module.scss';
import { ReactComponent as EmptyAnomalies } from 'app/containers/MainDashboard/img/empty-anomalies.svg';
import { Routes as ROUTES } from 'shared/constants/routes.js';
import MonitoringPanelAction from 'app/containers/MainDashboard/panels/MonitoringData/MonitoringPanelAction.jsx';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import { NoPermission } from 'app/containers/MainDashboard/components/NoPermission.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import AreasChart from 'shared/components/dashboardCharts/AreasChart/AreasChart.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { palette } from 'shared/constants/colorsConstants.js';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import SaveDashboardPanel from 'app/containers/MainDashboard/components/SaveDashboardPanel.jsx';
import { PANEL_COMPONENT_TYPES } from 'usage/containers/CustomDashboard/constants/customDashboardConstants.js';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import anomaliesStyles from './anomaliesData.module.scss';
import styles from './monitoringData.module.scss';
const CustomizedAxisTick = ({ x, y, payload }) => {
  const date = moment(payload.value);
  const day = date.date();

  return payload.index % 5 === 0 ? (
    <>
      <text x={x + 5} y={y + 5} textAnchor="middle" fill={palette.gray[400]} fontSize={11} fontWeight={300}>
        {date.format('MMM')}
      </text>
      <text x={x} y={y + 20} textAnchor="middle" fill={palette.gray[400]} fontSize={11} fontWeight={300}>
        {day}
      </text>
    </>
  ) : null;
};

CustomizedAxisTick.propTypes = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    isLastDay: PropTypes.bool,
  }).isRequired,
};

const AreasGradient = (
  <defs>
    <linearGradient id="colorChart" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor={palette.blue[450]} stopOpacity={0.38} />
      <stop offset="100%" stopColor={palette.blue[450]} stopOpacity={0} />
    </linearGradient>
  </defs>
);

const dataProperties = {
  openAnomalies: { name: '', stroke: palette.blue[500], fill: 'url(#colorChart)' },
};

const CustomTooltip = ({ payload }) => {
  if (!payload?.length) {
    return null;
  }
  const dataPoint = payload[0]?.payload;
  const dateFormat = moment(dataPoint.day).format('MMM D, YYYY');
  return (
    <div className={anomaliesStyles.customizedTooltip}>
      <div className={anomaliesStyles.tooltipTitleContainer}>
        <GenerateIcon iconName={ICONS.calendarDay.name} />
        <div className={anomaliesStyles.tooltipTitle}>{dateFormat}</div>
      </div>
      <div className={anomaliesStyles.tooltipDataContainer}>
        <div className={anomaliesStyles.valueWrapper}>
          <span className={anomaliesStyles.value}>{+payload[0]?.value} </span>
          <span className={anomaliesStyles.key}>Anomalies</span>
        </div>
      </div>
    </div>
  );
};
CustomTooltip.propTypes = {
  payload: PropTypes.array,
};
const AnomaliesData = ({ hideSaveDashboard = false }) => {
  const { appStore } = useRootStore();
  const { updatePanelLoadingState } = useMainDashboardContext();
  const { getCurrencyNumber } = useUserSettingsContext();
  const { isHasPermission } = usePermissions();
  const hasPermission = isHasPermission(HierarchicalEntityCategory.DataAccess, Action.View);
  const anomaliesHook = useAnomalyDetection(hasPermission);
  const { data: anomaliesData, isLoading } = anomaliesHook.fetchAnomaliesData({ isPpApplied: appStore.isPpApplied });

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.ANOMALIES_STATS, isLoading);
  }, [isLoading]);

  const chartData = useMemo(() => {
    if (!anomaliesData?.historyData?.length) {
      return [];
    }
    return anomaliesData?.historyData.map((anomaly, index) => ({
      openAnomalies: anomaly.openAnomalies,
      index: index,
      isLastDay: index === anomaliesData.historyData.length - 1,
      day: anomaly.date,
    }));
  }, [anomaliesData]);

  const renderContent = () => {
    if (!hasPermission) {
      return <NoPermission />;
    }
    if (!anomaliesData?.openAnomalies) {
      return (
        <div className={styles.emptyStateWrapper}>
          <EmptyAnomalies className="anomalies" />
          <span className={styles.simpleText}>You have no open Anomalies</span>
        </div>
      );
    }
    return (
      <>
        <div className={styles.field}>
          {anomaliesData.openAnomalies} <span className={styles.simpleText}>Open Anomalies</span>
        </div>
        <div className={styles.redField}>
          {getCurrencyNumber(anomaliesData.impact)}
          <span className={styles.simpleText}>Open Anomalies cost impact</span>
        </div>
        <div className={anomaliesStyles.chartWrapper}>
          <AreasChart
            data={chartData}
            height={120}
            dataKeys={['openAnomalies']}
            specialGradient={AreasGradient}
            dataProperties={dataProperties}
            hideLegend={true}
            tooltipComponent={<CustomTooltip />}
            axisSettings={{
              xCustomTick: CustomizedAxisTick,
              yTickFill: palette.gray[400],
            }}
          />
          <div className={anomaliesStyles.infoWrapper}>
            <InfoPopover
              isSimple
              mode="outline"
              className={{
                icon: anomaliesStyles.infoIcon,
                tooltip: tooltipStyles.whiteTooltip,
                arrow: tooltipStyles.whiteArrow,
              }}
            >
              <span className={tooltipStyles.tooltipText}>Total of open anomalies per day</span>
            </InfoPopover>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={classNames(mainStyles.panelWrapper, styles.anomalyWrapper)}>
      {isLoading ? (
        <Spinner size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <div>
          <div className={styles.headerFlex}>
            <div className={styles.header}>Anomalies</div>
            <div className={styles.flexCenter}>
              {!hideSaveDashboard && (
                <>
                  <MonitoringPanelAction url={ROUTES.ANOMALY_DETECTION} />
                  <SaveDashboardPanel panelType={PANEL_COMPONENT_TYPES.ANOMALY_DATA} />
                </>
              )}
            </div>
          </div>
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default AnomaliesData;
