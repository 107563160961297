import React from 'react';
import PropTypes from 'prop-types';
import comingSoonImg from '../img/coming-soon.gif';
import { ReactComponent as EmptyStateImage } from '../img/sp-empty-state.svg';
import styles from './spSpecialMode.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const SpSpecialMode = ({ isLoading, isEmpty, isComingSoon, isMsp, specialText, isLongText }) => {
  const brand = useBrand();
  return (
    <div className={styles.bigIconWrapper}>
      {isLoading && <brand.spAnalyzerLoader className={styles.image} alt="loader" />}
      {isEmpty && <EmptyStateImage className={styles.image} />}
      {isMsp && <brand.spMspState></brand.spMspState>}
      {isComingSoon && <img className={styles.image} src={comingSoonImg} alt="comingSoon" />}
      <span className={`${styles.specialText} ${isLongText && styles.longText}`}>{specialText}</span>
    </div>
  );
};

SpSpecialMode.propTypes = {
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isComingSoon: PropTypes.bool,
  isMsp: PropTypes.bool,
  specialText: PropTypes.object,
  isLongText: PropTypes.bool,
};
export default SpSpecialMode;
