import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { palette } from 'shared/constants/colorsConstants.js';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';

const CustomXTick = ({ x, y, payload, overrideWidth, isDateChart }) => {
  const tickValue = isDateChart ? moment(payload.value).format('MMM') : payload.value;
  const width = tickValue.length * 6;
  const maxLength = overrideWidth ? overrideWidth / 7 : 0;
  const truncatedText = tickValue.length > maxLength ? `${tickValue.substring(0, maxLength)}...` : tickValue;
  return (
    <Tooltip
      title={tickValue.length > maxLength ? tickValue : null}
      arrow
      classes={{
        tooltip: tooltipStyles.whiteTooltip,
        arrow: tooltipStyles.whiteArrow,
        content: tooltipStyles.tooltipText,
      }}
    >
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={7}
          dy={12}
          textAnchor="middle"
          fill={palette.gray[400]}
          width={overrideWidth || width}
          style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
        >
          {truncatedText}
        </text>
      </g>
    </Tooltip>
  );
};
CustomXTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.object,
};
export default CustomXTick;
