import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import getSymbolFromCurrency from 'currency-symbol-map';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import { getVendorMappingsStore } from 'app/store/vendorsStore/vendorStoreProvider';

const ColumnsNames = {
  Onboarding: 'onboarding',
  CloudTypeId: 'cloudTypeId',
  AccountName: 'accountName',
  AccountId: 'accountId',
  LastProcessTime: 'lastProcessTime',
  AccountLastProcessTime: 'accountProcessTime',
  DbCreationDate: 'dbCreationTime',
  Details: 'details',
  CurrencyCode: 'currencyCode',
  IsDefault: 'isDefault',
  Edit: 'edit',
  Delete: 'delete',
};

const Properties = {
  Key: 'name',
  Title: 'title',
  Direction: 'direction',
  Width: 'width',
  GetCellValue: 'getCellValue',
  SortingEnabled: 'sortingEnabled',
  ColumnName: 'columnName',
  Compare: 'compare',
};

const dateCompare = (a, b) => {
  if (!a) return -1;
  if (!b) return 1;

  const dateA = new Date(a);
  const dateB = new Date(b);
  return dateA - dateB;
};

const fullColumns = [
  {
    [Properties.Key]: ColumnsNames.Onboarding,
    [Properties.ColumnName]: ColumnsNames.Onboarding,
    [Properties.Title]: ColumnsNames.Onboarding,
    [Properties.Direction]: 'desc',
    [Properties.SortingEnabled]: true,
  },
  {
    [Properties.Key]: ColumnsNames.CloudTypeId,
    [Properties.ColumnName]: ColumnsNames.CloudTypeId,
    [Properties.Title]: 'Cloud Type',
    [Properties.Width]: 120,
    [Properties.Direction]: 'asc',
    [Properties.SortingEnabled]: true,
    [Properties.GetCellValue]: (row) => getVendorMappingsStore().getDescriptionById(row.cloudTypeId),
  },
  {
    [Properties.Key]: ColumnsNames.AccountName,
    [Properties.ColumnName]: ColumnsNames.AccountName,
    [Properties.Title]: 'Account Name',
    [Properties.Width]: 200,
    [Properties.SortingEnabled]: true,
  },
  {
    [Properties.Key]: ColumnsNames.AccountId,
    [Properties.ColumnName]: ColumnsNames.AccountId,
    [Properties.Title]: 'Account ID',
    [Properties.Direction]: 'asc',
    [Properties.Width]: 200,
    [Properties.SortingEnabled]: true,
  },
  {
    [Properties.Key]: ColumnsNames.LastProcessTime,
    [Properties.ColumnName]: ColumnsNames.LastProcessTime,
    [Properties.Width]: 150,
    [Properties.SortingEnabled]: true,
    [Properties.Compare]: dateCompare,
    [Properties.Title]: 'Last Date in File', // The DB column name is misleading, so we're fixing it here. See https://anodot.atlassian.net/browse/PIL-10984
  },
  {
    [Properties.Key]: ColumnsNames.AccountLastProcessTime,
    [Properties.ColumnName]: ColumnsNames.AccountLastProcessTime,
    [Properties.Width]: 150,
    [Properties.SortingEnabled]: true,
    [Properties.Compare]: dateCompare,
    [Properties.Title]: 'Last Processed Time', // The DB column name is misleading, so we're fixing it here. See https://anodot.atlassian.net/browse/PIL-10984
    [Properties.GetCellValue]: (row) =>
      row.accountProcessTime ? moment(row.accountProcessTime).format('MMMM DD YYYY') : '',
  },
  {
    [Properties.Key]: ColumnsNames.DbCreationDate,
    [Properties.ColumnName]: ColumnsNames.DbCreationDate,
    [Properties.Width]: 150,
    [Properties.Title]: 'Creation Date',
    [Properties.SortingEnabled]: true,
    [Properties.Compare]: dateCompare,
    [Properties.GetCellValue]: (row) => (row.dbCreationTime ? moment(row.dbCreationTime).format('MMMM DD YYYY') : ''),
  },
  {
    [Properties.Key]: ColumnsNames.Details,
    [Properties.ColumnName]: ColumnsNames.Details,
    [Properties.Title]: 'Details',
    [Properties.Width]: 220,
    [Properties.SortingEnabled]: true,
    [Properties.Compare]: (aDetailsAsString, bDetailsAsString) => {
      if (isEmpty(aDetailsAsString) && isEmpty(bDetailsAsString)) {
        return 0;
      }
      if (isEmpty(aDetailsAsString)) {
        return 1;
      }
      if (isEmpty(bDetailsAsString)) {
        return -1;
      }
      if (bDetailsAsString.length === aDetailsAsString.length) {
        return bDetailsAsString - aDetailsAsString;
      }
      return bDetailsAsString.length - aDetailsAsString.length;
    },
    [Properties.GetCellValue]: (row) => {
      return !isEmpty(row.accountInfo)
        ? `${Object.keys(row.accountInfo)
            .map((key) => {
              return `${key}: ${row.accountInfo?.[key]}`;
            })
            .join(';\r')}`
        : '';
    },
  },
  {
    [Properties.Key]: ColumnsNames.CurrencyCode,
    [Properties.ColumnName]: ColumnsNames.CurrencyCode,
    [Properties.Title]: 'Currency',
    [Properties.Width]: 80,
    [Properties.SortingEnabled]: true,
    [Properties.GetCellValue]: (row) => {
      if (row.onboarding) {
        return null;
      }
      return `${row.currencyCode || CURRENCY_DETAILS.USD} (${getSymbolFromCurrency(
        row.currencyCode || CURRENCY_DETAILS.USD,
      )})`;
    },
  },
  {
    [Properties.Key]: ColumnsNames.IsDefault,
    [Properties.ColumnName]: ColumnsNames.IsDefault,
    [Properties.Width]: 80,
    [Properties.SortingEnabled]: true,
    [Properties.Title]: 'Default',
    [Properties.Compare]: (aDefault, bDefault) => {
      if (aDefault && !bDefault) {
        return 1;
      }
      if (!aDefault && bDefault) {
        return -1;
      }
      return 0;
    },
  },
  {
    [Properties.Key]: ColumnsNames.Edit,
    [Properties.ColumnName]: ColumnsNames.Edit,
    [Properties.Width]: 80,
    [Properties.SortingEnabled]: false,
    [Properties.Title]: 'Edit',
  },
  {
    [Properties.Key]: ColumnsNames.Delete,
    [Properties.ColumnName]: ColumnsNames.Delete,
    [Properties.Width]: 80,
    [Properties.SortingEnabled]: false,
    [Properties.Title]: 'Delete',
  },
];

export const CSV_COLUMNS = cloneDeep(fullColumns)
  .filter(
    (column) => ![ColumnsNames.Onboarding, ColumnsNames.Delete, ColumnsNames.Edit].includes(column[Properties.Key]),
  )
  .map((column) => {
    return pick(column, [Properties.Key, Properties.Title, Properties.GetCellValue]);
  });

export const TABLE_COLUMNS = cloneDeep(fullColumns).map((column) => {
  return pick(column, [Properties.Key, Properties.Title, Properties.GetCellValue]);
});

export const TABLE_COLUMN_WIDTHS = cloneDeep(fullColumns).map((column) => {
  return pick(column, [Properties.ColumnName, Properties.Width, Properties.SortingEnabled, Properties.Compare]);
});

export const TABLE_DEFAULT_SORTING = cloneDeep(fullColumns)
  .filter((column) =>
    [ColumnsNames.Onboarding, ColumnsNames.CloudTypeId, ColumnsNames.AccountId].includes(column[Properties.Key]),
  )
  .map((column) => {
    return pick(column, [Properties.ColumnName, Properties.Direction]);
  });
