import React from 'react';
import PropTypes from 'prop-types';
import { Area, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { palette } from 'shared/constants/colorsConstants';
import CustomYAxisTick from 'shared/components/chartComponents/CustomYAxisTick/CustomYAxisTick.jsx';
import { CustomizedLegend } from '../CustomizedLegend/CustomizedLegend.jsx';
import { CustomTooltip } from '../CustomTooltip.jsx';

const AreasChart = ({
  data,
  dataKeys,
  dataProperties,
  specialGradient,
  axisSettings,
  specialLegends,
  tooltipComponent,
  height,
  hideLegend = false,
}) => {
  return (
    <div>
      <ResponsiveContainer width="100%" height={height || 300}>
        <ComposedChart data={data} margin={{ top: 15, right: 24 }}>
          <XAxis
            dataKey="day"
            tick={axisSettings.xCustomTick}
            interval={0}
            domain={['dataMin', 'dataMax + 0.5']}
            axisLine={false}
            tickLine={false}
            label={axisSettings.customXAxisLabel || null}
          />
          <XAxis dataKey="day" interval={0} domain={['dataMin', 'dataMax + 0.5']} />
          <YAxis
            axisLine={false}
            tickLine={false}
            domain={[0, axisSettings.maxValue]}
            tick={(props) =>
              CustomYAxisTick({
                x: props.x,
                y: props.y,
                payload: props.payload,
                fill: axisSettings.yTickFill || palette.gray[450],
                formattedValue: axisSettings.yTickFormatter ? axisSettings.yTickFormatter(props.payload.value) : null,
              })
            }
          />
          {specialGradient}
          <Tooltip content={tooltipComponent || <CustomTooltip />} />
          {!hideLegend && <Legend content={<CustomizedLegend specialLegends={specialLegends} />} />}
          <CartesianGrid stroke={palette.gray[100]} vertical={false} />
          {dataKeys?.map((dataKey) => (
            <Area
              type="monotone"
              key={dataKey}
              dataKey={dataKey}
              name={dataProperties?.[dataKey]?.name}
              dot={false}
              stroke={dataProperties?.[dataKey]?.stroke}
              fill={dataProperties?.[dataKey]?.fill}
              strokeWidth={1.5}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
AreasChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataProperties: PropTypes.object,
  specialGradient: PropTypes.node,
  axisSettings: PropTypes.object,
  tooltipComponent: PropTypes.node,
  dataKeys: PropTypes.array.isRequired,
  specialLegends: PropTypes.array,
  height: PropTypes.number,
  hideLegend: PropTypes.bool,
};

export default AreasChart;
