import React, { useMemo, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { graphColors, palette } from 'shared/constants/colorsConstants.js';
import { numberWithCommas } from 'shared/utils/strUtil.js';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CustomTooltip } from '../CustomTooltip.jsx';
import { CustomizedLegend } from '../CustomizedLegend/CustomizedLegend.jsx';
import CustomXTick from 'shared/components/dashboardCharts/CustomXTick.jsx';
import CustomYAxisTick from 'shared/components/chartComponents/CustomYAxisTick/CustomYAxisTick.jsx';
import CustomLabel from 'shared/components/dashboardCharts/CustomLabel.jsx';
import styles from './barsChart.module.scss';

const getLabelValue = (value, axisSettings) => {
  if (!value) {
    return '';
  }
  return axisSettings.yTickFormatter ? axisSettings.yTickFormatter(value) : numberWithCommas(value, 2);
};
const BarsChart = ({
  data,
  dataKeys,
  dataProperties,
  specialGradient,
  specialLegends,
  axisSettings,
  tooltipComponent,
  showLabel,
  xKey,
  isStack = true,
  height = 350,
  width = null,
  containerWidth,
  barWidth,
  isDateChart = false,
}) => {
  const [currKey, setCurrKey] = useState();

  const calculateChartWidth = useMemo(() => {
    const maxTextLength = data.reduce((max, item) => Math.max(max, item[xKey].length), 0);
    if (!containerWidth || (maxTextLength * 32 < containerWidth && maxTextLength < 20)) {
      return null;
    }
    if (barWidth) {
      return Math.max(containerWidth, barWidth * data.length);
    }
    return Math.max(containerWidth, maxTextLength * 7 * data.length);
  }, [data, containerWidth, xKey]);

  const customMouseOver = (payload) => {
    const dataKey = payload?.tooltipPayload[0]?.dataKey;
    if (dataKey !== currKey) {
      setCurrKey(dataKey);
    }
  };
  const topBars = useMemo(() => {
    return data.reduce((acc, entry) => {
      dataKeys.forEach((key) => {
        if (entry[key] !== undefined) {
          acc[entry[xKey]] = key;
        }
      });
      return acc;
    }, {});
  }, [data, dataKeys]);
  const valueAccessor =
    (dataKey) =>
    ({ payload }) => {
      if (dataKeys.length === 1) {
        return payload[dataKey];
      }
      if (topBars[payload[xKey]] === dataKey) {
        return Object.keys(payload)
          .filter((pointKey) => pointKey !== xKey)
          .reduce((sum, pointKey) => sum + parseFloat(payload[pointKey] || 0), 0);
      }
    };
  return (
    <div className={styles.barWrapper}>
      <div style={{ width: width || calculateChartWidth || '100%' }}>
        <ResponsiveContainer width="100%" height={height || 300}>
          <BarChart
            data={data}
            margin={{
              top: 15,
              right: 30,
              left: 20,
              bottom: -10,
            }}
          >
            {specialGradient}
            <CartesianGrid stroke={palette.gray[100]} vertical={false} />
            <XAxis
              dataKey={xKey}
              tickCount={0}
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={<CustomXTick overrideWidth={50} isDateChart={isDateChart} />}
              tickFormatter={(value) => moment(value).format('MMM')}
            />
            <YAxis
              type="number"
              axisLine={false}
              tickLine={false}
              tick={(props) =>
                CustomYAxisTick({
                  x: props.x,
                  y: props.y,
                  payload: props.payload,
                  fill: palette.gray[450],
                  formattedValue: axisSettings.yTickFormatter ? axisSettings.yTickFormatter(props.payload.value) : null,
                })
              }
            />
            <Tooltip
              itemSorter={() => 1}
              cursor={{ fill: 'transparent' }}
              content={tooltipComponent || <CustomTooltip activeKey={currKey} />}
            />
            <Legend content={<CustomizedLegend dataProperties={dataProperties} specialLegends={specialLegends} />} />
            {dataKeys?.map((dataKey, index) => (
              <Bar
                key={dataKey}
                barSize={28}
                dataKey={dataKey}
                fill={dataProperties?.[dataKey]?.fill || graphColors[index]}
                onMouseOver={customMouseOver}
                onFocus={customMouseOver}
                stackId={dataProperties?.[dataKey]?.stackId || 'a'}
              >
                {data.map((entry) => {
                  if (!isStack || topBars[entry[xKey]] === dataKey) {
                    return <Cell key={`cell-${dataKey}`} radius={[5, 5, 0, 0]} />;
                  }
                  return <Cell key={`cell-${dataKey}`} radius={0} />;
                })}
                {showLabel && (
                  <LabelList
                    valueAccessor={valueAccessor(dataKey)}
                    position="top"
                    fill={palette.gray[400]}
                    style={{ fontSize: '12px', width: '100px' }}
                    formatter={(value) =>
                      axisSettings.yTickFormatter ? axisSettings.yTickFormatter(value) : numberWithCommas(value, 2)
                    }
                    content={(props) =>
                      CustomLabel({
                        x: props.x + 10,
                        y: props.y,
                        value: getLabelValue(props.value, axisSettings),
                        fill: palette.gray[400],
                        fontSize: 12,
                      })
                    }
                  />
                )}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
BarsChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataProperties: PropTypes.object,
  specialGradient: PropTypes.node,
  axisSettings: PropTypes.object,
  tooltipComponent: PropTypes.node,
  dataKeys: PropTypes.array.isRequired,
  specialLegends: PropTypes.array,
  showLabel: PropTypes.bool,
  isStack: PropTypes.bool,
  height: PropTypes.number,
  width: PropTypes.number,
  containerWidth: PropTypes.number,
  xKey: PropTypes.string.isRequired,
};
export default BarsChart;
