import React, { useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import {
  CustomClickableFormatter,
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomHeaderSelectionCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { FILTER_NUMBER_OR_DATE_OPERATIONS } from 'shared/components/andtComponents/TableComponents/const';
import {
  GET_USERS_QUERY_PARAMS,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
  ROLE_SUB_ROLES_COLUMNS,
} from '../../consts';
import RemoveButton from '../../components/RemoveButton';
import RolesNoData from 'users/containers/Organization/components/EmptyStates/RolesNoData.jsx';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

const RoleSubRolesTabTable = ({
  subRoles,
  selectedSubRoles,
  setSelectedSubRoles,
  removeSubRoleClicked,
  isViewOnly,
}) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ROLE_SUB_ROLES_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const onSubRoleClick = (row) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.set(GET_USERS_QUERY_PARAMS.SEARCH.name, row?.displayName);
    window.open(currentUrl.toString(), '_blank');
  };

  const RemoveButtonWrapper = (props) =>
    !isViewOnly ? (
      <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Roles} action={Action.Update}>
        <RemoveButton props={props} removeClicked={() => removeSubRoleClicked?.(props.row)} />
      </ReadOnlyDisplayWrapper>
    ) : null;

  const CustomSelectionCellWrapper = (props) => <CustomSelectionCell {...props} isDisabled={isViewOnly} />;

  const CustomHeaderSelectionCellWrapper = (props) => <CustomHeaderSelectionCell {...props} isDisabled={isViewOnly} />;

  return (
    subRoles && (
      <Grid rows={subRoles} columns={getColumns(ROLE_SUB_ROLES_COLUMNS)} automationid="role_sub-roles-tab-list">
        <SortingState
          columnExtensions={getSortingColumns(ROLE_SUB_ROLES_COLUMNS)}
          defaultSorting={getDefaultSorting(ROLE_SUB_ROLES_COLUMNS)}
        />
        <SelectionState selection={selectedSubRoles} onSelectionChange={setSelectedSubRoles} />
        <IntegratedSelection />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.ROLE_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomClickableFormatter {...props} onClick={() => onSubRoleClick(props?.row)} />
          )}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.createdAt ? moment(props?.row?.createdAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.CREATED_BY.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltipOnlyIfLongText isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.UPDATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText
              value={props?.row?.updatedAt ? moment(props?.row?.updatedAt).format('MMM DD YYYY, hh:mm:ss A') : null}
              isSmallText
            />
          )}
          availableFilterOperations={FILTER_NUMBER_OR_DATE_OPERATIONS}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.UPDATED_BY.columnName]}
          formatterComponent={(props) => <CustomFormatterWithTooltipOnlyIfLongText isSmallText {...props} />}
        />
        <DataTypeProvider
          for={[ROLE_SUB_ROLES_COLUMNS.REMOVE_SUB_ROLE.columnName]}
          formatterComponent={RemoveButtonWrapper}
        />
        <TableWrapper virtual noDataCellComponent={RolesNoData} height="auto" />
        <TableSelection
          showSelectAll
          cellComponent={CustomSelectionCellWrapper}
          headerCellComponent={CustomHeaderSelectionCellWrapper}
        />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableHeaderRow showSortingControls cellComponent={CustomHeaderCell} />
      </Grid>
    )
  );
};

RoleSubRolesTabTable.propTypes = {
  isViewOnly: PropTypes.bool,
  removeSubRoleClicked: PropTypes.func.isRequired,
  selectedSubRoles: PropTypes.array.isRequired,
  setSelectedSubRoles: PropTypes.func.isRequired,
  subRoles: PropTypes.array.isRequired,
};

export default RoleSubRolesTabTable;
