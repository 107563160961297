import { ServiceCostAlert } from './types';
import { FlatObject } from '../../../shared/types/commonTypes.ts';
import { SelectOption } from '../../../users/containers/FeatureFlags/components/Filters';

export const validateRecipientsEmails = (emails: string) => {
  const recipientsEmailsArr = emails.replace(/ /g, '').split(',');
  const result = emails && recipientsEmailsArr.every((mail) => !mail || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(mail));
  return !!result;
};

export const createOptions = (typesToDisplayObj: FlatObject, descriptionsObj?: FlatObject) => {
  let options = [] as SelectOption[];
  try {
    options = Object.entries(typesToDisplayObj).map(
      ([k, v]) =>
        ({
          value: k,
          label: v,
          description: descriptionsObj?.[k] || '',
        } as SelectOption),
    );
  } catch (error) {
    return options ?? error;
  }
  return options;
};

export const prepareFiltersForSaving = (filters: ServiceCostAlert['filters']) =>
  Object.fromEntries(
    Object.entries(filters).map(([key, filterConfig]) => [
      key,
      {
        ...filterConfig,
        value: Array.isArray(filterConfig.value) ? filterConfig.value.map((d) => d.value) : filterConfig.value,
      },
    ]),
  );
