import { getVendorMappingsStore } from 'app/store/vendorsStore/vendorStoreProvider';

export const getAccountLabelById = (accounts, accountId) => {
  const [cloud, list] = Object.entries(accounts).find(([, accounts]) =>
    accounts?.some((acc) => acc.accountId === accountId),
  );
  const account = list.find((acc) => acc.accountId === accountId);
  const vendorMappingsStore = getVendorMappingsStore();
  return `${vendorMappingsStore.getDescriptionById(+cloud)} - ${account.accountName}`;
};

export const getAccountByAccountId = (accounts, accountId) =>
  Object.values(accounts)
    .flat()
    .find((acc) => acc.accountId === accountId);

export const totalLinkedAccountsCounter = (accounts, linkedAccounts) =>
  [...(linkedAccounts || new Map()).entries()].reduce(
    (acc, [accountId, arr]) => acc + (getAccountByAccountId(accounts, accountId) ? arr.length : 0),
    0,
  );

export const getLinkedAccountsInAccount = (selected, linkedAccounts = []) =>
  (selected?.linkedAccountIds === 'all'
    ? linkedAccounts
    : linkedAccounts?.filter((ln) => selected?.linkedAccountIds.includes(ln.linkedAccountId))) || [];

export const linkedAccountsSelectedCounter = (accounts, selectedAccounts, linkedAccounts, filter) =>
  selectedAccounts.reduce((acc, { accountId, linkedAccountIds }) => {
    if (filter) {
      if (filter.cloud) {
        if (!getAccountByAccountId({ [filter.cloud]: accounts[filter.cloud] }, accountId)) {
          return acc;
        }
      }
    }
    if (!getAccountByAccountId(accounts, accountId)) {
      return acc;
    }
    if (linkedAccountIds === 'all') {
      return acc + (linkedAccounts.get(accountId)?.length || 0);
    }
    return acc + linkedAccountIds.length;
  }, 0);
