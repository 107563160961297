import React from 'react';
import { Form, Input } from 'reactstrap';
import t from '../texts.ts';
import { hideCustomerNameField } from './index.tsx';
import RecipientsSelect from 'shared/components/RecipientsSelect.tsx';
import classNames from 'classnames';
import { Channel, Recipient } from 'shared/components/RecipientsSelect';
import styles from '../alerts.module.scss';
import { getAppSelectStyles } from 'shared/components/AppSelect/index.tsx';

export interface FormCommitmentExpirationProps {
  className?: string;
  daysBeforeExpiry: number;
  recipientsEmails: string;
  channels: Channel[];
  recipients: Recipient[];
}

const AlertForm: React.FC<FormCommitmentExpirationProps> = ({
  daysBeforeExpiry,
  recipientsEmails,
  className,
  channels,
  recipients,
}) => (
  <Form className={className}>
    <div style={{ maxWidth: 230 }}>
      <h5>{t('DAYS_BEFORE_CUD_EXPIRES')}</h5>
      <Input
        automation-id="days-before-field"
        value={daysBeforeExpiry}
        margin="normal"
        variant="outlined"
        placeholder="Name"
        className="name-input"
        disabled
      />
    </div>
    <div style={{ minWidth: '40%', maxWidth: '40%' }}>
      <h5>{t('RECIPIENTS')}</h5>
      {/* <div>
        <Input
          automation-id="recipient-field"
          value={recipientsEmails}
          margin="normal"
          variant="outlined"
          placeholder="Name"
          className="name-input"
          disabled
        />
      </div> */}
      <div>
        <RecipientsSelect
          className={classNames(styles.select)}
          channels={channels as Channel[]}
          savedRecipients={recipients}
          onChangeHandler={() => {}}
          selectStylesConfig={getAppSelectStyles({ disabled: true, isEmpty: !recipients.length, height: 32})}
          isDisabled
        />
      </div>
    </div>

    {!hideCustomerNameField && (
      <div>
        <h5>{t('CUSTOMER_NAME')}</h5>
        <div>
          <Input
            automation-id="recipient-field"
            value={recipientsEmails}
            margin="normal"
            variant="outlined"
            placeholder="Customer Name"
            className="name-input"
            disabled
          />
        </div>
      </div>
    )}
  </Form>
);

export default AlertForm;
