import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Grid,
  TableColumnResizing,
  TableHeaderRow,
  TableRowDetail,
} from '@devexpress/dx-react-grid-material-ui';
import {
  DataTypeProvider,
  RowDetailState,
  SortingState,
} from '@devexpress/dx-react-grid';
import useTable from 'shared/hooks/customHooks/useTable';
import TableWrapper from 'shared/components/tables/TableWrapper';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltip,
  CustomFormatterWithTooltipAndComponent,
  CustomHeaderCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import CustomersIcon from './CustomersIcon.jsx';
import { ReactComponent as NoResults } from './img/no-results.svg';
import { useCustomersContext } from './contexts/customersContext.jsx';
import {
  CUSTOMERS_COLUMNS,
  getColumns,
  getDefaultSorting,
  getSortingColumns,
} from '../consts';
import CustomerExpandedData from './customersExpandedRowComponents/CustomerExpanded';
import CustomersActions from './CustomersActions';
import styles from './CustomersTable.module.scss';
import CustomersAccountsTable
  from 'users/containers/Organization/Customers/CustomersAccountsTable/CustomersAccountsTable.jsx';

export const CustomersNoData = (customers) => {
  if (!customers) {
    return null;
  }
  return (
    <div className={styles.noResultsWrapper}>
      <NoResults />
      <span className={styles.mainText}>No Customers were found</span>
    </div>
  );
};

const CustomersTable = ({ customers, accounts }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(CUSTOMERS_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const { NewTableRow, CustomToggleCell } = useTable();

  const [expandedRowIds, setExpandedRowIds] = useState([]);

  const { isAllExpanded, setIsAllExpanded, filters } =
    useCustomersContext();

  const customersFiltered = useMemo(() => {
    if (!filters.search) {
      return customers;
    }
    return customers?.filter((customer) => {
      return customer.name.toLowerCase().includes(filters.search.toLowerCase()) ||
        customer.code?.toLowerCase().includes(filters.search.toLowerCase());
    });
  }, [customers, filters.search]);

  useEffect(() => {
    if (!isAllExpanded) {
      setExpandedRowIds([]);
    } else {
      setExpandedRowIds(customers?.map((row, index) => index));
    }
  }, [isAllExpanded]);

  useEffect(() => {
    if (expandedRowIds?.length === 0) {
      setIsAllExpanded(false);
    }
  }, [expandedRowIds]);

  const renderCustomerTable = () => {
    return (
      <Grid
        id="customers-list"
        rows={customersFiltered}
        columns={getColumns(CUSTOMERS_COLUMNS)}
        automationid="customers-list"
      >
        <SortingState
          columnExtensions={getSortingColumns(CUSTOMERS_COLUMNS)}
          defaultSorting={getDefaultSorting(CUSTOMERS_COLUMNS)}
        />
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={setExpandedRowIds}
        />
        <DataTypeProvider
          for={[CUSTOMERS_COLUMNS.CUSTOMER_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipAndComponent {...props} isLeftComponent>
              <CustomersIcon />
            </CustomFormatterWithTooltipAndComponent>
          )}
        />
        <DataTypeProvider
          for={[CUSTOMERS_COLUMNS.CREATED_AT.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltip
              value={props?.row?.creationTime ? moment(props?.row?.creationTime).format('MMM DD YYYY') : null}
              isSmallText
            />
          )}
        />
        <DataTypeProvider
          for={[CUSTOMERS_COLUMNS.ACTIONS.columnName]}
          formatterComponent={CustomersActions}
        />
        <TableWrapper
          virtual
          noDataCellComponent={() => CustomersNoData(customers)}
          rowComponent={(props) => (
            <NewTableRow
              expandedRowIds={expandedRowIds}
              setExpandedRowIds={setExpandedRowIds} {...props} />
          )}
          height="auto"
        />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableRowDetail
          contentComponent={CustomerExpandedData}
          toggleCellComponent={CustomToggleCell}
        />
        <TableHeaderRow cellComponent={CustomHeaderCell} />
      </Grid>
    );
  };

  if (!customers) {
    return null;
  }

  if (filters.mode === 'customers') {
    return renderCustomerTable();
  }
  if (filters.mode === 'accounts') {
    return <CustomersAccountsTable accounts={accounts} />
  }
  return null;
};

CustomersTable.propTypes = {
  customers: PropTypes.array.isRequired,
};

export default CustomersTable;
