import React, { useEffect } from 'react';
import Button from 'shared/components/andtComponents/Button';
import PropTypes from 'prop-types';
import classes from './BudgetModal.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

export const CustomProgress = ({ setCloseWindow, isLoading, isEditMode }) => {
  const brand = useBrand();
  const closeWindow = () => {
    setCloseWindow(!isLoading);
  };
  useEffect(() => {
    const timer = setTimeout(() => closeWindow(), 10000);
    return () => clearTimeout(timer);
  }, [closeWindow]);

  return (
    <div className={classes.progressWrapper}>
      <brand.customProgress className={classes.budgetUpdateIsInProgressSvg}/>
      <span className={classes.mainText}>
        {isEditMode ? 'Budget update is in progress and can take some time' : 'Budget creation is in progress'}
        <span className={classes.text}> You will be notified upon the result</span>
      </span>

      <Button onClick={() => setCloseWindow(false)} text="Got It" />
    </div>
  );
};

CustomProgress.propTypes = {
  setCloseWindow: PropTypes.func.isRequired,
  isLoading: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool,
};
