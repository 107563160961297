import React from 'react';
import CustomModal from 'shared/components/andtComponents/Modal.tsx';
import { ReactComponent as SecretSVG } from 'shared/img/images/secret-pink.svg';
import styles from '../K8sOnboarding.module.scss';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import secretGifLoader from "shared/img/images/K8s-Loader-3.gif";

const KeyIcon = () => <GenerateIcon iconName={ICONS.key.name} />;

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export const RevokeConfirmationModal: React.FC<Props> = ({ open, onClose, onConfirm }) => {
  return (
    <CustomModal
      saveTitle={'Generate Access Keys'}
      open={open}
      onClose={onClose}
      onSave={onConfirm}
      closeOnSave={false}
      saveIcon={KeyIcon}
      className={{ modal: styles.revokeConfirmationModal, footer: styles.revokeButtons }}
    >
      <div className={styles.revokeConfirmationContainer}>
        <SecretSVG className={styles.secretImage} />
        <h6>Generating new Access Keys will revoke your existing keys</h6>
        <div>
          In case you already have Prometheus-Agent installations under the same payer account, you must update them
          with the new key. Otherwise, these existent agents will not be able to function and usage data from such
          cluster will be lost.
        </div>
      </div>
    </CustomModal>
  );
};

export const InProgressModal: React.FC<{open: boolean}> = ({ open }) => (
  <CustomModal open={open} className={styles.inProgressModal} footerDisabled>
    <div className={styles.revokeConfirmationContainer}>
      <img src={secretGifLoader} alt="loading" width={167} style={{ maxWidth: 167 }} />
      <div>
        <h6>Generating new Access Keys.</h6>
        <div>This might take a few seconds.</div>
      </div>
    </div>
  </CustomModal>
);
