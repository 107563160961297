import React from 'react';
import PropTypes from 'prop-types';
import styles from './Explanation.module.scss';

const Explanation = ({ Icon, title, text }) => (
  <div className={styles.explanation}>
    <Icon className={styles.icon} />
    <div className={styles.explainBlock}>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.text}>{text}</p>
    </div>
  </div>
);

Explanation.propTypes = {
  Icon: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Explanation;
