import React from 'react';
import { ReactComponent as BYODIcon } from 'shared/img/cloud-providers/simple/byod.svg';
import { normalizeVendorLogoUrl } from 'shared/utils/vendorLogoUtil';

import styles from './VendorLabel.module.scss';

export interface VendorOption {
  label: string;
  value: number;
  vendorLogoUrl: string;
  cloudTypeId: number;
}

export const VendorLabel: React.FC<{ label: string; option?: VendorOption }> = ({ label, option }) => {
  if (!option) return <>{label}</>;

  return (
    <div className={styles.vendorOption}>
      {option.vendorLogoUrl ? (
        <img src={normalizeVendorLogoUrl(option.vendorLogoUrl)} alt={label} />
      ) : (
        React.createElement(BYODIcon)
      )}
      {label}
    </div>
  );
};
