import React from 'react';
import { ReactComponent as AWSSimple } from 'shared/img/cloud-providers/simple/aws.svg';
import { ReactComponent as AzureSimple } from 'shared/img/cloud-providers/simple/azure.svg';
import { ReactComponent as GCPSimple } from 'shared/img/cloud-providers/simple/gcp.svg';
import { ReactComponent as MultiSimple } from 'shared/img/cloud-providers/simple/multi.svg';
import { ReactComponent as BYODSimple } from 'shared/img/cloud-providers/simple/byod.svg';
import { ReactComponent as AWSSquare } from 'shared/img/cloud-providers/aws-square.svg';
import AzureSquare from 'shared/img/cloud-providers/azure-square';
import { ReactComponent as GCPSquare } from 'shared/img/cloud-providers/gcp-square.svg';
import { ReactComponent as MulticloudSquare } from 'shared/img/cloud-providers/multi-square.svg';
import { ReactComponent as BYODSquare } from 'shared/img/cloud-providers/byod-square.svg';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { VendorStore } from './vendorStore.ts';

import styles from './vendor-icons.module.scss';

import { getCloudTypeIconClassName } from '../../../shared/utils/vendorLogoUtil';

let vendorMappingsStoreInstance: VendorStore | null = null;

export const initializeVendorMappingsStore = (store: VendorStore): void => {
  if (!vendorMappingsStoreInstance) {
    vendorMappingsStoreInstance = store;
  }
};

export const getVendorMappingsStore = () => {
  if (!vendorMappingsStoreInstance) {
    return {
      getDescriptionById: () => 'Unknown',
      getIdByDescription: () => undefined,
      doesCloudTypeIdBelongToByod: () => undefined,
    };
  }
  return vendorMappingsStoreInstance;
};

interface CloudTypeIcon {
  simple: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  square: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const CLOUD_TYPE_ICONS: Record<number, CloudTypeIcon> = {
  [CLOUD_TYPE_IDS.AWS]: { simple: AWSSimple, square: AWSSquare },
  [CLOUD_TYPE_IDS.AZURE]: { simple: AzureSimple, square: AzureSquare },
  [CLOUD_TYPE_IDS.GCP]: { simple: GCPSimple, square: GCPSquare },
  [CLOUD_TYPE_IDS.MULTI]: { simple: MultiSimple, square: MulticloudSquare },
};

type ImageProps = Pick<React.ImgHTMLAttributes<HTMLImageElement>, 'className' | 'style' | 'alt'>;

const createImageComponent = (url?: string) => {
  return React.forwardRef<HTMLImageElement, ImageProps>((props, ref) => (
    <img ref={ref} src={url} alt="Vendor Logo" className={props.className} style={{ ...props.style }} />
  ));
};

/**
 * Returns the simple icon component for a given cloud type ID
 * @param cloudTypeId - The numeric ID of the cloud type
 * @returns React component for the icon
 */
export const getCloudTypeSimpleIcon = (cloudTypeId: number) => {
  if (CLOUD_TYPE_ICONS[cloudTypeId]) {
    return CLOUD_TYPE_ICONS[cloudTypeId].simple;
  }

  const vendorStore = vendorMappingsStoreInstance;
  if (vendorStore) {
    const vendors = vendorStore.allVendors;
    const vendor = vendors.get(cloudTypeId);
    if (vendor?.vendorLogoUrl) {
      return createImageComponent(vendor.vendorLogoUrl);
    }
  }

  return BYODSimple;
};

/**
 * Returns a ready-to-use cloud type icon component
 * @param cloudTypeId - The numeric ID of the cloud type
 * @returns Ready-to-use React component that accepts className prop
 */
export const getCloudTypeSquareIcon = (cloudTypeId: number) => {
  if (CLOUD_TYPE_ICONS[cloudTypeId]) {
    const SquareIcon = CLOUD_TYPE_ICONS[cloudTypeId].square;
    return (props: ImageProps) => <SquareIcon {...props} />;
  }

  const vendorStore = vendorMappingsStoreInstance;
  if (vendorStore) {
    const vendors = vendorStore.allVendors;
    const vendor = vendors.get(cloudTypeId);
    if (vendor?.vendorLogoUrl) {
      const ImageComponent = createImageComponent(vendor.vendorLogoUrl);
      return (props: ImageProps) => (
        <ImageComponent {...props} className={getCloudTypeIconClassName(cloudTypeId, styles)} />
      );
    }
  }

  const FallbackIcon = BYODSquare;
  return (props: ImageProps) => <FallbackIcon {...props} />;
};
