import { ExternalLinks } from 'shared/enums/external-links.enum';
import { Brand } from '../../../contexts/Brand/types';

interface OnboardingLinks {
  AWS: {
    customerType: string;
    onboardingType: {
      automatic: string;
      manual: string;
    };
    onboardingLinkedAccountsAutomatic: string;
    trustedAccess: string;
    onboardingTypeEventNotification: string;
  };
  AZURE: {
    onboardingType: {
      manual: string;
    };
    customerType: string;
    focusGuide: string;
  };
  GCP: {
    customerType: string;
    onboardingType: {
      manual: string;
    };
    k8sIntegration: string;
  };
}

const getOnboardingLinks = (brand: Brand): OnboardingLinks => {
  const externalLinks = brand.externalLinks
  return {
    AWS: {
      customerType: externalLinks.AWSCustomerType,
      onboardingType: {
        automatic: externalLinks.AutomaticAWSOnboardingType,
        manual: externalLinks.ManualAWSOnboardingType,
      },
      onboardingLinkedAccountsAutomatic: externalLinks.AWSOnboardingLinkedAccountsAutomatic,
      trustedAccess: ExternalLinks.AWSTrustedAccess,
      onboardingTypeEventNotification: externalLinks.AWSOnboardingTypeEventNotification,
    },
    AZURE: {
      onboardingType: {
        manual: externalLinks.AzureOnboardingType,
      },
      customerType: externalLinks.AzureCustomerType,
      focusGuide: externalLinks.AzureFocusGuide,
    },
    GCP: {
      customerType: externalLinks.GcpCustomerType,
      onboardingType: {
        manual: externalLinks.GcpOnboardingType,
      },
      k8sIntegration: externalLinks.GcpK8sIntegration,
    },
  };
};

export default getOnboardingLinks;
