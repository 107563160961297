import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { capitalize } from 'lodash/string';
import { Card, CardBody, Container, Row } from 'reactstrap';
import { OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import DeleteWarningModal from 'shared/components/DeleteWarningModal';
import Spinner from 'shared/components/andtComponents/Spinner';
import { awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { withCUEGoalsContextProvider } from 'users/contexts/CUEGoalsContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import CustomDashboardActivePanel from './CustomDashboardActivePanel';
import CustomDashboardFilters from './CustomDashboardFilters';

const CustomDashboardWrapper = ({ id: idFromProps = null, isTemplate = false }) => {
  const { usersStore, usageStore, appStore } = useRootStore();

  const [activeDashboard, setActiveDashboard] = useState(null);
  const [selectedPanelForRemove, setSelectedPanelForRemove] = useState(null);
  const [panelsForceReload, setPanelsForceReload] = useState(false);
  const [panelLoadingMap, setPanelLoadingMap] = useState({});

  const { id: urlParamDashboardId } = useParams();

  const getDashboardIdFromLocation = () => {
    if (idFromProps) {
      return idFromProps;
    }
    return urlParamDashboardId;
  };

  const handleReloadPanels = async () => {
    setPanelsForceReload((prevPanelsForceReload) => !prevPanelsForceReload);
  };

  const handleRemovePanelClicked = (dbId, selectedPanelForRemove) => {
    const activeDashboard = usageStore.customDbSubStore.customDashboardModel.dashboards.find((db) => db.uuid === dbId);
    setActiveDashboard(activeDashboard);
    setSelectedPanelForRemove(selectedPanelForRemove);
  };

  const handleRemovePanelFromCustomDb = async (action) => {
    if (action === 'delete') {
      await usageStore.customDbSubStore.customDashboardModel.removePanel(selectedPanelForRemove.uuid, activeDashboard);
    }
    setActiveDashboard(null);
  };

  const renderActivePanels = (panelsIds, panelsSettings, dashboardId, currentUserOwner) => {
    const { customDbSubStore } = usageStore;
    const { filters, accountId } =
      customDbSubStore.customDashboardModel.getExistingDashboardByUuid(dashboardId, isTemplate) || {};
    const sortedPanels = panelsIds
      .slice()
      .sort((id1, id2) => panelsSettings.get(id1)?.order - panelsSettings.get(id2)?.order);
    return (
      <Row>
        {sortedPanels.map((panelId, index) => (
          <CustomDashboardActivePanel
            key={panelId}
            id={`graph_${index}`}
            fromTemplate={isTemplate}
            dbAccountId={accountId}
            panelsForceReload={panelsForceReload}
            panelSettings={panelsSettings.get(panelId)}
            hasDashboardFilters={!isEmptyArray(filters)}
            customDashboardStore={usageStore.customDbSubStore}
            dashboardId={dashboardId}
            currentUserOwner={isTemplate ? false : currentUserOwner}
            handlePanelLoading={(isLoading) =>
              setPanelLoadingMap((prevMap) => ({
                ...prevMap,
                [panelId]: isLoading,
              }))
            }
            panelId={panelId}
            handleRemovePanelClicked={handleRemovePanelClicked}
            isPpApplied={appStore.isPpApplied}
            filters={filters}
          />
        ))}
      </Row>
    );
  };

  const renderCustomFilters = (filters) => {
    if (isEmptyArray(filters)) {
      return '';
    }
    return filters[0].filters
      .map(
        ({ field, values, operator }) => `${capitalize(awsCommonFieldToDisplayField.get(field) || field)} is
      ${operator === OPERATORS_KEYS.IS_NOT ? 'not ' : ''}${values.map((v) => v.label).join(', ')}`,
      )
      .join('; ');
  };

  const { modelIsLoading } = usageStore.customDbSubStore.customDashboardModel;
  if (modelIsLoading) {
    return <Spinner />;
  }
  const dashboardId = getDashboardIdFromLocation();
  const { customDbSubStore } = usageStore;
  const { customDashboardModel } = customDbSubStore;
  const {
    filters,
    name: dashboardName,
    accountKey,
  } = customDbSubStore.customDashboardModel.getExistingDashboardByUuid(dashboardId, isTemplate) || {};
  const currentUserOwner = customDbSubStore.isCurrentUserOwner(dashboardId);
  const { panelsIds, panelsSettings } = customDashboardModel.getDashboardPanelIdsByDbId(dashboardId, isTemplate);
  const isAnyPanelLoading = panelsIds.some((id) => !panelLoadingMap[id]);
  // the below global var is for the creation of dashboard reports by the BE
  window.isAnyPanelLoading = isAnyPanelLoading;

  const pageTitle = (() => {
    if (idFromProps) {
      return PageNames.DASHBOARD;
    }
    if (isTemplate) {
      return PageNames.CUSTOM_DASHBOARD_TEMPLATE;
    }
    return PageNames.CUSTOM_DASHBOARD;
  })();

  return (
    <Container>
      <PageHeader
        showDate
        title={`${pageTitle} - ${dashboardName}`}
        barIcons
        smallSubTitle={renderCustomFilters(filters)}
      >
        {!isTemplate && String(accountKey) === String(usersStore.currDispUserAccountKey) ? (
          <CustomDashboardFilters dashboardId={dashboardId} onReload={handleReloadPanels} filters={filters} />
        ) : null}
      </PageHeader>
      <DeleteWarningModal
        deletedItemName={selectedPanelForRemove && selectedPanelForRemove.name}
        isOpen={activeDashboard}
        handleDelete={handleRemovePanelFromCustomDb}
      />
      {/*
          the div with id 'dashboard-finish-loading' is for the creation of dashboard reports by the BE
          this is an indication that all panels completed fetching the data and a snapshot can be taken
          */}
      <div id={isAnyPanelLoading ? 'dashboard-loading' : 'dashboard-finish-loading'} />
      <Card>
        <CardBody id="custom-dashboard-wrapper">
          {isEmptyArray(panelsIds)
            ? null
            : renderActivePanels(panelsIds, panelsSettings, dashboardId, currentUserOwner)}
        </CardBody>
      </Card>
    </Container>
  );
};

CustomDashboardWrapper.propTypes = {
  isTemplate: PropTypes.bool,
};

const ObserverCustomDashboardWrapper = withCUEGoalsContextProvider(observer(CustomDashboardWrapper));
export default ObserverCustomDashboardWrapper;
