// https://docs.google.com/document/d/1LiCYIMcvawzpr5BlhbgtDKLl33aJ2eM1BAML77eOOsY/edit#
import React from 'react';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import NotificationsDescriptions from './NotificationsDescriptions';
import { NOTIFICATIONS_TYPES } from './notificationsConstants';
import { getVendorMappingsStore } from 'app/store/vendorsStore/vendorStoreProvider';

const getNotificationRenderDetails = (type, details, { isCurrentUserReseller, cloudType }) => {
  const renderDescription = () => (
    <NotificationsDescriptions
      isCurrentUserReseller={isCurrentUserReseller}
      details={details}
      type={type}
      cloudType={cloudType}
    />
  );
  const vendorMappingsStore = getVendorMappingsStore();

  const NOTIFICATIONS_CONFIG = {
    [NOTIFICATIONS_TYPES.LINKED_ACCOUNT_NOT_ASSOCIATED_WITH_DIVISION]: {
      title: `${prepareLinkAccountLabel(cloudType, true)} is not associated with ${LabelCoordinator.getFieldLabel(
        'Cost Center',
      )}`,
      subTitle: `${details.linkedAccountName} (${details.linkedAccountId})`,
      icon: 'linkHorizontal',
      excludable: true,
    },

    [NOTIFICATIONS_TYPES.NEW_LINKED_ACCOUNT]: {
      title: `New ${prepareLinkAccountLabel(cloudType, true)}`,
      subTitle: `${details.linkedAccountName} (${details.linkedAccountId})`,
      icon: 'linkHorizontal',
      excludable: true,
    },

    [NOTIFICATIONS_TYPES.NOT_CONNECTED_LINKED_ACCOUNT]: {
      title: `${prepareLinkAccountLabel(cloudType, false)} are Not Connected`,
      subTitle: '',
      icon: 'linkHorizontal',
    },

    [NOTIFICATIONS_TYPES.AZURE_APP_REGISTRATION_EXPIRES]: {
      title: 'The Azure App Registration key is about to expire within a month',
      subTitle: details.applicationId,
      icon: 'pencilMechanical',
    },

    [NOTIFICATIONS_TYPES.ONBOARDING_SUCCEED]: {
      title: `${vendorMappingsStore.getDescriptionById(details.cloud)} Successful Account Onboard`,
      subTitle: details.id,
      icon: 'cloudCheck',
    },

    [NOTIFICATIONS_TYPES.ONBOARDING_FAILED]: {
      title: `${vendorMappingsStore.getDescriptionById(details.cloud)} Account Onboarding Validation Failure`,
      subTitle: details.id,
      icon: 'cloudXMark',
    },
  };

  const config = NOTIFICATIONS_CONFIG[type];
  if (!config) {
    return null;
  }
  return {
    ...config,
    description: renderDescription(),
  };
};

const NotificationsUtils = {
  getNotificationRenderDetails,
};

export default NotificationsUtils;
