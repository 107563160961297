import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import DateFilter from '../modules/dateFilter';
import Input from './andtComponents/Input';
import styles from './PageHeader.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const getDisplayDate = () => {
  const currDate = DateFilter.getDateFilterRangeStr();
  if (!currDate) {
    return '';
  }
  return `Updated: ${currDate}`;
};

const PageHeader = ({
  title,
  showDate = false,
  barIcons = false,
  isBeta = false,
  subTitle = '',
  smallSubTitle = '',
  searchParams = null,
  hasInfo = false,
  children = null,
}) => {
  const brand = useBrand();
  return (
    <Row className="page-title-wrapper">
      <div className="page-title">
        <h3 automation-id="pageTitle">{title}</h3>
        {hasInfo && children}
        {showDate ? <h3 className="page-subhead subhead mt-3">{getDisplayDate()}</h3> : <></>}
        {isBeta && <brand.beta className={styles.betaIcon} />}
      </div>
      <Col xl={4} lg={4} md={4} className="d-flex justify-content-end">
        {barIcons && children}
        {searchParams ? (
          <div className={styles.searchInputContainer}>
            <Input
              value={searchParams.value}
              onChange={(e) => searchParams.onChange(e.target.value)}
              placeholder="Search"
              className={styles.searchInput}
            />
          </div>
        ) : null}
      </Col>
      {subTitle ? <h4 className="page-sub-title">{subTitle}</h4> : <></>}
      {smallSubTitle ? (
        <h3
          className="page-subhead"
          style={{
            fontSize: 14,
            maxWidth: '60%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {smallSubTitle}
        </h3>
      ) : (
        <></>
      )}
    </Row>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  smallSubTitle: PropTypes.string,
  showDate: PropTypes.bool,
  barIcons: PropTypes.bool,
  isBeta: PropTypes.bool,
  hasInfo: PropTypes.bool,
  children: PropTypes.object,
  searchParams: PropTypes.object,
};
