import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from 'shared/components/tableHeader/TableHeader';

export const SecondaryTableHeaderRow = ({ csvData, filteredData, accounts }) => {
  return (
    <TableHeader
      tableName="Cloud Accounts"
      filteredRows={filteredData.length}
      totalRows={accounts.length}
      csvData={{ data: csvData, filename: 'accounts.csv' }}
      titleComponent={<span></span>}
    />
  );
};

SecondaryTableHeaderRow.propTypes = {
  csvData: PropTypes.object.isRequired,
  filteredData: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
};
