import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import CloudProviderButton from 'app/components/CloudProviderButton';
import { CLOUD_TYPE_IDS, ACCOUNT_FEATURES } from 'users/constants/usersConstants';
import styles from '../CloudAccounts.module.scss';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';

export const CreateCloudAccountTableHeader = ({ usersStore, setIsCreateMode }) => {
  return (
    <div className={classNames(styles.providersHeader, styles.header)}>
      <p className={styles.title}>Choose your cloud provider</p>
      <div className={styles.providers}>
        <CloudProviderButton type={CLOUD_TYPE_IDS.AWS} />
        <CloudProviderButton type={CLOUD_TYPE_IDS.AZURE} />
        <CloudProviderButton type={CLOUD_TYPE_IDS.GCP} />
        {checkFeatureFlag(usersStore, ACCOUNT_FEATURES.BYOD_ONBOARDING) && (
          <CloudProviderButton type="BYOD" />
        )}
      </div>
      <div className={styles.closeButton}>
        <Button
          onClick={() => setIsCreateMode(false)}
          text="Close"
          icon={() => <GenerateIcon iconName={ICONS.xMark.name} />}
        />
      </div>
    </div>
  );
};

CreateCloudAccountTableHeader.propTypes = {
  usersStore: PropTypes.object.isRequired,
  setIsCreateMode: PropTypes.func.isRequired,
};
