import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import styles from './comingSoon.module.scss';

const ComingSoonTag = ({ className }) => (
  <div className={classNames(styles.comingSoonWrapper, className)}>
    <GenerateIcon iconName={ICONS.sparkles.name} />
    <span>Coming Soon</span>
  </div>
);

ComingSoonTag.propTypes = {
  className: PropTypes.object,
};
export default ComingSoonTag;
