import React from 'react';
import PropTypes from 'prop-types';
import Button from 'shared/components/andtComponents/Button';
import CloudProviderButton from 'app/components/CloudProviderButton';
import { ReactComponent as Clouds } from './assets/clouds.svg';
import { ReactComponent as CloudsDimmed } from './assets/clouds-dimmed.svg';

import styles from './OnboardingWelcome.module.scss';
import ConfettiAnimation from './ConfettiAnimation';
import { ACCOUNT_FEATURES, CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import checkFeatureFlag from 'shared/utils/featureFlagUtil';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const OnboardingWelcome = ({ usersStore }) => {
  const brand = useBrand();
  const [secondScreen, setSecondScreen] = React.useState(
    usersStore.welcomeScreenAnimationDone || !usersStore.isCurrentUserNew,
  );
  const renderFirstScreen = () => {
    return (
      <>
        <div className={styles.content}>
          <h1>Welcome Aboard!</h1>
          <div className={styles.subContent}>
            <p>Let`s begin your journey by integrating your clouds. Ready? </p>
            <Button
              onClick={() => {
                setSecondScreen(true);
                usersStore.setWelcomeScreenAnimationDone();
              }}
              text="Let’s start"
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.sunLight} />
          <brand.sun className={styles.sun} />
          <Clouds className={styles.clouds} />
        </div>
        <ConfettiAnimation />
      </>
    );
  };
  const renderSecondScreen = () => (
    <>
      <div className={styles.providers}>
        <p>Choose your cloud provider</p>
        <div className={styles.providersList}>
          <CloudProviderButton type={CLOUD_TYPE_IDS.AWS} />
          <CloudProviderButton type={CLOUD_TYPE_IDS.AZURE} />
          <CloudProviderButton type={CLOUD_TYPE_IDS.GCP} />
          {checkFeatureFlag(usersStore, ACCOUNT_FEATURES.BYOD_ONBOARDING) && (
            <CloudProviderButton type="BYOD" />
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <CloudsDimmed className={styles.clouds} />
      </div>
    </>
  );
  return <div className={styles.container}>{secondScreen ? renderSecondScreen() : renderFirstScreen()}</div>;
};

OnboardingWelcome.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

export default OnboardingWelcome;
