import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { removeDecPoint } from 'shared/utils/strUtil';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import ComingSoonTag from 'app/containers/MainDashboard/components/ComingSoonTag.jsx';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './usageCost.module.scss';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';

const UsageCost = memo(
  ({
    containerClassName = '',
    title,
    showIcon = false,
    infoValue = '',
    specialInfo = null,
    value,
    showPercent = false,
    displayComingSoon = false,
    iconText,
    percent,
    overrideCurrency = null,
    navigateTo = null,
    moreDetails,
    tooltip,
  }) => {
    const { getCurrencyNumber } = useUserSettingsContext();
    const Wrapper = navigateTo ? Link : 'div';
    const wrapperProps = navigateTo
      ? {
          to: {
            pathname: `${navigateTo.path}`,
            search: navigateTo.searchParams,
          },
          rel: 'stylesheet',
          className: classNames(!showPercent && styles.navigateMargin),
          'automation-id': `investigate-${navigateTo?.path}`,
        }
      : {};

    const renderValue = () => {
      if (Array.isArray(value)) {
        return value.map((item) => (
          <div key={item.description} className={styles.valueRow}>
            <div>
              <span>{item.value}</span>
              <span className={styles.usageDescription}>{item.description}</span>
            </div>
            {item.navigateTo && (
              <GenerateIcon
                iconName={ICONS.chevronRight.name}
                automation-id="investigate-icon"
                className={styles.arrowIcon}
              />
            )}
          </div>
        ));
      }
      return <span>{value ? getCurrencyNumber(removeDecPoint(value, 10), 0, {}, overrideCurrency) : '-'}</span>;
    };

    const renderMoreDetails = () => {
      if (moreDetails) {
        return (
          <div className={styles.moreDetailsWrapper}>
            <h5 className={styles.secondHeader}>{moreDetails.title}</h5>
            <div className={styles.secondValue}>
              {moreDetails.value
                ? `${getCurrencyNumber(removeDecPoint(moreDetails.value, 10), 0, {}, overrideCurrency)}`
                : '-'}
            </div>
          </div>
        );
      }
      return null;
    };

    return (
      <div className={classNames(styles.usageWrapper, containerClassName)}>
        <Tooltip
          placement="top"
          title={tooltip ? <span className={tooltipStyles.tooltipText}>{tooltip}</span> : null}
          arrow
          cursor={tooltip ? 'pointer' : 'default'}
          classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}
        >
          <Wrapper {...wrapperProps}>
            <div className={styles.savingPanel}>
              <div className={styles.flex}>
                <div
                  className={classNames(
                    styles.cardWrapper,
                    moreDetails && styles.split,
                    Array.isArray(value) && styles.smallGap,
                  )}
                >
                  <div className={styles.headerWrapper}>
                    <div className={styles.mainHeader}>
                      <h5 className={styles.description}>{title}</h5>
                      {showIcon ? (
                        <InfoPopover
                          mode="outline"
                          className={{
                            icon: styles.infoIcon,
                            tooltip: tooltipStyles.whiteTooltip,
                            arrow: tooltipStyles.whiteArrow,
                          }}
                        >
                          <span className={tooltipStyles.tooltipText}>{infoValue}</span>
                        </InfoPopover>
                      ) : (
                        specialInfo
                      )}
                    </div>
                  </div>
                  {displayComingSoon ? (
                    <ComingSoonTag />
                  ) : (
                    <div className={styles.valueWrapper}>
                      <h2 className={classNames(styles.title, Array.isArray(value) ? styles.fullWidth : '')}>
                        {renderValue()}
                      </h2>
                    </div>
                  )}
                </div>
                {renderMoreDetails()}
              </div>
              <div className={styles.cardWrapper}>
                <div className={classNames(styles.detailsWrapper, styles.marginTop)}>
                  <div className={classNames(!navigateTo && styles.percentMargin)}>
                    {showPercent && percent && !displayComingSoon ? (
                      <PercentTag percent={percent} iconText={iconText} />
                    ) : null}
                  </div>
                  {navigateTo && !Array.isArray(value) && (
                    <GenerateIcon
                      iconName={ICONS.chevronRight.name}
                      className={styles.arrowIcon}
                      automation-id="investigate-icon"
                    />
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </Tooltip>
      </div>
    );
  },
);

UsageCost.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.array]).isRequired,
  percent: PropTypes.string,
  infoValue: PropTypes.string,
  showPercent: PropTypes.bool,
  showIcon: PropTypes.bool,
  containerClassName: PropTypes.string,
  withHeader: PropTypes.bool,
  displayComingSoon: PropTypes.bool,
  overrideCurrency: PropTypes.string,
  navigateTo: PropTypes.object,
  tooltip: PropTypes.string,
  iconText: PropTypes.string,
  moreDetails: PropTypes.object,
};

export default UsageCost;

UsageCost.displayName = 'UsageCost';
