import { ExternalLinks } from 'shared/enums/external-links.enum';
import { BrandExternalLinks, envConfig } from '../types';
import cloneDeep from 'lodash/cloneDeep';

const prod: envConfig = {
  api: 'https://api-front.mypileus.io',
  legacyAPI: 'https://api.mypileus.io',
  redirectURL: 'https://mypileus.io',
  keycloak: 'https://login.mypileus.io',
  cognitoPool: 'https://mypileus.auth.us-east-1.amazoncognito.com',
};

const staging: envConfig = {
  api: 'https://api.staging.mypileus.io',
  legacyAPI: 'https://api.staging.mypileus.io',
  redirectURL: 'https://staging.mypileus.io',
  keycloak: 'https://keycloak.dev.umbrellacost.dev',
  cognitoPool: 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',
};

const dev: envConfig = {
  api: 'https://api.dev.mypileus.io',
  legacyAPI: 'https://api.dev.mypileus.io',
  redirectURL: 'https://dev.mypileus.io',
  keycloak: 'https://keycloak.dev.umbrellacost.dev',
  cognitoPool: 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',
};

const feature: envConfig = {
  api: 'https://feature-frontend-frontend-app-server:8080',
  legacyAPI: 'https://feature-frontend-frontend-app-server:8080',
  redirectURL: 'https://feature.mypileus.io',
  keycloak: 'http://keycloak-http:80',
  cognitoPool: 'https://dev-mypileus.auth.us-east-1.amazoncognito.com',
};

const local: envConfig = {
  api: ExternalLinks.LocalHost,
  legacyAPI: 'https://f78f-199-203-250-201.ngrok-free.app',
  redirectURL: ExternalLinks.LocalServerURI,
};

const DefaultExternalLinks: BrandExternalLinks = {
  prod,
  staging,
  dev,
  feature,
  local,

  TermsAndConditions: 'https://www.anodot.com/terms-of-use',
  PrivacyPolicy: 'https://www.anodot.com/privacy-policy',
  ContactUs: 'https://www.anodot.com/contact-us',

  AwsOnboardingCFTJson: 'https://pileus-cloudformation-public.s3.amazonaws.com/PileuseOnboardingCFT.json',

  ConnectYourLinkedAccounts: 'https://cloudcost.zendesk.com/hc/en-us/articles/6041352942492',
  SettingNonUSDCurrenciesForAccounts: 'https://cloudcost.anodot.com/hc/en-us/articles/9719238564124',
  ConnectAzureApplications: 'https://cloudcost.anodot.com/hc/en-us/articles/17715327015324-Connect-Azure-Applications',
  CueFilters: 'https://cloudcost.anodot.com/hc/en-us/articles/11040212772508-Understand-the-Cost-Usage-filters',
  RecommendationsForAWS: 'https://cloudcost.anodot.com/hc/en-us/articles/7017727629596-Recommendations-for-AWS',
  RecommendationsForAzure: 'https://cloudcost.anodot.com/hc/en-us/articles/7017727826716-Recommendations-for-Azure',
  RecommendationsForGCP: 'https://cloudcost.anodot.com/hc/en-us/articles/7017727991964-Recommendations-for-GCP',
  K8sWorkloadRightsizingRecommendation:
    'https://cloudcost.anodot.com/hc/en-us/articles/15324209487644-K8s-Workload-Rightsizing-Recommendation',
  RecommendationsForAwsSectionRds:
    'https://cloudcost.anodot.com/hc/en-us/articles/7017727629596-Recommendations-for-AWS#h_01H4ENV0FJ4CAFZ4PWYWZE13DW',
  PrometheusAgentForKubernetesForAWSAzure: 'https://cloudcost.anodot.com/hc/en-us/articles/7145768262812',
  WorkingWithCostGPT: 'https://cloudcost.anodot.com/hc/en-us/articles/9568761938076',
  IntroductionToAWSCostMonitoring: 'https://cloudcost.anodot.com/hc/en-us/articles/6629591463068',
  PrerequisitesForOnboardingWithAzure: 'https://cloudcost.anodot.com/hc/en-us/articles/9425596170780',
  GcpOnboarding: 'https://cloudcost.anodot.com/hc/en-us/articles/15181340450972-GCP-onboarding',
  DocumentationForResellers: 'https://cloudcost.anodot.com/hc/en-us/articles/6143226566812',
  ForecastArchitecture: 'https://cloudcost.anodot.com/hc/en-us/articles/10019455778972-Forecast-Architecture',
  AWSCustomerType: 'https://cloudcost.anodot.com/hc/en-us/articles/10273697984668',
  AutomaticAWSOnboardingType: 'https://cloudcost.anodot.com/hc/en-us/articles/10273724354076',
  ManualAWSOnboardingType: 'https://cloudcost.anodot.com/hc/en-us/articles/10273724354076',
  AWSOnboardingLinkedAccountsAutomatic:
    'https://cloudcost.anodot.com/hc/en-us/articles/6041352942492-Connect-your-linked-accounts',
  AWSOnboardingTypeEventNotification:
    'https://cloudcost.anodot.com/hc/en-us/articles/10273724354076-AWS-Automatic-and-Manual-Onboarding#01HQEFVPQ5E3C4BACM3Y1YR3TS',

  AzureOnboardingType:
    'https://cloudcost.anodot.com/hc/en-us/articles/12759538700572-Azure-Onboarding-CSP-MCA-Pay-as-you-go',
  AzureCustomerType: 'https://cloudcost.anodot.com/hc/en-us/articles/12865472672668-Azure-Onboarding-for-MSP',
  AzureFocusGuide: 'https://cloudcost.anodot.com/hc/en-us/articles/15866356565660-Migrate-Azure-accounts-to-FOCUS',
  GcpCustomerType: 'https://cloudcost.anodot.com/hc/en-us/articles/15181395736860-GCP-onboarding-for-MSP',
  GcpOnboardingType: 'https://cloudcost.anodot.com/hc/en-us/articles/15181340450972-GCP-new-onboarding',
  GcpK8sIntegration: 'https://cloudcost.anodot.com/hc/en-us/articles/6787850245404-How-to-connect-a-GCP-K8s-Cluster',
  ByodOnboarding: 'https://cloudcost.anodot.com/hc/en-us/articles/18317901529628-Bring-Your-Own-Cost-Data',
  SystemNotifications: 'https://cloudcost.anodot.com/hc/en-us/articles/12414162952732-System-Notifications',
  CostMainDashboard: 'https://cloudcost.anodot.com/hc/en-us/articles/19189422473628-Main-Dashbaord',
  RiUtilization: 'https://cloudcost.anodot.com/hc/en-us/articles/11847396384924-RI-Utilization',
  ConnectLinkedAccount:
    'https://cloudcost.anodot.com/hc/en-us/articles/6041352942492-Connect-your-linked-accounts#01J9GN2XTYFXGZGM50HW38DBHW',
};

export const UmbrellaExternalLinks: BrandExternalLinks = {
  ...cloneDeep(DefaultExternalLinks),
  prod: {
    ...cloneDeep(DefaultExternalLinks.prod),
    api: 'https://api-front.umbrellacost.io',
    redirectURL: 'https://umbrellacost.io',
  },
  staging: {
    ...cloneDeep(DefaultExternalLinks.staging),
    api: 'https://api.staging.umbrellacost.dev',
    redirectURL: 'https://staging.umbrellacost.dev',
  },
  dev: {
    ...cloneDeep(DefaultExternalLinks.dev),
    api: 'https://api.dev.umbrellacost.dev',
    redirectURL: 'https://dev.umbrellacost.dev',
  },
  ConnectYourLinkedAccounts: 'https://docs.umbrellacost.io/docs/connect-your-linked-accounts#/',
  TermsAndConditions: 'https://umbrellacost.com/terms-of-use',
  PrivacyPolicy: 'https://umbrellacost.com/privacy-policy',
  ContactUs: 'https://umbrellacost.com/contact-us',
  SettingNonUSDCurrenciesForAccounts: 'https://docs.umbrellacost.io/docs/accounts-currency#/',
  ConnectAzureApplications: 'https://docs.umbrellacost.io/docs/connect-azure-applications#/',
  CueFilters: 'https://docs.umbrellacost.io/docs/understand-the-cost-usage-filters#/',
  RecommendationsForAWS: 'https://docs.umbrellacost.io/docs/recommendations-for-aws#/',
  RecommendationsForAzure: 'https://docs.umbrellacost.io/docs/azure-recommendations#/',
  RecommendationsForGCP: 'https://docs.umbrellacost.io/docs/recommendations-for-gcp#/',
  K8sWorkloadRightsizingRecommendation: 'https://docs.umbrellacost.io/docs/k8s-workload-rightsizing-recommendation#/',
  PrometheusAgentForKubernetesForAWSAzure:
    'https://docs.umbrellacost.io/docs/prometheus-agent-for-kubernetes-for-aws-azure#/',
  WorkingWithCostGPT: 'https://docs.umbrellacost.io/docs/costgpt#/',
  IntroductionToAWSCostMonitoring: 'https://docs.umbrellacost.io/docs/connecting-aws#/',
  PrerequisitesForOnboardingWithAzure: 'https://docs.umbrellacost.io/docs/prerequisites-for-onboarding#/',
  GcpOnboarding: 'https://docs.umbrellacost.io/docs/gcp-onboarding#/',
  DocumentationForResellers: 'https://docs.umbrellacost.io/docs/partner-overview#/',
  ForecastArchitecture: 'https://docs.umbrellacost.io/docs/forecast-architecture#/',
  AWSCustomerType: 'https://docs.umbrellacost.io/docs/aws-onboarding-for-msp#/',
  AutomaticAWSOnboardingType: 'https://docs.umbrellacost.io/docs/aws-automatic-onboarding#/',
  ManualAWSOnboardingType: 'https://docs.umbrellacost.io/docs/aws-manual-onboarding#/',
  AWSOnboardingLinkedAccountsAutomatic: 'https://docs.umbrellacost.io/docs/connect-your-linked-accounts#/',
  AWSOnboardingTypeEventNotification:
    'https://docs.umbrellacost.io/docs/aws-manual-onboarding#/create-event-notification',
  AzureOnboardingType: 'https://docs.umbrellacost.io/docs/azure-onboarding#/',
  AzureCustomerType: 'https://docs.umbrellacost.io/docs/azure-onboarding-for-msp#/',
  AzureFocusGuide: 'https://docs.umbrellacost.io/docs/migrate-azure-accounts-to-focus#/',
  GcpCustomerType: 'https://docs.umbrellacost.io/docs/gcp-onboarding-for-msp#/',
  GcpOnboardingType: 'https://docs.umbrellacost.io/docs/gcp-onboarding#/',
  GcpK8sIntegration: 'https://docs.umbrellacost.io/docs/how-to-connect-a-gcp-k8s-cluster#/',
  ByodOnboarding: 'https://docs.umbrellacost.io/docs/bring-your-own-data#/',
  SystemNotifications: 'https://docs.umbrellacost.io/docs/system-notifications#/',
  CostMainDashboard: 'https://docs.umbrellacost.io/docs/umbrella-cost-main-dashboard#/',
  RiUtilization: 'https://docs.umbrellacost.io/docs/ri-utilization#/',
  ConnectLinkedAccount:
    'https://docs.umbrellacost.io/docs/connect-your-linked-accounts#/manual-connection-for-each-linked-account-via-the-umbrella-cost-ui',
};
export const AnodotExternalLinks: BrandExternalLinks = cloneDeep(DefaultExternalLinks);
