import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from '~/shared/components/andtComponents/Modal.js';
import RadioButtonList from '~/shared/components/andtComponents/RadioButtonList.jsx';
import Button from '~/shared/components/andtComponents/Button.js';
import { ReactComponent as GCPIcon } from '~/shared/img/cloud-providers/simple/gcp.svg';
import { ReactComponent as AzureIcon } from '~/shared/img/cloud-providers/simple/azure.svg';
import { ReactComponent as AWSIcon } from '~/shared/img/cloud-providers/simple/aws.svg';
import { ReactComponent as CloudsDimmed } from '~/app/containers/OnboardingWelcome/assets/clouds-dimmed.svg';
import onboardingStyles from '~/app/containers/Onboarding/SharedPages/Footer/Footer.module.scss';
import { ONBOARDING_FIELDS, ONBOARDING_TYPES } from '~/app/containers/Onboarding/utils/OnboardingConstants.js';
import ListWithHelp from '~/shared/components/andtComponents/ListWithHelp/ListWithHelp.js';
import { ReactComponent as ManualImg } from './assets/manual.svg';
import AccountDetailsHelp from './AccountDetailsHelp.jsx';
import styles from './AccountDetailsModal.module.scss';
import CustomerInput from '../CustomerInput/CustomerInput.jsx';
import { useBrand } from '~/app/contexts/Brand/BrandContext.js';
import Input from '~/shared/components/andtComponents/Input.tsx';
import { CLOUD_TYPE_IDS } from '~/users/constants/usersConstants.js';
import {
  AZURE_ACCOUNT_TYPES,
  AZURE_ONBOARDING_FIELDS,
} from '~/app/containers/Onboarding/AzureOnboarding/AzureOnboardingConstants.jsx';
import { AWS_ONBOARDING_FIELDS } from '~/app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants.js';
import Checkbox from '~/shared/components/andtComponents/Checkbox.jsx';
import Select from '~/shared/components/andtComponents/SimpleSelect.js';
import { prepareLinkAccountLabel } from '~/recommendations/containers/Dashboard/helpers/dataPrepareHelpers.js';
import { useRootStore } from 'app/contexts/RootStoreContext';

const AccountDetailsModal = ({ nextStep, autoAssignEnabled, automaticEnabled }) => {
  const brand = useBrand();
  const { appStore } = useRootStore();
  const [loading, setLoading] = React.useState(false);
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const accountType = getValues(ONBOARDING_FIELDS.ACCOUNT_TYPE);
  const setAccountType = (value) => {
    setValue(ONBOARDING_FIELDS.ACCOUNT_TYPE, value);
    if (value === 'shared') {
      setValue(ONBOARDING_FIELDS.RESELLER_CUSTOMER, '');
      setValue(ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID, '');
    }
  };
  const onboardingType = getValues(ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const setOnboardingType = (value) => {
    setValue(ONBOARDING_FIELDS.ONBOARDING_TYPE, value);
    setValue(AWS_ONBOARDING_FIELDS.ROLE_ARN, '');
    setValue(AWS_ONBOARDING_FIELDS.BUCKET_NAME, '');
    setValue(AWS_ONBOARDING_FIELDS.AUTO_SETUP_LINKED_ACCOUNTS, false);
  };
  const existingCustomerSelected = getValues(ONBOARDING_FIELDS.EXISTING_CUSTOMER_SELECTED);
  const setExistingCustomerSelected = (value) => setValue(ONBOARDING_FIELDS.EXISTING_CUSTOMER_SELECTED, value);
  const setCustomer = (value) => setValue(ONBOARDING_FIELDS.RESELLER_CUSTOMER, value);
  const setCustomerId = (value) => setValue(ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID, value);

  const autoCreateCustomersProperty = getValues(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY);

  const azureAccountType = getValues(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE);
  const setAzureAccountType = (value) => {
    setValue(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE, value);
  };

  const getIconByCloudType = () => {
    switch (getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID)) {
      case CLOUD_TYPE_IDS.AWS:
        return <AWSIcon />;
      case CLOUD_TYPE_IDS.AZURE:
        return <AzureIcon />;
      case CLOUD_TYPE_IDS.GCP:
        return <GCPIcon />;
      default:
        return null;
    }
  };

  const renderClearButtonComponent = () => (
    <Button
      text={''}
      isTextButton
      className={{ button: styles.clear }}
      onClick={() => {
        setExistingCustomerSelected(false);
        setCustomerId('');
        setCustomer('');
        setValue(ONBOARDING_FIELDS.CUSTOMER_DOMAIN, '');
      }}
      icon={<GenerateIcon iconName={ICONS.xMark.name} />}
    />
  );

  const renderSelectAzureAccountType = () => (
    <div>
      <p className={styles.subTitle}>Select your Azure billing account type</p>
      <div className={styles.selectBlock}>
        <RadioButtonList
          options={[
            {
              value: AZURE_ACCOUNT_TYPES.MCA_EXPORT,
              key: 1,
              label: 'MCA',
              primary: true,
            },
            {
              value: AZURE_ACCOUNT_TYPES.CSP_EXPORT,
              key: 2,
              label: 'CSP',
              primary: true,
            },
            {
              value: AZURE_ACCOUNT_TYPES.PAYG_EXPORT,
              key: 3,
              label: 'Pay as you go',
              primary: true,
            },
            {
              value: AZURE_ACCOUNT_TYPES.EA_EXPORT,
              key: 4,
              label: 'EA',
              primary: true,
            },
          ]}
          value={azureAccountType}
          onChange={(val) => {
            setAzureAccountType(val);
          }}
        />
      </div>
    </div>
  );

  const renderSelectOnboardingType = () => (
    <div>
      <p className={styles.subTitle}>How would you like to complete the onboarding?</p>
      <div className={styles.selectBlock} data-onboarding="true">
        <RadioButtonList
          options={[
            {
              value: ONBOARDING_TYPES.AUTOMATIC,
              key: 1,
              label: <span>Automatically {automaticEnabled ? null : <i>Coming Soon!</i>}</span>,
              primary: true,
              disabled: !automaticEnabled,
            },
          ]}
          value={onboardingType}
          onChange={(val) => {
            setOnboardingType(val);
          }}
        />
        {getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID) === CLOUD_TYPE_IDS.AWS &&
          getValues(ONBOARDING_FIELDS.ONBOARDING_TYPE) === ONBOARDING_TYPES.AUTOMATIC && (
            <div onClick={(e) => e.stopPropagation()}>
              <Checkbox
                primary
                className={styles.radioCheckbox}
                isChecked={getValues(AWS_ONBOARDING_FIELDS.AUTO_SETUP_LINKED_ACCOUNTS)}
                isDisabled={ONBOARDING_TYPES.AUTOMATIC !== onboardingType}
                onChange={(value) => {
                  setValue(AWS_ONBOARDING_FIELDS.AUTO_SETUP_LINKED_ACCOUNTS, value);
                }}
                text={
                  <span className={styles.radioCheckboxLabel}>
                    Connect all the{' '}
                    {prepareLinkAccountLabel(getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID), false).toLowerCase()} under the
                    Management account to {brand.name}, in order to see Recommendations.
                  </span>
                }
              />
            </div>
          )}
        <RadioButtonList
          options={[
            {
              value: ONBOARDING_TYPES.MANUAL,
              key: 2,
              label: 'Manually',
              primary: true,
            },
          ]}
          value={onboardingType}
          onChange={(val) => {
            setOnboardingType(val);
          }}
        />
        {getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID) !== CLOUD_TYPE_IDS.AWS && (
          <div className={styles.onboardingTypeIllustration}>
            {onboardingType === ONBOARDING_TYPES.MANUAL ? <ManualImg /> : <brand.automatic />}
          </div>
        )}
      </div>
    </div>
  );
  const renderSelectMSPAccountType = () => (
    <div>
      <p className={styles.subTitle}>Which type of account do you want?</p>
      <RadioButtonList
        className={styles.radioButtonsList}
        options={[
          { value: 'dedicated', label: 'Dedicated Account', primary: true },
          { value: 'shared', label: 'Shared Account', primary: true },
        ]}
        value={accountType}
        onChange={(val) => {
          setAccountType(val);
        }}
      />
    </div>
  );

  const renderCustomerInput = () => {
    return <CustomerInput autoAssignEnabled={autoAssignEnabled} />;
  };

  const renderAzureCreationType = () => {
    return (
      <div>
        Creation Type
        <div className={styles.selectBlock}>
          <div className={styles.customerInfo}>
            <RadioButtonList
              className={styles.radioSecondary}
              options={[
                { value: 'automatic', label: 'Create customers automatically using Azure logic', primary: true },
              ]}
              value={autoCreateCustomersProperty ? 'automatic' : ''}
              onChange={() => {
                setValue(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY, 'customer_id');
              }}
            />
            {getValues(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY) && (
              <div>
                <p>Create and group the customer by:</p>
                <Select
                  isClearable={false}
                  placeholder="Select field"
                  name="autoCreateCustomersProperty"
                  value={autoCreateCustomersProperty ?? undefined}
                  options={[
                    { value: 'customer_id', label: 'Customer tenant ID' },
                    { value: 'customer_name', label: 'Customer name' },
                  ]}
                  onChange={(value) => {
                    setValue(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY, value);
                  }}
                  className={styles.input}
                />
              </div>
            )}
            <RadioButtonList
              className={styles.radioSecondary}
              options={[{ value: 'manual', label: 'Create customers manually', primary: true }]}
              value={!autoCreateCustomersProperty ? 'manual' : ''}
              onChange={() => {
                setValue(AZURE_ONBOARDING_FIELDS.AUTO_CREATE_CUSTOMERS_PROPERTY, '');
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCustomerDomain = () => {
    return (
      <div className={styles.selectBlock} data-onboarding="true">
        <div>
          <label htmlFor="customer-domain">Customer Domain</label>
          <div className={styles.inputContainer}>
            <Input
              name="customer-domain"
              value={getValues(ONBOARDING_FIELDS.CUSTOMER_DOMAIN)}
              placeholder="Enter Domain"
              disabled={existingCustomerSelected || !getValues(ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED)}
              isSearch={existingCustomerSelected}
              searchComponent={existingCustomerSelected ? renderClearButtonComponent() : null}
              isInvalid={!!errors[ONBOARDING_FIELDS.CUSTOMER_DOMAIN]}
              invalidComponent={errors[ONBOARDING_FIELDS.CUSTOMER_DOMAIN]}
              onChange={(e) => {
                setValue(ONBOARDING_FIELDS.CUSTOMER_DOMAIN, e.target.value);
              }}
              className={styles.input}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.background}>
      <div className={styles.bgFooter}>
        <CloudsDimmed className={styles.bgClouds} />
      </div>
      <CustomModal
        open
        closeOnOutside={false}
        overrideStyles={{
          width: 968,
        }}
        size="big"
        onClose={() => {}}
        className={{
          container: styles.modalContainer,
          modal: `${styles.modalBody} ${getValues(ONBOARDING_FIELDS.IS_MSP) ? '' : styles.direct}`,
          title: styles.modalTitle,
        }}
        closeOnSave={false}
        title={
          <div className={styles.header}>
            <div className={styles.headerContent}>Account details and onboarding experience</div>
            <div className={styles.headerLogo}>{getIconByCloudType()}</div>
          </div>
        }
        footerDisabled
      >
        <div className={styles.body}>
          <ListWithHelp
            styles={{
              listGap: '24px',
              helpWidth: 300,
            }}
            help={{
              'azure-account-type': AccountDetailsHelp.AzureAccountType,
              'onboarding-type': AccountDetailsHelp.OnboardingType,
              'msp-account-type': AccountDetailsHelp.MSPAccountType,
              'msp-customer': AccountDetailsHelp.MspCustomer,
              'msp-customer-domain': AccountDetailsHelp.MspCustomerDomain,
              'azure-creation-type': AccountDetailsHelp.AzureCreationType,
            }}
            list={[
              {
                render: renderSelectAzureAccountType,
                key: 'azure-account-type',
                disabled: getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID) !== CLOUD_TYPE_IDS.AZURE,
              },
              {
                render: renderSelectOnboardingType,
                key: 'onboarding-type',
                disabled: getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID) === CLOUD_TYPE_IDS.AZURE,
              },
              {
                render: renderSelectMSPAccountType,
                key: 'msp-account-type',
                disabled: !getValues(ONBOARDING_FIELDS.IS_MSP),
              },
              {
                render: renderCustomerInput,
                key: 'msp-customer',
                disabled: !getValues(ONBOARDING_FIELDS.IS_MSP) || accountType === 'shared',
              },
              ...(appStore.isKeyCloakManagement
                ? []
                : [
                    {
                      render: renderCustomerDomain,
                      key: 'msp-customer-domain',
                      disabled: !getValues(ONBOARDING_FIELDS.IS_MSP) || accountType === 'shared',
                    },
                  ]),
              {
                render: renderAzureCreationType,
                key: 'azure-creation-type',
                disabled:
                  getValues(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE) !== AZURE_ACCOUNT_TYPES.CSP_EXPORT ||
                  accountType === 'dedicated',
              },
            ]}
          />
        </div>
        <div className={onboardingStyles.footer}>
          <div />
          <div className={onboardingStyles.footerMenu}>
            <Button
              onClick={async () => {
                setLoading(true);
                await nextStep();
                setLoading(false);
              }}
              isLoading={loading}
              text="Next"
              iconPlacement="right"
              icon={<GenerateIcon iconName={ICONS.chevronRight.name} />}
            />
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

AccountDetailsModal.propTypes = {
  nextStep: PropTypes.func.isRequired,
};

export default AccountDetailsModal;
