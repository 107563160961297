import React from 'react';
import classNames from 'classnames';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import styles from './mtdTop10.module.scss';

export const MtdTop10ChartTooltip = ({ payload, activeKey, tooltipData }) => {
  const { getCurrencyNumber } = useUserSettingsContext();
  if (!payload?.length) {
    return null;
  }
  const dataPoint = payload[0]?.payload;
  return (
    <div className={styles.customizedTooltip}>
      <div className={styles.tooltipTitleContainer}>
        <div className={styles.tooltipTitle}>{dataPoint.groupBy}</div>
      </div>
      <div className={styles.dataWrapper}>
        {payload.map((pointKey) => (
          <div
            key={`${pointKey.dataKey}-${pointKey.value}`}
            className={classNames(styles.tooltipRow, pointKey.dataKey === activeKey && styles.bold)}
          >
            <div className={styles.tooltipValue}>
              <div className={styles.keyWrapper}>
                <svg width="14" height="10" className={styles.legend}>
                  <rect width="14" height="10" fill={pointKey.color} />
                </svg>
                <span>{tooltipData[pointKey.dataKey]?.displayValue}</span>
              </div>
              <span className={styles.valueText}>{getCurrencyNumber(pointKey.value)}</span>
            </div>
          </div>
        ))}
        {payload.length > 1 && (
          <div className={styles.summaryRow}>
            <span className={styles.summaryTitle}>Change from previous Month</span>
            <span className={styles.value}>{getCurrencyNumber(dataPoint.changeValue)}</span>
            <PercentTag percent={dataPoint.changePercent} />
          </div>
        )}
      </div>
    </div>
  );
};
