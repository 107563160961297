import React, { useEffect, useState } from 'react';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import IconFromPng from 'shared/components/IconFromPng';
import { useLocation } from 'react-router-dom';

export default function MenuWrapper({
  title,
  icon,
  iconSize = '24px',
  isNew = false,
  matchHeight = false,
  links = [],
  children,
  styleUpdate = { sideBarLink: undefined, sidebarSubmenu: undefined },
  id = '',
  isSidebarShow = false,
  collapse: collapseProps = false,
}) {
  const [collapse, setCollapse] = useState(collapseProps);
  const location = useLocation();

  useEffect(() => {
    if (!isSidebarShow) {
      setCollapse(false);
    }
  }, [isSidebarShow]);

  const { sideBarLinkTitle, sidebarSubmenu } = styleUpdate;
  const renderIcon = (icn, size, height) => {
    if (!icn) return '';
    if (typeof icn === 'string') {
      return <IconFromPng size={size} icon={icn} matchHeight={height} />;
    }
    return icn;
  };

  return (
    <div
      className={`${collapse ? 'sidebar__category-wrap sidebar__category-wrap--open' : 'sidebar__category-wrap'} ${
        links.some((link) => link.route === location.pathname) ? 'sidebar__selected-child' : ''
      }`}
    >
      <button
        id={id}
        className="sidebar__link sidebar__category"
        onClick={() => setCollapse((prevState) => !prevState)}
        type="button"
      >
        {renderIcon(icon, iconSize, matchHeight)}
        <p style={sideBarLinkTitle || {}} className="sidebar__link-title">
          {title}
          {isNew && <span className="sidebar__category-new" />}
        </p>
        <span className="sidebar__category-icon lnr lnr-chevron-right" />
      </button>
      <Collapse isOpen={collapse} className="sidebar__submenu-wrap">
        <ul style={sidebarSubmenu || {}} className="sidebar__submenu">
          <div>{children}</div>
        </ul>
      </Collapse>
    </div>
  );
}

MenuWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  iconSize: PropTypes.string,
  isNew: PropTypes.bool,
  matchHeight: PropTypes.bool,
  links: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.object]).isRequired,
  styleUpdate: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSidebarShow: PropTypes.bool,
  collapse: PropTypes.bool,
};
