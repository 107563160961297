import { makeAutoObservable, runInAction } from 'mobx';
import { queryClient } from 'queryClient';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import apiConstants from 'shared/api/apiConstants';

export interface Vendor {
  cloudTypeId: number;
  companyUuid: string;
  isPredefined: number;
  vendorName: string;
  vendorLogoUrl: string | null;
}

export class VendorStore {
  private cloudTypeIdToDescription: Map<number, string> = new Map<number, string>();
  private cloudDescriptionToTypeId: Map<string, number> = new Map<string, number>();
  private vendors: Map<number, Vendor> = new Map<number, Vendor>();
  private isInitialized = false;

  constructor() {
    makeAutoObservable(this);
    this.initializeBasicProviders();
  }

  private static readonly CLOUD_PROVIDERS = {
    AWS: { id: CLOUD_TYPE_IDS.AWS, name: 'AWS' },
    AZURE: { id: CLOUD_TYPE_IDS.AZURE, name: 'AZURE' },
    GCP: { id: CLOUD_TYPE_IDS.GCP, name: 'GCP' },
    MULTI: { id: CLOUD_TYPE_IDS.MULTI, name: 'MULTICLOUD' },
  } as const;

  /**
   * Pre-populate basic providers with known constants, such as AWS or Azure.
   */
  private initializeBasicProviders(): void {
    Object.values(VendorStore.CLOUD_PROVIDERS).forEach(({ id, name }) => {
      this.cloudTypeIdToDescription.set(id, name);
      this.cloudDescriptionToTypeId.set(name, id);
    });
  }

  /**
   * Checks if the given cloud type ID belongs to BYOD vendors
   * @param {number} cloudTypeId - The cloud type ID to check
   * @returns {boolean} True if the ID belongs to BYOD vendors
   */
  public doesCloudTypeIdBelongToByod(cloudTypeId: number): boolean {
    return cloudTypeId >= 10000;
  }

  public initializeWithVendors(vendors: Vendor[]): void {
    if (this.isInitialized) return;

    runInAction(() => {
      (vendors || []).forEach((vendor) => {
        const { cloudTypeId, vendorName } = vendor;
        this.vendors.set(cloudTypeId, vendor);
        this.cloudTypeIdToDescription.set(cloudTypeId, vendorName);
        this.cloudDescriptionToTypeId.set(vendorName, cloudTypeId);
      });
      this.isInitialized = true;
    });
  }

  public invalidateStore(): void {
    this.vendors.clear();
    this.cloudTypeIdToDescription.clear();
    this.cloudDescriptionToTypeId.clear();
    this.isInitialized = false;
    this.initializeBasicProviders();
    queryClient.invalidateQueries({ queryKey: [apiConstants.QUERY_KEYS.VENDOR_MAPPINGS_QUERY_KEY] });
  }

  /**
   * Given a one of CLOUD_TYPE_IDS (0, 1, 2, 3...), returns the associated vendor name.
   * If not found, returns 'Unknown'.
   */
  public getDescriptionById = (id: number): string => {
    return this.cloudTypeIdToDescription.get(id) || 'Unknown';
  };

  /**
   * Given a vendor description ('AWS', 'AZURE'), returns its numeric ID.
   * If not found, returns undefined.
   */
  public getIdByDescription = (description: string): number | undefined => {
    return this.cloudDescriptionToTypeId.get(description);
  };

  /**
   * Exposes the current internal mappings.
   */
  public get allMappings() {
    return {
      idToDescription: this.cloudTypeIdToDescription,
      descriptionToId: this.cloudDescriptionToTypeId,
    };
  }

  public get allVendors(): Map<number, Vendor> {
    return this.vendors;
  }

  public get initialized(): boolean {
    return this.isInitialized;
  }
}
