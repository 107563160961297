import { k8sCommonFieldToDisplayField } from 'shared/constants/k8sConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AZURE_K8S_GROUP_BY_LOV, K8S_GROUP_BY_LOV } from '../../usage/constants/costAndUsageConstants';
import { AwsCommonFields, awsCommonFieldToDisplayField } from '../constants/awsConstants';
import { getBusinessMappingFilterLabel } from './awsUtils';

export const getK8sFieldDisplayLabel = (k8sField, cloudType = CLOUD_TYPE_IDS.AWS, secondLevelItemsValuesMap) => {
  let label = '';
  if (k8sField) {
    const subFieldKey = k8sField.split(':').slice(1).join(':');
    if (k8sField.includes('viewscustomtags')) {
      label = `Views - ${subFieldKey}`;
    } else if (k8sField.includes(AwsCommonFields.WORKLOAD)) {
      label = awsCommonFieldToDisplayField.get(AwsCommonFields.WORKLOAD);
    } else if (k8sField.includes('customtags')) {
      label = cloudType === CLOUD_TYPE_IDS.GCP ? `Labels - ${subFieldKey}` : `Tags - ${subFieldKey}`;
    } else if (k8sField.includes('accounttags')) {
      label = `Enrichment Tags - ${subFieldKey}`;
    } else if (k8sField.includes(AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS)) {
      let field = k8sField;
      if (secondLevelItemsValuesMap) {
        field = ([...secondLevelItemsValuesMap.entries()].find(([, v]) => v === k8sField) || [])[0] || k8sField;
      }
      label = getBusinessMappingFilterLabel(field);
    } else if (k8sField.includes('labels')) {
      label = cloudType === CLOUD_TYPE_IDS.GCP ? `K8S Labels - ${subFieldKey}` : `Labels - ${subFieldKey}`;
    } else if (k8sCommonFieldToDisplayField.has(k8sField.toLowerCase())) {
      label = k8sCommonFieldToDisplayField.get(k8sField);
      if (label === K8S_GROUP_BY_LOV.BY_LINKED_ACC_ID && cloudType === CLOUD_TYPE_IDS.AZURE) {
        label = AZURE_K8S_GROUP_BY_LOV.BY_SUBSCRIPTION;
      }
    } else {
      label = k8sField;
    }
  }

  return label;
};
