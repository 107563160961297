import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import MtdCost from 'app/containers/MainDashboard/panels/MtdCost.jsx';
import PreviousMonthCost from 'app/containers/MainDashboard/panels/PreviousMonthCost.jsx';
import AnnualSavings from 'app/containers/MainDashboard/panels/AnnualSavings.jsx';
import OpenRecommendations from 'app/containers/MainDashboard/panels/OpenRecommendations.jsx';
import ForecastedCost from 'app/containers/MainDashboard/panels/ForecastedCost.jsx';
import LinkedAccountTotal from 'app/containers/MainDashboard/panels/LinkedAccountTotal.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import CostGptPanel from 'app/containers/MainDashboard/panels/CostGptPanel.jsx';
import BudgetsData from 'app/containers/MainDashboard/panels/MonitoringData/BudgetsData.jsx';
import mainStyles from '../mainDashboardCommon.module.scss';
import styles from './costPanelWrapper.module.scss';

const CostPanelsWrapper = ({ isDashboardPanel = false }) => {
  const { usersStore } = useRootStore();
  const costPanelsWrapperRef = useRef(null);
  const [maxWidth, setMaxWidth] = useState('100%');

  useEffect(() => {
    if (isDashboardPanel && costPanelsWrapperRef.current) {
      setMaxWidth(`${costPanelsWrapperRef.current.offsetWidth}px`);
    }
  }, [isDashboardPanel, costPanelsWrapperRef.current?.offsetWidth]);

  const content = (
    <>
      <div className={styles.costWrapper}>
        <MtdCost />
        <PreviousMonthCost />
        <ForecastedCost />
        {usersStore.isCurrentAccountSingle && <AnnualSavings />}
      </div>
      <div className={styles.dataPanelsWrapper}>
        {usersStore.isCurrentAccountSingle && <OpenRecommendations />}
        {usersStore.isCurrentAccountMulticloud && <BudgetsData isCostPanel={true} />}
        <LinkedAccountTotal />
        <CostGptPanel />
      </div>
    </>
  );
  return (
    <div ref={costPanelsWrapperRef} className={classNames(styles.costPanelsWrapper, mainStyles.fullWidth)}>
      {isDashboardPanel ? (
        <div className={styles.scrollOnHover} style={{ maxWidth }}>
          {content}
        </div>
      ) : (
        content
      )}
    </div>
  );
};

export default CostPanelsWrapper;
