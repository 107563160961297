import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import { DATA_ACCESS_TABS } from '../../../consts';
import {
  getInitialAccountsDataWithCounters,
  provideAccountsData,
} from '../../../components/LinkedAccounts/dataAccessHelperFunctions';
import {
  getCostCentersDataAccessCombinedData,
  mergeCostCentersDataWithDataAccess,
} from 'users/containers/Organization/CostCenters/costCentersHelperFunctions';
import DataAccessContent from '~/users/containers/Organization/Roles/rolesExpandedRowComponents/DataAccess/DataAccessContent.jsx';

const DataAccessContentWrapper = ({
  roleId,
  isCreateEditMode,
  isInProgress,
  isViewOnly,
  modifiedSelectionById,
  onRemoveLinkedAccounts,
  onRemoveCostCenters,
  onAccountsSelectionChange,
  onCostCentersSelectionChange,
  setInitialCostCentersDataAccessData,
}) => {
  const [selectedTab, setSelectedTab] = useState(DATA_ACCESS_TABS.ACCOUNTS);

  // const { usersStore } = useRootStore();
  // const organizationId = usersStore.getCurrentUser?.organizationId;

  // TODO: In case the Role data access is derived from more than one Organization, need to call the API for each?
  // TODO: Or should I only display the data for the User organization?
  // TODO: If the user is a reseller, then the data access is derived from all organizations? And then call for each?
  const { fetchDataAccessPayerAccounts, fetchDataAccessCostCenters } = useDataAccessAccounts();
  const { data: accountsData, isLoading: isAccountsDataLoading } = fetchDataAccessPayerAccounts();
  const { data: costCentersData, isLoading: isCostCentersDataAccessLoading } = fetchDataAccessCostCenters();

  const { fetchRoleDataAccess } = useRoles();
  const { data: roleDataAccess, isLoading: isRoleDataAccessLoading } = fetchRoleDataAccess(roleId);

  const dataToDisplay = useMemo(() => {
    if (selectedTab.id === DATA_ACCESS_TABS.ACCOUNTS.id) {
      if (!accountsData?.length || !roleDataAccess) {
        return null;
      }
      const combinedData = getInitialAccountsDataWithCounters(accountsData);
      const combinedPayerAccountsData = provideAccountsData({
        accountsData,
        roleDataAccess,
        currentRoleId: roleId,
        isCreateEditMode,
      });

      const dataAccessWithFullAccess = roleDataAccess.find(
        (roleAccess) => roleAccess.payerAccounts?.allLinkedAccountsOfAllPayerAccounts,
      );
      return { ...combinedData, accountsData: combinedPayerAccountsData, dataAccessWithFullAccess };
    }

    if (selectedTab.id === DATA_ACCESS_TABS.COST_CENTERS.id) {
      if (!costCentersData?.length || !roleDataAccess) {
        return null;
      }
      const data = getCostCentersDataAccessCombinedData(
        costCentersData,
        roleDataAccess,
        roleId,
        modifiedSelectionById,
        !isCreateEditMode,
      );
      if (isCreateEditMode) {
        setInitialCostCentersDataAccessData(data);
        // If createEdit mode - get the assigned together with the unassigned cost centers
        return mergeCostCentersDataWithDataAccess(costCentersData, data);
      } else {
        return data.filter((cc) => cc.costCenter.isAllowed || cc.costCenter.effectiveIsAllowed);
      }
    }
    return null;
  }, [
    accountsData,
    costCentersData,
    isCreateEditMode,
    modifiedSelectionById,
    roleDataAccess,
    roleId,
    selectedTab.id,
    setInitialCostCentersDataAccessData,
  ]);

  return (
    <DataAccessContent
      dataToDisplay={dataToDisplay}
      isCreateEditMode={isCreateEditMode}
      isInProgress={isInProgress}
      isViewOnly={isViewOnly}
      isLoading={
        (selectedTab.id === DATA_ACCESS_TABS.ACCOUNTS.id ? isRoleDataAccessLoading : isCostCentersDataAccessLoading) ||
        isAccountsDataLoading
      }
      modifiedSelectionById={modifiedSelectionById}
      onAccountsSelectionChange={onAccountsSelectionChange}
      onCostCentersSelectionChange={onCostCentersSelectionChange}
      onRemoveCostCenters={onRemoveCostCenters}
      onRemoveLinkedAccounts={onRemoveLinkedAccounts}
      roleId={roleId}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
    />
  );
};

DataAccessContentWrapper.propTypes = {
  isCreateEditMode: PropTypes.bool,
  isInProgress: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  modifiedSelectionById: PropTypes.object,
  onAccountsSelectionChange: PropTypes.func,
  onCostCentersSelectionChange: PropTypes.func,
  onRemoveCostCenters: PropTypes.func,
  onRemoveLinkedAccounts: PropTypes.func,
  roleId: PropTypes.string,
  setInitialCostCentersDataAccessData: PropTypes.func,
};

export default DataAccessContentWrapper;
