import React, { useRef, useState } from 'react';
import {
  SelectRoot,
  SelectTrigger,
  SelectValue,
  SelectIcon,
  SelectViewport,
  SelectScrollUpButton,
  SelectContent,
  SelectScrollDownButton,
  GenerateIcon,
  ICONS,
  SelectGroup,
  SelectItem,
  SelectItemText,
  SelectItemIndicator,
} from '@pileus-cloud/anodot-frontend-common';

import useContainerDimensions from '../../hooks/customHooks/useElementSize';
import styles from './SimpleSelect.module.scss';

interface SelectOption {
  value: string | number;
  label: string;
}

interface SimpleSelectProps {
  label?: string;
  options: SelectOption[];
  value?: string | number;
  onChange: (value: string | number | null) => void;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  isInvalid?: boolean;
  isClearable?: boolean;
  isPrimarySmall?: boolean;
  renderLabel?: (label: string) => React.ReactNode;
  invalidComponent?: React.ReactNode;
  automationid?: string;
}

const SimpleSelect: React.FC<SimpleSelectProps> = ({
  label = '',
  options,
  value,
  onChange,
  disabled = false,
  placeholder = 'Select',
  className = '',
  isInvalid = false,
  isClearable = true,
  isPrimarySmall = false,
  renderLabel = null,
  invalidComponent = null,
  automationid,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(ref);
  const [key, setKey] = useState<number>(0);

  return (
    <div automation-id={automationid} className={`${styles.container} ${className}`} ref={ref}>
      {label && <p className={styles.label}>{label}</p>}
      <SelectRoot value={(value as string) ?? undefined} disabled={disabled} onValueChange={onChange} key={key}>
        <SelectTrigger
          className={`${styles.trigger} ${isInvalid ? styles.error : ''} ${disabled ? styles.disabled : ''} ${
            isPrimarySmall ? styles.isPrimarySmall : ''
          }`}
        >
          <SelectValue placeholder={placeholder} aria-label={value?.toString()} className={styles.value} />
          {!disabled && (
            <SelectIcon>
              {isClearable && value ? (
                <span
                  onClick={(e: React.MouseEvent) => {
                    e.stopPropagation();
                    onChange(null);
                    setKey((prevKey) => prevKey + 1);
                  }}
                  onKeyDown={(e: React.KeyboardEvent) => {
                    if (e.key === 'Enter' || e.key === 'Space') {
                      e.stopPropagation();
                      onChange(null);
                      setKey((prevKey) => prevKey + 1);
                    }
                  }}
                  className="ps-2"
                  role="button"
                  tabIndex={0}
                  aria-label="Clear selection"
                >
                  <GenerateIcon iconName={ICONS.xMark.name} style={{ pointerEvents: 'all' }} />
                </span>
              ) : (
                <GenerateIcon iconName={ICONS.caretDown.name} />
              )}
            </SelectIcon>
          )}
        </SelectTrigger>
        <SelectContent className={styles.content} position="popper">
          <SelectScrollUpButton>
            <GenerateIcon iconName={ICONS.chevronUp.name} />
          </SelectScrollUpButton>
          <SelectViewport className={styles.viewport} style={{ minWidth: width }}>
            <SelectGroup>
              {options.map((item) => (
                <SelectItem value={item.value as string} key={item.value}>
                  <SelectItemText>{renderLabel ? renderLabel(item.label) : item.label}</SelectItemText>
                  <SelectItemIndicator>
                    <GenerateIcon iconName={ICONS.check.name} />
                  </SelectItemIndicator>
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectViewport>
          <SelectScrollDownButton>
            <GenerateIcon iconName={ICONS.chevronDown.name} />
          </SelectScrollDownButton>
        </SelectContent>
      </SelectRoot>
      {isInvalid && <span className={styles.error}>{invalidComponent}</span>}
    </div>
  );
};

export default SimpleSelect;
