import React, { memo } from 'react';

interface IconProps {
  size?: number | string;
  height?: number | null;
  icon: string | React.ComponentType | React.ReactElement;
  className?: string;
  matchHeight?: boolean;
}

const Icon = memo(({ size = 23, height = null, icon, className = '', matchHeight = false }: IconProps) => {
  const styles = {
    img: {
      width: `${size}`,
      height: matchHeight ? `${height || size}` : '100%',
    },
  };

  if (icon && typeof icon !== 'string') {
    if (!React.isValidElement(icon)) {
      const IconComp = icon as React.ComponentType;
      return <IconComp />;
    }
    return icon;
  }
  return <img className={className} style={styles.img} src={icon as string} alt="img" />;
});

export default Icon;
