import React from 'react';
import classNames from 'classnames';
import FilterField from 'app/containers/MainDashboard/components/FilterField';
import Button from 'shared/components/andtComponents/Button';
import { getSearchParamsPanel } from 'app/containers/MainDashboard/utils';
import { ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import { PANELS, PANELS_TYPES, filtersFields } from 'app/containers/MainDashboard/consts';
import SaveDashboardPanel from 'app/containers/MainDashboard/components/SaveDashboardPanel';
import NavigateIcon from 'app/containers/MainDashboard/components/NavigateIcon';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { PANEL_COMPONENT_TYPES } from 'usage/containers/CustomDashboard/constants/customDashboardConstants';
import mainStyles from '../../mainDashboardCommon.module.scss';
import styles from './mtdTop10.module.scss';
import { HeaderRowProps } from './types.ts';

const modeSwitch = {
  daily: { id: 'daily', text: 'Daily' },
  mtd: { id: 'monthly', text: 'MTD' },
};

const HeaderRow: React.FC<HeaderRowProps> = ({
  groupBy,
  setGroupBy,
  orderBy,
  setOrderBy,
  options,
  sortOptions,
  selectedMode,
  setSelectedMode,
  hideSaveDashboard,
  navigateCueParams,
}) => {
  const { appStore } = useRootStore();
  return (
    <div className={mainStyles.headerRow}>
      <div className={styles.headerWrapper}>
        <span className={mainStyles.header}>{PANELS[PANELS_TYPES.MTD_TOP_10].title}</span>
        <FilterField
          filterType={filtersFields.by}
          onSelectMultiButtonClick={() => {}}
          selectedOptions={groupBy}
          handleSelectionChange={(options) => setGroupBy([options])}
          options={options}
          height={300}
          width={215}
        />
        <FilterField
          filterType={filtersFields.sort}
          onSelectMultiButtonClick={() => {}}
          selectedOptions={orderBy}
          handleSelectionChange={(options) => setOrderBy([options])}
          options={sortOptions}
          height={260}
          width={215}
        />
      </div>
      <div className={styles.headerWrapper}>
        <div className={styles.modeTypeWrapper}>
          <Button
            key="monthly"
            text="MTD"
            className={{ button: classNames(styles.mode, selectedMode === modeSwitch.mtd.id && styles.selected) }}
            onClick={() => setSelectedMode(modeSwitch.mtd.id)}
          />
          <Button
            key={modeSwitch.daily.id}
            text={modeSwitch.daily.text}
            className={{ button: classNames(styles.mode, selectedMode === modeSwitch.daily.id && styles.selected) }}
            onClick={() => setSelectedMode(modeSwitch.daily.id)}
          />
        </div>

        {!hideSaveDashboard && (
          <>
            <NavigateIcon
              getSearchParams={() => getSearchParamsPanel(navigateCueParams, appStore.isPpApplied)}
              id={PANELS_TYPES.MTD_TOP_10}
            />
            <SaveDashboardPanel
              groupBy={groupBy}
              orderBy={orderBy}
              granLevel={selectedMode === modeSwitch.mtd.id ? 'month' : 'day'}
              defaultPeriod={
                selectedMode === modeSwitch.mtd.id ? ReportPeriodTime.THIRTY_DAYS : ReportPeriodTime.THREE_DAYS
              }
              panelType={
                selectedMode === modeSwitch.mtd.id
                  ? PANEL_COMPONENT_TYPES.MONTH_TO_DATE_TOP_5
                  : PANEL_COMPONENT_TYPES.DAILY_CHANGE_TOP_5_COSTS
              }
              forecastMonths={null}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default HeaderRow;
