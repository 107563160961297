import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Input from 'shared/components/andtComponents/Input';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import FilesDownload from 'shared/components/andtComponents/FilesDownload';
import CodeBlock from 'shared/components/andtComponents/CodeBlock';
import Instructions from 'shared/components/andtComponents/Instructions';
import {
  NEW_INVOICE_TOPIC_FILENAME,
  PILEUS_POLICY_FILENAME,
  PILEUS_ROLE_FILENAME,
  MAX_ASSUME_ROLE_SESSION_DURATION_IN_SECONDS,
} from 'app/containers/Onboarding/AwsOnboarding/utils/constants';
import { AWS_ONBOARDING_FIELDS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import CustomModal from 'shared/components/andtComponents/Modal';
import RoleImg from '../../assets/role.png';
import EventImg from './assets/Event.jpg';
import styles from './GrantAccessPage.module.scss';
import commonStyles from '../Pages.module.scss';
import { generatePolicy, generateRole, generateTopicConfiguration } from '../../utils/jsonTemplatesStringsGenerators';
import { useBrand } from '~/app/contexts/Brand/BrandContext.js';

const GrantAccessPage = ({ nextStep }) => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  useWatch(AWS_ONBOARDING_FIELDS.GRANT_ACCESS_REMINDER);
  const brand = useBrand();
  return (
    <div className={styles.container}>
      {getValues(AWS_ONBOARDING_FIELDS.GRANT_ACCESS_REMINDER) && (
        <CustomModal
          open
          title="Validate your Event Notification"
          cancelHidden
          saveTitle="Got it!"
          closeOnOutside={false}
          onClose={() => {}}
          overrideStyles={{ width: 1000 }}
          onSave={() => {
            nextStep();
          }}
        >
          <div className={styles.reminderImageBody}>
            Navigate to your <span>S3 bucket</span>, click on the <span>Properties</span> tab, scroll down to the{' '}
            <span>Event Notification</span> section, and make sure it was defined as follows:
            <img src={EventImg} alt="Event Notifcations" />
          </div>
        </CustomModal>
      )}
      <Instructions
        steps={[
          <div key="1">
            <p className={`${commonStyles.description} mb-2`}>Download the JSON files below.</p>
            <FilesDownload
              files={[
                {
                  title: 'New Invoice Topic.JSON',
                  fileName: NEW_INVOICE_TOPIC_FILENAME,
                  jsonGen: () =>
                    generateTopicConfiguration({ bucketRegionName: getValues(AWS_ONBOARDING_FIELDS.BUCKET_REGION) }),
                  nodeId: 'NewInvoiceTopicConfiguration',
                },
                {
                  title: `PileusRole.json`,
                  fileName: PILEUS_ROLE_FILENAME,
                  jsonGen: () => generateRole({ externalId: getValues(AWS_ONBOARDING_FIELDS.EXTERNAL_ID) }),
                  nodeId: 'PileusRole',
                },
                {
                  title: `PileusPolicy.json`,
                  fileName: PILEUS_POLICY_FILENAME,
                  jsonGen: () => generatePolicy({ invoiceBucketName: getValues(AWS_ONBOARDING_FIELDS.BUCKET_NAME) }),
                  nodeId: 'PileusPolicy',
                },
              ]}
            />
          </div>,
          <div key="2">
            <p className={commonStyles.description}>
              After downloading the JSON files, open the AWS CLI and copy the commands below. Make sure to run them in
              the order they are displayed here.
            </p>
            <div className={styles.codeList}>
              {}
              <CodeBlock
                code={`aws s3api put-bucket-notification-configuration --bucket ${getValues(
                  AWS_ONBOARDING_FIELDS.BUCKET_NAME,
                )} --notification-configuration file://${NEW_INVOICE_TOPIC_FILENAME}`}
              />
              <CodeBlock
                // eslint-disable-next-line max-len
                code={`aws iam create-role --role-name PileusRole --max-session-duration ${MAX_ASSUME_ROLE_SESSION_DURATION_IN_SECONDS} --assume-role-policy-document file://${PILEUS_ROLE_FILENAME}`}
              />
              <CodeBlock
                code={`aws iam put-role-policy --role-name PileusRole --policy-name PileusPolicy --policy-document file://${PILEUS_POLICY_FILENAME}`}
              />
            </div>
          </div>,
          <div key="3">
            <p className={`${commonStyles.description} mb-2`}>Enter the Role ARN from the AWS CLI command.</p>
            <div className={commonStyles.inputField}>
              <label htmlFor="onboarding-role-arn">
                <span>
                  <GenerateIcon iconName={ICONS.bucket.name} />
                  Role ARN
                </span>
              </label>
              <div className={commonStyles.inputBox}>
                <div>
                  <Input
                    placeholder={`Ex: arn:aws:iam::123456789:role/${brand.name}Role`}
                    value={getValues(AWS_ONBOARDING_FIELDS.ROLE_ARN)}
                    isInvalid={!!errors[AWS_ONBOARDING_FIELDS.ROLE_ARN]}
                    invalidComponent={errors[AWS_ONBOARDING_FIELDS.ROLE_ARN]}
                    onChange={(e) =>
                      setValue(AWS_ONBOARDING_FIELDS.ROLE_ARN, e.target.value?.trim(), { shouldValidate: true })
                    }
                    name="onboarding-role-arn"
                    type="text"
                  />
                </div>
                <InfoPopover isSimple={false} placement="right">
                  <div className={styles.infoPopoverContainer}>
                    <div className={styles.infoPopoverTitle}>How to find my ARN example</div>
                    <img src={RoleImg} alt="how to find role arn" />
                  </div>
                </InfoPopover>
              </div>
            </div>
          </div>,
        ]}
      />
    </div>
  );
};

export default GrantAccessPage;
