import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize } from 'lodash';
import CustomModal from 'shared/components/andtComponents/Modal';
import FieldFilter from 'shared/components/FieldFilter';
import Input from 'shared/components/andtComponents/Input';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import ICONS from 'shared/constants/assetsConstants';
import IconFromPng from 'shared/components/IconFromPng';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import styles from './creditModal.module.scss';
import { validateCredit } from '../creditHelpers';

const propTypes = {
  selectedCredit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  modalType: 'New' || 'Edit' || 'Clone',
  divisions: PropTypes.array,
  services: PropTypes.array,
  handleSaveCredit: PropTypes.func.isRequired,
  creditNames: PropTypes.array,
  isLoading: PropTypes.bool,
};

const initiateCredit = (selectedCredit, services, divisions) => {
  if (selectedCredit) {
    const {
      name,
      amount,
      isExcludeAwsMarketplace,
      service,
      divisionName,
      startDate,
      expirationDate,
      monthlyAmountLimit,
    } = selectedCredit;
    const customer = divisions.find((division) => division.value === divisionName);
    const serviceValues = Array.isArray(service.values) ? service.values : [service.values];
    return {
      name,
      customers: customer ? [customer] : [],
      service: {
        ...service,
        values: serviceValues.map(
          (value) =>
            services.find(({ value: servicePropsValue }) => servicePropsValue === value) || { label: value, value },
        ),
      },
      isExcludeAwsMarketplace,
      amount,
      monthlyAmountLimit,
      startDate: new Date(moment(startDate).startOf('month').format('YYYY-MM-DD')),
      expirationDate: expirationDate ? new Date(moment(expirationDate).endOf('month').format('YYYY-MM-DD')) : null,
    };
  }
  return {
    name: '',
    customers: [],
    service: {
      relation: 'include',
      values: [],
    },
    isExcludeAwsMarketplace: true,
    amount: null,
    monthlyAmountLimit: null,
    startDate: new Date(),
    expirationDate: new Date(),
  };
};

const CreditModal = ({
  selectedCredit = null,
  modalType = 'New',
  open = false,
  handleSaveCredit,
  onClose,
  divisions = [],
  services = [{ label: 'All Services', value: 'all' }],
  creditNames = [],
  isLoading = false,
}) => {
  const [credit, setCredit] = useState(initiateCredit(selectedCredit, services, divisions));
  const [creditValidationErrors, setCreditValidation] = useState(null);
  const [saveClicked, setSaveClicked] = useState(false);
  const { currencySymbol } = useUserSettingsContext();

  useEffect(() => {
    const creditValidation = validateCredit(credit, creditNames);
    setCreditValidation(creditValidation);
  }, [credit]);

  const handleSetCredit = (field, value) => {
    setCredit((prevCredit) => ({ ...prevCredit, [field]: value }));
  };

  const handleSetCreditFilterValues = (key, values) => {
    if (!values) {
      setCredit((prevCredit) => ({
        ...prevCredit,
        [key]: { ...prevCredit[key], values: [] },
      }));
      return;
    }

    let newValue;
    if (values.some(({ value }) => value === 'all') && !credit[key].values.some(({ value }) => value === 'all')) {
      newValue = [values.find(({ value }) => value === 'all')];
    } else {
      newValue = values.filter(({ value }) => value !== 'all');
    }
    setCredit((prevCredit) => ({
      ...prevCredit,
      [key]: { ...prevCredit[key], values: newValue },
    }));
  };
  const handleSetIncludeExcludeServices = (key, val) => {
    const { value } = val || {};
    setCredit((prevCredit) => ({
      ...prevCredit,
      [key]: { ...prevCredit[key], relation: value || 'include' },
    }));
  };

  const handleChangeDates = ({ startDate: start, endDate: end }) => {
    if (start) {
      handleSetCredit('startDate', start);
    }
    if (end) {
      handleSetCredit('expirationDate', end);
    }
  };

  const onSave = async () => {
    setSaveClicked(true);
    if (creditValidationErrors) {
      return;
    }
    await handleSaveCredit(credit);
  };
  const minDate = new Date(
    moment()
      .subtract(moment().date() >= 20 ? 0 : 1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD'),
  );

  const renderErrorMsg = (fieldName) =>
    saveClicked && creditValidationErrors && creditValidationErrors[fieldName] ? (
      <span className={styles.errorMsg}>{creditValidationErrors[fieldName]}</span>
    ) : null;
  return (
    <CustomModal
      open={open}
      onClose={onClose}
      title={`${modalType} Credit`}
      saveTitle="Save"
      onSave={onSave}
      saveDisabled={isLoading || (saveClicked && creditValidationErrors)}
      closeOnSave={false}
      className={{ content: styles.modal }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="d-flex flex-column">
          <div className="d-flex flex-column mb-3">
            <h5>Credit Name</h5>
            <Input
              value={credit.name}
              onChange={(e) => handleSetCredit('name', e.target.value)}
              isInvalid={saveClicked && creditValidationErrors && creditValidationErrors.name}
              invalidComponent={renderErrorMsg('name')}
              placeholder="Credit Name"
              type="text"
              automationid="credit-name"
            />
          </div>
          <div className="d-flex flex-column mb-3">
            <h5>Customers</h5>
            <FieldFilter
              type="customers"
              value={credit.customers}
              options={divisions}
              handleChange={(name, val) => handleSetCredit(name, val)}
              automationid="credit-customers"
            />
            {renderErrorMsg('customers')}
          </div>
          <div className="d-flex flex-column mb-3">
            <h5>Service</h5>
            <div className="d-flex flex-grow-1 mb-1">
              <div className="d-flex flex-column flex-grow-1 me-2">
                <FieldFilter
                  type="relation"
                  value={{
                    label: capitalize(credit.service.relation),
                    value: credit.service.relation,
                  }}
                  options={[
                    { label: 'Include', value: 'include' },
                    { label: 'Exclude', value: 'exclude' },
                  ]}
                  handleChange={(_, value) => handleSetIncludeExcludeServices('service', value)}
                  singleSelect
                  isClearable={false}
                  automationid="credit-service-relation"
                />
                {renderErrorMsg('serviceRelation')}
              </div>
              <div className={`d-flex flex-column ${styles.servicesContainer}`}>
                <FieldFilter
                  type="service"
                  value={credit.service.values}
                  options={services}
                  handleChange={(_, val) => handleSetCreditFilterValues('service', val)}
                  automationid="credit-service"
                />
                {renderErrorMsg('service')}
              </div>
            </div>
            <div className="d-flex">
              <Checkbox
                isChecked={credit.isExcludeAwsMarketplace}
                onChange={(val) => handleSetCredit('isExcludeAwsMarketplace', val)}
                text="Exclude Aws Marketplace"
                automationid="credit-exclude-aws-marketplace"
              />
            </div>
          </div>
          <div className="d-flex mb-3">
            <div className="d-flex flex-column me-4">
              <h5>Amount {currencySymbol}</h5>
              <div className={styles.amountContainer}>
                <Input
                  value={credit.amount}
                  onChange={(e) => handleSetCredit('amount', e.target.value)}
                  placeholder={`i.g 1,000${currencySymbol}`}
                  type="number"
                  name="amount"
                  automationid="credit-amount"
                />
              </div>
              {renderErrorMsg('amount')}
            </div>
            <div className="d-flex flex-column">
              <h5>Monthly Amount Limit {currencySymbol}</h5>
              <div className={styles.amountContainer}>
                <Input
                  value={credit.monthlyAmountLimit}
                  onChange={(e) => handleSetCredit('monthlyAmountLimit', e.target.value)}
                  placeholder={`i.g 1,000${currencySymbol}`}
                  type="number"
                  name="monthlyAmountLimit"
                  automationid="credit-monthly-amount-limit"
                />
              </div>
              {renderErrorMsg('monthlyAmountLimit')}
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex mb-1">
              <span className="me-1">Start - End Month</span>
              <Tooltip
                title="Credits can’t be given for the previous month after the 20th of the current month"
                arrow
                placement="top"
              >
                <div>
                  <IconFromPng size="14px" matchHeight="14px" icon={ICONS.info} />
                </div>
              </Tooltip>
            </div>
            <DatePickerFilter
              onDateChange={handleChangeDates}
              startDate={credit.startDate}
              endDate={credit.expirationDate}
              currPeriodGranLevel={GranularityLevelsTypes.GRAN_LEVEL_MONTHLY}
              isDateRangeError={false}
              minDate={minDate}
              andtLook
              forceNoMaxDate
            />
            {renderErrorMsg('startDate')}
            {renderErrorMsg('expirationDate')}
          </div>
        </div>
      )}
    </CustomModal>
  );
};

CreditModal.propTypes = propTypes;
export default CreditModal;
