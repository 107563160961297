import React from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const SimplePieChart = ({ data, height, width, dataKey, nameKey }) => {
  return (
    <div style={{ height: height || 200, width: width || 200 }}>
      <ResponsiveContainer height={'100%'} width={'100%'}>
        <PieChart>
          <Pie
            data={data}
            dataKey={dataKey}
            nameKey={nameKey}
            innerRadius="80%"
            outerRadius="100%"
            labelLine={false}
            stroke="none"
          >
            {data?.map((entry) => (
              <Cell key={`cell-${entry.value}`} fill={entry.color} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

SimplePieChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  nameKey: PropTypes.string.isRequired,
};
export default SimplePieChart;
