import React from 'react';
import classNames from 'classnames';
import { Routes } from 'shared/constants/routes.js';
import { Link } from 'react-router-dom';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { ReactComponent as CostGptIcon } from 'app/containers/MainDashboard/img/cost-gpt.svg';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import costStyles from './costPanels.module.scss';
import styles from './costGptPanel.module.scss';

const CostGptPanel = () => {
  return (
    <Tooltip
      placement="top"
      title={<span className={tooltipStyles.tooltipText}>Open CostGPT page</span>}
      arrow
      classes={{ tooltip: tooltipStyles.whiteTooltip, arrow: tooltipStyles.whiteArrow }}
    >
      <Link to={{ pathname: Routes.COST_GPT }}>
        <div className={classNames(costStyles.costPanel, styles.gbtPanelWrapper)}>
          <div className={styles.flexData}>
            <div className={styles.icon}>
              <GenerateIcon iconName={ICONS.commentDots.name} />
            </div>
            <span>Ask</span>
            <span className={styles.text}>
              Cost<span className={styles.boldText}>GPT</span>
            </span>
          </div>
          <CostGptIcon />
        </div>
      </Link>
    </Tooltip>
  );
};

export default CostGptPanel;
