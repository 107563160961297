/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { toast } from 'react-toastify';

const root = '/api/v1/msp/billing-rules/v2';

const getBillingRules = () => API.get('billings', root);
const createBillingRule = (billingRule) => API.post('billings', root, { body: billingRule });
const updateBillingRule = (billingRule) => API.put('billings', root, { body: billingRule });
const deleteBillingRule = (ruleId) => API.del('billings', `${root}/${ruleId}`);

const getBillingRuleTemplates = () => API.get('billings', `${root}/templates`);
const createBillingRuleTemplate = (template) => API.post('billings', `${root}/templates`, { body: template });
const updateBillingRuleTemplate = (template) => API.put('billings', `${root}/templates`, { body: template });
const deleteBillingRuleTemplate = (templateId) => API.del('billings', `${root}/templates/${templateId}`);

export default function useBillingRules() {
  const queryClient = useQueryClient();
  const queryKey = [apiConstants.QUERY_KEYS.BILLING_RULES];
  const queryKeyTemplates = [apiConstants.QUERY_KEYS.BILLING_RULES, 'templates'];

  return {
    getBillingRules: (options = {}) =>
      useQuery({
        queryKey,
        queryFn: getBillingRules,
        staleTime: 10 * 60 * 1000,
        meta: { errorMessage: 'Error fetching Billing Rules' },
        ...options,
      }),
    createBillingRule: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation({
        mutationFn: createBillingRule,
        onSuccess: () => {
          toast.success('Billing Rule created');
          queryClient.invalidateQueries({ queryKey });
          onSuccess();
        },
        meta: { errorMessage: 'Error creating Billing Rule' },
        ...options,
      }),
    updateBillingRule: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation({
        mutationFn: updateBillingRule,
        onSuccess: () => {
          toast.success('Billing Rule updated');
          queryClient.invalidateQueries({ queryKey });
          onSuccess();
        },
        meta: { errorMessage: 'Error updating Billing Rule' },
        ...options,
      }),
    deleteBillingRule: (options = {}) =>
      useMutation({
        mutationFn: deleteBillingRule,
        onSuccess: () => {
          toast.success('Billing Rule deleted');
          queryClient.invalidateQueries({ queryKey });
        },
        meta: { errorMessage: 'Error deleting Billing Rule' },
        ...options,
      }),
    getBillingRuleTemplates: (options = {}) =>
      useQuery({
        queryKey: queryKeyTemplates,
        queryFn: getBillingRuleTemplates,
        staleTime: 10 * 60 * 1000,
        meta: { errorMessage: 'Error fetching Billing Rule Templates' },
        ...options,
      }),
    createBillingRuleTemplate: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation({
        mutationFn: createBillingRuleTemplate,
        onSuccess: () => {
          toast.success('Billing Rule Template created');
          queryClient.invalidateQueries(queryKeyTemplates);
          onSuccess();
        },
        meta: { errorMessage: 'Error creating Billing Rule Template' },
        ...options,
      }),
    updateBillingRuleTemplate: ({ onSuccess = () => {}, ...options } = {}) =>
      useMutation({
        mutationFn: updateBillingRuleTemplate,
        onSuccess: () => {
          toast.success('Billing Rule Template updated');
          queryClient.invalidateQueries(queryKeyTemplates);
          onSuccess();
        },
        meta: { errorMessage: 'Error updating Billing Rule Template' },
        ...options,
      }),
    deleteBillingRuleTemplate: (options = {}) =>
      useMutation({
        mutationFn: deleteBillingRuleTemplate,
        onSuccess: () => {
          toast.success('Billing Rule Template deleted');
          queryClient.invalidateQueries(queryKeyTemplates);
        },
        meta: { errorMessage: 'Error deleting Billing Rule Template' },
        ...options,
      }),
  };
}
