import React, { useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { palette } from 'shared/constants/colorsConstants';
import { GranularityLevelsTypes } from 'shared/constants/appConstants';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { convertRangeToDates, DATE_RANGE_OPTIONS } from 'recommendationsNew/consts';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import moment from 'moment/moment';

import classes from './dateRangeFilter.module.scss';

const customStyles = () => ({
  container: (base) => ({
    ...base,
  }),
  control: (base, state) => ({
    ...base,
    border: '0',
    boxShadow: 'none',
    borderRadius: 6,
    backgroundColor: state.menuIsOpen ? palette.blue[200] : palette.gray[200],
    color: '#3D4C59',
    caretColor: 'transparent',
    fontSize: 16,
    minHeight: 'unset',
    lineHeight: '24px',
    '&:hover': {
      backgroundColor: palette.blue[200],
      cursor: 'pointer',
    },
  }),
  valueContainer: (base) => ({
    ...base,
    width: '100%',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none', // Set display to 'none' to hide the separator
  }),
  menu: (base) => ({
    ...base,
    border: 0,
    boxShadow: '0 4px 12px -2px rgba(0,0,36,0.25)',
    borderRadius: 6,
    margin: '3px 0',
    padding: 5,
    position: 'fixed',
    top: 'unset',
    width: 275,
  }),
  menuList: (base) => ({
    ...base,
    maxHeight: 'none',
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? palette.gray[100] : palette.white[500],
    color: state.isSelected ? palette.blue[500] : palette.gray[500],
    fontSize: 14,
    fontWeight: 400,
    padding: '8px 12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: state.isSelected ? palette.gray[100] : palette.blue[100],
    },
  }),
});

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>{children}</components.ValueContainer>
);
ValueContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const IndicatorsContainer = (props) => (
  <div>
    <components.IndicatorsContainer {...props}>
      <GenerateIcon iconName={ICONS.caretDown.name} className={classes.icon} />
    </components.IndicatorsContainer>
  </div>
);

const Option = ({ label, ...props }) => {
  const getInitialValue = () => {
    if (label === DATE_RANGE_OPTIONS.CUSTOM.label) {
      const customOption = props?.options?.find((option) => option.value === DATE_RANGE_OPTIONS.CUSTOM.value);
      if (customOption?.dateRange) {
        return customOption?.dateRange;
      }
    }
    return convertRangeToDates(DATE_RANGE_OPTIONS.LAST_12_MONTHS);
  };
  const selectedCustomDateRef = useRef(getInitialValue());

  const datePickerChangeDate = ({ startDate, endDate }) => {
    selectedCustomDateRef.current = {
      from: moment(startDate).format('YYYY-MM-DD'),
      to: moment(endDate).format('YYYY-MM-DD'),
    };
    props.selectOption({ ...props.data, selectedCustomDate: selectedCustomDateRef.current }, props);
  };

  function onClick() {
    props.selectOption({ ...props.data, selectedCustomDate: selectedCustomDateRef.current }, props);
  }

  const tooltip = props?.options?.find((option) => option.label === label)?.tooltip;
  return (
    <components.Option {...props} onClick={onClick}>
      <div className={classes.optionContainer} onClick={onClick}>
        <div className={classes.option}>
          <Tooltip placement="top" title={tooltip} arrow>
            <div>{label}</div>
          </Tooltip>
          {props.isSelected ? <GenerateIcon iconName={ICONS.check.name} className={classes.icon} /> : <div />}
        </div>
        {label === DATE_RANGE_OPTIONS.CUSTOM.label ? (
          <DatePickerFilter
            andtLook
            onDateChange={datePickerChangeDate}
            startDate={selectedCustomDateRef?.current?.from}
            endDate={selectedCustomDateRef?.current?.to}
            currPeriodGranLevel={GranularityLevelsTypes.GRAN_LEVEL_DAILY}
            isDateRangeError={false}
            forceNoMaxDate
            isPopover
            placement="right-start"
            isDateColumnView
          />
        ) : null}
      </div>
    </components.Option>
  );
};
Option.propTypes = {
  label: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  options: PropTypes.array.isRequired,
  selectOption: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export const DateRangeDropDown = ({ onPeriodChange, selected }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const options = useMemo(() => {
    const tmpOptions = { ...DATE_RANGE_OPTIONS };
    if (selected?.isCustom || (selected && !selected.range)) {
      tmpOptions.CUSTOM.dateRange = {
        from: selected.from,
        to: selected.to,
      };
    }
    return Object.values(tmpOptions);
  }, [selected]);

  const getSelectedDateRange = (selected) => {
    if (!selected) {
      return 'Selected...';
    }
    if (selected?.range) {
      return selected.range.value;
    }
    return DATE_RANGE_OPTIONS.CUSTOM.value;
  };

  const [selectedValue, setSelectedValue] = useState(getSelectedDateRange(selected));

  const selectedOption = selected?.isCustom
    ? DATE_RANGE_OPTIONS.CUSTOM
    : options?.find((option) => option.value === selectedValue);

  const onChange = (selectedOption) => {
    if (selectedOption.value === DATE_RANGE_OPTIONS.CUSTOM.value && selectedOption.selectedCustomDate === undefined) {
      return;
    }

    setSelectedValue(selectedOption.value);
    let result;
    if (selectedOption.value === DATE_RANGE_OPTIONS.CUSTOM.value) {
      result = { ...selectedOption.selectedCustomDate, isCustom: true, range: DATE_RANGE_OPTIONS.CUSTOM };
    } else {
      result = convertRangeToDates(selectedOption);
    }
    onPeriodChange(result);
  };

  const handleMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  return (
    <Select
      value={selectedOption}
      styles={customStyles()}
      closeMenuOnSelect={false}
      components={{ ValueContainer, IndicatorsContainer, Option }}
      isSearchable
      name="dateRangeFilter"
      options={options}
      onChange={onChange}
      menuIsOpen={isMenuOpen}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
    />
  );
};

DateRangeDropDown.propTypes = {
  onPeriodChange: PropTypes.func.isRequired,
  selected: PropTypes.object,
};
