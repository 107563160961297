import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { ReactComponent as CaretRightIcon } from 'shared/img/icons/caret-right.svg';
import InfoIconSVG from './assets/InfoIconSVG';

import styles from './ListWithHelp.module.scss';

interface ListItem {
  key: string;
  render: (setActiveHelp: () => void, isActive: boolean) => React.ReactNode;
  disabled?: boolean;
  topSpace?: number;
}

interface HelpItem {
  component: React.ComponentType;
  image?: string;
  imageHeight?: number | string;
  imageOpenWidth?: number | string;
}

interface StylesProps {
  listGap?: number;
  helpWidth?: number;
  hideControl?: boolean;
  controlStyles?: React.CSSProperties;
}

interface ListWithHelpProps {
  list: ListItem[];
  title?: string;
  listDescription?: React.ReactNode;
  help: Record<string, HelpItem>;
  styles: StylesProps;
}

interface InfoIconProps {
  onClick: () => void;
  isActive: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const ListWithHelp: React.FC<ListWithHelpProps> & { InfoIcon: React.FC<InfoIconProps> } = ({
  list,
  title = 'Help',
  listDescription = null,
  help,
  styles: { listGap, helpWidth, hideControl, controlStyles = {} },
}) => {
  const [activeHelp, setActiveHelp] = useState<string>(list.filter((i) => i.key && !i.disabled)[0].key);
  const HelpComponent = help[activeHelp].component;
  const helpImage = help[activeHelp].image;
  const [imageOpen, setImageOpen] = useState<boolean>(false);

  return (
    <div
      className={styles.container}
      data-wide={!!hideControl}
      style={{ gridTemplateColumns: `1fr ${helpWidth ? `${helpWidth}px` : '1fr'}` }}
    >
      {imageOpen &&
        createPortal(
          <>
            <div
              className={styles.imageModalBg}
              onClick={() => setImageOpen(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  setImageOpen(false);
                }
              }}
              role="button"
              tabIndex={0}
            />
            <div
              className={styles.imageModal}
              style={{ width: help[activeHelp].imageOpenWidth }}
              onClick={() => setImageOpen(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  setImageOpen(false);
                }
              }}
              role="button"
              tabIndex={0}
            >
              <img src={helpImage} alt="help" />
            </div>
          </>,
          document.getElementById('root')!,
        )}
      <div className={styles.list} style={{ gap: listGap }}>
        {listDescription}
        {list
          .filter((item) => !item.disabled)
          .map((item, index) => (
            <div
              key={`${item.key ?? ''}-${index}`}
              onClick={() => (!help[item.key] ? undefined : setActiveHelp(item.key))}
              onKeyDown={(e) => {
                if ((e.key === 'Enter' || e.key === ' ') && help[item.key]) {
                  e.preventDefault();
                  setActiveHelp(item.key);
                }
              }}
              role="button"
              tabIndex={0}
              className={styles.listItem}
            >
              {item.render(() => setActiveHelp(item.key), activeHelp === item.key)}
              {!hideControl && help[item.key] && (
                <div className={styles.lineControl} style={{ top: item.topSpace }}>
                  <ListWithHelp.InfoIcon
                    style={controlStyles}
                    onClick={() => setActiveHelp(item.key)}
                    isActive={activeHelp === item.key}
                  />
                  {activeHelp === item.key && <CaretRightIcon />}
                </div>
              )}
            </div>
          ))}
      </div>
      <div className={styles.helpContent}>
        <div className={styles.helpTitle}>
          <InfoIconSVG /> {title}
        </div>
        {HelpComponent && <HelpComponent />}
        {helpImage && (
          <div className={styles.helpImage}>
            <Button
              className={{ button: styles.zoomButton }}
              text=""
              isTextButton
              onClick={() => setImageOpen(true)}
              icon={() => <GenerateIcon iconName={ICONS.magnifyingGlassPlus.name} />}
            />
            <div
              className={styles.image}
              style={{ backgroundImage: `url(${helpImage})`, height: help[activeHelp].imageHeight }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const InfoIcon: React.FC<InfoIconProps> = ({ onClick, isActive, className = '', style = {} }) => (
  <div
    className={`${styles.infoIcon} ${className}`}
    style={style}
    onClick={onClick}
    onKeyDown={(e) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onClick();
      }
    }}
    role="button"
    tabIndex={0}
    data-active={isActive}
  >
    <InfoIconSVG />
  </div>
);

ListWithHelp.InfoIcon = InfoIcon;

export default ListWithHelp;
