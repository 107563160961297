import React from 'react';

export const getOnboardingError = ({ code, brand }) =>
  ({
    // app-server errors
    APP_REG_PARAMS_WRONG: 'The Application details are incorrect, please update it.',
    // airflow errors (key should match airflow error)
    ERR_HANDSHAKE_AZURE_BLOBS_REQUEST: 'ERR_HANDSHAKE_AZURE_BLOBS_REQUEST',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST: 'ERR_HANDSHAKE_AZURE_EA_API_REQUEST',
    ERR_HANDSHAKE_AZURE_CSP_API_REQUEST: 'ERR_HANDSHAKE_AZURE_CSP_API_REQUEST',
    ERR_HANDSHAKE_AZURE_STORAGE_NOT_AVAILABLE: `The Storage Account was not found. Please verify the Storage Account name, update it in the 'Billing Export' step, and continue with the onboarding.`,
    ERR_HANDSHAKE_AZURE_CONTAINER_NOT_FOUND: `The Container was not found. Please verify the Container name, update it in the 'Billing Export' step, and continue with the onboarding.`,
    ERR_HANDSHAKE_AZURE_CLIENT_AUTH: (
      <>
        Missing permissions prevent us from listing your containers or accessing blobs in your container. Please ensure
        that the 'Storage Blob Data Reader' permission is granted to your Storage Account/Container and that the
        application you created for {brand.name} is associated with it. Once fixed, continue with the onboarding steps.
      </>
    ),
    ERR_HANDSHAKE_AZURE_EXPORT_REQUEST_ERROR: `We are unable to receive data from Azure and cannot determine the exact cause. Please ensure that all values entered in the onboarding steps are correct and that you have completed all required steps on the Azure side.`,
    ERR_HANDSHAKE_AZURE_CRED_UNAVAILABLE: `The application details provided in the "App Registration" step of the onboarding are incorrect. Please verify and update them in the same step, then continue with the onboarding.`,
    ERR_HANDSHAKE_AZURE_EXPORT_PERMISSION_ERROR: (
      <>
        The validation failed due to missing permissions. Please ensure that the 'Storage Blob Data Reader' permission
        is granted to your Storage Account/Container and that the application you created for {brand.name} is associated
        with it. Once fixed, continue with the onboarding steps.
      </>
    ),

    ERR_HANDSHAKE_FLAG_NOT_RECEIVED: (
      <>
        Your onboarding session has expired (24-hour time limit).
        <br />
        To continue:
        <br />
        <ol>
          <li>Click the Restart button.</li>
          <li>Verify and enter all required information.</li>
          <li>Start the validation process.</li>
        </ol>
      </>
    ),

    // legacy codes
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_UNKNOWN:
      'Azure EA API request returns an empty response. Please check the onboarding on the Azure side.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_UNAUTHORIZED:
      'Authentication credentials for Azure EA API requests are invalid, or the credentials provided' +
      ' are not authorized to access the resource. ' +
      'Please check the onboarding on the Azure side and the parameters you entered in step #2 (EA Account Details).',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_FORBIDDEN:
      'Access to the enrollment information has been denied or refused due to insufficient permissions. ' +
      'Please check the onboarding on the Azure side.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_NOT_FOUND:
      'The enrollment ID was not found on the Azure side, please validate the enrollment ID, update it ' +
      'in step #2 (EA Account Details), and click Next.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_SERVER_ERROR:
      'There was an unexpected error by the server while processing the request on the Azure side.' +
      ' We will retry the request.',
    ERR_HANDSHAKE_AZURE_EA_API_REQUEST_UNAVAILABLE:
      'The server is currently unable to' +
      ' handle the request due to temporary overloading or maintenance of' +
      ' the server on the Azure side.  We will retry the request.',
  }[code]);
