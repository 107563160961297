import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { API } from 'shared/utils/apiMiddleware';
import { transformFiltersContextToApiFormat } from 'recommendationsNew/hooks/react-query/apiUtils';
import { BILLINGS_API_NAME, GROUP_BY_OPTIONS, RECOMMENDATIONS_LIST_ROOT, STALE_TIME } from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import toast from 'shared/components/andtComponents/Toast';

const getSortColumnParam = (column) => {
  if (column.isRecData) {
    return {
      by: 'rec_data',
      rec_data_column: column.columnName,
      order: column.direction,
    };
  }
  return {
    by:
      column.columnName === 'linkedAccount'
        ? 'linked_account_name'
        : column.columnName?.replace(/([A-Z])/g, '_$1').toLowerCase(),
    order: column.direction,
  };
};
const getRecommendationsParams = ({
  filters,
  tableFilters,
  groupBy,
  sortBy,
  lastItem,
  firstItem,
  offset,
  pageSize,
  searchText,
}) => {
  const transformedFilters = transformFiltersContextToApiFormat(filters);
  // the data in the backend except to get the column name with separate line between the words
  // linkedAccount column key is change in the backend between filters and sortBy
  // so in sort send manually the name of linkAccount column
  const sortColumns = sortBy?.length ? sortBy.map((s) => getSortColumnParam(s)) : [{ by: 'savings', order: 'desc' }];
  return {
    filters: transformedFilters || {},
    table_filters: tableFilters || {},
    group_by: groupBy || [GROUP_BY_OPTIONS.items.TYPE_ID.id],
    sort: sortColumns,
    page_size: pageSize,
    pagination_token: lastItem,
    backward_pagination_token: firstItem,
    offset,
    search_expression: searchText || null,
  };
};
function fetchRecommendations({
  filters,
  tableFilters,
  groupBy,
  sortBy,
  lastItem,
  firstItem,
  offset,
  pageSize,
  searchText,
}) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_LIST_ROOT}`, {
    body: getRecommendationsParams({
      filters,
      tableFilters,
      groupBy,
      sortBy,
      lastItem,
      firstItem,
      offset,
      pageSize,
      searchText,
    }),
  });
}
function fetchFullRecommendations({ filters, tableFilters, groupBy, sortBy, offset, pageSize, searchText }) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_LIST_ROOT}/full`, {
    body: getRecommendationsParams({
      filters,
      tableFilters,
      groupBy,
      sortBy,
      lastItem: null,
      firstItem: null,
      offset,
      pageSize,
      searchText,
    }),
  });
}

function fetchRecommendationColumns({ filters }) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_LIST_ROOT}/columns`, {
    body: {
      filters: transformFiltersContextToApiFormat(filters),
    },
  });
}

function fetchRecommendationsTotal({ filters }) {
  return API.post(BILLINGS_API_NAME, `${RECOMMENDATIONS_LIST_ROOT}/total`, {
    body: {
      filters: transformFiltersContextToApiFormat(filters),
    },
  });
}

export default function useRecsList() {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [
    apiConstants.QUERY_KEYS.RECOMMENDATIONS,
    apiConstants.QUERY_KEYS.RECOMMENDATIONS_LIST,
    userAccountKey,
  ];
  const fullRecsQueryKey = [apiConstants.QUERY_KEYS.FULL_RECOMMENDATIONS_LIST];

  return {
    invalidate: ({ filters, groupBy, sortBy }) =>
      queryClient.invalidateQueries({ queryKey: [...queryKey, filters, groupBy, sortBy] }),
    reset: ({ filters, groupBy, sortBy }, options) =>
      queryClient.resetQueries({ queryKey: [...queryKey, filters, groupBy, sortBy], ...filters }, options),
    fetchRecommendations: (
      { filters, tableFilters, groupBy, sortBy, lastItem, firstItem, offset, pageSize, pageNumber, searchText },
      success,
    ) =>
      useQuery({
        queryKey: [
          ...queryKey,
          filters,
          groupBy,
          sortBy,
          lastItem,
          firstItem,
          pageNumber,
          searchText,
          pageSize,
          offset,
          tableFilters,
        ],
        queryFn: () =>
          fetchRecommendations({
            filters,
            tableFilters,
            groupBy,
            sortBy,
            lastItem,
            firstItem,
            offset,
            pageSize,
            searchText,
          }),
        onSuccess: () => {
          if (success) {
            success();
          }
        },
        onError: () => {
          toast.error('Operation has failed');
        },
        enabled: !!filters,
        retry: false,
        staleTime: STALE_TIME,
      }),
    fetchFullRecommendations: ({ filters, tableFilters, groupBy, sortBy, offset, pageSize, pageNumber, searchText }) =>
      useQuery({
        queryKey: [
          ...fullRecsQueryKey,
          filters,
          groupBy,
          sortBy,
          pageNumber,
          searchText,
          pageSize,
          offset,
          tableFilters,
        ],
        queryFn: () =>
          fetchFullRecommendations({
            filters,
            tableFilters,
            groupBy,
            sortBy,
            offset,
            pageSize,
            searchText,
          }),
        enabled: false,
        retry: false,
        staleTime: STALE_TIME,
      }),
    fetchRecommendationColumns: ({ filters }) =>
      useQuery({
        queryKey: [...queryKey, filters],
        queryFn: () => fetchRecommendationColumns({ filters }),
        enabled: !!filters,
        retry: false,
        staleTime: STALE_TIME,
      }),
    fetchRecommendationsTotal: ({ filters }) =>
      useQuery({
        queryKey: [...queryKey, filters],
        queryFn: () => fetchRecommendationsTotal({ filters }),
        enabled: !!filters,
        retry: false,
        staleTime: STALE_TIME,
      }),
  };
}
