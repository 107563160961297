import React, { useCallback, useState } from 'react';
import CustomModal from 'shared/components/andtComponents/Modal';
import { Form, Input } from 'reactstrap';
import { validateRecipients } from 'shared/utils/sharedUtils.js';
import t from '../texts.ts';
import styles from '../alerts.module.scss';
import { hideCustomerNameField } from './index.tsx';
import RecipientsSelect, { Channel, Recipient } from 'shared/components/RecipientsSelect';
import { getAppSelectStyles } from 'shared/components/AppSelect/index.tsx';

export interface CommitmentModalProps {
  onClose(): void;

  onUpdate(days: number, emails: string, recipients: Recipient[], uuid?: string): void;

  uuid?: string;
  isEdit?: boolean;
  daysBeforeExpiry?: number;
  recipientsEmails?: string;
  recipients?: Recipient[];
  channels?: Channel[]
}

const DEFAULT_DAYS = 1;

export const UpdateModal: React.FC<CommitmentModalProps> = ({
  onClose,
  isEdit,
  daysBeforeExpiry,
  recipientsEmails,
  recipients,
  onUpdate,
  uuid,
  channels
}) => {
  const [days, setDays] = useState(daysBeforeExpiry || DEFAULT_DAYS);
  const [emails, setEmails] = useState(recipientsEmails || '');
  const [selectedRecipients, setSelectedRecipients] = useState(recipients || []);
  const [showErrors, setShowErrors] = useState(false);
  const isDaysValid = days >= DEFAULT_DAYS;
  const isValidRecipients = validateRecipients(emails, selectedRecipients);

  const onSave = useCallback(
    (e) => {
      if (isValidRecipients && isDaysValid) {
        onUpdate(days, emails, selectedRecipients, uuid);
      } else {
        e.preventDefault();
        e.stopPropagation();
        setShowErrors(true);
      }
    },
    [onUpdate, days, emails, selectedRecipients, uuid],
  );
  const automationPostfix = isEdit ? '-edit' : '-create';
  return (
    <CustomModal
      onClose={onClose}
      onCloseClick={onClose}
      headerMode={isEdit ? 'edit' : 'add'}
      onSave={onSave}
      open
      closeOnSave={false}
      saveTitle={isEdit ? t('SAVE') : t('CREATE')}
      saveDisabled={showErrors && (!isDaysValid || !isValidRecipients)}
      title={`${isEdit ? t('EDIT') : t('CREATE')} ${t('CUD_Alert')}`}
      automationid="update-alert-modal"
    >
      <Form automation-id={`form${automationPostfix}`}>
        <div className="mt-1">
          <h5>{t('DAYS_BEFORE_CUD_EXPIRES')}</h5>
          <Input
            automation-id={`days-before-field${automationPostfix}`}
            value={days}
            onChange={(e) => {
              setDays(Number(e.target.value));
              setShowErrors(false);
            }}
            type="number"
            variant="outlined"
            placeholder="Name"
            className={styles.alertsInput}
            min={DEFAULT_DAYS}
            invalid={showErrors && !isDaysValid}
          />
        </div>
        <div className="mt-4">
          <div className="d-flex justify-content-between">
            <h5>{t('RECIPIENTS')}</h5>
          </div>
          <RecipientsSelect
            className="mt-2"
            channels={channels}
            savedRecipients={selectedRecipients}
            onChangeHandler={(selected) => {
              setSelectedRecipients(Array.isArray(selected) ? selected : [selected]);
            }}
            selectStylesConfig={getAppSelectStyles({
              disabled: false,
              isEmpty: !selectedRecipients,
              height: 35,
            })}
            isDisabled={false}
          />
          {!hideCustomerNameField && (
            <div className={'mt-2'}>
              <h5>{t('CUSTOMER_NAME')}</h5>
              <Input
                automation-id={`recipient-field${automationPostfix}`}
                onChange={(e) => {
                  setEmails(e.target.value);
                  setShowErrors(false);
                }}
                value={emails}
                variant="outlined"
                placeholder={`${t('EMAIL_PLACEHOLDER')}, ${t('EMAIL_PLACEHOLDER')}`}
                className={styles.alertsInput}
                invalid={showErrors && !isValidRecipients}
              />
            </div>
          )}
        </div>
      </Form>
    </CustomModal>
  );
};

export default UpdateModal;
