import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { v4 } from 'uuid';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import Spinner from 'shared/components/andtComponents/Spinner';
import { Routes } from 'shared/constants/routes';
import {
  anomalyDetectionProvider,
  anomaliesAlertRulesProvider,
} from 'usage/containers/AnomalyDetection/hooks/react-query/useAnomalyDetection';
import { propComparator } from 'shared/utils/sortUtil';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import useStateParams from 'shared/hooks/customHooks/useStateParams';
import { AwsCommonFields, serviceNameToDisplayNameWithShortName } from 'shared/constants/awsConstants';
import { formatDateToQueryString } from 'shared/utils/strUtil';
import { useDivisions } from 'shared/hooks/react-query/useDivisions';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import highchartsInit from 'usage/containers/AnomalyDetection/components/AnomalyChart/highchartsInit';
import { FILTER_PARAMS_KEYS } from './anomalyDetectionConstants';
import { getDivisionsForFilter, getMapLinkedAccountIdToName, getAlertRuleRows } from './anomalyDetectionHelpers';
import AnomaliesTable from './components/AnomalyDetectionTable';
import ActionsBar from './components/ActionsBar';
import AlertRulesListModal from './components/AlertRulesListModal';
import AddAlertRuleModal from './components/AddAlertRuleModal';
import WarningModal from './components/WarningModalNew';
import styles from './anomalyDetection.module.scss';

highchartsInit();

const AnomalyDetection = ({ filtersValuesMap }) => {
  const { usersStore, appStore } = useRootStore();
  // Pagination
  const [nextPageKeys, setNextPageKeys] = useState([]);
  const [alertedNextPageKeys, setAlertedNextPageKeys] = useState([]);
  const [currPageIdx, setCurrPageIdx] = useState(0);
  const [alertedCurrPageIdx, setAlertedCurrPageIdx] = useState(0);
  const [totalPages, setTotalPages] = useState(null);
  const [alertedTotalPages, setAlertedTotalPages] = useState(null);

  const [showAlerts, setShowAlerts] = useStateParams({
    initialState: false,
    paramsName: 'showAlerts',
    serialize: (s) => (s ? 'true' : 'false'),
    deserialize: (s) => s === 'true',
  });
  const [startDate, setStartDate] = useStateParams({
    initialState: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
    paramsName: FILTER_PARAMS_KEYS.START_DATE,
    serialize: (s) => formatDateToQueryString(s),
    deserialize: (s) => formatDateToQueryString(s),
  });
  const [endDate, setEndDate] = useStateParams({
    initialState: moment().format('YYYY-MM-DD'),
    paramsName: FILTER_PARAMS_KEYS.END_DATE,
    serialize: (s) => formatDateToQueryString(s),
    deserialize: (s) => formatDateToQueryString(s),
  });
  const [searchInput, setSearchInput] = useStateParams({
    initialState: '',
    paramsName: FILTER_PARAMS_KEYS.SEARCH,
    serialize: (s) => s,
    deserialize: (s) => s,
  });
  const [isAlertRulesListOpen, setIsAlertRulesListOpen] = useState(false);
  const [isAddAlertRuleModalOpen, setIsAddAlertRuleModalOpen] = useState(false);
  const [isEditAlertRuleWarningModalOpen, setIsEditAlertRuleWarningModalOpen] = useState(false);
  const [alertRuleToDelete, setAlertRuleToDelete] = useState(false);
  const [alertRuleToEdit, setAlertRuleToEdit] = useState(false);

  const { getDivisions } = useDivisions();
  const { data: divisionsData = [], isLoading: isDivisionsLoading } = getDivisions();
  const { fetchAnomalies } = anomalyDetectionProvider(
    {
      [FILTER_PARAMS_KEYS.START_DATE]: startDate,
      [FILTER_PARAMS_KEYS.END_DATE]: endDate,
      [FILTER_PARAMS_KEYS.SEARCH]: searchInput,
      [FILTER_PARAMS_KEYS.EXCLUSIVE_START_KEY]: nextPageKeys[currPageIdx - 1],
    },
    appStore.isPpApplied,
  );
  const { data: { anomalies = [], lastEvaluatedKey } = {}, isLoading: isAnomaliesLoading } = fetchAnomalies();

  const { fetchAnomaliesCount } = anomalyDetectionProvider(
    {
      [FILTER_PARAMS_KEYS.START_DATE]: startDate,
      [FILTER_PARAMS_KEYS.END_DATE]: endDate,
      [FILTER_PARAMS_KEYS.SEARCH]: searchInput,
    },
    appStore.isPpApplied,
    true,
  );
  const { data: anomaliesCount } = fetchAnomaliesCount();
  useEffect(() => {
    setTotalPages(anomaliesCount?.count);
  }, [anomaliesCount]);

  useEffect(() => {
    if (lastEvaluatedKey) {
      setNextPageKeys([...nextPageKeys, lastEvaluatedKey]);
    } else if (!isAnomaliesLoading) {
      setTotalPages(nextPageKeys.length + 1);
    }
  }, [lastEvaluatedKey, isAnomaliesLoading]);

  const { fetchAnomalies: fetchAnomaliesAlerts } = anomalyDetectionProvider(
    {
      [FILTER_PARAMS_KEYS.START_DATE]: startDate,
      [FILTER_PARAMS_KEYS.END_DATE]: endDate,
      [FILTER_PARAMS_KEYS.SEARCH]: searchInput,
      [FILTER_PARAMS_KEYS.ALERTED]: true,
      [FILTER_PARAMS_KEYS.EXCLUSIVE_START_KEY]: alertedNextPageKeys[alertedCurrPageIdx - 1],
    },
    appStore.isPpApplied,
  );
  const { data: { anomalies: alertedAnomalies = [] } = {}, isLoading: isAlertedAnomaliesLoading } =
    fetchAnomaliesAlerts({
      onSuccess: ({ lastEvaluatedKey }) => {
        if (lastEvaluatedKey) {
          setAlertedNextPageKeys([...alertedNextPageKeys, lastEvaluatedKey]);
        } else {
          setAlertedTotalPages(alertedNextPageKeys.length + 1);
        }
      },
    });

  const anomaliesAlertRulesHook = anomaliesAlertRulesProvider(appStore.isPpApplied);
  const {
    data: alertRules = [],
    isLoading: isAlertRulesLoading,
    refetch: refetchAlertRules,
  } = anomaliesAlertRulesHook.fetchAlertRules(appStore.isPpApplied);

  const { mutateAsync: mutationAddAlertRule, isLoading: isAddingAlertRule } = anomaliesAlertRulesHook.createAlert({
    onSuccess: () => {
      setIsAddAlertRuleModalOpen(false);
      refetchAlertRules();
    },
  });
  const { mutateAsync: mutationUpdateAlertRule, isLoading: isUpdatingAlertRule } = anomaliesAlertRulesHook.updateAlert({
    onSuccess: () => {
      setIsAddAlertRuleModalOpen(false);
      setAlertRuleToEdit(null);
      refetchAlertRules();
    },
  });
  const { mutateAsync: mutationDeleteAlertRule, isLoading: isDeletingAlertRule } = anomaliesAlertRulesHook.deleteAlert({
    onSuccess: () => {
      refetchAlertRules();
    },
  });

  const resetPagination = () => {
    setCurrPageIdx(0);
    setAlertedCurrPageIdx(0);
    setNextPageKeys([]);
    setAlertedNextPageKeys([]);
    setTotalPages(null);
    setAlertedTotalPages(null);
  };

  const onPageChange = (newPageIdx) => {
    if (showAlerts) {
      if (newPageIdx > alertedNextPageKeys.length) {
        return;
      }
      setAlertedCurrPageIdx(newPageIdx);
      if (newPageIdx < alertedCurrPageIdx) {
        setAlertedNextPageKeys(alertedNextPageKeys.slice(0, -1));
      }
    } else {
      if (newPageIdx > nextPageKeys.length) {
        return;
      }
      setCurrPageIdx(newPageIdx);
      if (newPageIdx < currPageIdx) {
        setNextPageKeys(nextPageKeys.slice(0, -1));
      }
    }
  };

  const getServicesOptions = (services, cloudTypeId) => {
    const values = [...services].filter(Boolean);
    if (cloudTypeId === CLOUD_TYPE_IDS.AWS) {
      values.push(...['AWS Support [Business]', 'AWS Support [Developer]', 'AWS Premium Support']);
    }
    const options = values.map((value) => ({
      label: serviceNameToDisplayNameWithShortName.get(value) || value,
      value,
    }));
    if (options.length) {
      options.sort(propComparator('label'));
    }
    return options;
  };

  const accountTags = getServicesOptions(
    filtersValuesMap.get(AwsCommonFields.ACCOUNT_TAGS_KEYS) || [],
    usersStore.currDispUserCloudAccountType,
  );

  const services = getServicesOptions(
    filtersValuesMap.get(AwsCommonFields.SERVICE) || [],
    usersStore.currDispUserCloudAccountType,
  );
  const divisions = getDivisionsForFilter(divisionsData);
  const linkedAccounts = (filtersValuesMap.get(AwsCommonFields.LINKED_ACCOUNT_NAME) || []).map((linkedAccount) => ({
    value: linkedAccount.linkedAccountId,
    label: linkedAccount.displayLabel,
    name: linkedAccount.linkedAccountName,
  }));

  const mapLinkedAccountIdToName = getMapLinkedAccountIdToName(linkedAccounts || []);
  const alertRulesRows = getAlertRuleRows(alertRules, mapLinkedAccountIdToName);
  const onSearchChanged = (input) => {
    resetPagination();
    setSearchInput(input);
  };
  const onDateChange = (dates) => {
    resetPagination();
    if (dates[FILTER_PARAMS_KEYS.START_DATE] !== startDate) {
      setStartDate(dates[FILTER_PARAMS_KEYS.START_DATE]);
    }
    if (dates[FILTER_PARAMS_KEYS.END_DATE] !== endDate) {
      setEndDate(dates[FILTER_PARAMS_KEYS.END_DATE]);
    }
  };

  const openAlertRulesListModal = () => setIsAlertRulesListOpen(true);
  const closeAlertRulesListModal = () => setIsAlertRulesListOpen(false);

  const openAddAlertRuleModal = () => setIsAddAlertRuleModalOpen(true);
  const closeAddAlertRuleModal = () => {
    setAlertRuleToEdit(null);
    setIsAddAlertRuleModalOpen(false);
  };

  const saveAlertRule = async (alertRule) => {
    try {
      if (alertRuleToEdit) {
        setIsEditAlertRuleWarningModalOpen(true);
        setAlertRuleToEdit(alertRule);
      } else {
        await mutationAddAlertRule({ uuid: alertRule.uuid || v4(), ...alertRule });
      }
    } catch {
      // TODO - add error handling
    }
  };
  const deleteAlertRule = async (action) => {
    try {
      if (action === 'cancel') {
        setAlertRuleToDelete(null);
      } else if (action === 'delete') {
        await mutationDeleteAlertRule(alertRuleToDelete);
        setAlertRuleToDelete(null);
      }
    } catch {
      // TODO - add error handling
    }
  };
  const editAlertRule = async (action) => {
    try {
      if (action === 'cancel') {
        setIsEditAlertRuleWarningModalOpen(false);
      } else if (action === 'delete') {
        await mutationUpdateAlertRule(alertRuleToEdit);
        setIsEditAlertRuleWarningModalOpen(false);
      }
    } catch {
      setIsEditAlertRuleWarningModalOpen(false);
      // TODO - add error handling
    }
  };
  const onDeleteAlertRule = async (alertRuleId) => setAlertRuleToDelete(alertRuleId);
  const onEditAlertRule = async (alertRuleId) => {
    const alertRule = alertRules.find(({ uuid }) => uuid === alertRuleId);
    if (alertRule) {
      setAlertRuleToEdit(alertRule);
      setIsAddAlertRuleModalOpen(true);
    }
  };

  const isLoading = isDivisionsLoading || (showAlerts ? isAlertedAnomaliesLoading : isAnomaliesLoading);

  const renderPageContent = () => (
    <>
      <ActionsBar
        onSearchChanged={onSearchChanged}
        onDateChange={onDateChange}
        anomaliesFilterParams={{
          [FILTER_PARAMS_KEYS.START_DATE]: startDate,
          [FILTER_PARAMS_KEYS.END_DATE]: endDate,
          [FILTER_PARAMS_KEYS.SEARCH]: searchInput,
        }}
        alertRulesCounter={(alertRules || []).length}
        openAlertRulesListModal={openAlertRulesListModal}
        openAddAlertRuleModal={openAddAlertRuleModal}
        isAlerted={showAlerts}
        setIsAlerted={(showAlertsValue) => setShowAlerts(showAlertsValue)}
        isAlertRulesLoading={isAlertRulesLoading}
        mapLinkedAccIdToDivisionName={divisionsData.mapLinkedAccIdToDivisionName || new Map()}
        isPpApplied={appStore.isPpApplied}
        currPageIdx={showAlerts ? alertedCurrPageIdx : currPageIdx}
        onPageChange={onPageChange}
        totalPages={showAlerts ? alertedTotalPages : totalPages}
        isNextPageLoading={
          showAlerts
            ? isAlertedAnomaliesLoading ||
              (alertedCurrPageIdx >= alertedNextPageKeys.length && alertedNextPageKeys.length + 1 !== alertedTotalPages)
            : isAnomaliesLoading || (currPageIdx >= nextPageKeys.length && nextPageKeys.length + 1 !== totalPages)
        }
      />
      {isLoading ? (
        <Spinner />
      ) : (
        <AnomaliesTable
          exploreRoute={Routes.COST_USAGE_EXPLORER}
          rows={(showAlerts ? alertedAnomalies : anomalies) || []}
          showAlerts={showAlerts}
          mapLinkedAccIdToDivisionName={divisionsData.mapLinkedAccIdToDivisionName || new Map()}
          userAccounts={usersStore.getAllCurrDisplayedUserAccounts()}
          alertRuleIds={(alertRules || []).map(({ uuid }) => uuid)}
          userType={usersStore.deprecatedGetCurrentDisplayedUserType}
          openAddAlertRuleModal={openAddAlertRuleModal}
        />
      )}
    </>
  );

  return (
    <Container className={styles.anomaliesContainer}>
      <div className={styles.pageHeaderContainer}>
        <PageHeader showDate title={PageNames.ANOMALY_DETECTION} />
      </div>
      {renderPageContent()}
      {isAlertRulesListOpen ? (
        <AlertRulesListModal
          onDeleteAlertRule={onDeleteAlertRule}
          onEditAlertRule={onEditAlertRule}
          alertRules={alertRulesRows}
          onClose={closeAlertRulesListModal}
          addAlertRule={openAddAlertRuleModal}
        />
      ) : null}
      {isAddAlertRuleModalOpen ? (
        <AddAlertRuleModal
          selectedAlertRule={alertRuleToEdit}
          open={isAddAlertRuleModalOpen}
          onClose={closeAddAlertRuleModal}
          getMyEmail={() => usersStore.currUserName}
          filtersOptions={{ accountTags, services, divisions, linkedAccounts }}
          handleSaveAlertRule={saveAlertRule}
          isLoading={isAddingAlertRule || isUpdatingAlertRule}
          alertRules={alertRules}
        />
      ) : null}
      {alertRuleToDelete ? (
        <WarningModal
          isOpen={alertRuleToDelete}
          handleDelete={deleteAlertRule}
          warningText="There might be opened alerts under this Alert Rule. Deleting the Alert Rule will delete these alerts."
          executeButtonTitle="Delete"
          isLoading={isDeletingAlertRule}
        />
      ) : null}
      {alertRuleToEdit && isEditAlertRuleWarningModalOpen ? (
        <WarningModal
          isOpen={alertRuleToEdit && isEditAlertRuleWarningModalOpen}
          handleDelete={editAlertRule}
          warningText="There might be opened alerts under this Alert Rule. Updating the Alert Rule will delete these alerts."
          executeButtonTitle="Continue"
          isLoading={isUpdatingAlertRule}
        />
      ) : null}
    </Container>
  );
};

AnomalyDetection.propTypes = {
  filtersValuesMap: PropTypes.object.isRequired,
};
export default withInvoiceFiltersContextConsumer(AnomalyDetection);
