import { Brand } from '../types';
import React, { FC, memo, SVGProps } from 'react';
import { AnodotExternalLinks } from './external-links.configuration';
// @ts-ignore
import { ClarityLogo } from '@pileus-cloud/anodot-frontend-common';
import { ReactComponent as AnodotMspStateImage } from '../assets/Anodot/spMspState.svg';
import { ReactComponent as AnodotNoBudget } from '../assets/Anodot/noBudget.svg';
import { ReactComponent as AnodotProgress } from '../assets/Anodot/customProgress.svg';
import { ReactComponent as AnodotAccountNameSVG } from '../assets/Anodot/accountName.svg';
import { ReactComponent as AnodotSun } from '../assets/Anodot/sun.svg';
import { ReactComponent as AnodotAutomaticImg } from '../assets/Anodot/automatic.svg';
import { ReactComponent as AnodotEmptyGroupByCardData } from '../assets/Anodot/noData.svg';
import { ReactComponent as AnodotBeta } from '../assets/Anodot/beta.svg';
import { ReactComponent as AnodotDetailsIcon } from '../assets/Anodot/details.svg';

import loginLogoPng from '../assets/Anodot/loginLogo.png';
import awsOnboardingAccountId from '../assets/Anodot/awsOnboardingAccountId.png';
import azureClientSecretHelp from '../assets/Anodot/azureClientSecretHelp.jpg';
import azureBillingExportContainerHelp from '../assets/Anodot/azureBillingExportContainerHelp.png';
import azureBillingExportDirectoryPrefixHelp from '../assets/Anodot/azureBillingExportDirectoryPrefixHelp.png';
import azureBillingExportCostTypeHelp from '../assets/Anodot/azureBillingExportCostTypeHelp.png';
import azureBillingExportAccountStorageHelp from '../assets/Anodot/azureBillingExportAccountStorageHelp.png';
import gcpOnboardingBillingDatasetNameHelp from '../assets/Anodot/gcpOnboardingBillingDatasetNameHelp.jpg';
import gcpOnboardingBillingTableNameHelp from '../assets/Anodot/gcpOnboardingBillingTableNameHelp.jpg';
import gcpOnboardingServiceAccountKeyHelp from '../assets/Anodot/gcpOnboardingServiceAccountKeyHelp.gif';
import gcpOnboardingBillingDatasetProjectIDHelp from '../assets/Anodot/gcpOnboardingBillingDatasetProjectIDHelp.jpg';

import errorFoundPng from '../assets/Anodot/errorFound.png';
import spAnalyzerLoaderPng from '../assets/Anodot/spAnalyzerLoader.gif';
import illustrationPng from '../assets/Anodot/illustration.png';
import ValidationGif from '../assets/Anodot/validation.gif';
import LoginHeader from '../assets/Anodot/LoginHeader.png';
import LoginFooter from '../assets/Anodot/LoginFooter.png';
import LoginCenter from '../assets/Anodot/LoginCenter.png';
import CURVideo from '../assets/Anodot/AWS_CUR.mp4';
import { GetBrandResource } from './GetBrandResource';
import { Themes } from '../enums';

class AnodotBrand {
  public name: string = 'Anodot';
  public domain: string = 'anodot.com';
  public supportEmail: string = 'support@anodot.com';
  public infoEmail: string = 'support@anodot.com';
  public hideContactUs: boolean = false;
  public themeName = Themes.Default;
  public realm: {
    excludeDomain: string;
  } = {
    excludeDomain: 'pileuscloud.com',
  };
  public externalLinks = AnodotExternalLinks;
  public loginLogo: FC<SVGProps<any>> = GetBrandResource(
    { resource: loginLogoPng },
    {
      style: { width: '34vh' },
    },
  );
  public logo: FC<SVGProps<any>> = GetBrandResource(
    { resource: ClarityLogo, isSvg: true },
    {
      style: {
        position: 'unset',
      },
    },
  );

  public AwsOnboardingAccountId: string = `${awsOnboardingAccountId}`;
  public AzureClientSecretHelp: string = `${azureClientSecretHelp}`;
  public AzureBillingExportContainerHelp: string = `${azureBillingExportContainerHelp}`;
  public GCPOnboardingBillingDatasetNameHelp: string = `${gcpOnboardingBillingDatasetNameHelp}`;
  public GCPOnboardingBillingTableNameHelp: string = `${gcpOnboardingBillingTableNameHelp}`;
  public GCPOnboardingServiceAccountKeyHelp: string = `${gcpOnboardingServiceAccountKeyHelp}`;
  public GCPOnboardingBillingDatasetProjectIDHelp: string = `${gcpOnboardingBillingDatasetProjectIDHelp}`;
  public AzureBillingExportAccountStorageHelp: string = `${azureBillingExportAccountStorageHelp}`;
  public AzureBillingExportDirectoryPrefixHelp: string = `${azureBillingExportDirectoryPrefixHelp}`;
  public AzureBillingExportCostTypeHelp: string = `${azureBillingExportCostTypeHelp}`;

  public errorFound: FC<SVGProps<any>> = GetBrandResource({ resource: errorFoundPng });
  public spMspState: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotMspStateImage, isSvg: true });
  public noBudget: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotNoBudget, isSvg: true });
  public spAnalyzerLoader: FC<SVGProps<any>> = GetBrandResource({ resource: spAnalyzerLoaderPng });
  public customProgress: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotProgress, isSvg: true });
  public accountName: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotAccountNameSVG, isSvg: true });
  public sun: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotSun, isSvg: true });
  public automatic: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotAutomaticImg, isSvg: true });
  public noData: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotEmptyGroupByCardData, isSvg: true });
  public beta: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotBeta, isSvg: true });
  public details: FC<SVGProps<any>> = GetBrandResource({ resource: AnodotDetailsIcon, isSvg: true });
  public illustration: FC<SVGProps<any>> = GetBrandResource({ resource: illustrationPng });
  public validation: FC<SVGProps<any>> = GetBrandResource({ resource: ValidationGif });
  public awsCurVideo: FC<SVGProps<any>> = GetBrandResource({ resource: CURVideo, isVideo: true });
  public leftSideIllustration: FC<SVGProps<any>> = memo((props: any) => (
    <div className={props?.styles?.mainImgWrapper}>
      <div className={props?.styles.timeline}>
        <img src={LoginHeader} alt="" />
      </div>
      <div className={props?.styles?.cube}>
        <img src={LoginFooter} alt="" />
      </div>
      <div className={props?.styles?.cloud}>
        <img src={LoginCenter} alt="" />
      </div>
    </div>
  ));

  constructor() {
    Object.freeze(this);
  }
}

export const anodotBrand: Brand = new AnodotBrand();
