import { API } from 'shared/utils/apiMiddleware';
import { isNil } from 'lodash';
import { BILLINGS_API_NAME, USER_MANAGEMENT_USERS } from './helperFunctions';

export async function createUsers(users) {
  const payload = {
    body: {
      users,
    },
  };
  return API.post(BILLINGS_API_NAME, USER_MANAGEMENT_USERS, payload);
}

export async function updateUser(user) {
  const payload = {
    body: {
      user,
    },
  };
  return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/${user.id}`, payload);
}

export async function deleteUsers(usersIds) {
  const payload = {
    body: {
      usersIds,
    },
  };
  return API.del(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}`, payload);
}

export async function updateRolesAssignedToUsers(updatePayload) {
  const payload = {
    body: updatePayload,
  };
  return API.put(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/roles`, payload);
}

export async function enableDisableUsers(usersIds, enable) {
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/enable-disable?enable=${enable}`, {
    body: {
      usersIds,
    },
  });
}

export async function resetUsersPassword(usersIds) {
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/reset-password`, {
    body: {
      usersIds,
    },
  });
}

export async function fetchUsers(params) {
  const queryString = params
    ? Object.keys(params)
        ?.filter((key) => !isNil(params[key]))
        ?.map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        ?.join('&')
    : null;
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}${queryString ? `?${queryString}` : ''}`);
}

export async function fetchUser(userId) {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/${userId}`);
}

export async function downloadUsers(userIdsToDownload, numberOfUsers) {
  return API.post(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/download-users`, {
    body: {
      userIds: userIdsToDownload,
      pageSize: userIdsToDownload ? userIdsToDownload.length : numberOfUsers || 0,
    },
  });
}

export async function fetchPartialUserPermissions(userId, category, actionId) {
  return API.get(BILLINGS_API_NAME, `${USER_MANAGEMENT_USERS}/${userId}/permissions/${category}/${actionId}`);
}

export async function fetchUsersForAdmin(search, limit, page) {
  return API.get('billings', `${USER_MANAGEMENT_USERS}/admin/users?search=${search}&limit=${limit}&page=${page}`);
}
