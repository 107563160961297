import React from 'react';
import PropTypes from 'prop-types';
import styles from './errorMessage.module.scss';

const ErrorMessage = ({ displayError = false, errMsg = '', automationid = '' }) => {
  if (!displayError) {
    return null;
  }
  return (
    <span automation-id={automationid} className={styles.errorMsg}>
      {errMsg}
    </span>
  );
};

ErrorMessage.propTypes = {
  errMsg: PropTypes.string,
  displayError: PropTypes.bool,
  automationid: PropTypes.string,
};

export default ErrorMessage;
