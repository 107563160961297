import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import Input from 'shared/components/andtComponents/Input';
import { getCurrenciesSymbol } from 'users/containers/Onboarding/shared/components/UserCurrency/currencySymbolsHelper';
import Select from 'shared/components/andtComponents/SimpleSelect';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import {
  AZURE_ACCOUNT_TYPES,
  AZURE_COST_TYPES,
  AZURE_ONBOARDING_FIELDS,
  AZURE_ONBOARDING_STEPS,
} from '../../AzureOnboardingConstants';
import commonStyles from '../Pages.module.scss';
import BillingExportPageHelp from './BillingExportPageHelp';
import styles from './BillingExportPage.module.scss';
import ErrorModal from '../../../SharedPages/ErrorModal/ErrorModal';
import ValidateModal from '../../../SharedPages/ValidateModal/ValidateModal';
import { ONBOARDING_FIELDS } from 'app/containers/Onboarding/utils/OnboardingConstants';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const BillingExportPage = () => {
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const brand = useBrand();
  // wait 60 seconds before redirecting user to next page in case success status is
  // from prev onboarding (airflow updates it in a few sec)
  const [minWaitingTime, setMinWaitingTime] = useState(false);
  const [validationOngoing, setValidationOngoing] = useState(false);
  const { fetchOnboardingStatus, restartOnboarding, updateOnboardingParams } = useOnboarding();
  const hasExpired =
    getValues(ONBOARDING_FIELDS.START_TIME) &&
    moment(getValues(ONBOARDING_FIELDS.START_TIME)).add(24, 'h').isBefore(moment());
  const { mutateAsync: handleUpdateOnboardingParams } = updateOnboardingParams();
  const accountId = getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_ID);
  const validationStarted = getValues(AZURE_ONBOARDING_FIELDS.VALIDATION_STARTED);
  const validationStartTrigger = getValues(AZURE_ONBOARDING_FIELDS.VALIDATION_START_TRIGGER);
  useEffect(() => {
    if (validationStarted) {
      setMinWaitingTime(false);
      setTimeout(() => {
        setMinWaitingTime(true);
        handleUpdateOnboardingParams({ validationShouldStart: true, accountId });
      }, 60000);
    }
  }, [validationStarted]);
  useWatch(AZURE_ONBOARDING_FIELDS.VALIDATION_STARTED);
  const { data: status } = fetchOnboardingStatus(accountId, {
    refetchInterval: 15 * 1000,
  });
  const handleRestart = restartOnboarding();
  const [validationFailed, setValidationFailed] = useState(false);
  const { validationStatus } = status || {};
  const currencies = useMemo(
    () =>
      getCurrenciesSymbol().map(({ key }) => ({
        value: key,
        label: key,
      })),
    [],
  );
  useEffect(() => {
    if (validationStartTrigger) {
      setValidationOngoing(true);
      setTimeout(() => {
        setValidationOngoing(false);
      }, 60 * 1000);
    }
  }, [validationStartTrigger]);
  useEffect(() => {
    const isFailed = !validationOngoing && validationStatus?.status === 'FAILED' && validationStarted;
    const isSuccess = minWaitingTime && validationStatus?.status === 'SUCCESS' && validationStarted;
    if (isSuccess) {
      setValue(AZURE_ONBOARDING_FIELDS.SCREEN, AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT);
      return;
    }
    if (isFailed) {
      setValidationFailed(true);
    } else {
      setValidationFailed(false);
    }
  }, [status, minWaitingTime, validationOngoing]);
  const renderInput = ({ fieldName, name, label, icon, disabled }) => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            disabled={disabled}
            value={getValues(fieldName)}
            isInvalid={!!errors[fieldName]}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={(e) => setValue(fieldName, e.target.value, { shouldValidate: true })}
            placeholder=""
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  const azureAccountType = getValues(AZURE_ONBOARDING_FIELDS.AZURE_ACCOUNT_TYPE);
  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 14,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        list={[
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.ACCOUNT_STORAGE,
                name: 'onboarding-account-storage',
                label: 'Storage Account',
                icon: 'server',
              }),
            key: 'onboarding-account-storage',
          },
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.CONTAINER_NAME,
                name: 'onboarding-container-name',
                label: 'Container Name',
                icon: 'fileLines',
              }),
            key: 'onboarding-container-name',
          },
          {
            render: () =>
              renderInput({
                fieldName: AZURE_ONBOARDING_FIELDS.DIRECTORY_PREFIX,
                name: 'onboarding-directory-prefix',
                label: 'Directory Field',
                icon: 'cloud',
              }),
            key: 'onboarding-directory-prefix',
          },
          {
            render: () => (
              <RadioButtonList
                className={styles.radioButtonsList}
                options={[
                  {
                    value: AZURE_COST_TYPES.FOCUS,
                    label: (
                      <>
                        FOCUS
                        <GenerateIcon className={styles.newIcon} iconName={ICONS.sparkles.name} />
                      </>
                    ),
                    primary: true,
                  },
                  { value: AZURE_COST_TYPES.UNBLENDED, label: 'Actual cost', primary: true },
                  ...(azureAccountType === AZURE_ACCOUNT_TYPES.PAYG_EXPORT
                    ? []
                    : [
                        {
                          value: AZURE_COST_TYPES.AMORTIZED,
                          label: 'Amortized',
                          primary: true,
                        },
                      ]),
                ]}
                value={getValues(AZURE_ONBOARDING_FIELDS.AZURE_COST_TYPE)}
                onChange={(val) => {
                  setValue(AZURE_ONBOARDING_FIELDS.AZURE_COST_TYPE, val);
                }}
              />
            ),
            key: 'onboarding-cost-type',
            topSpace: 12,
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-currency-type">
                  <span>
                    <GenerateIcon iconName={ICONS.coins.name} />
                    Currency Type (optional)
                  </span>
                </label>
                <div className={commonStyles.inputBox}>
                  <Select
                    placeholder="USD"
                    className={styles.select}
                    value={getValues(AZURE_ONBOARDING_FIELDS.CURRENCY_TYPE)}
                    options={currencies}
                    name="onboarding-currency-type"
                    onChange={(value) => {
                      setValue(AZURE_ONBOARDING_FIELDS.CURRENCY_TYPE, value);
                    }}
                  />
                </div>
              </div>
            ),
            key: 'onboarding-currency-type',
          },
        ]}
        help={{
          'onboarding-account-storage': BillingExportPageHelp.accountStorage(brand.AzureBillingExportAccountStorageHelp),
          'onboarding-container-name': BillingExportPageHelp.containerName(brand.AzureBillingExportContainerHelp),
          'onboarding-directory-prefix': BillingExportPageHelp.directoryPrefix(brand.AzureBillingExportDirectoryPrefixHelp),
          'onboarding-cost-type': BillingExportPageHelp.costType(brand.AzureBillingExportCostTypeHelp),
          'onboarding-currency-type': BillingExportPageHelp.currencyType,
        }}
      />
      {validationStarted && getValues(AZURE_ONBOARDING_FIELDS.VALIDATION_STARTED) && !validationFailed && (
        <ValidateModal
          title="Data Fetch Simulation is on the way"
          subtitle={
            <p>
              We are currently validating your account details <br /> and creating a data fetch simulation.
            </p>
          }
          checkbox={
            <Checkbox
              isChecked={getValues(AZURE_ONBOARDING_FIELDS.SKIP_DATA_SIMULATION)}
              onChange={async () => {
                setValue(
                  AZURE_ONBOARDING_FIELDS.SKIP_DATA_SIMULATION,
                  !getValues(AZURE_ONBOARDING_FIELDS.SKIP_DATA_SIMULATION),
                );
                await handleUpdateOnboardingParams({
                  accountId: getValues(AZURE_ONBOARDING_FIELDS.ACCOUNT_ID),
                  [AZURE_ONBOARDING_FIELDS.SKIP_DATA_SIMULATION]: getValues(
                    AZURE_ONBOARDING_FIELDS.SKIP_DATA_SIMULATION,
                  ),
                });
              }}
            >
              Inform me when the entire process is done (without data fetch simulation)
            </Checkbox>
          }
        />
      )}
      {(validationStarted && validationFailed) || hasExpired ? (
        <ErrorModal status={status} handleRestart={handleRestart} cloudType={CLOUD_TYPE_IDS.AZURE} />
      ) : null}
    </div>
  );
};

export default BillingExportPage;
