import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { STALE_TIME } from 'recommendationsNew/consts';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { API } from 'shared/utils/apiMiddleware.js';

const utilizationApi = '/api/v1/commitment/utilization';
export const getCommitmentData = async ({ date, commitmentServices, linkedAccount, commitmentType, payerAccount }) => {
  const params = new URLSearchParams({
    date,
    commitmentType,
    linkedAccount: linkedAccount.join(','),
    payerAccount: payerAccount.join(','),
  });

  commitmentServices.forEach((service) => params.append('commitmentServices', service));

  return await API.get('billings', `${utilizationApi}/i/summary?${params.toString()}`);
};

const getCommitmentStatisticsData = async ({ dates, commitmentType }) => {
  const params = new URLSearchParams({
    commitmentType,
  });
  dates.forEach((date) => params.append('dates', date));
  const apiQuery = `${utilizationApi}/totalsavings?${params.toString()}`;
  return await API.get('billings', apiQuery);
};
export default function useCommitmentData(isEnabled = true) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const queryKey = [apiConstants.QUERY_KEYS.MAIN_DASHBOARD, apiConstants.QUERY_KEYS.COMMITMENT, userAccountKey];

  const useFetchCommitmentData = ({
    date,
    commitmentServices,
    linkedAccount = [],
    commitmentType,
    payerAccount = [],
  }) => {
    return useQuery({
      queryKey: [...queryKey, date, commitmentServices, linkedAccount, commitmentType, payerAccount],
      queryFn: () => getCommitmentData({ date, commitmentServices, linkedAccount, commitmentType, payerAccount }),
      retry: false,
      staleTime: STALE_TIME,
      enabled: isEnabled,
      keepPreviousData: true,
    });
  };

  const useFetchCommitmentStatisticData = ({ dates, commitmentType }) => {
    return useQuery({
      queryKey: [...queryKey, dates, commitmentType],
      queryFn: () => getCommitmentStatisticsData({ dates, commitmentType }),
      retry: false,
      staleTime: STALE_TIME,
      enabled: isEnabled && !!dates && !!commitmentType,
      keepPreviousData: true,
    });
  };
  return {
    invalidate: (queryKeyToInvalidate) =>
      queryClient.invalidateQueries(queryKeyToInvalidate ? [...queryKey] : queryKey),
    reset: (queryKeyToInvalidate) =>
      queryClient.resetQueries(queryKeyToInvalidate ? { queryKey: [...queryKey] } : { queryKey }),
    fetchCommitmentData: useFetchCommitmentData,
    fetchCommitmentStatisticData: useFetchCommitmentStatisticData,
  };
}
