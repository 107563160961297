import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button';
import { ROLE_ACTIONS } from '../consts';
import { Action, CategoryAction, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import DeleteRolesModal from 'users/containers/Organization/Roles/roleModals/DeleteRolesModal';
import EditRoleModal from 'users/containers/Organization/Roles/roleModals/EditRoleModal';
import { usePermissions } from 'app/contexts/PermissionsContext';
import CreateEditRoleModal from 'users/containers/Organization/Roles/roleModals/CreateEditRoleModal/CreateEditRoleModal';

import styles from './RoleActions.module.scss';

const RoleActions = ({ row }) => {
  const [isOpen, setIsOpen] = useState(false);

  const [isDeleteRoleModalOpen, setIsDeleteRoleModalOpen] = useState(false);
  const [isEditRoleModalOpen, setIsEditRoleModalOpen] = useState(false);
  const [isCreateRoleModalOpen, setIsCreateRoleModalOpen] = useState(false);

  const { isHasPermission } = usePermissions();
  const updateAllowed = isHasPermission(OrganizationEntityCategory.Roles, Action.Update);
  const viewAllowed = isHasPermission(OrganizationEntityCategory.Roles, Action.View);
  const createAllowed = isHasPermission(OrganizationEntityCategory.Roles, CategoryAction.Create);

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  return updateAllowed || viewAllowed || createAllowed ? (
    <div className={styles.roleActionsContainer} onClick={(e) => toggleOpen(e)}>
      <Popover>
        <PopoverTrigger as="button" type="button">
          <Button
            disabled={row && !row?.editable}
            isTextButton={!!row}
            text=""
            icon={<GenerateIcon iconName={ICONS.verticalDots.name} className={styles.actionsIcon} />}
            onClick={() => setIsOpen(!isOpen)}
            className={row ? { icon: styles.actionsIcon } : { button: styles.asButton }}
          />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={-10} side="left" align="start" alignOffset={20} className={styles.popoverContent}>
            {!row ? (
              <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={CategoryAction.Create}>
                <Button
                  isTextButton
                  text={ROLE_ACTIONS.CREATE.action}
                  automationid={ROLE_ACTIONS.CREATE.automationid}
                  className={{ button: styles.menuItem }}
                  icon={() => <GenerateIcon iconName={ROLE_ACTIONS.CREATE.icon} className={styles.itemIcon} />}
                  onClick={() => setIsCreateRoleModalOpen(true)}
                />
              </ReadOnlyDisplayWrapper>
            ) : (
              <>
                {updateAllowed ? (
                  <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.Update}>
                    <Button
                      isTextButton
                      text={ROLE_ACTIONS.EDIT.action}
                      automationid={ROLE_ACTIONS.EDIT.automationid}
                      className={{ button: styles.menuItem }}
                      icon={() => <GenerateIcon iconName={ROLE_ACTIONS.EDIT.icon} className={styles.itemIcon} />}
                      onClick={() => setIsEditRoleModalOpen(true)}
                    />
                  </ReadOnlyDisplayWrapper>
                ) : (
                  <ReadOnlyDisplayWrapper category={OrganizationEntityCategory.Roles} action={Action.View}>
                    <Button
                      isTextButton
                      text={ROLE_ACTIONS.VIEW.action}
                      automationid={ROLE_ACTIONS.VIEW.automationid}
                      className={{ button: styles.menuItem }}
                      icon={() => <GenerateIcon iconName={ROLE_ACTIONS.VIEW.icon} className={styles.itemIcon} />}
                      onClick={() => setIsEditRoleModalOpen(true)}
                    />
                  </ReadOnlyDisplayWrapper>
                )}
                <ReadOnlyDisplayWrapper
                  category={OrganizationEntityCategory.Roles}
                  action={Action.Update}
                  isHide={!updateAllowed}
                >
                  <Button
                    isTextButton
                    text={ROLE_ACTIONS.DELETE.action}
                    automationid={ROLE_ACTIONS.DELETE.automationid}
                    className={{ button: styles.menuItem }}
                    icon={() => <GenerateIcon iconName={ROLE_ACTIONS.DELETE.icon} className={styles.itemIcon} />}
                    onClick={() => setIsDeleteRoleModalOpen(true)}
                  />
                </ReadOnlyDisplayWrapper>
              </>
            )}
          </PopoverContent>
        </PopoverPortal>
      </Popover>

      {isDeleteRoleModalOpen && (
        <DeleteRolesModal setIsOpen={setIsDeleteRoleModalOpen} isOpen={isDeleteRoleModalOpen} roles={[row]} />
      )}
      {isEditRoleModalOpen && (
        <EditRoleModal
          setIsOpen={setIsEditRoleModalOpen}
          isOpen={isEditRoleModalOpen}
          role={row}
          isViewOnly={!updateAllowed}
        />
      )}
      {isCreateRoleModalOpen && (
        <CreateEditRoleModal isOpen={isCreateRoleModalOpen} onClose={() => setIsCreateRoleModalOpen(false)} />
      )}
    </div>
  ) : (
    <div />
  );
};

RoleActions.propTypes = {
  row: PropTypes.object,
};

export default RoleActions;
