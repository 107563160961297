import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InformationIcon from 'mdi-react/InformationIcon';
import { UncontrolledTooltip } from 'reactstrap';
import PencilIcon from 'shared/img/icons/PencilIcon';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';
import { numberWithCommas, percentStrNoCommas } from '../utils/strUtil';

class ContentInfo extends PureComponent {
  static propTypes = {
    title: PropTypes.number.isRequired,
    value: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    infoValue: PropTypes.string.isRequired,
    isInfo: PropTypes.bool,
    small: PropTypes.bool,
    isEditable: PropTypes.bool,
    editFunc: PropTypes.func.isRequired,
    isCost: PropTypes.bool,
    isPercent: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      isCurrEdit: false,
    };
  }

  toggleEnableEdit = () => {
    const { isCurrEdit } = this.state;
    this.setState({ isCurrEdit: !isCurrEdit });
  };

  renderTextInput = (classes) => (
    <div className={classes.root}>
      <TextField
        id="standard-number"
        type="number"
        value={this.props.value}
        onChange={(event) => this.props.editFunc(event)}
        className={classNames(classes.margin, classes.textField)}
        InputLabelProps={{
          shrink: true,
        }}
        margin="normal"
        style={{ whiteSpace: 'nowrap' }}
      />
    </div>
  );

  render() {
    const { classes } = this.props;
    const { isEditable, small, value, id, title, isInfo, infoValue, numStrAbriviaionByDisplayMetric } = this.props;
    const { isCurrEdit } = this.state;
    const getValueFormat = (value) => {
      const { isCost, isPercent } = this.props;
      if (isCost) {
        return `${numStrAbriviaionByDisplayMetric(value, value, DisplayMetricTypes.COST)}`;
      }
      if (isPercent) {
        return percentStrNoCommas(+value);
      }
      return numberWithCommas(value);
    };
    return (
      <div className="savigns-plans__content-info-container" style={{ maxWidth: '300px' }}>
        <h5 className="savigns-plans__content-info-header">
          {title}
          {isInfo ? (
            <span>
              <InformationIcon size={16} />
            </span>
          ) : null}
          {isEditable ? (
            <span style={{ marginLeft: '10px' }}>
              <PencilIcon onClick={this.toggleEnableEdit} />
            </span>
          ) : null}
        </h5>
        {isCurrEdit ? (
          this.renderTextInput(classes)
        ) : (
          <h3 className={`savigns-plans__content-info-value ${small ? 'small' : null}`}>{getValueFormat(value)}</h3>
        )}
        {isInfo ? (
          <UncontrolledTooltip placement="top" target={`TooltipTop${id}`}>
            {infoValue}
          </UncontrolledTooltip>
        ) : null}
      </div>
    );
  }
}

const ObserverContentInfo = withUserSettingsConsumer(ContentInfo);
export default ObserverContentInfo;
