import moment from 'moment';
import { snakeCase } from 'lodash';
import { CLOUD_TYPE_IDS } from '../../users/constants/usersConstants';

export const isLetter = (str) => str.length === 1 && str.match(/[a-z]/i);

export const isDecimalNeeded = (num, decimal = 1) => {
  const formattedNum = Math.abs(num);
  const zerosForGreaterThanNum = decimal > 0 ? '0'.repeat(decimal) : '';
  // TODO: support decimals other then 1;
  const isNeeded = formattedNum % 1 > parseFloat(`0.${zerosForGreaterThanNum}5`) && formattedNum % 1 <= 0.95;
  return isNeeded;
};

export const removeTrailingZeroes = (number, decimal = 2) => {
  return (+number).toFixed(decimal).replace(/(\.0+|0+)$/, '');
};

export const numberWithCommas = (num, fractionDigits = 0) => {
  const parsedNum = parseFloat(num);
  return parseFloat(parsedNum.toFixed(fractionDigits))
    .toString()
    .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
};

// There is some confusion about base number that should be used for conversion from bytes to GB/TB/PB/etc.
// We have changed this number from 1024 to 1000 and back.
// First ticket to change form 1024 to 1000: https://anodot.atlassian.net/browse/PIL-5556
// and latest ticket to change back to 1024: https://anodot.atlassian.net/browse/PIL-5765
// according this article, AWS S3 usage is per 1024: https://aws.amazon.com/s3/pricing/#:~:text=Amazon%20S3%20storage%20usage%20is%20calculated%20in%20binary%20gigabytes%20(GB)%2C%20where%201%20GB%20is%20230%C2%A0bytes.%20This%20unit%20of%20measurement%20is%20also%20known%20as%20a%20gibibyte%20(GiB)%2C%20defined%20by%20the%20International%20Electrotechnical%20Commission%20(IEC).%20Similarly%2C%201%20TB%20is%20240%C2%A0bytes%2C%20i.e.%201024%20GBs.
// Backend is converting it from GB to bits by 1000, so for now we are converting it by 1000 to GB and then by 1024 to needed unit.
const BITS_PER_KILO = 1024;
const BYTES_PER_KILO = 1000;
export const bytesToGb = (bytes, decimal = 2, asNumber = false) => {
  const base = BYTES_PER_KILO;
  const byteAsInteger = typeof bytes === 'string' ? parseInt(bytes, 10) : bytes;
  if (isNaN(byteAsInteger)) {
    return bytes;
  }
  const value = byteAsInteger / base ** 3;
  const returnValue = !asNumber ? `${value.toFixed(isDecimalNeeded(value, decimal) ? decimal : 0)} GB` : value;
  return returnValue;
};
export const strNumToSize = (bytes, decimal = 2, cloudTypeId) => {
  let byteAsInteger = typeof bytes === 'string' ? parseInt(bytes, 10) : bytes;
  if (isNaN(byteAsInteger)) {
    return bytes || null;
  }
  if (bytes === 0) {
    return '0 Bytes';
  }
  // https://anodot.atlassian.net/browse/PIL-5765
  const base1024 = typeof cloudTypeId === 'number' && cloudTypeId === CLOUD_TYPE_IDS.AWS;
  if (base1024) {
    const gbOriginal = bytesToGb(byteAsInteger, 20, true);
    byteAsInteger = gbOriginal * 1024 ** 3;
  }
  const base = base1024 ? BITS_PER_KILO : BYTES_PER_KILO;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = parseInt(Math.floor(Math.log(Math.abs(byteAsInteger)) / Math.log(base)), 10);
  if (i <= 0) {
    return `${byteAsInteger.toFixed(isDecimalNeeded(byteAsInteger, decimal) ? decimal : 0)} ${sizes[0]}`;
  }
  const value = byteAsInteger / base ** i;
  return `${value.toFixed(isDecimalNeeded(value, decimal) ? decimal : 0)} ${sizes[i]}`;
};

export const strGbToSize = (gb) => {
  const gbAsInteger = typeof gb === 'string' ? parseInt(gb, 10) : gb;
  const gbInBytes = gbAsInteger * BYTES_PER_KILO ** 3;
  return strNumToSize(gbInBytes);
};

export const kFormatter = (num, decimal = 0) =>
  Math.abs(num) > 999
    ? `${
        Math.sign(num) * (Math.abs(num) / 1000).toFixed(isDecimalNeeded(Math.abs(num) / 1000, decimal) ? decimal : 0)
      }k`
    : `${(Math.sign(num) * Math.abs(num)).toFixed(isDecimalNeeded(Math.abs(num), decimal) ? decimal : 0)}`;

export const kFormatterWithDecimal = (num, decimal = 1) => kFormatter(num, decimal);

export const kFormatterNoDecimal = (num) =>
  Math.abs(num) > 999 ? `${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k` : num?.toFixed(0);

export const kIntFormmater = (num, decimal = 0, isTrunc = true) => kFormatter(isTrunc ? Math.trunc(num) : num, decimal);

export const trimZeroDecimal = (strValue) => {
  let newValue = strValue;
  if (typeof strValue === 'string' && (newValue.endsWith('.0') || newValue.endsWith('.00'))) {
    newValue = newValue.split('.').slice(0, -1).join('.');
  }

  return newValue;
};

export const maxNumSizeAbbreviation = (maxNum, numValue, decimals = 2) => {
  const M = 1000000;
  const K = 1000;
  let abbreviatedStringNum = '';
  try {
    abbreviatedStringNum = `${numValue && numValue.toFixed(decimals)}`;
    if (Math.abs(maxNum) > K) {
      abbreviatedStringNum =
        Math.abs(maxNum) >= M
          ? `${trimZeroDecimal(numValue && (numValue / M).toFixed(2))}M`
          : `${trimZeroDecimal(numValue && (numValue / K).toFixed(1))}K`;
    } else {
      abbreviatedStringNum = trimZeroDecimal(abbreviatedStringNum);
    }
  } catch (error) {
    abbreviatedStringNum = numValue;
  }
  return abbreviatedStringNum;
};

export const percentStr = (num, fractionDigits = 1) => {
  if (num == null) {
    return num; // skip null and undefined but not 0
  }
  return `${numberWithCommas(num, fractionDigits)}%`;
};

export const percentStrNoCommas = (numValue, decimal = 1) => {
  return `${numValue.toFixed(isDecimalNeeded(numValue, decimal) ? decimal : 0)}%`;
};

export const removeDecPoint = (value) => parseInt(value, 10);

export const capitalize = (s) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const replaceAt = (str, index, char) => str.slice(0, index) + char + str.slice(index + char.length);

export const copyStrToClipboard = (copyText) => {
  const el = document.createElement('textarea');
  el.value = copyText;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el);
  // Check if there is any content selected previously
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false;
  el.select(); // Select the <textarea> content
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const containsLowerCase = (str) => str.toUpperCase() !== str;

export const containsUpperCase = (str) => str.toLowerCase() !== str;

export const containsNumber = (str) => {
  const matches = str?.match(/\d+/g);
  return matches != null;
};

export const containsSpecialChar = (str) => {
  const specialCharFormat = /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/;
  const matches = str?.match(specialCharFormat);
  return matches != null;
};

export const isEmailValid = (str) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((?!-)[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,})$/;
  return re.test(String(str).toLowerCase());
};

export const trimNonAlphanumericSymbols = (str) => {
  if (!str) {
    return str;
  }
  return str.replace(/[^a-zA-Z0-9_\-.\s]/g, '');
};

export const intersperse = (arr, sep) => {
  // function to separate between array elemets
  let results = [];
  if (arr && Array.isArray(arr)) {
    if (arr.length === 0) {
      results = [];
    }

    results = arr.slice(1).reduce((xs, x, i) => xs.concat([sep, x]), [arr[0]]);
  }

  return results;
};

export const strToTitleCase = (str) =>
  str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const hashText = async (text = '', toSize = 8) => {
  if (!text) {
    return '';
  }

  async function digestMessage(message) {
    try {
      const msgUint8 = new TextEncoder().encode(message); // encode as (utf-8) Uint8Array
      const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8); // hash the message
      const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
      return hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  const hashedText = await digestMessage(text);
  // const hashedText = digestMessage(text).then(digestHex => digestHex.substr(0, toSize));
  return hashedText.substr(0, toSize);
};

export const generateCloneName = (clonedName, names) => {
  const getNextName = (name) => {
    if (name.match(/_COPY$/)) {
      return `${name}_2`;
    }
    if (name.match(/_COPY_[\d+]$/)) {
      const number = parseInt(name.match(/_COPY_([\d+])$/)[1], 10);
      return name.replace(/_[\d+]$/, `_${number + 1}`);
    }
    return `${name}_COPY`;
  };
  let result = clonedName;
  while (names.includes(result)) {
    result = getNextName(result);
  }
  return result;
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const formatDateToQueryString = (date) => {
  try {
    const momentDate = moment(date);
    return momentDate.isValid() ? moment(date).format('YYYY-MM-DD') : '';
  } catch (error) {
    return '';
  }
};

export const transformKeysToSnakeCase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map(transformKeysToSnakeCase);
  }
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = snakeCase(key);
      acc[newKey] = transformKeysToSnakeCase(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

export const eNotationToNumberStr = (num, maxZeros = 20) => {
  // also known as scientific notation
  if (num == null) {
    return;
  }
  const [base, exponent] = num.toString().split('e');
  const baseDecimalLength = base?.split('.')[1]?.length || 0;
  if (exponent < 0 && exponent >= -(maxZeros + 1)) {
    return (+num).toFixed(-exponent + baseDecimalLength);
  }
  return num.toString();
};
