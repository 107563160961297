import React, { useState } from 'react';
import { FixedSizeList } from 'react-window';
// @ts-ignore
import { ICONS, GenerateIcon, UserSettingsContext } from '@pileus-cloud/anodot-frontend-common';
import { useNavigate } from 'react-router-dom';
import MenuWrapper from 'app/containers/Layout/topNavbar/components/MenuWrapper';
import TopBarSearch from 'shared/components/TopBarSearch';
import AdminAccountItem from 'users/containers/Admin/components/AdminAccountItem';
import { Routes } from 'shared/constants/routes';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import useDebouncedInput from 'shared/hooks/customHooks/useDebouncedInput';
import useTokens from 'users/new-user-management/hooks/reactQuery/useTokens';
import toast from 'shared/components/andtComponents/Toast';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from '../TopNavBar.module.scss';

const UserPickerNewUM = () => {
  const { usersStore } = useRootStore();
  const { fetchUsersForAdmin } = useUsers();
  const { useExchangeTokenByUserImpersonationToken } = useTokens();
  const navigate = useNavigate();

  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebouncedInput(search, 500);

  const { data, isLoading } = fetchUsersForAdmin({ search: debouncedSearch, page: 0 });
  const { users = [], hasNextPage } = (data as any) || {};

  const { mutate: exchangeTokenByUserImpersonationToken } = useExchangeTokenByUserImpersonationToken({
    handleOnSuccess: onImpersonateSuccess,
  });

  function onImpersonateSuccess({ accessToken, userId }) {
    if (!accessToken) {
      toast.error('Failed to impersonate user');
      return;
    }

    usersStore.impersonateUser({ token: accessToken, userId }).then(() => {
      navigate(Routes.LANDING);
    });
  }

  const handleImpersonate = async (userKey: string, orgUuid: string) => {
    exchangeTokenByUserImpersonationToken({ userId: userKey, orgUuid });
  };

  const renderRow = (props) => {
    const { index, style, data } = props;
    const item = data[index];
    return (
      <li key={index} style={style}>
        <AdminAccountItem
          id={index}
          key={index}
          user={{
            userKey: item.userId,
            userDisplayName: item.username,
          }}
          handleUserClicked={() => handleImpersonate(item.userId, item.orgUuid)}
        />
      </li>
    );
  };
  return (
    <div className="userAccounts" id="accountUsersEnv">
      <div className="topbar__menu">
        <MenuWrapper
          id="topbar_users_list_toggler"
          title="Users"
          iconSize="12px"
          matchHeight
          icon={<GenerateIcon iconName={ICONS.userSecret.name} className={styles.icon} />}
        >
          <TopBarSearch
            isLoading={isLoading}
            currentValue={search}
            handler={(e) => setSearch(e.target.value)}
            id="topbar_user_search_panel"
            placeholder="Search users"
          />
          {users.length || isLoading ? (
            <FixedSizeList itemData={users} height={200} width="auto" itemSize={30} itemCount={users.length}>
              {renderRow}
            </FixedSizeList>
          ) : (
            <div className={styles.placeholder}>No users found</div>
          )}
          {hasNextPage && !isLoading && (
            <div className={styles.placeholder}>First 100 are shown, refine search to load more</div>
          )}
        </MenuWrapper>
      </div>
    </div>
  );
};

export default UserPickerNewUM;
