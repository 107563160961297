import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import {
  FilteringState,
  IntegratedFiltering,
  IntegratedSorting,
  SortingState,
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
  SummaryState,
  IntegratedSummary,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  TableColumnVisibility,
  TableHeaderRow,
  TableFilterRow,
  Toolbar,
  ColumnChooser,
  TableColumnResizing,
  PagingPanel,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import { percentStrNoCommas, kFormatterWithDecimal } from 'shared/utils/strUtil';
import { Col } from 'reactstrap';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import TableWrapper from './TableWrapper';
import { createDateDisplayStr, parseDateFormatFromDate } from '../../utils/dateUtil';

const filterIconPropTypes = {
  type: PropTypes.string.isRequired,
};
const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;
FilterIcon.propTypes = filterIconPropTypes;

class DevExpFilterColumnTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    columns: PropTypes.object.isRequired,
    columnWidths: PropTypes.array.isRequired,
    leftColumns: PropTypes.object,
    rightColumns: PropTypes.object,
    tableColumnExtensions: PropTypes.array.isRequired,
    defaultHiddenColumnNames: PropTypes.array.isRequired,
    defaultSorting: PropTypes.array.isRequired,
    numericColumns: PropTypes.object,
    csvTitle: PropTypes.string,
    costColumnsArray: PropTypes.array.isRequired,
    isDownloadAsCsv: PropTypes.bool,
    totalSummaryItems: PropTypes.array,
    isResize: PropTypes.bool,
    isPaging: PropTypes.bool,
    isVirtual: PropTypes.bool,
    isVisibility: PropTypes.bool,
    numCostDecimal: PropTypes.number,
    tableHeight: PropTypes.string,
    pageSizes: PropTypes.array,
    defaultPageSize: PropTypes.number,
    csvData: PropTypes.object,
    fetchCsvData: PropTypes.func,
    isTableSummaryRow: PropTypes.bool,
    isLoading: PropTypes.bool,
    isCurrencyInKFormat: PropTypes.bool,
    tableHeaderContent: PropTypes.func,
    currenciesFormatterComponent: PropTypes.func,
    getCurrencyNumber: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
  };

  messages = {
    sum: ' ',
  };
  constructor(props) {
    super(props);
    const { columns, data, columnWidths, defaultSorting, defaultHiddenColumnNames, pageSizes = [5, 10, 15, 0] } = props;
    this.state = {
      columns,
      rows: data || [],
      defaultSorting,
      columnWidths,
      hiddenColumnNames: defaultHiddenColumnNames,
      pageSizes,
      currencyFilterOperations: ['greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual'],
    };
  }

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };
  hiddenColumnNamesChange = (hiddenColumnNames) => {
    this.setState({ hiddenColumnNames });
  };

  kFormmater = (value) => kFormatterWithDecimal(value.value, 3);

  currenciesFormatter = (value) => {
    const { numCostDecimal = 0, isCurrencyInKFormat = true, getCurrencyNumber, overrideCurrency } = this.props;
    return isCurrencyInKFormat
      ? `${getCurrencyNumber(kFormatterWithDecimal(value.value, numCostDecimal), 2, {}, overrideCurrency)}`
      : getCurrencyNumber(value.value, 0, { roundNumber: true }, overrideCurrency);
  };

  dateFormatter = (value) => {
    const format = parseDateFormatFromDate(value.value);
    if (format) {
      return createDateDisplayStr(value.value, format);
    }
    return value.value;
  };
  percentageFormatter = (value) => percentStrNoCommas(Number(value.value));

  compareFunc = (a, b) => {
    const regex = new RegExp(/^\$|,/g);
    if (typeof a === 'string' && typeof b === 'string') {
      const numA = parseFloat(a.replace(regex, ''));
      const numB = parseFloat(b.replace(regex, ''));
      if (typeof numA === 'number' && typeof numB === 'number') {
        return numA < numB ? -1 : 1;
      }
    }
    return a < b ? -1 : 1;
  };

  customSorting = () => {
    const { columns } = this.props;
    const columnsWithSorting = [];
    columns.forEach((column) => {
      if (column?.name.toLowerCase().includes('cost')) {
        columnsWithSorting.push({ columnName: column.name, compare: this.compareFunc });
      }
    });
    return columnsWithSorting;
  };

  render() {
    const {
      tableHeaderContent = (props) => <TableHeaderRow.Content {...props} />,
      isDownloadAsCsv = true,
      csvData,
      fetchCsvData,
      isLoading,
      csvTitle,
      totalSummaryItems = [],
      isPaging,
      isVirtual,
      defaultPageSize,
      numericColumns,
      costColumnsArray,
      tableColumnExtensions,
      isResize,
      tableHeight,
      isVisibility = true,
      isTableSummaryRow,
      currenciesFormatterComponent,
    } = this.props;
    const { rows, columns, defaultSorting, columnWidths, hiddenColumnNames, pageSizes, currencyFilterOperations } =
      this.state;
    return (
      <>
        {isDownloadAsCsv && (
          <Col xs={12} md={12} lg={3} xl={3} style={{ textAlign: 'start' }}>
            <CustomCSVDownload
              data={[{ data: csvData || rows, filename: `${csvTitle}.csv` }]}
              fetchData={fetchCsvData}
              isLoading={isLoading || false}
              showDownloadIcon
              filesNumber={1}
            />
          </Col>
        )}
        <Col xs={12} md={12} lg={12} xl={12}>
          <Paper>
            <Grid rows={rows} columns={columns}>
              <SortingState defaultSorting={defaultSorting} />
              <FilteringState defaultFilters={[]} />
              <IntegratedSorting columnExtensions={this.customSorting()} />
              <IntegratedFiltering
                columnExtensions={[
                  {
                    columnName: 'usagedate',
                    predicate: (value, filter, row) =>
                      IntegratedFiltering.defaultPredicate(value, filter, row) ||
                      IntegratedFiltering.defaultPredicate(this.dateFormatter({ value }), filter, row),
                  },
                ]}
              />
              {isTableSummaryRow ? <SummaryState totalItems={totalSummaryItems} /> : null}
              {isTableSummaryRow ? <IntegratedSummary /> : null}
              {isPaging ? (
                <PagingState defaultCurrentPage={0} defaultPageSize={defaultPageSize || pageSizes[0]} />
              ) : null}
              {isPaging ? <IntegratedPaging /> : null}
              {numericColumns && (
                <DataTypeProvider
                  for={numericColumns}
                  formatterComponent={this.kFormmater}
                  availableFilterOperations={currencyFilterOperations}
                />
              )}
              {costColumnsArray && (
                <DataTypeProvider
                  for={costColumnsArray}
                  formatterComponent={currenciesFormatterComponent || this.currenciesFormatter}
                  availableFilterOperations={currencyFilterOperations}
                />
              )}
              <DataTypeProvider for={['usagedate']} formatterComponent={this.dateFormatter} />
              <TableWrapper columnExtensions={tableColumnExtensions || []} virtual={isVirtual} height={tableHeight} />
              {isResize ? (
                <TableColumnResizing
                  resizingMode="nextColumn"
                  columnWidths={columnWidths}
                  onColumnWidthsChange={this.changeColumnWidths}
                />
              ) : null}
              <TableHeaderRow contentComponent={tableHeaderContent} showSortingControls />
              {isTableSummaryRow && <TableSummaryRow messages={this.messages} />}
              {isPaging ? <PagingPanel pageSizes={pageSizes} /> : null}
              <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
              {isVisibility ? (
                <TableColumnVisibility
                  hiddenColumnNames={hiddenColumnNames}
                  onHiddenColumnNamesChange={this.hiddenColumnNamesChange}
                />
              ) : null}
              {isVisibility ? <Toolbar /> : null}
              {isVisibility ? <ColumnChooser /> : null}
            </Grid>
          </Paper>
        </Col>
      </>
    );
  }
}

const ObserverDevExpFilterColumnTable = withUserSettingsConsumer(DevExpFilterColumnTable);
export default ObserverDevExpFilterColumnTable;
