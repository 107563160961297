/* eslint-disable react-hooks/rules-of-hooks */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { API } from 'shared/utils/apiMiddleware';
import { getEmailFromRecipients, prepareNewRecipients } from 'shared/utils/sharedUtils.js';
import { CommitmentExpAlert, ServiceCostAlert, ServiceCostAlertUpdate, UpdatedCommitmentAlert } from '../types.ts';
import toast from 'shared/components/andtComponents/Toast';
import { AxiosError } from 'axios';

const root = '/api/v1/usage/alerts';

const fetchAlertsList = (): Promise<(CommitmentExpAlert | ServiceCostAlert)[]> =>
  API.get('billings', root).then((data) =>
    data.map((alert: CommitmentExpAlert | ServiceCostAlert) => ({
      ...alert,
      recipientsEmails: getEmailFromRecipients(alert.recipients),
    })),
  );

const checkSPPlans = (): Promise<boolean> =>
  API.get('billings', `${root}/check-sp-plans`).then((spRecordsLength) => {
    const parsedLength = parseInt(spRecordsLength, 10);
    return !Number.isNaN(parsedLength) && parsedLength > 0;
  });

const deleteAlert = (deletedAlertId: string) => () => API.del('billings', `${root}/${deletedAlertId}`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateAlerts = (payload: UpdatedCommitmentAlert[] | ServiceCostAlertUpdate[]): Promise<any> => {
  return API.post('billings', root, { body: payload });
};

export const useAlerts = () => {
  const queryClient = useQueryClient();
  return {
    fetchAlertsList: () =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.CUE_ALERTS],
        queryFn: fetchAlertsList,
      }),
    checkSPPlansExist: () =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.CUE_ALERTS, 'checkPlan'],
        queryFn: checkSPPlans,
        enabled: false,
      }),
    setAlertsLocally: (updatedAlerts: (CommitmentExpAlert | ServiceCostAlert)[]) => {
      queryClient.setQueryData([apiConstants.QUERY_KEYS.CUE_ALERTS], () => updatedAlerts);
    },
    deleteAlert: (deletedAlertId: string) =>
      useQuery({
        queryKey: [`${apiConstants.QUERY_KEYS.CUE_ALERTS}Delete`],
        queryFn: deleteAlert(deletedAlertId),
        enabled: false,
      }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateAlerts: (onSuccess?: (d: any) => void) =>
      useMutation({
        mutationFn: (alerts: UpdatedCommitmentAlert[] | ServiceCostAlertUpdate[]) =>
          updateAlerts(
            alerts.map((alert) => ({
              ...alert,
              recipients: prepareNewRecipients({ email: alert.recipientsEmails, recipients: alert.recipients }),
            })),
          ),
        onSuccess: (d) => {
          onSuccess?.(d);
        },
        onError: (error: AxiosError<{ message: string }>) => {
          toast.error(error?.response?.data?.message || 'Error updating alerts');
        },
      }),
  };
};
