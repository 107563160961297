export default class DivisionGroup {
  constructor(args) {
    this.parentAccountKey = args.accountKey;
    this.accountId = args.accountId;
    this.divisionId = args.divisionId;
    this.divisionName = args.divisionName;
    this.divisionNameDisplay = args.divisionNameDisplay;
    this.divisionCode = args.divisionCode;
    this.divisionTypeId = args.divisionTypeId;
    this.dbCreationTime = args.dbCreationTime;
    this.linkedAccounts = args.linkedAccounts?.map((linkedAccount) => ({
      ...linkedAccount,
      linkedAccountName: linkedAccount.linkedAccountName ?? linkedAccount.linkedAccountId,
    }));
    this.roleId = args.roleId;
  }
}
