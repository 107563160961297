/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import ButtonMultiDropdown from 'shared/components/andtComponents/ButtonMultiDropdown';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import CustomCSVDownload from '../../../buttons/CustomCSVDownload';
import icons from '../icons';
import styles from '../ChartButtonsMenu.module.scss';

const { OverwriteIcon, SaveReportIcon, SavePanelIcon, ExportCsvIcon, ExportPngIcon, GoalIcon } = icons.download;

const DownloadMenu = ({
  saveReportHandler,
  overwriteReportHandler,
  saveDashboardHandler,
  saveGoalHandler,
  overwriteDashboardHandler,
  exportToCSVHandler,
  exportToPNGHandler,
}) => {
  const options = [
    {
      handler: saveReportHandler,
      icon: SaveReportIcon,
      text: 'Save as Report',
      id: 'saveAsReport',
    },
    {
      handler: saveGoalHandler,
      icon: GoalIcon,
      text: 'Save as Goal',
      id: 'aveAsGoal',
      subMenuContent: (
        <>
          <li onClick={() => saveGoalHandler.onClick()} automation-id="create-new">
            <span>
              <b>Create new</b>
            </span>
          </li>
          {saveGoalHandler &&
            saveGoalHandler.options?.map((item) => (
              <li key={item.uuid} onClick={() => saveGoalHandler.onClick(item.uuid)} id={item.uuid}>
                <span>{item.title}</span>
              </li>
            ))}
        </>
      ),
    },
    {
      handler: overwriteReportHandler,
      icon: OverwriteIcon,
      text: 'Overwrite Existing Report',
      id: 'overwriteExistingReport',
    },
    {
      handler: exportToPNGHandler,
      icon: ExportPngIcon,
      text: 'Export as PNG',
      id: 'exportAsPNG',
    },
    {
      handler: exportToCSVHandler,
      id: 'exportAsCSV',
      simpleOptionContent: (
        <li className={styles.downloadCsvOptionContainer}>
          <CustomCSVDownload
            fetchData={exportToCSVHandler.onClick}
            filesNumber={1}
            isLoading={false}
            showDownloadIcon={false}
            hideText
            classNames={`btn-no-style ${styles.downloadCsvOption}`}
          >
            <span>
              <ExportCsvIcon /> Export as CSV
            </span>
          </CustomCSVDownload>
        </li>
      ),
    },
    {
      handler: overwriteDashboardHandler,
      icon: OverwriteIcon,
      text: 'Overwrite Existing Dashboard',
      id: 'overwriteExistingDashboard',
    },
    {
      handler: saveDashboardHandler,
      icon: SavePanelIcon,
      text: 'Save as Dashboard Panel',
      id: 'saveAsDashboardPanel',
    },
  ];
  return (
    <ButtonMultiDropdown
      position="left"
      dropdownProps={{
        tooltipText: 'Save & Export Options',
        isSecondary: false,
        icon: icons.menu.downloadIcon,
      }}
      automationid="save"
      items={options
        .filter((opt) => !!opt.handler)
        ?.map(({ handler, icon, text, content, simpleOptionContent, subMenuContent, id }, index) => ({
          id: id || index,
          simpleOption: isEmptyArray(handler.options),
          label: content || text,
          content: subMenuContent,
          simpleOptionContent,
          icon,
          disabled: handler.disabled,
          onClick: handler.onClick,
        }))}
    />
  );
};

DownloadMenu.propTypes = {
  saveReportHandler: PropTypes.object.isRequired,
  saveGoalHandler: PropTypes.object.isRequired,
  overwriteReportHandler: PropTypes.object.isRequired,
  saveDashboardHandler: PropTypes.object.isRequired,
  overwriteDashboardHandler: PropTypes.object.isRequired,
  exportToCSVHandler: PropTypes.object.isRequired,
  exportToPNGHandler: PropTypes.object.isRequired,
};

export default DownloadMenu;
