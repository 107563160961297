import React from 'react';
import PropTypes from 'prop-types';
import HeatMapCard from 'recommendationsNew/components/heatMap/heatMapCards/heatMapCard';
import useHeatMap from 'recommendationsNew/hooks/react-query/useHeatMap';
import { useHeatMapContext } from 'recommendationsNew/components/heatMap/contexts/heatMapContext';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext';
import useHeatMapSummary from 'recommendationsNew/hooks/react-query/useHeatMapSummary';

import classes from '../heatMap.module.scss';

const HeatMapCardList = ({ onCardClose = () => {} }) => {
  const { heatMapSortByOption: sortByContext, heatMapGroupByOptions, isHeatMapClosed } = useHeatMapContext();
  const { recommendationFilters: filtersContext, recommendationExternalFilters: externalFilterContext } =
    useRecommendationsNewContext();

  const heatMapHook = useHeatMap(!isHeatMapClosed);
  const {
    data: heatMapData,
    isLoading: isHeatMapDataLoading,
    isFetching,
  } = heatMapHook.fetchRecommendationsHeatMap({
    filters: filtersContext,
    groupBy: heatMapGroupByOptions?.map((i) => i.id),
    sortBy: sortByContext,
    externalFilters: externalFilterContext,
  });

  const heatmapSummaryHook = useHeatMapSummary();
  const { data: summary } = heatmapSummaryHook.fetchHeatMapSummary(filtersContext);

  return (
    <div className={classes.heatMapCardsContainer}>
      <div className={classes.cardsList}>
        {heatMapGroupByOptions?.map((option) => (
          <HeatMapCard
            key={option?.id}
            summaryData={{ totalCount: summary?.totalCount, totalSavings: summary?.totalSavings }}
            groupByOption={option}
            isLastPage={heatMapData?.[option?.id]?.isLastPage}
            onCardClose={onCardClose}
            optionData={heatMapData?.[option?.id]?.page}
            sortBy={sortByContext}
            isHeatMapDataLoading={isHeatMapDataLoading || isFetching}
          />
        ))}
      </div>
    </div>
  );
};

HeatMapCardList.propTypes = {
  onCardClose: PropTypes.func,
};

export default HeatMapCardList;
