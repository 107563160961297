import React from 'react';
import { palette } from 'shared/constants/colorsConstants.js';

const CustomYAxisTick = ({ x, y, payload, formattedValue, fill, fontSize }) => {
  return (
    <text x={x} y={y} dy={3} textAnchor="end" fill={fill || palette.gray[450]} style={{ fontSize: fontSize || '11px' }}>
      {formattedValue || payload.value}
    </text>
  );
};
export default CustomYAxisTick;
