import { useFormContext } from 'react-hook-form';
import React from 'react';
import classNames from 'classnames';
import Select from 'shared/components/andtComponents/SimpleSelect';
import Input from 'shared/components/andtComponents/Input';
import {
  PopoverTrigger,
  Popover,
  PopoverContent,
  PopoverPortal,
  GenerateIcon,
  ICONS,
} from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { ONBOARDING_FIELDS } from '../../utils/OnboardingConstants';
import styles from './CustomerInput.module.scss';
import Button from 'shared/components/andtComponents/Button';
import { useMspCustomers } from '~/users/new-user-management/hooks/reactQuery/useMspCustomers.js';
import { GCP_ONBOARDING_FIELDS } from '~/app/containers/Onboarding/GcpOnboarding/GcpOnboardingConstants.jsx';
import { AWS_ONBOARDING_FIELDS } from '~/app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants.js';
import { prepareLinkAccountLabel } from '~/recommendations/containers/Dashboard/helpers/dataPrepareHelpers.js';
import { CLOUD_TYPE_IDS } from '~/users/constants/usersConstants.js';
import PropTypes from 'prop-types';

const CustomerInput = ({ autoAssignEnabled }) => {
  const {
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();
  const [selectExistingCustomerOpen, setSelectExistingCustomerOpen] = React.useState(false);
  const existingCustomerSelected = getValues(ONBOARDING_FIELDS.EXISTING_CUSTOMER_SELECTED);
  const setExistingCustomerSelected = (value) => {
    setValue(ONBOARDING_FIELDS.EXISTING_CUSTOMER_SELECTED, value);
    setValue(ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED, false);
  };
  const { usersStore, appStore } = useRootStore();
  const { fetchMspCustomersData } = useMspCustomers();
  const { data: customers = [] } = fetchMspCustomersData({
    enabled: appStore.isKeyCloakManagement,
  });
  const customerDivisionsList = appStore.isKeyCloakManagement
    ? customers
        .filter((c) => c.payers?.every((p) => p.isFullyDedicated))
        .map((c) => ({
          name: c.id,
          valueName: c.name,
          displayName: c.name,
        }))
    : Object.entries(usersStore.getAllCustomerDivisionUserAccounts() || {})
        .filter(([, item]) => Object.values(item)[0].accountType === 'dedicated')
        .map(([displayName, item]) => {
          const name = Object.values(item)[0].divisionName;
          return {
            name,
            valueName: displayName,
            displayName: displayName !== name ? `${displayName} (${name})` : name,
            domain: Object.values(item)[0].domain,
          };
        });
  const customer = getValues(ONBOARDING_FIELDS.RESELLER_CUSTOMER);
  const customerId = getValues(ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID);
  const setCustomer = (value) => setValue(ONBOARDING_FIELDS.RESELLER_CUSTOMER, value);
  const setCustomerId = (value) => setValue(ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID, value);

  const autoAssignLinkedAccounts = getValues(AWS_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS);
  const setAutoAssignLinkedAccounts = (value) => setValue(AWS_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS, value);

  const renderExistingCustomerSelect = () =>
    customerDivisionsList.length > 0 && (
      <Popover
        open={selectExistingCustomerOpen}
        onOpenChange={() => setSelectExistingCustomerOpen(!selectExistingCustomerOpen)}
      >
        <PopoverTrigger>
          <a>Select Existing</a>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent className={styles.popoverContent}>
            <Select
              name="customer-name"
              value={customerDivisionsList.some((item) => item.name === customer) ? customer : undefined}
              placeholder="Select Customer"
              options={customerDivisionsList.map((item) => ({
                value: item.name,
                label: item.displayName,
              }))}
              isInvalid={!!errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
              invalidComponent={errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
              onChange={(value) => {
                const item = customerDivisionsList.find((item) => item.name === value);
                setCustomer(item?.valueName);
                setCustomerId(item?.name);
                setValue(
                  ONBOARDING_FIELDS.CUSTOMER_DOMAIN,
                  customerDivisionsList.find((item) => item.name === value)?.domain || '',
                );
                setSelectExistingCustomerOpen(false);
                setExistingCustomerSelected(!!item);
              }}
              className={styles.input}
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    );
  const renderClearButtonComponent = () => (
    <Button
      text={''}
      isTextButton
      className={{ button: styles.clear }}
      onClick={() => {
        setExistingCustomerSelected(false);
        setCustomerId('');
        setCustomer('');
        setValue(ONBOARDING_FIELDS.CUSTOMER_DOMAIN, '');
      }}
      icon={<GenerateIcon iconName={ICONS.xMark.name} />}
    />
  );
  const renderCustomerInput = () => {
    if (appStore.isKeyCloakManagement && existingCustomerSelected) {
      return (
        <div className={styles.customerInputs}>
          <div>
            <label htmlFor="customer-name">Customer</label>
            <div className={styles.inputContainer}>
              <Input
                name="customer-name"
                value={customer}
                disabled={existingCustomerSelected}
                isSearch={existingCustomerSelected}
                searchComponent={existingCustomerSelected ? renderClearButtonComponent() : null}
                placeholder="Type name"
                isInvalid={!!errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
                invalidComponent={errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
                onChange={(e) => {
                  setCustomer(e.target.value);
                  setExistingCustomerSelected(false);
                }}
                className={styles.input}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className={styles.customerInputs}>
        <div>
          <label htmlFor="customer-name">Customer Name</label>
          <div className={styles.inputContainer}>
            <Input
              name="customer-name"
              value={customer}
              disabled={existingCustomerSelected}
              isSearch={existingCustomerSelected}
              searchComponent={existingCustomerSelected ? renderClearButtonComponent() : null}
              placeholder="Type name"
              isInvalid={!!errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
              invalidComponent={errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER]}
              onChange={(e) => {
                setCustomer(e.target.value);
                setExistingCustomerSelected(false);
              }}
              className={styles.input}
            />
          </div>
        </div>
        <div>
          <label htmlFor="customer-id">Customer Id (optional)</label>
          <div className={styles.inputContainer}>
            <Input
              name="customer-id"
              value={customerId}
              disabled={existingCustomerSelected}
              isSearch={existingCustomerSelected}
              searchComponent={existingCustomerSelected ? renderClearButtonComponent() : null}
              placeholder="Type id"
              isInvalid={!!errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID]}
              invalidComponent={errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID]}
              onChange={(e) => {
                setCustomerId(e.target.value);
                setExistingCustomerSelected(false);
              }}
              className={styles.input}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={styles.header}>
        <p>Customer Details</p>
        {renderExistingCustomerSelect()}
      </div>
      <div className={styles.selectBlock}>
        {appStore.isKeyCloakManagement && !existingCustomerSelected ? (
          <div className={styles.customerLine}>
            {renderCustomerInput()}
            <div className={styles.inputContainer}>
              <label htmlFor="customer-admin-email">Customer Admin Email:</label>
              <Input
                name="customer-admin-email"
                value={getValues(ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL)}
                placeholder="Type Email"
                isInvalid={!!errors[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL]}
                invalidComponent={errors[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL]}
                onChange={(e) => {
                  setValue(ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL, e.target.value);
                }}
                className={styles.input}
              />
            </div>
          </div>
        ) : (
          <div className={styles.customerLine}>{renderCustomerInput()}</div>
        )}
        {autoAssignEnabled && (
          <Checkbox
            className={styles.checkbox}
            isChecked={!!autoAssignLinkedAccounts}
            isDisabled={getValues(GCP_ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED)}
            onChange={() => setAutoAssignLinkedAccounts(!autoAssignLinkedAccounts)}
            text={`Auto assign ${prepareLinkAccountLabel(
              getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID),
              false,
            ).toLowerCase()} to the customer`}
          />
        )}
        {!!autoAssignLinkedAccounts && getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID) === CLOUD_TYPE_IDS.AWS && (
          <div>
            <label className={styles.excludeLinkedAccountsLabel} htmlFor="customer-name">
              Excluded linked accounts
              <span>
                Define the pattern for linked accounts that are excluded from the auto-assignment process of accounts to
                customers. You can use ‘*’ as a wildcard in this pattern.
                <br />
                For example: *SP*. (to exclude any linked accounts with SP in their name)
              </span>
            </label>
            <Input
              name="customer-excluded-linked-accounts"
              value={getValues(AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH)}
              placeholder="Type pattern"
              isInvalid={!!errors[AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH]}
              invalidComponent={errors[AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH]}
              onChange={(e) => {
                setValue(AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH, e.target.value);
              }}
              className={styles.input}
            />
          </div>
        )}
      </div>
      {!appStore.isKeyCloakManagement && !existingCustomerSelected && (
        <Checkbox
          primary
          className={classNames(styles.checkbox, styles.scimCheckbox)}
          isChecked={!!getValues(ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED)}
          onChange={() => {
            const newValue = !getValues(ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED);
            setValue(ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED, newValue);
            if (newValue) {
              setValue(ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS, 1);
            }
          }}
          text="Self User Managed Customer (SSO User Provisioning/SCIM)"
        />
      )}
    </>
  );
};

CustomerInput.propTypes = {
  autoAssignEnabled: PropTypes.bool,
};

export default CustomerInput;
