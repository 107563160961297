import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import Stepper from 'shared/components/andtComponents/Stepper/Stepper';
import { hashText } from 'shared/utils/strUtil';
import { ReactComponent as AWSIcon } from 'shared/img/cloud-providers/simple/aws.svg';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import {
  AWS_ONBOARDING_FIELDS,
  AWS_ONBOARDING_STEPS,
  AWS_ONBOARDING_STEPS_SHORT_TITLES,
} from './AwsOnboardingConstants';
import useFormValidation from './hooks/useFormValidation';
import useFormNavigation from './hooks/useFormNavigation';
import styles from './AwsOnboarding.module.scss';
import CreateCurPage from './pages/CreateCurPage/CreateCurPage';
import DetailsPage from './pages/DetailsPage/DetailsPage';
import GrantAccessPage from './pages/GrantAccessPage/GrantAccessPage';
import ValidateAccessPage from './pages/ValidateAccessPage/ValidateAccessPage';
import BillingProfilePage from './pages/BillingProfilePage/BillingProfilePage';
import AccountDetailsModal from '../SharedPages/AccountDetailsModal';
import ProcessDataPage from '../SharedPages/ProcessDataPage/ProcessDataPage';
import LoadingGif from '../assets/page-loading.gif';
import { getPageForPausedOnboarding, trimAccId } from './AwsOnboardingUtils';
import LinkedAccountsPage from './pages/LinkedAccountsPage/LinkedAccountsPage';
import Footer from '../SharedPages/Footer/Footer';
import { ONBOARDING_TYPES } from '../utils/OnboardingConstants';
import WarningsList from '../SharedPages/WarningsList/WarningsList';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';
import { CLOUD_TYPE_IDS } from '~/users/constants/usersConstants.js';
import HandshakeCountdown from '../SharedPages/HandshakeCountdown/HandshakeCountdown';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const getAwsOnBoardingSteps = (name) => ({
  [AWS_ONBOARDING_STEPS.CREATE_CUR_FILE]: {
    // TODO: enable when videos are ready onboardingType === ONBOARDING_TYPES.AUTOMATIC },
    title: 'Create Cost & Usage Report (CUR)',
    titleShort: AWS_ONBOARDING_STEPS_SHORT_TITLES.CREATE_CUR_FILE,
    component: CreateCurPage,
  },
  [AWS_ONBOARDING_STEPS.AWS_DETAILS]: {
    title: 'Add details from AWS for your CUR',
    automaticTitle: `Add details from AWS for your CUR & grant ${name} access`,
    titleShort: AWS_ONBOARDING_STEPS_SHORT_TITLES.AWS_DETAILS,
    component: DetailsPage,
    countdown: true,
  },
  [AWS_ONBOARDING_STEPS.GRANT_ACCESS]: {
    title: `Grant ${name} access to your AWS root account`,
    titleShort: AWS_ONBOARDING_STEPS_SHORT_TITLES.GRANT_ACCESS,
    component: GrantAccessPage,
    countdown: true,
  },
  [AWS_ONBOARDING_STEPS.VALIDATE_ACCESS]: {
    title: `Validate ${name}'s access to your CUR`,
    titleShort: AWS_ONBOARDING_STEPS_SHORT_TITLES.VALIDATE_ACCESS,
    component: ValidateAccessPage,
    countdown: true,
    nextText: 'Validate',
  },
  [AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS]: {
    title: 'Check the status of linked accounts',
    titleShort: AWS_ONBOARDING_STEPS_SHORT_TITLES.LINKED_ACCOUNTS,
    component: LinkedAccountsPage,
    noReturn: true,
    skip: true,
  },
  [AWS_ONBOARDING_STEPS.BILLING_PROFILE]: {
    title: 'Choose the type of billing profile',
    titleShort: AWS_ONBOARDING_STEPS_SHORT_TITLES.BILLING_PROFILE,
    component: BillingProfilePage,
    skip: true,
  },
  [AWS_ONBOARDING_STEPS.PROCESS_DATA]: {
    title: 'Data Processing',
    titleShort: AWS_ONBOARDING_STEPS_SHORT_TITLES.PROCESS_DATA,
    component: ProcessDataPage,
    nextText: 'Done',
    nextEnabled: AWS_ONBOARDING_FIELDS.INVOICE_SUCCESS,
  },
});

const AWSOnboarding = ({ usersStore, brandContext }) => {
  const onBoardingSteps = getAwsOnBoardingSteps(brandContext.name);
  const { getValues } = useFormContext();
  const onboardingType = getValues(AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const screens = [
    { id: AWS_ONBOARDING_STEPS.CREATE_CUR_FILE, disabled: onboardingType === ONBOARDING_TYPES.AUTOMATIC },
    { id: AWS_ONBOARDING_STEPS.AWS_DETAILS },
    { id: AWS_ONBOARDING_STEPS.GRANT_ACCESS, disabled: onboardingType === ONBOARDING_TYPES.AUTOMATIC },
    { id: AWS_ONBOARDING_STEPS.VALIDATE_ACCESS },
    {
      id: AWS_ONBOARDING_STEPS.LINKED_ACCOUNTS,
      subTitle: '(Optional)',
      isDotted: true,
      disabled: !!getValues(AWS_ONBOARDING_FIELDS.AUTO_SETUP_LINKED_ACCOUNTS),
    },
    {
      id: AWS_ONBOARDING_STEPS.BILLING_PROFILE,
      subTitle: '(Optional)',
      isDotted: true,
      disabled: !usersStore.isCurrentUserReseller,
    },
    { id: AWS_ONBOARDING_STEPS.PROCESS_DATA },
  ]
    .filter(({ disabled }) => !disabled)
    .map(({ id, subTitle, isDotted }) => ({
      id,
      subTitle,
      isDotted,
      title: onBoardingSteps[id].titleShort,
    }));

  const screen = getValues(AWS_ONBOARDING_FIELDS.SCREEN);
  const startTime = getValues(AWS_ONBOARDING_FIELDS.START_TIME);
  const screenIndex = screens.findIndex((scr) => scr.id === screen);
  useWatch(AWS_ONBOARDING_FIELDS.SCREEN);
  useWatch(AWS_ONBOARDING_FIELDS.START_TIME);

  const { prevStep, nextStep } = useFormNavigation({ screen, screens });

  if (screen === AWS_ONBOARDING_STEPS.ACCOUNT_TYPE) {
    return <AccountDetailsModal nextStep={nextStep} autoAssignEnabled automaticEnabled />;
  }

  const { component: Page, title, automaticTitle, countdown } = onBoardingSteps[screen];
  const getTitle = () => {
    if (onboardingType === ONBOARDING_TYPES.AUTOMATIC && automaticTitle) {
      return automaticTitle;
    }
    return title;
  };

  return (
    <div>
      <div className={styles.container}>
        <Stepper activeStepIndex={screenIndex} steps={screens} />
        <div className={styles.pageScreen}>
          <div className={styles.header}>
            <div className={styles.headerContent}>
              {screenIndex + 1}. {getTitle()}
            </div>
            {countdown && <HandshakeCountdown startTime={startTime} />}
            <div className={styles.headerLogo}>
              <AWSIcon />
            </div>
          </div>
          <div className={styles.body}>{Page && <Page usersStore={usersStore} nextStep={nextStep} />}</div>
          <Footer steps={onBoardingSteps} prevStep={prevStep} nextStep={nextStep} screen={screen} />
        </div>
      </div>
    </div>
  );
};

const AWSOnboardingWrapper = ({ usersStore, brandContext }) => {
  const isMSP = usersStore.isCurrentUserReseller;
  const location = useLocation();
  const { appStore } = useRootStore();
  const brand = useBrand();
  const DEFAULT_FORM_STATE = {
    accountId: '',
    accountName: '',
    s3BucketName: '',
    s3BucketRegion: '',
    roleARN: '',
    [AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE]: ONBOARDING_TYPES.MANUAL,
    [AWS_ONBOARDING_FIELDS.ACCOUNT_TYPE]: isMSP ? 'dedicated' : null,
    [AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER]: '',
    [AWS_ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID]: '',
    [AWS_ONBOARDING_FIELDS.EXISTING_CUSTOMER_SELECTED]: false,
    [AWS_ONBOARDING_FIELDS.BILLING_PROFILE]: {
      rebilling: 1,
      copyAwsCredits: 1,
    },
    [AWS_ONBOARDING_FIELDS.IS_MSP]: isMSP,
    [AWS_ONBOARDING_FIELDS.USER_KEY]: usersStore.currentDisplayedUserKey,
    [AWS_ONBOARDING_FIELDS.ACCOUNT_KEY]: usersStore.currDispUserAccountKey,
    screen: AWS_ONBOARDING_STEPS.ACCOUNT_TYPE,
  };

  const searchParams = new URLSearchParams(location.search);
  const accountId = searchParams.get('accountId');
  const screen = searchParams.get('screen');

  const { fetchOnboardingStatus } = useOnboarding();
  const { data: onboarding, isLoading } = fetchOnboardingStatus(accountId, { enabled: !!accountId });

  const [isLoadingDebounced, setIsLoadingDebounced] = useState(!!accountId);

  useEffect(() => {
    if (isLoading) {
      setIsLoadingDebounced(true);
    } else {
      setTimeout(() => setIsLoadingDebounced(false), 1000);
    }
  }, [isLoading]);

  const methods = useForm({
    defaultValues: {
      ...DEFAULT_FORM_STATE,
      [AWS_ONBOARDING_FIELDS.SCREEN]: screen || DEFAULT_FORM_STATE.screen,
      [AWS_ONBOARDING_FIELDS.CLOUD_TYPE_ID]: CLOUD_TYPE_IDS.AWS,
    },
    // eslint-disable-next-line react-hooks/rules-of-hooks
    resolver: (currentStream) => useFormValidation(currentStream, appStore.isKeyCloakManagement, brand),
    shouldFocusError: true,
  });

  useEffect(() => {
    if (!onboarding || !accountId || accountId === methods.getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID)) {
      return;
    }
    if (!onboarding?.accountId) {
      methods.reset({
        ...DEFAULT_FORM_STATE,
        [AWS_ONBOARDING_FIELDS.ACCOUNT_ID]: accountId,
      });
      hashText(trimAccId(accountId), 8).then((hash) => {
        methods.setValue(AWS_ONBOARDING_FIELDS.EXTERNAL_ID, hash);
      });
    } else {
      const newScreen = screen || getPageForPausedOnboarding(onboarding);
      methods.reset({
        ...DEFAULT_FORM_STATE,
        [AWS_ONBOARDING_FIELDS.ACCOUNT_ID]: onboarding.accountId,
        [AWS_ONBOARDING_FIELDS.ACCOUNT_NAME]: onboarding.accountName,
        [AWS_ONBOARDING_FIELDS.BUCKET_NAME]: onboarding.s3BucketName,
        [AWS_ONBOARDING_FIELDS.BUCKET_REGION]: onboarding.s3BucketRegion,
        [AWS_ONBOARDING_FIELDS.ROLE_ARN]: onboarding.roleARN,
        [AWS_ONBOARDING_FIELDS.EXTERNAL_ID]: onboarding.externalId,
        [AWS_ONBOARDING_FIELDS.STARTED]: true,
        [AWS_ONBOARDING_FIELDS.ONBOARDING_TYPE]: onboarding.onboardingType,
        [AWS_ONBOARDING_FIELDS.VALIDATION_STARTED]: onboarding.validationStarted,
        [AWS_ONBOARDING_FIELDS.ACCOUNT_TYPE]: onboarding.accountType,
        [AWS_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS]: onboarding.autoAssignLinkedAccounts,
        [AWS_ONBOARDING_FIELDS.AUTO_SETUP_LINKED_ACCOUNTS]: onboarding.autoSetupLinkedAccounts,
        [AWS_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH]: onboarding.excludedLinkedAccountMatch,
        [AWS_ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED]: onboarding.isCustomerSelfManaged,
        [AWS_ONBOARDING_FIELDS.CUSTOMER_DOMAIN]: onboarding.resellerCustomerDomain,
        [AWS_ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL]: onboarding.resellerCustomerEmail,
        [AWS_ONBOARDING_FIELDS.BILLING_PROFILE]: {},
        [AWS_ONBOARDING_FIELDS.SCREEN]: newScreen,
        [AWS_ONBOARDING_FIELDS.START_TIME]: onboarding.startTime,
      });
    }
  }, [onboarding?.accountId]);

  return (
    <FormProvider {...methods}>
      {isLoadingDebounced ? (
        <div className={styles.loadingPage}>
          <img src={LoadingGif} alt="loading" />
        </div>
      ) : (
        <div className="h-100">
          <AWSOnboarding usersStore={usersStore} brandContext={brandContext} />
          {onboarding?.validationStatus?.errors?.length ? (
            <WarningsList cloudTypeId={CLOUD_TYPE_IDS.AWS} warnings={onboarding.validationStatus.errors} />
          ) : null}
        </div>
      )}
    </FormProvider>
  );
};

AWSOnboardingWrapper.propTypes = {
  usersStore: PropTypes.object.isRequired,
  brandContext: PropTypes.object.isRequired,
};

AWSOnboarding.propTypes = {
  usersStore: PropTypes.object.isRequired,
  brandContext: PropTypes.object.isRequired,
};

export default AWSOnboardingWrapper;
