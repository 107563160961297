import React from 'react';
import PropTypes from 'prop-types';
import ArrowUpThickIcon from 'mdi-react/ArrowUpThickIcon';
import ArrowDownThickIcon from 'mdi-react/ArrowDownThickIcon';
import { percentStr } from 'shared/utils/strUtil';

const adjustArrowDirection = (isUp, style) =>
  isUp ? (
    <ArrowUpThickIcon color={style.color} size={style.size} />
  ) : (
    <ArrowDownThickIcon color={style.color} size={style.size} />
  );

const UsageCostInfoGrid = React.memo(({ color = '#4ce1b6', value, percent, percentArrowIsUp, iconStyle }) => {
  const isDataValid = isFinite(percent);
  const percentAsStr = isDataValid ? percentStr(percent) : 0;
  return (
    isDataValid && (
      <>
        {adjustArrowDirection(percentArrowIsUp, iconStyle)}
        <span style={{ padding: '0 1px' }}>{percentAsStr}</span>
        <span style={{ display: 'block' }}> Over last month</span>
      </>
    )
  );
});

UsageCostInfoGrid.propTypes = {
  color: PropTypes.string,
  value: PropTypes.number.isRequired,
  percent: PropTypes.string.isRequired,
  percentArrowIsUp: PropTypes.bool,
  iconStyle: PropTypes.object.isRequired,
};

export default UsageCostInfoGrid;
