import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from '@pileus-cloud/anodot-frontend-common';
import Button from 'shared/components/andtComponents/Button.js';
import { getDateRange } from 'recommendationsNew/components/heatMap/heatMapActions/views/utils.jsx';
import { useRecommendationsNewContext } from 'recommendationsNew/contexts/recommendationsNewContext.jsx';
import useRecViews from 'recommendationsNew/hooks/react-query/useRecViews.js';
import { VIEWS_TYPE } from 'recommendationsNew/consts.js';
import { useHeatMapContext } from '../../contexts/heatMapContext.jsx';
import styles from './viewActions.module.scss';

const saveIcon = () => <GenerateIcon iconName={ICONS.floppyDisk.name} className={styles.iconPadding} />;
const renameIcon = () => <GenerateIcon iconName={ICONS.penToSquare.name} className={styles.iconPadding} />;
const defaultIcon = () => <GenerateIcon iconName={ICONS.badgeCheck.name} className={styles.iconPadding} />;
const duplicateIcon = () => <GenerateIcon iconName={ICONS.copy.name} className={styles.iconPadding} />;
const copyUrlIcon = () => <GenerateIcon iconName={ICONS.bracketsCurly.name} className={styles.iconPadding} />;
const deleteIcon = () => <GenerateIcon iconName={ICONS.deleteRegular.name} className={styles.iconPadding} />;
export const ViewActions = ({ view, changeViewName, setIsOpen, viewType }) => {
  const { useDeleteViewPanel, useUpdateViewPanel, useCreateViewPanel } = useRecViews();
  const { recommendationFilters, showDoneCompletedOnly } = useRecommendationsNewContext();
  const { isWasteChart, heatMapGroupByOptions } = useHeatMapContext();
  const { mutate: deleteView } = useDeleteViewPanel();
  const { mutate: updateView } = useUpdateViewPanel();
  const { mutate: createView } = useCreateViewPanel();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const closeMenus = () => {
    setIsMenuOpen(false);
    setIsOpen(false);
  };

  const toggleOpen = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles.actionWrapper} onClick={(e) => toggleOpen(e)}>
      <Popover open={isMenuOpen} onOpenChange={() => setIsMenuOpen(!isMenuOpen)}>
        <PopoverTrigger as="button" type="button">
          <GenerateIcon iconName={ICONS.verticalDots.name} />
        </PopoverTrigger>

        <PopoverPortal>
          <PopoverContent sideOffset={10} side="bottom" className={styles.actionPopoverContent}>
            <Button
              isTextButton
              text="Save changes"
              automationid="save-view-filters"
              className={{ button: styles.menuItem }}
              icon={saveIcon}
              onClick={() => {
                updateView({
                  ...view,
                  context_data: {
                    recommendationFilters: {
                      ...recommendationFilters,
                      dateRange: getDateRange(recommendationFilters, showDoneCompletedOnly),
                    },
                    isWasteChart,
                    heatMapGroupByOptions,
                  },
                  viewType,
                });
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Rename"
              automationid="rename-view"
              className={{ button: styles.menuItem }}
              icon={renameIcon}
              onClick={() => {
                changeViewName({ id: view.id, name: view.view_name });
                setIsMenuOpen(false);
              }}
            />
            <Button
              isTextButton
              text={view.is_default ? 'Set Main View as default' : 'Set as Default View'}
              automationid="set-default-view"
              className={{ button: styles.menuItem }}
              icon={defaultIcon}
              onClick={() => {
                updateView({ ...view, is_default: !view.is_default, viewType });
                closeMenus();
              }}
              disabled={viewType !== VIEWS_TYPE.Organization}
            />
            <Button
              isTextButton
              text="Duplicate"
              automationid="duplicate-view"
              className={{ button: styles.menuItem }}
              icon={duplicateIcon}
              onClick={() => {
                createView({
                  viewName: `Copy of ${view.view_name}`,
                  contextData: {
                    recommendationFilters: {
                      ...recommendationFilters,
                      dateRange: getDateRange(recommendationFilters, showDoneCompletedOnly),
                    },
                    isWasteChart,
                    heatMapGroupByOptions,
                  },
                  isDefault: false,
                  viewType,
                });
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Copy this view URL"
              automationid="copy-view-url"
              className={{ button: `${styles.menuItem} ${styles.paddingIcon}` }}
              icon={copyUrlIcon}
              onClick={() => {
                navigator.clipboard.writeText(
                  window.location.origin + location.pathname + (location.search || `?viewId=${view.id}`),
                );
                closeMenus();
              }}
            />
            <Button
              isTextButton
              text="Delete"
              automationid="delete-view"
              className={{ button: styles.menuItem }}
              icon={deleteIcon}
              onClick={() => {
                deleteView(view.id);
                navigate(location.pathname, { replace: true });
                closeMenus();
              }}
            />
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );
};

ViewActions.propTypes = {
  view: PropTypes.object.isRequired,
  changeViewName: PropTypes.func.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  viewType: PropTypes.string.isRequired,
};
