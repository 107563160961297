import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { AWS_ONBOARDING_FIELDS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import { ReactComponent as ByodProcessSVG } from './assets/byod-process.svg';
import WarningsList from '../../../SharedPages/WarningsList/WarningsList';
import styles from './ProcessDataPage.module.scss';
import { CLOUD_TYPE_IDS } from '~/users/constants/usersConstants.js';

const ProcessDataPage = () => {
  const { getValues, setValue } = useFormContext();
  const accountId = getValues(AWS_ONBOARDING_FIELDS.ACCOUNT_ID);
  const invoiceSuccess = getValues(AWS_ONBOARDING_FIELDS.INVOICE_SUCCESS);
  const { fetchOnboardingStatus } = useOnboarding();
  const [invoiceFailed, setInvoiceFailed] = useState(false);
  const { data: status } = fetchOnboardingStatus(accountId, {
    enabled: !invoiceFailed && !invoiceSuccess,
    refetchInterval: 15 * 1000,
  });
  const { invoiceStatus } = status || {};
  useEffect(() => {
    const isFailed = invoiceStatus?.status === 'FAILED' && status.invoiceShouldStart;
    const isSuccess = invoiceStatus?.status === 'SUCCESS';
    if (isSuccess) {
      setValue(AWS_ONBOARDING_FIELDS.INVOICE_SUCCESS, true);
      return;
    }
    if (isFailed) {
      setInvoiceFailed(true);
    }
  }, [status]);
  return (
    <div className={styles.container}>
      <WarningsList warnings={invoiceStatus?.errors || []} cloudTypeId="BYOD" />
      <ByodProcessSVG className={styles.illustration} />
      <p className={styles.title}>Onboarding is done</p>
      <p className={styles.desc}>
        When you click done, the wizard will be complete.
        <br />
        Your new account will be visible shortly in the &quot;Cloud Accounts&quot; page.
        <br />
        Once you upload files to the S3 bucket, they will be processed and cost data will be available in our platform
        in Multi-Cloud view.
      </p>
    </div>
  );
};

export default ProcessDataPage;
