import React from 'react';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import Delete from 'mdi-react/TrashCanOutlineIcon';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { Tooltip } from '@mui/material';
import { Container, Input, Row } from 'reactstrap';
import Slider from 'shared/components/form/Slider';
import DraggableList from 'shared/components/tables/DraggableList/index.tsx';
import Spinner from 'shared/components/andtComponents/Spinner';
import CustomModal from 'shared/components/andtComponents/Modal';
import { generateCloneName } from 'shared/utils/strUtil';
import { MapSpanToWidth, MapWidthToSpan } from 'recommendations/constants/recommendationsConstants';
import EditPanelRow from './CustomDashboardEditPanelRow';
import CustomDashboardPreviewPanelLayout from './CustomDashboardPreviewPanelLayout';

class EditOrCloneCustomDashboardModal extends React.Component {
  static propTypes = {
    isCloneMode: PropTypes.bool.isRequired,
    isTemplateMode: PropTypes.bool.isRequired,
    handlers: PropTypes.object.isRequired,
    dashboard: PropTypes.object.isRequired,
    existingDashboardsNamesAndIds: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    customDbSubStore: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      name: props.isCloneMode
        ? generateCloneName(
            props.dashboard.name,
            props.existingDashboardsNamesAndIds.map(({ name }) => name),
          )
        : props.dashboard.name,
      panelsDisplaySettings: cloneDeep(props.dashboard.panelsDisplaySettings),
      panelsIds: [...(props.dashboard.panelsIds || [])],
      nameValidError: '',
      tempSpanVal: null,
      clonedPanelSuffix: '',
      panels: null,
    };
  }

  async componentDidMount() {
    const { customDbSubStore, dashboard, isTemplateMode } = this.props;
    const { panelsIds } = this.state;

    this.setState({
      panels: await customDbSubStore.customDashboardModel.getPanelsByIds(
        panelsIds,
        isTemplateMode ? dashboard.accountId : undefined,
      ),
    });
  }
  handleClose = () => {
    const { onClose } = this.props;
    this.setState({ name: '', panelsIds: [], panelsDisplaySettings: null });
    onClose();
  };
  validateNewName = (e) => {
    const { dashboard, isCloneMode, existingDashboardsNamesAndIds } = this.props;
    const existingNames = existingDashboardsNamesAndIds
      .map(({ name }) => name)
      .filter((name) => isCloneMode || name !== dashboard.name);
    const { value } = e.target;
    let nameValidError = '';
    if (!value) {
      nameValidError = 'Name must have a value';
    } else if (existingNames.some((name) => name.toLowerCase() === value.toLowerCase())) {
      nameValidError = 'Name already exists';
    }
    this.setState({ name: value, nameValidError });
  };
  handleSaveEdit = () => {
    const { isCloneMode, handlers, dashboard, isTemplateMode } = this.props;
    const { nameValidError, name, panelsDisplaySettings, panelsIds, clonedPanelSuffix } = this.state;
    if (nameValidError) {
      return;
    }
    const data = { name, panelsDisplaySettings, panelsIds };
    if (isTemplateMode && isCloneMode) {
      handlers.cloneTemplate(dashboard, data, clonedPanelSuffix);
      return;
    }
    if (isCloneMode) {
      handlers.clone(dashboard, data, clonedPanelSuffix);
      return;
    }
    if (isTemplateMode) {
      handlers.saveTemplate(dashboard, data);
      return;
    }
    handlers.saveEdit(dashboard, data);
  };
  handleOrderChange = (fIndex, sIndex, tableData) => {
    const { panelsDisplaySettings } = this.state;
    const newOrder = [...tableData];
    const fPanel = tableData[fIndex];
    const settings = new Map(panelsDisplaySettings);

    newOrder.splice(fIndex, 1);
    newOrder.splice(sIndex, 0, fPanel);
    newOrder.forEach((data, index) => {
      const currSettings = settings.get(data.uuid);
      currSettings.order = index + 1;
      settings.set(data.uuid, currSettings);
    });

    this.setState({ panelsDisplaySettings: settings });
  };
  handleSpanChange = (e, span, panelId, commited) => {
    const { panelsDisplaySettings } = this.state;
    const settings = new Map(panelsDisplaySettings);

    if (commited) {
      const currSettings = settings.get(panelId);
      currSettings.span = MapWidthToSpan[span];
      settings.set(panelId, currSettings);
    }
    this.setState({
      panelsDisplaySettings: settings,
      tempSpanVal: commited ? null : { id: panelId, val: MapWidthToSpan[span] },
    });
  };
  handleRemovePanel = (e, panelId) => {
    e.preventDefault();
    const { panelsIds, panelsDisplaySettings } = this.state;
    const ids = [...panelsIds];
    const settings = new Map(panelsDisplaySettings);
    const index = panelsIds.findIndex((p) => p === panelId);
    if (index > -1) {
      ids.splice(index, 1);
      settings.delete(panelId);

      for (const panlId of ids) {
        const currOrder = settings?.get(panlId)?.order;
        if (currOrder > 1) {
          const currSettings = settings.get(panlId);
          currSettings.order = currOrder - 1;
          settings.set(panlId, currSettings);
        }
      }
    }
    this.setState({ panelsIds: ids, panelsDisplaySettings: settings });
  };
  handlePanelNameSuffixChange = (e) => {
    this.setState({ clonedPanelSuffix: e.target.value });
  };
  renderTableHeader = () => {
    const header = ['Panel Name', 'Panel Width', ' '];
    return (
      <EditPanelRow classSufix="th" className="header">
        {header.map((title) => (
          <h5 key={title}>{title}</h5>
        ))}
      </EditPanelRow>
    );
  };

  renderTableRow = (row, dragProps) => {
    const { tempSpanVal } = this.state;
    const { uuid, name, span } = row;
    return (
      <EditPanelRow classSufix="td">
        <span key={`${uuid}-${name}`} {...dragProps}>
          <GenerateIcon iconName={ICONS.gripDots.name} className="me-2 ml-n2" style={{ transform: 'rotate(90deg)' }} />
          <span>{name}</span>
        </span>
        <div className="range-span">
          <Slider
            key={`${uuid}-${span}`}
            name="span"
            id="span"
            value={tempSpanVal && tempSpanVal.id === uuid ? MapSpanToWidth[tempSpanVal.val] : MapSpanToWidth[span]}
            onChange={(e, value) => this.handleSpanChange(e, value, uuid)}
            onChangeCommitted={(e, value) => this.handleSpanChange(e, value, uuid, true)}
            step={null}
            valueLabelDisplay="off"
            marks={[
              {
                label: '25%',
                value: 25,
              },
              {
                label: '33%',
                value: 33,
              },
              {
                label: '50%',
                value: 50,
              },
              {
                label: '66%',
                value: 66,
              },
              {
                label: '75%',
                value: 75,
              },
              {
                label: '100%',
                value: 100,
              },
            ]}
          />
        </div>
        <div style={{ width: '100%', textAlign: 'right' }} {...dragProps}>
          <Tooltip title="Remove panel from dashboard">
            <button
              type="button"
              key={`${uuid}-remove`}
              className="btn-no-style"
              onClick={(e) => this.handleRemovePanel(e, uuid)}
            >
              <Delete />
            </button>
          </Tooltip>
        </div>
      </EditPanelRow>
    );
  };

  renderTableData = (tableData) => {
    const sortedByOrder = tableData.sort((itemA, itemB) => itemA.order - itemB.order);
    return (
      <DraggableList
        items={sortedByOrder}
        onChangeOrder={(fIndex, sIndex) => this.handleOrderChange(fIndex, sIndex, tableData)}
        renderItem={this.renderTableRow}
      />
    );
  };

  renderPanelsEditTable = () => {
    const { customDbSubStore } = this.props;
    const { panelsIds, panelsDisplaySettings, panels } = this.state;
    if (!panels) {
      return (
        <p style={{ marginTop: '15px' }}>
          <Spinner />
        </p>
      );
    }
    const tableData = customDbSubStore.customDashboardModel.prepareDashboardPanelsAsTableData(
      panelsIds,
      panelsDisplaySettings,
      panels,
    );
    if (!tableData.length) {
      return <p style={{ color: 'red', marginTop: '15px' }}>No Panels Found</p>;
    }
    return (
      <>
        {this.renderTableHeader()}
        {this.renderTableData(tableData)}
        <CustomDashboardPreviewPanelLayout panels={tableData} />
      </>
    );
  };
  renderClonedPanelNameSuffix = () => {
    const { isCloneMode } = this.props;
    const { clonedPanelSuffix } = this.state;
    if (!isCloneMode) {
      return null;
    }
    return (
      <>
        <br />
        <Row>
          <h5 className="mb-3">Cloned panels name suffix:</h5>
        </Row>
        <Row>
          <Input
            value={clonedPanelSuffix}
            onChange={(e) => this.handlePanelNameSuffixChange(e)}
            margin="normal"
            variant="outlined"
            placeholder="Leave blank for auto generated suffix"
            className="name-input"
          />
        </Row>
      </>
    );
  };
  render() {
    const { dashboard, onClose, isCloneMode, isTemplateMode } = this.props;
    const { name = '', nameValidError } = this.state;
    const getTitle = () => {
      if (isTemplateMode) {
        if (isCloneMode) {
          return 'Clone Template to Dashboards';
        }
        return 'Edit Template';
      }
      if (isCloneMode) {
        return 'Clone Dashboard';
      }
      return 'Edit Dashboard';
    };
    return (
      <CustomModal
        open={!!dashboard}
        title={getTitle()}
        onClose={onClose}
        overrideStyles={{ width: 700 }}
        saveDisabled={nameValidError}
        onSave={this.handleSaveEdit}
      >
        <Container>
          <Row>
            <h5>{isTemplateMode ? 'Template' : 'Dashboard'} name:</h5>
          </Row>
          <br />
          <Row>
            <Input
              value={name}
              onChange={this.validateNewName}
              margin="normal"
              variant="outlined"
              placeholder="Name"
              className="name-input"
            />
            {nameValidError && <p style={{ color: 'red', marginTop: '15px' }}>{nameValidError}</p>}
          </Row>
          {this.renderClonedPanelNameSuffix()}
          <br />
          <Row>{this.renderPanelsEditTable()}</Row>
        </Container>
      </CustomModal>
    );
  }
}

export default EditOrCloneCustomDashboardModal;
