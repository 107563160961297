import React, { useMemo, useState } from 'react';
import { ReactComponent as LinkedAccountsEmptyState } from './assets/linked-accounts-empty-state.svg';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { capitalize, cloneDeep } from 'lodash';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import {
  DataTypeProvider,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';
import { Grid, PagingPanel, TableColumnResizing, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { getZeroPadAccountId } from 'shared/utils/awsUtils';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableWrapper from 'shared/components/tables/TableWrapper';
import toast from 'shared/components/andtComponents/Toast';
import Button from 'shared/components/andtComponents/Button';
import Chip from 'shared/components/andtComponents/Chip';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import useTable from 'shared/hooks/customHooks/useTable';
import styles from './LinkedAccounts.module.scss';
import EditLinkedAccountNameModal from './EditLinkedAccountNameModal';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';
import useDataAccessAccounts from '~/users/new-user-management/hooks/reactQuery/useDataAccessAccounts.js';
import externalLinksFunctions from 'shared/utils/external-links.util';
import classNames from 'classnames';
import EmptyState from '../components/EmptyStates/EmptyState';

const CLOUD_CONFIG = {
  [CLOUD_TYPE_IDS.AWS]: {
    title: 'Connected Linked Account',
    infoTitle: 'linked account',
    idTitle: 'Linked Account ID',
    nameTitle: 'Linked Account Name',
    statusTitle: 'Verification Status',
    csvFileName: 'linked_accounts.csv',
  },
  [CLOUD_TYPE_IDS.AZURE]: {
    title: 'Connected Subscription',
    infoTitle: 'subscription',
    idTitle: 'Subscription ID',
    nameTitle: 'Subscription Name',
    statusTitle: 'Status',
    csvFileName: 'subscription.csv',
  },
  [CLOUD_TYPE_IDS.GCP]: {
    title: 'Connected Project',
    infoTitle: 'project',
    idTitle: 'Project Number',
    nameTitle: 'Project ID',
    statusTitle: 'Status',
    csvFileName: 'project.csv',
  },
};

const NoData = ({ searchText }) => {
  const mainText = searchText?.length ? 'Could not find the linked account' : 'Accounts were not onboarded yet';

  const subText = searchText?.length
    ? ['Refine your search to find the required linked account']
    : [
        'Sub accounts are AWS Linked accounts, Azure subscriptions and GCP projects. Sub accounts will appear here once the accounts are onboarded.',
        'Onboard your accounts to view the related sub account list here.',
      ];

  return (
    <EmptyState
      classes={classNames(
        styles.container,
        styles.noData,
        'flex',
        'flex-column',
        'items-center',
        'justify-center',
        'w-full',
      )}
      mainText={mainText}
      subTexts={subText}
      backgroundIcon={<LinkedAccountsEmptyState />}
    />
  );
};

NoData.propTypes = {
  searchText: PropTypes.string,
};

const LinkedAccounts = ({ onboardingMode }) => {
  const { searchText } = useTableHeaderContext();
  const [isUpdating, setIsUpdating] = useState(false);
  const [editLinkedAccount, setEditLinkedAccount] = useState(null);

  const { usersStore } = useRootStore();

  const { NewTableWrapper } = useTable();

  const { currDispUserAccountKey } = usersStore;
  const payerAccountId = usersStore.getCurrentDisplayedAccountId(currDispUserAccountKey);

  const cloudTypeId = usersStore.currDispUserCloudAccountType;

  const { fetchPayerLinkedAccounts, updateLinkedAccountData } = useDataAccessAccounts();
  const { data: linkedAccountsData = [], isLoading: linkedAccountsDataIsLoading } =
    fetchPayerLinkedAccounts(payerAccountId);

  const handleUpdateLinkedAccountName = (linkedAccountName) => {
    return updateLinkedAccountData.mutateAsync({
      payerAccountId,
      linkedAccountId: editLinkedAccount?.linked_account?.id,
      linkedAccountData: { name: linkedAccountName, isNameExplicitSet: true },
    });
  };

  const connectedLinkedAcc = useMemo(() => {
    if (!linkedAccountsData) {
      return;
    }
    return linkedAccountsData.filter((acc) => acc.linked_account?.isLinked);
  }, [linkedAccountsData]);

  const filteredData = useMemo(() => {
    return linkedAccountsData?.filter((row) => {
      if (!searchText) {
        return linkedAccountsData;
      }
      const searchLowerCase = searchText.toLowerCase();
      return (
        row.name?.toLowerCase().includes(searchLowerCase) ||
        row.customerName?.toLowerCase().includes(searchLowerCase) ||
        row.id?.toLowerCase().includes(searchLowerCase)
      );
    });
  }, [searchText, linkedAccountsData]);

  const handleConnectLinkedAccountsBulk = async (linkedAccounts) => {
    setIsUpdating(true);
    const result = await usersStore.updateLinkedAccounts(linkedAccounts);
    Object.keys(result || {}).forEach((linkedAccountId) => {
      if (!result[linkedAccountId].isVerified) {
        toast.error(`${linkedAccountId}: ${result[linkedAccountId].message}`, { autoClose: false });
      } else {
        toast.success(`${linkedAccountId}: validated successfully`, { autoClose: false });
      }
    });
    setIsUpdating(false);
  };

  const prepareToConnectLinkedAccount = async (linkedAccountId, arnRoleNum, externalId, accountId) => {
    if (!linkedAccountId || !arnRoleNum) {
      return;
    }
    await handleConnectLinkedAccountsBulk([{ linkedAccountId, linkedAccountArn: arnRoleNum, externalId, accountId }]);
  };

  const getColor = (isVerified) => {
    if (!isVerified) {
      return 'full-red';
    }
    return 'full-green';
  };

  const prepareRoleArn = (linkedAccountId) => {
    const fixedLinkedAccountId = getZeroPadAccountId(linkedAccountId);
    const role = `arn:aws:iam::${fixedLinkedAccountId}:role/PileusRole`;
    return role;
  };

  const verificationStatus = (data) => {
    return (
      <Chip
        color={getColor(data.row?.linked_account?.isLinked)}
        label={data.row?.linked_account?.isLinked ? 'Connected' : 'Not Connected'}
      />
    );
  };

  if (linkedAccountsDataIsLoading || isUpdating) {
    return <Spinner />;
  }

  const cloudConfig = CLOUD_CONFIG[cloudTypeId];
  if (!cloudConfig) {
    return <NoData></NoData>;
  }
  const isAWS = cloudTypeId === CLOUD_TYPE_IDS.AWS;
  const isReseller = usersStore.isCurrentUserReseller;

  const columns = [
    ...(isReseller
      ? [{ name: 'customerName', title: 'Customer Name', getCellValue: (row) => row.customerName || '-' }]
      : []),
    { name: 'linkedAccountId', title: cloudConfig.idTitle, getCellValue: (row) => row?.linked_account?.id },
    {
      name: 'linkedAccountName',
      title: cloudConfig.nameTitle,
      getCellValue: (row) => (
        <>
          {row?.linked_account?.name}
          {row?.linked_account?.isNameExplicitSet === true ? <Chip className={styles.editedChip} label="edited" /> : ''}
        </>
      ),
    },
    {
      name: 'isLinked',
      title: cloudConfig.statusTitle,
      getCellValue: (row) => row?.linked_account?.isLinked,
    },
    ...(!isAWS
      ? []
      : [
          {
            name: 'connect',
            title: ' ',
            getCellValue: (row) =>
              !row?.linked_account?.isLinked ? (
                <Button
                  isTextButton
                  overrideStyles={{
                    marginLeft: -8,
                  }}
                  icon={() => <GenerateIcon iconName={ICONS.arrowUpRightFromSquare.name} />}
                  text="Open AWS platform to connect"
                  onClick={() => {
                    window.open(
                      externalLinksFunctions.AwsOpenPlatformToConnect({ externalId: row.externalId }),
                      '_blank',
                    );
                  }}
                />
              ) : null,
          },
          ...(onboardingMode
            ? []
            : [
                {
                  name: 'refresh',
                  title: ' ',
                  getCellValue: (row) =>
                    !row?.linked_account?.isLinked ? (
                      <Button
                        text="Validate Connection"
                        icon={() => <GenerateIcon iconName={ICONS.refresh.name} />}
                        onClick={() =>
                          prepareToConnectLinkedAccount(row.id, prepareRoleArn(row.id), row.externalId, payerAccountId)
                        }
                      />
                    ) : null,
                },
              ]),
        ]),
    {
      name: 'action',
      title: ' ',
      getCellValue: (row) => (
        <Button
          isTextButton
          text=""
          icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
          onClick={() => setEditLinkedAccount(row)}
        />
      ),
    },
  ];

  const csvModifiedRows = cloneDeep(filteredData).map((row) => {
    if (isAWS) {
      const currRow = row;
      delete currRow.isConnectMeSent;
      delete currRow.id;
      return currRow;
    }
    const { linkedAccountId, linkedAccountName, isVerified, customerName } = row;
    const csvRow = {
      [cloudConfig.idTitle]: linkedAccountId,
      [cloudConfig.nameTitle]: linkedAccountName,
      [cloudConfig.statusTitle]: capitalize(`${!!isVerified}`),
    };
    if (isReseller) {
      csvRow['Customer Name'] = customerName;
    }
    return csvRow;
  });

  const columnsWidth = [
    ...(isReseller ? [{ columnName: 'customerName', width: '170px' }] : []),
    { columnName: 'linkedAccountId', width: '15%' },
    { columnName: 'linkedAccountName', width: '20%' },
    {
      columnName: 'isLinked',
      width: '10%',
    },
    {
      columnName: 'connect',
      width: '15%',
      sortingEnabled: false,
    },
    ...(onboardingMode
      ? []
      : [
          {
            columnName: 'refresh',
            width: '20%',
            sortingEnabled: false,
          },
        ]),
    {
      columnName: 'action',
      align: 'right',
      width: 'auto',
      sortingEnabled: false,
    },
  ];

  return (
    <div className={styles.container}>
      <EditLinkedAccountNameModal
        open={!!editLinkedAccount}
        title={cloudConfig.infoTitle}
        linkedAccountName={editLinkedAccount?.linked_account?.name}
        onClose={() => setEditLinkedAccount(null)}
        onSave={handleUpdateLinkedAccountName}
      />
      <TableHeader
        tableName={cloudConfig.title}
        filteredRows={connectedLinkedAcc?.length}
        totalRows={linkedAccountsData?.length}
        isCreatable={false}
        csvData={{ data: csvModifiedRows, filename: cloudConfig.csvFileName }}
        infoTooltip={`Connect your ${cloudConfig.infoTitle}s in order to receive recommendations for them.`}
        actionButton={
          !isAWS ? null : (
            <div className={styles.validateAllContainer}>
              <Button
                text="Validate All Connections"
                isTextButton
                onClick={() =>
                  handleConnectLinkedAccountsBulk(
                    filteredData.map((ln) => ({
                      linkedAccountId: ln.id,
                      linkedAccountArn: prepareRoleArn(ln.id),
                      externalId: ln.externalId,
                      accountId: payerAccountId,
                    })),
                  )
                }
                icon={() => <GenerateIcon iconName={ICONS.refresh.name} />}
              />
            </div>
          )
        }
      />
      <NewTableWrapper isCompact>
        <Grid rows={filteredData} columns={columns}>
          <SortingState columnExtensions={columnsWidth} />
          <IntegratedSorting />
          <PagingState defaultCurrentPage={0} defaultPageSize={onboardingMode ? 5 : 10} />
          <IntegratedPaging />
          <DataTypeProvider for={['isLinked']} formatterComponent={verificationStatus} />
          <TableWrapper
            columnExtensions={columnsWidth}
            noDataCellComponent={(props) => <NoData {...props} searchText={searchText}></NoData>}
          />
          <TableColumnResizing columnWidths={columnsWidth} resizingMode="nextColumn" />
          <TableHeaderRow showSortingControls />
          <PagingPanel pageSizes={[5, 10, 15]} />
        </Grid>
      </NewTableWrapper>
    </div>
  );
};

LinkedAccounts.propTypes = {
  onboardingMode: PropTypes.bool,
};

export default observer(LinkedAccounts);
