import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import Button from 'shared/components/andtComponents/Button';
import Input from 'shared/components/andtComponents/Input';
import { ReactComponent as ServiceNowIcon } from 'shared/img/icons/servicenow.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from 'shared/components/andtComponents/Modal';
import styles from './AddServiceNowChannelModal.module.scss';

export default function AddServiceNowChannelModal({ selectedChannel = null, onClose }) {
  const [channelToAdd, setChannelToAdd] = useState({
    channelType: 'SERVICENOW',
    connectionData: { ...selectedChannel?.connectionData, type: 'SERVICENOW' },
    authData: { ...selectedChannel?.authData, type: 'SERVICENOW' },
  });
  const [isConnectionValid, setIsConnectionValid] = useState(true);

  const { createChannel, testRecipients } = useChannels();
  const { mutateAsync: handleTestRecipients, isLoading: isTestingRecipients } = testRecipients({
    onSuccess: () => setIsConnectionValid(true),
    onError: () => setIsConnectionValid(false),
  });
  const { mutateAsync: handleCreateChannel, isLoading: isCreatingChannel } = createChannel({
    onError: () => setIsConnectionValid(false),
  });

  return (
    <CustomModal
      open
      title="Add ServiceNow Channel"
      headerMode="add"
      headerIcon={<ServiceNowIcon height={36} width={36} />}
      onClose={onClose}
      saveDisabled={!channelToAdd?.name || !channelToAdd?.connectionData?.baseUrl}
      saveTitle={
        <>
          <GenerateIcon iconName={ICONS.plus.name} /> Add Channel
        </>
      }
      onSave={() => handleCreateChannel(channelToAdd)}
      isLoading={isCreatingChannel}
      className={styles.addServiceNowChannelModal}
      footerBorder
    >
      <div className={styles.newConnectionContainer}>
        <label htmlFor="servicenow-name">
          Name
          <Input
            value={channelToAdd?.name || ''}
            onChange={(e) =>
              setChannelToAdd({
                ...channelToAdd,
                name: e.target.value,
              })
            }
            id="servicenow-name"
            type="text"
            placeholder="Enter Name"
          />
        </label>
        <label htmlFor="servicenow-url">
          ServiceNow Instance URL
          <Input
            value={channelToAdd?.connectionData?.baseUrl || ''}
            onChange={(e) =>
              setChannelToAdd({
                ...channelToAdd,
                connectionData: { ...channelToAdd?.connectionData, baseUrl: e.target.value },
              })
            }
            id="servicenow-url"
            type="text"
            placeholder="Enter Instance URL"
          />
        </label>

        <div className={styles.userCredsContainer}>
          <label htmlFor="servicenow-url">
            Username
            <Input
              value={channelToAdd?.connectionData?.userName || ''}
              onChange={(e) =>
                setChannelToAdd({
                  ...channelToAdd,
                  connectionData: { ...channelToAdd?.connectionData, userName: e.target.value },
                })
              }
              id="servicenow-username"
              type="text"
              placeholder="Enter Username"
            />
          </label>
          <label htmlFor="servicenow-url">
            Password
            <Input
              value={channelToAdd?.authData?.authToken || ''}
              onChange={(e) =>
                setChannelToAdd({
                  ...channelToAdd,
                  authData: { ...channelToAdd?.authData, authToken: e.target.value },
                })
              }
              id="servicenow-password"
              type="password"
              placeholder="Enter Password"
            />
          </label>
        </div>
        <div className={styles.testButtonContainer}>
          <Button
            text="Test Connection"
            onClick={() => {
              handleTestRecipients({
                managerRequest: {
                  ...channelToAdd,
                },
              });
            }}
            icon={() => <GenerateIcon iconName={ICONS.hexagonCheck.name} />}
            isGhost
            disabled={!channelToAdd?.connectionData?.baseUrl || !channelToAdd?.name}
            isLoading={isTestingRecipients}
          />
          {!isConnectionValid && (
            <div className={styles.errorText}>Unable to connect. Verify your connection data.</div>
          )}
        </div>
      </div>
    </CustomModal>
  );
}

AddServiceNowChannelModal.propTypes = {
  selectedChannel: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
