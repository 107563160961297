import React, { useEffect, useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState, SortingState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomHeaderSelectionCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ADD_USERS_TO_ROLE_MODAL_COLUMNS, getColumns, getDefaultSorting, getSortingColumns } from '../../../consts';
import UsersNoData from 'users/containers/Organization/components/EmptyStates/UsersNoData.jsx';
import Spinner from 'shared/components/andtComponents/Spinner.jsx';

import styles from './AddUsersToRoleModal.module.scss';

const AddUsersToRoleModalTable = ({ isDisabled, users, setSelectedUserIds, isLoading, initialSelectedUsersIds }) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ADD_USERS_TO_ROLE_MODAL_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const onSelectionChange = (selected) => {
    setSelectedIndexes(selected);
    const tmp = !selected?.length ? [] : selected.map((index) => users[index].id);
    setSelectedUserIds(tmp);
  };

  const [selectedIndexes, setSelectedIndexes] = useState([]);

  useEffect(() => {
    if (initialSelectedUsersIds?.length && users?.length) {
      setSelectedUserIds(initialSelectedUsersIds);
      const selectedIndexes = initialSelectedUsersIds.map((id) => users.findIndex((user) => user.id === id));
      setSelectedIndexes(selectedIndexes);
    }
  }, []);

  const renderUsersNoData = (props) => <UsersNoData {...props} isSmall={true} />;

  return isLoading ? (
    <Spinner />
  ) : (
    users && (
      <div className={styles.addUsersToRoleContainer}>
        <Grid rows={users} columns={getColumns(ADD_USERS_TO_ROLE_MODAL_COLUMNS)} automationid="add-users-to-role-list">
          <SortingState
            columnExtensions={getSortingColumns(ADD_USERS_TO_ROLE_MODAL_COLUMNS)}
            defaultSorting={getDefaultSorting(ADD_USERS_TO_ROLE_MODAL_COLUMNS)}
          />
          <SelectionState onSelectionChange={onSelectionChange} selection={selectedIndexes} />
          <IntegratedSelection />
          <DataTypeProvider
            for={[ADD_USERS_TO_ROLE_MODAL_COLUMNS.USER_NAME.columnName]}
            formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          />
          <DataTypeProvider
            for={[ADD_USERS_TO_ROLE_MODAL_COLUMNS.FIRST_NAME.columnName]}
            formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          />
          <DataTypeProvider
            for={[ADD_USERS_TO_ROLE_MODAL_COLUMNS.LAST_NAME.columnName]}
            formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
          />
          <TableWrapper virtual noDataCellComponent={renderUsersNoData} />
          <TableSelection
            showSelectAll
            cellComponent={(props) => <CustomSelectionCell {...props} isDisabled={isDisabled} />}
            headerCellComponent={(props) => <CustomHeaderSelectionCell {...props} isDisabled={isDisabled} />}
          />
          <TableColumnResizing
            resizingMode="nextColumn"
            defaultColumnWidths={localColumnsWidth}
            onColumnWidthsChange={setLocalColumnsWidth}
          />
          <TableHeaderRow cellComponent={CustomHeaderCell} />
        </Grid>
      </div>
    )
  );
};

AddUsersToRoleModalTable.propTypes = {
  initialSelectedUsersIds: PropTypes.array,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  setSelectedUserIds: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
};

export default AddUsersToRoleModalTable;
