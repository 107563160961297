/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useChannels from 'shared/components/slackIntegration/hooks/useChannels.js';
import RecipientsSelect from 'shared/components/RecipientsSelect';
import Button from 'shared/components/andtComponents/Button';
import Input from 'shared/components/andtComponents/Input';
import RadioButton from 'shared/components/andtComponents/RadioButton';
import { ReactComponent as JiraIcon } from 'shared/img/icons/jira.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import CustomModal from 'shared/components/andtComponents/Modal';
import Select, { createFilter } from 'react-select';
import { palette } from 'shared/constants/colorsConstants';
import styles from './AddJiraChannelModal.module.scss';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const selectStyles = {
  control: (baseStyle, state) => ({
    ...baseStyle,
    borderColor: state.isFocused ? palette.blue[400] : 'transparent',
    backgroundColor: state.isDisabled ? palette.gray[100] : palette.gray[200],
    borderWidth: '2px',
    borderRadius: '6px',
    boxShadow: 'unset',
    minHeight: '0',
  }),
  valueContainer: (baseStyle) => ({
    ...baseStyle,
    padding: '2px 4px',
  }),
  dropdownIndicator: (baseStyle) => ({
    ...baseStyle,
    padding: '0',
    margin: '6px',
  }),
  placeholder: (baseStyle, state) => ({
    ...baseStyle,
    color: state.isDisabled ? palette.gray[400] : palette.gray[450],
  }),
};

export default function AddJiraChannelModal({ channels, selectedChannel = null, onChannelSelect, onClose }) {
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [channelToAdd, setChannelToAdd] = useState({
    ...selectedChannel,
    connectionData: { ...selectedChannel?.connectionData, type: 'JIRA_CLOUD' },
    authData: { ...selectedChannel?.authData, type: 'JIRA_CLOUD' },
  });
  const [isConnectionValid, setIsConnectionValid] = useState(true);
  const [isNewConnection, setIsNewConnection] = useState(!selectedChannel?.channelId);

  const { getPossibleRecipients, getJiraIssueTypes, addRecipients, createChannel } = useChannels();
  const { data: possibleRecipients, isLoading } = getPossibleRecipients(selectedChannel?.channelId);
  const { data: jiraIssueTypes, isLoading: isLoadingJiraIssueTypes } = getJiraIssueTypes({
    channelId: selectedChannel?.channelId,
    projectId: selectedRecipient?.dstId,
  });
  const { mutateAsync: handleAddRecipients, isLoading: isAddingRecipients } = addRecipients();
  const { mutateAsync: handleCreateChannel, isLoading: isCreatingChannel } = createChannel({
    onSuccess: (channel) => {
      onChannelSelect(channel);
      setIsNewConnection(false);
    },
    onError: () => setIsConnectionValid(false),
  });

  useEffect(() => {
    setIsConnectionValid(true);
    setChannelToAdd({
      ...selectedChannel,
      connectionData: { ...selectedChannel?.connectionData, type: 'JIRA_CLOUD' },
      authData: { ...selectedChannel?.authData, type: 'JIRA_CLOUD' },
    });
  }, [selectedChannel]);

  const channelOptions = channels.map((c) => ({ value: c.channelId, label: c.name, ...c }));
  const jiraIssueTypesOptions = jiraIssueTypes?.map((i) => ({ value: i.issueTypeId, label: i.name, ...i }));

  return (
    <CustomModal
      open
      title="Add Jira Cloud Channel"
      headerMode="add"
      headerIcon={<JiraIcon height={36} width={36} />}
      onClose={onClose}
      saveDisabled={
        !selectedRecipient?.channelId ||
        !selectedRecipient?.anodotName ||
        !selectedRecipient?.recipientData?.jiraIssueInfo
      }
      saveTitle={
        <>
          <GenerateIcon iconName={ICONS.plus.name} /> Add Channel
        </>
      }
      onSave={() => handleAddRecipients({ channelId: selectedChannel?.channelId, recipients: [selectedRecipient] })}
      isLoading={isAddingRecipients}
      className={styles.addJiraChannelModal}
      footerBorder
    >
      <div className={styles.connectionsContainer}>
        <div className={styles.existingConnectionContainer}>
          <label htmlFor="channel-select">
            <RadioButton
              label="Existing Connection"
              onClick={() => setIsNewConnection(false)}
              checked={!isNewConnection}
              primary
              isDisabled={!channelOptions?.length}
            />
          </label>
          <Select
            placeholder="Select"
            value={
              selectedChannel?.channelId ? { value: selectedChannel?.channelId, label: selectedChannel.name } : null
            }
            options={channelOptions.filter((c) => c.channelType === 'JIRA_CLOUD')}
            onChange={({ value, label, ...rawChannel }) => onChannelSelect(rawChannel)}
            filterOption={createFilter({ stringify: ({ data: { name } }) => name || '' })}
            components={{ IndicatorSeparator: () => null }}
            styles={selectStyles}
            openMenuOnFocus
            inputId="channel-select"
            isDisabled={!channelOptions?.length || isNewConnection}
          />
        </div>
        <RadioButton
          label="New Connection"
          onClick={() => {
            onChannelSelect({ channelType: 'JIRA_CLOUD' });
            setIsNewConnection(true);
          }}
          checked={isNewConnection}
          primary
        />
      </div>
      <div className={styles.newConnectionContainer}>
        <label htmlFor="jira-url">
          Jira Site URL
          <Input
            value={channelToAdd?.connectionData?.baseUrl || ''}
            onChange={(e) =>
              setChannelToAdd({
                ...channelToAdd,
                name: `${e.target.value.match(/(https?:\/\/|www\.)?(.*)\.atlassian\.net/)?.[2]}.atlassian.net`,
                connectionData: { ...channelToAdd?.connectionData, baseUrl: e.target.value },
              })
            }
            id="jira-url"
            type="text"
            placeholder="foo.atlassian.net"
            disabled={!isNewConnection}
          />
        </label>
        <div className={styles.userCredsContainer}>
          <div>
            <label htmlFor="jira-email">
              User Email
              <Input
                value={channelToAdd?.connectionData?.userName || ''}
                onChange={(e) =>
                  setChannelToAdd({
                    ...channelToAdd,
                    connectionData: { ...channelToAdd?.connectionData, userName: e.target.value },
                  })
                }
                id="jira-email"
                type="text"
                placeholder="Enter Email"
                disabled={!isNewConnection}
              />
            </label>
          </div>
          <div>
            <label htmlFor="jira-token">
              <div className={styles.apiTokenContainer}>
                API Token
                <a href={ExternalLinks.AtlassianAPITokens} target="_blank" rel="noreferrer">
                  <GenerateIcon iconName={ICONS.arrowUpRightFromSquare.name} /> Jira Page
                </a>
              </div>
              <Input
                value={selectedChannel?.channelId ? '*'.repeat(192) : channelToAdd?.authData?.authToken || ''}
                onChange={(e) =>
                  setChannelToAdd({
                    ...channelToAdd,
                    authData: { ...channelToAdd?.authData, authToken: e.target.value },
                  })
                }
                id="jira-token"
                type="password"
                placeholder="Enter Token"
                disabled={!isNewConnection}
              />
            </label>
          </div>
        </div>
        <div className={styles.connectButtonContainer}>
          {isConnectionValid ? (
            <div className={selectedChannel?.channelId && styles.disabled}>
              Connect to view available projects and issue types
            </div>
          ) : (
            <div className={styles.errorText}>Unable to connect. Verify your connection data.</div>
          )}
          <Button
            text="Connect"
            onClick={() => {
              setIsConnectionValid(true);
              if (/(https?:\/\/)?^[^.]+\.atlassian\.net/.test(channelToAdd?.connectionData?.baseUrl)) {
                handleCreateChannel(channelToAdd);
              } else {
                setIsConnectionValid(false);
              }
            }}
            icon={() => <GenerateIcon iconName={ICONS.hexagonCheck.name} />}
            isGhost
            disabled={
              !isNewConnection ||
              !channelToAdd?.connectionData?.baseUrl ||
              !channelToAdd?.authData?.authToken ||
              !channelToAdd?.connectionData?.userName
            }
            isLoading={isCreatingChannel}
          />
        </div>
      </div>
      <div className={styles.recipientDataContainer}>
        <label htmlFor="recipient-name">
          Channel Name
          <Input
            value={selectedRecipient?.anodotName || ''}
            onChange={(e) =>
              setSelectedRecipient({
                ...selectedRecipient,
                anodotName: e.target.value,
              })
            }
            id="recipient-name"
            type="text"
            placeholder="Enter Name"
            disabled={!selectedChannel?.channelId}
          />
        </label>
        <div className={styles.projectAndIssueContainer}>
          <label style={{ display: 'block' }} htmlFor="recipients-select">
            Jira Project Name
            {possibleRecipients?.length >= 0 && <span> ({possibleRecipients.length})</span>}
            <RecipientsSelect
              channels={!possibleRecipients ? [] : [{ ...selectedChannel, recipients: possibleRecipients }]}
              savedRecipients={selectedRecipient?.id ? [{ ...selectedRecipient, anodotName: null }] : []}
              isLoading={isLoading}
              onChangeHandler={({ value, label, ...rawRecipient }) =>
                setSelectedRecipient({ ...rawRecipient, anodotName: selectedRecipient?.anodotName })
              }
              isDisabled={!selectedChannel?.channelId}
              isMulti={false}
            />
          </label>
          <label htmlFor="issue-type-select">
            Jira Issue Type
            <Select
              placeholder="Select"
              options={jiraIssueTypesOptions}
              onChange={({ label, value, ...jiraIssueInfo }) =>
                setSelectedRecipient({
                  ...selectedRecipient,
                  recipientData: { ...selectedRecipient.recipientData, jiraIssueInfo },
                })
              }
              filterOption={createFilter({ stringify: ({ data: { name } }) => name || '' })}
              components={{ IndicatorSeparator: () => null }}
              styles={selectStyles}
              openMenuOnFocus
              inputId="issue-type-select"
              className={styles.channelSelect}
              isLoading={isLoadingJiraIssueTypes}
              isDisabled={!selectedRecipient}
            />
          </label>
        </div>
      </div>
    </CustomModal>
  );
}

AddJiraChannelModal.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedChannel: PropTypes.object,
  onChannelSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
