import React from 'react';
import PropTypes from 'prop-types';
import CustomModal, { MODAL_MODES } from 'shared/components/andtComponents/Modal';
import { ReactComponent as AzureFocusIcon } from 'shared/img/cloud-providers/azure-focus.svg';
import Input from 'shared/components/andtComponents/Input';
import getOnboardingLinks from 'app/containers/Onboarding/utils/getOnboardingLinks';
import styles from './AzureFocusMigrationModal.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext.js';

const AzureFocusMigrationModal = ({ onClose, isOpen, onSave, accountData }) => {
  const [directoryPrefix, setDirectoryPrefix] = React.useState('');
  const brand = useBrand();
  const handleOnSaveButtonClick = async (e) => {
    e.preventDefault();
    await onSave({ accountId: accountData.accountId, directoryPrefix });
  };
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      headerMode={MODAL_MODES.ADD}
      saveTitle="Migrate"
      headerIcon={<AzureFocusIcon />}
      onSave={(e) => handleOnSaveButtonClick(e)}
      title="Migrate to FOCUS"
      className={{
        content: styles.container,
      }}
    >
      <p>To migrate your Azure account to FOCUS export:</p>
      <ol>
        <li>Create a new FOCUS export in Azure.</li>
        <li>
          Place the export in a new directory within your existing container (Container Name:{' '}
          <b>{accountData.accountInfo?.container_name}</b>).
        </li>
        <li>Enter the new directory path below:</li>
      </ol>
      <Input
        type="text"
        value={directoryPrefix}
        onChange={(e) => setDirectoryPrefix(e.target.value)}
        placeholder="Enter Directory Path"
      />
      <div className={styles.note}>
        <p className={styles.notesTitle}>Important Note:</p>
        <p>
          In the FOCUS export, values under the &quot;Service&quot; dimension have changed due to a new classification
          (e.g., &quot;SQL Database&quot; → &quot;Azure SQL Database&quot;).
          <br />
          This may impact configurations in {brand.name}, including Dashboards, Business Mapping, Alerts, Budgets, and
          Anomaly Rules. Please review and update them accordingly.
        </p>
      </div>
      <p>
        For detailed instructions,{' '}
        <a href={getOnboardingLinks(brand).AZURE.focusGuide} rel="noopener noreferrer" target="_blank">
          see our documentation
        </a>
        .
      </p>
    </CustomModal>
  );
};

AzureFocusMigrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  accountData: PropTypes.object.isRequired,
};

export default AzureFocusMigrationModal;
