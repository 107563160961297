import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../../CloudAccounts.module.scss';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { CloudChip } from '../CloudChip';

export const CloudAccountsTableHeaderTitle = ({
  filteredData,
  accounts,
  chipFilterClicked,
  cloudTypeChips,
  showUnComplateOnboardingAccounts,
  setShowUnComplateOnboardingAccounts,
}) => {
  const hasOnboardingAccounts = useMemo(() => {
    return !!accounts.find((account) => account.onboarding);
  }, [accounts]);

  const title = `Cloud Accounts (${filteredData.length}\\${accounts.length})`;

  const tableTitleClass = useMemo(() => {
    switch (true) {
      case accounts.length < 100: {
        return styles.smallCounter;
      }
      case accounts.length < 1000: {
        return styles.medieumCounter;
      }
      default: {
        return styles.bigCounter;
      }
    }
  }, [accounts]);

  return (
    <div className={styles.tableTitleRow}>
      <div className={tableTitleClass}>{title}</div>
      {hasOnboardingAccounts ? (
        <Checkbox
          isChecked={showUnComplateOnboardingAccounts}
          onChange={() => setShowUnComplateOnboardingAccounts((prev) => !prev)}
        >
          <span>Show incomplete onboarding accounts</span>
        </Checkbox>
      ) : null}
      <div className={styles.counters}>
        {cloudTypeChips.map((chip) => (
          <CloudChip key={`${chip.cloudTypeId}-chip`} onClick={() => chipFilterClicked(chip)} chip={chip} />
        ))}
      </div>
    </div>
  );
};

CloudAccountsTableHeaderTitle.propTypes = {
  filteredData: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  chipFilterClicked: PropTypes.func.isRequired,
  cloudTypeChips: PropTypes.array.isRequired,
  showUnComplateOnboardingAccounts: PropTypes.bool.isRequired,
  setShowUnComplateOnboardingAccounts: PropTypes.func.isRequired,
};
