import config from '../../../config';

export function logoutOkta({ idToken, issuer }) {
  const url = new URL(`${issuer}/oauth2/v1/logout`);
  const searchParams = new URLSearchParams();
  searchParams.append('id_token_hint', idToken);
  searchParams.append('post_logout_redirect_uri', `${window.location.origin}/log_in`);
  url.search = searchParams.toString();
  window.location.assign(url);
}

export function logoutPing({ clientId }) {
  const congnitoPoolUrl = config.COGNITO_POOL_URL;
  const url = new URL(`${congnitoPoolUrl}/logout`);
  const searchParams = new URLSearchParams();
  searchParams.append('client_id', clientId);
  searchParams.append('redirect_uri', config.COGNITO_REDIRECT_URI);
  searchParams.append('response_type', 'token');
  url.search = searchParams.toString();
  window.location.assign(url);
}
