/* eslint-disable no-param-reassign */
import React, { PureComponent } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { mapCloudTypeToDisplayLabelFunc } from 'shared/utils/cloudUtils';
import { getK8sFieldDisplayLabel } from 'shared/utils/k8sUtils';
import { K8sCommonFields, k8sCommonFieldToDisplayField, k8sGranLevel } from 'shared/constants/k8sConstants';
import { GCPCommonFieldLabels, GcpCommonFields } from 'shared/constants/gcpConstants';
import {
  k8sGroupByNodesFields,
  k8sGroupByPodsFields,
  k8sFiltersPodsFields,
  k8sFiltersNodesFields,
} from 'kubernetes/constants/k8sConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import {
  K8S_GROUP_BY_LOV,
  K8sGroupByLovToAttributes,
  mapK8SGroupByCloudBasedLov,
} from 'usage/constants/costAndUsageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import GroupByFilterButton from 'shared/components/GroupByFilterButton';
// eslint-disable-next-line max-len
import K8sGranularityToggle from 'kubernetes/containers/KubernetesDashboard/components/K8sGranularityToggle/K8sGranularityToggle';
import { AwsCommonFields } from 'shared/constants/awsConstants';

class FilterBarK8s extends PureComponent {
  componentDidUpdate() {
    this.resetSelectedItemGCP();
  }
  addCustomTagsGroupByValues = (
    arrViewsNames,
    arrCustomTagsKeys,
    arrAccountTagsKeys,
    arrBusinessMappingViewpoints,
    arrWorkloadTypes = [],
    groupByItems,
  ) => {
    const { currentCloudType, isAllAccounts = false, arrCloudTagsKeys } = this.props;
    if (!arrViewsNames) {
      arrViewsNames = [];
    }
    if (!arrCustomTagsKeys) {
      arrCustomTagsKeys = [];
    }
    if (arrViewsNames.length) {
      groupByItems.push('Views');
    }
    if ((arrAccountTagsKeys || []).length) {
      groupByItems.push('Enrichment Tags');
    }
    if ((arrWorkloadTypes || []).length) {
      groupByItems.push('Workload');
    }
    if (arrBusinessMappingViewpoints && arrBusinessMappingViewpoints.length) {
      groupByItems.push('Business Mapping Viewpoints');
    }
    if (arrCustomTagsKeys && arrCustomTagsKeys.length) {
      groupByItems.push(LabelCoordinator.getFieldLabelByCloudType('k8scustomtags', currentCloudType));
      if (currentCloudType !== CLOUD_TYPE_IDS.AZURE) {
        groupByItems.push(LabelCoordinator.getFieldLabelByCloudType('tags', currentCloudType));
      }
    }

    const secondLevelItemsValuesMap = new Map(K8sGroupByLovToAttributes);
    const secondLevelItems = [];

    arrCustomTagsKeys.forEach((item) => {
      const customTagDispaly = `label: ${item}`;
      if (item) {
        secondLevelItemsValuesMap.set(customTagDispaly, `labels:${item}`);
        secondLevelItems.push(customTagDispaly);
      }
    });
    arrViewsNames.forEach(({ name }) => {
      const viewTagDisplay = `viewsTag:${name}`;
      secondLevelItemsValuesMap.set(viewTagDisplay, `viewscustomtags:${name}`);
      secondLevelItems.push(viewTagDisplay);
    });
    arrAccountTagsKeys.forEach((item) => {
      const tagDisplay = `accountTag:${item}`;
      if (item) {
        secondLevelItemsValuesMap.set(tagDisplay, `accounttags:${item}`);
        secondLevelItems.push(tagDisplay);
      }
    });
    arrWorkloadTypes.forEach((item) => {
      const tagDisplay = `workloadvalue:${item}`;
      if (item) {
        secondLevelItemsValuesMap.set(tagDisplay, `workloadvalue:${item}`);
        secondLevelItems.push(tagDisplay);
      }
    });
    // eslint-disable-next-line no-unused-expressions
    arrCloudTagsKeys &&
      arrCloudTagsKeys.forEach((item) => {
        const customTagDispaly = `customTag: ${item}`;
        if (item) {
          secondLevelItemsValuesMap.set(customTagDispaly, `customtags:${item}`);
          secondLevelItems.push(customTagDispaly);
        }
      });
    (arrBusinessMappingViewpoints || []).forEach((item) => {
      const id = `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: ${item.id}`;
      const display = `${AwsCommonFields.BUSINESS_MAPPING_VIEWPOINTS}: ${item.name}`;
      if (item) {
        secondLevelItemsValuesMap.set(display, id);
        secondLevelItems.push(display);
      }
    });
    if (isAllAccounts) {
      secondLevelItemsValuesMap.set(
        k8sCommonFieldToDisplayField.get(K8sCommonFields.PAYER_ACCOUNT),
        K8sCommonFields.PAYER_ACCOUNT,
      );
      secondLevelItems.push(k8sCommonFieldToDisplayField.get(K8sCommonFields.PAYER_ACCOUNT));
    }
    return { secondLevelItems, secondLevelItemsValuesMap };
  };

  formatFiltersByNodesOrPods = (groupByItems) => {
    const { kubernetesStore, usersStore } = this.props;
    if (usersStore.currDispUserCloudAccountType !== CLOUD_TYPE_IDS.GCP) {
      return;
    }
    let fieldsToRemove = [];
    if (kubernetesStore.k8SGranularity === k8sGranLevel.NODES) {
      fieldsToRemove = k8sGroupByPodsFields;
      // todo - remove this when group by tags/labels will be supported when granularity is by nodes
      fieldsToRemove.push(LabelCoordinator.getFieldLabelByCloudType(GcpCommonFields.CUSTOM_TAGS, CLOUD_TYPE_IDS.GCP));
    } else {
      fieldsToRemove = k8sGroupByNodesFields;
    }
    fieldsToRemove.forEach((groupByKey) => {
      const groupByKeyIndex = groupByItems.findIndex(
        (item) => item === (k8sCommonFieldToDisplayField.get(groupByKey) || groupByKey),
      );
      if (groupByKeyIndex > -1) {
        groupByItems.splice(groupByKeyIndex, 1);
      }
    });
  };

  formatValueLabelItems = (groupByItems) => {
    const { currentCloudType } = this.props;
    if (currentCloudType !== CLOUD_TYPE_IDS.GCP) {
      return groupByItems;
    }
    const groupBy = groupByItems.map((item) => ({ value: item, label: GCPCommonFieldLabels.get(item) || item }));
    return groupBy;
  };

  formatSelectedItem = (selectedItem) => {
    const { currentCloudType } = this.props;
    if (currentCloudType !== CLOUD_TYPE_IDS.GCP) {
      return selectedItem;
    }
    return GCPCommonFieldLabels.get(selectedItem) || selectedItem;
  };

  resetSelectedItemGCP = () => {
    const { currentCloudType, handleGroupByChange, topLevelGroupBy, topLevelSecondaryGroupBy } = this.props;
    if (currentCloudType !== CLOUD_TYPE_IDS.GCP) {
      return;
    }
    const { kubernetesStore } = this.props;
    let fieldsToRemove = [];
    if (kubernetesStore.k8SGranularity === k8sGranLevel.NODES) {
      fieldsToRemove = k8sFiltersPodsFields;
      // todo - remove this when group by tags/labels will be supported when granularity is by nodes
      fieldsToRemove.push(LabelCoordinator.getFieldLabelByCloudType(GcpCommonFields.CUSTOM_TAGS, CLOUD_TYPE_IDS.GCP));
    } else {
      fieldsToRemove = k8sFiltersNodesFields;
    }
    if (fieldsToRemove.some((field) => field === topLevelGroupBy.split(':')[0])) {
      handleGroupByChange(K8sCommonFields.NONE);
    }
    if (fieldsToRemove.some((field) => field === topLevelSecondaryGroupBy.split(':')[0])) {
      handleGroupByChange(K8sCommonFields.NONE, true);
    }
  };

  render() {
    const {
      topLevelGroupBy,
      handleGroupByChange,
      topLevelSecondaryGroupBy,
      currentCloudType,
      isAllAccounts = false,
      arrViewsNames,
      arrAccountTagsKeys,
      arrBusinessMappingViewpoints,
      arrCustomTagsKeys,
      arrWorkloadTypes,
      usersStore,
      kubernetesStore,
    } = this.props;
    const groupByItems = Object.values(mapK8SGroupByCloudBasedLov.get(currentCloudType)).filter(
      (lov) => lov !== getK8sFieldDisplayLabel(topLevelGroupBy, currentCloudType),
    );
    if (isAllAccounts) {
      groupByItems.push(k8sCommonFieldToDisplayField.get(K8sCommonFields.PAYER_ACCOUNT));
    }
    const { secondLevelItems, secondLevelItemsValuesMap } = this.addCustomTagsGroupByValues(
      arrViewsNames,
      arrCustomTagsKeys,
      arrAccountTagsKeys,
      arrBusinessMappingViewpoints,
      arrWorkloadTypes,
      groupByItems,
    );
    this.formatFiltersByNodesOrPods(groupByItems);
    const displayLabelCloudBaseMap = mapCloudTypeToDisplayLabelFunc.get(currentCloudType);
    const groupByItemsSecondary = groupByItems.filter(
      (item) =>
        item !== displayLabelCloudBaseMap(topLevelGroupBy) &&
        item !== displayLabelCloudBaseMap(topLevelSecondaryGroupBy) &&
        item !== K8S_GROUP_BY_LOV.BY_NONE &&
        item !== 'Labels' &&
        item !== 'Tags' &&
        item !== 'Views',
    );
    if (!groupByItemsSecondary.includes('Date')) {
      groupByItemsSecondary.unshift('Date');
    }
    const groupByItemsSecondaryValueLabel = this.formatValueLabelItems(groupByItemsSecondary);
    const groupByValuesLabels = this.formatValueLabelItems(groupByItems);
    const selectedItem = getK8sFieldDisplayLabel(topLevelGroupBy, currentCloudType, secondLevelItemsValuesMap);
    const selectedLabel = this.formatSelectedItem(selectedItem);
    const secondarySelectedItem = getK8sFieldDisplayLabel(
      topLevelSecondaryGroupBy,
      currentCloudType,
      secondLevelItemsValuesMap,
    );
    const secondarySelectedLabel = this.formatSelectedItem(secondarySelectedItem);
    return (
      <div className="d-flex align-center">
        <K8sGranularityToggle usersStore={usersStore} kubernetesStore={kubernetesStore} />
        <div
          style={{ marginLeft: '15px', marginRight: '15px' }}
          className="filter-bar-inner-col-wrapper-stretch cue-filter-bar"
        >
          <GroupByFilterButton
            title="Group By: "
            usersStore={usersStore}
            isSecondary={false}
            items={groupByValuesLabels}
            handler={handleGroupByChange}
            selectedItem={selectedLabel}
            rawSelectedItem={topLevelGroupBy}
            itemsValuesMap={secondLevelItemsValuesMap}
            isIconButton
            secondLevelItems={secondLevelItems}
            secondLevelItemsValuesMap={secondLevelItemsValuesMap}
            currentCloudType={currentCloudType}
          />
        </div>
        <div className="filter-bar-inner-col-wrapper-stretch cue-filter-bar" style={{ marginRight: '15px' }}>
          <GroupByFilterButton
            title="By: "
            usersStore={usersStore}
            isSecondary
            items={groupByItemsSecondaryValueLabel}
            handler={handleGroupByChange}
            selectedItem={secondarySelectedLabel}
            rawSelectedItem={topLevelGroupBy}
            itemsValuesMap={secondLevelItemsValuesMap}
            isIconButton
            secondLevelItems={secondLevelItems}
            currentCloudType={currentCloudType}
          />
        </div>
      </div>
    );
  }
}

FilterBarK8s.propTypes = {
  topLevelGroupBy: PropTypes.string.isRequired,
  currentCloudType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  handleGroupByChange: PropTypes.func.isRequired,
  arrCustomTagsKeys: PropTypes.array.isRequired,
  arrViewsNames: PropTypes.array.isRequired,
  arrAccountTagsKeys: PropTypes.array.isRequired,
  arrWorkloadTypes: PropTypes.array.isRequired,
  arrBusinessMappingViewpoints: PropTypes.array.isRequired,
  arrCloudTagsKeys: PropTypes.array.isRequired,
  usersStore: PropTypes.object.isRequired,
  kubernetesStore: PropTypes.object.isRequired,
  topLevelSecondaryGroupBy: PropTypes.string.isRequired,
  isAllAccounts: PropTypes.bool,
};

const ObserverFilterBarK8s = observer(FilterBarK8s);
export default ObserverFilterBarK8s;
