import React from 'react';
import PropTypes from 'prop-types';
import TableHeader from 'shared/components/tableHeader/TableHeader';
import { CloudAccountsTableHeaderTitle } from './TableHeaderTitle';

export const MainTableHeaderRow = ({
  filteredData,
  accounts,
  chipFilterClicked,
  cloudTypeChips,
  showUnComplateOnboardingAccounts,
  setShowUnComplateOnboardingAccounts,
}) => {
  return (
    <TableHeader
      tableName="Cloud Accounts"
      titleComponent={
        <CloudAccountsTableHeaderTitle
          filteredData={filteredData}
          accounts={accounts}
          chipFilterClicked={chipFilterClicked}
          cloudTypeChips={cloudTypeChips}
          showUnComplateOnboardingAccounts={showUnComplateOnboardingAccounts}
          setShowUnComplateOnboardingAccounts={setShowUnComplateOnboardingAccounts}
        ></CloudAccountsTableHeaderTitle>
      }
      hideSearch="true"
      createButtonComponent={<span></span>}
    />
  );
};

MainTableHeaderRow.propTypes = {
  filteredData: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  chipFilterClicked: PropTypes.func.isRequired,
  cloudTypeChips: PropTypes.array.isRequired,
  showUnComplateOnboardingAccounts: PropTypes.bool.isRequired,
  setShowUnComplateOnboardingAccounts: PropTypes.func.isRequired,
};
