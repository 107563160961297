import { getFakeT } from 'shared/utils/translationUtil.ts';

const textMap = {
  SAVING_PLAN: 'Saving Plan',
  ENABLE_ALERTS: 'CUD Expiration',
  SAVING_PLANS_EXPIRATION: 'Saving Plans Expiration',
  CUD_EXPIRATION: 'CUD Expiration',
  CUD_Alert: 'CUD Alert',
  RESERVED_INSTANCES: 'Reserved Instances',
  DAYS_BEFORE_SAVING_PLAN_EXPIRES: 'Days before my saving plan expires',
  DAYS_BEFORE_RESERVATION_EXPIRES: 'Days before my reservation expires',
  DAYS_BEFORE_CUD_EXPIRES: 'Days before my CUD expires',
  RECIPIENTS: 'Recipients',
  ALERT_RULES: 'Alert Rules',
  EMAIL_PLACEHOLDER: 'email@example',
  MY_EMAIL: 'My Email',
  DELETE_ALERT_TITLE: 'Delete Alert',
  DELETE_ALERT_DESCRIPTION: 'Be advise you are about to delete this alert',
  DELETE: 'Delete',
  EDIT: 'Edit',
  SAVE: 'Save',
  CREATE: 'Create',
  COST_CHANGES: 'Cost Changes',
  ALERT_NAME: 'Alert Name',
  ALERT_LEVEL: 'Alert Level',
  ALERT_TYPE: 'Alert Type',
  COMPARING_RULE: 'Comparing Rule',
  DAY: 'Day',
  WHEN: 'When',
  BY_PERCENTS: 'By (%)',
  FOR_$: 'For ($)',
  AND: 'And',
  PRICING_MODE: 'Pricing Mode',
  USAGE_TYPE: 'Usage Type',
  EXAMPLE_EMAIL: 'example@example.com',
  EDIT_INFO: 'To edit - Click the pencil on the upper right.',
  RESERVATION: 'Reservation',
  RESERVATIONS: 'Reservations',
  CREATE_ALERT_RULE: 'Create Alert Rule',
  SERVICE_COST: 'Cost Alert Rules',
  COMMITMENT_EXPIRATION: 'Commitment Expiration Alert Rules',
  GCP_COMMITMENT_EXPIRATION: 'CUD Expiration Alert Rules',
  ALERT_GRANULARITY: 'Alert Granularity',
  COST: 'Cost',
  CUSTOMER_NAME: 'Customer Name',
};

export default getFakeT(textMap);
