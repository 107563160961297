import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import {
    BASE_PROPERTIES,
    COST_PROPERTIES,
    ENGINE_PROPERTIES,
    EXTENDED_SUPPORT_PROPERTIES, getNumericValue, IOPS_PROPERTIES,
    OPTIONS_PROPERTIES,
    RDS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';
import RecommendationChartData, {
    CHART_TYPES
} from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationChart/recommendationChartData.jsx';
import {
    Y_AXIS_UNIT
} from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationChart/chartConsts.js';
import RecommendationCommand, {
    COMMAND_TYPES
} from 'recommendationsNew/components/detailedRecommendation/recommendationGenericComponents/recommendationCommand.jsx';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const RDS_STORAGE_PROPERTIES = {
    ALLOCATED_STORAGE: {
        label: 'Allocated Storage',
        getterFunction: (recommendation) => recommendation.recData.allocated_storage,
    },
    MAX_ALLOCATED_STORAGE: {
        label: 'Max Allocated Storage',
        getterFunction: (recommendation) => recommendation.recData.max_allocated_storage,
    },
    MEM_MAX_USAGE: {
        label: 'Memory Max',
        isBSize: true,
        getterFunction: (recommendation) => getNumericValue(recommendation?.recData?.max_memory)?.toFixed(2),
    },

}
const RdsStorageTypeChange = ({ recommendation }) => {
    const brand = useBrand();
    const RDS_PROPERTIES_UPDATED = { ...RDS_PROPERTIES };
    delete RDS_PROPERTIES_UPDATED.RECOMMENDED_INSTANCE_TYPE;
    const properties = {
        ...RDS_PROPERTIES_UPDATED,
        ...ENGINE_PROPERTIES,
        ...BASE_PROPERTIES,
        ...COST_PROPERTIES,
        ...EXTENDED_SUPPORT_PROPERTIES,
        ...RDS_STORAGE_PROPERTIES,
        ...IOPS_PROPERTIES,
    };

    const description = <span>
        The RDS storage type change recommendation analyzes your current database storage configuration
        and suggests migrating to a more cost-effective or performance-aligned storage option.
        Supported volume types: <b>gp2 to gp3</b>, <b>io1 to gp3</b>.
        </span>;
    const descriptionUrl = {
        fullSentence: 'Click here for more details',
        url: brand.externalLinks.RecommendationsForAwsSectionRds,
    };
    const insideLookComponent = <RecommendationInsideLook description={description}  descriptionUrl={descriptionUrl}/>;

    const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

    const detailsComponent = (
        <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} viewAll />
    );

    const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;


    const CURRENT_PROPERTIES = [
        properties.VERSION,
        properties.EXTENDED_SUPPORT_TYPE,
        properties.START_OF_EXTENDED_SUPPORT_DATE,
        properties.END_OF_EXTENDED_SUPPORT_DATE,
        properties.CURRENT_ANNUAL_COST,
        properties.STORAGE,
        properties.ENGINE,
        properties.ENGINE_VERSION,
    ];

    const ALTERNATIVE_PROPERTIES = [
        properties.RECOMMENDED_VERSION,
        properties.RECOMMENDED_SUPPORT_TYPE,
        properties.RECOMMENDED_END_OF_EXTENDED_SUPPORT_DATE,
        properties.RECOMMENDED_ANNUAL_COST,
        OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
        properties.STORAGE,
        properties.ENGINE,
        properties.ENGINE_VERSION,
    ];

    const alternatives = recommendation?.recData?.alternatives;
    const optionsPanelComponent =
        Array.isArray(alternatives) && alternatives.length ? (
            <RecommendationOptionsPanel
                alternatives={alternatives}
                currentProperties={CURRENT_PROPERTIES}
                alternativeProperties={ALTERNATIVE_PROPERTIES}
                noticeComment={
                    properties.START_OF_EXTENDED_SUPPORT_DATE.getterFunction(recommendation) > new Date()
                        ? 'Early notice for extended support!'
                        : ''
                }
                minHeight={232}
                recommendation={recommendation}
                isRecommendedOption
            />
        ) : null;

    const chartsData = [
        {
            chartType: CHART_TYPES.SERIES_DATA,
            chartTitle: 'IOPS Performance',
            yAxisLabel: 'IOPS',
            maxDataProperty: recommendation.recData?.iops_max_data,
            avgDataProperty: recommendation.recData?.iops_avg_data,
            percentileDataProperty: recommendation.recData?.iops_p95_data,
            percentile99DataProperty: recommendation.recData?.iops_p99_data,
        },
        {
            chartType: CHART_TYPES.SERIES_DATA,
            chartTitle: 'Throughput Performance',
            yAxisLabel: 'Throughput',
            yAxisUnit: Y_AXIS_UNIT.BYTE,
            maxDataProperty: recommendation.recData?.throughput_max_data,
            avgDataProperty: recommendation.recData?.throughput_avg_data,
            percentileDataProperty: recommendation.recData?.throughput_p95_data,
        },
        {
            chartType: CHART_TYPES.SERIES_DATA,
            chartTitle: 'CPU Performance',
            yAxisLabel: 'CPU',
            yAxisUnit: Y_AXIS_UNIT.PERCENT,
            maxDataProperty: recommendation.recData?.cpu_max_data,
            avgDataProperty: recommendation.recData?.cpu_avg_data,
            percentileDataProperty: recommendation.recData?.cpu_p95_data,
        },
    ];

    const chartsComponent = <RecommendationChartData chartsData={chartsData} />;

    const commandsList = recommendation?.recData?.command ?
        [
            {
                type: COMMAND_TYPES.CLI,
                instructions: [
                    {
                        actionText: '',
                        actionCommand: recommendation?.recData?.command,
                    },
                ],
            },
        ] : null;

    const cliCommandComponent = <RecommendationCommand commandsList={commandsList} />;

    return (
        <RecommendationDetailsLayout
            insideLook={insideLookComponent}
            otherResourceIdRecommendations={otherResourceIdRecommendations}
            details={detailsComponent}
            charts={chartsComponent}
            optionsPanel={optionsPanelComponent}
            preferences={preferencesComponent}
            command={cliCommandComponent}
        />
    );
};

RdsStorageTypeChange.propTypes = {
    recommendation: PropTypes.object.isRequired,
};

export default RdsStorageTypeChange;
