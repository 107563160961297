import React, { FC, memo, SVGProps } from 'react';

import { Brand } from '../types';
import { ReactComponent as UmbrellaLogo } from '../assets/Umbrella/Umbrella-logo.svg';
import { ReactComponent as UmbrellaMspStateImage } from '../assets/Umbrella/spMspState.svg';
import { ReactComponent as UmbrellaNoBudget } from '../assets/Umbrella/noBudget.svg';
import { ReactComponent as UmbrellaProgress } from '../assets/Umbrella/customProgress.svg';
import { ReactComponent as UmbrellaAccountNameSVG } from '../assets/Umbrella/accountName.svg';
import { ReactComponent as UmbrellaSun } from '../assets/Umbrella/sun.svg';
import { ReactComponent as UmbrellaAutomaticImg } from '../assets/Umbrella/automatic.svg';
import { ReactComponent as UmbrellaEmptyGroupByCardData } from '../assets/Umbrella/noData.svg';
import { ReactComponent as UmbrellaBeta } from '../assets/Umbrella/beta.svg';
import { ReactComponent as UmbrellaDetailsIcon } from '../assets/Umbrella/details.svg';

import { ReactComponent as LoginHeader } from '../assets/Umbrella/LoginHeader.svg';
import { ReactComponent as LoginFooter } from '../assets/Umbrella/LoginFooter.svg';
import LoginCenter from '../assets/Umbrella/LoginCenter.gif';

import umbrellaLoginLogoPng from '../assets/Umbrella/loginLogo.png';
import azureClientSecretHelp from '../assets/Umbrella/azureClientSecretHelp.jpg';
import umbrellaErrorFound from '../assets/Umbrella/errorFound.png';
import gcpOnboardingBillingDatasetNameHelp from '../assets/Umbrella/gcpOnboardingBillingDatasetNameHelp.jpg';
import gcpOnboardingBillingTableNameHelp from '../assets/Umbrella/gcpOnboardingBillingTableNameHelp.jpg';
import azureBillingExportContainerHelp from '../assets/Umbrella/azureBillingExportContainerHelp.png';
import azureBillingExportAccountStorageHelp from '../assets/Umbrella/azureBillingExportAccountStorageHelp.png';
import azureBillingExportDirectoryPrefixHelp from '../assets/Umbrella/azureBillingExportDirectoryPrefixHelp.png';
import azureBillingExportCostTypeHelp from '../assets/Umbrella/azureBillingExportCostTypeHelp.png';
import gcpOnboardingServiceAccountKeyHelp from '../assets/Umbrella/gcpOnboardingServiceAccountKeyHelp.gif';
import gcpOnboardingBillingDatasetProjectIDHelp from '../assets/Umbrella/gcpOnboardingBillingDatasetProjectIDHelp.jpg';
import awsOnboardingAccountId from '../assets/Umbrella/awsOnboardingAccountId.png';
import umbrellaSpLoader from '../assets/Umbrella/spAnalyzerLoader.gif';
import umbrellaIllustrationPng from '../assets/Umbrella/illustration.png';
import ValidationGif from '../assets/Umbrella/validation.gif';
import CURVideo from '../assets/Umbrella/AWS_CUR.mp4';
import { GetBrandResource } from './GetBrandResource';
import { UmbrellaExternalLinks } from './external-links.configuration';
import { Themes } from '../enums';

class UmbrellaBrand {
  public name: string = 'Umbrella';
  public domain: string = 'umbrellacost.com';
  public supportEmail: string = 'support@umbrellacost.com';
  public infoEmail: string = 'info@umbrellacost.com';
  public realm: {
    excludeDomain: string;
  } = {
    excludeDomain: 'umbrellacost.com',
  };
  public themeName = Themes.Default;
  public externalLinks = UmbrellaExternalLinks;
  public hideContactUs: boolean = false;
  public leftSideIllustration: FC<SVGProps<any>> = memo((props: any) => {
    return (
      <div className={props?.styles?.newMainImgWrapper}>
        <div className={props?.styles?.top}>
          <LoginHeader />
        </div>
        <div className={props?.styles?.center}>
          <img alt="" src={LoginCenter} />
        </div>
        <div className={props?.styles?.footer}>
          <LoginFooter />
        </div>
      </div>
    );
  });
  public loginLogo: FC<SVGProps<any>> = GetBrandResource(
    { resource: umbrellaLoginLogoPng },
    {
      style: { width: '18vh' },
    },
  );
  public logo: FC<SVGProps<any>> = GetBrandResource(
    { resource: UmbrellaLogo, isSvg: true },
    {
      style: { width: '135px', marginLeft: '2px' },
      className: 'logo',
    },
  );
  public AzureClientSecretHelp: string = `${azureClientSecretHelp}`;
  public AwsOnboardingAccountId: string = `${awsOnboardingAccountId}`;
  public AzureBillingExportContainerHelp: string = `${azureBillingExportContainerHelp}`;
  public AzureBillingExportAccountStorageHelp: string = `${azureBillingExportAccountStorageHelp}`;
  public AzureBillingExportDirectoryPrefixHelp: string = `${azureBillingExportDirectoryPrefixHelp}`;
  public AzureBillingExportCostTypeHelp: string = `${azureBillingExportCostTypeHelp}`;
  public GCPOnboardingBillingTableNameHelp: string = `${gcpOnboardingBillingTableNameHelp}`;
  public GCPOnboardingBillingDatasetProjectIDHelp: string = `${gcpOnboardingBillingDatasetProjectIDHelp}`;
  public GCPOnboardingServiceAccountKeyHelp: string = `${gcpOnboardingServiceAccountKeyHelp}`;
  public GCPOnboardingBillingDatasetNameHelp: string = `${gcpOnboardingBillingDatasetNameHelp}`;
  public errorFound: FC<SVGProps<any>> = GetBrandResource({ resource: umbrellaErrorFound });
  public spMspState: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaMspStateImage, isSvg: true });
  public noBudget: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaNoBudget, isSvg: true });
  public spAnalyzerLoader: FC<SVGProps<any>> = GetBrandResource({ resource: umbrellaSpLoader });
  public customProgress: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaProgress, isSvg: true });
  public accountName: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaAccountNameSVG, isSvg: true });
  public sun: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaSun, isSvg: true });
  public automatic: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaAutomaticImg, isSvg: true });
  public noData: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaEmptyGroupByCardData, isSvg: true });
  public beta: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaBeta, isSvg: true });
  public details: FC<SVGProps<any>> = GetBrandResource({ resource: UmbrellaDetailsIcon, isSvg: true });
  public illustration: FC<SVGProps<any>> = GetBrandResource({ resource: umbrellaIllustrationPng });
  public validation: FC<SVGProps<any>> = GetBrandResource({ resource: ValidationGif });
  public awsCurVideo: FC<SVGProps<any>> = GetBrandResource({ resource: CURVideo, isVideo: true });

  constructor() {
    Object.freeze(this);
  }
}

export const umbrellaBrand: Brand = new UmbrellaBrand();
