import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { isNil } from 'lodash';
import styles from './percentTag.module.scss';

const getPercentValue = (percent, isNA) => {
  if (isNA) {
    return 'N/A';
  }
  return !isNil(percent) ? `${Number(percent ?? 0).toFixed(2)}%` : '+ 100%';
};
const PercentTag = ({ percent, iconText, mediumWidth, fullWidth }) => {
  if (percent === Infinity) {
    return null;
  }
  const isArrowUp = percent > 0 || isNil(percent);
  const isNA = percent === 'N/A';
  return (
    <div
      className={classNames(
        styles.percentTag,
        (isArrowUp || iconText) && styles.redTag,
        percent === 0 && styles.grayTag,
        isNA && styles.naTag,
        iconText && styles.bigWidth,
        mediumWidth && styles.mediumWidth,
        fullWidth && styles.fullWidth,
      )}
    >
      {getPercentValue(percent, isNA)}
      {percent !== 0 && !isNA && !iconText && (
        <GenerateIcon
          automation-id="arrow-icon"
          iconName={isArrowUp ? ICONS.arrowTrendUp.name : ICONS.arrowTrendDown.name}
        />
      )}
      {iconText && <span className={classNames(styles.percentTxt)}>{iconText}</span>}
    </div>
  );
};

PercentTag.propTypes = {
  percent: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconText: PropTypes.string,
};
export default PercentTag;
