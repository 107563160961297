import React, { HTMLProps } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { useRootStore } from 'app/contexts/RootStoreContext';
import styles from '../alerts.module.scss';

export interface EditDeleteButtonsProps extends HTMLProps<HTMLDivElement> {
  className?: string;
  onEdit(): void;
  onShare(): void;
  onDelete(): void;
  alertId: string;
}

const EditDeleteButtons: React.FC<EditDeleteButtonsProps> = ({
  className,
  alertId,
  onDelete,
  onEdit,
  onShare,
  ...divProps
}) => {
  const { appStore } = useRootStore();
  return (
    <div className={className} {...divProps}>
      {appStore.isKeyCloakManagement && (
        <GenerateIcon
          onClick={(e) => {
            e.stopPropagation();
            onShare();
          }}
          iconName={ICONS.share.name}
          className={styles.actionIcon}
          automation-id={`${alertId}share-alert-button`}
        />
      )}
      <GenerateIcon
        onClick={(e) => {
          e.stopPropagation();
          onEdit();
        }}
        iconName={ICONS.edit.name}
        className={styles.actionIcon}
        automation-id={`${alertId}edit-alert-button`}
      />
      <GenerateIcon
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        iconName={ICONS.delete.name}
        className={styles.actionIcon}
        automation-id={`${alertId}delete-alert-button`}
      />
    </div>
  );
};

export default EditDeleteButtons;
