export const roundHundred = (numValue) => Math.round(numValue / 100) * 100;

export const random5DigitNumber = () => Math.floor(Math.random() * 10000) + 1;

export const roundNumber = (numValue) => Math.round(numValue * 100) / 100;

export const sumArrayValues = (values) => values.reduce((p, c) => p + c, 0);

export const weightedMean = (factorsArray, weightsArray) =>
  sumArrayValues(factorsArray.map((factor, index) => factor * weightsArray[index])) / sumArrayValues(weightsArray);
