import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import React from 'react';
import Button from 'shared/components/andtComponents/Button';
import styles from './TemplatesDropdown.module.scss';
import useBillingRules from '../hooks/react-query/useBillingRules';

const TemplateItem = ({
  template,
  onTemplateClick,
  onTemplateEdit,
  onTemplateDelete,
  isDeletingBillingRuleTemplate,
  isSystem,
}) => (
  <div className={isSystem ? styles.systemTemplate : styles.orgTemplate}>
    <Button
      text={
        <div>
          {template.description.name} {!isSystem && <span className={styles.customLabel}>(Custom)</span>}
        </div>
      }
      isTextButton
      icon={<GenerateIcon iconName={isSystem ? ICONS.sparkles.name : ICONS.sparklesRegular.name} />}
      isSecondary
      onClick={() => onTemplateClick(template)}
      className={styles.templateButton}
    />
    {!isSystem && (
      <div>
        <Button
          icon={<GenerateIcon iconName={ICONS.pencilLight.name} />}
          isTextButton
          onClick={() => onTemplateEdit(template)}
        />
        <Button
          icon={<GenerateIcon iconName={ICONS.deleteRegular.name} />}
          isTextButton
          isLoading={isDeletingBillingRuleTemplate}
          onClick={() => onTemplateDelete(template.templateId)}
        />
      </div>
    )}
  </div>
);

const TemplatesDropdown = ({ templates = [], onClose, onTemplateClick, onTemplateCreate, onTemplateEdit }) => {
  const { deleteBillingRuleTemplate } = useBillingRules();
  const { mutateAsync: handleDeleteBillingRuleTemplate, isPending: isDeletingBillingRuleTemplate } =
    deleteBillingRuleTemplate();
  const systemTemplates = templates.filter((template) => template.owner === 'system');
  const orgTemplates = templates.filter((template) => template.owner === 'organization');

  return (
    <div>
      <div className={styles.templatesDropdown}>
        {systemTemplates.map((template) => (
          <TemplateItem key={template.templateId} template={template} onTemplateClick={onTemplateClick} isSystem />
        ))}
        {!!systemTemplates.length && <hr />}
        {orgTemplates.map((template) => (
          <TemplateItem
            key={template.templateId}
            template={template}
            onTemplateClick={onTemplateClick}
            onTemplateEdit={onTemplateEdit}
            onTemplateDelete={handleDeleteBillingRuleTemplate}
            isDeletingBillingRuleTemplate={isDeletingBillingRuleTemplate}
          />
        ))}
        <div className={styles.createButton}>
          {!!orgTemplates.length && <hr />}
          <Button
            text="Create Custom Rule Template"
            icon={<GenerateIcon iconName={ICONS.plus.name} />}
            isTextButton
            onClick={onTemplateCreate}
          />
        </div>
      </div>
      <div className={styles.closeWrapper} onClick={onClose} />
    </div>
  );
};

export default TemplatesDropdown;
