import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ReactComponent as DeleteIcon } from 'shared/img/icons/delete.svg';
import Button from 'shared/components/andtComponents/Button';
import { REVIEW_MULTI_ACCOUNT_ROLE_TABLE_COLUMN } from '../adminPanel.helpers';

const propTypes = {
  onDelete: PropTypes.func,
  roleDivisions: PropTypes.array,
  onSave: PropTypes.func.isRequired,
  saveButtonDisabled: PropTypes.bool,
  saveButtonLoading: PropTypes.bool,
};

const DeleteButtonFormatter = ({ row, onDelete }) => {
  return onDelete ? (
    <div className="d-inline-block">
      <DeleteIcon style={{ cursor: 'pointer', opacity: onDelete ? 1 : 0.3 }} onClick={() => onDelete?.(row)} />
    </div>
  ) : null;
}

DeleteButtonFormatter.propTypes = {
  row: PropTypes.any.isRequired,
  onDelete: PropTypes.func,
};

const RoleDivisionReviewTable = ({
  onDelete,
  roleDivisions = [],
  onSave,
  saveButtonDisabled = false,
  saveButtonLoading = false,
}) => (
  <div className="mb-2">
    <div className="mb-2">
      <span>Review role accounts:</span>
      <Grid rows={roleDivisions} columns={REVIEW_MULTI_ACCOUNT_ROLE_TABLE_COLUMN}>
        <DataTypeProvider
          for={['deleteButton']}
          formatterComponent={(props) => <DeleteButtonFormatter {...props} onDelete={onDelete} />}
        />
        <TableWrapper />
        <TableHeaderRow />
      </Grid>
    </div>
    <Button
      type="button"
      text="Create multi customer role"
      onClick={onSave}
      disabled={saveButtonDisabled}
      isLoading={saveButtonLoading}
    />
  </div>
);

RoleDivisionReviewTable.propTypes = propTypes;
export default RoleDivisionReviewTable;
