import { BrandConfiguration, ThemeConfiguration } from './configuration';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import { Routes } from 'shared/constants/routes';

import { BrandTypes, Themes } from '../enums';

export const getBrandDomainKey = (): BrandTypes => {
  const url = window.location.hostname;
  switch (true) {
    case url.includes(`${BrandTypes.Umbrella}`.toLowerCase()):
    case url.includes('localhost'.toLowerCase()): {
      return BrandTypes.Umbrella;
    }
    default: {
      return BrandTypes.Anodot;
    }
  }
};

export const getThemeDomainKey = (currUserTheme: string): string => {
  const url = window.location.href;

  switch (true) {
    case url.includes(Routes.REGISTER_SNOW): {
      return Themes.Snow;
    }
    case url.includes(Routes.REGISTER_PROCURE_IT): {
      return Themes.ProcureIT;
    }
    default: {
      return currUserTheme ?? Themes.Default;
    }
  }
};

export const getBrandByDomain = (currUserTheme: string, brandKey?: string) => {
  const themeKey = getThemeDomainKey(currUserTheme);
  const themeConfig = cloneDeep(ThemeConfiguration[themeKey]);

  const brandDomainKey = brandKey ?? getBrandDomainKey();
  const brandConfig = cloneDeep(BrandConfiguration[brandDomainKey]);
  if (themeKey !== Themes.Default) {
    delete brandConfig.logo;
  }

  const merged = merge(brandConfig, themeConfig);
  return merged;
};
