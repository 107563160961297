import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import * as AccountTableConstants from '../../constants/cloudAccountsConstants';
import { getCSVData } from '../../CloudAccountsUtil';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import { CreateCloudAccountTableHeader } from '../CreateCloudAccountTableHeader';
import { SingleRowHeader } from './SingleRowHeader';
import { DoubleRowHeader } from './DoubleRowHeader';

export const CloudAccountsTableHeader = ({
  filteredData,
  accounts,
  chipFilterClicked,
  cloudTypeChips,
  showUnComplateOnboardingAccounts,
  setShowUnComplateOnboardingAccounts,
  usersStore,
}) => {
  const { isCreateMode, setIsCreateMode } = useTableHeaderContext();

  const csvData = useMemo(() => getCSVData(filteredData, AccountTableConstants.CSV_COLUMNS), [filteredData]);
  const hasOnboardingAccounts = useMemo(() => {
    return !!accounts.find((account) => account.onboarding);
  }, [accounts]);
  const shouldUseMultiRowHeader =
    (cloudTypeChips?.length > 7 && hasOnboardingAccounts) || (cloudTypeChips?.length > 11 && !hasOnboardingAccounts);
  switch (true) {
    case isCreateMode: {
      return <CreateCloudAccountTableHeader usersStore={usersStore} setIsCreateMode={setIsCreateMode} />;
    }
    case shouldUseMultiRowHeader: {
      return (
        <DoubleRowHeader
          key={'DoubleRowHeader'}
          filteredData={filteredData}
          accounts={accounts}
          chipFilterClicked={chipFilterClicked}
          cloudTypeChips={cloudTypeChips}
          showUnComplateOnboardingAccounts={showUnComplateOnboardingAccounts}
          setShowUnComplateOnboardingAccounts={setShowUnComplateOnboardingAccounts}
          csvData={csvData}
        ></DoubleRowHeader>
      );
    }
    default: {
      return (
        <SingleRowHeader
          key={'SingleRowHeader'}
          filteredData={filteredData}
          accounts={accounts}
          chipFilterClicked={chipFilterClicked}
          cloudTypeChips={cloudTypeChips}
          showUnComplateOnboardingAccounts={showUnComplateOnboardingAccounts}
          setShowUnComplateOnboardingAccounts={setShowUnComplateOnboardingAccounts}
          csvData={csvData}
        ></SingleRowHeader>
      );
    }
  }
};

CloudAccountsTableHeader.propTypes = {
  filteredData: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  chipFilterClicked: PropTypes.func.isRequired,
  cloudTypeChips: PropTypes.array.isRequired,
  showUnComplateOnboardingAccounts: PropTypes.bool.isRequired,
  setShowUnComplateOnboardingAccounts: PropTypes.func.isRequired,
  usersStore: PropTypes.object.isRequired,
};
