import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { RolesContext } from './contexts/rolesContext';
import RolesHeader from './RolesHeader';
import RolesTable from './RolesTable';
import { GET_ROLES_QUERY_PARAMS } from '../consts';
import usePagination from 'users/hooks/usePagination';
import { useOrgToCustomers } from 'users/containers/Organization/components/OrgToCustomersSwitch/useOrgToCustomers';

import styles from './Roles.module.scss';

const Roles = () => {
  const {
    selectedCustomerDetails,
    handleOrgCustomersToggle,
    handleCustomerSelectionChanged,
    isCustomersView,
    setIsCustomersView,
    hookData,
  } = useOrgToCustomers(useRoles, initializeState);
  const [totalRoles, setTotalRoles] = useState(0);
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [excludeBuiltInRoles, setExcludeBuiltInRoles] = useState(true);

  const { NewTableWrapper } = useTable();

  const { data, isLoading, isFetching } = hookData.fetchRoles({
    [GET_ROLES_QUERY_PARAMS.PAGINATION_TOKEN.name]: paginationToken,
    [GET_ROLES_QUERY_PARAMS.SEARCH.name]: search,
    [GET_ROLES_QUERY_PARAMS.EXCLUDE_BUILT_IN_ROLES.name]: excludeBuiltInRoles,
    [GET_ROLES_QUERY_PARAMS.PAGE_SIZE.name]: GET_ROLES_QUERY_PARAMS.PAGE_SIZE.defaultValue,
  });

  useEffect(() => {
    if (
      selectedCustomerDetails.customer ||
      !isCustomersView ||
      (isCustomersView && !selectedCustomerDetails.customer)
    ) {
      hookData.reset();
    }
  }, [isCustomersView, selectedCustomerDetails.customer]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_ROLES_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setSearch(searchParam);
      setExcludeBuiltInRoles(false);
    }
  }, []);

  const handlePageChange = (newPaginationToken) => {
    setPaginationToken(newPaginationToken);
  };

  const { currentPage, pageChanged } = usePagination({
    data,
    onPageChange: handlePageChange,
  });

  useEffect(() => {
    setTotalRoles(data?.total ?? 0);
  }, [data?.total]);

  function initializeState() {
    setSearch(null);
    setPaginationToken(null);
    setSelectedRows([]);
    setIsAllExpanded(false);
  }

  return (
    <div className={styles.roles}>
      <RolesContext.Provider
        value={{
          isAllExpanded,
          isCustomersView,
          excludeBuiltInRoles,
          paginationToken,
          search,
          selectedCustomer: selectedCustomerDetails.customer,
          selectedRows,
          setIsAllExpanded,
          setIsCustomersView,
          setExcludeBuiltInRoles,
          setSearch,
          setSelectedRows,
          setPaginationToken,
          totalRoles,
        }}
      >
        <div className={styles.roles}>
          <NewTableWrapper>
            <RolesHeader
              pageChanged={pageChanged}
              currentPage={currentPage}
              isNextPageLoading={isLoading || isFetching}
              onCustomerSelectionChanged={handleCustomerSelectionChanged}
              onOrgCustomersToggle={handleOrgCustomersToggle}
            />
            <RolesTable isLoading={isLoading} roles={data?.roles || []} />
          </NewTableWrapper>
        </div>
      </RolesContext.Provider>
    </div>
  );
};

export default Roles;
