import { useMutation } from '@tanstack/react-query';
import { getImpersonationToken } from 'shared/utils/tokenUtil';

const useTokens = () => {
  return {
    useExchangeTokenByUserImpersonationToken: ({ handleOnSuccess }) =>
      useMutation({
        mutationFn: getImpersonationToken,
        onSuccess: ({ accessToken, userId }) => {
          if (handleOnSuccess) {
            handleOnSuccess({ accessToken, userId });
          }
        },
      }),
  };
};

export default useTokens;
