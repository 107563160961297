import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { useTableHeaderContext } from 'shared/components/tableHeader/TableHeaderContext';
import Spinner from 'shared/components/andtComponents/Spinner';
import AccountsTable from './components/AccountsTable';
import { filterAccountList, getAccountsChipFilterValue, getCloudAccounts } from './CloudAccountsUtil';
import { CloudAccountsTableHeader } from './components/CloudAccountsTableHeader/CloudAccountsTableHeader';

const CloudAccounts = ({ usersStore }) => {
  const rootStore = useRootStore();
  const { vendorMappingsStore } = rootStore;

  const [filteredData, setFilteredData] = useState(null);
  const [showUnComplateOnboardingAccounts, setShowUnComplateOnboardingAccounts] = useState(true);
  const [selectedChipsIds, setSelectedChipsIds] = useState([]);
  const [cloudTypeChips, setCloudTypeChips] = useState([]);

  const { searchText } = useTableHeaderContext();
  const data = usersStore.getAllCurrDisplayedUserAccounts();

  const { fetchOnboardings } = useOnboarding();
  const { data: onboardingAccounts, isLoading: isUncompletedAccountsFetchInProgress } = fetchOnboardings();

  const { defaultAccountId } = usersStore.usersModel.usersNotifications;

  const getIsAzureFocusMigrationAvailable = usersStore.getIsAzureFocusMigrationAvailable;

  const accounts = useMemo(() => {
    if (isUncompletedAccountsFetchInProgress) {
      return;
    }
    return getCloudAccounts(
      data,
      vendorMappingsStore,
      isUncompletedAccountsFetchInProgress,
      onboardingAccounts,
      defaultAccountId,
      getIsAzureFocusMigrationAvailable,
    );
  }, [data, vendorMappingsStore, defaultAccountId, isUncompletedAccountsFetchInProgress, onboardingAccounts]);

  useEffect(() => {
    if (!Array.isArray(accounts)) return;

    const newFilteredAccounts = filterAccountList({
      selectedChipsIds,
      accounts,
      searchText,
      showUnComplateOnboardingAccounts,
    });

    setFilteredData(newFilteredAccounts);
  }, [searchText, accounts, selectedChipsIds, showUnComplateOnboardingAccounts]);

  useEffect(() => {
    if (!filteredData) return;

    const uniqChips = getAccountsChipFilterValue(accounts, filteredData, selectedChipsIds);

    setCloudTypeChips(uniqChips);
  }, [filteredData, accounts, selectedChipsIds]);

  const chipFilterClicked = useCallback((chip) => {
    setSelectedChipsIds((prevSelectedChips) => {
      if (chip.selected) {
        const newSelectedChips = prevSelectedChips.filter((id) => id !== chip.cloudTypeId);
        return newSelectedChips;
      }

      return [...prevSelectedChips, chip.cloudTypeId];
    });
  }, []);

  if (!vendorMappingsStore.initialized || isUncompletedAccountsFetchInProgress || !filteredData) {
    return <Spinner />;
  }

  return (
    <div>
      <CloudAccountsTableHeader
        key={'upper-header'}
        filteredData={filteredData}
        accounts={accounts}
        usersStore={usersStore}
        chipFilterClicked={chipFilterClicked}
        cloudTypeChips={cloudTypeChips}
        showUnComplateOnboardingAccounts={showUnComplateOnboardingAccounts}
        setShowUnComplateOnboardingAccounts={setShowUnComplateOnboardingAccounts}
      />
      <div className="pt-3 mt-2" />
      <AccountsTable data={filteredData} usersStore={usersStore} isSearchActive={!!searchText?.length} />
    </div>
  );
};

CloudAccounts.propTypes = {
  usersStore: PropTypes.object.isRequired,
};

const ObserverClousAccounts = observer(CloudAccounts);
export default ObserverClousAccounts;
