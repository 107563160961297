import React from 'react';
import PropTypes from 'prop-types';
import styles from './customizeLegend.module.scss';
import SvgPattern from './svgPattern.js';

export const CustomizedLegend = ({ payload, hideKeys, specialLegends, dataProperties }) => (
  <div className={styles.customizedLegendWrapper}>
    <div className={styles.customizedLegend}>
      {payload?.map((entry, index) => {
        const entryId = `${entry.payload.id ?? index}Legend`;
        return !hideKeys?.includes(entry.dataKey) ? (
          <div key={entryId} className={styles.legendPair} id={entryId}>
            {!specialLegends?.includes(entry.dataKey) ? (
              <svg width="20" height="10" className={styles.legendSymbol}>
                <rect width="20" height="10" fill={entry.color} />
              </svg>
            ) : (
              <div
                className={styles.legendSymbolPattern}
                style={{
                  background: SvgPattern({ color: dataProperties?.[entry.dataKey]?.legendColors || entry.color }),
                }}
              />
            )}
            <div className={styles.legendLabel}>{dataProperties?.[entry.value]?.displayValue || entry.value}</div>
          </div>
        ) : null;
      })}
    </div>
  </div>
);

CustomizedLegend.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      payload: PropTypes.shape({
        id: PropTypes.string,
      }),
      dataKey: PropTypes.string,
      color: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  hideKeys: PropTypes.arrayOf(PropTypes.string),
  specialLegends: PropTypes.arrayOf(PropTypes.string),
  dataProperties: PropTypes.object,
};
