import PropTypes from 'prop-types';
import React from 'react';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import styles from './FullAccessCheckbox.module.scss';

const FullAccessCheckbox = ({ isFullAccess, isDisabled, onFullAccessSelectChanged }) => {
  const fullAccessContent = () => (
    <div className={styles.fullAccess}>
      <div>Full Access</div>
      <GenerateIcon iconName={ICONS.shieldKeyhole.name} className={styles.shieldKeyholeIcon} />
    </div>
  );

  return (
    <Checkbox
      isChecked={isFullAccess}
      isDisabled={isDisabled}
      onChange={onFullAccessSelectChanged}
      primary
      isSimple
      text={fullAccessContent()}
    />
  );
};

FullAccessCheckbox.propTypes = {
  isFullAccess: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onFullAccessSelectChanged: PropTypes.func,
};

export default FullAccessCheckbox;
