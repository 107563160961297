/*

PLEASE DON'T ADD Route LINKS HERE

*/
export enum ExternalLinks {
  // eslint-disable-next-line typescript:S5332
  LocalHost = 'http://127.0.0.1:8080',
  // eslint-disable-next-line typescript:S5332
  LocalServerURI = 'http://localhost:3000',

  CognitoIdpPrefix = 'https://cognito-idp',
  AwsSavingsPlansPurchase = 'https://console.aws.amazon.com/costmanagement/home?/savings-plans/purchase#/savings-plans/purchase',
  AwsConfiguringWettingsForCLI = 'https://docs.aws.amazon.com/cli/latest/userguide/cli-configure-quickstart.html',
  AwsAccountHome = 'https://console.aws.amazon.com/billing/home?#/account',
  SvgXMLPAth = 'http://www.w3.org/2000/svg',
  AzureMigrateFromEnterpriseAgreementToMicrosoftCustomerAgreementAPIs = 'https://learn.microsoft.com/en-us/azure/cost-management-billing/costs/migrate-cost-management-api',
  AtlassianAPITokens = 'https://id.atlassian.com/manage-profile/security/api-tokens',
  SlackAuthorize = 'https://slack.com/oauth/v2/authorize',
  AzureVMShutdownTipsToSaveMoney = 'https://build5nines.com/properly-shutdown-azure-vm-to-save-money/',
  AzureMoveResourcesToANewResourceGroupOrSubscription = 'https://docs.microsoft.com/en-us/azure/azure-resource-manager/management/move-resource-group-and-subscription',
  AzureSaveCostsWithAppServiceReservedInstances = 'https://docs.microsoft.com/en-us/azure/cost-management-billing/reservations/prepay-app-service',
  AWSDiscoveringAndDeletingIncompleteMultipartUploadsToLowerS3Costs = 'https://aws.amazon.com/blogs/aws-cloud-financial-management/discovering-and-deleting-incomplete-multipart-uploads-to-lower-amazon-s3-costs/',
  AWSDeleteKmsKey = 'https://docs.aws.amazon.com/kms/latest/developerguide/deleting-keys.html',
  AWSIPv6SupportForYourVPC = 'https://docs.aws.amazon.com/vpc/latest/userguide/vpc-migrate-ipv6.html',
  AWSEc2TypeChanges = 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-instance-resize.html',
  TerminateAWSEc2Instances = 'https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/terminating-instances.html',
  AWSMoveEc2Instance = 'https://aws.amazon.com/premiumsupport/knowledge-center/move-ec2-instance/',
  AWSDeletingAnAmazonDocumentDBCluster = 'https://docs.aws.amazon.com/documentdb/latest/developerguide/db-cluster-delete.html',
  AWSLogClasses = 'https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/CloudWatch_Logs_Log_Classes.html',
  AWSTrustedAccess = 'https://docs.aws.amazon.com/AWSCloudFormation/latest/UserGuide/stacksets-orgs-activate-trusted-access.html',
}
