import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'shared/components/andtComponents/Spinner';
import LinkedAccountsComponentWrapper from '../../../components/LinkedAccounts/LinkedAccountsComponentWrapper';
import {
  DATA_ACCESS_TABS,
  ENTITIES,
  ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS,
  ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS,
} from '../../../consts';
import CostCenters from 'users/containers/Organization/CostCenters/CostCenters';
import LeftSideTabs from 'users/containers/Organization/Roles/rolesExpandedRowComponents/DataAccess/LeftSideTabs';

const DataAccessContent = ({
  dataToDisplay,
  isLoading,
  roleId,
  isCreateEditMode,
  isInProgress,
  isViewOnly,
  modifiedSelectionById,
  onRemoveLinkedAccounts,
  onRemoveCostCenters,
  onAccountsSelectionChange,
  onCostCentersSelectionChange,
  selectedTab,
  setSelectedTab,
}) => {
  const getSelectedTabComponent = useCallback(() => {
    let tabComponent;
    switch (selectedTab.id) {
      case DATA_ACCESS_TABS.ACCOUNTS.id:
        tabComponent =
          isLoading || isInProgress ? (
            <div className="position-relative ">
              <Spinner />
            </div>
          ) : (
            <LinkedAccountsComponentWrapper
              additionalData={{ roleId: roleId }}
              allAccountsData={dataToDisplay}
              entity={ENTITIES.ROLE.id}
              isCreateEditMode={isCreateEditMode}
              isViewOnly={isViewOnly}
              linkedAccountsColumns={Object.values(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
              onRemoveLinkedAccount={onRemoveLinkedAccounts}
              onSelectionChange={onAccountsSelectionChange}
              payerAccountsColumns={Object.values(ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
            />
          );
        break;
      case DATA_ACCESS_TABS.COST_CENTERS.id:
        tabComponent = (
          <CostCenters
            isRoleCostCentersDataAccessView={true}
            isSelectionEnabled={isCreateEditMode && !isViewOnly}
            isRoleCostCentersDataAccessLoading={isLoading || isInProgress}
            modifiedSelectionById={modifiedSelectionById}
            onRemoveCostCenters={onRemoveCostCenters}
            onSelectionChange={onCostCentersSelectionChange}
            roleCostCentersDataAccess={dataToDisplay}
            roleId={roleId}
          />
        );
        break;
      default:
        tabComponent = null;
    }
    return tabComponent;
  }, [
    dataToDisplay,
    isCreateEditMode,
    isInProgress,
    isLoading,
    isViewOnly,
    modifiedSelectionById,
    onAccountsSelectionChange,
    onCostCentersSelectionChange,
    onRemoveCostCenters,
    onRemoveLinkedAccounts,
    roleId,
    selectedTab.id,
  ]);

  return (
    <LeftSideTabs
      getSelectedTabComponent={getSelectedTabComponent}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      showTabsTooltip={isCreateEditMode}
      tabs={Object.values(DATA_ACCESS_TABS)}
    />
  );
};

DataAccessContent.propTypes = {
  dataToDisplay: PropTypes.array,
  isCreateEditMode: PropTypes.bool,
  isInProgress: PropTypes.bool,
  isLoading: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  modifiedSelectionById: PropTypes.object,
  onAccountsSelectionChange: PropTypes.func,
  onCostCentersSelectionChange: PropTypes.func,
  onRemoveCostCenters: PropTypes.func,
  onRemoveLinkedAccounts: PropTypes.func,
  roleId: PropTypes.string,
  selectedTab: PropTypes.shape({
    id: PropTypes.string,
  }),
  setSelectedTab: PropTypes.func,
};

export default DataAccessContent;
