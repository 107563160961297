/* eslint-disable react-hooks/rules-of-hooks */
import { useMatch } from 'react-router-dom';
import { Routes } from 'shared/constants/routes';
import { useState } from 'react';

function useCurrentRoute() {
  const [match, setMatch] = useState(null);

  for (const route of Object.values(Routes || {})) {
    const routMatch = useMatch(route);
    if (routMatch && routMatch.pattern.path !== match) {
      setMatch(routMatch.pattern.path);
    }
  }
  return match;
}

export default useCurrentRoute;
