import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TopBarSearch from 'shared/components/TopBarSearch';
import CheckBox from 'shared/components/form/CheckBox';
import { Col, Row } from 'reactstrap';
import AddMoreFiltersList from 'shared/components/AddMoreFiltersList';

const externalContainerStyle = {
  margin: '0px 0px 15px 0px',
  maxWidth: '100%',
};
const innerContainerStyle = {
  minHeight: '35px',
  borderRadius: '3px',
  backgroundColor: '#FFFFFF',
  boxShadow: '0 0 4px 0 rgba(0,0,0,0.15)',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  alignItems: 'center',
  margin: '0px 0px 15px 0px',
  hover: {
    cursor: 'pointer',
  },
};

const buttonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  color: 'rgb(78, 152, 203)',
  cursor: 'pointer',
};

class ChartKeysSearchAndFilterPanel extends PureComponent {
  static propTypes = {
    clearAllFilters: PropTypes.func.isRequired,
    selectAllFilters: PropTypes.func.isRequired,
    handleSearchInputChange: PropTypes.func.isRequired,
    handleAddOrRemoveMoreFilters: PropTypes.func.isRequired,
    allFilters: PropTypes.array.isRequired,
    searchValue: PropTypes.string,
    groupBy: PropTypes.string,
    isOthersVisible: PropTypes.bool.isRequired,
    isShowOthers: PropTypes.bool.isRequired,
    isShowOthersChange: PropTypes.func.isRequired,
    visibleFiltersWithSearchValues: PropTypes.array.isRequired,
    selectedIds: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
    };
  }

  toggleOrForce = (isForced) => {
    const { collapse } = this.state;
    const value = isForced !== undefined ? isForced : !collapse;
    this.setState({
      collapse: value,
    });
  };

  amountOfFilters = () => {
    const { selectedIds, visibleFiltersWithSearchValues, searchValue = '' } = this.props;
    const numOfFilters = visibleFiltersWithSearchValues.length;
    if (searchValue) {
      return numOfFilters;
    }
    return selectedIds.length > numOfFilters ? numOfFilters : selectedIds.length;
  };

  render() {
    const {
      clearAllFilters,
      selectAllFilters,
      handleSearchInputChange,
      allFilters,
      handleAddOrRemoveMoreFilters,
      groupBy,
      isOthersVisible,
      isShowOthers,
      isShowOthersChange,
      searchValue,
      selectedIds,
    } = this.props;
    const { collapse } = this.state;
    return (
      <Row style={externalContainerStyle}>
        <Col style={innerContainerStyle} className="w-100 overflow-auto chart-keys-search-bar">
          <TopBarSearch handler={handleSearchInputChange} currentValue={searchValue} />
          <button type="button" style={buttonStyle} onClick={() => clearAllFilters()}>
            Clear all
          </button>
          <span style={{ marginBottom: '3px', color: '#64677794' }}>{' | '}</span>
          <button type="button" style={buttonStyle} onClick={() => selectAllFilters()}>
            Select all
          </button>
          <span style={{ marginBottom: '3px', color: '#64677794' }}>{' | '}</span>
          {allFilters.length > 0 && (
            <button type="button" style={buttonStyle} onClick={() => this.toggleOrForce()}>
              Items ({`${this.amountOfFilters()}/${allFilters.length}`})
            </button>
          )}
          {isOthersVisible && <span style={{ marginBottom: '3px', color: '#64677794' }}>{' | '}</span>}
          {isOthersVisible && (
            <CheckBox
              style={{ marginBottom: '0', marginLeft: '5px' }}
              labelStyle={{ color: 'rgb(78, 152, 203)', padding: '0 0 0 22px' }}
              name="isShowOthers"
              value={isShowOthers}
              onChange={(e) => isShowOthersChange(e.target.checked)}
              label="Other"
            />
          )}
        </Col>
        <AddMoreFiltersList
          toggleOrForce={this.toggleOrForce}
          handleAddOrRemoveMoreFilters={handleAddOrRemoveMoreFilters}
          collapse={collapse}
          listOfValues={allFilters}
          selected={selectedIds}
          groupBy={groupBy}
          isFiltersAmountLimit
        />
      </Row>
    );
  }
}

export default ChartKeysSearchAndFilterPanel;
