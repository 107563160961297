import React from 'react';
import ReactSelect from 'react-select';
import classNames from 'classnames';

import { palette } from '../../constants/colorsConstants';
import classes from './AppSelect.module.scss'

export const formatOptionLabel = ({ label, description }) => (
  <div className={classes.customOptionWrapper}>
    <div className={classes.optionLabel}>{label}</div>
    <div className={classes.optionSubLabel}>{description}</div>
  </div>
);

export const CustomInputValue = ({ data }) => (
  <div className={classes.selectOptionWrapper}>
    <div>{data.label}</div>
  </div>
);
const { gray, blue } = palette;
export const getAppSelectStyles = ({ height, disabled, isEmpty, backgroundColor = gray[200] }) => ({
  container: (base) => ({ ...base, height, borderRadius: 5 }),
  valueContainer: (base) => ({ ...base, height: '100%', display: 'flex', flexWrap: 'nowrap' }),
  indicatorsContainer: (base) => ({
    ...base,
    height,
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: isEmpty ? 'unset' : 'none',
    color: gray[400],
    stroke: 'none',
  }),
  indicatorSeparator: (base) => ({ ...base, display: 'none' }),
  clearIndicator: (base) => ({ ...base, color: gray[500], stroke: 'none' }),
  control: (base, state) => ({
    ...base,
    borderColor: `${state.isHovered ? gray[300] : 'transparent'} !important`,
    outline: state.isFocused ? `2px solid ${blue[450]}` : 'undefined',
    borderRadius: 5,
    backgroundColor: disabled ? gray[100] : backgroundColor,
    color: disabled ? gray[400] : gray[500],
    fontSize: 16,
    height,
    minHeight: 'unset',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  placeholder: (base) => ({
    ...base,
    color: gray[400],
  }),
  option: (base, state) => ({
    ...base,
    backgroundColor: state.isSelected ? blue[200] : 'white',
    cursor: 'pointer',
  }),
});

export interface AppSelectProps {
  className?: string;
  value: any;
  options: any[];
  placeholder?: string;
  onChange(selected: string): void;
  automationid?: string;
  disabled?: boolean;
  height?: number;
  invalid?: boolean;
  isClearable?: boolean;
}

const AppSelect: React.FC<AppSelectProps> = ({
  className,
  value,
  options,
  placeholder = 'Select',
  onChange,
  disabled,
  height = 36,
  invalid,
  isClearable = true,
  ...reactSelectProps
}) => {
  const activeOption = options?.find((o) => o?.value === value) || value;

  return (
    <ReactSelect
      {...reactSelectProps}
      className={classNames(classes.appSelect, className, invalid && classes.invalid, { disabled })}
      classNamePrefix="app-select"
      isClearable={isClearable}
      value={activeOption}
      components={{ SingleValue: CustomInputValue }}
      formatOptionLabel={formatOptionLabel}
      options={options}
      placeholder={placeholder}
      onChange={(option) => onChange(option?.value)}
      isDisabled={disabled}
      styles={getAppSelectStyles({ height, disabled, isEmpty: !value })}
    />
  );
};

export default AppSelect;
