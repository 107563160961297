import React from 'react';
import DatasetIDImage from './assets/DatasetID.jpg';
import commonStyles from '../Pages.module.scss';
import { useBrand } from '~/app/contexts/Brand/BrandContext.js';
import Button from '~/shared/components/andtComponents/Button.js';
import getOnboardingLinks from '~/app/containers/Onboarding/utils/getOnboardingLinks.js';

const K8sIntegrationPageHelp = {};

K8sIntegrationPageHelp.projectID = (gcpOnboardingBillingDatasetProjectIDHelp) => ({
  component: () => {
    const brand = useBrand();
    return (
      <p className={commonStyles.helpText}>
        <Button
          onClick={() => window.open(getOnboardingLinks(brand).GCP.k8sIntegration, '_blank')}
          text="Check our guide"
          isTextButton
          overrideStyles={{ paddingLeft: 0, paddingRight: 0, height: 20 }}
        />{' '}
        to set up a BigQuery dataset for storing K8s labels. Once done, copy the <span>ID of the project</span> that
        will host your BigQuery billing dataset.
      </p>
    );
  },
  image: gcpOnboardingBillingDatasetProjectIDHelp,
  imageHeight: 246,
});

K8sIntegrationPageHelp.k8sToggle = {
  component: () => {
    const brand = useBrand();
    return (
      <p className={commonStyles.helpText}>
        This is an optional step where you can define a dataset to allow {brand.name} to collect K8s labels. If you
        prefer to skip, click "Validate" to proceed with onboarding.
      </p>
    );
  },
};

K8sIntegrationPageHelp.datasetID = {
  component: () => (
    <p className={commonStyles.helpText}>
      Copy of the <span>name of the dataset</span> you created for the K8s metadata and labels data.
    </p>
  ),
  image: DatasetIDImage,
  imageHeight: 246,
};

export default K8sIntegrationPageHelp;
