import LabelCoordinator from 'shared/modules/labelCoordinator';
import { gcpCommonFieldToDisplayField, GCPCommonFieldLabels } from 'shared/constants/gcpConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { getAccountTagFilterLabel, getBusinessMappingFilterLabel } from './awsUtils';
import { AwsCommonFields } from '../constants/awsConstants';
import { getCustomTagFilterLabel } from './sharedUtils';

export const getDisplayGcpLabel = (field) => {
  let label = '';
  label = GCPCommonFieldLabels.get(field);
  if (field && !label) {
    label = gcpCommonFieldToDisplayField.get(field) || LabelCoordinator.getServiceNameDisplayValue(field);
  }
  return label;
};

export const getFieldDisplayLabel = (field, secondLevelItemsValuesMap, isKeyCloakManagement) => {
  let label = '';
  if (field) {
    if (field.includes('customtags')) {
      label = getCustomTagFilterLabel(field, CLOUD_TYPE_IDS.GCP);
    } else {
      label = GCPCommonFieldLabels.get(field);
      if (!label) {
        label = gcpCommonFieldToDisplayField.get(field) || field;
      }
      if (field.includes('accounttags')) {
        label = getAccountTagFilterLabel(field);
      }
      if (field.includes(AwsCommonFields.BUSINESS_MAPPING)) {
        let fieldValue = field;
        if (secondLevelItemsValuesMap) {
          fieldValue = ([...secondLevelItemsValuesMap.entries()].find(([, v]) => v === field) || [])[0] || field;
        }
        label = getBusinessMappingFilterLabel(fieldValue);
      }
    }
  }

  return label;
};

const familyTypesWithTypes = [];

const servicesWithFamilyTypes = ['Compute Engine'];

// this is needed
export const isServiceHasTypes = () => false;

export const isServiceHasFamilyType = (serviceName) => servicesWithFamilyTypes.includes(serviceName);

export const isFamilyTypeHasTypes = (familyType) => familyTypesWithTypes.includes(familyType);
