import React from 'react';
import PropTypes from 'prop-types';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import CustomerSelection from 'users/containers/Organization/components/OrgToCustomersSwitch/CustomerSelection';
import OrgCustomersSwitchButton from 'users/containers/Organization/components/OrgToCustomersSwitch/OrgCustomersSwitchButton';

const OrgToCustomerSwitch = ({
  onCustomerSelectionChanged,
  onOrgCustomersToggle,
  isCustomersView,
  selectedCustomer,
}) => {
  return (
    <>
      <OrgCustomersSwitchButton
        action={Action.View}
        category={OrganizationEntityCategory.Roles}
        isCustomersView={isCustomersView}
        onOrgCustomersToggle={onOrgCustomersToggle}
      />
      <CustomerSelection
        isDisabled={!isCustomersView}
        onCustomerSelected={onCustomerSelectionChanged}
        selectedCustomer={selectedCustomer}
      />
    </>
  );
};

OrgToCustomerSwitch.propTypes = {
  isCustomersView: PropTypes.bool.isRequired,
  onCustomerSelectionChanged: PropTypes.func.isRequired,
  onOrgCustomersToggle: PropTypes.func.isRequired,
  selectedCustomer: PropTypes.object,
};

export default OrgToCustomerSwitch;
