import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { Button, Card, CardBody, Col, Collapse, Container, Row } from 'reactstrap';
import PlusIcon from 'mdi-react/PlusIcon';
import MinusIcon from 'mdi-react/MinusIcon';
import Spinner from 'shared/components/andtComponents/Spinner';
import PageHeader from 'shared/components/PageHeader';
import { PageNames } from 'shared/constants/appConstants';
import FieldFilter from 'shared/components/FieldFilter';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';
import { withInvoiceFiltersContextConsumer } from 'invoices/contexts/InvoiceFiltersContext';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { buildStartAndEndDate, datePickerSyntexDates, getDatePlusDays } from 'shared/utils/dateUtil';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useIdleInstances } from 'usage/hooks/react-query/useIdleInstances';
import IdleCriteriaRulesPanel from './components/IdleCriteriaRulesPanel';
import IdleInstancesTable from './components/IdleInstancesTable';

const IdleInstancesPage = ({ filtersValuesMap }) => {
  const { usageStore } = useRootStore();
  const [collapse, setCollapse] = React.useState(false);
  const [filterSelectedOptionsMap, setFilterSelectedOptionsMap] = React.useState(
    new Map([
      ['criterianame', [{ label: 'All', value: 'all' }]],
      ['linkedaccid', []],
    ]),
  );
  const [startDate, setStartDate] = React.useState(buildStartAndEndDate(null, null).startDate);
  const [endDate, setEndDate] = React.useState(buildStartAndEndDate(null, null).endDate);
  const [filterFormMsg, setFilterFormMsg] = React.useState('');

  const { fetchIdleInstancesCriteria, fetchIdleInstancesTableData } = useIdleInstances();
  const { data = [], isLoading } = fetchIdleInstancesCriteria();

  const {
    data: tableData = [],
    refetch: fetchTableData,
    isLoading: isTableLoading,
  } = fetchIdleInstancesTableData({
    startDate,
    endDate,
    linkedAccounts: filterSelectedOptionsMap.get('linkedaccid').map((linkedAcc) => linkedAcc.value),
    criteriaIds: filterSelectedOptionsMap.get('criterianame').find((cn) => cn.value === 'all')
      ? null
      : filterSelectedOptionsMap.get('criterianame').map((cn) => cn.value),
  });

  const availableLinkedAccounts = useMemo(() => {
    const linkedAccountsSet = new Set();
    data.forEach((criteria) => {
      if (Array.isArray(criteria.linkedAccounts)) {
        criteria.linkedAccounts.forEach((la) => linkedAccountsSet.add(la));
      }
    });
    return [...linkedAccountsSet];
  }, [data]);
  const availableCriteriaNames = useMemo(
    () => [
      { label: 'All', value: 'all' },
      ...data.map((criteria) => ({
        label: criteria.criteriaName,
        value: criteria.criteriaId,
      })),
    ],
    [data],
  );

  const handleGetIdleInstancesTableData = async () => {
    setFilterFormMsg('');
    try {
      const linkedAccounts = filterSelectedOptionsMap.get('linkedaccid');
      if (isEmptyArray(linkedAccounts)) {
        setFilterFormMsg('* Please select at least one linked account');
      }
      await fetchTableData();
    } catch {
      setFilterFormMsg('Something went wrong, please try again later');
    }
  };

  const handleDateChange = ({ startDate: start, endDate: end }) => {
    const { startDate: modifiedStart, endDate: modifiedEnd } = buildStartAndEndDate(start || startDate, end || endDate);
    setStartDate(modifiedStart);
    setEndDate(modifiedEnd);
  };

  const selecLinkAccFilterValueHandler = (filterType, selectedOptions) => {
    const selectedOptionsMap = new Map(filterSelectedOptionsMap);
    selectedOptionsMap.set(filterType, selectedOptions);
    setFilterSelectedOptionsMap(selectedOptionsMap);
    setFilterFormMsg('');
  };

  const selecCriteriNameFilterValueHandler = (filterType, selectedOptions) => {
    const allIndex = selectedOptions.findIndex((option) => option.value === 'all');
    let validSelcetedOption = [...selectedOptions];
    if (allIndex > -1) {
      if (allIndex === selectedOptions.length - 1) {
        // just added all
        validSelcetedOption = [{ label: 'All', value: 'all' }];
      } else {
        validSelcetedOption.splice(allIndex, 1);
      }
    }
    const selectedOptionsMap = new Map(filterSelectedOptionsMap);
    selectedOptionsMap.set(filterType, validSelcetedOption);
    setFilterSelectedOptionsMap(selectedOptionsMap);
    setFilterFormMsg('');
  };

  if (isLoading) {
    return <Spinner />;
  }

  const preparedLinkedAccountsOptions = usageStore.getPreparedLinkedAccountsOptions(
    availableLinkedAccounts,
    filtersValuesMap.get('linkedaccid'),
  );
  const preparedIdleInstancesTableData = tableData
    ? tableData.map((datum) => {
        const currLinkedAccItem =
          preparedLinkedAccountsOptions.find((item) => item.value === datum.linkedAccountId) || {};
        return {
          ...datum,
          linkedAccount: currLinkedAccItem.label || datum.linkedAccountId,
        };
      })
    : null;
  return (
    <Container>
      <PageHeader title={PageNames.IDLE_INSTANCES} />
      <Card>
        <CardBody className="idle-instances-body">
          <div className="collapse-title" onClick={() => setCollapse((p) => !p)}>
            {collapse ? <MinusIcon className="collapse-icon" /> : <PlusIcon className="collapse-icon" />}
            <h5 style={{ cursor: 'pointer', fontSize: '18px' }}>Idle Instances Criteria</h5>
          </div>
          <Collapse isOpen={collapse || data.length === 0}>
            <ReadOnlyDisplayWrapper
              isHide={false}
              category={OrganizationEntityCategory.OrganizationManagement}
              action={Action.Update}
            >
              <IdleCriteriaRulesPanel existingCriteriasNames={availableCriteriaNames} />
            </ReadOnlyDisplayWrapper>
          </Collapse>
          {!data || !data.length ? null : (
            <div className="filters-collapse-container">
              <Card>
                <CardBody className="filters-card-body">
                  <DatePickerFilter
                    onDateChange={handleDateChange}
                    startDate={datePickerSyntexDates(startDate, null).startDate}
                    endDate={datePickerSyntexDates(null, endDate).endDate}
                    maxDate={getDatePlusDays(startDate, 90)}
                    currPeriodGranLevel="day"
                    className="idle-instance-datepicker"
                  />
                  <Row className="linked-acc-filter">
                    <Col>
                      <h5 className="filter-bar-inner-col-title">Criteria Name</h5>
                      <FieldFilter
                        type="criterianame"
                        value={filterSelectedOptionsMap.get('criterianame')}
                        options={availableCriteriaNames}
                        handleChange={selecCriteriNameFilterValueHandler}
                      />
                    </Col>
                    <Col>
                      <h5 className="filter-bar-inner-col-title">Linked Account</h5>
                      <FieldFilter
                        type="linkedaccid"
                        value={filterSelectedOptionsMap.get('linkedaccid')}
                        options={preparedLinkedAccountsOptions}
                        handleChange={selecLinkAccFilterValueHandler}
                      />
                    </Col>
                  </Row>
                  <div style={{ marginLeft: -15 }}>
                    <Button
                      className="form__button-toolbar"
                      color="success"
                      size="sm"
                      onClick={handleGetIdleInstancesTableData}
                    >
                      Filter
                    </Button>
                    <h5 className="filter-bar-inner-col-title filter-msg">{filterFormMsg}</h5>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
          <div>
            {isTableLoading && <Spinner customStyle={{ width: '200px' }} />}
            {tableData?.length || isTableLoading ? (
              <IdleInstancesTable data={preparedIdleInstancesTableData} startDate={startDate} endDate={endDate} />
            ) : null}
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default withInvoiceFiltersContextConsumer(observer(IdleInstancesPage));
