import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';
import ButtonDropdown from './ButtonDropdown';
import styles from './ButtonMultiDropdown.module.scss';
import dropdownStyles from './ButtonDropdown.module.scss';
import IconFromPng from '../IconFromPng';

const ButtonMultiDropdown = ({
  dropdownProps = {},
  className = '',
  items = [],
  position = 'right',
  automationid = null,
}) => {
  const [expanded, setExpanded] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const positionStyleMap = {
    left: { right: 'calc(100% - 2px)' },
    right: { left: 'calc(100% - 2px)' },
  };

  const segmentReporting = (id) => {
    segmentEvent({
      type: 'select',
      target: automationid || dropdownProps.text || 'ButtonMultiDropdown',
      data: {
        id,
      },
    });
  };
  const handleSelection = (item) => {
    if (item.disabled) {
      return;
    }
    if (item.content) {
      setExpanded(expanded === item.id ? null : item.id);
      return;
    }
    segmentReporting(item.id);
    item.onClick();
    setMenuOpen(false);
  };

  const handleSecondarySelection = (item, event) => {
    // since click event can be on any level of children, need to find nearest parent with id
    let currentElemet = event.target;
    let option = '';
    for (let i = 0; i < 10; i++) {
      // limit the search to 10 levels up
      if (currentElemet.id) {
        option = currentElemet.id;
        break;
      }
      currentElemet = currentElemet.parentElement;
    }
    segmentReporting(option);
    event.stopPropagation();
    setMenuOpen(false);
  };

  return (
    <ButtonDropdown
      closeOnSelect={false}
      onClose={() => setExpanded(null)}
      menuOpen={menuOpen}
      setMenuOpen={setMenuOpen}
      {...dropdownProps}
      automationid={automationid}
    >
      {items.map((item) => (
        <div key={item.id} className={classnames(styles.option)}>
          {item.simpleOption && item.simpleOptionContent ? (
            <div onClick={() => segmentReporting(item.id)}>{item.simpleOptionContent}</div>
          ) : (
            <li
              onClick={() => handleSelection(item)}
              className={classnames(styles.optionComponent, {
                [styles.selected]: item.selected,
                [styles.simpleOption]: item.simpleOption,
              })}
              data-hover={expanded === item.id}
              data-disabled={item.disabled}
              automation-id={item.label}
            >
              <span style={{ color: item.selected ? item.color : undefined }}>
                {item.icon && <IconFromPng icon={item.icon} />}
                {item.label}
              </span>
            </li>
          )}
          {expanded === item.id ? (
            <div
              className={classnames(dropdownStyles.buttonDropDown, styles.secondDropdown, className)}
              onClick={(e) => handleSecondarySelection(item, e)}
              style={positionStyleMap[position]}
            >
              <div>{item.content}</div>
            </div>
          ) : null}
        </div>
      ))}
    </ButtonDropdown>
  );
};

ButtonMultiDropdown.propTypes = {
  dropdownProps: PropTypes.object,
  className: PropTypes.string,
  position: PropTypes.string,
  items: PropTypes.array,
  automationid: PropTypes.string,
};

export default ButtonMultiDropdown;
