import React, { useMemo, useRef, useState } from 'react';
import { Grid, TableColumnResizing, TableHeaderRow, TableSelection } from '@devexpress/dx-react-grid-material-ui';
import { DataTypeProvider, IntegratedSelection, SelectionState } from '@devexpress/dx-react-grid';
import PropTypes from 'prop-types';
import {
  CustomFormatterWithTooltipOnlyIfLongText,
  CustomHeaderCell,
  CustomHeaderSelectionCell,
  CustomSelectionCell,
} from 'shared/components/andtComponents/TableComponents/TableComponents';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { ADD_ROLES_MODAL_COLUMNS, getColumns, ROLES_COLUMNS } from '../../consts';
import Spinner from 'shared/components/andtComponents/Spinner.jsx';
import RolesNoData from 'users/containers/Organization/components/EmptyStates/RolesNoData.jsx';

const AddRolesTable = ({
  roles,
  selectedRoles,
  onRolesAddedRemoved,
  search,
  isLoading = false,
  isDisabled = false,
}) => {
  const [localColumnsWidth, setLocalColumnsWidth] = useState(
    Object.values(ADD_ROLES_MODAL_COLUMNS).map((c) => ({ columnName: c.columnName, width: c.width })),
  );

  const previousSelectedIndexesRef = useRef();
  const selectedRolesIndexes = useMemo(() => {
    if (!selectedRoles) {
      return [];
    }
    const selectedIndexes = selectedRoles
      .map((role) => roles?.findIndex((r) => r?.id === role?.id))
      .filter((index) => index !== -1);

    previousSelectedIndexesRef.current = selectedIndexes;
    return selectedIndexes;
  }, [selectedRoles, roles]);

  const onSelectionChange = (selectedIndexes) => {
    const addedIndexes = selectedIndexes.filter((index) => !previousSelectedIndexesRef.current.includes(index));
    const removedIndexes = previousSelectedIndexesRef.current.filter((index) => !selectedIndexes.includes(index));

    const addedRoles = addedIndexes.map((index) => roles[index]).filter((role) => !!role);
    const removedRoles = removedIndexes.map((index) => roles[index]).filter((role) => !!role);

    previousSelectedIndexesRef.current = selectedIndexes;
    onRolesAddedRemoved({ addedRoles, removedRoles });
  };

  const noRolesTexts = useMemo(() => {
    if (selectedRoles?.length === 0 && !search) {
      return { mainText: 'No roles to assign', subTexts: ['This user is already assigned to all roles'] };
    }
  }, [selectedRoles, search]);

  const renderRolesNoData = (props) => <RolesNoData {...props} {...noRolesTexts} isSmall={true} />;

  return isLoading ? (
    <Spinner />
  ) : (
    roles && (
      <Grid rows={roles} columns={getColumns(ADD_ROLES_MODAL_COLUMNS)} automationid="assign-roles-to-user-list">
        <SelectionState selection={selectedRolesIndexes} onSelectionChange={onSelectionChange} />
        <IntegratedSelection />
        <DataTypeProvider
          for={[ADD_ROLES_MODAL_COLUMNS.ROLE_NAME.columnName]}
          formatterComponent={(props) => (
            <CustomFormatterWithTooltipOnlyIfLongText overrideStyles={{ fontWeight: 500 }} {...props} />
          )}
        />
        <DataTypeProvider
          for={[ADD_ROLES_MODAL_COLUMNS.ROLE_ID.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
        />
        <DataTypeProvider
          for={[ROLES_COLUMNS.ROLE_DESCRIPTION.columnName]}
          formatterComponent={CustomFormatterWithTooltipOnlyIfLongText}
        />
        <TableWrapper virtual noDataCellComponent={renderRolesNoData} height={350} />
        <TableSelection
          showSelectAll
          cellComponent={(props) => <CustomSelectionCell {...props} isDisabled={isDisabled} />}
          headerCellComponent={(props) => <CustomHeaderSelectionCell {...props} isDisabled={isDisabled} />}
        />
        <TableColumnResizing
          resizingMode="nextColumn"
          columnWidths={localColumnsWidth}
          onColumnWidthsChange={setLocalColumnsWidth}
        />
        <TableHeaderRow cellComponent={CustomHeaderCell} />
      </Grid>
    )
  );
};

AddRolesTable.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onRolesAddedRemoved: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  search: PropTypes.string,
  selectedRoles: PropTypes.array.isRequired,
};

export default AddRolesTable;
