import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatTime } from 'shared/utils/dateUtil';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { getOnboardingError as getAWSOnboardingError } from 'app/containers/Onboarding/AwsOnboarding/utils/errors';
import { getOnboardingError as getAzureOnboardingError } from 'app/containers/Onboarding/AzureOnboarding/utils/errors';
import { getOnboardingError as getGcpOnboardingError } from 'app/containers/Onboarding/GcpOnboarding/utils/errors';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import styles from './WarningsList.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const getOnboardingError = (code, cloudType, brand) => {
  switch (cloudType) {
    case CLOUD_TYPE_IDS.AWS:
      return getAWSOnboardingError({ code, brand });
    case CLOUD_TYPE_IDS.AZURE:
      return getAzureOnboardingError({ code, brand });
    case CLOUD_TYPE_IDS.GCP:
      return getGcpOnboardingError({ code, brand });
    default:
      return null;
  }
};
const WarningsList = ({ warnings, cloudTypeId }) => {
  const brand = useBrand();
  const [open, setOpen] = useState(false);
  if (!open) {
    return (
      <div className={styles.closedContainer}>
        <div className={styles.title}>
          Errors
          <span> ({warnings.length})</span>
        </div>
        <div onClick={() => setOpen(true)} className={styles.openButton}>
          <GenerateIcon iconName={ICONS.chevronDown.name} />
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Errors ({warnings.length})</div>
        <div onClick={() => setOpen(false)} className={styles.openButton}>
          <GenerateIcon iconName={ICONS.xMark.name} />
        </div>
      </div>
      <div className={styles.list}>
        {warnings.map((w) => (
          <div key={w.code} className={styles.warning}>
            <div className={styles.warningTitle}>
              <span>
                <GenerateIcon iconName={ICONS.triangleExclamation.name} />
                Action Required
              </span>
              <span className={styles.date}>{formatTime(w.created)}</span>
            </div>
            <div className={styles.warningDesc}>{getOnboardingError(w.code, cloudTypeId, brand)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

WarningsList.propTypes = {
  cloudTypeId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  warnings: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string.isRequired,
      created: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default WarningsList;
