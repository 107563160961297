import { ONBOARDING_FIELDS } from '../utils/OnboardingConstants';

export const BYOD_ONBOARDING_STEPS = {
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  BYOD_DETAILS: 'BYOD_DETAILS',
  UPLOAD: 'UPLOAD',
  PROCESS_DATA: 'PROCESS_DATA',
};

export const BYOD_ONBOARDING_STEPS_SHORT_TITLES = {
  BYOD_DETAILS: 'Account Details',
  UPLOAD: 'Upload',
  PROCESS_DATA: 'Process Data',
};

export const BYOD_ONBOARDING_FIELDS = {
  ...ONBOARDING_FIELDS,
  IMPORT_TYPE: 'importType',
  CLOUD_TYPE_ID: 'cloudTypeId',
  ACCESS_KEY_URL: 'accessKeyUrl',
  ACCESS_POINT_ALIAS: 'accessPointAlias',
  ORGANIZATION_ID: 'organizationId',
  S3_PATH: 's3Path',
};

export const BYOD_ONBOARDING_STEP_TO_FIELDS = {
  [BYOD_ONBOARDING_STEPS.ACCOUNT_TYPE]: [BYOD_ONBOARDING_FIELDS.RESELLER_CUSTOMER],
  [BYOD_ONBOARDING_STEPS.BYOD_DETAILS]: [BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME, BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID],
};
