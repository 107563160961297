import React, { PureComponent } from 'react';
import { Button, Col, Collapse, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { AwsCommonFields, awsCommonFieldToDisplayField } from 'shared/constants/awsConstants';
import { azureCommonFieldToDisplayField } from 'shared/constants/azureConstants';
import { gcpCommonFieldToDisplayField } from 'shared/constants/gcpConstants';
import LabelCoordinator from 'shared/modules/labelCoordinator';
import If from 'shared/components/SimpleIf';
import PeriodGranularityFilter from 'shared/components/PeriodGranularityFilter';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import DateFilter from 'shared/modules/dateFilter';
import { default as ASSETS_ICONS } from 'shared/constants/assetsConstants';
import IconFromPng from 'shared/components/IconFromPng';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { propComparator } from 'shared/utils/sortUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import { modifiedPurchaseOptionToDisplay, modifyRegionNameToDisplay } from 'shared/utils/awsUtils';
import DatePickerFilter from './DatePickerFilter';
import FieldFilter from './FieldFilter';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

class ImmediateFilterBar extends PureComponent {
  static propTypes = {
    renderDatePickerFilter: PropTypes.bool.isRequired,
    startDate: PropTypes.object.isRequired,
    endDate: PropTypes.object.isRequired,
    maxDate: PropTypes.object,
    handleDateChange: PropTypes.func.isRequired,
    isDateRangeError: PropTypes.bool.isRequired,

    isShowYearly: PropTypes.bool,
    isShowQuarterly: PropTypes.bool,
    renderGranPeriodButton: PropTypes.bool.isRequired,
    currPeriodGranLevel: PropTypes.string.isRequired,
    handleChangeGranLevel: PropTypes.func.isRequired,

    renderFilters: PropTypes.bool.isRequired,
    selectedOptionsMap: PropTypes.object.isRequired,
    handleFilterChange: PropTypes.func.isRequired,
    fieldToFieldDistinctValuesMap: PropTypes.object.isRequired,
    isForK8S: PropTypes.bool.isRequired,

    handleApplyFiltersButtonClick: PropTypes.func.isRequired,
    isShowApplyFiltersButton: PropTypes.bool.isRequired,
    isApplyFiltersButtonDisabled: PropTypes.bool.isRequired,

    excludedFiltersStatusMap: PropTypes.object.isRequired,
    handleChangeFilterType: PropTypes.func.isRequired,
    includeExcludeFilterType: PropTypes.string,
    classes: PropTypes.object.isRequired,

    currDispUserCloudAccountType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    containerStyle: PropTypes.object,
    isFromReports: PropTypes.bool,
    isDisabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      filterBarCollapse: props.selectedOptionsMap && props.selectedOptionsMap.size > 0 && !props.isFromReports,
    };
  }

  toggle = (e) => {
    e.preventDefault();
    const { filterBarCollapse } = this.state;
    this.setState({
      filterBarCollapse: !filterBarCollapse,
    });
  };

  createOptions = (fieldValues, filterType) =>
    fieldValues.map((fieldValue) => {
      let value = '';
      let label = '';
      if (Object.prototype.toString.call(fieldValue) === '[object Object]' && 'linkedAccountId' in fieldValue) {
        value = fieldValue.linkedAccountId;
        label = fieldValue.displayLabel;
      } else if (filterType === 'virtualcustomtags') {
        value = fieldValue.uuid;
        label = fieldValue.name;
      } else if (filterType === 'purchaseoption') {
        value = fieldValue;
        label = modifiedPurchaseOptionToDisplay(fieldValue);
      } else if (filterType === 'namespace' && fieldValue === 'Not Allocated') {
        value = 'Not Available';
        label = modifyRegionNameToDisplay(filterType, fieldValue);
      } else {
        value = fieldValue;
        label = modifyRegionNameToDisplay(filterType, fieldValue);
      }
      return { value, label };
    });

  renderFilterByType = (
    includeExcludeFilterType,
    field,
    options,
    selectedOptionsMap,
    isOneChoiceFieldFilter = false,
  ) => {
    const { classes } = this.props;
    return (
      <div className="filter-bar-inner-col-wrapper">
        <div style={{ display: 'flex' }}>
          {includeExcludeFilterType === 'includeExclude' && (
            <Tooltip
              arrow
              title={`Press here to ${
                this.props.excludedFiltersStatusMap.has(field) ? 'include' : 'exclude'
              } the selected values`}
              placement="top"
            >
              <FormControlLabel
                control={
                  <Checkbox
                    icon={<GenerateIcon iconName={ICONS.plus.name} />}
                    checkedIcon={<GenerateIcon iconName={ICONS.minus.name} />}
                    checked={this.props.excludedFiltersStatusMap.has(field)}
                    onChange={() => this.props.handleChangeFilterType(field)}
                    classes={{
                      root: classes.root,
                    }}
                  />
                }
              />
            </Tooltip>
          )}
          <h5
            className="filter-bar-inner-col-title"
            style={{
              marginTop: '15px',
              marginLeft: `${includeExcludeFilterType === 'includeExclude' ? '-13px' : '0px'}`,
            }}
          >
            {/* <h5 className="filter-bar-inner-col-title" style={{ marginTop: '15px', marginLeft: '-13px' }}> */}
            {LabelCoordinator.getFieldLabel(field)}
            {/* {commonFieldToDisplayField.get(field)} */}
          </h5>
        </div>
        {isOneChoiceFieldFilter ? (
          <OneChoiceFieldFilter
            type={field}
            value={selectedOptionsMap.get(field) ? selectedOptionsMap.get(field) : 'Select...'}
            options={options ? options.sort(propComparator('label')) : options}
            handleChange={this.props.handleFilterChange}
          />
        ) : (
          <FieldFilter
            type={field}
            value={selectedOptionsMap.get(field)}
            options={options ? options.sort(propComparator('label')) : options}
            handleChange={this.props.handleFilterChange}
          />
        )}
      </div>
    );
  };

  renderFilters = (fieldToFieldDistinctValuesMap, selectedOptionsMap, isForK8S) => {
    const filters = [];
    let index = 1;
    if (!fieldToFieldDistinctValuesMap) {
      return null;
    }
    if (fieldToFieldDistinctValuesMap.size > 0) {
      fieldToFieldDistinctValuesMap.forEach((fieldValues, field) => {
        const options = this.createOptions(fieldValues, field);
        if (this.props.currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE) {
          filters.push(
            this.renderFilterByType(
              this.props.includeExcludeFilterType ?? 'include',
              field,
              options,
              selectedOptionsMap,
              azureCommonFieldToDisplayField,
              false,
              index,
            ),
          );
        } else if (this.props.currDispUserCloudAccountType === CLOUD_TYPE_IDS.GCP) {
          filters.push(
            this.renderFilterByType(
              this.props.includeExcludeFilterType ?? 'include',
              field,
              options,
              selectedOptionsMap,
              gcpCommonFieldToDisplayField,
              false,
              index,
            ),
          );
        } else if (AwsCommonFields.QUANTITY_TYPE !== field) {
          filters.push(
            this.renderFilterByType(
              this.props.includeExcludeFilterType ?? 'include',
              field,
              options,
              selectedOptionsMap,
              awsCommonFieldToDisplayField,
              false,
              index,
            ),
          );
        } else if (!isForK8S) {
          const isOneChoiceFieldFilter = true;
          filters.push(
            this.renderFilterByType(
              this.props.includeExcludeFilterType ?? 'include',
              field,
              options,
              selectedOptionsMap,
              awsCommonFieldToDisplayField,
              isOneChoiceFieldFilter,
              index,
            ),
          );
        }
        index++;
      });
    }
    return filters;
  };

  render() {
    const {
      startDate,
      endDate,
      fieldToFieldDistinctValuesMap,
      selectedOptionsMap,
      currPeriodGranLevel,
      renderGranPeriodButton,
      renderDatePickerFilter,
      renderFilters,
      handleDateChange,
      isShowQuarterly,
      isShowYearly,
      isForK8S,
      handleApplyFiltersButtonClick,
      isShowApplyFiltersButton,
      isApplyFiltersButtonDisabled,
      containerStyle = {},
      isDateRangeError,
      handleChangeGranLevel,
      isDisabled,
      maxDate = DateFilter.getDate(),
    } = this.props;

    const { filterBarCollapse } = this.state;
    const disabledStyle = isDisabled ? { pointerEvents: 'none', cursor: 'default', opacity: '0.5' } : {};
    return (
      // <Col>
      <>
        <Row
          className="w-auto"
          style={{ alignItems: 'center', marginBottom: '20px', ...containerStyle, ...disabledStyle }}
        >
          <If cond={renderGranPeriodButton}>
            <Col xl="auto" lg="auto" sm="auto" xs="auto">
              <PeriodGranularityFilter
                isShowYearly={isShowYearly}
                isShowQuarterly={isShowQuarterly}
                currPeriodGranLevel={currPeriodGranLevel}
                handleChangeGranLevel={handleChangeGranLevel}
                isDateRangeError={isDateRangeError}
                className="filter-bar-inner-col-text"
                startDate={startDate}
                endDate={endDate}
              />
            </Col>
          </If>
          <If cond={renderDatePickerFilter}>
            <Col xl="auto" lg="auto" sm="auto" xs="auto" style={{ marginRight: '20px' }}>
              <DatePickerFilter
                onDateChange={handleDateChange}
                startDate={startDate}
                endDate={endDate}
                maxDate={maxDate}
                currPeriodGranLevel={currPeriodGranLevel}
                isDateRangeError={isDateRangeError}
              />
            </Col>
          </If>
          <If cond={renderFilters}>
            <Col
              className={`filter-button-outer-wrapper ${!filterBarCollapse ? '' : 'filter-open'} `}
              xl="auto"
              lg="auto"
              sm="auto"
              xs="auto"
            >
              <div onClick={(e) => this.toggle(e)} className="filter-button-inner-wrapper">
                <Col className="filter-button-inner-button" xs="auto" md="auto" lg="auto" xl="auto">
                  <LinkIconButton>
                    {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <a href="#/" />
                    <IconFromPng size={23} icon={ASSETS_ICONS.filter} />
                  </LinkIconButton>
                </Col>
                <Col className="filter-button-inner-button" xs="auto" md="auto" lg="auto" xl="auto">
                  <h4>Filters</h4>
                </Col>
              </div>
            </Col>
          </If>
        </Row>
        <If cond={renderFilters}>
          <Collapse className="filters-collapse-container" isOpen={filterBarCollapse}>
            <div className="filters-collapse-inner-wrapper">
              {/* eslint-disable-next-line no-nested-ternary */}
              {!fieldToFieldDistinctValuesMap ? null : fieldToFieldDistinctValuesMap.size > 0 ? (
                this.renderFilters(fieldToFieldDistinctValuesMap, selectedOptionsMap, isForK8S)
              ) : (
                <Spinner customStyle={{ width: '200px' }} />
              )}
              {isShowApplyFiltersButton && (
                <Col style={{ marginTop: '10px' }} xl={12} lg={12} md={12} sm={12} xs={12}>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={handleApplyFiltersButtonClick}
                    disabled={isApplyFiltersButtonDisabled}
                  >
                    Apply
                  </Button>
                </Col>
              )}
            </div>
          </Collapse>
        </If>
      </>
    );
  }
}

export default ImmediateFilterBar;
