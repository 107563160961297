import { getVendorMappingsStore } from 'app/store/vendorsStore/vendorStoreProvider';
import classNames from 'classnames';
import { CSSModule } from 'reactstrap/types/lib/utils';

export const normalizeVendorLogoUrl = (url: string): string | undefined => {
  if (!url) {
    throw new Error('The Logo URL is empty');
  }
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  }
  return `${window.location.origin}${url.startsWith('/') ? '' : '/'}${url}`;
};

// Constants for the three major cloud vendors (AWS, Azure, GCP)
const MAJOR_CLOUD_VENDORS = [10000, 10001, 10002];

export const getCloudTypeIconClassName = (cloudTypeId: number, styles: CSSModule) => {
  const vendorStore = getVendorMappingsStore();
  const isByod = vendorStore.doesCloudTypeIdBelongToByod(cloudTypeId);
  const isMajorVendor = MAJOR_CLOUD_VENDORS.includes(cloudTypeId);

  return classNames({
    [styles.cloudVendorSquareIcon]: isByod && !isMajorVendor,
    [styles.byodCloudVendorIcon]: isByod && isMajorVendor,
    [styles.cloudTypeIcon]: true,
  });
};
