import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { CostTypeModes } from 'usage/constants/costAndUsageConstants';
import ButtonMultiDropdown from 'shared/components/andtComponents/ButtonMultiDropdown';
import icons from '../icons';
import RateBlock from './RateBlock';
import styles from './CostTypeMenu.module.scss';
import {
  buildUsageOption,
  getLabelByType,
  getTooltipByType,
  buildOptions,
  buildCarbonEmissions,
} from './CostTypeMenuUtil';

const { RateIcon } = icons.costUsage;

const CostTypeMenu = ({ costUsageHandler }) => {
  const {
    costOptions = [],
    costReadOnlyOptions = undefined,
    costValue,
    usageOptions = [],
    usageValue,
    onChange,
    costTypeMode,
    isRateDisabled,
    costDefaultType,
    carbonEmissionsOptions,
    carbonEmissionsValue,
  } = costUsageHandler;

  const costOptionName = costOptions?.find((c) => c.value === costValue)?.name ?? costDefaultType ?? 'Cost';
  const usageOptionName = (usageOptions || []).find((c) => c.value === usageValue)?.name;
  const carbonEmissionName = (carbonEmissionsOptions || []).find((c) => c.value === carbonEmissionsValue)?.name;
  const isCostContainOptions = !isEmptyArray(costOptions) || !isEmptyArray(costReadOnlyOptions);
  const isUsage = costTypeMode === CostTypeModes.USAGE;
  const isCost = costTypeMode === CostTypeModes.COST;
  const isRate = costTypeMode === CostTypeModes.RATE;
  const isCarbonEmissions = costTypeMode === CostTypeModes.CARBON_EMISSIONS;
  const labelByType = getLabelByType(costOptionName, usageOptionName, carbonEmissionName);
  const tooltipByType = getTooltipByType(costOptionName, usageOptionName, carbonEmissionName, costValue);
  const options = buildOptions(
    isCost,
    costDefaultType,
    isCostContainOptions,
    onChange,
    costValue,
    costReadOnlyOptions,
    costOptions,
  );
  if (usageOptions) {
    const usageOption = buildUsageOption(isUsage, usageOptionName, usageOptions, onChange, usageValue);
    options.push(usageOption);
  }
  if (!isRateDisabled) {
    options.push({
      id: 'rate',
      label: 'Rate',
      selected: isRate,
      icon: RateIcon,
      content: <RateBlock costUsageHandler={costUsageHandler} />,
    });
  }
  if (carbonEmissionsOptions?.length) {
    const carbonEmissionsOption = buildCarbonEmissions(
      isCarbonEmissions,
      carbonEmissionsOptions,
      onChange,
      carbonEmissionsValue,
    );
    options.push(carbonEmissionsOption);
  }
  return (
    <div className={styles.container}>
      <ButtonMultiDropdown
        dropdownProps={{
          text: labelByType[costTypeMode],
          icon: icons.menu.costTypeIcon,
          tooltipText: tooltipByType[costTypeMode],
          overrideMenuStyles: { minWidth: 200 },
        }}
        className={styles.dropDown}
        items={options}
        automationid="costTypeMenu"
      />
    </div>
  );
};

CostTypeMenu.propTypes = {
  costUsageHandler: PropTypes.object.isRequired,
};

export default CostTypeMenu;
