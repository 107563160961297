import { useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { STALE_TIME } from 'users/containers/Organization/consts';
import { handleError } from './helperFunctions';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';
import { fetchCustomerOrganizationToken } from 'users/new-user-management/hooks/reactQuery/apiResellerTokens';

export default function useResellerTokens(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const resellerTokensQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_RESELLER_TOKENS, userAccountKey];

  return {
    useCustomerOrganizationToken: (customerId) =>
      useQuery({
        queryKey: [...resellerTokensQueryKey, customerId],
        queryFn: () => fetchCustomerOrganizationToken(customerId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
  };
}
