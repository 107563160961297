import { BrandTypes, Themes } from '../enums';
import { Brand, Theme } from '../types';
import { GetBrandResource } from './GetBrandResource.tsx';
import { anodotBrand } from './anodot-brand.configuration.tsx';
import { umbrellaBrand } from './umbrella-brand.configuration';

export const ThemeConfiguration: Record<string, Theme> = {
  [Themes.Snow]: {
    name: 'Snow',
    themeName: Themes.Snow,
    hideContactUs: true,
    externalLinks: {
      TermsAndConditions: 'https://www.snowsoftware.com/legal/tos/',
      PrivacyPolicy: 'https://www.snowsoftware.com/legal/privacy-policy/',
    },
    loginLogo: GetBrandResource({
      resource: `https://whitelabeling-themes.s3.us-east-1.amazonaws.com/${Themes.Snow}/register-logo`,
    }),
  },
  [Themes.ProcureIT]: {
    name: 'Procure IT',
    themeName: Themes.ProcureIT,
    externalLinks: {
      ContactUs: 'https://www.procureit.com/contact',
    },
    loginLogo: GetBrandResource(
      { resource: `https://whitelabeling-themes.s3.us-east-1.amazonaws.com/${Themes.ProcureIT}/logo` },
      {
        style: { width: '30vh' },
      },
    ),
  },
};

export const BrandConfiguration: Record<string, Brand> = {
  [BrandTypes.Anodot]: anodotBrand,
  [BrandTypes.Umbrella]: umbrellaBrand,
};
