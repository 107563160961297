import { graphColors } from 'shared/constants/colorsConstants';

export const StringToColor = (() => {
  let instance = null;

  return {
    next: function stringToColor(str) {
      if (instance === null) {
        instance = {};
        instance.stringToColorHash = {};
        instance.nextVeryDifferntColorIdx = 0;
        instance.veryDifferentColors = graphColors;
      }
      if (!instance.stringToColorHash[str]) {
        if (instance.nextVeryDifferntColorIdx === instance.veryDifferentColors.length - 1) {
          // reached the last color
          instance.nextVeryDifferntColorIdx = 0;
        }
        instance.stringToColorHash[str] = instance.veryDifferentColors[instance.nextVeryDifferntColorIdx++];
      }
      return instance.stringToColorHash[str];
    },
  };
})();
