import React from 'react';
import PropTypes from 'prop-types';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';

import styles from './TextArea.module.scss';

const TextArea = React.forwardRef(
  (
    {
      automationid = '',
      className = '',
      disabled = false,
      id,
      invalidComponent = null,
      isAutoFocus = false,
      isInvalid = false,
      isResizable = false,
      name = '',
      onBlur = null,
      onChange = null,
      onFocus = null,
      onKeyDown = null,
      overrideStyles = {},
      placeholder = '',
      readOnly = false,
      rows = 3,
      value = '',
    },
    ref,
  ) => {
    const classes = `${styles.andtTextArea} ${isInvalid ? styles.invalid : ''} ${className}}`;

    const handleFocus = (val) => {
      segmentEvent({
        type: 'focus',
        target: automationid || name || 'Input',
        automationid,
      });
      if (onFocus) {
        onFocus(val);
      }
    };

    const renderTextArea = () => (
      <React.Fragment>
        <textarea
          rows={rows}
          ref={ref}
          className={classes}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          autoFocus={isAutoFocus}
          automation-id={automationid}
          id={id}
          name={name}
          readOnly={readOnly}
          style={{ ...overrideStyles, ...(isResizable ? {} : { resize: 'none' }) }}
        />
        {isInvalid && <p className={styles.error}>{invalidComponent}</p>}
      </React.Fragment>
    );

    return renderTextArea();
  },
);

TextArea.propTypes = {
  automationid: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  invalidComponent: PropTypes.node,
  isAutoFocus: PropTypes.bool,
  isInvalid: PropTypes.bool,
  isResizable: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  overrideStyles: PropTypes.object,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default TextArea;
