import React, { FC, SVGProps, memo } from 'react';
import merge from 'lodash/merge';

const getMergedProps = (propsFromParent: any, propsFromConfig: any) => {
  return merge({}, propsFromParent ?? {}, propsFromConfig ?? {});
};

export const GetBrandResource = (
  config: { resource: any; isSvg?: boolean; isVideo?: boolean },
  specificProps?: any,
) => {
  if (config.isSvg) {
    const ResourceComponent: FC<SVGProps<any>> = config.resource;
    return memo((props) => {
      const localProps = getMergedProps(props, specificProps);
      return <ResourceComponent {...localProps} />;
    });
  }
  if (config.isVideo) {
    return memo(() => <source src={config.resource} type="video/mp4" />);
  }

  return memo((props) => {
    const localProps = getMergedProps(props, specificProps);
    return <img alt={localProps.alt ?? ''} src={config.resource ?? ''} {...localProps} />;
  });
};
