/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as InfoIcon } from 'shared/img/icons/info.svg';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import Button from 'shared/components/andtComponents/Button';
import { isEmptyArray } from 'shared/utils/arrayUtils';
import { AWS_QUANTITY_TYPE_SELECT } from 'shared/constants/awsConstants';
import { CostTypeModes } from 'usage/constants/costAndUsageConstants';
import styles from '../ChartButtonsMenu.module.scss';

const APPLY_BTN_NAME = 'apply-button';

const RateBlock = ({ costUsageHandler }) => {
  const { costOptions = [], costValue, usageOptions = [], usageValue, onChange } = costUsageHandler;
  const [tempCost, setTempCost] = useState(costValue);
  const [tempUsage, setTempUsage] = useState(usageValue === AWS_QUANTITY_TYPE_SELECT ? null : usageValue || null);
  return (
    <div
      className={styles.rate}
      onClick={(e) => {
        if (e.target.name === APPLY_BTN_NAME || e.target.parentElement.name === APPLY_BTN_NAME) {
          return;
        }
        e.stopPropagation();
      }}
    >
      <span className={styles.rateHeader}>
        Rate
        <Tooltip
          title="The ratio between cost / usage and usage based on the selected cost / usage and usage type"
          arrow
        >
          <span>
            <InfoIcon />
          </span>
        </Tooltip>
      </span>
      <div className={styles.selects}>
        <ButtonDropdown
          text={
            tempCost
              ? ([...costOptions, ...usageOptions].find((c) => c.value === tempCost) || {}).name || 'Cost'
              : 'Select Cost'
          }
          automationid="ratecostoptions"
          arrow
          disabled={isEmptyArray(costOptions)}
        >
          <li data-group="true">Cost</li>
          {costOptions.map((o) => (
            <li data-selected={o.value === tempCost} onClick={() => setTempCost(o.value)} key={o.value} id={o.value}>
              <span>{o.name}</span>
            </li>
          ))}
          <li data-group="true">Usage</li>
          {usageOptions.map((o) => (
            <li data-selected={o.value === tempCost} onClick={() => setTempCost(o.value)} key={o.value} id={o.value}>
              <span>{o.name}</span>
            </li>
          ))}
        </ButtonDropdown>
        <ButtonDropdown
          text={tempUsage ? usageOptions.find((c) => c.value === tempUsage).name : 'Select Usage'}
          automationid="usagecostoptions"
          arrow
        >
          {usageOptions.map((o) => (
            <li data-selected={o.value === tempUsage} onClick={() => setTempUsage(o.value)} key={o.value}>
              <span>{o.name}</span>
            </li>
          ))}
        </ButtonDropdown>
      </div>
      <div className={styles.applyButton}>
        <Button
          isTextButton
          text="Apply"
          automationid="applyrate"
          name={APPLY_BTN_NAME}
          disabled={!tempUsage || !tempCost}
          onClick={() => onChange(tempCost, tempUsage, CostTypeModes.RATE)}
        />
      </div>
    </div>
  );
};

RateBlock.propTypes = {
  costUsageHandler: PropTypes.object.isRequired,
};

export default RateBlock;
