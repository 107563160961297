/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import LinkIconButton from 'shared/components/buttons/LinkIconButton';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import moment from 'moment';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { useRootStore } from 'app/contexts/RootStoreContext';
import DateFilter from 'shared/modules/dateFilter';
import { cubeletDimensionNameToCommonField } from '../anomalyDetectionConstants';
import styles from './exploreAnomaly.module.scss';

const propTypes = {
  row: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  enableCubeletFilters: PropTypes.bool,
};

const getNewAnomalyDetectionParams = ({ anomaly, enableCubeletFilters = false }) => {
  const { usersStore } = useRootStore();
  const { currDispUserCloudAccountType } = usersStore;
  const lastProcessTime = DateFilter.getDate();
  const filterParams = new URLSearchParams();
  let fieldToFilterdValuesMap = {};
  if (anomaly.serviceName) {
    fieldToFilterdValuesMap[AwsCommonFields.SERVICE] = [anomaly.serviceName];
  }
  if (anomaly.regionTagName) {
    fieldToFilterdValuesMap[AwsCommonFields.REGION] = [anomaly.regionTagName];
  }
  if (anomaly.linkedAccountId) {
    fieldToFilterdValuesMap[AwsCommonFields.LINKED_ACCOUNT_ID] = [anomaly.linkedAccountId];
  }
  if (anomaly.usageQuantityType) {
    fieldToFilterdValuesMap[AwsCommonFields.QUANTITY_TYPE] = [anomaly.usageQuantityType];
  }
  if (anomaly.purchaseOption) {
    fieldToFilterdValuesMap[AwsCommonFields.PURCHASE_OPTION] = [anomaly.purchaseOption];
  }

  if (enableCubeletFilters) {
    const cubeletFilters = anomaly.cubeletSplits?.reduce((acc, split) => {
      const filterName = cubeletDimensionNameToCommonField[split.dimensionName] || AwsCommonFields.CUSTOM_TAGS;
      if (filterName === 'NA') {
        return acc;
      }

      const filterValue =
        filterName === AwsCommonFields.CUSTOM_TAGS
          ? `${split.dimensionName}: ${split.dimensionValue}`
          : split.dimensionValue;
      // Spread current filter value if exists to allow multiple values for the same filter
      return { ...acc, [filterName]: acc[filterName] ? [...acc[filterName], filterValue] : [filterValue] };
    }, {});

    fieldToFilterdValuesMap = { ...fieldToFilterdValuesMap, ...cubeletFilters };
  }

  if (fieldToFilterdValuesMap[AwsCommonFields.CUSTOM_TAGS]?.length > 1) {
    filterParams.set('filtersConfig', JSON.stringify({ [AwsCommonFields.CUSTOM_TAGS]: { conjunction: 'or' } }));
  }

  let isShowAmortizeCost = false;
  const startDate = moment(anomaly.startTime * 1000)
    .subtract(1, 'months')
    .format('YYYY-MM-DD');
  const endDate = moment(anomaly.endTime ? anomaly.endTime * 1000 : lastProcessTime).format('YYYY-MM-DD');
  if ([CLOUD_TYPE_IDS.AWS].includes(currDispUserCloudAccountType)) {
    fieldToFilterdValuesMap[AwsCommonFields.CHARGE_TYPE] = ['Tax'];
    if (!usersStore.isCurrentUserSharedReCustomer) {
      isShowAmortizeCost = true;
    }
  }
  filterParams.set('fieldToFilterdValuesMap', JSON.stringify({ ...fieldToFilterdValuesMap }));
  filterParams.set('startDate', startDate);
  filterParams.set('endDate', endDate);
  filterParams.set('isLineChart', true);
  filterParams.set('isShowAmortizeCost', isShowAmortizeCost);
  filterParams.set('accountKey', usersStore.currDispUserAccountKey);
  return `?${filterParams.toString()}`;
};

const ExploreAnomaly = ({ row, path, enableCubeletFilters = false }) => (
  <LinkIconButton automationid="exploreAnomaly">
    <Link
      key={`${row.uuid}-${row.alertUuid}`}
      to={{
        pathname: path,
        search: getNewAnomalyDetectionParams({ anomaly: row, enableCubeletFilters }),
      }}
      onClick={(e) => e.stopPropagation()}
    />
    <Tooltip
      placement="top"
      title={
        enableCubeletFilters
          ? 'Drill down by Anomaly main impact factors'
          : 'Investigate using cost & usage explorer, presenting 1 month back from the anomaly start time'
      }
      arrow
    >
      <div>
        <GenerateIcon
          iconName={enableCubeletFilters ? ICONS.regularCubeMagnifyingGlass.name : ICONS.magnifyingGlassChart.name}
          className={`${enableCubeletFilters && styles.cubeletExplore} ${styles.icon}`}
        />
      </div>
    </Tooltip>
  </LinkIconButton>
);

ExploreAnomaly.propTypes = propTypes;
export default ExploreAnomaly;
