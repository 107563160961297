import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import styles from './valueTag.module.scss';

const ValueTag = ({ value, description, isSmallTag }) => {
  return (
    <div className={classNames(styles.valueTagWrapper, isSmallTag && styles.smallTag, !value && styles.grayTag)}>
      <span className={styles.value}>{value}</span>
      <span className={styles.description}>{description}</span>
      <GenerateIcon className={styles.icon} iconName={ICONS.circleExclamationRegular.name} />
    </div>
  );
};

ValueTag.propTypes = {
  value: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  isSmallTag: PropTypes.bool,
};
export default ValueTag;
