import LabelCoordinator from 'shared/modules/labelCoordinator';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { AzureCommonFields, azureCommonFieldToDisplayField } from '../constants/azureConstants';
import { getAccountTagFilterLabel, getBusinessMappingFilterLabel } from './awsUtils';
import { AwsCommonFields } from '../constants/awsConstants';
import { getCustomTagFilterLabel } from './sharedUtils';

export const getDisplayAzureLabel = (rawAzureName) => LabelCoordinator.getServiceNameDisplayValue(rawAzureName);

const familyTypesWithTypes = [];

export const getFieldDisplayLabel = (field, secondLevelItemsValuesMap, isKeyCloakManagement) => {
  let label = '';
  if (field) {
    if (field.includes('customtags')) {
      label = getCustomTagFilterLabel(field, CLOUD_TYPE_IDS.AZURE);
    } else if (azureCommonFieldToDisplayField.has(field.toLowerCase())) {
      if (isKeyCloakManagement && field === AzureCommonFields.COST_CENTER) {
        label = 'Cost center';
      } else {
        label = azureCommonFieldToDisplayField.get(field);
      }
    } else {
      label = field;
    }
    if (field.includes('accounttags')) {
      label = getAccountTagFilterLabel(field);
    }
    if (field.includes(AwsCommonFields.BUSINESS_MAPPING)) {
      let fieldValue = field;
      if (secondLevelItemsValuesMap) {
        fieldValue = ([...secondLevelItemsValuesMap.entries()].find(([, v]) => v === field) || [])[0] || field;
      }
      label = getBusinessMappingFilterLabel(fieldValue);
    }
  }

  return label;
};

// const servicesWithFamilyTypes = ['Bandwidth', 'Storage', 'Virtual Machines', 'Virtual Network'];
const servicesWithFamilyTypes = ['Storage'];

// this function is needed
export const isServiceHasTypes = () => false;

export const isServiceHasFamilyType = (serviceName) => servicesWithFamilyTypes.includes(serviceName);

export const isFamilyTypeHasTypes = (familyType) => familyTypesWithTypes.includes(familyType);
