import React from 'react';
import { dashLegend } from 'shared/components/chartComponents/Legends/LegendCommon';
import classes from './customizedLegend.module.scss';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
export const CustomizedLegend = ({
  payload,
  turnedOffLines,
  onClickLegendPair,
  hideKeys,
  className,
  effectiveTime,
  dashLegends,
}) => (
  <div className={classes?.customizedLegendWrapper}>
    <div className={`${className?.customizedLegend} ${classes.customizedLegend}`}>
      {payload?.map((entry) => {
        const entryId = `${entry.payload.id}Legend`;
        return !hideKeys || !hideKeys.includes(entry.dataKey) ? (
          <div className={classes.legendPair} onClick={onClickLegendPair} id={entryId} key={entryId}>
            {!dashLegends?.includes(entry.dataKey) ? (
              <div
                className={classes.legendSymbol}
                style={
                  turnedOffLines?.includes(entryId)
                    ? { border: `1px solid ${entry.color}` }
                    : { backgroundColor: entry.color }
                }
              />
            ) : (
              dashLegend(entry.color, entry.dataKey)
            )}
            <div className={classes.legendLabel}>{entry.value}</div>
          </div>
        ) : null;
      })}
    </div>
    {effectiveTime && (
      <div className={classes.effectiveLegend}>
        <span>Effective Time Frame</span>
        <InfoPopover mode="outline">
          Number of consecutive days the workload was consistently defined with the same request values (within the
          range of days to check).
        </InfoPopover>
        <div className={classes.rectangle} />
      </div>
    )}
  </div>
);
