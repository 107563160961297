import { AnodotExternalLinks } from 'app/contexts/Brand/configurations/external-links.configuration';
import { ExternalLinks } from 'shared/enums/external-links.enum';

const defaultBrandLinks = AnodotExternalLinks;

const apiReqHeaders = {
  createHeaders: () => {},
  setCreateHeadersFunc: (func) => {
    apiReqHeaders.createHeaders = func;
  },
};
const authEnv = import.meta.env.VITE_LOCAL_AUTH_ENV || 'dev';

const local = {
  env: 'local',
  apiGateway: {
    REGION: 'us-east-1',
    URL: defaultBrandLinks.local.api,
    LEGACY_URL: defaultBrandLinks.local.legacyAPI,
  },
  KEYCLOAK_URL: defaultBrandLinks[authEnv].keycloak,
  COGNITO_POOL_URL: defaultBrandLinks[authEnv].cognitoPool,
  COGNITO_REDIRECT_URI: defaultBrandLinks.local.redirectURL,
};

const dev = {
  env: 'dev',
  apiGateway: {
    REGION: 'us-east-1',
    URL: defaultBrandLinks.dev.api,
    LEGACY_URL: defaultBrandLinks.dev.legacyAPI,
  },
  KEYCLOAK_URL: defaultBrandLinks.dev.keycloak,
  COGNITO_POOL_URL: defaultBrandLinks.dev.cognitoPool,
  COGNITO_REDIRECT_URI: defaultBrandLinks.dev.redirectURL,
};

const feature = {
  env: 'feature',
  apiGateway: {
    REGION: 'us-east-1',
    URL: defaultBrandLinks.feature.api,
    LEGACY_URL: defaultBrandLinks.feature.legacyAPI,
  },
  KEYCLOAK_URL: ExternalLinks.FeatureKeycloak,
  COGNITO_POOL_URL: defaultBrandLinks.feature.cognitoPool,
  COGNITO_REDIRECT_URI: defaultBrandLinks.feature.api,
};

const staging = {
  env: 'staging',
  apiGateway: {
    REGION: 'us-east-1',
    URL: defaultBrandLinks.staging.api,
    LEGACY_URL: defaultBrandLinks.staging.legacyAPI,
  },
  COGNITO_POOL_URL: defaultBrandLinks.staging.cognitoPool,
  COGNITO_REDIRECT_URI: defaultBrandLinks.staging.redirectURL,
  KEYCLOAK_URL: defaultBrandLinks.staging.keycloak,
};

const prod = {
  env: 'prod',
  apiGateway: {
    REGION: 'us-east-1',
    URL: defaultBrandLinks.prod.api,
    LEGACY_URL: defaultBrandLinks.prod.legacyAPI,
  },
  COGNITO_POOL_URL: defaultBrandLinks.prod.cognitoPool,
  COGNITO_REDIRECT_URI: defaultBrandLinks.prod.redirectURL,
  KEYCLOAK_URL: defaultBrandLinks.prod.keycloak,
};

let configValue;
switch (import.meta.env.VITE_APP_STAGE) {
  case 'local': {
    configValue = local;
    break;
  }
  case 'dev': {
    configValue = dev;
    break;
  }
  case 'feature': {
    configValue = feature;
    break;
  }
  case 'staging': {
    configValue = staging;
    break;
  }
  case 'prod': {
    configValue = prod;
    break;
  }
  default:
    configValue = dev;
}

const config = {
  apiReqHeaders,
  mock: local,

  get current() {
    return configValue;
  },

  get env() {
    return configValue.env;
  },

  get apiGateway() {
    return configValue.apiGateway;
  },

  get KEYCLOAK_URL() {
    return configValue.KEYCLOAK_URL;
  },

  get COGNITO_POOL_URL() {
    return configValue.COGNITO_POOL_URL;
  },

  get COGNITO_REDIRECT_URI() {
    return configValue.COGNITO_REDIRECT_URI;
  },
};

export const updateAppConfigurationForChangedBrand = (brandContext) => {
  if (brandContext) {
    configValue = {
      ...configValue,
      apiGateway: {
        ...configValue.apiGateway,
        URL: brandContext.externalLinks[configValue.env].api,
      },
      COGNITO_REDIRECT_URI: brandContext.externalLinks[configValue.env].redirectURL,
    };
  }
};

export default config;
