import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import useRoles from 'users/new-user-management/hooks/reactQuery/useRoles';
import { PAGINATION_DIRECTION } from '../../../consts';
import AddRoles from '../../../components/AddRoles/AddRoles';
import Spinner from 'shared/components/andtComponents/Spinner';

import styles from './CreateEditRoleModal.module.scss';

const SubRolesStep = ({ isViewOnly, isDuplicate, roleId, role, setRole, setRoleOnCreateEdit, isStepSaved }) => {
  const [search, setSearch] = useState();
  const [paginationToken, setPaginationToken] = useState(null);
  const firstTime = useRef(true);

  const { fetchRoles, fetchRoleById } = useRoles();
  const { data, isLoading } = fetchRoles({ paginationToken, search });
  const { data: roleData, isLoading: isRoleDataLoading } = fetchRoleById(
    isDuplicate && !isStepSaved ? roleId : role?.id,
  );

  const initialSubRoles = useMemo(() => role?.subRoles || [], [role]);

  useEffect(() => {
    if (roleData) {
      if (firstTime.current) {
        if (!isDuplicate) {
          setRoleOnCreateEdit((prev) => ({ ...prev, subRoles: roleData.subRoles || [] }));
        }
        firstTime.current = false;
      }
      setRole((prev) => ({
        ...prev,
        subRoles: roleData.subRoles,
        subRolesPayload: isDuplicate ? { subRoleIdsToAdd: roleData.subRoles.map((role) => role.id) } : undefined,
      }));
    }
  }, [isDuplicate, roleData, setRole, setRoleOnCreateEdit]);

  const subRolesToDisplay = useMemo(() => {
    if (data?.roles) {
      return data?.roles?.filter((r) => r.id !== role.id);
    }
  }, [data, role]);

  const handleRolesAddedRemoved = ({ addedRoles, removedRoles }) => {
    setRole((prev) => {
      const currentToAdd = prev.subRolesPayload?.subRoleIdsToAdd || [];
      const currentToRemove = prev.subRolesPayload?.subRoleIdsToRemove || [];

      const addedNew = addedRoles.filter((role) => {
        const isInOriginal = initialSubRoles?.some((existingRole) => existingRole.id === role.id);
        const isInToAdd = currentToAdd.includes(role.id);

        if (isInOriginal) {
          return currentToRemove.includes(role.id);
        }
        return !isInToAdd;
      });

      const removedNew = removedRoles.filter((role) => {
        const isInOriginal = initialSubRoles?.some((existingRole) => existingRole.id === role.id);
        const isInToRemove = currentToRemove.includes(role.id);

        if (isInOriginal) {
          return !isInToRemove;
        }
        return currentToAdd.includes(role.id);
      });

      const updatedToAdd = [...currentToAdd]
        .filter((id) => !removedNew.some((role) => role.id === id))
        .concat(addedNew.map((role) => role.id));

      const updatedToRemove = [...currentToRemove]
        .filter((id) => !addedNew.some((role) => role.id === id))
        .concat(
          removedNew
            .filter((role) => initialSubRoles?.some((existingRole) => existingRole.id === role.id))
            .map((role) => role.id),
        );

      const newSelectedRoles = prev.subRoles?.filter((role) => !removedRoles.find((r) => r.id === role.id));
      const combined = [...(newSelectedRoles || []), ...addedRoles];

      return {
        ...prev,
        subRoles: combined,
        subRolesPayload: {
          subRoleIdsToAdd: updatedToAdd,
          subRoleIdsToRemove: updatedToRemove,
        },
      };
    });
  };

  const pageChanged = (direction) => {
    if (direction === PAGINATION_DIRECTION.NEXT && data?.paginationToken) {
      setPaginationToken(data?.paginationToken);
    }
  };

  return isLoading || isRoleDataLoading ? (
    <Spinner />
  ) : (
    <div className={styles.subRolesContainer}>
      <AddRoles
        isDisabled={isViewOnly}
        isLoading={isLoading || isRoleDataLoading}
        roles={subRolesToDisplay}
        search={search}
        setSearch={setSearch}
        pageChanged={pageChanged}
        selectedRoles={role?.subRoles || []}
        onRolesAddedRemoved={handleRolesAddedRemoved}
      />
    </div>
  );
};

SubRolesStep.propTypes = {
  isViewOnly: PropTypes.bool,
  isDuplicate: PropTypes.bool,
  isStepSaved: PropTypes.bool,
  role: PropTypes.object,
  roleId: PropTypes.string, // needed in addition roleId in case this is duplicate of role
  setRole: PropTypes.func.isRequired,
  setRoleOnCreateEdit: PropTypes.func.isRequired,
};

export default SubRolesStep;
