import { useEffect, useState } from 'react';
import useResellerTokens from 'users/new-user-management/hooks/reactQuery/useResellerTokens';
import {
  removeResellerCustomerTokenFromLocalStorage,
  saveResellerCustomerTokenInLocalStorage,
} from 'shared/utils/tokenUtil';

export function useOrgToCustomers(useDataHook, initializeState, isDisabledInitial = false) {
  const [isCustomersView, setIsCustomersView] = useState(false);
  const [selectedCustomerDetails, setSelectedCustomerDetails] = useState({
    customer: null,
    hasToken: false,
  });
  const isCustomerWithoutToken = selectedCustomerDetails.customer && !selectedCustomerDetails.hasToken;
  const { useCustomerOrganizationToken } = useResellerTokens(!isCustomersView || !isCustomerWithoutToken);
  const { data: customerTokens } = useCustomerOrganizationToken(selectedCustomerDetails.customer?.id);

  const isMyOrganization = !isCustomersView;
  const canFetchCustomers = isCustomersView && selectedCustomerDetails.hasToken;
  const canFetchData = isMyOrganization || canFetchCustomers;
  const hookData = useDataHook(!canFetchData || isDisabledInitial);

  useEffect(() => {
    const removeOrgBeforeUnload = () => {
      removeResellerCustomerTokenFromLocalStorage();
    };
    window.addEventListener('beforeunload', removeOrgBeforeUnload);
    // unmount
    return () => {
      removeResellerCustomerTokenFromLocalStorage();
      window.removeEventListener('beforeunload', removeOrgBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (customerTokens) {
      saveResellerCustomerTokenInLocalStorage(customerTokens.accessToken, customerTokens.refreshToken);
      setSelectedCustomerDetails((prev) => ({ ...prev, hasToken: true }));
    }
  }, [customerTokens]);

  const handleCustomerSelectionChanged = (customer) => {
    removeResellerCustomerTokenFromLocalStorage();
    setSelectedCustomerDetails({ customer, hasToken: false });
    initializeState();
  };

  const handleOrgCustomersToggle = () => {
    setIsCustomersView((prev) => !prev);
    removeResellerCustomerTokenFromLocalStorage();
    setSelectedCustomerDetails({ customer: null, hasToken: false });
    initializeState();
  };

  return {
    customerTokens,
    isCustomersView,

    handleCustomerSelectionChanged,
    handleOrgCustomersToggle,
    hookData,

    selectedCustomerDetails,
    setSelectedCustomerDetails,
    setIsCustomersView,
  };
}
