import React, { useContext, useEffect } from 'react';
import { PeriodOptions, ReportPeriodTime } from 'usage/constants/costAndUsageConstants';
import Select from 'shared/components/Select';
import { ReportContext } from 'shared/components/reportModal/ReportModal';
import styles from './ReportModal.module.scss';

const AssetsReportContent = () => {
  const { reportData, setReportData, data } = useContext(ReportContext);

  useEffect(() => {
    setReportData((prev) => ({
      ...prev,
      period: data.relativeDates || data.periodType || ReportPeriodTime.THIRTY_DAYS,
    }));
  }, [data?.relativeDates, data?.periodType]);

  return (
    <div className={`${styles.field} ${styles.halfField}`}>
      <div className={styles.fieldTitle}>Report period</div>
      <Select
        className={styles.select}
        value={`${reportData.period}`}
        options={PeriodOptions}
        onChange={(event) => {
          setReportData((prev) => ({ ...prev, period: event.target.value }));
        }}
        automationid="report-period"
      />
    </div>
  );
};

export default AssetsReportContent;
