import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as NoCostCenters } from 'users/containers/Organization/CostCenters/img/no-cost-centers.svg';
import EmptyState from './EmptyState';

import styles from './CostCentersNoData.module.scss';

const SUB_TEXTS = [
  `Use Cost Centers to allocate your cost according to the business unit hierarchy that fits your organization.`,
  `A Cost Center may contain an entire Payer account, or specific linked accounts.`,
  `Furthermore, a Cost Center may contain accounts from different cloud providers.`,
];
const NO_COST_CENTER_SUB_TEXTS = ['Refine your search to find the required Cost Center'];
const NOT_DEFINED = 'Cost Centers were not defined yet';
const NO_COST_CENTER = 'Could not find the Cost Center';

const CostCentersNoData = ({ colSpan, noCostCentersInOrg, mainText, subTexts }) => {
  const getMainText = () => {
    if (mainText) {
      return mainText;
    }
    if (noCostCentersInOrg) {
      return NOT_DEFINED;
    }
    return NO_COST_CENTER;
  };

  const getSubTexts = () => {
    if (subTexts) {
      return subTexts;
    }
    if (noCostCentersInOrg) {
      return NO_COST_CENTER_SUB_TEXTS;
    }
    return SUB_TEXTS;
  };

  return (
    <td colSpan={colSpan} className="border-bottom-0 border-start-0 border-end-0 border-top-0">
      <EmptyState
        mainText={getMainText()}
        subTexts={getSubTexts()}
        backgroundIcon={<NoCostCenters className={styles.svgImage} />}
      ></EmptyState>
    </td>
  );
};

CostCentersNoData.propTypes = {
  colSpan: PropTypes.number,
  mainText: PropTypes.string,
  noCostCentersInOrg: PropTypes.bool,
  subTexts: PropTypes.array,
};

export default CostCentersNoData;
