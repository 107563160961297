import React from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Input from 'shared/components/andtComponents/Input';
import Button from 'shared/components/andtComponents/Button';
import Pagination from 'shared/components/andtComponents/Pagination';
import DatePickerFilter from 'shared/components/DatePickerFilter';
import { CSVLink } from 'react-csv';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import classes from '../BillingSummary.module.scss';
import { BillingHistoryRow } from '../../types.ts';
import { prepareCsvModifiedRows } from '../../utils.ts';

const unselectedTooltip =
  'Please select one of the historical billing summary rows to download the invoice.\n' +
  'Only historical months can be exported, the current month isn’t closed, ' +
  'therefore it can’t be exported as an invoice.';

const selectedTooltip = "Historical months can be exported, current month can't be exported as an invoice. ";
const currentMonth = new Date().getMonth();
const InputWithNoTypes = Input as React.FC<any>;
const DownloadIconComponent = () => <GenerateIcon iconName={ICONS.download.name} />;
const CollapseIconComponent = () => <GenerateIcon iconName={ICONS.collapse.name} />;
const ExpandIconComponent = () => <GenerateIcon iconName={ICONS.expand.name} />;

type Props = {
  searchText?: string | null;
  setSearchText(txt: string): void;
  isExpanded: boolean;
  setIsExpanded(d: boolean): void;
  onPageChange(newIndex: number): void;
  currentPage: number;
  totalPages: number;
  headerText?: string;
  onDateChange(s: Date, e: Date): void;
  startDate: Date;
  endDate: Date;
  tableData: BillingHistoryRow[];
  showCurrentMonth: boolean;
  showHistory: boolean;
  setShowCurrentMonth: any;
  setShowHistory: any;
  isInvoiceDownloadDisabled?: boolean;
  onInvoicesDownload(type: 'csv' | 'pdf'): void;
};
const HistoryTableFilters: React.FC<Props> = ({
  searchText,
  setSearchText,
  isExpanded,
  setIsExpanded,
  onPageChange,
  currentPage,
  totalPages,
  headerText = '',
  onDateChange,
  startDate,
  endDate,
  tableData,
  showCurrentMonth,
  showHistory,
  setShowCurrentMonth,
  setShowHistory,
  isInvoiceDownloadDisabled,
  onInvoicesDownload,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onMenuClick = (type: 'csv' | 'pdf') => {
    setAnchorEl(null);
    onInvoicesDownload(type);
  };
  const isOnlyPastSelected = currentMonth > Number(endDate.getMonth());
  const isOnlyPresentSelected = currentMonth === Number(startDate.getMonth());

  return (
    <div className={classes.tableFilters}>
      <div className={classes.leftMenu}>
        <Button
          automationid="expand-rows"
          text=""
          isSecondary
          icon={isExpanded ? ExpandIconComponent : CollapseIconComponent}
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        />
        <span className={classes.headerText}>{headerText}</span>
        {!isOnlyPastSelected && (
          <Checkbox
            automationid="billing-current-month-checkbox"
            className="me-3 ms-3"
            isChecked={showCurrentMonth}
            primary
            onChange={() => {
              setShowCurrentMonth((s) => !s);
            }}
            text="Current Month"
          />
        )}
        {!isOnlyPresentSelected && (
          <Checkbox
            automationid="billing-history-checkbox"
            isChecked={showHistory}
            primary
            onChange={() => {
              setShowHistory((s) => !s);
            }}
            text="History"
          />
        )}
      </div>
      <div className={classes.rightMenu}>
        <DatePickerFilter
          isDateRangeError={false}
          onDateChange={({ startDate, endDate }) => {
            onDateChange?.(startDate, endDate);
          }}
          startDate={startDate}
          endDate={endDate}
          isMaxDateNeeded={false}
          currPeriodGranLevel="month"
          dateFormat="MMM YYYY"
          andtLook
          className={classes.datePicker}
        />
        <div className={classes.searchWrapper}>
          <InputWithNoTypes
            className={classes.input}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search"
            name="search-field"
            isSearch
            searchComponent={<GenerateIcon iconName={ICONS.search.name} />}
            type="text"
          />
        </div>
        <div>
          <Tooltip arrow placement="top" title={isInvoiceDownloadDisabled ? unselectedTooltip : selectedTooltip}>
            {' '}
            <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              isSecondary
              disabled={isInvoiceDownloadDisabled}
              icon={DownloadIconComponent}
              text="Invoice export"
            />
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={() => onMenuClick('csv')}>
              <div style={{ width: 100 }}>
                <GenerateIcon iconName={ICONS.download.name} />
                <span className="ms-2">CSV</span>
              </div>
            </MenuItem>
            <MenuItem onClick={() => onMenuClick('pdf')}>
              <GenerateIcon iconName={ICONS.download.name} />
              <span className="ms-2">PDF</span>
            </MenuItem>
          </Menu>
        </div>
        <CSVLink data={prepareCsvModifiedRows(tableData)} headers={null} filename="billing-summary.csv">
          <div className={classes.downloadLinkButton} title="Export CSV">
            <GenerateIcon iconName={ICONS.download.name} />
            <span className="ms-2">Table Export</span>
          </div>
        </CSVLink>
        <Pagination currPageIdx={currentPage} onPageChange={onPageChange} totalPages={totalPages} />
      </div>
    </div>
  );
};

export default HistoryTableFilters;
