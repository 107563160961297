import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip, ReferenceLine } from 'recharts';
import { palette } from 'shared/constants/colorsConstants';
import { EXPORT_COLUMNS } from 'commitment/containers/spAnalyzerNew/utils/consts';
import { downloadChartAsPng } from 'shared/utils/downloadAsPng';
import styles from './recommendedChart.module.scss';
import CustomChartTooltip from './CustomChartTooltip';
import ChartHeader from './ChartHeader';
import RecommendedCustomizedLegend from 'commitment/containers/spAnalyzerNew/components/recommendedCharts/RecommendedCustomLegend.jsx';
import CustomizedAxisTick from './CustomizedAxisTick.jsx';

const isLegendDisplay = (legend, includeEstimatedOption, isEstimatedMode) => {
  if (includeEstimatedOption) {
    return !legend.isSimulate && (!legend.isEstimated || isEstimatedMode);
  }
  return true;
};

const getChartKey = (legend, isEstimatedMode, isHourlyMode) => {
  if (isEstimatedMode) {
    if (isHourlyMode && legend.hourlyKey) {
      return legend.hourlyKey;
    }
    if (legend.estimateKey) return legend.estimateKey;
  }
  return legend.key;
};

// when includeEstimatedOption = true - The chart can include 4 mode:
// actual, actual+estimate, simulate, simulate+estimate
// set the display legend and relevant tooltip according to the mode
const RecommendedChart = ({
  data,
  legend,
  header,
  info,
  chartType,
  includeEstimatedOption = false,
  tooltipMapData,
  isAnimationActive,
  automationid,
  isCurrentTab,
  expirationEvents = {},
  purchaseEvents = {},
  isDashboardPanel = false,
  dailyMode = null,
  estimateMode = null,
}) => {
  const [isDailyMode, setIsDailyMode] = useState(dailyMode !== null ? dailyMode : true);
  const [isEstimatedMode, setIsEstimatedMode] = useState(estimateMode || false);
  const [showEvents, setShowEvents] = useState(true);
  const chartRef = useRef();
  const chartWrapperRef = useRef();
  const [chartData, setChartData] = useState(isDailyMode ? data.daily : data.hourly);
  const [turnedOffLines, setTurnedOffLines] = useState([]);
  const eventKeys = Array.from(
    new Set([
      ...Object.keys((isDailyMode ? expirationEvents.daily : expirationEvents.hourly) || {}),
      ...Object.keys((isDailyMode ? purchaseEvents.daily : purchaseEvents.hourly) || {}),
    ]),
  );

  // Calculate the maximum value in your data
  const maxValue = Math.max(
    ...chartData.map((item) =>
      Math.max(...legend.map((leg) => (leg.estimateKey ? item[leg.estimateKey] : item[leg.key]))),
    ),
  );

  // Calculate the chart width for long data
  const chartWidth =
    (chartWrapperRef.current?.offsetWidth && 50 * chartData.length < chartWrapperRef.current?.offsetWidth) ||
    chartData.length < 24
      ? '100%'
      : 50 * chartData.length;

  const CustomizedAxisTickComponent = useCallback(
    (props) => (
      <CustomizedAxisTick
        {...props}
        isDailyMode={isDailyMode}
        isDateFormat={isCurrentTab}
        tickLength={chartData.length - 1}
        isDashboardPanel={isDashboardPanel}
      />
    ),
    [isDailyMode, isCurrentTab, chartData.length, isDashboardPanel],
  );

  const fetchChartToCsv = () => {
    const exportColumns = EXPORT_COLUMNS.filter(
      (col) =>
        col.key === 'date' ||
        legend.find((l) => l.exportKey === col.key || l.key === col.key) ||
        (col.display && chartData && col.key in chartData[0]),
    );
    let headers = [...exportColumns];
    if (includeEstimatedOption) {
      headers = headers.filter(
        (col) => col.key === 'date' || col.isSimulateColumn === false || (isEstimatedMode && col.isEstimateColumn),
      );
    }
    if (isCurrentTab) {
      headers = headers.map((col) => ({
        key: col.key,
        label: col.currentLabel || col.label,
      }));
    }
    return [
      {
        data: chartData || [],
        filename: `${header}.csv`,
        headers,
      },
    ];
  };

  useEffect(() => {
    setChartData(isDailyMode ? data.daily : data.hourly);
  }, [isDailyMode, data]);

  const currentTooltipMapData = useMemo(() => {
    if (isEstimatedMode) {
      return tooltipMapData?.estimatedMode;
    }
    return tooltipMapData.regularMode;
  }, [isEstimatedMode, tooltipMapData]);

  const onClickLegendPair = (entry) => {
    if (turnedOffLines.includes(entry.currentTarget.id)) {
      setTurnedOffLines(turnedOffLines.filter((lineId) => lineId !== entry.currentTarget.id));
    } else {
      setTurnedOffLines([...turnedOffLines, entry.currentTarget.id]);
    }
  };

  useEffect(() => {
    if (!includeEstimatedOption && isEstimatedMode) {
      setIsEstimatedMode(false);
    }
  }, [includeEstimatedOption, isEstimatedMode]);

  return (
    <div ref={chartWrapperRef} className={`${styles.chartWrapper} ${chartWidth === '100%' ? styles.minChart : ''}`}>
      <ChartHeader
        header={header}
        includeEstimatedOption={includeEstimatedOption}
        info={info}
        exportToCsv={fetchChartToCsv}
        exportToPng={() => downloadChartAsPng(chartRef.current)}
        isDailyMode={isDailyMode}
        setIsDailyMode={setIsDailyMode}
        isEstimatedMode={isEstimatedMode}
        setIsEstimatedMode={setIsEstimatedMode}
        automationid={automationid}
        includeEvents={isCurrentTab}
        showEvents={showEvents}
        setShowEvents={setShowEvents}
        chartType={chartType}
        hideDownload={isDashboardPanel}
      />
      <div className={styles.chart}>
        <ResponsiveContainer width={chartWidth} height={315} ref={chartRef}>
          <AreaChart width={500} height={400} data={chartData} margin={{ top: 20, right: 0, bottom: 8, left: 0 }}>
            <defs>
              <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="0%" stopColor="rgb(200, 60, 238)" stopOpacity={0.6} />
                <stop offset="100%" stopColor="rgb(200, 60, 238)" stopOpacity={0.0} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke={palette.gray[100]} strokeDasharray="3 0" vertical={false} />
            <XAxis dataKey="date" axisLine={false} tickLine={false} interval={0} tick={CustomizedAxisTickComponent} />
            <YAxis
              axisLine={false}
              tickLine={false}
              tickFormatter={(value) => `${value ? '$ ' : ''}${Number(value).toFixed(0)}`}
              tick={{ fill: palette.gray[400] }}
              domain={[0, maxValue * 1.1]}
            />
            <Tooltip
              content={
                <CustomChartTooltip
                  active={true}
                  tooltipMapData={{ ...currentTooltipMapData, type: tooltipMapData.tpType }}
                  isDateFormat={isCurrentTab}
                  purchaseEvents={showEvents ? purchaseEvents[isDailyMode ? 'daily' : 'hourly'] : null}
                  expirationEvents={showEvents ? expirationEvents[isDailyMode ? 'daily' : 'hourly'] : null}
                />
              }
            />
            {legend.map((leg) =>
              isLegendDisplay(leg, includeEstimatedOption, isEstimatedMode) ? (
                <Area
                  type="monotone"
                  key={leg.key}
                  className={`${turnedOffLines.includes(`${leg.key}Legend`) && styles.hiddenLine}`}
                  dataKey={getChartKey(leg, isEstimatedMode, !isDailyMode)}
                  stroke={leg.color}
                  stackId={!isEstimatedMode && tooltipMapData.tpType === 'actual' ? '1' : undefined}
                  fill={leg.isGradientFill ? 'url(#colorGradient)' : leg.color}
                  fillOpacity={isEstimatedMode && leg.estimateOpacity ? leg.estimateOpacity : leg.opacity}
                  strokeWidth={leg.strokeWidth || 2}
                  id={leg.key}
                  name={leg.name}
                  isAnimationActive={isAnimationActive}
                  strokeDasharray={leg.isDash ? '4 3' : 'none'}
                />
              ) : null,
            )}
            {showEvents &&
              eventKeys.map((key) => <ReferenceLine key={key} x={key} stroke="#1F2C9C" ifOverflow={'extendDomain'} />)}
          </AreaChart>
        </ResponsiveContainer>
      </div>
      <div>
        <RecommendedCustomizedLegend
          legend={legend.filter((l) => {
            return isLegendDisplay(l, includeEstimatedOption, isEstimatedMode);
          })}
          turnedOffLines={turnedOffLines}
          className={{ customizedLegend: styles.customizedLegend }}
          onClickLegendPair={onClickLegendPair}
          dashLegends={legend.filter((l) => l.isDash).map((l) => (isEstimatedMode ? l.estimateKey || l.key : l.key))}
        />
      </div>
    </div>
  );
};

RecommendedChart.propTypes = {
  data: PropTypes.shape({
    daily: PropTypes.array.isRequired,
    hourly: PropTypes.array.isRequired,
  }).isRequired,
  legend: PropTypes.array.isRequired,
  header: PropTypes.string.isRequired,
  info: PropTypes.string,
  chartType: PropTypes.string.isRequired,
  includeEstimatedOption: PropTypes.bool,
  tooltipMapData: PropTypes.object.isRequired,
  isAnimationActive: PropTypes.bool,
  automationid: PropTypes.string,
  isCurrentTab: PropTypes.bool,
  isDashboardPanel: PropTypes.bool,
  dailyMode: PropTypes.bool,
  estimateMode: PropTypes.bool,
  expirationEvents: PropTypes.object,
  purchaseEvents: PropTypes.object,
};

export default RecommendedChart;
