import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import CustomModal from 'shared/components/andtComponents/Modal';

const AddOrUpdateWeightsModal = ({
  toggle,
  isOpen,
  className = '',
  modalState,
  inputListOfValues,
  inputSelectedOption,
  handleAddWeightsData,
  handleEditWeightsData,
  handleInstanceChange,
  handleNumericFilterChange,
  selectedInputsErrorMessage,
  instanceNotToShow = [],
}) => {
  const getModalTitles = (state) => {
    let modalTitle = '';
    let buttonText = '';
    if (state === 'add') {
      modalTitle = 'Add Weight';
      buttonText = 'Add';
    }
    if (state === 'edit') {
      modalTitle = 'Edit Weight';
      buttonText = 'Edit';
    }
    return { modalTitle, buttonText };
  };
  const createInstanceOptions = (fieldValues, instancesToRemove = []) => {
    const withDefault = ['Default', ...fieldValues];
    const cleansedArray = withDefault.filter((value) => !instancesToRemove.includes(value));
    return cleansedArray;
  };
  return (
    <CustomModal
      open={isOpen}
      onClose={toggle}
      className={className}
      closeOnSave={false}
      title={getModalTitles(modalState).modalTitle}
      onSave={modalState === 'add' ? handleAddWeightsData : handleEditWeightsData}
    >
      <div>
        <Col xs={12} style={{ margin: '10px 0px' }}>
          <div className="filter-bar-inner-col-wrapper full-width">
            <h5 className="filter-bar-inner-col-title">
              {`Total Weight Score: ${
                +inputSelectedOption.cpu + +inputSelectedOption.memory
              }`}
            </h5>
          </div>
        </Col>
        <Col xs={12} style={{ margin: '10px 0px' }}>
          <div className="filter-bar-inner-col-wrapper full-width">
            <h5 className="filter-bar-inner-col-title">Instance</h5>
            <OneChoiceFieldFilter
              type="instance"
              value={[inputSelectedOption.instance|| 'Select...']}
              options={createInstanceOptions(inputListOfValues, instanceNotToShow)}
              handleChange={handleInstanceChange}
            />
            {!!selectedInputsErrorMessage.instance && (
              <p style={{ color: 'red' }}>{selectedInputsErrorMessage.instance}</p>
            )}
          </div>
        </Col>
        <Col xs={12} style={{ display: 'flex', justifyContent: 'flex-start', flexFlow: 'wrap', margin: '10px 0px' }}>
          <div className="filter-bar-inner-col-wrapper  full-width">
            <h5 className="filter-bar-inner-col-title">CPU</h5>
            <input
              type="number"
              min={0}
              max={10}
              step={1}
              id="cpu"
              value={inputSelectedOption.cpu}
              onChange={handleNumericFilterChange}
              className="form-control"
              style={{ fontSize: '12px' }}
              autoComplete="off"
            />
            {!!selectedInputsErrorMessage.cpu && <p style={{ color: 'red' }}>{selectedInputsErrorMessage.cpu}</p>}
          </div>
        </Col>
        <Col xs={12} style={{ display: 'flex', justifyContent: 'flex-start', flexFlow: 'wrap', margin: '10px 0px' }}>
          <div className="filter-bar-inner-col-wrapper  full-width">
            <h5 className="filter-bar-inner-col-title">Memory</h5>
            <input
              type="number"
              min={0}
              max={10}
              step={1}
              id="memory"
              value={inputSelectedOption.memory}
              onChange={handleNumericFilterChange}
              className="form-control"
              style={{ fontSize: '12px' }}
              autoComplete="off"
            />
            {!!selectedInputsErrorMessage.memory && <p style={{ color: 'red' }}>{selectedInputsErrorMessage.memory}</p>}
          </div>
        </Col>
      </div>
    </CustomModal>
  );
};

AddOrUpdateWeightsModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  handleAddWeightsData: PropTypes.func.isRequired,
  handleEditWeightsData: PropTypes.func.isRequired,
  handleInstanceChange: PropTypes.func.isRequired,
  handleNumericFilterChange: PropTypes.func.isRequired,
  inputListOfValues: PropTypes.array.isRequired,
  inputSelectedOption: PropTypes.object.isRequired,
  selectedInputsErrorMessage: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  modalState: PropTypes.string.isRequired,
  className: PropTypes.bool,
  instanceNotToShow: PropTypes.array,
};

export default AddOrUpdateWeightsModal;
