import React from 'react';
import styles from './EmptyState.module.scss';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const EmptyState = ({ mainText, subTexts, action, backgroundIcon, classes }) => {
  return (
    <div className={classNames(styles.emptyStateContainer, classes)}>
      {backgroundIcon}
      <div className={styles.emptyStateText}>
        <span className={styles.mainText}>{mainText}</span>
        {subTexts ? (
          <div className={styles.subTexts}>
            {subTexts?.map((subText) => (
              <div key={subText}>{subText}</div>
            ))}
          </div>
        ) : null}
        {action ?? null}
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  mainText: PropTypes.string.isRequired,
  classes: PropTypes.string,
  subTexts: PropTypes.array,
  action: PropTypes.elementType,
  backgroundIcon: PropTypes.elementType,
};

export default EmptyState;
