import React from 'react';
import PropTypes from 'prop-types';
import RecommendationDetails from '../../recommendationGenericComponents/recommendationDetails';
import {
  BASE_PROPERTIES,
  COST_PROPERTIES,
  K8S_PROPERTIES,
  OPTIONS_PROPERTIES,
} from '../../recommendationPropertyUtils';
import RecommendationDetailsLayout from '../../recommendationDetailsLayout';
import RecommendationPreferences from '../../recommendationGenericComponents/recommendationPreferences';
import RecommendationInsideLook from '../../recommendationGenericComponents/recommendationInsideLook';
import OtherResourceIdRecommendations from '../../recommendationGenericComponents/otherResourceIdRecommendations';
import { Y_AXIS_UNIT } from '../../recommendationGenericComponents/recommendationChart/chartConsts';
import RecommendationChartData, {
  CHART_TYPES,
} from '../../recommendationGenericComponents/recommendationChart/recommendationChartData';
import RecommendationOptionsPanel from '../../recommendationGenericComponents/recommendationOptionsPanel';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const k8sWorkloadProperties = {
  POTENTIAL_SAVINGS: { ...COST_PROPERTIES.POTENTIAL_SAVINGS },
  CURRENT_ANNUAL_COST: {
    ...COST_PROPERTIES.CURRENT_ANNUAL_COST,
    info: `The annual cost is a prediction of the workload cost 
    based on the effective time frame (assuming the same behavior), extrapolated to a full-year extent.`,
  },
  RECOMMENDED_ANNUAL_COST: {
    ...COST_PROPERTIES.RECOMMENDED_ANNUAL_COST,
    info: `The calculation is a prediction of the cost allocated to this workload
     after applying the recommended request values, based on the effective time frame and assuming the same behavior.`,
  },
  HOURLY_COST: {
    ...COST_PROPERTIES.HOURLY_COST,
  },
};

const DESCRIPTION = `We have observed that the current utilization of this K8s Workload is relatively low.
As a cost-saving measure, we highly recommend downsizing the CPU/Memory/Both, which will help reduce your expenses.`;

const InsideLookComponent = () => {
  const brand = useBrand();
  const descriptionUrl = {
    fullSentence: 'Click here for comprehensive recommendation details',
    url: brand.externalLinks.K8sWorkloadRightsizingRecommendation,
  };
  return <RecommendationInsideLook recType="k8s" description={DESCRIPTION} descriptionUrl={descriptionUrl} />;
};

const K8sWorkloadRightsizing = ({ recommendation }) => {
  const properties = {
    ...BASE_PROPERTIES,
    ...k8sWorkloadProperties,
    ...K8S_PROPERTIES,
  };

  const otherResourceIdRecommendations = <OtherResourceIdRecommendations resourceId={recommendation?.resourceId} />;

  const detailsComponent = (
    <RecommendationDetails recommendationProperties={properties} recommendation={recommendation} viewAll />
  );

  const preferencesComponent = <RecommendationPreferences recommendationType={recommendation.typeId} />;

  const CURRENT_PROPERTIES = [
    properties.CPU_REQUEST,
    properties.MEMORY_REQUESTS,
    properties.CPU_LIMITS,
    properties.CPU_AVG_UTIL,
    properties.CPU_MAX_UTIL,
    properties.MEMORY_LIMITS,
    properties.MEM_AVG_UTIL,
    properties.MEM_MAX_UTIL,
    properties.CURRENT_ANNUAL_COST,
  ];

  const ALTERNATIVE_PROPERTIES = [
    properties.RECOMMENDED_CPU_REQUEST,
    properties.RECOMMENDED_MEMORY_REQUEST,
    properties.RECOMMENDED_ANNUAL_COST,
    {
      ...OPTIONS_PROPERTIES.POTENTIAL_SAVINGS,
      info: `The calculation is a based on the new annual cost which is a prediction of the cost allocated
       to this workload after applying the recommended request values, based on the effective time frame and assuming
        the same behavior. Then we subtract the current-new annual cost.`,
    },
  ];

  const chartsData = [
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'CPU Usage',
      yAxisLabel: '',
      yAxisUnit: Y_AXIS_UNIT.MILICPU,
      maxDataProperty: recommendation.recData?.cpu_max_data,
      avgDataProperty: recommendation.recData?.cpu_avg_data,
      percentileDataProperty: recommendation.recData?.cpu_p95_data,
      requestDataProperty: recommendation.recData?.cpu_request_data,
      effectiveTimeFrame: recommendation.recData?.effective_time_frame_start,
    },
    {
      chartType: CHART_TYPES.SERIES_DATA,
      chartTitle: 'Memory Usage',
      yAxisLabel: '',
      yAxisUnit: Y_AXIS_UNIT.BYTE,
      maxDataProperty: recommendation.recData?.mem_max_data,
      avgDataProperty: recommendation.recData?.mem_avg_data,
      requestDataProperty: recommendation.recData?.mem_request_data,
      percentileDataProperty: recommendation.recData?.mem_p95_data,
      effectiveTimeFrame: recommendation.recData?.effective_time_frame_start,
    },
    {
      chartType: CHART_TYPES.GENERIC,
      info: `Average pods uptime, where the value 1 represents a single pod running constantly.
             The Single pod graph represents the average uptime of the pods each day (1 is maximum),
             while the All pods accumulated graph represents the accumulated value,
             therefore can be viewed as the average number of pods running each day, from an uptime perspective.`,
      chartTitle: 'Average Uptime Ratio',
      yAxisLabel: '',
      yAxisUnit: Y_AXIS_UNIT.OTHER,
      effectiveTimeFrame: recommendation.recData?.effective_time_frame_start,
      linesList: [
        {
          id: 'podAccumulated',
          label: 'All pods accumulated',
          data: recommendation?.recData?.uptime_agg_data,
        },
        {
          id: 'singlePod',
          label: 'Single pod',
          data: recommendation?.recData?.uptime_avg_data,
        },
      ],
    },
  ];

  const recommendationChartsComponent = <RecommendationChartData chartsData={chartsData} />;

  const alternatives = recommendation?.recData?.alternatives;
  const optionsPanelComponent =
    Array.isArray(alternatives) && alternatives.length ? (
      <RecommendationOptionsPanel
        alternatives={alternatives}
        currentProperties={CURRENT_PROPERTIES}
        alternativeProperties={ALTERNATIVE_PROPERTIES}
        minHeight={252}
        recommendation={recommendation}
        isRecommendedOption
      />
    ) : null;

  return (
    <RecommendationDetailsLayout
      insideLook={<InsideLookComponent></InsideLookComponent>}
      otherResourceIdRecommendations={otherResourceIdRecommendations}
      details={detailsComponent}
      optionsPanel={optionsPanelComponent}
      preferences={preferencesComponent}
      charts={recommendationChartsComponent}
    />
  );
};

K8sWorkloadRightsizing.propTypes = {
  recommendation: PropTypes.object.isRequired,
};

export default K8sWorkloadRightsizing;
