import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { ReactComponent as CommentIcon } from 'shared/img/icons/comment.svg';
import Button from 'shared/components/andtComponents/Button';
import {
  GenerateIcon,
  ICONS,
  Popover,
  PopoverContent,
  PopoverTrigger,
  PopoverPortal,
} from '@pileus-cloud/anodot-frontend-common';
import { Input } from 'reactstrap';
import { useRootStore } from 'app/contexts/RootStoreContext';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import styles from './commentsColumn.module.scss';

const propTypes = {
  row: PropTypes.object.isRequired,
};

const CommentsColumn = ({
  row,
  idKey = 'commentUuid',
  mutationAddComment,
  mutationDeleteComment,
  mutationUpdateComment,
  isAddingComment,
  isDeletingComment,
  isUpdatingComment,
  deletingComments,
  setDeletingComments,
  className,
}) => {
  const [comments, setComments] = useState(row.comments || []);
  const [addCommentFormOpen, setAddCommentFormOpen] = useState(false);
  const [newComment, setNewComment] = useState();
  const [editedComment, setEditedComment] = useState(null);
  const { usersStore } = useRootStore();
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen((prev) => !prev);
  };
  const getCommentText = (comment) => {
    const urls = comment.match(/(http|ftp|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])/g);
    if (!urls) {
      return comment;
    }
    const commentWithLinks = urls.reduce(
      (str, url) => str.replace(url, `<a href="${url}" target="_blank">${url}</a>`),
      comment,
    );
    return commentWithLinks;
  };

  const handleClose = () => {
    setAddCommentFormOpen(false);
    setEditedComment(null);
    setNewComment(null);
    setIsOpen(false);
  };
  const addNewComment = async () => {
    const commentToAdd = {
      comment: newComment,
      createdBy: usersStore.getCurrUserName,
    };
    const response = await mutationAddComment(commentToAdd);
    if (!response) {
      return;
    }
    // add comment to comments list in order to save api fetch
    commentToAdd[idKey] = response[idKey];
    commentToAdd.createdAt = moment().unix();
    const newComments = [...comments];
    newComments.push(commentToAdd);
    setComments(newComments);
    // reset form
    setNewComment('');
    setAddCommentFormOpen(false);
  };

  const addCommentForm = (
    <div className={styles.addCommentForm}>
      <h4>Add comment</h4>
      <Input
        type="textarea"
        className={styles.addCommentText}
        value={newComment}
        onChange={(event) => {
          setNewComment(event.target.value);
        }}
        autoFocus
        automation-id="comment-text"
        onKeyDown={(e) => e.stopPropagation()}
      />
      <div className={styles.formFooter}>
        <Button
          onClick={() => {
            handleClose();
          }}
          automationid="cancel-comment"
          text="Cancel"
          isSecondary
        />
        <Button
          onClick={() => addNewComment()}
          text="Submit"
          disabled={!newComment}
          isLoading={isAddingComment}
          automationid="submit-comment"
        />
      </div>
    </div>
  );
  const footer = (
    <div className={styles.footerActions}>
      <Button
        onClick={() => setAddCommentFormOpen(true)}
        text="Add comment"
        isTextButton
        automationid="add-comment"
        icon={() => <CommentIcon className={styles.active} />}
      />
      <Button
        onClick={() => handleClose()}
        text="Close"
        isTextButton
        automationid="close-comment"
        icon={() => <GenerateIcon iconName={ICONS.xMark.name} />}
        iconPlacement="right"
      />
    </div>
  );

  const handleDeleteComment = async (comment) => {
    // please note that the code is not protected from 2 actions running in parallel
    // therefore, the protection is to disable the delete button while deleting
    setDeletingComments([...deletingComments, comment[idKey]]);
    const response = await mutationDeleteComment({ [idKey]: comment[idKey] });
    if (response) {
      const newComments = comments.filter((c) => c[idKey] !== comment[idKey]);
      setComments(newComments);
    }
    setDeletingComments(deletingComments.filter((c) => c !== comment[idKey]));
  };
  const handleUpdateComment = async () => {
    const response = await mutationUpdateComment(editedComment);
    // update comment in comments list in order to save api fetch
    if (response) {
      const newComments = comments.map((c) => {
        if (c[idKey] === editedComment[idKey]) {
          return { ...c, comment: editedComment.comment };
        }
        return c;
      });
      setComments(newComments);
      setEditedComment(null);
    }
  };
  const renderComment = (uuid, comment) => (
    <div className={styles.commentLine} key={comment[idKey]}>
      <div className={styles.comment}>
        <div className={styles.commentHeader}>
          {`${moment.unix(comment.createdAt).format('MMM DD, YYYY | HH:mm')} | by ${
            comment.createdByDisplayName || comment.createdBy
          }`}
        </div>
        {editedComment && editedComment[idKey] === comment[idKey] ? (
          <div className={styles.editComment}>
            <Input
              type="textarea"
              value={editedComment?.comment}
              onChange={(event) => setEditedComment({ ...editedComment, comment: event.target.value })}
              onKeyDown={(e) => e.stopPropagation()}
              autoFocus
            />
            <Button
              onClick={() => handleUpdateComment()}
              text="Update"
              disabled={!editedComment?.comment}
              isLoading={isUpdatingComment}
            />
          </div>
        ) : (
          // eslint-disable-next-line react/no-danger
          (<div className={styles.commentText} dangerouslySetInnerHTML={{ __html: getCommentText(comment.comment) }} />)
        )}
      </div>
      <div className={styles.commentMenuBtnContainer}>
        <ButtonDropdown
          text=""
          isTextButton
          icon={() => <GenerateIcon iconName={ICONS.verticalDots.name} />}
          automationid="comment-actions"
          isLoading={deletingComments.includes(comment[idKey])}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li
            onClick={() => {
              setEditedComment({ [idKey]: comment[idKey], comment: comment.comment });
            }}
            automation-id="edit-comment"
            id="edit"
          >
            <span>
              <GenerateIcon iconName={ICONS.edit.name} /> Edit
            </span>
          </li>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <li
            onClick={() => !isDeletingComment && handleDeleteComment(comment)}
            id="delete"
            automation-id="delete-comment"
          >
            <span>
              <GenerateIcon iconName={ICONS.delete.name} /> Delete
            </span>
          </li>
        </ButtonDropdown>
      </div>
    </div>
  );

  return (
    <div className={`${styles.wrapper} ${className}`} onClick={(e) => e.stopPropagation()} automation-id="comment-btn">
      <Popover open={isOpen} onOpenChange={toggle} style={{ zIndex: 1 }}>
        <PopoverTrigger>
          <Tooltip title="Add Comment" arrow placement="bottom">
            <div className={styles.iconContainer}>
              <CommentIcon className={`${styles.icon} ${comments?.length > 0 ? `${styles.active}` : ''} `} />

              {!!comments.length && <div className={styles.numberOfComments}>{comments.length}</div>}
            </div>
          </Tooltip>
        </PopoverTrigger>
        <PopoverPortal>
          <PopoverContent className={styles.content}>
            <div className={styles.comments}>
              {!!comments.length && (
                <div className={styles.commentsList}>
                  {comments?.map((comment) => renderComment(row.uuid, comment))}
                </div>
              )}
              <div className={styles.footer}>{addCommentFormOpen || !comments.length ? addCommentForm : footer}</div>
            </div>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
    </div>
  );
};

CommentsColumn.propTypes = propTypes;
export default CommentsColumn;
