import { numberWithCommas, strNumToSize } from 'shared/utils/strUtil';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants';

function localCostStrFromString(
  strValue,
  displayedMetric,
  getCurrencyNumber,
  overrideCurrency = null,
  overrideDecimal = undefined,
) {
  const decimal = overrideDecimal || 2;
  if (displayedMetric === DisplayMetricTypes.COST && typeof strValue === 'number') {
    return `${getCurrencyNumber(strValue, decimal, {}, overrideCurrency)}`;
  }
  return numberWithCommas(strValue, decimal);
}

export const getFormatterComponent = (
  isUsage,
  usageType,
  displayedMetric,
  getCurrencyNumber,
  overrideCurrency = null,
  carbonEmissionsValue = undefined,
) => {
  const getDecimal = (value) => {
    if (carbonEmissionsValue) {
      return 5;
    }
    if (value.value < 10) {
      return 4;
    }
    return undefined;
  };
  const defaultFormatter = (value) =>
    value.value === 0
      ? '-'
      : localCostStrFromString(value.value, displayedMetric, getCurrencyNumber, overrideCurrency, getDecimal(value));
  let formatter = defaultFormatter;
  if (isUsage && usageType === 'Bytes') {
    const bytesFormatter = (value) => (value.value === 0 ? '-' : `${numberWithCommas(value.value, 2)} GB`);
    formatter = bytesFormatter;
  }
  return formatter;
};

export const getNumToSizeFormatterComponent = (value, cloudTypeId) => {
  const num = strNumToSize(value.value, 2, cloudTypeId);
  return num.split(' ')[0] === '0' ? 0 : num;
};
