import React from 'react';
import { observer } from 'mobx-react';
import { VendorStore } from './vendorStore.ts';
import { useVendorMappings } from '../../hooks/react-query/useVendorMappings';

interface Props {
  vendorStore: VendorStore;
}

export const VendorMappingsInitializer = observer(({ vendorStore }: Props) => {
  const { data: vendors } = useVendorMappings();

  React.useEffect(() => {
    if (vendors && !vendorStore.initialized) {
      vendorStore.initializeWithVendors(vendors);
    }
  }, [vendors, vendorStore.initialized, vendorStore]);

  return null;
});
