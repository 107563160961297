import React, { useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import UnauthenticatedRoute from 'app/containers/App/components/UnauthenticatedRoute';
import MainWrapper from 'app/containers/App/MainWrapper';
import LogIn from 'users/containers/LogIn/Login';
import ResetPassword from 'users/containers/LogIn/ResetPassword';
import Register from 'users/containers/Register';
import DesignSystem from 'shared/components/designSystem/DesignSystem';
import { Routes as ROUTES } from 'shared/constants/routes';
import AuthenticatedRouter from '~/app/containers/App/AuthenticatedRouter.jsx';

const RedirectToLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    navigate(`${ROUTES.LOG_IN}${location.hash}`);
  }, [navigate]);

  return null; // No UI, just performs the redirect
};

const Router = ({ childProps }) => {
  const navigate = useNavigate();
  childProps.navigate = navigate;
  return (
    <MainWrapper childProps={childProps}>
      <main>
        <Routes>
          <Route exact path={ROUTES.LOG_IN} element={<UnauthenticatedRoute component={LogIn} props={childProps} />} />
          {/* need for SSO login */}
          <Route exact path="/" element={<UnauthenticatedRoute component={RedirectToLogin} props={childProps} />} />
          <Route
            exact
            path={ROUTES.RESET_PASSWORD}
            element={<UnauthenticatedRoute component={ResetPassword} props={childProps} />}
          />
          <Route exact path={ROUTES.DESIGN_SYSTEM} Component={DesignSystem} />
          <Route exact path={ROUTES.REGISTER} element={<Register {...childProps} />} />
          <Route
            exact
            path={ROUTES.REGISTER_NEW_USER_MANAGEMENT}
            element={<Register {...childProps} isNewUserManagement />}
          />
          <Route
            exact
            path={ROUTES.REGISTER_MSP_NEW_USER_MANAGEMENT}
            element={<Register {...childProps} isNewUserManagement isMSP />}
          />
          <Route exact path={ROUTES.REGISTER_MSP} element={<Register {...childProps} isMSP />} />
          <Route exact path={ROUTES.REGISTER_SNOW} element={<Register {...childProps} />} />
          <Route exact path={ROUTES.REGISTER_PROCURE_IT} element={<Register {...childProps} />} />
          <Route exact path={ROUTES.REGISTER_SNOW_MSP} element={<Register {...childProps} isMSP />} />
          {/*  if no match - try Authenticated routes*/}
          <Route path="*" element={<AuthenticatedRouter props={childProps} />} />
        </Routes>
      </main>
    </MainWrapper>
  );
};

Router.propTypes = {
  childProps: PropTypes.object.isRequired,
};

export default Router;
