/* allows to use textMaps in a i18n-like way */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TextMapValue = string | any[] | ((...args: any[]) => any);

export const getFakeT = <T extends Record<string | number | symbol, TextMapValue>>(textMap: T) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (key: keyof T, ...args: any[]) => {
    if (!textMap[key]) {
      return key;
    }

    if (typeof textMap[key] === 'string' || Array.isArray(textMap[key])) {
      return textMap[key];
    }

    return textMap[key]?.(...args);
  };
};
