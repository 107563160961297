import React from 'react';
import PropTypes from 'prop-types';
import { Search } from '../../components/Search';
import RemoveButton from '../../components/RemoveButton';
import AddSubRolesToRole from '../roleModals/AddSubRolesModal/AddSubRolesToRole';
import { OrganizationEntityCategory, Action } from '@anodot-cost/rbac-client';
import ReadOnlyDisplayWrapper from 'shared/components/ReadOnlyDisplayWrapper';

import styles from './RoleTab.module.scss';

const RoleSubRolesTabHeader = ({
  subRolesCount,
  selectedSubRoles,
  search,
  setSearch,
  removeSubRoleClicked,
  role,
  isViewOnly,
}) => (
  <div className={styles.headerContainer}>
    <div className={styles.headerLeft}>
      <div className={styles.header}>
        <div className={styles.title}>
          Roles <span className={styles.count}>({subRolesCount})</span>
        </div>
      </div>
    </div>
    <div className={styles.headerRight}>
      {!isViewOnly ? (
        <>
          <ReadOnlyDisplayWrapper isHide={false} category={OrganizationEntityCategory.Roles} action={Action.Update}>
            <RemoveButton
              isMultiple
              removeClicked={removeSubRoleClicked}
              selectedCounter={selectedSubRoles?.length}
              showRemoveComponent={selectedSubRoles?.length}
            />
          </ReadOnlyDisplayWrapper>
          <AddSubRolesToRole role={role} />
        </>
      ) : (
        <div />
      )}
      <Search search={search} setSearch={setSearch} />
    </div>
  </div>
);

RoleSubRolesTabHeader.propTypes = {
  isViewOnly: PropTypes.bool,
  subRolesCount: PropTypes.number,
  selectedSubRoles: PropTypes.array,
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired,
  removeSubRoleClicked: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
};

export default RoleSubRolesTabHeader;
