import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { MoreVertical } from 'react-feather';
import ButtonDropdown from 'shared/components/andtComponents/ButtonDropdown';
import { ReactComponent as PencilIcon } from 'shared/img/icons/pencil.svg';
import { ReactComponent as DeleteIcon } from 'shared/img/icons/trash.svg';
import { ReactComponent as CopyIcon } from 'shared/img/icons/clone.svg';

const CustomDashboardTemplateActionButton = ({
  dashboard,
  dashboardHandlers,
  currentDisplayedUserKey,
  currDispUserAdminRole,
}) => {
  const isEditDeleteAvailable = dashboard.userKey === currentDisplayedUserKey && currDispUserAdminRole;
  const { editDashboardTemplate, deleteDashboardTemplate, copyTemplateToDashboards } = dashboardHandlers;
  const options = [
    {
      text: 'Clone to Dashboards',
      Icon: CopyIcon,
      onClick: () => copyTemplateToDashboards(dashboard),
    },
    {
      text: 'Edit',
      Icon: PencilIcon,
      disabled: !isEditDeleteAvailable,
      onClick: () => editDashboardTemplate(dashboard),
    },
    {
      text: 'Delete',
      Icon: DeleteIcon,
      disabled: !isEditDeleteAvailable,
      onClick: () => deleteDashboardTemplate(dashboard),
    },
  ];
  return (
    <ButtonDropdown icon={MoreVertical} isTextButton>
      {options
        .filter((opt) => !opt.disabled)
        .map(({ text, Icon, onClick }) => (
          <li key={text} onClick={onClick}>
            <span>
              <Icon />
              {text}
            </span>
          </li>
        ))}
    </ButtonDropdown>
  );
};

CustomDashboardTemplateActionButton.propTypes = {
  dashboardHandlers: PropTypes.object.isRequired,
  dashboard: PropTypes.object.isRequired,
  currDispUserAdminRole: PropTypes.bool.isRequired,
  currentDisplayedUserKey: PropTypes.string.isRequired,
};

export default observer(CustomDashboardTemplateActionButton);
