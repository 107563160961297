import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Container, Input, InputGroup, Label, Row } from 'reactstrap';
import DatePicker from 'shared/components/DatePicker';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { GranularityLevelsTypes } from '../../constants/appConstants';
import CustomModal from '../andtComponents/Modal';

const EventCreateModal = ({ onClose, isOpen, handleCreateEvent, isLoading, editItem }) => {
  const [title, setTitle] = useState(editItem ? editItem.title : '');
  const [description, setDescription] = useState(editItem ? editItem.description : '');
  const [estimation, setEstimation] = useState(editItem ? editItem.estimation : null);
  const [date, setDate] = useState(
    editItem
      ? new Date(new Date(editItem.date).valueOf() + new Date(editItem.date).getTimezoneOffset() * 60000)
      : new Date(),
  );
  const [error, setError] = useState(null);
  const { currencySymbol } = useUserSettingsContext();

  useEffect(() => {
    setError(null);
  }, [title, description, date]);
  return (
    <CustomModal
      open={isOpen}
      className="events-create-modal"
      onClose={onClose}
      saveDisabled={isLoading}
      closeOnSave={false}
      onSave={async () => {
        if (!title) {
          setError('Title is required');
          return;
        }
        await handleCreateEvent({
          date: moment(date).format('YYYY-MM-DD'),
          title,
          description,
          estimation: Number(estimation),
          id: editItem ? editItem.uuid : null,
        });
        setTitle('');
        setEstimation(null);
        setDescription('');
        onClose();
      }}
      title={editItem ? 'Edit Event' : 'Create Event'}
    >
      <Container>
        <Row className="pb-4">
          <h5>
            <Label for="eventTitle">Title (required)</Label>
          </h5>
          <InputGroup>
            <Input
              type="text"
              maxlength={60}
              name="eventTitle"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              placeholder="Enter title"
              automationid="event-title"
            />
          </InputGroup>
        </Row>
        <Row className="pb-4">
          <h5>
            <Label for="eventDescription">Description</Label>
          </h5>
          <InputGroup>
            <Input
              type="textarea"
              name="eventDescription"
              maxlength={320}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              placeholder="Enter description"
              automationid="event-descrption"
            />
          </InputGroup>
        </Row>
        <Row className="pb-4">
          <h5>
            <Label for="eventDescription">{currencySymbol} Impact Estimation (optional)</Label>
          </h5>
          <InputGroup>
            <Input
              type="number"
              name="eventEstimation"
              onChange={(e) => setEstimation(e.target.value)}
              value={estimation}
              placeholder="Enter estimation"
              automationid="event-estimation"
            />
          </InputGroup>
        </Row>
        <Row>
          <h5>Date (required)</h5>
          <InputGroup>
            <DatePicker
              startDate={date}
              granLevel={GranularityLevelsTypes.GRAN_LEVEL_DAILY}
              handleChangeDateStart={(value) => {
                setDate(value);
              }}
            />
          </InputGroup>
        </Row>
        {error && (
          <Row className="pt-4">
            <p className="events-create-modal__error">{error}</p>
          </Row>
        )}
      </Container>
    </CustomModal>
  );
};

EventCreateModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleCreateEvent: PropTypes.func.isRequired,
  editItem: PropTypes.object,
};
export default EventCreateModal;
