/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { debounce } from 'lodash';

const selectStyles = {
  control: (provided) => ({ ...provided, minWidth: 240, margin: 8 }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

class AutocompleteSearch extends PureComponent {
  static propTypes = {
    noOptionsMessageTxt: PropTypes.string.isRequired,
    handleSelect: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    asyncFunc: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    itemsLimit: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      inputValue: '',
    };
    this.debouncedLoader = debounce(async (str, callBack) => {
      const result = await props.asyncFunc(str);
      callBack(result.slice(0, props.itemsLimit || result.length));
    }, 500);
  }

  getOptions = (inputValue, callBack) => {
    if (!inputValue || inputValue.length < 3) {
      return;
    }
    this.debouncedLoader(inputValue, callBack);
  };

  handleInputChange = (newValue) => {
    this.setState({ inputValue: newValue });
    return newValue;
  };

  filterResources = (inputValue, data) => data.filter((i) => i.label.toLowerCase().includes(inputValue.toLowerCase()));

  noOptionsMessage = () => {
    if (this.state.inputValue.length < 3) {
      return '';
    }
    if (this.state.inputValue.length) {
      return this.props.noOptionsMessageTxt ? this.props.noOptionsMessageTxt : 'No Options';
    }
    return '';
  };

  filterValues = (data) => data.filter((i) => i.label.toLowerCase().includes(this.state.inputValue.toLowerCase()));

  render() {
    const { handleSelect, placeholder = 'Search...', value } = this.props;
    return (
      <AsyncSelect
        cacheOptions
        loadOptions={this.getOptions}
        onInputChange={this.handleInputChange}
        autoFocus
        backspaceRemovesValue={false}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={handleSelect}
        placeholder={placeholder}
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
        noOptionsMessage={this.noOptionsMessage}
        formatCreateLabel={(inputValue) => ` Search ${inputValue}`}
      />
    );
  }
}

export default AutocompleteSearch;
