import React from 'react';
import AppSelect from 'shared/components/AppSelect/index.tsx';
import Select from 'shared/components/andtComponents/SimpleSelect';
import { Input } from 'reactstrap';
import ColoredBlock from 'shared/components/ColoredFormBlock';
import styles from '../alerts.module.scss';
import t from '../texts.ts';
import { createOptions } from '../helpers.ts';
import {
  alertsThresholdChangeTypesToDisplayLabels,
  costChangeAlertChangeTypesToDisplayLabels,
  cueAlertAndOrToDisplayLabels,
} from '../../../constants/usageConstants';
import { AlertTypesEnum } from '../types.ts';
import { FormServiceCostProps } from './FormServiceCost.tsx';

interface Props {
  automationPostfix: string;
  currencySymbol: string;
}

const AlertChangesFormBlock: React.FC<Props & FormServiceCostProps> = ({
  showErrors,
  setAlert,
  alert,
  disabled,
  automationPostfix,
  isChangesValid,
  currencySymbol,
}) => {
  const isTrendType = String(alert.alertType) === String(AlertTypesEnum.COST_CHANGES);

  return (
    <ColoredBlock className="mb-3" icon="chartLine" color="orange">
      <div className={styles.twoColumns}>
        <div>
          <div className="mb-2" automation-id={`when-field-wrapper${automationPostfix}`}>
            <h5>{t('WHEN')}</h5>
            <AppSelect
              isClearable={false}
              className={styles.select}
              automationid={`when-field${automationPostfix}`}
              options={createOptions(
                isTrendType ? costChangeAlertChangeTypesToDisplayLabels : alertsThresholdChangeTypesToDisplayLabels,
              )}
              value={alert.changeType?.value || null}
              onChange={(selected) => {
                setAlert?.('changeType', selected);
              }}
              placeholder="Select"
              disabled={disabled}
              invalid={showErrors && !alert.changeType}
            />
          </div>
          {isTrendType && (
            <div style={{ marginTop: 25 }}>
              <Select
                className={styles.select}
                automationid={`operator-type-field${automationPostfix}`}
                options={createOptions(cueAlertAndOrToDisplayLabels)}
                value={alert.operatorType?.value}
                onChange={(selected: string | number) => {
                  setAlert?.('operatorType', selected);
                }}
                placeholder="Select"
                disabled={disabled}
                isClearable={false}
              />
            </div>
          )}
        </div>
        <div>
          {isTrendType && (
            <div className="mb-2">
              <h5>{t('BY_PERCENTS')}</h5>
              <Input
                automation-id={`by-percents-field${automationPostfix}`}
                value={alert.changePercent}
                margin="normal"
                min={0}
                variant="outlined"
                placeholder="0"
                type="number"
                className={styles.inputServiceCost}
                disabled={disabled}
                invalid={showErrors && !disabled && !isChangesValid && !Number(alert.changePercent)}
                onChange={(e) => setAlert?.('changePercent', e.target.value)}
              />
            </div>
          )}
          <div>
            <h5>({currencySymbol})</h5>
            <Input
              automation-id={`for-number-field${automationPostfix}`}
              value={alert.changeValue}
              min={0}
              margin="normal"
              variant="outlined"
              placeholder="0"
              disabled={disabled}
              className={styles.inputServiceCost}
              type="number"
              invalid={showErrors && !disabled && !isChangesValid && !Number(alert.changeValue)}
              onChange={(e) => setAlert?.('changeValue', e.target.value)}
            />
          </div>
        </div>
      </div>
    </ColoredBlock>
  );
};

export default AlertChangesFormBlock;
