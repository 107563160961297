import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomModal from 'shared/components/andtComponents/Modal';
import Input from 'shared/components/andtComponents/Input';
import { isUsernameValid } from 'users/utils/userUtil';
import SimpleSelect from 'shared/components/andtComponents/SimpleSelect';
import { RoleUsersType } from 'users/constants/usersConstants';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';
import { ROLE_TYPES } from 'users/containers/RolesAndUsers/constants/rolesAndUsersConstants';
import { ReactComponent as EditorIcon } from '../assets/editor.svg';
import { ReactComponent as ViewerIcon } from '../assets/viewer.svg';
import { ReactComponent as UserIcon } from '../assets/user.svg';
import { ReactComponent as UsersIcon } from '../assets/users.svg';
import styles from './AddOrEditUserModal.module.scss';
import AddUsersCSV from './AddUsersCSV/AddUsersCSV';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const createRoleOptions = (roles) => roles?.map((role) => ({ label: role.roleName, value: role.uuid }));

const AddOrEditUserModal = ({
  onClose,
  onSave,
  roles,
  open,
  email = '',
  role: roleProp,
  readOnly = false,
  isEditMode = false,
  customerMode = false,
}) => {
  const brand = useBrand();
  const [userEmail, setUserEmail] = useState(email || '');
  const [emails, setEmails] = useState([]);
  const [role, setRole] = useState(roleProp?.uuid);
  const [error, setError] = useState(null);
  const [isBulk, setIsBulk] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(readOnly || false);
  const curRole = roles?.find((r) => r.uuid === role);
  useEffect(() => {
    if (!isBulk) {
      setEmails([]);
    }
  }, [isBulk]);
  return (
    <CustomModal
      closeOnSave={false}
      overrideStyles={{ width: 600 }}
      saveDisabled={((isBulk ? !emails.length : !userEmail) && (!!email || !isEditMode)) || (!customerMode && !role)}
      onSave={async () => {
        if (!isBulk) {
          const { resultStrings } = isUsernameValid(userEmail);
          if (resultStrings[0] && !isEditMode) {
            setError(resultStrings[0]);
            return;
          }
        }
        if (isBulk) {
          const invalid = emails.find((e) => !isUsernameValid(e.trim()).validResult);
          if (invalid) {
            setError(isUsernameValid(invalid).resultStrings[0]);
            return;
          }
        }
        const isSuccess = await onSave({
          userEmail: isBulk ? emails.map((e) => e.trim()).filter(Boolean) : userEmail,
          role: createRoleOptions(roles).find((r) => r.value === role),
          overrideReadOnly: curRole?.roleType === ROLE_TYPES.DEFAULT_ADMIN ? undefined : +isReadOnly,
        });
        if (isSuccess) {
          onClose();
        }
      }}
      open={open}
      onClose={onClose}
      title=""
      automationid="addEditUserModal"
    >
      <div className={styles.container}>
        {!isEditMode && (
          <div className={styles.roleTypeOptions} data-user="true">
            <div className={styles.roleTypeOption} data-active={!isBulk} onClick={() => setIsBulk(false)}>
              <div className={styles.roleTypeHeader}>
                <RadioButtonList
                  options={[{ value: 'single', label: 'Add one user', primary: true }]}
                  value={isBulk ? 'bulk' : 'single'}
                  onChange={() => {
                    setIsBulk(false);
                  }}
                />
              </div>
              <div className={styles.roleTypeBody} data-user="true">
                <p>Provide Email address</p>
                <UserIcon />
              </div>
            </div>
            <div className={styles.roleTypeOption} data-active={!!isBulk} onClick={() => setIsBulk(true)}>
              <div className={styles.roleTypeHeader}>
                <RadioButtonList
                  options={[{ value: 'bulk', label: 'Add many at once', primary: true }]}
                  value={isBulk ? 'bulk' : 'single'}
                  onChange={() => {
                    setIsBulk(true);
                  }}
                />
              </div>
              <div className={styles.roleTypeBody} data-user="true">
                <p>Upload CSV file</p>
                <UsersIcon />
              </div>
            </div>
          </div>
        )}
        {isBulk ? (
          <AddUsersCSV
            error={error}
            setError={setError}
            emails={emails}
            onChangeEmails={(emails) => setEmails(emails)}
          />
        ) : (
          <>
            {(email || !isEditMode) && (
              <div>
                <p className={styles.label}>Email</p>
                <Input
                  overrideStyles={{ height: 36 }}
                  disabled={isEditMode}
                  placeholder={`example@${brand.domain}`}
                  value={userEmail}
                  isInvalid={!!error}
                  invalidComponent={error}
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                    setError(null);
                  }}
                  autoComplete="off"
                  automationid="addEditUserModalEmail"
                />
              </div>
            )}
          </>
        )}
        {(isEditMode || !role) && !customerMode ? (
          <div>
            <p className={styles.label}>Role</p>
            <SimpleSelect
              isDisabled={!isEditMode}
              value={role}
              placeholder="Select Role"
              options={createRoleOptions(roles)}
              onChange={(role) => {
                setRole(role);
                if (role) {
                  setIsReadOnly(+roles.find((r) => r.uuid === role)?.roleUserType === RoleUsersType.VIEWER);
                }
              }}
            />
          </div>
        ) : null}
        {!isBulk && !customerMode && curRole.roleType !== ROLE_TYPES.DEFAULT_ADMIN && (!!email || !isEditMode) && (
          <div>
            <p className={styles.label}>Permission</p>
            <div className={styles.roleTypeOptions}>
              <div className={styles.roleTypeOption} data-active={!isReadOnly} onClick={() => setIsReadOnly(false)}>
                <div className={styles.roleTypeHeader}>
                  <RadioButtonList
                    options={[{ value: RoleUsersType.EDITOR, label: 'Editor', primary: true }]}
                    value={isReadOnly ? RoleUsersType.VIEWER : RoleUsersType.EDITOR}
                    onChange={() => {
                      setIsReadOnly(false);
                    }}
                  />
                </div>
                <div className={styles.roleTypeBody}>
                  <EditorIcon />
                  <p>Can view and manage accounts</p>
                </div>
              </div>
              <div className={styles.roleTypeOption} data-active={!!isReadOnly} onClick={() => setIsReadOnly(true)}>
                <div className={styles.roleTypeHeader}>
                  <RadioButtonList
                    options={[{ value: RoleUsersType.VIEWER, label: 'Viewer', primary: true }]}
                    value={isReadOnly ? RoleUsersType.VIEWER : RoleUsersType.EDITOR}
                    onChange={() => {
                      setIsReadOnly(true);
                    }}
                  />
                </div>
                <div className={styles.roleTypeBody}>
                  <ViewerIcon />
                  <p>Can only view accounts</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

AddOrEditUserModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  isEditMode: PropTypes.bool,
  readOnly: PropTypes.bool,
  customerMode: PropTypes.bool,
  email: PropTypes.string,
  role: PropTypes.object.isRequired,
  roles: PropTypes.object.isRequired,
};

export default AddOrEditUserModal;
