import { useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { createApiKey } from 'shared/utils/apiUtil';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { ONBOARDING_TYPES } from 'app/containers/Onboarding/utils/OnboardingConstants';
import { Routes } from 'shared/constants/routes';
import { useRootStore } from 'app/contexts/RootStoreContext';
import {
  BYOD_ONBOARDING_FIELDS,
  BYOD_ONBOARDING_STEP_TO_FIELDS,
  BYOD_ONBOARDING_STEPS,
} from '../ByodOnboardingConstants';
import * as ByodOnboardingUtils from '../ByodOnboardingUtils';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateAccountId } from 'app/containers/Onboarding/utils/OnboardingUtils';

export default function useFormNavigation({ screen, screens }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { trigger, formState, reset, setValue, getValues } = useFormContext();
  const { errors } = formState || {};
  const { usersStore, appStore } = useRootStore();
  const triggerErrors = async () => trigger(BYOD_ONBOARDING_STEP_TO_FIELDS[screen] || [], { shouldFocus: true });
  const { createByodAccount, updateOnboardingParams, cancelOnboarding } = useOnboarding();
  const { mutateAsync: handleCreateByodAccount } = createByodAccount();
  const onboardingType = getValues(BYOD_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const { mutateAsync: handleUpdateOnboardingParams } = updateOnboardingParams();
  const { mutateAsync: handleCancelOnboarding } = cancelOnboarding();
  useWatch(BYOD_ONBOARDING_FIELDS.ONBOARDING_TYPE);
  const nextStep = useCallback(async () => {
    const isValid = await triggerErrors();
    if (!isValid) {
      return;
    }
    const params = {
      userKey: getValues(BYOD_ONBOARDING_FIELDS.USER_KEY),
      apiKey: createApiKey(usersStore, true),
      accountKey: getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_KEY),
      accountId: getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_ID),
      accountName: getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME),
      tenantName: getValues(BYOD_ONBOARDING_FIELDS.TENANT_NAME),
      tenantScheme: getValues(BYOD_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME),
      resellerCustomer: getValues(BYOD_ONBOARDING_FIELDS.RESELLER_CUSTOMER),
      resellerCustomerId: getValues(BYOD_ONBOARDING_FIELDS.RESELLER_CUSTOMER_ID),
      onboardingType: getValues(BYOD_ONBOARDING_FIELDS.ONBOARDING_TYPE),
      validationStarted: getValues(BYOD_ONBOARDING_FIELDS.VALIDATION_STARTED),
      accountType: getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_TYPE),
      excludedLinkedAccountMatch: getValues(BYOD_ONBOARDING_FIELDS.EXCLUDED_LINKED_ACCOUNT_MATCH),
      isCustomerSelfManaged: +getValues(BYOD_ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED),
      isReseller: +getValues(BYOD_ONBOARDING_FIELDS.IS_MSP),
      autoAssignLinkedAccounts: +getValues(BYOD_ONBOARDING_FIELDS.AUTO_ASSIGN_LINKED_ACCOUNTS),
      mappingType: getValues(BYOD_ONBOARDING_FIELDS.IMPORT_TYPE),
      cloudTypeId: getValues(BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID),
    };
    if (screen === BYOD_ONBOARDING_STEPS.BYOD_DETAILS) {
      if (!getValues(BYOD_ONBOARDING_FIELDS.ACCESS_POINT_ALIAS)) {
        return;
      }
      const searchParams = new URLSearchParams(location.search);
      const accountIdChanged =
        searchParams.get('accountId') && getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_ID) !== searchParams.get('accountId');
      if (!params.accountId) {
        const newAccountId = generateAccountId(getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME));
        setValue(BYOD_ONBOARDING_FIELDS.ACCOUNT_ID, newAccountId);
        params.accountId = newAccountId;
      }
      if (!getValues(BYOD_ONBOARDING_FIELDS.STARTED) || accountIdChanged) {
        if (accountIdChanged) {
          await handleCancelOnboarding({ accountId: searchParams.get('accountId') });
        }
        setValue(BYOD_ONBOARDING_FIELDS.STARTED, true);
        const addQueryParam = (key, value) => {
          const url = new URL(window.location.href);
          url.searchParams.set(key, value);
          window.history.pushState({}, '', url.toString());
        };
        addQueryParam('accountId', params.accountId);
      } else {
        await handleUpdateOnboardingParams(params);
      }
      const generateS3Path = () => {
        return `s3://${getValues(BYOD_ONBOARDING_FIELDS.ACCESS_POINT_ALIAS)}/${getValues(
          BYOD_ONBOARDING_FIELDS.ORGANIZATION_ID,
        )}/${params.accountId}`;
      };
      if (!params.s3Path) {
        const newS3Path = generateS3Path();
        setValue(BYOD_ONBOARDING_FIELDS.S3_PATH, newS3Path);
        params.s3Path = newS3Path;
      }
      const accounts = usersStore.getAllCurrDisplayedUserAccounts();
      const multicloudAccount = accounts.find((acc) => acc.cloudTypeId === 4);
      try {
        params.multicloudAccountId = multicloudAccount?.accountId;
        await handleCreateByodAccount(params);
      } catch (error) {
        console.info(error);
        return;
      }
    }

    // go to Accounts page if it is the last screen of onboarding
    if (screens && screens.findIndex((scr) => scr.id === screen) === screens.length - 1) {
      navigate(appStore.isKeyCloakManagement ? Routes.ORGANIZATION_CLOUD_ACCOUNTS : Routes.ACCOUNT);
      window.location.reload();
      return;
    }

    setValue(BYOD_ONBOARDING_FIELDS.SCREEN, ByodOnboardingUtils.handleNextScreen(screen));
  }, [errors, screen, onboardingType]);

  const prevStep = useCallback(() => {
    reset({}, { keepValues: true, keepTouched: false, keepErrors: false, keepDirty: true });

    setValue(
      BYOD_ONBOARDING_FIELDS.SCREEN,
      ByodOnboardingUtils.handlePrevScreen(screen, onboardingType === ONBOARDING_TYPES.AUTOMATIC),
    );
  }, [errors, screen, onboardingType]);

  return {
    nextStep,
    prevStep,
  };
}
