import React from 'react';
import PropTypes from 'prop-types';
import { SecondaryTableHeaderRow } from './SecondaryTableHeaderRow';
import { MainTableHeaderRow } from './MainTableHeaderRow';

export const DoubleRowHeader = ({
  filteredData,
  accounts,
  chipFilterClicked,
  cloudTypeChips,
  showUnComplateOnboardingAccounts,
  setShowUnComplateOnboardingAccounts,
  csvData,
}) => {
  return (
    <>
      <MainTableHeaderRow
        key={'upper-header'}
        filteredData={filteredData}
        accounts={accounts}
        chipFilterClicked={chipFilterClicked}
        cloudTypeChips={cloudTypeChips}
        showUnComplateOnboardingAccounts={showUnComplateOnboardingAccounts}
        setShowUnComplateOnboardingAccounts={setShowUnComplateOnboardingAccounts}
      />
      <SecondaryTableHeaderRow key={'lower-header'} filteredData={filteredData} accounts={accounts} csvData={csvData} />
    </>
  );
};

DoubleRowHeader.propTypes = {
  filteredData: PropTypes.array.isRequired,
  accounts: PropTypes.array.isRequired,
  chipFilterClicked: PropTypes.func.isRequired,
  cloudTypeChips: PropTypes.array.isRequired,
  showUnComplateOnboardingAccounts: PropTypes.bool.isRequired,
  setShowUnComplateOnboardingAccounts: PropTypes.func.isRequired,
  csvData: PropTypes.object.isRequired,
};
