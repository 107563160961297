import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';

import styles from './Switch.module.scss';

const Switch = ({
  options,
  onChange,
  isDisabled = false,
  isLoading = false,
  className = undefined,
  backgroundColor = undefined,
}) => {
  return (
    options?.length > 0 && (
      <div className={classNames(styles.container, { [styles.inProgress]: isLoading })}>
        <div className={styles.switchOptionsContainer} style={{ backgroundColor: backgroundColor }}>
          {options.map((option) => (
            <Tooltip
              title={option.tooltip}
              disabled={!option.tooltip}
              key={option.label || option.icon}
              placement="top"
            >
              <div
                key={option.label || option.icon}
                style={option.isSelected && !isDisabled ? { backgroundColor: option.color } : {}}
                className={classNames(styles.option, className, {
                  [styles.viewOnly]: option.isSelected && isDisabled,
                  [styles.selected]: option.isSelected,
                  [styles.notSelected]: !option.isSelected,
                  [styles.disabled]: isDisabled,
                })}
                onClick={(e) => onChange(e, option)}
              >
                {option.label || <GenerateIcon iconName={option.icon} />}
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    )
  );
};

Switch.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      isSelected: PropTypes.bool,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Switch;
