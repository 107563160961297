import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { OPERATORS_KEYS, PageNames } from 'shared/constants/appConstants';
import FilterContentList from 'shared/components/FilterSidebar/FilterContentList';
import { Option, ServiceCostAlert } from '../types.ts';
import { useInvoiceFilters } from 'invoices/contexts/InvoiceFiltersContext';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { AwsCommonFields } from 'shared/constants/awsConstants';
import { cloneDeep } from 'lodash/lodash';

export interface FiltersServiceCostProps {
  alert: Partial<ServiceCostAlert>;
  disabled?: boolean;

  setAlert?(field: keyof ServiceCostAlert | string, value: string | number | Option[]): void;
}

const FiltersServiceCost: React.FC<FiltersServiceCostProps> = ({ setAlert, disabled, alert }) => {
  const { usersStore, appStore } = useRootStore();
  const invoiceContext = useInvoiceFilters();
  const fieldToFieldDistinctValuesMap = invoiceContext.getPageFilters(
    PageNames.CUE_ALERTS,
    usersStore.currDispUserCloudAccountType,
  );
  const [excludeMap, setExcludeMap] = useState(new Map());

  useEffect(() => {
    setExcludeMap(
      new Map(
        // @ts-expect-error overload error
        Object.entries(alert.filters || []).map(([key, value]) =>
          key === AwsCommonFields.CUSTOM_TAGS ? [key, value] : [key, value.operator === OPERATORS_KEYS.IS_NOT ? 1 : 0],
        ),
      ),
    );
  }, [JSON.stringify(alert.filters)]);

  const selectedOptionsMap = useMemo(
    () =>
      new Map(
        Object.entries(alert.filters || []).map(([key, value]) => [
          key,
          Array.isArray(value.value) ? value.value?.filter((option) => option.value !== 'all') : value.value,
        ]),
      ),
    [alert.filters],
  );

  const handleOnExcludeClick = useCallback(
    (field: string, subField: string, isExcluded: boolean) => {
      setAlert?.(`filters.${field}.operator`, isExcluded ? OPERATORS_KEYS.IS_NOT : OPERATORS_KEYS.IS);
      if (field === AwsCommonFields.CUSTOM_TAGS) {
        setTimeout(
          () =>
            setExcludeMap((s) => {
              // return formatExcludeFilterMap(s, field, subField);
              // TODO: temporary workaround for PIL-10418 BE
              const newMap = cloneDeep(s);
              const customPart = newMap.get(AwsCommonFields.CUSTOM_TAGS);
              if (!isExcluded) {
                newMap.set(AwsCommonFields.CUSTOM_TAGS, { value: customPart.value });
                return newMap;
              } else {
                const allSubfields = Array.from(
                  // @ts-expect-error overload error
                  new Set(Object.values(customPart?.value || {}).map((d) => d?.value?.split(':')?.[0] || '')),
                );
                const newCustomTags = { value: customPart.value };
                allSubfields.forEach((subField) => {
                  newCustomTags[subField] = 1;
                });
                newMap.set(AwsCommonFields.CUSTOM_TAGS, newCustomTags);
                return newMap;
              }
            }),
          10,
        );
      }
    },
    [setAlert],
  );

  const handleSelectOptions = useCallback(
    (field: string, selected = [] as Option[]) => {
      let optionsToSave = [...selected];
      if (selected.length > 1) {
        optionsToSave = selected.filter((d) => d.value !== 'all');
      }
      if (selected.length === 0) {
        optionsToSave = [{ value: 'all', label: 'Any Type' }];
      }
      setAlert?.(`filters.${field}.value`, optionsToSave);
    },
    [setAlert, fieldToFieldDistinctValuesMap],
  );

  return (
    <FilterContentList
      fieldToFieldDistincValuesMap={fieldToFieldDistinctValuesMap}
      selectedOptionsMap={selectedOptionsMap}
      generalProps={{
        ...invoiceContext,
        isDisabled: disabled,
        handleFilterChange: handleSelectOptions,
        likeFiltersStatus: {},
        handleChangeFilterType: handleOnExcludeClick,
        excludedFiltersStatusMap: excludeMap,
        likeOperator: false,
        fieldsWidth: 440,
        isKeyCloakManagement: appStore.isKeyCloakManagement,
      }}
    />
  );
};

export default FiltersServiceCost;
