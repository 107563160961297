import React, { useState } from 'react';
import classnames from 'classnames';
import { ReactComponent as MenuIcon } from 'shared/img/icons/three-dots-menu.svg';
import { ReactComponent as ModalHeaderAddIcon } from 'shared/img/icons/modal-header-add.svg';
import { ReactComponent as ModalHeaderEditIcon } from 'shared/img/icons/modal-header-edit.svg';
import { ReactComponent as ModalHeaderLockIcon } from 'shared/img/icons/modal-header-lock.svg';
import Button from './Button';
import ButtonDropdown from './ButtonDropdown';
import InfoPopover from './InfoPopover';

import styles from './Modal.module.scss';

export const MODAL_MODES = {
  ADD: 'add',
  EDIT: 'edit',
  SIMPLE: 'simple',
  SIMPLE_NEW: 'simple_new',
  LOCK: 'lock',
} as const;

type ModalMode = typeof MODAL_MODES[keyof typeof MODAL_MODES];

interface ClassNameObject {
  modalHeader?: string;
  title?: string;
  container?: string;
  modal?: string;
  content?: string;
  footer?: string;
  buttons?: string;
}

interface CustomModalProps {
  open: boolean;
  onClose?: (value: boolean) => void;
  onCloseClick?: () => void;
  onSecondPrimaryClick?: (val?: any) => void;
  onSecondCancelClick?: () => void;
  closeOnOutside?: boolean;
  cancelTitle?: string;
  cancelHidden?: boolean;
  secondCancelTitle?: string;
  title?: React.ReactNode;
  onSave?: (e?: React.MouseEvent) => void | Promise<void>;
  className?: string | ClassNameObject;
  children?: React.ReactNode;
  saveDisabled?: boolean;
  saveWarning?: boolean;
  saveTitle?: string;
  saveIcon?: any;
  menuContent?: React.ReactElement | React.ReactElement[];
  closeOnSave?: boolean;
  footerDisabled?: boolean;
  footerMessage?: string;
  overrideStyles?: React.CSSProperties;
  leftButton?: React.ReactNode;
  saveHidden?: boolean;
  automationid?: string;
  secondPrimaryTitle?: string;
  secondPrimaryDisabled?: boolean;
  headerMode?: ModalMode;
  headerIcon?: React.ReactNode;
  comment?: React.ReactNode;
  footerBorder?: boolean;
  isSecondPrimaryError?: boolean;
  isLoading?: boolean;
  colorTheme?: string;
}

const CustomModal: React.FC<CustomModalProps> = ({
  open,
  onClose,
  onCloseClick,
  onSecondCancelClick,
  closeOnOutside = true,
  cancelTitle = 'Cancel',
  cancelHidden = false,
  secondCancelTitle = '',
  title = null,
  onSave = () => Promise.resolve(),
  className = '',
  children,
  saveDisabled = false,
  saveWarning = false,
  saveTitle = 'Save',
  saveIcon,
  menuContent = null,
  closeOnSave = true,
  footerDisabled = false,
  footerMessage = '',
  overrideStyles = null,
  leftButton = null,
  saveHidden = false,
  automationid = 'modal',
  secondPrimaryTitle = null,
  onSecondPrimaryClick = null,
  secondPrimaryDisabled = false,
  headerMode = 'simple',
  headerIcon = null,
  comment = null,
  footerBorder = false,
  isSecondPrimaryError = true,
  isLoading = undefined,
  colorTheme = '',
}) => {
  const [cbLoading, setCbLoading] = useState<boolean>(false);

  const onClick = async (e: React.MouseEvent): Promise<void> => {
    try {
      setCbLoading(true);
      await onSave(e);
      setCbLoading(false);
      if (closeOnSave) {
        onClose?.(false);
      }
    } catch (error) {
      setCbLoading(false);
    }
  };

  if (!open) {
    return null;
  }

  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement>): void => {
    const target = e.target as HTMLElement;
    if (
      closeOnOutside &&
      typeof target.className?.includes === 'function' &&
      target.className?.includes('capture-click-outside')
    ) {
      onClose?.(false);
    }
  };

  const classObject = typeof className === 'object' ? className : { modal: className };

  const renderHeader = (): React.ReactNode => {
    const infoIcon = comment ? (
      <InfoPopover isSimple>
        <div className={styles.comment}>{comment}</div>
      </InfoPopover>
    ) : (
      <div />
    );

    switch (headerMode) {
      case MODAL_MODES.ADD:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            {headerIcon ?? <ModalHeaderAddIcon />}
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.EDIT:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            <ModalHeaderEditIcon />
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.SIMPLE_NEW:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      case MODAL_MODES.LOCK:
        return (
          <div className={classnames(styles.header, classObject.modalHeader)}>
            <ModalHeaderLockIcon />
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3>
            {infoIcon}
          </div>
        );
      default:
        return title ? (
          <div className={styles.flexHeader}>
            <h3 className={classnames(styles.title, classObject.title)}>{title}</h3> {infoIcon}
          </div>
        ) : null;
    }
  };

  return (
    <div
      className={`${classnames(styles.container, classObject.container)} capture-click-outside`}
      onMouseDown={handleClickOutside}
      data-automation-id={automationid}
    >
      <div
        className={classnames(styles.modal, classObject.modal)}
        style={overrideStyles ?? undefined}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === 'Space') {
            e.stopPropagation();
          }
        }}
        tabIndex={0}
        role="dialog"
      >
        {renderHeader()}
        {menuContent && (
          <div className={styles.menuIcon}>
            <ButtonDropdown text="" icon={MenuIcon} isTextButton>
              {menuContent}
            </ButtonDropdown>
          </div>
        )}
        <div className={classnames(styles.content, classObject.content)}>{children}</div>
        {!footerDisabled && (
          <div className={classnames(styles.footer, classObject.footer, { [styles.footerBorder]: footerBorder })}>
            {footerMessage ? <div className={styles.footerMessage}>{footerMessage}</div> : <div />}
            <div className={classnames(styles.buttons, classObject.buttons)}>
              {leftButton && <div className={styles.leftMenu}>{leftButton}</div>}
              {secondCancelTitle && (
                <Button
                  text={secondCancelTitle}
                  onClick={() => {
                    onClose?.(false);
                    onSecondCancelClick?.();
                  }}
                  isSecondary
                  automationid={`${automationid}-${secondCancelTitle}`}
                  disabled={cbLoading}
                />
              )}
              {!cancelHidden && (
                <Button
                  text={cancelTitle}
                  onClick={() => {
                    onClose?.(false);
                    onCloseClick?.();
                  }}
                  isSecondary
                  automationid={`${automationid}-${cancelTitle}`}
                  disabled={cbLoading}
                />
              )}
              {secondPrimaryTitle && (
                <Button
                  text={secondPrimaryTitle}
                  onClick={onSecondPrimaryClick ?? (() => {})}
                  isError={isSecondPrimaryError}
                  disabled={secondPrimaryDisabled ?? cbLoading}
                  automationid={`${automationid}-${secondPrimaryTitle}`}
                />
              )}
              {!saveHidden && (
                <Button
                  text={saveTitle}
                  icon={saveIcon}
                  onClick={onClick}
                  isError={saveWarning}
                  disabled={saveDisabled ?? cbLoading}
                  isLoading={isLoading ?? cbLoading}
                  automationid={`${automationid}-${saveTitle}`}
                  overrideStyles={{ background: colorTheme }}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomModal;
