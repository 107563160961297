import React from 'react';
import { ReactComponent as ConnectK8sImg } from 'shared/img/images/connect-k8s.svg';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import styles from './ConnectK8s.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const ConnectK8s = () => {
  const brand = useBrand();
  return (
    <div className={styles.container}>
      <ConnectK8sImg />
      <h4 className={styles.title}>Connect your Kubernetes resources and gain:</h4>
      <div className="d-flex flex-column align-items-center">
        <ul className={styles.list}>
          <li>&quot;Deep dive&quot; insights into all Kubernetes layers: clusters, namespaces, nodes and pods</li>
          <li>Get a more holistic picture of costs: Analyze Kubernetes and infrastructure costs all together</li>
          <li>Full allocation of Kubernetes costs, and identify waste and under - utilization</li>
        </ul>
        <a
          className={styles.linkBtn}
          target="_blank"
          href={brand.externalLinks.PrometheusAgentForKubernetesForAWSAzure}
          rel="noreferrer"
        >
          <GenerateIcon iconName={ICONS.play.name} /> Learn more
        </a>
      </div>
    </div>
  );
};

export default ConnectK8s;
