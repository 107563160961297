/* eslint-disable @typescript-eslint/no-explicit-any */

import { v4 } from 'uuid';
import User from '../../../../users/models/user';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import {
  getPageTitleForPausedOnboarding as getAWSPageTitleForPausedOnboarding,
  getPageForPausedOnboarding as getAWSPageForPausedOnboarding,
} from '../AwsOnboarding/AwsOnboardingUtils';
import {
  getPageTitleForPausedOnboarding as getAzurePageTitleForPausedOnboarding,
  getPageForPausedOnboarding as getAzurePageForPausedOnboarding,
} from '../AzureOnboarding/AzureOnboardingUtils';
import {
  getPageTitleForPausedOnboarding as getGCPPageTitleForPausedOnboarding,
  getPageForPausedOnboarding as getGCPPageForPausedOnboarding,
} from '../GcpOnboarding/GcpOnboardingUtils';
import { ONBOARDING_FIELDS } from 'app/containers/Onboarding/utils/OnboardingConstants';
import { isEmailValid } from 'shared/utils/strUtil';
import { AWS_ONBOARDING_STEPS } from 'app/containers/Onboarding/AwsOnboarding/AwsOnboardingConstants';
import { AZURE_ONBOARDING_STEPS } from 'app/containers/Onboarding/AzureOnboarding/AzureOnboardingConstants';
import { GCP_ONBOARDING_STEPS } from 'app/containers/Onboarding/GcpOnboarding/GcpOnboardingConstants';
import { validateOnboardingCustomerEmailApi } from 'app/hooks/react-query/useOnboarding';

// TODO: The interfaces below should be actualized with the real props.

interface Onboarding {
  [key: string]: any;
}

export interface CurrentScreen {
  [key: string]: any;
}

interface OnboardingErrors {
  [key: string]: string;
}

interface TenantParams {
  tenantName: string;
  tenantSchemaName: string;
}

export const getPageTitleForPausedOnboarding = (cloudTypeId: number, onboarding: Onboarding): string => {
  switch (cloudTypeId) {
    case CLOUD_TYPE_IDS.AWS:
      return getAWSPageTitleForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.AZURE:
      return getAzurePageTitleForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.GCP:
      return getGCPPageTitleForPausedOnboarding(onboarding);
    default:
      return getAWSPageTitleForPausedOnboarding(onboarding);
  }
};

export const getPageForPausedOnboarding = (cloudTypeId: number, onboarding: Onboarding): string => {
  switch (cloudTypeId) {
    case CLOUD_TYPE_IDS.AWS:
      return getAWSPageForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.AZURE:
      return getAzurePageForPausedOnboarding(onboarding);
    case CLOUD_TYPE_IDS.GCP:
      return getGCPPageForPausedOnboarding(onboarding);
    default:
      return getAWSPageForPausedOnboarding(onboarding);
  }
};

export const getStepsForOnboarding = (cloudTypeId: number): string[] => {
  switch (cloudTypeId) {
    case CLOUD_TYPE_IDS.AWS:
      return Object.values(AWS_ONBOARDING_STEPS);
    case CLOUD_TYPE_IDS.AZURE:
      return Object.values(AZURE_ONBOARDING_STEPS);
    case CLOUD_TYPE_IDS.GCP:
      return Object.values(GCP_ONBOARDING_STEPS);
    default:
      return Object.values(AWS_ONBOARDING_STEPS);
  }
};

export async function accountTypeValidation(
  currentScreen: CurrentScreen,
  keycloakAuth?: boolean,
): Promise<OnboardingErrors> {
  const errors: OnboardingErrors = {};
  if (currentScreen[ONBOARDING_FIELDS.IS_CUSTOMER_SELF_MANAGED] && !currentScreen[ONBOARDING_FIELDS.CUSTOMER_DOMAIN]) {
    errors[ONBOARDING_FIELDS.CUSTOMER_DOMAIN] = 'Customer domain is required';
  }
  if (currentScreen[ONBOARDING_FIELDS.IS_MSP] && currentScreen[ONBOARDING_FIELDS.ACCOUNT_TYPE] === 'dedicated') {
    if (!currentScreen[ONBOARDING_FIELDS.RESELLER_CUSTOMER]) {
      errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER] = 'Customer is required';
    } else if (
      keycloakAuth &&
      typeof currentScreen[ONBOARDING_FIELDS.RESELLER_CUSTOMER] === 'string' &&
      currentScreen[ONBOARDING_FIELDS.RESELLER_CUSTOMER].match(/[%$#@!?±^]/)
    ) {
      errors[ONBOARDING_FIELDS.RESELLER_CUSTOMER] = 'Can not contain special characters';
    }
    if (keycloakAuth) {
      if (!currentScreen[ONBOARDING_FIELDS.EXISTING_CUSTOMER_SELECTED]) {
        if (!currentScreen[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL]) {
          errors[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL] = 'Customer admin email is required';
        } else if (
          typeof currentScreen[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL] === 'string' &&
          !isEmailValid(currentScreen[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL])
        ) {
          errors[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL] = 'Invalid email';
        } else {
          try {
            await validateOnboardingCustomerEmailApi(currentScreen[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL] as string);
          } catch (e: any) {
            errors[ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL] = e.response?.data?.message || 'Something went wrong';
          }
        }
      }
    }
  }
  return errors;
}

export const getTenantParams = (currentDisplaydUser: User, accountName: string): TenantParams => {
  const randomSuffix = v4().substring(0, 6).toLowerCase();
  const accountSchemaNameRaw = accountName
    .trim()
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, '')
    .replace(/-/g, '_')
    .replace(/ /g, '_');
  const tenantName =
    currentDisplaydUser.tenantName ||
    (currentDisplaydUser.companyName || accountName)
      .trim()
      .toLowerCase()
      .replace(/ /g, '_')
      .replace(/[^a-z0-9_]/g, '')
      .slice(0, 100);
  const accountSchemaName = `${accountSchemaNameRaw}_${randomSuffix}`;
  return { tenantName, tenantSchemaName: accountSchemaName };
};

export const generateAccountId = (accountName: string, nameLength = 6, uuidLength = 6): string => {
  const uuid = v4().substring(0, uuidLength);

  const nonAlphanumericRegex = /[^A-Za-z0-9_-]/g;
  const sanitizedAccountName = accountName.replace(nonAlphanumericRegex, '_').substring(0, nameLength);

  return `${sanitizedAccountName}-${uuid}`;
};
