import React from 'react';
import { Input } from 'reactstrap';
import Checkbox from 'shared/components/andtComponents/Checkbox';
import classNames from 'classnames';
import styles from './preferences.module.scss';

interface PreferencesFiltersProps {
  className?: string;
  searchValue?: string;
  handleSearchValue(str: string): void;
  showActives: boolean;
  showInactives: boolean;
  setShowActives: any;
  setShowInactives: any;
  amount?: number;
}

const PreferencesFilters: React.FC<PreferencesFiltersProps> = ({
  className,
  searchValue,
  handleSearchValue,
  showActives,
  showInactives,
  setShowActives,
  setShowInactives,
  amount = 0,
}) => (
  <div className={classNames(className, styles.preferenceFilters)}>
    <div className="d-flex align-items-center">
      <span className={styles.filtersTitle}>Recommendation Types ({amount})</span>
      <Checkbox
        automationid="pref-active-checkbox"
        className="me-3"
        isChecked={showActives}
        primary
        onChange={() => {
          setShowActives((s) => !s);
        }}
        text="Active"
      />
      <Checkbox
        automationid="pref-inactive-checkbox"
        isChecked={showInactives}
        primary
        onChange={() => {
          setShowInactives((s) => !s);
        }}
        text="Inactive"
      />
    </div>
    <Input
      automationid="pref-search-input"
      type="text"
      value={searchValue}
      placeholder="Search by type..."
      onChange={(e) => handleSearchValue(e.target.value)}
      className={classNames(styles.newInput, 'me-3')}
      autoComplete="off"
      style={{ width: 'unset' }}
    />
  </div>
);

export default PreferencesFilters;
