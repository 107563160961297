import React, { PureComponent } from 'react';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { graphColors } from 'shared/constants/colorsConstants';
import PropTypes from 'prop-types';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

class CostChart extends PureComponent {
  static propTypes = {
    isStacked: PropTypes.bool,
    data: PropTypes.object.isRequired,
    isPrecent: PropTypes.bool,
    displayMetric: PropTypes.string.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  getMaxVal = (data) =>
    data.reduce((acc, currData) => {
      Object.keys(currData).forEach((currKey) => {
        if (currKey !== 'divName' && currKey !== 'usageDate' && currKey !== 'groupBy' && +currData[currKey] > acc) {
          acc = currData[currKey];
        }
      });
      return acc;
    }, -Infinity);

  toPrecent = (data) => `${data}%`;
  renderBars = (data) => {
    const barsKeys = data.reduce((acc, currValue) => {
      Object.keys(currValue).forEach((currKey) => {
        if (!acc[currKey] && currKey !== 'divName' && currKey !== 'usageDate' && currKey !== 'groupBy') {
          acc[currKey] = null;
        }
      });
      return acc;
    }, {});
    return Object.keys(barsKeys).map((key, idx) => (
      <Bar dataKey={key} fill={graphColors[idx + 3]} stackId={this.props.isStacked ? 'a' : `a${idx}`} />
    ));
  };

  render() {
    const { renderBars, toPrecent } = this;
    const { data } = this.props;
    const { isPrecent, displayMetric, numStrAbriviaionByDisplayMetric, overrideCurrency } = this.props;
    const maxValue = this.getMaxVal(data);
    return (
      <ResponsiveContainer height={400}>
        <BarChart height={400} data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="groupBy" />
          {isPrecent && (
            <YAxis
              allowDataOverflow
              allowDecimals={false}
              domain={[0, 100]}
              tickFormatter={(value) => toPrecent(value)}
            />
          )}
          {!isPrecent && (
            <YAxis
              tickFormatter={(value) =>
                numStrAbriviaionByDisplayMetric(maxValue, value, displayMetric, overrideCurrency)
              }
            />
          )}
          <Legend />
          <Tooltip />
          {renderBars(data)}
        </BarChart>
      </ResponsiveContainer>
    );
  }
}

const ObserverCostChart = withUserSettingsConsumer(CostChart);

export default ObserverCostChart;
