import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import LinkedAccountsComponent from './LinkedAccountsComponent';
import { ACCOUNT_TYPES, ENTITIES, getIdWithType } from 'users/containers/Organization/consts.js';
import { getIsAccountSelected } from '~/users/containers/Organization/components/LinkedAccounts/dataAccessHelperFunctions.js';
import { cloneDeep } from 'lodash';

const ENABLE_LOGS = false;

function debugLog(...args) {
  if (ENABLE_LOGS === true) {
    /* eslint-disable no-console */
    console.log('LinkedAccountsComponentWrapper.jsx:', ...args);
    /* eslint-enable no-console */
  }
}

const LinkedAccountsComponentWrapper = ({
  additionalData,
  allAccountsData,
  entity = ENTITIES.ROLE.id,
  isCreateEditMode,
  isViewOnly,
  linkedAccountsColumns,
  onRemoveLinkedAccount,
  onSelectionChange,
  payerAccountsColumns,
}) => {
  const transformedAllAccountsData = useMemo(() => {
    if (allAccountsData) {
      // In AWS PayerAccount is also a linked account in this payer account, and it has the same id.
      // In order to distinguish between them - adding a prefix to the payer and linked accounts ids.
      let dataTransformed = cloneDeep(allAccountsData?.accountsData);
      dataTransformed?.forEach((pa) => {
        pa.payerAccount.originalId = pa.payerAccount.id;
        pa.payerAccount.id = getIdWithType(pa.payerAccount.id, ACCOUNT_TYPES.PAYER_ACCOUNT);
        pa.linkedAccounts.forEach((la) => {
          la.originalId = la.id;
          la.id = getIdWithType(la.id, ACCOUNT_TYPES.LINKED_ACCOUNT);
        });
      });
      return { ...allAccountsData, accountsData: dataTransformed };
    }
  }, [allAccountsData]);

  const [fullAccessData, setFullAccessData] = useState(() => {
    let data = {};
    if (allAccountsData?.dataAccessWithFullAccess) {
      data.isFullAccess = !!allAccountsData?.dataAccessWithFullAccess;
      data.isFullAccessInherited =
        allAccountsData?.dataAccessWithFullAccess?.derivedFromRole?.id !== additionalData.roleId;
    }
    return data;
  });

  const accountsDataByCloudProviders = useMemo(() => {
    const data = { [CLOUD_TYPE_IDS.AWS]: [], [CLOUD_TYPE_IDS.AZURE]: [], [CLOUD_TYPE_IDS.GCP]: [] };
    if (transformedAllAccountsData) {
      transformedAllAccountsData?.accountsData?.forEach((account) => {
        switch (+account.payerAccount.cloudType) {
          case CLOUD_TYPE_IDS.AWS:
            data[CLOUD_TYPE_IDS.AWS].push(account);
            break;
          case CLOUD_TYPE_IDS.AZURE:
            data[CLOUD_TYPE_IDS.AZURE].push(account);
            break;
          case CLOUD_TYPE_IDS.GCP:
            data[CLOUD_TYPE_IDS.GCP].push(account);
            break;
          default:
            break;
        }
      });
    }
    /* eslint-disable-next-line no-unused-vars */
    const { dataAccessWithFullAccess: _, ...restAccountsData } = allAccountsData || {};

    return structuredClone({ ...restAccountsData, accountsData: data, fullAccessData: fullAccessData });
  }, [transformedAllAccountsData, allAccountsData, fullAccessData]);

  const handleFullAccessSelectChange = (isSelected) => {
    setFullAccessData({ isFullAccess: isSelected, isFullAccessInherited: false });
    if (isSelected) {
      setModifiedSelectionById({});
    }
  };

  const [modifiedSelectionById, setModifiedSelectionById] = useState({});

  function updateModifiedSelection(newModifiedSelectionById, account, allSelectedIds, childAccounts) {
    const isSelectedNew = allSelectedIds?.includes(account.id);
    const isSelected = getIsAccountSelected(account, newModifiedSelectionById);
    if (!!isSelectedNew !== !!isSelected && account.isSelectionEnabled !== false) {
      debugLog('[updateModifiedSelection] isSelected do not match:', account, 'allSelectedIds:', allSelectedIds);
      if (modifiedSelectionById[account.id] === undefined) {
        newModifiedSelectionById[account.id] = isSelectedNew;
        debugLog('[updateModifiedSelection] newModifiedSelectionById value:', isSelectedNew);
      } else {
        delete newModifiedSelectionById[account.id];
        debugLog('[updateModifiedSelection] deleted newModifiedSelectionById value:', account.id);
      }

      // if payer account is modified by user, we reset all linked accounts modifications
      childAccounts?.forEach((childAccount) => {
        if (getIsAccountSelected(childAccount, newModifiedSelectionById)) {
          if (newModifiedSelectionById[childAccount.id] === true) {
            delete newModifiedSelectionById[childAccount.id];
          } else {
            newModifiedSelectionById[childAccount.id] = false;
          }
        }
      });
    }
  }

  const selectionPayload = useMemo(() => {
    if (!transformedAllAccountsData?.accountsData) {
      return null;
    }

    if (fullAccessData?.isFullAccess === true) {
      return {
        allLinkedAccountsOfAllPayerAccounts: true,
      };
    }

    const payload = {
      accountsToAdd: {
        fullyAssignedPayerAccounts: [],
        payerWithLinkedAccounts: {},
      },
      accountsToRemove: {
        fullyAssignedPayerAccounts: [],
        payerWithLinkedAccounts: {},
      },
      allLinkedAccountsOfAllPayerAccounts: false,
    };

    transformedAllAccountsData?.accountsData.forEach((account) => {
      const { payerAccount, linkedAccounts } = account;
      const modifiedPayerSelection = modifiedSelectionById[payerAccount.id];
      if (modifiedPayerSelection !== undefined) {
        if (modifiedPayerSelection === true && isCreateEditMode === true) {
          payload.accountsToAdd.fullyAssignedPayerAccounts.push(payerAccount.originalId);
        } else {
          payload.accountsToRemove.fullyAssignedPayerAccounts.push(payerAccount.originalId);
        }
      }
      linkedAccounts.forEach((linkedAccount) => {
        const modifiedLinkedSelection = modifiedSelectionById[linkedAccount.id];
        if (modifiedLinkedSelection !== undefined) {
          if (modifiedLinkedSelection === true && isCreateEditMode === true) {
            if (payload.accountsToAdd.payerWithLinkedAccounts[payerAccount.originalId] === undefined) {
              payload.accountsToAdd.payerWithLinkedAccounts[payerAccount.originalId] = [];
            }
            payload.accountsToAdd.payerWithLinkedAccounts[payerAccount.originalId].push(linkedAccount.originalId);
          } else {
            if (payload.accountsToRemove.payerWithLinkedAccounts[payerAccount.originalId] === undefined) {
              payload.accountsToRemove.payerWithLinkedAccounts[payerAccount.originalId] = [];
            }
            payload.accountsToRemove.payerWithLinkedAccounts[payerAccount.originalId].push(linkedAccount.originalId);
          }
        }
      });
    });
    return payload;
  }, [transformedAllAccountsData?.accountsData, fullAccessData?.isFullAccess, modifiedSelectionById, isCreateEditMode]);

  useEffect(() => {
    if (onSelectionChange) {
      onSelectionChange(selectionPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionPayload]);

  const handleSelectionChange = ({ type, allSelectedIds, parentPayerAccount }) => {
    debugLog(
      '[handleSelectionChange] Invoked with type:',
      type,
      'allSelectedIds:',
      allSelectedIds,
      'parentPayerAccount:',
      parentPayerAccount,
    );

    const newModifiedSelectionById = { ...modifiedSelectionById };
    transformedAllAccountsData?.accountsData.forEach((account) => {
      const { payerAccount, linkedAccounts } = account;
      if (type === ACCOUNT_TYPES.PAYER_ACCOUNT) {
        updateModifiedSelection(newModifiedSelectionById, payerAccount, allSelectedIds, linkedAccounts);
      } else if (type === ACCOUNT_TYPES.LINKED_ACCOUNT && parentPayerAccount?.id === payerAccount.id) {
        linkedAccounts?.forEach((linkedAccount) => {
          updateModifiedSelection(newModifiedSelectionById, linkedAccount, allSelectedIds);
        });
      }
    });
    setModifiedSelectionById(newModifiedSelectionById);

    debugLog('[handleSelectionChange] modifiedSelectionById:', modifiedSelectionById);
  };

  const handleSelectAllChange = (selectAllInfo) => {
    debugLog('[handleSelectAllChange]', selectAllInfo);

    const newModifiedSelectionById = {};
    transformedAllAccountsData?.accountsData.forEach((account) => {
      const { payerAccount, linkedAccounts } = account;
      if (selectAllInfo?.[ACCOUNT_TYPES.PAYER_ACCOUNT]?.length) {
        updateModifiedSelection(
          newModifiedSelectionById,
          payerAccount,
          selectAllInfo[ACCOUNT_TYPES.PAYER_ACCOUNT],
          linkedAccounts,
        );
      }
      if (selectAllInfo?.[ACCOUNT_TYPES.LINKED_ACCOUNT]?.length) {
        selectAllInfo[ACCOUNT_TYPES.LINKED_ACCOUNT]?.forEach((account) => {
          account.linkedAccounts.forEach((linkedAccount) => {
            updateModifiedSelection(
              newModifiedSelectionById,
              linkedAccount,
              account.linkedAccounts?.map((la) => la.id),
            );
          });
        });
      }
    });
    setModifiedSelectionById(newModifiedSelectionById);

    debugLog('[handleSelectionChange] modifiedSelectionById:', modifiedSelectionById);
  };

  const handleRemoveLinkedAccount = (accountToRemove, type, payerAccount) => {
    let payload = {};

    if (type) {
      payload = {
        accountsToRemove: {
          fullyAssignedPayerAccounts: [],
          payerWithLinkedAccounts: {},
        },
      };
      if (type === ACCOUNT_TYPES.PAYER_ACCOUNT) {
        payload.accountsToRemove.fullyAssignedPayerAccounts.push(accountToRemove.originalId);
      } else if (type === ACCOUNT_TYPES.LINKED_ACCOUNT) {
        payload.accountsToRemove.payerWithLinkedAccounts[payerAccount.originalId] = [accountToRemove.originalId];
      }
    } else {
      // If removing from the Linked Accounts Component header - need to create a payload for BE api
      payload = selectionPayload;
    }

    onRemoveLinkedAccount(payload);
  };

  return (
    accountsDataByCloudProviders && (
      <LinkedAccountsComponent
        entity={entity}
        additionalData={additionalData}
        allAccountsData={accountsDataByCloudProviders}
        isCreateEditMode={isCreateEditMode}
        isViewOnly={isViewOnly}
        linkedAccountsColumns={linkedAccountsColumns}
        modifiedSelectionById={modifiedSelectionById}
        onSelectionChange={handleSelectionChange}
        onSelectAllChanged={handleSelectAllChange}
        onFullAccessSelectChanged={handleFullAccessSelectChange}
        onRemoveLinkedAccount={handleRemoveLinkedAccount}
        payerAccountsColumns={payerAccountsColumns}
      />
    )
  );
};

LinkedAccountsComponentWrapper.propTypes = {
  additionalData: PropTypes.object,
  allAccountsData: PropTypes.object,
  entity: PropTypes.oneOf(Object.values(ENTITIES).map((e) => e.id)),
  isCreateEditMode: PropTypes.bool,
  isViewOnly: PropTypes.bool,
  linkedAccountsColumns: PropTypes.array,
  onRemoveLinkedAccount: PropTypes.func,
  onChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  payerAccountsColumns: PropTypes.array,
};

export default LinkedAccountsComponentWrapper;
