import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  AMORTIZE_COST_TYPE_TO_NAME,
  AMORTIZE_COST_TYPES_ITEMS,
  AZURE_FOCUS_COST_TYPES_ITEMS,
  calcRateValue,
  CARBON_EMISSION_TO_UNIT,
  CARBON_EMISSIONS_ITEMS,
  CostTypeModes,
  mapDisplayUsageQuantityTypeToMetricType,
  mapGranularityToDisplayName,
} from 'usage/constants/costAndUsageConstants';
import { ReactComponent as CarbonEmissionsIllustration } from './assets/carbon-emissions.svg';
import { ReactComponent as InfoiIcon } from 'shared/img/icons/info.svg';
import { ReactComponent as EditIcon } from 'shared/img/icons/edit.svg';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import Tooltip from '../../andtComponents/Tooltip';
import styles from './TotalChartValueLabel.module.scss';
import { maxNumSizeAbbreviation } from '../../../utils/strUtil';

const TITLE_BY_COST_TYPE = (costLabel, usageSelected, carbonEmissionLabel, granularity) => ({
  [CostTypeModes.COST]: costLabel ? `Total Cost: ${costLabel}` : 'Total Cost',
  [CostTypeModes.USAGE]: usageSelected ? `Total Usage: ${usageSelected}` : 'Total Usage',
  [CostTypeModes.RATE]: 'Avg. Rate',
  [CostTypeModes.GOAL]: `${mapGranularityToDisplayName.get(granularity) || ''} Goal`,
  [CostTypeModes.CARBON_EMISSIONS]: carbonEmissionLabel,
});

const TotalChartValueLabel = ({
  dataForRate = null,
  totalCost,
  goalName = '',
  costTypeMode,
  costSelected,
  usageSelected,
  displayedMetric,
  goalReached = false,
  subTitle = null,
  granularity = '',
  editHandler = null,
  costOptions = [],
  carbonEmissionsOptions = [],
  carbonEmissionsValue = '',
}) => {
  const sum =
    costTypeMode === CostTypeModes.RATE
      ? calcRateValue(dataForRate.totalCostSum, dataForRate.totalUsageSum, usageSelected)
      : totalCost;
  const { numStrAbriviaionByDisplayMetric, getCurrencyNumber } = useUserSettingsContext();
  const sumLabel = (() => {
    if (costTypeMode === CostTypeModes.COST) {
      return getCurrencyNumber(sum || 0, 2, {});
    }
    if (costTypeMode === CostTypeModes.RATE) {
      return maxNumSizeAbbreviation(sum || 0, parseFloat(sum || 0), 4);
    }
    if (costTypeMode === CostTypeModes.CARBON_EMISSIONS) {
      return (
        <>
          {numStrAbriviaionByDisplayMetric(sum || 0, sum || 0, displayedMetric, null)}{' '}
          <span className={styles.subTitle}>{CARBON_EMISSION_TO_UNIT[carbonEmissionsValue]}</span>
        </>
      );
    }
    return numStrAbriviaionByDisplayMetric(sum || 0, sum || 0, displayedMetric, null);
  })();
  const isCarbonEmissions = costTypeMode === CostTypeModes.CARBON_EMISSIONS;
  const isRateUsageBased =
    costTypeMode === CostTypeModes.RATE && AMORTIZE_COST_TYPE_TO_NAME[costSelected] === undefined;
  const costLabel =
    costOptions &&
    costSelected &&
    (
      [...Object.values(AMORTIZE_COST_TYPES_ITEMS), ...Object.values(AZURE_FOCUS_COST_TYPES_ITEMS)].find(
        (i) => i.value === costSelected,
      ) || {}
    ).name;
  const carbonEmissionLabel = carbonEmissionsOptions?.find((i) => i.value === carbonEmissionsValue)?.name;

  const title = TITLE_BY_COST_TYPE(costLabel, usageSelected, carbonEmissionLabel, granularity)[costTypeMode];

  if (isCarbonEmissions) {
    const config = Object.values(CARBON_EMISSIONS_ITEMS).find((i) => i.value === carbonEmissionsValue);
    return (
      <div className={classNames(styles.container, styles.carbonEmissionsContainer)}>
        <div>
          <CarbonEmissionsIllustration />
        </div>
        <div>
          <h5>
            {title}
            {config?.tooltip ? (
              <Tooltip title={config.tooltip} arrow>
                <span className={styles.icon}>
                  <InfoiIcon />
                </span>
              </Tooltip>
            ) : null}
          </h5>
          <span className={styles.number}>{sumLabel}</span>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <h5>
        {title}
        {costTypeMode === CostTypeModes.GOAL && (
          <Tooltip
            title={
              <>
                {goalName}
                <br />
                {TITLE_BY_COST_TYPE(costLabel, usageSelected, granularity)[costTypeMode]}: {sumLabel}
                <br />
                {subTitle}
              </>
            }
            arrow
          >
            <span className={styles.icon}>
              <InfoiIcon />
            </span>
          </Tooltip>
        )}
        {(costTypeMode === CostTypeModes.RATE || costTypeMode === CostTypeModes.USAGE) && (
          <Tooltip
            title={`Any data that is not measurable by ${usageSelected}${
              isRateUsageBased ? ` or ${costSelected}` : ''
            } has been filtered out.`}
            arrow
          >
            <span className={styles.icon}>
              <InfoiIcon />
            </span>
          </Tooltip>
        )}
        {editHandler && (
          <Tooltip title="Edit Goal">
            <span onClick={editHandler} className={styles.editGoalIcon}>
              <EditIcon />
            </span>
          </Tooltip>
        )}
      </h5>
      <span className={styles.number}>
        {sumLabel}
        {costTypeMode === CostTypeModes.GOAL && (goalReached ? <CheckIcon /> : <ArrowDownIcon />)}
      </span>
      {costTypeMode === CostTypeModes.RATE && (
        <p className={styles.subtitle}>
          {!isRateUsageBased ? (
            <>
              {costLabel}: {getCurrencyNumber(dataForRate.totalCostSum, 2)}
            </>
          ) : (
            <>
              {costSelected}:{' '}
              {numStrAbriviaionByDisplayMetric(
                dataForRate.totalCostSum,
                dataForRate.totalCostSum,
                mapDisplayUsageQuantityTypeToMetricType.get(costSelected),
                null,
              )}
            </>
          )}{' '}
          / {usageSelected}:{' '}
          {numStrAbriviaionByDisplayMetric(
            dataForRate.totalUsageSum,
            dataForRate.totalUsageSum,
            mapDisplayUsageQuantityTypeToMetricType.get(usageSelected),
            null,
          )}
        </p>
      )}
      {subTitle && <p className={styles.subtitle}>{subTitle}</p>}
    </div>
  );
};

TotalChartValueLabel.propTypes = {
  dataForRate: PropTypes.object,
  totalCost: PropTypes.number.isRequired,
  costTypeMode: PropTypes.string.isRequired,
  costSelected: PropTypes.string.isRequired,
  usageSelected: PropTypes.string.isRequired,
  displayedMetric: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  granularity: PropTypes.string,
  editHandler: PropTypes.func,
  goalName: PropTypes.string,
  goalReached: PropTypes.bool,
  costOptions: PropTypes.array,
  carbonEmissionsOptions: PropTypes.array,
  carbonEmissionsValue: PropTypes.string,
};

export default TotalChartValueLabel;
