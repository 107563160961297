import { API } from 'shared/utils/apiMiddleware';
import { useMutation } from '@tanstack/react-query';

const monitorClientErrorApi = (data) => API.post('billings', '/api/v1/client-error', { body: data });
const monitorClientErrorMessageApi = (data) => API.post('billings', '/api/v1/client-error-message', { body: data });

export const useClientError = () => {
  return {
    emitClientError: () =>
      useMutation({
        mutationFn: monitorClientErrorApi,
      }),
    emitClientErrorMessage: monitorClientErrorMessageApi,
  };
};
