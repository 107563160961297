import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import Button from 'shared/components/andtComponents/Button';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import PropTypes from 'prop-types';
import { Search } from '../components/Search';
import { useCostCentersContext } from './contexts/costCentersContext';
import AssignCostCentersToRole from './costCentersModals/AssignCostCentersToRole/AssignCostCentersToRole';
import CreateEditCostCenter from 'users/containers/Organization/CostCenters/costCentersModals/CreateEditCostCenter/CreateEditCostCenter';
import OrgToCustomersSwitch from 'users/containers/Organization/components/OrgToCustomersSwitch/OrgToCustomersSwitch';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { fetchDataAccessCostCenters } from 'users/new-user-management/hooks/reactQuery/apiDataAccessAccounts';

import styles from './CostCentersHeader.module.scss';

const CostCentersHeader = ({
  total,
  isRoleDataAccessView,
  roleId,
  isSelectionEnabled,
  onCustomerSelectionChanged,
  onOrgCustomersToggle,
}) => {
  const { isAllExpanded, isCustomersView, search, selectedCustomer, setIsAllExpanded, setSearch } =
    useCostCentersContext();

  const [isCsvDataFetching] = useState(false);

  const fetchExportData = async () => {
    const costCenters = await fetchDataAccessCostCenters();
    if (costCenters) {
      const costCentersData = costCenters.map((cc) => ({
        id: cc.costCenter.id,
        name: cc.costCenter.name,
        creationTime: cc.creationTime,
      }));

      return [{ data: costCentersData, filename: 'costCenters.csv' }];
    }
  };

  const { usersStore } = useRootStore();

  return (
    <div className={styles.headerContainer}>
      <div className={styles.headerLeft}>
        {!isRoleDataAccessView ? (
          <Button
            automationid="expand-rows"
            text=""
            isSecondary
            icon={() => <GenerateIcon iconName={isAllExpanded ? ICONS.collapse.name : ICONS.expand.name} />}
            onClick={() => {
              setIsAllExpanded(!isAllExpanded);
            }}
          />
        ) : null}
        <div className={styles.header}>
          <div className={styles.title}>
            Cost Centers <span className={styles.count}>({total})</span>
          </div>
        </div>
        {!isRoleDataAccessView && usersStore.isCurrentUserReseller ? (
          <OrgToCustomersSwitch
            isCustomersView={isCustomersView}
            onOrgCustomersToggle={onOrgCustomersToggle}
            selectedCustomer={selectedCustomer}
            onCustomerSelectionChanged={onCustomerSelectionChanged}
          />
        ) : null}
      </div>
      <div className={styles.headerRight}>
        {!isRoleDataAccessView && <CreateEditCostCenter />}
        {isRoleDataAccessView && !isSelectionEnabled ? <AssignCostCentersToRole roleId={roleId} /> : null}
        <Search search={search} setSearch={setSearch} isDebounce />
        {!isRoleDataAccessView ? (
          <Tooltip title="Export" arrow>
            <CustomCSVDownload
              fetchData={fetchExportData}
              automationid="export-cost-centers"
              showDownloadIcon
              isLoading={isCsvDataFetching}
              filesNumber={1}
              isSecondary
              isLightButton
              hideText
              style={{ height: 36 }}
            />
            <span />
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};

CostCentersHeader.propTypes = {
  isRoleDataAccessView: PropTypes.object,
  isSelectionEnabled: PropTypes.bool,
  onCustomerSelectionChanged: PropTypes.func,
  onOrgCustomersToggle: PropTypes.func,
  roleId: PropTypes.string,
  total: PropTypes.number.isRequired,
};

export default CostCentersHeader;
