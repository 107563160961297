import React, { useMemo } from 'react';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';

import styles from './CustomerExpanded.module.scss';
import { useMspCustomers } from 'users/new-user-management/hooks/reactQuery/useMspCustomers.js';
import { getInitialAccountsDataWithCounters } from 'users/containers/Organization/components/LinkedAccounts/dataAccessHelperFunctions.js';
import LinkedAccountsComponentWrapper from 'users/containers/Organization/components/LinkedAccounts/LinkedAccountsComponentWrapper.jsx';
import {
  ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS,
  ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS,
} from 'users/containers/Organization/consts.js';
import { groupBy } from 'lodash';
import Spinner from 'shared/components/andtComponents/Spinner.jsx';

const CustomerExpanded = ({ row }) => {
  const { fetchDataAccessPayerAccounts } = useDataAccessAccounts();
  const { data: accountsData, isLoading: accountsLoading } = fetchDataAccessPayerAccounts();

  const { fetchCustomerAccounts, updateCustomer } = useMspCustomers();
  const { data: accounts, isLoading } = fetchCustomerAccounts(row);

  const dataToDisplay = useMemo(() => {
    if (!accountsData || !accounts) {
      return null;
    }
    const combinedData = getInitialAccountsDataWithCounters(accountsData);

    const groupedByPayer = groupBy(accounts.linked_accounts, 'payerAccountId');

    return {
      ...combinedData,
      accountsData: [
        ...accounts.fullyDedicatedPayerAccounts.map((p) => ({
          payerAccount: p,
          linkedAccounts: [],
          totalPayerLinkedAccounts: p.linkedAccountsCount,
          totalSelectedPayerAccounts: p.linkedAccountsCount,
        })),
        ...Object.values(groupedByPayer).map((lns) => {
          const payerId = lns[0].payerAccountId;
          const payer = accountsData.find((a) => a.payerAccount.id === payerId);
          return {
            payerAccount: payer.payerAccount,
            linkedAccounts: lns.map((l) => l.linked_account),
            totalPayerLinkedAccounts: payer.payerAccount.linkedAccountsCount,
            totalSelectedPayerAccounts: lns.length,
          };
        }),
      ],
    };
  }, [accountsData, accounts, row?.id]);

  const handleRemoveLinkedAccounts = async (payload) => {
    return updateCustomer.mutateAsync({
      ...row,
      accounts: { accountsToRemove: payload.accountsToRemove },
    });
  };

  if (accountsLoading || isLoading) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <LinkedAccountsComponentWrapper
        allAccountsData={dataToDisplay}
        onRemoveLinkedAccount={handleRemoveLinkedAccounts}
        entity="customer"
        linkedAccountsColumns={Object.values(ROLE_DATA_ACCESS_LINKED_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
        payerAccountsColumns={Object.values(ROLE_DATA_ACCESS_PAYER_ACCOUNTS_COLUMNS).map((c) => c.columnName)}
        additionalData={{ roleId: row?.id }}
      />
    </div>
  );
};

export default CustomerExpanded;
