import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import OneChoiceFieldFilter from 'shared/components/OneChoiceFieldFilter';
import Button from 'shared/components/andtComponents/Button';

const propTypes = {
  commitmentServiceOptions: PropTypes.array.isRequired,
  linkedAccountOptions: PropTypes.array.isRequired,
  selectedCommitmentService: PropTypes.string.isRequired,
  payerAccountOptions: PropTypes.string.isRequired,
  selectedLinkedAccount: PropTypes.string.isRequired,
  selectedPayerAccount: PropTypes.string.isRequired,
  getCommitmentTableData: PropTypes.object.isRequired,
  handleFilterChange: PropTypes.object.isRequired,
  isGetTableDataButtonActive: PropTypes.bool.isRequired,
  isAllAccounts: PropTypes.bool.isRequired,
  isAws: PropTypes.bool.isRequired,
};

const createOptions = (fieldValues) =>
  !fieldValues
    ? [{ value: '', label: '' }]
    : fieldValues.map((fieldValue) => {
        let value = '';
        let label = '';
        if (Object.prototype.toString.call(fieldValue) === '[object Object]' && 'linkedAccountId' in fieldValue) {
          value = fieldValue.linkedAccountId;
          label = fieldValue.displayLabel;
        } else {
          value = fieldValue;
          label = fieldValue;
        }
        return { value, label };
      });

const FilterBar = ({
  commitmentServiceOptions,
  linkedAccountOptions,
  selectedCommitmentService,
  payerAccountOptions,
  selectedLinkedAccount,
  selectedPayerAccount,
  getCommitmentTableData,
  handleFilterChange,
  isGetTableDataButtonActive,
  isAllAccounts,
  isAws,
}) => (
  <Row style={{ alignItems: 'center', width: '100%' }}>
    {isAws && (
      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
        <div className="filter-bar-inner-col-wrapper-large">
          <h5 className="filter-bar-inner-col-title">Commitment Service (*)</h5>
          <OneChoiceFieldFilter
            type="tempSelectedCommitmentService"
            value={selectedCommitmentService}
            options={commitmentServiceOptions}
            handleChange={handleFilterChange}
            isClearable
          />
        </div>
      </Col>
    )}
    {isAllAccounts ? (
      <Col xl={4} lg={4} md={6} sm={6} xs={12}>
        <div className="filter-bar-inner-col-wrapper-large">
          <h5 className="filter-bar-inner-col-title">Payer Account</h5>
          <OneChoiceFieldFilter
            type="selectedPayerAccount"
            value={selectedPayerAccount}
            options={createOptions(payerAccountOptions)}
            handleChange={handleFilterChange}
            isClearable
          />
        </div>
      </Col>
    ) : null}
    <Col xl={4} lg={4} md={6} sm={6} xs={12}>
      <div className="filter-bar-inner-col-wrapper-large">
        <h5 className="filter-bar-inner-col-title">{isAws ? 'Linked Account' : 'Subscription'}</h5>
        <OneChoiceFieldFilter
          type="selectedLinkedAccount"
          value={selectedLinkedAccount}
          options={createOptions(linkedAccountOptions)}
          handleChange={handleFilterChange}
          isClearable
          isLoading={linkedAccountOptions && linkedAccountOptions.length === 0}
        />
      </div>
    </Col>
    <Col xl={3} lg={3} md={6} sm={6} xs={12} style={{ paddingTop: '10px' }}>
      <Button
        onClick={getCommitmentTableData}
        disabled={!isGetTableDataButtonActive}
        text="Apply"
        automationid="RIUtilization-apply"
      />
    </Col>
  </Row>
);

FilterBar.propTypes = propTypes;

export default FilterBar;
