import React from 'react';

/* eslint-disable max-len */
export const getOnboardingError = ({ code }) =>
  ({
    ERR_HANDSHAKE_GCP_BQ_CLIENT_NOT_CREATED: (
      <>
        The <b>Service Account Key file</b> uploaded in the 'GCP Details' step is incorrect or malformed. Please upload
        the correct file and continue with the onboarding steps.
      </>
    ),
    ERR_HANDSHAKE_GCP_BQ_PROJECT_NOT_FOUND: (
      <>
        It seems like the <b>project ID</b> you entered in the 'GCP Details' step is incorrect. Please update the
        correct Project ID and continue with the onboarding steps.
      </>
    ),
    ERR_HANDSHAKE_GCP_BQ_TABLE_NOT_FOUND: (
      <>
        It seems like the <b>table name</b> you entered in the 'GCP Details' step is incorrect. Please update the
        correct Project ID and continue with the onboarding steps.
      </>
    ),
    ERR_HANDSHAKE_GCP_BQ_TABLE_FORBIDDEN: (
      <>
        <p>We do not have permission to query your billing dataset. Please check the following:</p>
        <ol>
          <li>
            Your Service Account has the <b>"BigQuery Job User"</b> role.
          </li>
          <li>
            The <b>"BigQuery Data Viewer"</b> role is assigned to your billing dataset and linked to your Service
            Account principal.
          </li>
          <li>The values entered in the 'GCP Details' step are correct.</li>
        </ol>
        <p>After correcting the configuration, continue with the onboarding steps.</p>
      </>
    ),
    ERR_HANDSHAKE_FLAG_NOT_RECEIVED: (
      <>
        Please ensure all required information is provided, then start the validation by clicking the <b>Validate</b>{' '}
        button in the <b>K8s Integration</b> step.
      </>
    ),
    ERR_HANDSHAKE_GCP_NO_RECORDS:
      'Your billing dataset table does not seem to have updated data. Please check this in BigQuery, and once the data is available continue with the onboarding steps.',
  }[code]);
