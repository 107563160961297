import React from 'react';
import CommitmentData from 'app/containers/MainDashboard/panels/MonitoringData/CommitmentData.jsx';
import AnomaliesData from 'app/containers/MainDashboard/panels/MonitoringData/AnomaliesData.jsx';
import BudgetsData from 'app/containers/MainDashboard/panels/MonitoringData/BudgetsData.jsx';
import SavingsData from 'app/containers/MainDashboard/panels/MonitoringData/SavingsData.jsx';
import styles from './monitoringData.module.scss';

const MonitoringDataWrapper = () => {
  return (
    <div className={styles.monitoringDataWrapper}>
      <div className={styles.flexRow}>
        <AnomaliesData />
        <div className={styles.flexWrapper}>
          <BudgetsData />
          <CommitmentData />
        </div>
      </div>
      <SavingsData />
    </div>
  );
};

export default MonitoringDataWrapper;
