import React from 'react';
import styles from './Stepper.module.scss';

export interface Step {
  id: string | number;
  title: string;
  isDotted?: boolean;
  subTitle?: string;
}

interface StepperProps {
  steps: Step[];
  activeStepIndex: number;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStepIndex }) => (
  <div className={styles.container} key={activeStepIndex}>
    <div className={styles.progressLine}>
      <div
        style={{
          width:
            activeStepIndex === steps.length - 1
              ? '100%'
              : `calc(50% - ${(110 * steps.length) / 2 - 110 * (activeStepIndex + 1)}px)`,
        }}
      />
    </div>
    <div className={styles.steps}>
      {steps.map((step, index) => (
        <div
          key={`${step.id}${index}`}
          data-active={activeStepIndex === index}
          data-done={activeStepIndex > index}
          data-dotted={step.isDotted}
          className={styles.step}
        >
          <div className={styles.stepCounter}>
            <span>{index + 1}</span>
          </div>
          <div className={styles.stepTitle}>{step.title}</div>
          {step.subTitle && <div className={styles.stepSubTitle}>{step.subTitle}</div>}
        </div>
      ))}
    </div>
  </div>
);

export default Stepper;
