import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { GenerateIcon, ICONS, Navbar, BIZ_PACK_OPTIONS } from '@pileus-cloud/anodot-frontend-common';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import AppLogo from 'app/containers/Layout/topNavbar/components/AppLogo';
import ScopePicker from 'app/containers/Layout/topNavbar/components/ScopePicker';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { Routes } from 'shared/constants/routes';
import Tooltip from 'shared/components/andtComponents/Tooltip';
import MarginPicker from 'app/containers/Layout/topNavbar/components/MarginPicker';
import UserSettings from 'app/containers/Layout/topNavbar/components/UserSettings';
import AccountPicker from 'app/containers/Layout/topNavbar/components/AccountPicker';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner';
import HolidayUnderHeaderRightIcon from 'shared/components/HolidayThemedIcons/HolidayUnderHeaderRightIcon';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext';
import Notifications from 'usage/containers/Notifications/Notifications';
import { useNotifications } from 'usage/hooks/react-query/useNotifications';
import { CURRENCY_DETAILS } from 'shared/constants/appConstants';
import useDebouncedInput from 'shared/hooks/customHooks/useDebouncedInput';
import { useCostCenters } from 'users/new-user-management/hooks/reactQuery/useCostCenters';
import { useMspCustomers } from 'users/new-user-management/hooks/reactQuery/useMspCustomers';
import { useDbFlags } from 'usage/hooks/react-query/useDbFlags';
import { Action, OrganizationEntityCategory } from '@anodot-cost/rbac-client';
import { palette } from 'shared/constants/colorsConstants.js';

import styles from 'app/containers/Layout/topNavbar/TopNavBar.module.scss';
import classnames from 'classnames';
import { useBrand } from 'app/contexts/Brand/BrandContext';
import { BrandTypes, Themes } from 'app/contexts/Brand/enums';

const TopNavbar = (props) => {
  const brand = useBrand();
  const { childProps, currDisplayedAccount } = props;
  const { usersStore, appStore, invoiceStore, usageStore, invalidateStores, vendorMappingsStore } = useRootStore();
  const [notificationsOpened, setNotificationsOpened] = useState(false);
  const { fetchNotifications } = useNotifications();
  const { currDispUserAccountKey } = usersStore;
  const [lastFetchedAccountKey] = useDebouncedInput(currDispUserAccountKey, 500);
  const { fetchCostCentersData } = useCostCenters();
  const { fetchCurrAccountCustomersData } = useMspCustomers();
  const { fetchDbFlags } = useDbFlags();
  const location = useLocation();
  const navigate = useNavigate();

  const { data: notifications = [], isLoading: notificationsLoading } = fetchNotifications({
    enabled: !!usersStore.authUserKey && !!currDisplayedAccount && currDispUserAccountKey === lastFetchedAccountKey,
  });
  const { currencyCode, currencySymbol } = useUserSettingsContext();
  const { data: dbFlagsData } = fetchDbFlags({
    enabled: !!usersStore.currentDisplayedUserKey && !!usersStore.currDispUserAccountKey,
  });

  const handleChangeToCustomerView = (divId, divisionName, accountKey, isScopeSwitch = false) => {
    usersStore.deprecatedChangeCustomerUserType();
    appStore.updatePricingMode(true);
    usersStore.fetchDivisionGroups();
    usersStore.updateCurrDisplayedDivIdAndName(divId, divisionName, accountKey, true);
    if (isScopeSwitch) {
      invalidateStores(true, true);
    }
    const currentPath = location.pathname;
    navigate(currentPath);
  };

  const getScopeData = () => {
    /* TODO - when move all the application to use keycloak and accounts service,
    change this logic to support impersonation */
    if (appStore.isKeyCloakManagement) {
      const { isLoading: isCostCentersLoading, data: costCentersData } = fetchCostCentersData();
      const { isLoading: isMspCustomersLoading, data: mspCustomersData } = fetchCurrAccountCustomersData({
        enabled: usersStore.isHasPermission(OrganizationEntityCategory.ResellerCustomers, Action.View),
      });
      return {
        isLoading: isCostCentersLoading || isMspCustomersLoading,
        data: [...(costCentersData || []), ...(mspCustomersData || [])],
      };
    }
    const { divisionList } = usersStore.usersModel;
    return { data: divisionList };
  };

  const renderTrialDaysText = ({ trialDaysLeft }) => {
    const trialDaysText =
      trialDaysLeft !== 0 ? `Evaluation expires in ${trialDaysLeft} days` : 'Your free trial has expired';
    if (usersStore.userEndTrial) {
      return <div className={styles.menuItem}>{trialDaysText}</div>;
    }
    return null;
  };

  const RenderNavbar = () => {
    const { currDispUserAccountKey, currDispUserCloudAccountType, getAllCurrDisplayedUserAccounts, trialDaysLeft } =
      usersStore;

    const accounts = getAllCurrDisplayedUserAccounts().filter(
      (acc) => !vendorMappingsStore.doesCloudTypeIdBelongToByod(acc.cloudTypeId),
    );

    const { isLoading: isScopeLoading, data: scopeListData } = getScopeData();
    const cloudTypeId = usersStore.currDispUserCloudAccountType;
    const marginPickerClass = { ...styles, item: styles.marginPickerItem, tooltip: styles.tooltip };

    const scopesList = useMemo(() => {
      return scopeListData?.map(({ divisionName, divisionNameDisplay, name, divisionId, id, ...rest }) => ({
        ...rest,
        name: name || divisionName,
        nameDisplay: name || divisionNameDisplay || divisionName,
        id: id || divisionId,
        parentId: rest.parentId || null,
      }));
    }, [scopeListData]);

    const showCostTextInTitle =
      !usersStore.currentHoliday &&
      usersStore.getCurrDisplayedUserTheme() === Themes.Default &&
      brand.name === BrandTypes.Anodot;

    const logoText = showCostTextInTitle ? BIZ_PACK_OPTIONS.cloudCost.value : '';

    return (
      <Navbar
        logo={<AppLogo />}
        value={logoText}
        onClick={() => {
          navigate(currDisplayedAccount ? Routes.DASHBOARD : Routes.ACCOUNTS_OVERVIEW);
        }}
         
        tooltip={`App version: ${process.env.VITE_APP_VERSION}-${dbFlagsData?.isBigQueryRead ? 'BQ' : 'PG'}`}
      >
        <div className={styles.topbarDropdowns}>
          {appStore.isAccountLoading ? (
            <div style={{ position: 'relative', width: '50px', height: '100%' }}>
              <Spinner size={SPINNER_SIZES.SMALL} />
            </div>
          ) : null}
          {renderTrialDaysText({
            trialDaysLeft,
          })}
          {scopesList?.length && currDisplayedAccount && cloudTypeId !== CLOUD_TYPE_IDS.MULTI ? (
            <div className={styles.menuItem}>
              {isScopeLoading ? (
                <Spinner size={20} color="white" />
              ) : (
                <ScopePicker
                  classes={styles}
                  handleBackToCurrDisplayedAccount={usersStore.handleBackToCurrDisplayedAccount}
                  currentDisplayedCustomer={usersStore.currDispUserDivisionName}
                  currentDisplayedCloudTypeId={usersStore.currDispUserCloudAccountType}
                  accountsList={[currDisplayedAccount]}
                  scopesList={scopesList}
                  handleChangeCustomer={handleChangeToCustomerView}
                  currAccountDivisionId={usersStore.getCurrAccountDivisionId()}
                  currDispUserDivisionId={usersStore.currDispUserDivisionId}
                  isAccountLoading={appStore.isAccountLoading}
                />
              )}
            </div>
          ) : null}
          {usersStore.isCurrentUserReseller ? (
            <div className={styles.menuItem}>
              <MarginPicker classes={marginPickerClass} />
            </div>
          ) : null}
          {!usersStore.isCurrentUserNew ? (
            <div className={styles.menuItem}>
              <AccountPicker
                classes={styles}
                handleAccountChange={usersStore.handleDisplayedAccountChange}
                handleChangeCustomer={handleChangeToCustomerView}
                currentDisplayedAccount={usersStore.getCurrentDisplayedAccountName(currDispUserAccountKey)}
                currentDisplayedCloudTypeId={currDispUserCloudAccountType}
                accountsList={accounts}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.divider} />
        {!usersStore.isCurrentUserNew && currDisplayedAccount ? (
          <div className={styles.roundButton}>
            {currencyCode || CURRENCY_DETAILS.USD} ({currencySymbol})
          </div>
        ) : null}
        {currDisplayedAccount && (
          <div
            className={`${styles.roundButton} ${styles.notificationsButton}`}
            onClick={() => setNotificationsOpened(true)}
          >
            <div
              className={styles.notificationsCounter}
              data-empty={notifications?.length === 0}
              data-loading={notificationsLoading}
            >
              {notificationsLoading ? (
                <Spinner size={20} color={palette.blue[500]} />
              ) : (
                <GenerateIcon iconName={ICONS.bell.name} />
              )}
            </div>
            <span>{notificationsLoading ? '-' : notifications?.length}</span>
          </div>
        )}
        {notificationsOpened && (
          <Notifications notifications={notifications} onClose={() => setNotificationsOpened(false)} />
        )}
        <Tooltip title={`${appStore.isKeyCloakManagement ? 'Organization' : 'Account'} settings`}>
          <div
            className={classnames(styles.roundButton, styles.settingsButton)}
            onClick={() => {
              navigate({
                pathname: appStore.isKeyCloakManagement ? Routes.ORGANIZATION : Routes.ACCOUNT,
              });
            }}
          >
            <GenerateIcon iconName={ICONS.gearRegular.name} />
          </div>
        </Tooltip>
        {usersStore.usersModel.users.length !== 0 ? (
          <UserSettings
            classes={styles}
            currDisplayedAccount={currDisplayedAccount}
            handleLogout={childProps.handleLogout}
            invoiceStoreLoad={invoiceStore.isLoading}
            usageStoreLoad={usageStore.isLoading}
          />
        ) : null}
        {usersStore.getCurrDisplayedUserTheme() ? (
          <HolidayUnderHeaderRightIcon holiday={usersStore.currentHoliday} />
        ) : null}
      </Navbar>
    );
  };

  return RenderNavbar();
};

TopNavbar.propTypes = {
  childProps: PropTypes.object.isRequired,
  currDisplayedAccount: PropTypes.object,
};

const TopbarObserver = observer(TopNavbar);
export default TopbarObserver;
