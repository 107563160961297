import React from 'react';
import PropTypes from 'prop-types';
import { Y_AXIS_UNIT } from './chartConsts';
import GenericChart from './genericChart';
import { useBrand } from 'app/contexts/Brand/BrandContext';
import { LINE_NAMES } from './seriesDataChart.constants';

const SeriesDataChart = ({ data, yAxisLabel, yAxisUnit = Y_AXIS_UNIT.OTHER, effectiveTimeFrame }) => {
  const brand = useBrand();
  if (!data?.length) {
    return (
      <div className="mb-4">
        <brand.noData className="m-auto d-flex" />
      </div>
    );
  }

  const getLinesList = () => {
    const linesList = [
      {
        id: LINE_NAMES.MAX,
        label: `Max ${yAxisLabel}`,
      },
      {
        id: LINE_NAMES.AVG,
        label: `Avg ${yAxisLabel}`,
      },
      {
        id: LINE_NAMES.PERCENTILE,
        label: `95th Percentile ${yAxisLabel}`,
      },
      {
        id: LINE_NAMES.ESTIMATED,
        label: `Estimated ${yAxisLabel}`,
      },
      {
        id: LINE_NAMES.PERCENTILE99,
        label: `99th Percentile ${yAxisLabel}`,
      },
      {
        id: LINE_NAMES.REQUEST,
        label: `Request ${yAxisLabel}`,
      },
    ];

    return linesList.filter((line) => line?.id && data?.[0] && line.id in data[0]);
  };

  return (
    <GenericChart
      data={data}
      yAxisLabel={yAxisLabel}
      yAxisUnit={yAxisUnit}
      linesList={getLinesList()}
      effectiveTimeFrame={effectiveTimeFrame}
    />
  );
};

SeriesDataChart.propTypes = {
  data: PropTypes.object.isRequired,
  yAxisLabel: PropTypes.string.isRequired,
  yAxisUnit: PropTypes.string,
};

export default SeriesDataChart;
