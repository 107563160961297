import React from 'react';
import styles from './Login.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const LeftSideIllustration = () => {
  const brand = useBrand();
  return (
    <div>
      <brand.leftSideIllustration styles={styles} />
    </div>
  );
};

export default LeftSideIllustration;
