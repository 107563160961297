import React, { useState } from 'react';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import commonStyles from '../Pages.module.scss';
import styles from './InstructionsPage.module.scss';

const InstructionsPage = () => {
  const [step, setStep] = useState(1);
  return (
    <div className={styles.container}>
      <p className={commonStyles.description}>Watch the tutorial for step-by-step instructions.</p>
      <div className={styles.content}>
        <div className={styles.selectorList}>
          <div onClick={() => setStep(1)} className={styles.selector} data-active={step === 1}>
            <span className={styles.badge}>1</span>
            Create an App Registration <GenerateIcon className={styles.icon} iconName={ICONS.chevronRight.name} />
          </div>
          <div onClick={() => setStep(2)} className={styles.selector} data-active={step === 2}>
            <span className={styles.badge}>2</span> Create Billing Export
            <GenerateIcon className={styles.icon} iconName={ICONS.chevronRight.name} />
          </div>
        </div>
        <video width={560} height={316} controls="controls" className={styles.video}></video>
      </div>
    </div>
  );
};

export default InstructionsPage;
