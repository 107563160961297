import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Input from 'shared/components/andtComponents/Input';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getCurrenciesSymbol } from 'users/containers/Onboarding/shared/components/UserCurrency/currencySymbolsHelper';
import Select from 'shared/components/andtComponents/SimpleSelect';
import commonStyles from '../Pages.module.scss';
import GcpDetailsPageHelp from './GcpDetailsPageHelp';
import { GCP_ONBOARDING_FIELDS } from '../../GcpOnboardingConstants';
import styles from './GcpDetailsPage.module.scss';
import { getTenantParams } from '../../../utils/OnboardingUtils';
import { AZURE_ONBOARDING_FIELDS } from '../../../AzureOnboarding/AzureOnboardingConstants';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const GcpDetailsPage = () => {
  const {
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();
  const brand = useBrand();
  const { usersStore } = useRootStore();
  const [tempFileName, setTempFileName] = useState('');
  useWatch(GCP_ONBOARDING_FIELDS.ACCOUNT_NAME);
  const accountName = getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_NAME);
  useEffect(() => {
    const { tenantName, tenantSchemaName } = getTenantParams(usersStore.currentDisplaydUser, accountName);
    setValue(GCP_ONBOARDING_FIELDS.TENANT_NAME, tenantName);
    setValue(GCP_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME, tenantSchemaName);
  }, [accountName]);
  const currencies = useMemo(
    () =>
      getCurrenciesSymbol().map(({ key }) => ({
        value: key,
        label: key,
      })),
    [],
  );
  const renderInput = ({ fieldName, name, label, icon }) => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            value={getValues(fieldName)}
            isInvalid={errors?.[fieldName] && errors?.[fieldName] !== 'GeneralError'}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={(e) => {
              setValue(fieldName, e.target.value);
              clearErrors(fieldName);
            }}
            placeholder=""
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 10,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        list={[
          {
            render: () => {
              return renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.ACCOUNT_NAME,
                name: 'onboarding-account-name',
                label: `Account name displayed in ${brand.name}`,
                icon: 'fileLines',
              })
            },
            key: 'onboarding-account-name',
          },
          {
            render: () =>
              renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.PROJECT_ID,
                name: 'onboarding-project-id',
                label: 'Project ID',
                icon: 'cloud',
              }),
            key: 'onboarding-project-id',
          },
          {
            render: () =>
              renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.BILLING_DATASET_NAME,
                name: 'onboarding-billing-dataset-name',
                label: 'Billing Dataset Name',
                icon: 'server',
              }),
            key: 'onboarding-billing-dataset-name',
          },
          {
            render: () =>
              renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.BILLING_TABLE_NAME,
                name: 'onboarding-billing-table-name',
                label: 'Billing Table Name',
                icon: 'server',
              }),
            key: 'onboarding-billing-table-name',
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-secret-key">
                  <span>
                    <GenerateIcon iconName={ICONS.key.name} />
                    Service Account Key File (JSON)
                  </span>
                </label>
                <div className={commonStyles.inputBox}>
                  <div>
                    <Input
                      value={
                        getValues(GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY) ? tempFileName || 'key.json' : undefined
                      }
                      isInvalid={
                        !!errors[GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY] &&
                        errors[GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY]
                      }
                      clearable
                      icon={<GenerateIcon iconName={ICONS.upload.name} />}
                      onClick={(e) => {
                        e.target.value = null;
                      }}
                      placeholder="Upload JSON"
                      overrideStyles={{ height: 36 }}
                      invalidComponent={
                        errors[GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY]?.message ||
                        errors[GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY]
                      }
                      onChange={(e) => {
                        if (!e) {
                          setValue(GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY, null);
                          return;
                        }
                        const file = e.target.files[0];
                        if (!file) {
                          return;
                        }
                        setTempFileName(file.name);
                        const readerObj = new FileReader();
                        readerObj.onload = () => {
                          setValue(GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY, readerObj.result);
                          clearErrors(GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY);
                        };
                        readerObj.readAsText(file);
                      }}
                      name="onboarding-secret-key"
                      id="gcp-onboarding-secret-key"
                      type="file"
                      accept=".json"
                    />
                  </div>
                </div>
              </div>
            ),
            key: 'onboarding-secret-key',
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-currency-type">
                  <span>
                    <GenerateIcon iconName={ICONS.coins.name} />
                    Currency Type (optional)
                  </span>
                </label>
                <div className={commonStyles.inputBox}>
                  <Select
                    placeholder="USD"
                    className={styles.select}
                    value={getValues(AZURE_ONBOARDING_FIELDS.CURRENCY_TYPE)}
                    options={currencies}
                    name="onboarding-currency-type"
                    onChange={(value) => {
                      setValue(AZURE_ONBOARDING_FIELDS.CURRENCY_TYPE, value);
                    }}
                  />
                </div>
              </div>
            ),
            key: 'onboarding-currency-type',
          },
        ]}
        help={{
          'onboarding-account-name': GcpDetailsPageHelp.accountName,
          'onboarding-project-id': GcpDetailsPageHelp.projectID(brand.GCPOnboardingBillingDatasetProjectIDHelp),
          'onboarding-billing-dataset-name': GcpDetailsPageHelp.billingDatasetName(brand.GCPOnboardingBillingDatasetNameHelp),
          'onboarding-billing-table-name': GcpDetailsPageHelp.billingTableName(brand.GCPOnboardingBillingTableNameHelp),
          'onboarding-secret-key': GcpDetailsPageHelp.secretKey(brand.GCPOnboardingServiceAccountKeyHelp),
          'onboarding-currency-type': GcpDetailsPageHelp.currency,
        }}
      />
    </div>
  );
};

export default GcpDetailsPage;
