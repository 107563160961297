import React, { useEffect, useState } from 'react';
import useTable from 'shared/hooks/customHooks/useTable';
import useUsers from 'users/new-user-management/hooks/reactQuery/useUsers';
import UsersHeader from './UsersHeader';
import UsersTable from './UsersTable';
import { UsersContext } from './contexts/usersContext';
import { GET_USERS_QUERY_PARAMS } from '../consts';
import usePagination from 'users/hooks/usePagination';
import { useOrgToCustomers } from 'users/containers/Organization/components/OrgToCustomersSwitch/useOrgToCustomers';

import styles from './Users.module.scss';

const Users = () => {
  const {
    selectedCustomerDetails,
    handleOrgCustomersToggle,
    handleCustomerSelectionChanged,
    isCustomersView,
    setIsCustomersView,
    hookData,
  } = useOrgToCustomers(useUsers, initializeState);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [tableFilters, setTableFilters] = useState({
    [GET_USERS_QUERY_PARAMS.PAGINATION_TOKEN.name]: null,
    [GET_USERS_QUERY_PARAMS.PAGE_SIZE.name]: GET_USERS_QUERY_PARAMS.PAGE_SIZE.defaultValue,
  });

  const { data, isLoading, isFetching } = hookData.fetchUsers({
    ...tableFilters,
  });

  const { NewTableWrapper } = useTable();

  useEffect(() => {
    if (
      selectedCustomerDetails.customer ||
      !isCustomersView ||
      (isCustomersView && !selectedCustomerDetails.customer)
    ) {
      hookData.reset();
    }
  }, [isCustomersView, selectedCustomerDetails.customer]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const searchParam = searchParams.get(GET_USERS_QUERY_PARAMS.SEARCH.name);
    if (searchParam) {
      setTableFilters((prev) => ({ ...prev, [GET_USERS_QUERY_PARAMS.SEARCH.name]: searchParam }));
    }
  }, []);

  const handlePageChange = (newPaginationToken) => {
    setTableFilters((prev) => ({
      ...prev,
      [GET_USERS_QUERY_PARAMS.PAGINATION_TOKEN.name]: newPaginationToken,
    }));
  };

  const { currentPage, pageChanged } = usePagination({
    data,
    onPageChange: handlePageChange,
  });

  useEffect(() => {
    setTotalUsers(data?.total ?? 0);
  }, [data?.total]);

  function initializeState() {
    setSelectedRows([]);
    setIsAllExpanded(false);
  }

  return (
    <div className={styles.users}>
      <UsersContext.Provider
        value={{
          isAllExpanded,
          isCustomersView,
          setIsAllExpanded,
          showFilters,
          selectedCustomer: selectedCustomerDetails.customer,
          setShowFilters,
          selectedRows,
          setIsCustomersView,
          setSelectedRows,
          setTableFilters,
          tableFilters,
          totalUsers,
        }}
      >
        <NewTableWrapper>
          <UsersHeader
            pageChanged={pageChanged}
            currentPage={currentPage}
            isNextPageLoading={isLoading || isFetching}
            onCustomerSelectionChanged={handleCustomerSelectionChanged}
            onOrgCustomersToggle={handleOrgCustomersToggle}
          />
          <UsersTable isLoading={isLoading} users={data?.users || []} />
        </NewTableWrapper>
      </UsersContext.Provider>
    </div>
  );
};

export default Users;
