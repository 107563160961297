import React from 'react';
import PropTypes from 'prop-types';
import { ComposableMap, Geographies, Geography, Marker, ZoomableGroup } from 'react-simple-maps';
import { DisplayMetricTypes } from 'usage/constants/costAndUsageConstants.js';
import { cloudTypeToRegionMarkersMap } from 'shared/constants/regionMarkersConstants.js';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import geoData from 'shared/components/world-50m.json';
import { palette } from 'shared/constants/colorsConstants';
import styles from './regionMap.module.scss';

const markSizeRange = {
  min: 3,
  max: 16,
};

const getUsageCost = (regionData, regionTag) => {
  const region = regionData?.find((currRegion) => currRegion.regionTag === regionTag);
  return region?.usageCost || 0;
};

const percentOfTotal = (regionSum, totalRegionSum) => {
  if (totalRegionSum <= 0) return '';
  return Number((regionSum / totalRegionSum) * 100)?.toFixed(3);
};

const filterMarkersMap = (regionFilters, markers) => {
  return (markers || []).filter((marker) => {
    const result = regionFilters.filter((curr) => curr.regionTag.includes(marker.regionTag));
    return result.length > 0;
  });
};

const groupByRegions = (regionsArr) => {
  const result = [];
  regionsArr?.reduce((res, value) => {
    if (!res[value.regionTag]) {
      res[value.regionTag] = { regionTag: value.regionTag, usageCost: 0 };
      result.push(res[value.regionTag]);
    }
    res[value.regionTag].usageCost += value.usageCost;
    return res;
  }, {});

  return result;
};

const normalizeRegionCost = (regionsArr, minRange, maxRange) => {
  // Extract the usage costs and determine the min and max values
  const costs = regionsArr.map((region) => region.usageCost);
  const minCost = Math.min(...costs);
  const maxCost = Math.max(...costs);
  const rangeDiff = maxRange - minRange;

  // Normalize the usage cost for each region
  return regionsArr.map((region) => {
    const normalizedCost = (region.usageCost - minCost) / (maxCost - minCost); // Normalize between 0 and 1
    const scaledCost = normalizedCost * rangeDiff + minRange; // Scale to target range
    return {
      regionTag: region.regionTag,
      usageCost: scaledCost,
    };
  });
};
const unifyZones = (resultRegions) =>
  resultRegions?.map((region) => ({
    regionName: region.regionName,
    regionTag: region.regionTag,
    usageCost: region.usageCost,
  }));

const unionZonesToRegionArray = (resultZones) => {
  const resultWithRegion = unifyZones(resultZones);
  return groupByRegions(resultWithRegion);
};

const unionZonesToNormalizedRegionArray = (resultZones, markSizeRange) => {
  const resultUnionZones = unionZonesToRegionArray(resultZones);
  return normalizeRegionCost(resultUnionZones, markSizeRange.min, markSizeRange.max);
};

const RegionMap = ({ regionData, overrideCurrency = '' }) => {
  const { usersStore } = useRootStore();
  const { numStrAbriviaionByDisplayMetric, getCurrencyNumber } = useUserSettingsContext();

  const regionsData = unionZonesToRegionArray(regionData.data);
  const normalizedRegionsData = unionZonesToNormalizedRegionArray(regionData.data, markSizeRange);
  const regionNotAvailableUsageCost = getUsageCost(regionData.data, 'Global');
  const markers = cloudTypeToRegionMarkersMap.get(usersStore?.currDispUserCloudAccountType);

  return (
    <>
      <h5>
        Total cost by region: <span>{getCurrencyNumber(regionData.totalRegionsCost)}</span>
      </h5>
      <h5>
        Global costs: <span>{getCurrencyNumber(regionNotAvailableUsageCost)}</span>
      </h5>
      <div className={styles.wrapperStyles}>
        <ComposableMap
          projection="geoEqualEarth"
          projectionConfig={{
            scale: 210,
            xOffset: 0,
            yOffset: 0,
            rotation: [-40, 0, 0],
          }}
          width={980}
          height={551}
          className={styles.composableMap}
        >
          <ZoomableGroup center={[0, 10]} disablepanning="true">
            <Geographies geography={geoData}>
              {({ geographies }) =>
                geographies.map(
                  (geography) =>
                    geography.id !== 'ATA' && (
                      <Geography
                        key={geography.rsmKey}
                        geography={geography}
                        style={{
                          default: {
                            fill: '#e8e8e8',
                            stroke: '#e8e8e8',
                            strokeWidth: 0.75,
                            outline: 'none',
                          },
                          hover: {
                            fill: '#e8e8e8',
                            stroke: '#e8e8e8',
                            strokeWidth: 1,
                            outline: 'none',
                          },
                        }}
                      />
                    ),
                )
              }
            </Geographies>

            {filterMarkersMap(normalizedRegionsData, markers).map((marker, i) => (
              <Marker
                id={`marker-${i}`}
                key={marker.regionTag}
                coordinates={marker.coordinates}
                style={{
                  default: { fill: '#134b66' },
                  hover: { fill: palette.white[500] },
                  pressed: { fill: palette.white[500] },
                }}
              >
                <circle
                  cx={0}
                  cy={0}
                  r={getUsageCost(normalizedRegionsData, marker.regionTag)}
                  style={{
                    stroke: '#134b66',
                    strokeWidth: 2,
                    opacity: 0.7,
                  }}
                />

                <text
                  fontSize="14"
                  textAnchor="middle"
                  x={marker.markerOffsetX}
                  y={marker.markerOffsetY}
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fill: '#ff653a',
                  }}
                >
                  {marker.displayName}
                </text>
                <text
                  fontSize="12"
                  textAnchor="middle"
                  x={marker.subOffsetX}
                  y={marker.subOffsetY}
                  style={{
                    fontFamily: 'Roboto, sans-serif',
                    fill: '#000000',
                  }}
                >
                  {`${numStrAbriviaionByDisplayMetric(
                    getUsageCost(regionsData, marker.regionTag),
                    getUsageCost(regionsData, marker.regionTag),
                    DisplayMetricTypes.COST,
                    overrideCurrency,
                  )} (${percentOfTotal(getUsageCost(regionsData, marker.regionTag), regionData.totalRegionsCost)}%)`}
                </text>
              </Marker>
            ))}
          </ZoomableGroup>
        </ComposableMap>
      </div>
    </>
  );
};

RegionMap.propTypes = {
  regionData: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        regionName: PropTypes.string.isRequired,
        regionTag: PropTypes.string.isRequired,
        usageCost: PropTypes.number.isRequired,
      }),
    ).isRequired,
    totalRegionsCost: PropTypes.number.isRequired,
  }).isRequired,
  overrideCurrency: PropTypes.string,
};

export default RegionMap;
