import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import useTable from 'shared/hooks/customHooks/useTable';
import useDataAccessAccounts from 'users/new-user-management/hooks/reactQuery/useDataAccessAccounts';
import { CostCentersContext } from './contexts/costCentersContext';
import CostCentersHeader from './CostCentersHeader';
import CostCentersTable from './CostCentersTable';
import { findFilteredData } from 'users/containers/Organization/CostCenters/costCentersHelperFunctions.js';
import { useOrgToCustomers } from 'users/containers/Organization/components/OrgToCustomersSwitch/useOrgToCustomers';

import styles from './CostCenters.module.scss';

const CostCenters = ({
  isRoleCostCentersDataAccessLoading,
  isRoleCostCentersDataAccessView,
  isSelectionEnabled = true,
  onRemoveCostCenters,
  onSelectionChange,
  roleCostCentersDataAccess,
  roleId,
}) => {
  const {
    selectedCustomerDetails,
    handleOrgCustomersToggle,
    handleCustomerSelectionChanged,
    isCustomersView,
    setIsCustomersView,
    hookData,
  } = useOrgToCustomers(useDataAccessAccounts, initializeState, !!roleCostCentersDataAccess);

  const [search, setSearch] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const { NewTableWrapper } = useTable();

  const { data: costCentersData, isLoading: isCostCentersDataAccessLoading } = hookData.fetchDataAccessCostCenters();

  useEffect(() => {
    if (
      !isRoleCostCentersDataAccessView &&
      (selectedCustomerDetails.customer || !isCustomersView || (isCustomersView && !selectedCustomerDetails.customer))
    ) {
      hookData.reset();
    }
  }, [isCustomersView, selectedCustomerDetails.customer]); // eslint-disable-line react-hooks/exhaustive-deps

  const costCentersToDisplay = useMemo(() => {
    let data = isRoleCostCentersDataAccessView ? roleCostCentersDataAccess : costCentersData;

    let result = null;
    if (data) {
      result = data.map((cc) => {
        const transformedCostCenter = {
          ...{ ...cc?.costCenter, parentId: cc?.costCenter?.parentCcId },
          accounts: cc.accounts,
          creationTime: cc.creationTime,
        };

        delete transformedCostCenter.parentCcId;

        return transformedCostCenter;
      });
      if (search) {
        result = findFilteredData(result, search, null);
      }
    }
    return result;
  }, [costCentersData, isRoleCostCentersDataAccessView, roleCostCentersDataAccess, search]);

  const totalCount = useMemo(() => {
    if (isRoleCostCentersDataAccessView) {
      if (isSelectionEnabled) {
        return costCentersToDisplay?.filter((cc) => cc.isAllowed === true).length;
      }
      return roleCostCentersDataAccess?.length || 0;
    }

    return costCentersData?.length;
  }, [
    costCentersData?.length,
    costCentersToDisplay,
    isRoleCostCentersDataAccessView,
    isSelectionEnabled,
    roleCostCentersDataAccess?.length,
  ]);

  function initializeState() {
    setSelectedRows([]);
    setIsAllExpanded(false);
  }

  return (
    <div className={styles.costCenters}>
      <CostCentersContext.Provider
        value={{
          isAllExpanded,
          isCustomersView,
          search,
          selectedCustomer: selectedCustomerDetails.customer,
          selectedRows,
          setIsAllExpanded,
          setIsCustomersView,
          setSearch,
          setSelectedRows,
        }}
      >
        <NewTableWrapper>
          <CostCentersHeader
            isSelectionEnabled={isSelectionEnabled}
            isRoleDataAccessView={isRoleCostCentersDataAccessView}
            onCustomerSelectionChanged={handleCustomerSelectionChanged}
            onOrgCustomersToggle={handleOrgCustomersToggle}
            roleId={roleId}
            total={totalCount}
          />
          <CostCentersTable
            costCenters={costCentersToDisplay || []}
            isLoading={isCostCentersDataAccessLoading || isRoleCostCentersDataAccessLoading}
            isSelectionEnabled={isSelectionEnabled}
            isRoleDataAccessView={isRoleCostCentersDataAccessView}
            onRemoveCostCenter={onRemoveCostCenters}
            onSelectionChange={onSelectionChange}
            noCostCentersInOrg={!isCostCentersDataAccessLoading && !costCentersData?.length}
          />
        </NewTableWrapper>
      </CostCentersContext.Provider>
    </div>
  );
};

CostCenters.propTypes = {
  isRoleCostCentersDataAccessLoading: PropTypes.bool,
  isRoleCostCentersDataAccessView: PropTypes.bool,
  isSelectionEnabled: PropTypes.bool,
  onRemoveCostCenters: PropTypes.func,
  onSelectionChange: PropTypes.func,
  roleCostCentersDataAccess: PropTypes.object,
  roleId: PropTypes.string,
};

export default CostCenters;
