import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Input from 'shared/components/andtComponents/Input';
import { useRootStore } from 'app/contexts/RootStoreContext';
import Select from 'shared/components/andtComponents/SimpleSelect';
import ByodDetailsPageHelp from './ByodDetailsPageHelp';
import { BYOD_ONBOARDING_FIELDS } from '../../ByodOnboardingConstants';
import { getTenantParams } from '../../../utils/OnboardingUtils';
import { ReactComponent as BYODIcon } from 'shared/img/cloud-providers/simple/byod.svg';
import { ReactComponent as FocusIcon } from 'shared/img/cloud-providers/azure-focus.svg';
import RadioButtonsList from 'shared/components/andtComponents/RadioButtonList';
import toast from 'shared/components/andtComponents/Toast';
import Button from 'shared/components/andtComponents/Button';
import { AddVendorModal } from './AddVendorModal';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { VendorLabel, VendorOption } from './VendorLabel.tsx';

import commonStyles from '../Pages.module.scss';
import styles from './ByodDetailsPage.module.scss';

interface RenderInputProps {
  fieldName: string;
  name: string;
  label: string;
  icon: string;
  placeholder: string;
}

interface VendorOptionMap {
  optionsList: VendorOption[];
  labelToOption: { [key: string]: VendorOption };
}

interface Vendor {
  vendorName: string;
  vendorLogoUrl: string;
  cloudTypeId: number;
  isPredefined: boolean;
}

const createVendorOptionsMap = (vendors: Vendor[]): VendorOptionMap => {
  const sortedVendors = sortVendorsByPredefinedAndCloudType(vendors);
  const optionsList = sortedVendors.map(({ vendorName, cloudTypeId, vendorLogoUrl }) => ({
    label: vendorName,
    value: cloudTypeId,
    vendorLogoUrl,
    cloudTypeId,
  }));

  const labelToOption = Object.fromEntries(optionsList.map((option) => [option.label, option]));

  return {
    optionsList,
    labelToOption,
  };
};

const sortVendorsByPredefinedAndCloudType = (vendors: Vendor[]) => {
  const AwsAzureGCPVendors = [...vendors].sort((a, b) => a.cloudTypeId - b.cloudTypeId).slice(0, 3);
  const awsAzureGCPVendorIds = new Set(AwsAzureGCPVendors.map((vendor) => vendor.cloudTypeId));

  const remainingVendors = vendors.filter((vendor) => !awsAzureGCPVendorIds.has(vendor.cloudTypeId));
  const sortedRemainingVendors = remainingVendors.sort((a, b) => {
    if (a.isPredefined !== b.isPredefined) {
      return b.isPredefined ? 1 : -1;
    }
    return a.vendorName.localeCompare(b.vendorName);
  });

  return [...AwsAzureGCPVendors, ...sortedRemainingVendors];
};

const ByodDetailsPage: React.FC = () => {
  const {
    setValue,
    clearErrors,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { usersStore } = useRootStore();
  const [isAddVendorModalOpen, setIsAddVendorModalOpen] = useState<boolean>(false);
  useWatch({ name: BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME });
  const accountName = getValues(BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME);

  const { getByodVendors, addByodVendor } = useOnboarding();
  const { data: vendors = [] } = getByodVendors();
  const vendorOptions = React.useMemo(() => createVendorOptionsMap(vendors), [vendors]);
  const { mutateAsync: handleAddByodVendor } = addByodVendor();

  useEffect(() => {
    const { tenantName, tenantSchemaName } = getTenantParams(usersStore.currentDisplaydUser, accountName);
    setValue(BYOD_ONBOARDING_FIELDS.TENANT_NAME, tenantName);
    setValue(BYOD_ONBOARDING_FIELDS.TENANT_SCHEMA_NAME, tenantSchemaName);
  }, [accountName, setValue, usersStore.currentDisplaydUser]);

  const renderInput = ({ fieldName, name, label, icon, placeholder }: RenderInputProps): React.ReactElement => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            value={getValues(fieldName)}
            isInvalid={Boolean(errors?.[fieldName]) && errors?.[fieldName]?.type !== 'GeneralError'}
            invalidComponent={
              typeof errors[fieldName]?.message === 'string'
                ? errors[fieldName]?.message
                : String(errors[fieldName] || '')
            }
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setValue(fieldName, e.target.value);
              clearErrors(fieldName);
            }}
            placeholder={placeholder}
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 32,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        list={[
          {
            render: () =>
              renderInput({
                fieldName: BYOD_ONBOARDING_FIELDS.ACCOUNT_NAME,
                name: 'onboarding-account-name',
                label: 'Account Name',
                icon: 'fileLinesLight',
                placeholder: 'Enter Name',
              }),
            key: 'onboarding-account-name',
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-import-type">
                  <span>
                    <GenerateIcon iconName={ICONS.fileImport.name} />
                    Import Type
                  </span>
                </label>
                <div className={commonStyles.checkboxField}>
                  <RadioButtonsList
                    onChange={(value) => {
                      setValue(BYOD_ONBOARDING_FIELDS.IMPORT_TYPE, value);
                    }}
                    options={[
                      {
                        value: 'focus',
                        key: 1,
                        label: (
                          <>
                            <FocusIcon height="30" width="30" /> Focus
                          </>
                        ),
                        primary: true,
                      },
                      {
                        value: 'custom',
                        key: 2,
                        label: (
                          <>
                            <BYODIcon height="30" width="30" /> Custom Schema <span>Coming Soon!</span>
                          </>
                        ),
                        primary: true,
                        disabled: true,
                      },
                    ]}
                    value={getValues(BYOD_ONBOARDING_FIELDS.IMPORT_TYPE)}
                    direction="row"
                  />
                </div>
              </div>
            ),
            key: 'onboarding-import-type',
          },
          {
            render: () => (
              <div className={commonStyles.inputField}>
                <label htmlFor="onboarding-vendor">
                  <span>
                    <GenerateIcon iconName={ICONS.comment.name} />
                    Vendor
                  </span>
                  <Button text="Add vendor to list" isTextButton onClick={() => setIsAddVendorModalOpen(true)} />
                </label>
                <div className={commonStyles.inputBox}>
                  <Select
                    placeholder="Select Vendor"
                    className={styles.select}
                    value={getValues(BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID)}
                    automationid="onboarding-vendor"
                    onChange={(value) => {
                      setValue(BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID, value);
                      clearErrors(BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID);
                    }}
                    isInvalid={Boolean(errors?.[BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID])}
                    invalidComponent={String(errors[BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID])}
                    options={vendorOptions.optionsList}
                    renderLabel={(label: string) => (
                      <VendorLabel label={label} option={vendorOptions.labelToOption[label]} />
                    )}
                  />
                </div>
              </div>
            ),
            key: 'onboarding-vendor',
          },
        ]}
        help={{
          'onboarding-account-name': ByodDetailsPageHelp.accountName,
          'onboarding-import-type': ByodDetailsPageHelp.importType,
          'onboarding-vendor': ByodDetailsPageHelp.vendor,
        }}
      />
      <AddVendorModal
        isOpen={isAddVendorModalOpen}
        onClose={() => setIsAddVendorModalOpen(false)}
        onSave={async (vendor: Vendor) => {
          try {
            await handleAddByodVendor(vendor as never);
            setValue(BYOD_ONBOARDING_FIELDS.CLOUD_TYPE_ID, vendor.cloudTypeId);
            toast.success('Vendor added successfully');
          } catch (error) {
            toast.error(error?.response?.data?.message || 'Adding BYOD vendor failed');
          }
        }}
      />
    </div>
  );
};

export default ByodDetailsPage;
