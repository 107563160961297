import PropTypes from 'prop-types';
import React from 'react';
import InfoPopover from 'shared/components/andtComponents/InfoPopover';
import { GenerateIcon } from '@pileus-cloud/anodot-frontend-common';

import classes from './threeStateSwitchButton.module.scss';

export const ThreeStateSwitchButton = ({
  automationid = '',
  infoIconTooltipText = '',
  icon = null,
  isDisabled = false,
  switchLabel = '',
  optionsList,
  changeState,
}) => {
  const onOptionClick = (event) => {
    if (isDisabled) {
      return;
    }
    changeState(event);
  };

  return (
    optionsList?.length > 0 && (
      <div className={classes.container}>
        <div className={classes.statusAndLabelContainer}>
          <div className={classes.iconAndLabel}>
            {icon ? <GenerateIcon iconName={icon} /> : null}
            {switchLabel}
          </div>
          <InfoPopover isSimple>{infoIconTooltipText}</InfoPopover>
        </div>
        <div
          className={`${classes.switchOptionsContainer} ${isDisabled ? classes.isDisabled : ''}`}
          automation-id={automationid}
        >
          {optionsList.map((option) => (
            <div
              className={`${classes.option} ${option.isSelected ? classes.selected : classes.notSelected} ${
                isDisabled ? classes.isDisabled : ''
              }`}
              onClick={() => onOptionClick({ option: option.label })}
              automation-id={`${automationid}-option-${option.label}`}
            >
              {option.iconName ? <GenerateIcon iconName={`${option.iconName}`} /> : option.label}
            </div>
          ))}
        </div>
      </div>
    )
  );
};

ThreeStateSwitchButton.propTypes = {
  automationid: PropTypes.string,
  infoIconTooltipText: PropTypes.string,
  isDisabled: PropTypes.bool,
  switchLabel: PropTypes.string,
  optionsList: PropTypes.array.isRequired,
  changeState: PropTypes.func.isRequired,
  icon: PropTypes.string,
};

export default ThreeStateSwitchButton;
