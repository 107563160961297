import React from 'react';
import { ICONS, GenerateIcon } from '@pileus-cloud/anodot-frontend-common';
import { useFormContext } from 'react-hook-form';
import Button from 'shared/components/andtComponents/Button';
import getOnboardingLinks from 'app/containers/Onboarding/utils/getOnboardingLinks';
import { ReactComponent as RocketIcon } from './assets/rocket.svg';
import styles from './AccountDetailsModal.module.scss';
import { useBrand } from 'app/contexts/Brand/BrandContext';
import { prepareLinkAccountLabel } from 'recommendations/containers/Dashboard/helpers/dataPrepareHelpers';
import { ONBOARDING_FIELDS } from 'app/containers/Onboarding/utils/OnboardingConstants';
import commonStyles from 'app/containers/Onboarding/AwsOnboarding/pages/Pages.module.scss';
import AccountTypeImage from '~/app/containers/Onboarding/SharedPages/AccountDetailsModal/assets/accountType.jpg';
import { useRootStore } from '~/app/contexts/RootStoreContext.jsx';
import { CLOUD_TYPE_IDS } from '~/users/constants/usersConstants.js';
import { getLinkedAccountName } from '~/shared/utils/cloudUtils.js';

const AccountDetailsHelp = {};

AccountDetailsHelp.OnboardingType = {
  component: () => {
    const brand = useBrand();
    const onboardingLinks = getOnboardingLinks(brand);
    const { vendorMappingsStore } = useRootStore();
    const { getValues } = useFormContext();
    const cloud = vendorMappingsStore.getDescriptionById(getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID));
    return (
      <>
        {getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID) === CLOUD_TYPE_IDS.AWS && (
          <div className={styles.onboardingTypeLabel}>
            <p>
              <RocketIcon className={styles.rocketIcon} />
              Automatically
            </p>
            <span>
              Run scripts automatically in order to grant access.
              <br />
              If you chose also to connect your linked accounts, ensure that trusted access for CloudFormation StackSets
              is enabled in your AWS accounts.
            </span>
            <Button
              onClick={() => window.open(onboardingLinks[cloud].onboardingType.automatic, '_blank')}
              text="Learn more"
              isTextButton
              overrideStyles={{ height: 20 }}
            />
          </div>
        )}
        <div className={styles.onboardingTypeLabel}>
          <p>
            <GenerateIcon iconName={ICONS.gear.name} />
            Manually
          </p>
          <span>Grant {brand.name} access to your cloud provider by completing the onboarding steps manually.</span>
          <Button
            text="Learn more"
            onClick={() => window.open(onboardingLinks[cloud].onboardingType.manual, '_blank')}
            isTextButton
            overrideStyles={{ height: 20 }}
          />
        </div>
      </>
    );
  },
};

AccountDetailsHelp.MSPAccountType = {
  component: () => {
    const brand = useBrand();
    const { getValues } = useFormContext();
    const { vendorMappingsStore } = useRootStore();
    const cloud = vendorMappingsStore.getDescriptionById(getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID));
    const onboardingLinks = getOnboardingLinks(brand);
    const linkedAccountsName = getLinkedAccountName(getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID)).toLowerCase();
    return (
      <div className={styles.mspAccountTypeHelp}>
        <p className={styles.mspAccountTypeHelpTitle}>
          <GenerateIcon iconName={ICONS.mainUser.name} />
          Dedicated Account
        </p>
        <p>The onboarded payer account belongs to a single customer.</p>
        <Button
          onClick={() => window.open(onboardingLinks[cloud].customerType, '_blank')}
          text="Learn more"
          isTextButton
          overrideStyles={{ paddingLeft: 0, height: 20 }}
        />
        {getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID) !== CLOUD_TYPE_IDS.GCP && (
          <p className={commonStyles.helpText}>
            <span className={commonStyles.notice}>Notice:</span>
            If you enable automatic {linkedAccountsName}s assignment, all {linkedAccountsName}s will be automatically
            associated with the customer, including Savings Plans and Reservations.
          </p>
        )}
        <p className={styles.mspAccountTypeHelpTitle}>
          <GenerateIcon iconName={ICONS.users.name} />
          Shared Account
        </p>
        <p>The onboarded payer account belongs to multiple customers.</p>
        <Button
          onClick={() => window.open(onboardingLinks[cloud].customerType, '_blank')}
          text="Learn more"
          isTextButton
          overrideStyles={{ paddingLeft: 0, height: 20 }}
        />
      </div>
    );
  },
};

AccountDetailsHelp.MspCustomer = {
  component: () => {
    const { getValues } = useFormContext();
    return (
      <div className={commonStyles.helpText}>
        <span>Define the customer for this account.</span>
        <br />
        Enter the customer display name and the customer ID (optional).
        <br />
        <br />
        <span className={commonStyles.notice}>Note:</span>
        <br />
        Enabling auto-assignment will apply to all{' '}
        {prepareLinkAccountLabel(getValues(ONBOARDING_FIELDS.CLOUD_TYPE_ID), false).toLowerCase()} associated with this
        customer, including those with commitments.
      </div>
    );
  },
};

AccountDetailsHelp.MspCustomerDomain = {
  component: () => {
    const brand = useBrand();
    return (
      <div className={commonStyles.helpText}>
        For the SCIM option, we will create a dedicated role for the customer to manage users and roles from the SSO
        provider.
        <br />
        <br />
        <span className={commonStyles.notice}>Note:</span>
        <br />
        <ol>
          <li>
            After onboarding, contact our support team at{' '}
            <a href={`mailto:${brand.supportEmail}`}>{brand.supportEmail}</a> to receive the special role.
          </li>
          <li>Once SCIM is configured, the auto-assignment of sub-accounts to customers will be enabled by default.</li>
        </ol>
      </div>
    );
  },
};

AccountDetailsHelp.AzureCreationType = {
  component: () => {
    const brand = useBrand();
    const onboardingLinks = getOnboardingLinks(brand);
    return (
      <div className={commonStyles.helpText}>
        <p>
          You can either allow {brand.name} to create customers automatically based on Azure logic from the export, or
          you can create each customer manually after onboarding. If you want it will be created automatically, you can
          choose whether the customer will be created based on &quot;customer name&quot; or &quot;customer tenant
          ID&quot;:
          <br />
          - &quot;Customer name&quot;: all the tenants (and subscriptions) of that customer name will be associated with
          it
          <br />- &quot;Customer tenant ID&quot;: each tenant will be defined as a new customer.
        </p>
        <span className={styles.noteTitle}>Please note:</span>
        <ol>
          <li>We automatically assign new subscriptions/tenant ongoing.</li>
          <li>You can not update it manually.</li>
        </ol>
        <Button
          onClick={() => window.open(onboardingLinks.AZURE.customerType, '_blank')}
          text="Learn more"
          isTextButton
          overrideStyles={{ paddingLeft: 0, height: 20 }}
        />
      </div>
    );
  },
};

AccountDetailsHelp.AzureAccountType = {
  component: () => (
    <div className={styles.mspAccountTypeHelp}>
      <div>
        On Azure portal navigate to Cost Management + Billing page, select the relevant billing scope and click on
        Properties.
        <br />
        <p>
          <span>
            <span className={styles.noteTitle}>Notice:</span>
            <br />
            1. You can onboard Amortized export only for CSP, EA and MCA types.
            <br />
            2. All the accounts will be created using billing export.
            <br />
            3. Azure EA account onboarding uses Export and Application creation, not APIs.
          </span>
        </p>
      </div>
    </div>
  ),
  image: AccountTypeImage,
  imageHeight: 110,
};

export default AccountDetailsHelp;
