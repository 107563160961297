import { useMutation, useQuery } from '@tanstack/react-query';
import apiConstants from 'shared/api/apiConstants';
import { queryClient } from 'queryClient';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { PERMISSION_ENTITIES, STALE_TIME } from 'users/containers/Organization/consts';
import {
  addPartialRolePermissions,
  createRole,
  deletePartialRolePermissions,
  deleteRoles,
  fetchAvailableCategoryActions,
  fetchMergedPermissionsOfRoles,
  fetchPartialRolePermissions,
  fetchRoleById,
  fetchRoleDataAccess,
  fetchRolePermissions,
  fetchRoles,
  setRolePermissions,
  updateAccountsAccessibilityForRole,
  updateCostCentersAccessibilityForRole,
  updateResellerCustomersAccessibilityForRole,
  updateRole,
  updateSubRoles,
} from './apiRoles';
import { handleError } from './helperFunctions';

export default function useRoles(isDisabled = false) {
  const { usersStore } = useRootStore();
  const userAccountKey = usersStore?.currDispUserAccountKey;

  const rolesQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES, userAccountKey];
  const usersQueryKey = [apiConstants.QUERY_KEYS.USER_MANAGEMENT_USERS, userAccountKey];

  return {
    invalidate: () => queryClient.invalidateQueries({ queryKey: rolesQueryKey }),
    reset: () => queryClient.resetQueries({ queryKey: rolesQueryKey }),
    fetchRoles: (params) => {
      if (params && !params.search) {
        delete params.search;
      }
      const queryKeyFetchRoles = [...rolesQueryKey];
      if (params && Object.values(params)?.length) {
        const paramsQueryKey = Object.values(params).filter((p) => p);
        if (paramsQueryKey.length) {
          queryKeyFetchRoles.push([...Object.values(params).filter((p) => p)]);
        }
      }

      return useQuery({
        queryKey: queryKeyFetchRoles,
        queryFn: () => fetchRoles(params),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      });
    },
    fetchRoleById: (roleId) =>
      useQuery({
        queryKey: [...rolesQueryKey, roleId],
        queryFn: () => fetchRoleById(roleId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchMergedPermissionsOfRoles: ({ rolesIds }) =>
      useQuery({
        queryKey: [...rolesQueryKey, ...rolesIds],
        queryFn: () => fetchMergedPermissionsOfRoles(rolesIds),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchRolePermissions: (roleId) =>
      useQuery({
        queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS, roleId],
        queryFn: () => fetchRolePermissions(roleId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchPartialRolePermissions: ({ roleId, category, actionId, paginationToken, search }) =>
      useQuery({
        queryKey: [
          ...rolesQueryKey,
          apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS,
          roleId,
          category,
          actionId,
          paginationToken,
          search,
        ],
        queryFn: () => fetchPartialRolePermissions(roleId, category, actionId, paginationToken, search),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    fetchAvailableCategoryActions: (isReseller) =>
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLES_AVAILABLE_ACTION_CATEGORIES],
        queryFn: () => fetchAvailableCategoryActions(),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
        select: (availableCategories) => {
          if (!isReseller) {
            return availableCategories.filter((ac) => ac.category !== PERMISSION_ENTITIES.RESELLER_CUSTOMERS.id);
          }
          return availableCategories;
        },
      }),
    fetchRoleDataAccess: (roleId) =>
      useQuery({
        queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS, roleId],
        queryFn: () => fetchRoleDataAccess(roleId),
        enabled: !isDisabled,
        retry: false,
        staleTime: STALE_TIME,
        onError: handleError,
      }),
    createRole: useMutation({
      mutationFn: ({ role }) => createRole(role),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: rolesQueryKey });
        await queryClient.invalidateQueries({ queryKey: usersQueryKey });
      },
      onError: handleError,
    }),
    updateRole: useMutation({
      mutationFn: ({ roleId, roleDetails }) => updateRole(roleId, roleDetails),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: rolesQueryKey });
        await queryClient.invalidateQueries({ queryKey: usersQueryKey });
      },
      onError: handleError,
    }),
    deleteRoles: useMutation({
      mutationFn: ({ rolesIds }) => deleteRoles(rolesIds),
      onSuccess: async () => {
        await queryClient.invalidateQueries({ queryKey: rolesQueryKey });
        await queryClient.invalidateQueries({ queryKey: usersQueryKey });
      },
      onError: handleError,
    }),
    updateSubRoles: useMutation({
      mutationFn: ({ roleId, subRolesPayload }) => updateSubRoles(roleId, subRolesPayload),
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, variables.roleId],
        });
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
        await queryClient.invalidateQueries({
          queryKey: [...usersQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
        await queryClient.resetQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS],
        });
      },
      onError: handleError,
    }),
    setRolePermissions: useMutation({
      mutationFn: ({ roleId, roleCategoryPermissions }) => setRolePermissions(roleId, roleCategoryPermissions),
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, variables.roleId],
        });
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
        await queryClient.invalidateQueries({
          queryKey: [...usersQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
      },
      onError: handleError,
    }),
    addPartialRolePermissions: useMutation({
      mutationFn: ({ roleId, category, action, roleCategoryPermissions }) =>
        addPartialRolePermissions(roleId, category, action, roleCategoryPermissions),
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, variables.roleId, variables.category, variables.action],
        });
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
        await queryClient.invalidateQueries({
          queryKey: [...usersQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
      },
      onError: handleError,
    }),
    deletePartialRolePermissions: useMutation({
      mutationFn: ({ roleId, category, action, roleCategoryPermissions }) =>
        deletePartialRolePermissions(roleId, category, action, roleCategoryPermissions),
      onSuccess: async (_, variables) => {
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, variables.roleId, variables.category, variables.action],
        });
        await queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
        await queryClient.invalidateQueries({
          queryKey: [...usersQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_ROLE_PERMISSIONS],
        });
      },
      onError: handleError,
    }),
    updateResellerCustomersAccessibilityForRole: useMutation({
      mutationFn: ({ roleId, roleDataAccess }) => updateResellerCustomersAccessibilityForRole(roleId, roleDataAccess),
      onSuccess: (_, variables) =>
        queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS, variables.roleId],
        }),
      onError: handleError,
    }),
    updateAccountsAccessibilityForRole: useMutation({
      mutationFn: ({ roleId, roleDataAccess }) => updateAccountsAccessibilityForRole(roleId, roleDataAccess),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS],
        }),
      onError: handleError,
    }),
    updateCostCentersAccessibilityForRole: useMutation({
      mutationFn: ({ roleId, roleDataAccess }) => updateCostCentersAccessibilityForRole(roleId, roleDataAccess),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: [...rolesQueryKey, apiConstants.QUERY_KEYS.USER_MANAGEMENT_DATA_ACCESS],
        }),
      onError: handleError,
    }),
  };
}
