import { fetchAzureOnboardingAppRegistrationStatusApi } from 'app/hooks/react-query/useOnboarding';
import {
  AZURE_ONBOARDING_FIELDS,
  AZURE_ONBOARDING_STEPS,
} from 'app/containers/Onboarding/AzureOnboarding/AzureOnboardingConstants';
import toast from 'shared/components/andtComponents/Toast';
import { accountTypeValidation } from 'app/containers/Onboarding/utils/OnboardingUtils';

function billingExportValidation(currentScreen) {
  const errors = {};
  const accountStorage = currentScreen[AZURE_ONBOARDING_FIELDS.ACCOUNT_STORAGE];
  const containerName = currentScreen[AZURE_ONBOARDING_FIELDS.CONTAINER_NAME];

  if (!accountStorage) {
    errors[AZURE_ONBOARDING_FIELDS.ACCOUNT_STORAGE] = 'Storage Account is required';
  }
  if (!containerName) {
    errors[AZURE_ONBOARDING_FIELDS.CONTAINER_NAME] = 'Container Name is required';
  }
  return errors;
}

async function appRegistrationValidation(currentScreen) {
  const errors = {};
  const accountId = currentScreen[AZURE_ONBOARDING_FIELDS.ACCOUNT_ID];
  const applicationId = currentScreen[AZURE_ONBOARDING_FIELDS.APPLICATION_ID];
  const applicationName = currentScreen[AZURE_ONBOARDING_FIELDS.APPLICATION_DISPLAY_NAME];
  const directoryId = currentScreen[AZURE_ONBOARDING_FIELDS.DIRECTORY_ID];
  const clientSecret = currentScreen[AZURE_ONBOARDING_FIELDS.CLIENT_SECRET];
  const accountName = currentScreen[AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME];
  if (!applicationId) {
    errors[AZURE_ONBOARDING_FIELDS.APPLICATION_ID] = 'Application ID is required';
  }
  if (!directoryId) {
    errors[AZURE_ONBOARDING_FIELDS.DIRECTORY_ID] = 'Directory ID is required';
  }
  if (!clientSecret) {
    errors[AZURE_ONBOARDING_FIELDS.CLIENT_SECRET] = 'Client Secret is required';
  }
  if (!applicationName) {
    errors[AZURE_ONBOARDING_FIELDS.APPLICATION_DISPLAY_NAME] = 'Application Display Name is required';
  }
  if (!accountName) {
    errors[AZURE_ONBOARDING_FIELDS.ACCOUNT_NAME] = 'Account Name is required';
  }

  if (Object.values(errors).length) {
    return errors;
  }

  try {
    const response = await fetchAzureOnboardingAppRegistrationStatusApi(
      accountId,
      directoryId,
      applicationId,
      clientSecret,
    );
    if (!response) {
      errors[AZURE_ONBOARDING_FIELDS.APPLICATION_ID] = 'GeneralError';
    }
  } catch (e) {
    if (e.response?.data?.clientMessage) {
      toast.error(e.response?.data?.clientMessage);
    }
    errors[AZURE_ONBOARDING_FIELDS.APPLICATION_ID] = 'GeneralError';
  }

  return errors;
}

export default async function useFormValidation(currentScreen, keycloakAuth) {
  switch (currentScreen.screen) {
    case AZURE_ONBOARDING_STEPS.ACCOUNT_TYPE:
      return {
        errors: await accountTypeValidation(currentScreen, keycloakAuth),
        currentScreen,
      };
    case AZURE_ONBOARDING_STEPS.CREATE_APP:
      return {
        errors: {},
        currentScreen,
      };
    case AZURE_ONBOARDING_STEPS.APP_REGISTRATION:
      return {
        errors: await appRegistrationValidation(currentScreen),
        currentScreen,
      };
    case AZURE_ONBOARDING_STEPS.BILLING_EXPORT:
      return {
        errors: billingExportValidation(currentScreen),
        currentScreen,
      };
    case AZURE_ONBOARDING_STEPS.PROCESS_DATA:
      return {
        errors: {},
        currentScreen,
      };
    case AZURE_ONBOARDING_STEPS.VALIDATE_ACCOUNT:
      return {
        errors: {},
        currentScreen,
      };
    default:
      return {
        errors: {},
        currentScreen,
      };
  }
}
