import React, { useEffect, useState } from 'react';
import { MainDashboardProvider } from './contexts/mainDashboardContext.jsx';
import MainDashboard from 'app/containers/MainDashboard/MainDashboard.jsx';
import CustomDashboardWrapper from 'usage/containers/CustomDashboard/components/CustomDashboardWrapper.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
const MainDashboardPage = () => {
  const { usageStore } = useRootStore();
  const { customDashboardModel } = usageStore.customDbSubStore;
  const { dashboards } = customDashboardModel;
  const [defaultDashboard, setDefaultDashboard] = useState(null);

  useEffect(() => {
    const dashboard = dashboards.find((d) => d.isDefault);
    setDefaultDashboard(dashboard);
  }, [dashboards]);
  return (
    <>
      {defaultDashboard ? (
        <CustomDashboardWrapper id={defaultDashboard.uuid} />
      ) : (
        <MainDashboardProvider>
          <MainDashboard />
        </MainDashboardProvider>
      )}
    </>
  );
};

export default MainDashboardPage;
