import React from 'react';
import { ONBOARDING_FIELDS } from '../utils/OnboardingConstants';

export const GCP_ONBOARDING_STEPS = {
  ACCOUNT_TYPE: 'ACCOUNT_TYPE',
  CREATE_APP: 'CREATE_APP',
  GCP_DETAILS: 'GCP_DETAILS',
  K8S_INTEGRATION: 'K8S_INTEGRATION',
  PROCESS_DATA: 'PROCESS_DATA',
};

export const GCP_ONBOARDING_STEPS_SHORT_TITLES = {
  CREATE_APP: (
    <>
      Create Service
      <br /> Account
    </>
  ),
  GCP_DETAILS: 'GCP Details',
  K8S_INTEGRATION: 'K8s Integration',
  PROCESS_DATA: 'Process data',
};

export const GCP_ONBOARDING_FIELDS = {
  ...ONBOARDING_FIELDS,
  PROJECT_ID: 'projectId',
  BILLING_DATASET_NAME: 'billingDatasetName',
  BILLING_TABLE_NAME: 'billingTableName',
  SERVICE_ACCOUNT_KEY: 'serviceAccountKey',
  CURRENCY_TYPE: 'currencyType',
  K8S_PROJECT_ID: 'k8sProjectId',
  K8S_DATASET_ID: 'k8sDatasetId',
};

export const GCP_ONBOARDING_STEP_TO_FIELDS = {
  [GCP_ONBOARDING_STEPS.ACCOUNT_TYPE]: [
    ONBOARDING_FIELDS.RESELLER_CUSTOMER,
    ONBOARDING_FIELDS.CUSTOMER_ADMIN_EMAIL,
    ONBOARDING_FIELDS.CUSTOMER_DOMAIN,
  ],
  [GCP_ONBOARDING_STEPS.GCP_DETAILS]: [
    GCP_ONBOARDING_FIELDS.ACCOUNT_NAME,
    GCP_ONBOARDING_FIELDS.PROJECT_ID,
    GCP_ONBOARDING_FIELDS.BILLING_DATASET_NAME,
    GCP_ONBOARDING_FIELDS.BILLING_TABLE_NAME,
    GCP_ONBOARDING_FIELDS.SERVICE_ACCOUNT_KEY,
  ],
};
