export enum BrandTypes {
  Anodot = 'Anodot',
  Umbrella = 'Umbrella',
}

export enum Themes {
  Snow = 'snow-theme',
  ProcureIT = 'procureit-theme',
  Default = 'default-theme'
}
