import React from 'react';
import moment from 'moment';
import { ICONS } from '@pileus-cloud/anodot-frontend-common';

export const BILLINGS_API_NAME = 'billings';
export const RECOMMENDATIONS_ROOT = '/api/v1/recommendationsNew';
export const RECOMMENDATIONS_HEATMAP_ROOT = `${RECOMMENDATIONS_ROOT}/heatmap`;
export const RECOMMENDATIONS_LIST_ROOT = `${RECOMMENDATIONS_ROOT}/list`;
export const RECOMMENDATIONS_VIEWS_ROOT = `${RECOMMENDATIONS_ROOT}/views`;
export const RECOMMENDATION_ACTIONS_ROOT = `${RECOMMENDATIONS_ROOT}/user-action`;
export const RECOMMENDATION_BULK_ACTIONS_ROOT = `${RECOMMENDATIONS_ROOT}/bulk_user_action`;

export const STALE_TIME = 60 * 60 * 1000;

export const DEFAULT_TARGET_WASTE = 10;

export const TARGET_WASTE = 'target_waste';

export const SORT_BY_OPTIONS = {
  title: 'Sort by',
  items: {
    SAVINGS: { property: 'savings', label: 'Saving Amount', symbol: '$' },
    RECS_COUNT: { property: 'recs_count', label: 'Number of Recommendations', symbol: '#' },
  },
};

export const GROUP_BY_OPTIONS = {
  title: 'Group by',
  items: {
    BUSINESS_MAP: { id: 'business_map', label: 'Business Map', icon: ICONS.pieChart.name },
    CAT_ID: { id: 'cat_id', label: 'Category', icon: ICONS.memo.name },
    COST_CENTER: { id: 'cost_center', label: 'Cost Center', icon: ICONS.listTree.name },
    CUSTOMER: { id: 'customer', label: 'Customer', icon: ICONS.user.name },
    CUSTOM_TAGS: { id: 'custom_tags', label: 'Custom Tags', icon: ICONS.tag.name },
    ENRICHMENT_TAGS: { id: 'enrichment_tags', label: 'Enrichment Tags', icon: ICONS.tags.name },
    INSTANCE_TYPE: { id: 'instance_type', label: 'Instance Type', icon: ICONS.gearsRegular.name },
    LINKED_ACCOUNT_ID: { id: 'linked_account_id', label: 'Linked Account', icon: ICONS.link.name },
    REGION: { id: 'region', label: 'Region', icon: ICONS.earth.name },
    RESOURCE_ID: { id: 'resource_id', label: 'Resource Name', icon: ICONS.server.name },
    SERVICE: { id: 'service', label: 'Service', icon: ICONS.cloud.name },
    TYPE_ID: { id: 'type_id', label: 'Type', icon: ICONS.list.name },
    VIRTUAL_TAGS: { id: 'virtual_tags', label: 'Virtual Tags', icon: ICONS.tags.name },
  },
};

export const FILTERS = {
  ASSIGNEE: { id: 'assignee', label: 'Assignee' },
  ANNUAL_SAVINGS_GREATER_THAN: { id: 'annual_savings_greater_than', label: 'Annual Savings Greater Than' },
  AGE_RANGE: { id: 'age_range', label: 'Age Range' },
  BUSINESS_MAP: { id: 'business_map', label: 'Business Map' },
  CATEGORIES: { id: 'cat_id', label: 'Categories' },
  CLOSED_AND_DONE_RECS_DATES: { id: 'closed_and_done_recs_dates', label: 'Closed and Done recommendations dates' },
  COST_CENTER: { id: 'cost_center', label: 'Cost Center' },
  CUSTOM_TAGS: { id: 'custom_tags', label: 'Custom Tags' },
  ENRICHMENT_TAGS: { id: 'enrichment_tags', label: 'Enrichment Tags' },
  INSTANCE_TYPE: { id: 'instance_type', label: 'Instance Type' },
  IS_OPEN: { id: 'is_open', label: 'Status' },
  IS_STARRED: { id: 'is_starred', label: 'Starred' },
  LINKED_ACCOUNT_ID: { id: 'linked_account_id', label: 'Linked Account' },
  OPEN_RECS_CREATION_DATE: { id: 'open_recs_creation_date', label: 'Open recommendation creation dates' },
  RECOMMENDED_INSTANCE_TYPE: { id: 'recommended_instance_type', label: 'Recommended Instance Type' },
  REGION: { id: 'region', label: 'Region' },
  RESOURCE_ID: { id: 'resource_id', label: 'Resource Name' },
  SERVICES: { id: 'service', label: 'Services' },
  STATUS_FILTER: { id: 'status_filter', label: 'Status Filter' },
  TYPES: { id: 'type_id', label: 'Types' },
  USER_STATUS: { id: 'user_status', label: 'User Status' },
  VIRTUAL_TAGS: { id: 'virtual_tags', label: 'Virtual Tags' },
  COST_MODE: { id: 'cost_mode', label: 'Cost Mode' },
};

export const DASHBOARD_FILTERS = {
  linkedaccid: FILTERS.LINKED_ACCOUNT_ID,
  region: FILTERS.REGION,
  resourceid: FILTERS.RESOURCE_ID,
  businessmapping: FILTERS.BUSINESS_MAP,
  accounttag: FILTERS.ENRICHMENT_TAGS,
  customtags: FILTERS.CUSTOM_TAGS,
  virtualcustomtags: FILTERS.VIRTUAL_TAGS,
  instancetype: FILTERS.INSTANCE_TYPE,
};

export const CLOSED_AND_DONE_RECS_DATES_FILTERS = {
  LAST_UPDATE_DATE: 'last_update_date',
  OPERATOR: 'operator',
  CREATION_DATE: 'creation_date',
};

export const CLOSED_AND_DONE_RECS_DATES_OPERATOR = {
  AND: 'And',
  OR: 'Or',
};

export const USER_STATUS_FILTERS = {
  filterLabel: FILTERS.USER_STATUS.label,
  ITEMS: {
    ALL: { id: 'all', label: 'All' },
    EXCLUDED: { id: 'excluded', label: 'Excluded' },
    DONE: { id: 'done', label: 'Done' },
  },
};

export const DROPDOWN_MAIN_FILTERS = {
  CATEGORIES: { id: 'cat_id', label: 'Categories', icon: ICONS.memo.name },
  TYPES: { id: 'type_id', label: 'Types', icon: ICONS.list.name },
  SERVICES: { id: 'service', label: 'Services', icon: ICONS.cloud.name },
  PERIOD: { id: 'period', label: 'Period' },
};

export const DROPDOWN_ADDITIONAL_FILTERS = {
  // ASSIGNEE: { id: 'assignee', label: 'Assignee', icon: ICONS.clipboardUser.name, order: 10 }, // NOT For MVP
  // COST_CENTER: {
  //   id: 'cost_center',
  //   label: 'Cost Center',
  //   icon: ICONS.listTree.name,
  //   excludedIcon: ICONS.regularListTreeSlash.name,
  //   order: 1,
  // },
  CUSTOM_TAGS: {
    id: 'custom_tags',
    label: 'Tags',
    icon: ICONS.tag.name,
    excludedIcon: ICONS.tagExcluded.name,
    subMenu: true,
    hideSelectAll: true,
    order: 7,
  },
  // BUSINESS_MAP: { id: 'business_map', label: 'Business Map', icon: ICONS.pieChart.name, order: 11 },
  ENRICHMENT_TAGS: {
    id: 'enrichment_tags',
    label: 'Enrichment Tags',
    icon: ICONS.tags.name,
    excludedIcon: ICONS.enrTagExcluded.name,
    subMenu: true,
    hideSelectAll: true,
    order: 8,
  },
  INSTANCE_TYPE: {
    id: 'instance_type',
    label: 'Instance Type',
    icon: ICONS.gearsRegular.name,
    excludedIcon: ICONS.modelsExcluded.name,
    hideSelectAll: true,
    order: 4,
  },
  LINKED_ACCOUNT_ID: {
    id: 'linked_account_id',
    label: 'Linked Account',
    excludedIcon: ICONS.linkSlash.name,
    icon: ICONS.link.name,
    hideSelectAll: true,
    order: 2,
  },
  REGION: {
    id: 'region',
    label: 'Region',
    icon: ICONS.earth.name,
    hideSelectAll: true,
    excludedIcon: ICONS.solidEarthSlash.name,
    order: 3,
  },
  // RECOMMENDED_INSTANCE_TYPE: {
  //   id: 'recommended_instance_type',
  //   label: 'Recommended Instance Type',
  //   icon: ICONS.gearsRegular.name,
  //   order: 5,
  // },
  RESOURCE_ID: {
    id: 'resource_id',
    label: 'Resource Name',
    icon: ICONS.server.name,
    excludedIcon: ICONS.lightServerSlash.name,
    hideSelectAll: true,
    order: 6,
  },
  // VIRTUAL_TAGS: {
  //   id: 'virtual_tags',
  //   label: 'Virtual Tags',
  //   icon: ICONS.tags.name,
  //   excludedIcon: ICONS.enrTagExcluded.name,
  //   order: 9,
  // },
};

export const SWITCH_BUTTON_STATUSES = {
  ALL: 'All',
  OPEN: 'Open',
  COMPLETED: 'Compl.✓',
  DONE: 'Done',
  UN_DONE: 'Undone',
  EXCLUDED: 'Excluded',
  NOT_EXCLUDED: 'Not Exc.',
  STARRED: 'Starred',
  NOT_STARRED: 'Not Starred',
};

export const STATUS_FILTER_PRESETS = {
  POTENTIAL_SAVINGS: {
    id: 'potential_savings',
    label: 'Potential Savings',
    displayName: 'Potential Savings',
    tooltip: (
      <div>
        <i>Open</i> and <i>Undone</i> and <i>Not Excluded</i>
      </div>
    ),
  },
  ACTUAL_SAVINGS: {
    id: 'actual_savings',
    label: 'Actual Savings (Savings Tracker)',
    displayName: 'Actual Savings',
    tooltip: (
      <div>
        (<i>Completed</i> or <i>Done</i>) and <i>Not Excluded</i>
      </div>
    ),
  },
  EXCLUDED: {
    id: 'excluded',
    label: 'Excluded',
    displayName: 'Excluded',
    tooltip: (
      <div>
        <i>Excluded</i> and (<i>Completed</i> or <i>Open</i>)
      </div>
    ),
  },
  USER_ACTIONS: {
    id: 'user_actions',
    label: 'User Actions',
    displayName: 'User Actions',
    tooltip: (
      <div>
        <i>Done</i> and <i>Excluded</i>
      </div>
    ),
  },
  CUSTOM_SETTINGS: {
    id: 'custom',
    label: 'Custom Settings',
    displayName: 'Custom Settings',
    tooltip: '',
  },
};

export const tooltipTextForCustomStatusFilter = (filters) => {
  const isOpen = filters[FILTERS.IS_OPEN.id];
  const isExcluded = filters[FILTERS.USER_STATUS.id][USER_STATUS_FILTERS.ITEMS.EXCLUDED.id];
  const isDone = filters[FILTERS.USER_STATUS.id][USER_STATUS_FILTERS.ITEMS.DONE.id];

  return (
    <div>
      {isOpen === true ? (
        <i>{SWITCH_BUTTON_STATUSES.OPEN}</i>
      ) : isOpen === false ? (
        <i>{SWITCH_BUTTON_STATUSES.COMPLETED}</i>
      ) : (
        <>
          <i>{SWITCH_BUTTON_STATUSES.OPEN}</i> and <i>{SWITCH_BUTTON_STATUSES.COMPLETED}</i>
        </>
      )}
      {}
      {isDone === true ? (
        <>
          &nbsp;and <i>{SWITCH_BUTTON_STATUSES.DONE}</i>
        </>
      ) : isDone === false ? (
        <>
          &nbsp;and <i>{SWITCH_BUTTON_STATUSES.UN_DONE}</i>
        </>
      ) : (
        <>
          &nbsp;and <i>{SWITCH_BUTTON_STATUSES.DONE}</i> and <i>{SWITCH_BUTTON_STATUSES.UN_DONE}</i>
        </>
      )}
      {}
      {isExcluded === true ? (
        <>
          &nbsp;and <i>{SWITCH_BUTTON_STATUSES.EXCLUDED}</i>
        </>
      ) : isExcluded === false ? (
        <>
          &nbsp;and <i>{SWITCH_BUTTON_STATUSES.NOT_EXCLUDED}</i>
        </>
      ) : (
        <>
          &nbsp;and <i>{SWITCH_BUTTON_STATUSES.EXCLUDED}</i> and <i>{SWITCH_BUTTON_STATUSES.NOT_EXCLUDED}</i>
        </>
      )}
    </div>
  );
};

export const SWITCH_BUTTON_LABELS = {
  STATUS: 'Status',
  DONE: 'Done',
  EXCLUDED: 'Excluded',
  STARRED: 'Starred',
};

export const getUserStatusSwitchButtons = ({ isOpen, isDone, isExcluded, isStateDisabled }) => ({
  STATUS: {
    switchLabel: SWITCH_BUTTON_LABELS.STATUS,
    infoIconTooltipText: 'Show All recommendations, or Filter by Status',
    optionsList: {
      OPEN: { label: SWITCH_BUTTON_STATUSES.OPEN, isSelected: isOpen === true },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isOpen === null },
      COMPLETED: { label: SWITCH_BUTTON_STATUSES.COMPLETED, isSelected: isOpen === false },
    },
    isDisabled: isStateDisabled,
  },
  DONE: {
    switchLabel: SWITCH_BUTTON_LABELS.DONE,
    infoIconTooltipText: 'Show recommendations marked as done, or show all',
    icon: ICONS.clipboardCheck.name,
    optionsList: {
      UN_DONE: { label: SWITCH_BUTTON_STATUSES.UN_DONE, isSelected: isDone === false },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isDone === null },
      DONE: { label: SWITCH_BUTTON_STATUSES.DONE, isSelected: isDone === true },
    },
    isDisabled: isStateDisabled,
  },
  EXCLUDED: {
    switchLabel: SWITCH_BUTTON_LABELS.EXCLUDED,
    infoIconTooltipText: 'Filter out excluded recommendations',
    icon: ICONS.excluded.name,
    optionsList: {
      NOT_EXCLUDED: { label: SWITCH_BUTTON_STATUSES.NOT_EXCLUDED, isSelected: isExcluded === false },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isExcluded === null },
      EXCLUDED: { label: SWITCH_BUTTON_STATUSES.EXCLUDED, isSelected: isExcluded === true },
    },
    isDisabled: isStateDisabled,
  },
});

export const getStarredSwitchButtons = ({ isStarred }) => ({
  STARRED: {
    switchLabel: SWITCH_BUTTON_LABELS.STARRED,
    infoIconTooltipText: 'Filter out recommendations you have starred earlier',
    optionsList: {
      STARRED: { label: SWITCH_BUTTON_STATUSES.STARRED, iconName: ICONS.star.name, isSelected: isStarred === true },
      ALL: { label: SWITCH_BUTTON_STATUSES.ALL, isSelected: isStarred === null || isStarred === undefined },
      NOT_STARRED: {
        label: SWITCH_BUTTON_STATUSES.NOT_STARRED,
        iconName: ICONS.regularStarSlash.name,
        isSelected: isStarred === false,
      },
    },
  },
});

export const NO_WASTE_RECOMMENDATION_TYPES = [
  'ecs-fargate',
  'azure-app-service-reserved-capacity',
  'k8s-workload-rightsizing',
  'eks-extended-support',
  'rds-extended-support',
  'azure-k8s-workload-rightsizing',
];

const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_RANGE_OPTIONS = {
  CUSTOM: { value: 'custom', label: 'Custom' },
  LAST_DAY: { value: 'previous_day', label: 'Last day' },
  SEVEN_DAYS: { value: 'last_7_days', label: 'Last 7 Days' },
  PREVIOUS_MONTH: { value: 'previous_month', label: 'Previous month' },
  PREVIOUS_QUARTER: { value: 'previous_quarter', label: 'Previous quarter' },
  MTD: { value: 'mtd', label: 'MTD' },
  QTD: { value: 'qtd', label: 'QTD' },
  YTD: { value: 'ytd', label: 'YTD' },
  LAST_60_DAYS: { value: 'last_60_days', label: 'Last 60 days' },
  LAST_90_DAYS: { value: 'last_90_days', label: 'Last 90 days' },
  LAST_6_MONTHS: { value: 'last_6_months', label: 'Last 6 months' },
  LAST_12_MONTHS: { value: 'last_12_months', label: 'Last 12 months' },
};

export function convertRangeToDates(dateRange) {
  const periodTime = dateRange?.value;

  let date;
  let endDate = moment();
  const today = moment();

  switch (periodTime) {
    case DATE_RANGE_OPTIONS.LAST_DAY.value:
      date = today.subtract(1, 'day');
      break;
    case DATE_RANGE_OPTIONS.SEVEN_DAYS.value:
      date = today.subtract(7, 'days');
      break;
    case DATE_RANGE_OPTIONS.PREVIOUS_MONTH.value:
      date = moment().subtract(1, 'month').startOf('month');
      endDate = moment().subtract(1, 'month').endOf('month');
      break;
    case DATE_RANGE_OPTIONS.PREVIOUS_QUARTER.value:
      date = today.subtract(1, 'quarter').startOf('quarter');
      endDate = date.clone().endOf('quarter');
      break;
    case DATE_RANGE_OPTIONS.MTD.value:
      date = today.startOf('month');
      break;
    case DATE_RANGE_OPTIONS.QTD.value:
      date = today.startOf('quarter');
      break;
    case DATE_RANGE_OPTIONS.YTD.value:
      date = today.startOf('year');
      break;
    case DATE_RANGE_OPTIONS.LAST_60_DAYS.value:
      date = today.subtract(60, 'day');
      break;
    case DATE_RANGE_OPTIONS.LAST_90_DAYS.value:
      date = today.subtract(90, 'day');
      break;
    case DATE_RANGE_OPTIONS.LAST_6_MONTHS.value:
      date = today.subtract(6, 'month');
      break;
    case DATE_RANGE_OPTIONS.LAST_12_MONTHS.value:
      date = today.subtract(1, 'year');
      break;
    // case DATE_RANGE_OPTIONS.All_TIMES.value:
    //   date = moment('2020-01-01');
    //   break;
    default:
      return 'null';
  }

  return {
    from: date.format(DATE_FORMAT),
    to: (endDate || moment()).format(DATE_FORMAT),
    range: dateRange,
  };
}

export const DEFAULT_RECS_DATE_RANGE = {
  from: convertRangeToDates(DATE_RANGE_OPTIONS.LAST_12_MONTHS).from,
  to: convertRangeToDates(DATE_RANGE_OPTIONS.LAST_12_MONTHS).to,
  range: DATE_RANGE_OPTIONS.LAST_12_MONTHS,
};

export const PERIOD_TYPE = {
  until: 'until',
  nextTime: 'forNextTime',
};

export const ACTION_STATUS = {
  done: 'done',
  undone: 'undone',
  excluded: 'excluded',
  include: 'include',
  star: 'star',
};

export const ACTION_TYPE = {
  exclude: 'exclude',
  include: 'include',
  unstar: 'unstar',
  star: 'star',
};

export const COST_CONFIGURATION = {
  AMORTIZED: 'amortized',
  NET_AMORTIZED: 'netAmortized',
  NET_UNBLENDED: 'netUnblended',
  UNBLENDED: 'unblended',
};

export const ACTION_SUCCESS_MESSAGE = {
  star: "Recommendation marked successfully as 'Star', filter by 'Star' to view it",
  unstar: "Recommendation is no longer marked as 'Star'",
  done: "Recommendation marked successfully as 'Done’, filter by 'DONE' to view it",
  undone: "Recommendation marked successfully as 'Undone', filter by 'UNDONE' to view it",
  exclude: "Recommendation excluded successfully, filter by 'EXCLUDE' to view it",
  include: "Recommendation is no longer excluded, filter by 'NOT EXC.' to view it",
  label: 'Recommendation labels were updated successfully',
};

export const BULK_ACTION_SUCCESS_MESSAGE = {
  star: "Recommendations marked successfully as 'Star', filter by 'Star' to view them",
  unstar: "Recommendations is no longer marked as 'Star'",
  done: "Recommendations marked successfully as 'Done’, filter by 'DONE' to view them",
  undone: "Recommendations marked successfully as 'Undone', filter by 'UNDONE' to view them",
  exclude: "Recommendations excluded successfully, filter by 'EXCLUDE' to view them",
  include: "Recommendations is no longer excluded, filter by 'NOT EXC.' to view them",
  label: 'Recommendations labeled successfully',
};

export const RESERVED_INSTANCE_TYPES = ['rds-ri', 'elasticache-ri', 'opensearch-ri', 'redshift-ri'];

export const getDashboardFilter = (filters) => {
  if (!filters) {
    return {};
  }
  const externalFilters = {};
  if (filters?.length > 0 && filters[0].filters?.length) {
    filters[0]?.filters?.forEach((filter) => {
      const filterKey = DASHBOARD_FILTERS[filter?.field];
      if (filterKey) {
        externalFilters[filterKey.id] = filter?.values;
      }
    });
  }
  return externalFilters;
};

export const transformKeys = (obj) => {
  const newObj = {};

  Object.keys(obj).forEach((key) => {
    if (key === 'excludedAdditionalFilters') {
      newObj[key] = {};
      Object.keys(obj[key]).forEach((nestedKey) => {
        const newNestedKey = nestedKey.replace(/([A-Z])/g, '_$1').toLowerCase();
        newObj[key][nestedKey] = obj[key][nestedKey];
        newObj[key][newNestedKey] = obj[key][nestedKey];
      });
    } else {
      const newKey = key.replace(/([A-Z])/g, '_$1').toLowerCase();
      newObj[newKey] = obj[key];
    }
  });
  return newObj;
};

export const getColumnHeaderByName = (columnName) => {
  switch (columnName) {
    case 'az':
      return 'AZ';
    case 'dt_type':
      return 'DT Type';
    case 'new_model':
      return 'Recommended Instance Type Family';
    case 'mem_used_percent_statistics_monthly_avg_usage':
      return 'Memory Avg Monthly Utilization';
    case 'cpu_util_statistics_monthly_avg_usage':
      return 'CPU Avg Monthly Utilization';
    case 'max_memory':
      return 'Memory Max';
    case 'cpu_util':
      return 'CPU Utilization';
    case 'ebs_type':
      return 'EBS Type';
    case 'ebs_size':
      return 'EBS Size';
    case 'new_ebs_type':
      return 'Recommended EBS Type';
    case 'detached_ebs_id':
      return 'Detached EBS';
    case 'recommended_instance_type':
      return 'Recommended Instance Type';
    case 'cpu_max':
      return 'Max CPU';
    case 'cpu_p95':
      return 'P95 CPU';
    case 'mem_p95':
      return 'P95 Memory P95';
    case 'mem_max':
      return 'Max Memory';
    case 'max_iops':
      return 'Max IOPS';
    case 'db_type':
      return 'DB Type';
    case 'db_name':
      return 'DB Names';
    case 'primary_db_instance_id':
      return 'Primary DB Instance ID';
    case 'public_ip':
      return 'Public IP';
    case 'recommended_iops':
      return 'Recommended IOPS';
    case 'provisioned_iops':
      return 'Provisioned IOPS';
    case 'operation_system':
    case 'os':
      return 'OS';
    case 'is_current_reserved':
      return 'Current Instance type RI Coverage';
    case 'is_recommended_reserved':
      return 'Target Instance type RI Coverage';
    case 'instance_type_current':
      return 'Current Instance Type';
    case 'instance_type_recommended':
      return 'Recommended Instance Type';
    case 'start_support_date':
      return 'Current version start support date';
    case 'end_support_date':
      return 'Current version end support date';
    default:
      return columnName
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
  }
};
export const RecListColumnsOrder = {
  recommended_instance_type: 1,
  workload: 2,
  cluster: 3,
  namespace: 4,
  compute_resources_recommended: 5,
  workload_kind: 6,
  num_of_containers: 7,
  num_of_pods: 8,
  recommended_version: 9,
  start_of_extended_support_date: 10,
};

export const getSortOrder = (columnName) => RecListColumnsOrder[columnName] || Number.MAX_SAFE_INTEGER;

export const AlternativesColumns = {
  'EC2 Rightsizing': {
    maxOptions: 3,
    properties: [
      { key: 'instance_type', label: 'Recommended Instance Type' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
    ],
  },
  'RDS Rightsizing': {
    maxOptions: 3,
    properties: [
      { key: 'instance_type', label: 'Recommended Instance Type' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
      { key: 'physical_processor', label: 'Physical Processor' },
    ],
  },
  'EC2 Generation Upgrade': {
    maxOptions: 3,
    properties: [
      { key: 'instance_type', label: 'Recommended Instance Type' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
      { key: 'physical_processor', label: 'Physical Processor' },
    ],
  },
  'RDS Generation Upgrade': {
    maxOptions: 3,
    properties: [
      { key: 'instance_type', label: 'Recommended Instance Type' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
    ],
  },
  'S3 Storage Class': {
    maxOptions: 3,
    properties: [
      { key: 'storage_class', label: 'Recommended Instance Type' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
    ],
  },
  'OpenSearch Reserved Instance': {
    maxOptions: 6,
    properties: [
      { key: 'payment_plan', label: 'Recommended RI Plan' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
    ],
  },
  'Redshift Reserved Instance': {
    maxOptions: 6,
    properties: [
      { key: 'payment_plan', label: 'Recommended RI Plan' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
    ],
  },
  'ElastiCache Reserved Instance': {
    maxOptions: 6,
    properties: [
      { key: 'payment_plan', label: 'Recommended RI Plan' },
      { key: 'saving_amount', label: 'Potential Saving', isCost: true },
    ],
  },
};

export const VIEWS_TYPE = {
  Account: 'account',
  Personal: 'personal',
  Organization: 'organization',
};

export const S3AttentionComment = `Single region recommendations affect backup and redundancy. Validate your backup 
  and redundancy requirements before choosing single region storage class`;

export const getSavingColumnHeader = (statusFilter) => {
  if (statusFilter.isDoneOrCompletedOnly) {
    return 'Annual Actual Savings';
  }
  if (statusFilter.isOnlyOpen) {
    return 'Annual Potential Savings';
  }
  return 'Annual Savings';
};

export const Y_AXIS_UNIT = {
  PERCENT: 'percent',
  BYTE: 'byte',
  OTHER: 'other',
};
