import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Input from 'shared/components/andtComponents/Input';
import useDebouncedInput from 'shared/hooks/customHooks/useDebouncedInput';
import { segmentEvent } from 'shared/modules/segmentAndAptrinsicHandler';

const propTypes = {
  searchInputValue: PropTypes.string,
  onSearchChanged: PropTypes.func.isRequired,
};

const SearchInput = ({ searchInputValue = '', onSearchChanged }) => {
  const [input, setInput] = useState(searchInputValue);
  const [debaouncedValue] = useDebouncedInput(input);
  useEffect(() => {
    if (searchInputValue !== debaouncedValue) {
      const shouldReportToSegment = debaouncedValue;
      if (shouldReportToSegment) {
        segmentEvent({
          type: 'search',
          target: `AnomalyDetectionSearch`,
          data: {
            searchValue: debaouncedValue,
          },
        });
      }
      onSearchChanged(debaouncedValue);
    }
  }, [debaouncedValue]);
  return (
    <Input
      name="search"
      placeholder="Search"
      onInput={(e) => setInput(e.target.value)}
      value={input}
      onChange={(e) => setInput(e.target.value)}
      overrideStyles={{
        marginInlineEnd: '40px',
        width: 'auto',
        height: '35px',
        fontFamily: 'Roboto',
        fontSize: '14px',
      }}
    />
  );
};

SearchInput.propTypes = propTypes;
export default SearchInput;
