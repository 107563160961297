import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import uniq from 'lodash/uniq';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';

export const getCSVData = (data, csvColumns) => {
  return data?.map((account) => {
    const formattedAccount = {};
    csvColumns.forEach((col) => {
      formattedAccount[col.title] = !!col.getCellValue ? col.getCellValue(account) : account[col.name];
      if (isNull(formattedAccount[col.title]) || isUndefined(formattedAccount[col.title])) {
        formattedAccount[col.title] = '';
      }
    });

    return formattedAccount;
  });
};

export const getCloudAccounts = (
  data,
  vendorMappingsStore,
  isUncompletedAccountsFetchInProgress,
  onboardingAccounts,
  defaultAccountId,
  getIsAzureFocusMigrationAvailable,
) => {
  if (!data || (isUncompletedAccountsFetchInProgress && !onboardingAccounts)) return [];

  const { onboardingAccountIds, mappedOnboardingAccounts } = getOnboardingAccounts(
    onboardingAccounts,
    vendorMappingsStore,
  );

  const uniqAccounts = data.filter((acc) => !onboardingAccountIds.has(acc.accountId));
  const cloudAccounts = [...mappedOnboardingAccounts, ...uniqAccounts]
    .reduce((accounts, account) => {
      if (account.cloudTypeId === CLOUD_TYPE_IDS.MULTI) {
        return accounts;
      }
      const hasCloudAccountId = account.cloudTypeId || account.cloudTypeId === 0;
      if (!hasCloudAccountId) {
        return accounts;
      }
      accounts.push({
        ...account,
        onboarding: !!account.onboarding,
        isDefault: account.accountId === defaultAccountId,
        isFocusMigrationAvailable: getIsAzureFocusMigrationAvailable(account),
      });
      return accounts;
    }, []);

  return cloudAccounts;
};

export const filterAccountList = ({ selectedChipsIds, accounts, searchText, showUnComplateOnboardingAccounts }) => {
  return accounts
    .filter((item) => {
      const isChipsFilterUnused = !selectedChipsIds?.length;

      if (!searchText && isChipsFilterUnused) {
        return true;
      }

      if (!searchText && !isChipsFilterUnused) {
        return selectedChipsIds.includes(item.cloudTypeId);
      }

      const { accountId, accountName } = item;
      const searchLower = searchText.toLowerCase();
      const matchesSearch =
        (accountId?.toLowerCase() || '').includes(searchLower) ||
        (accountName?.toLowerCase() || '').includes(searchLower);

      if (searchText && isChipsFilterUnused) {
        return matchesSearch;
      }

      return matchesSearch && selectedChipsIds.includes(item.cloudTypeId);
    })
    .filter((account) => {
      if (!showUnComplateOnboardingAccounts) {
        return !account.onboarding;
      }
      return true;
    });
};

export const getAccountsChipFilterValue = (accounts, filteredData, selectedChipsIds) => {
  return uniq(accounts.map((item) => item.cloudTypeId ?? 0)).map((cloudTypeId) => {
    const filtereditemsCount = filteredData.filter((a) => a.cloudTypeId === cloudTypeId).length;

    return {
      cloudTypeId,
      selected: selectedChipsIds.includes(cloudTypeId),
      text: `${filtereditemsCount}`,
    };
  });
};

const getOnboardingAccounts = (onboardingAccounts, vendorMappingsStore) => {
  const onboardingAccountIds = new Set();

  const mappedOnboardingAccounts = (onboardingAccounts || []).map((account) => {
    onboardingAccountIds.add(account.accountId);
    const cloudTypeId = vendorMappingsStore.getIdByDescription(account.provider?.toUpperCase());

    return {
      ...account,
      onboarding: true,
      accountName: account.accountName,
      cloudTypeId,
      accountId: account.accountId,
    };
  });
  return { onboardingAccountIds, mappedOnboardingAccounts };
};
