import { useMutation, useQuery } from '@tanstack/react-query';
import { API } from 'shared/utils/apiMiddleware';
import { GenerationResponseStatus } from "./types.ts";
import apiConstants from "shared/api/apiConstants.ts";
import { FlatObject } from "shared/types/commonTypes.ts";

export type FetchLinkResponse = {
  link?: string;
  status: GenerationResponseStatus;
}
export type GenerateLinkResponse = {
  status: GenerationResponseStatus;
  dagId?: string;
  isFirstTime?: boolean;
}
const root = '/api/v1/users/on-boarding/v2/k8s';
const fetchOneTimeLinkRequest = (dagId?: string): Promise<FetchLinkResponse> => API.get('billings', `${root}/iam?dagId=${dagId}`);

const generateLinkRequest = (
  shouldRecreate?: boolean,
): Promise<GenerateLinkResponse> => API.post('billings', `${root}/start`, { body: { shouldRecreate } });

export const useK8sOnboarding = () => {
  return {
    fetchOneTimeLink: (dagId = "", options: FlatObject) =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useQuery({
        queryKey: [apiConstants.QUERY_KEYS.K8S_ONBOARDING_LINK],
        queryFn: () => fetchOneTimeLinkRequest(dagId),
        ...options,
      }),
    generateLink: () =>
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMutation({
        mutationFn: generateLinkRequest,
      }),
  };
};
