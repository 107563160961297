import React, { useEffect } from 'react';
import { PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import UsageCost from '../components/UsageCost.jsx';
import { Routes } from 'shared/constants/routes.js';
import useHeatMapSummary from 'recommendationsNew/hooks/react-query/useHeatMapSummary.js';
import { getInitialFiltersStateCopy } from 'recommendationsNew/components/heatMap/heatMapFilters/filterStates.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import styles from './costPanels.module.scss';

const AnnualSavings = () => {
  const { updatePanelLoadingState } = useMainDashboardContext();
  const heatmapSummaryHook = useHeatMapSummary();
  const { data: summary, isLoading } = heatmapSummaryHook.fetchHeatMapSummary(getInitialFiltersStateCopy());

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.ANNUAL_POTENTIAL_SAVINGS, isLoading);
  }, [isLoading]);

  return (
    <div className={styles.costPanel}>
      {isLoading ? (
        <Spinner className={styles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <UsageCost
          title={PANELS[PANELS_TYPES.ANNUAL_POTENTIAL_SAVINGS].title}
          value={summary?.potentialAnnualSavings}
          showIcon
          showPercent
          percent={`${summary?.expectedSavingsRatePercent ? summary?.expectedSavingsRatePercent * -1 : 0}`}
          iconText="Current Waste"
          infoValue={PANELS[PANELS_TYPES.ANNUAL_POTENTIAL_SAVINGS].info}
          navigateTo={{ path: `${Routes.RECOMMENDATION_EXPLORER}` }}
          tooltip="Open Waste Detector page"
        />
      )}
    </div>
  );
};

export default AnnualSavings;
