import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';

import ListWithHelp from 'shared/components/andtComponents/ListWithHelp/ListWithHelp';
import Input from 'shared/components/andtComponents/Input';
import { useOnboarding } from 'app/hooks/react-query/useOnboarding';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { GCP_ONBOARDING_FIELDS, GCP_ONBOARDING_STEPS } from '../../GcpOnboardingConstants';
import commonStyles from '../Pages.module.scss';
import K8sIntegrationPageHelp from './K8sIntegrationPageHelp';
import styles from './K8sIntegrationPage.module.scss';
import ErrorModal from '../../../SharedPages/ErrorModal/ErrorModal';
import ValidateModal from '../../../SharedPages/ValidateModal/ValidateModal';
import getOnboardingLinks from '../../../utils/getOnboardingLinks';
import Button from '../../../../../../shared/components/andtComponents/Button';
import { useBrand } from 'app/contexts/Brand/BrandContext';
import { ONBOARDING_FIELDS } from '~/app/containers/Onboarding/utils/OnboardingConstants.js';
import moment from 'moment';
import RadioButtonList from 'shared/components/andtComponents/RadioButtonList';

const K8sIntegrationPage = () => {
  const brand = useBrand();
  const {
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  // wait 60 seconds before redirecting user to next page in case success status is
  // from prev onboarding (airflow updates it in a few sec)
  const [minWaitingTime, setMinWaitingTime] = useState(false);
  const [validationOngoing, setValidationOngoing] = useState(false);
  const [k8sIntegration, setK8sIntegration] = useState(false);
  const hasExpired =
    getValues(ONBOARDING_FIELDS.START_TIME) &&
    moment(getValues(ONBOARDING_FIELDS.START_TIME)).add(24, 'h').isBefore(moment());
  const { fetchOnboardingStatus, restartOnboarding, updateOnboardingParams } = useOnboarding();
  const { mutateAsync: handleUpdateOnboardingParams } = updateOnboardingParams();
  const accountId = getValues(GCP_ONBOARDING_FIELDS.ACCOUNT_ID);
  const validationStarted = getValues(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED);
  const validationStartTrigger = getValues(GCP_ONBOARDING_FIELDS.VALIDATION_START_TRIGGER);
  useEffect(() => {
    if (validationStarted) {
      setMinWaitingTime(false);
      setTimeout(() => {
        setMinWaitingTime(true);
        handleUpdateOnboardingParams({ validationShouldStart: true, accountId });
      }, 60000);
    }
  }, [validationStarted]);
  useWatch(GCP_ONBOARDING_FIELDS.K8S_DATASET_ID);
  useWatch(GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID);
  useWatch(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED);

  const projectId = getValues(GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID);
  const datasetId = getValues(GCP_ONBOARDING_FIELDS.K8S_DATASET_ID);

  useEffect(() => {
    if (projectId || datasetId) {
      setK8sIntegration(true);
    }
  }, [projectId, datasetId]);

  const { data: status } = fetchOnboardingStatus(accountId, {
    refetchInterval: 15 * 1000,
  });
  const handleRestart = restartOnboarding();
  const [validationFailed, setValidationFailed] = useState(false);
  const { validationStatus } = status || {};
  useEffect(() => {
    if (validationStartTrigger) {
      setValidationOngoing(true);
      setTimeout(() => {
        setValidationOngoing(false);
      }, 60 * 1000);
    }
  }, [validationStartTrigger]);
  useEffect(() => {
    const isFailed = !validationOngoing && validationStatus?.status === 'FAILED' && validationStarted;
    const isSuccess = minWaitingTime && validationStatus?.status === 'SUCCESS' && validationStarted;
    if (isSuccess) {
      setValue(GCP_ONBOARDING_FIELDS.SCREEN, GCP_ONBOARDING_STEPS.PROCESS_DATA);
      return;
    }
    if (isFailed) {
      setValidationFailed(true);
    } else {
      setValidationFailed(false);
    }
  }, [status, minWaitingTime, validationOngoing]);

  const renderK8SToggle = () => {
    return (
      <div>
        <p>Display Kubernetes (K8s) labels as part of your account data?</p>
        <RadioButtonList
          className={styles.radioButtonsList}
          options={[
            {
              value: 'no',
              label: 'No',
              primary: true,
            },
            { value: 'yes', label: 'Yes', primary: true },
          ]}
          value={k8sIntegration ? 'yes' : 'no'}
          onChange={(val) => {
            setK8sIntegration(val === 'yes');
            if (val === 'no') {
              setValue(GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID, '');
              setValue(GCP_ONBOARDING_FIELDS.K8S_DATASET_ID, '');
            }
          }}
        />
        {!k8sIntegration && (
          <p>
            Click <b>Validate</b> to proceed with onboarding if you don't want us to collect K8s labels
          </p>
        )}
      </div>
    );
  };

  const renderInput = ({ fieldName, name, label, icon, disabled }) => (
    <div className={commonStyles.inputField}>
      <label htmlFor={name}>
        <span>
          <GenerateIcon iconName={ICONS[icon].name} />
          {label}
        </span>
      </label>
      <div className={commonStyles.inputBox}>
        <div>
          <Input
            disabled={disabled}
            value={getValues(fieldName)}
            isInvalid={!!errors[fieldName]}
            invalidComponent={errors[fieldName]?.message || errors[fieldName]}
            onChange={(e) => setValue(fieldName, e.target.value, { shouldValidate: true })}
            placeholder=""
            name={name}
            type="text"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <ListWithHelp
        styles={{
          listGap: 14,
          helpWidth: 460,
          controlStyles: {
            marginLeft: '-4px',
            borderLeft: 'none',
          },
        }}
        list={[
          {
            render: renderK8SToggle,
            key: 'onboarding-k8s-toggle',
            topSpace: 33,
          },
          {
            render: () =>
              renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.K8S_PROJECT_ID,
                name: 'onboarding-k8s-project-id',
                label: 'Project ID',
                icon: 'cloud',
              }),
            disabled: !k8sIntegration,
            key: 'onboarding-k8s-project-id',
          },
          {
            render: () =>
              renderInput({
                fieldName: GCP_ONBOARDING_FIELDS.K8S_DATASET_ID,
                name: 'onboarding-k8s-dataset-id',
                label: 'GKE data set name',
                icon: 'cloud',
              }),
            disabled: !k8sIntegration,
            key: 'onboarding-k8s-dataset-id',
          },
        ]}
        help={{
          'onboarding-k8s-toggle': K8sIntegrationPageHelp.k8sToggle,
          'onboarding-k8s-project-id': K8sIntegrationPageHelp.projectID(brand.GCPOnboardingBillingDatasetProjectIDHelp),
          'onboarding-k8s-dataset-id': K8sIntegrationPageHelp.datasetID,
        }}
      />
      {validationStarted && getValues(GCP_ONBOARDING_FIELDS.VALIDATION_STARTED) && !validationFailed && (
        <ValidateModal
          title="We are currently validating your account details and collecting billing data"
          noteTitle="This step can take up to 24 hours"
          text={`You can close this window, and we will send you an 
         email after we process your data and it will be visible in ${brand.name}.`}
        />
      )}
      {(validationStarted && validationFailed) || hasExpired ? (
        <ErrorModal status={status} handleRestart={handleRestart} cloudType={CLOUD_TYPE_IDS.GCP} />
      ) : null}
    </div>
  );
};

export default K8sIntegrationPage;
