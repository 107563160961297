import React, { useCallback, useContext, useState } from 'react';
import styles from '../K8sOnboarding.module.scss';
import AddClusterModal from './AddClusterModal.tsx';
import { AddClusterFields } from '../config.ts';
import Button from 'shared/components/andtComponents/Button.tsx';
import { useRootStore } from 'app/contexts/RootStoreContext';
import { getCloudTypeSimpleIcon } from 'app/store/vendorsStore/vendorStoreProvider.tsx';
import { ReactComponent as ClusterIcon } from 'shared/img/icons/k8sCluster.svg';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import { OnboardingContext } from '../OnBoardingContext.tsx';
import { K8Cluster } from '../types.ts';

const ClusterInformationStep: React.FC = () => {
  const { addedClusters, updateCluster, deleteCluster } = useContext(OnboardingContext);
  const [showClusterModal, setShowClusterModal] = useState(false);
  const [activeCluster, setActiveCluster] = useState<K8Cluster | null>(null);
  const {
    usersStore: { currDispUserAccountKey, currDispUserCloudAccountType, getCurrentDisplayedAccountName },
  } = useRootStore();
  const accountName = getCurrentDisplayedAccountName(currDispUserAccountKey);
  const CloudTypeIcon = getCloudTypeSimpleIcon(currDispUserCloudAccountType);

  const onUpdateCluster = (clusterToEdit: null | K8Cluster) => {
    setActiveCluster(clusterToEdit || null);
    setShowClusterModal(true);
  };

  const onClose = useCallback(() => {
    setActiveCluster(null);
    setShowClusterModal(false);
  }, []);

  return (
    <div className={styles.clusterInfoContainer}>
      <div className={styles.stepTitle}>
        <CloudTypeIcon />
        {accountName} ({currDispUserAccountKey})
      </div>
      <div className={styles.addButton} onClick={() => onUpdateCluster(null)} onKeyDown={() => onUpdateCluster(null)}>
        <Button text={'+ Add Cluster'} onClick={() => onUpdateCluster(null)} isTextButton />
      </div>
      <div style={{maxHeight: 310, overflowY: 'auto'}}>
        {addedClusters.map((cluster, index) => (
        <div className={styles.clusterRow} key={index}>
          <div className={styles.clusterTile} key={index}>
            <ClusterIcon />
            {cluster[AddClusterFields.clusterName]}
          </div>
          <div className={styles.clusterActions}>
            <Button
              isTextButton
              automation-id="cluster-delete-button"
              icon={() => <GenerateIcon iconName={ICONS.deleteRegular.name} />}
              onClick={() => {
                deleteCluster(cluster.id as string);
              }}
            />
            <Button
              isTextButton
              className={styles.actionIcon}
              automation-id="cluster-edit-button"
              icon={() => <GenerateIcon iconName={ICONS.edit.name} />}
              onClick={() => {
                onUpdateCluster(cluster);
              }}
            />
          </div>
        </div>
      ))}
      </div>
      {showClusterModal && (
        <AddClusterModal onClose={onClose} activeCluster={activeCluster} updateCluster={updateCluster} />
      )}
    </div>
  );
};

export default ClusterInformationStep;
