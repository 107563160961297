import React, { useEffect, useMemo } from 'react';
import { Action, HierarchicalEntityCategory } from '@anodot-cost/rbac-client';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { usePermissions } from 'app/contexts/PermissionsContext.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import useBudgets from 'usage/containers/Budget/hooks/useBudgets.js';
import { Routes as ROUTES } from 'shared/constants/routes.js';
import { ReactComponent as EmptyBudget } from 'app/containers/MainDashboard/img/empty-budget.svg';
import mainStyles from 'app/containers/MainDashboard/mainDashboardCommon.module.scss';
import { getBudgetAmount } from 'usage/containers/Budget/budgetUtil.js';
import ValueTag from 'app/containers/MainDashboard/components/ValueTag.jsx';
import { NoPermission } from 'app/containers/MainDashboard/components/NoPermission.jsx';
import MonitoringPanelAction from 'app/containers/MainDashboard/panels/MonitoringData/MonitoringPanelAction.jsx';
import { GenerateIcon, ICONS } from '@pileus-cloud/anodot-frontend-common';
import SaveDashboardPanel from 'app/containers/MainDashboard/components/SaveDashboardPanel.jsx';
import { PANEL_COMPONENT_TYPES } from 'usage/containers/CustomDashboard/constants/customDashboardConstants.js';
import styles from './monitoringData.module.scss';
import costStyles from '../costPanels.module.scss';
const BudgetsData = ({ isCostPanel = false, hideSaveDashboard = false }) => {
  const { updatePanelLoadingState } = useMainDashboardContext();
  const { getUserBudget } = useBudgets();
  const { isHasPermission } = usePermissions();
  const hasPermission = isHasPermission(HierarchicalEntityCategory.Budgets, Action.View);
  const { data, isLoading } = getUserBudget();

  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.ANOMALIES_STATS, isLoading);
  }, [isLoading]);

  const budgetsData = useMemo(() => {
    if (!data) {
      return;
    }
    const openBudget = data.filter((budget) => new Date(budget.endDate) >= new Date());
    return {
      total: openBudget.length,
      overBudgetsTotal: openBudget.filter((budget) => budget.totalCost / getBudgetAmount(budget) > 1).length,
    };
  }, [data]);
  const renderContent = () => {
    if (!hasPermission) {
      return <NoPermission isSmallPanel={true} />;
    }
    if (!data?.length || !budgetsData.total) {
      return (
        <div className={styles.emptyState}>
          <EmptyBudget />
          <div className={styles.textWrapper}>
            <span className={styles.simpleText}>We saw you haven’t set up any budgets yet</span>
            <a target="_blank" href={ROUTES.BUDGET}>
              Click here to set one up
            </a>
          </div>
        </div>
      );
    }
    return (
      <div className={classNames(styles.flexData, styles.padding)}>
        <div className={styles.field}>
          {budgetsData.total} <span className={styles.simpleText}>Active Budgets</span>
        </div>
        <ValueTag value={budgetsData?.overBudgetsTotal} description="Over Budget" isSmallTag={true} />
      </div>
    );
  };

  if (isCostPanel) {
    return (
      <div className={classNames(costStyles.costPanel, costStyles.marginLeft, costStyles.limitWidth)}>
        {isLoading ? (
          <Spinner size={SPINNER_SIZES.MEDIUM} />
        ) : (
          <div className={costStyles.budgetWrapper}>
            <GenerateIcon iconName={ICONS.fileChartColumn.name} className={costStyles.icon} />
            <span className={costStyles.content}>
              <span className={costStyles.value}>{budgetsData.total}</span>Active Budgets
            </span>
            <ValueTag value={budgetsData?.overBudgetsTotal} description="Over Budget" isSmallTag={true} />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className={classNames(mainStyles.panelWrapper, styles.budgetWrapper)}>
      {isLoading ? (
        <Spinner size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <>
          <div className={styles.headerFlex}>
            <div className={styles.flex}>
              <div className={styles.header}>Budgets</div>
            </div>
            <div className={styles.flexCenter}>
              {!hideSaveDashboard && (
                <>
                  <MonitoringPanelAction url={ROUTES.BUDGET} />
                  <SaveDashboardPanel panelType={PANEL_COMPONENT_TYPES.ACTIVE_BUDGET} />
                </>
              )}
            </div>
          </div>
          {renderContent()}
        </>
      )}
    </div>
  );
};

BudgetsData.propTypes = {
  isCostPanel: PropTypes.object,
};

export default BudgetsData;
