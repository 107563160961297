import { cloneDeep } from 'lodash';
import { AppCommonFields } from 'shared/constants/appConstants';
import { numberWithCommas, strNumToSize } from 'shared/utils/strUtil';
import {
  CARBON_EMISSIONS_ITEMS,
  CostTrackingConstants,
  getValueFuncByMetricType,
} from 'usage/constants/costAndUsageConstants';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants';
import { AwsCommonFields, AwsQuantityTypes } from 'shared/constants/awsConstants';
import { isEmptyArray } from './arrayUtils';
import LabelCoordinator from '../modules/labelCoordinator';

export const groupDataByParam = (data, param) => {
  if (!data) {
    return [];
  }
  try {
    const groupedData = {};

    data.forEach((item) => {
      const paramValue = item[param];
      if (!groupedData[paramValue]) {
        groupedData[paramValue] = [];
      }
      groupedData[paramValue].push(item);
    });

    return Object.values(groupedData);
  } catch (error) {
    return [];
  }
};

export const groupDataByUsageDate = (data) => groupDataByParam(data, 'usageDate');

export const groupDataBySecondaryGroupBy = (data) => groupDataByParam(data, 'groupBySecondary');

export const addAllParamsToEachRow = (data, paramsSet) =>
  data.map((currItem) => {
    [...paramsSet].forEach((param) => {
      if (!currItem.hasOwnProperty(param)) {
        currItem[param] = 0;
      }
    });
    return currItem;
  });

export const makeChartDataCumulative = (dataWithFullParams, entryAnchorFieldName) => {
  dataWithFullParams.forEach((currItem, index) => {
    if (index === 0) {
      return;
    }
    Object.keys(currItem).forEach((key) => {
      // entryAnchorFieldName is just a name of an item (row)
      if (key === entryAnchorFieldName || typeof currItem[key] !== 'number') {
        return;
      }
      currItem[key] += dataWithFullParams[index - 1][key];
    });
  });
};

export const makeTableDataCumulative = (data) => {
  data.forEach((attribute) => {
    const keysSorted = Object.keys(attribute)
      .filter((key) => typeof attribute[key] === 'number')
      .sort();
    keysSorted.forEach((key, index) => {
      if (index === 0) {
        return;
      }
      attribute[key] += attribute[keysSorted[index - 1]];
    });
  });
};

export const getDisplayKey = (key, groupBy) => {
  if (groupBy?.startsWith(AwsCommonFields.ACCOUNT_TAGS)) {
    return key;
  }
  return LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', key);
};

export const prepareDataForDisplayChart = (
  data,
  entryAnchorFieldName,
  keyFieldName,
  valueFieldName,
  isCumulative = false,
  currentGroupBy = '',
) => {
  const allParamsSet = new Set();
  const getFieldValue = typeof valueFieldName === 'function' ? valueFieldName : (row) => row[valueFieldName];
  const modifiedData = data.map((currItem) => {
    // entryAnchorFieldName is a field which serves the entire items of the current iteration
    const chartEntry = {
      [entryAnchorFieldName]: currItem && currItem.length > 0 ? currItem[0][entryAnchorFieldName] : '',
    };
    for (let i = 0; i < currItem.length; i++) {
      const key = currItem[i][keyFieldName];
      const displayKey = getDisplayKey(key, currentGroupBy);

      if (chartEntry.hasOwnProperty(displayKey)) {
        chartEntry[displayKey] += getFieldValue(currItem[i]);
      } else {
        chartEntry[displayKey] = getFieldValue(currItem[i]);
      }
      allParamsSet.add(displayKey);
    }
    return chartEntry;
  });

  const dataWithFullParams = addAllParamsToEachRow(modifiedData, allParamsSet);
  if (isCumulative) {
    makeChartDataCumulative(dataWithFullParams, entryAnchorFieldName);
  }
  return dataWithFullParams;
};

export const calculateTrendRows = (data, usageType, cloudTypeId) => {
  const copyDataObjectsArray = cloneDeep(data);
  // create a parallel data array but with trend rows
  for (let row = 0; row < copyDataObjectsArray.length; row++) {
    const newTrendRow = copyDataObjectsArray[row];
    const datesKeys = Object.keys(newTrendRow).filter((key) => key !== 'groupBy' && key !== 'linkedAccountId');
    for (let i = 0; i < datesKeys.length; i++) {
      const currKey = datesKeys[i];
      if (i === 0) {
        newTrendRow[currKey] = '-';
      }
      if (i > 0) {
        const prevKey = datesKeys[i - 1];
        if (!data[row][prevKey]) {
          newTrendRow[currKey] = '-';
        } else {
          const costFigure =
            usageType === AwsQuantityTypes.BYTES
              ? strNumToSize(data[row][currKey] - data[row][prevKey], 2, cloudTypeId)
              : data[row][currKey] - data[row][prevKey];
          const percentFigure = (data[row][currKey] / data[row][prevKey] - 1) * 100;
          newTrendRow[currKey] = {
            cost: costFigure,
            percent: percentFigure,
          };
        }
      }
      if (i === datesKeys.length - 1) {
        newTrendRow.groupByOrigin = newTrendRow.groupBy;
        newTrendRow.groupBy = `${newTrendRow.groupBy} Trend`;
      }
    }
  }
  return copyDataObjectsArray;
};

export const addTrendRowToTableData = (origDataObjectArr, usageType, cloudTypeId) => {
  const copyDataObjectsArray = calculateTrendRows(origDataObjectArr, usageType, cloudTypeId);
  // insert new trend rows to original array
  for (const trendRow of copyDataObjectsArray) {
    const originKey = trendRow.groupByOrigin;
    const indexInOrignData = origDataObjectArr.findIndex((data) => data.groupBy === originKey);
    if (indexInOrignData > -1) {
      origDataObjectArr.splice(indexInOrignData + 1, 0, trendRow);
    }
  }
  // remove groupByOrigin column
  origDataObjectArr.forEach((dataObj) => delete dataObj.groupByOrigin);
  return copyDataObjectsArray;
};

export const prepareDataWithTrendsForExport = (data) => {
  return data.map((item) => {
    const { groupBy, ...dates } = item;
    const costOnly = {};

    Object.entries(dates).forEach(([key, value]) => {
      if (typeof value === 'object' && value !== null && 'cost' in value) {
        costOnly[key] = `${value.cost}${value.percent ? ` (${value.percent.toFixed(2)}%)` : ''}`;
      } else {
        costOnly[key] = value;
      }
    });

    return {
      groupBy,
      ...costOnly,
    };
  });
};

export const prepareDataForDisplayTable = ({
  data,
  entryAnchorFieldName,
  keyFieldName,
  valueFieldName,
  isShowTrendRow = false,
  isCumulative = false,
  usageType,
  splitResource = false,
  currentGroupBy,
  isTableOnlyTrendRow = false,
  cloudTypeId,
}) => {
  const getValue = typeof valueFieldName === 'function' ? valueFieldName : (row) => row[valueFieldName];
  const uniqueKeys = data.reduce((acc, curr) => {
    const k = curr.map((item) => item[entryAnchorFieldName]);
    acc = acc ? [...acc, ...k] : [...k];
    return Array.from(new Set(acc));
  }, []);
  const objData = data.reduce((acc, currData) => {
    const dataByKey = { ...acc };
    currData.forEach((item) => {
      let keyFName = item[keyFieldName];
      let keyEntryAnchorFieldName = item[entryAnchorFieldName];
      if (currentGroupBy === 'resourceid') {
        keyFName =
          item.resourceName && item.resourceName !== 'Not Available' && item.resourceName.trim().length > 0
            ? `${item.resourceName} (${item.resourceId})`
            : item.resourceId;
      }
      if (currentGroupBy?.startsWith(AwsCommonFields.WORKLOAD) && item.groupBy !== 'Other Workload Types') {
        keyFName = `${item.creatorKindName}:${item.groupBy}`;
      }
      if (currentGroupBy === AwsCommonFields.DIVISION) {
        keyFName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item.groupBy);
        keyEntryAnchorFieldName = LabelCoordinator.getDataKeyDisplayName(
          'cueDisplayCoordinator',
          keyEntryAnchorFieldName,
        );
      }
      if (currentGroupBy === AwsCommonFields.SERVICE) {
        keyFName = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', item.groupBy);
        const newKeyEntryAnchor = LabelCoordinator.getDataKeyDisplayName(
          'cueDisplayCoordinator',
          keyEntryAnchorFieldName,
        );
        if (newKeyEntryAnchor !== keyEntryAnchorFieldName) {
          if (dataByKey[keyFName]) {
            delete dataByKey[keyFName][keyEntryAnchorFieldName];
          }
          keyEntryAnchorFieldName = newKeyEntryAnchor;
        }
      }
      const val = +getValue(item);
      if (!dataByKey[keyFName]) {
        dataByKey[keyFName] = {};
        uniqueKeys.forEach((key) => {
          let keyFormatted = key;
          if (currentGroupBy === AwsCommonFields.DIVISION) {
            keyFormatted = LabelCoordinator.getDataKeyDisplayName('cueDisplayCoordinator', key);
          }
          dataByKey[keyFName][keyFormatted] = 0;
        });
      }
      if (!dataByKey[keyFName][keyEntryAnchorFieldName]) {
        dataByKey[keyFName][keyEntryAnchorFieldName] = 0;
      }
      dataByKey[keyFName][keyEntryAnchorFieldName] += val || 0;
    });
    return dataByKey;
  }, {});
  let mainToSecondGroupByData = Object.entries(objData).map(([key, value]) => ({ [keyFieldName]: key, ...value }));

  if (isCumulative) {
    makeTableDataCumulative(mainToSecondGroupByData);
  }
  if (isTableOnlyTrendRow) {
    mainToSecondGroupByData = calculateTrendRows(mainToSecondGroupByData, usageType);
  } else if (isShowTrendRow) {
    addTrendRowToTableData(mainToSecondGroupByData, usageType, cloudTypeId);
  }
  if (currentGroupBy === 'resourceid' && splitResource) {
    mainToSecondGroupByData.forEach((row) => {
      const [name, id] = row.groupBy.replace(/\)$/, '').split(' (');
      if (id) {
        row.resourceName = name;
        row.groupBy = id;
      }
    });
  }
  return mainToSecondGroupByData.filter((row) => !getEveryNumericAttributeIsEmpty(row));
};

const getEveryNumericAttributeIsEmpty = (obj) => {
  const numericColumns = Object.values(obj).filter((val) => typeof val === 'number');
  if (numericColumns.length === 0) {
    return false;
  }
  return numericColumns.every((val) => {
    return !val;
  });
};

// "Others" helper functions

function addOthers(filteredKeys, filteredDataKeys, modifiedDailyBalances, newKeyFieldName) {
  filteredDataKeys.push({ name: newKeyFieldName, cost: 0 });
  modifiedDailyBalances.forEach((dataEntry) => {
    const arrDataEntry = Object.entries(dataEntry);
    const newDataEntry = arrDataEntry.reduce(
      (acc, [key, val]) => {
        if (!filteredKeys.includes(key)) {
          const validVal = typeof val === 'number' ? val : 0;
          acc[newKeyFieldName] += validVal;
        }
        return acc;
      },
      { [newKeyFieldName]: 0 },
    );
    Object.assign(dataEntry, { ...dataEntry, ...newDataEntry });
  });
}

function removeOthers(filteredKeys, filteredDataKeys, newKeyFieldName, modifiedDailyBalances) {
  const index = filteredKeys.indexOf(newKeyFieldName);
  if (index > -1) {
    filteredDataKeys.splice(index, 1);
  }
  modifiedDailyBalances.forEach((balance) => {
    if (newKeyFieldName in balance) {
      delete balance[newKeyFieldName];
    }
  });
}

export const updateDataIfOthersIsChecked = (
  modifiedDailyBalances,
  filteredKeys,
  filteredDataKeys,
  newKeyFieldName,
  isOtherChecked,
) => {
  const showOthers = isOtherChecked;
  if (!filteredKeys || filteredKeys.length === 0) {
    return;
  }
  if (showOthers) {
    removeOthers(filteredKeys, filteredDataKeys, newKeyFieldName, modifiedDailyBalances);
    addOthers(filteredKeys, filteredDataKeys, modifiedDailyBalances, newKeyFieldName);
  } else {
    removeOthers(filteredKeys, filteredDataKeys, newKeyFieldName, modifiedDailyBalances);
  }
};

export const updateDataIfOthersIsCheckedV2 = (data, uncheckedKeys, isOtherChecked) => {
  const showOthers = isOtherChecked;
  let newData = cloneDeep(data);
  newData.forEach((balance) => {
    delete balance[CostTrackingConstants.OTHERS];
  });
  if (showOthers) {
    newData = newData.map((dataEntry) => {
      const arrDataEntry = Object.entries(dataEntry);
      const newDataEntry = arrDataEntry.reduce((acc, [key, val]) => {
        if (uncheckedKeys.includes(key)) {
          const validVal = typeof val === 'number' ? val : 0;
          acc[CostTrackingConstants.OTHERS] = (acc[CostTrackingConstants.OTHERS] || 0) + validVal;
        }
        return acc;
      }, {});
      return { ...dataEntry, ...newDataEntry };
    });
  }
  return newData;
};

export const prepareDataKeys = (data, keyFieldName, valueFieldName, sortByLastColumn = false) => {
  if (!data) {
    return [];
  }
  const arrOfAllKeys = [];
  data.forEach(({ usageDate, ...rest }) => {
    arrOfAllKeys.push(...Object.keys(rest));
  });
  const arrUniqueKeys = Array.from(new Set(arrOfAllKeys));
  let result = [];
  arrUniqueKeys.forEach((key) => {
    const dataKeyObj = {};
    dataKeyObj[keyFieldName] = key;
    dataKeyObj[valueFieldName] = 0;
    if (sortByLastColumn && data.length > 0) {
      dataKeyObj[valueFieldName] += data[data.length - 1][key] || 0;
    } else {
      data.forEach((item) => {
        dataKeyObj[valueFieldName] += item[key] || 0;
      });
    }
    result.push(dataKeyObj);
  });
  result = result.sort((a, b) => parseFloat(b[valueFieldName]) - parseFloat(a[valueFieldName]));
  return result;
};

export const prepareSecondaryGroupDataKeys = (data, keyFieldName, valueFieldName) => {
  if (!data) {
    return [];
  }
  let result = Object.values(
    data.reduce((a, { groupBySecondary, ...rest }) => {
      Object.entries(rest).forEach(([k, v]) => {
        a[k] = a[k] || { [keyFieldName]: k, [valueFieldName]: 0 };
        a[k][valueFieldName] += Number(v);
      });
      return a;
    }, {}),
  );
  result = result.sort((a, b) => parseFloat(b[valueFieldName]) - parseFloat(a[valueFieldName]));
  return result;
};

export const prepareTableHeaders = (data) => {
  const dataKeys = data.map((row) => Object.keys(row));
  const result = [...new Set([].concat(...dataKeys))];

  const columns = result.map((elem) => ({
    name: elem,
    title: elem,
  }));

  const columnWidths = result.map((elem) => ({
    columnName: elem,
    width: 150,
  }));

  const tableColumnExtensions = result.map((elem) => ({
    columnName: elem,
    align: 'center',
  }));
  const indexOfGroupBy = columns.findIndex((col) => col.name === 'groupBy');
  if (indexOfGroupBy > -1) {
    columns[indexOfGroupBy] = { name: 'groupBy', title: 'Group By' };
  }
  return { columns, columnWidths, tableColumnExtensions };
};

export const prepareDataToCauCostChart = (baseData, params, state) => {
  const { displayMetricTypes, ...rest } = params;
  const isSecondary = rest.secondaryGroupByLevel !== 'usagedate';
  const data = isSecondary ? groupDataBySecondaryGroupBy(baseData) : groupDataByUsageDate(baseData);
  const currDisplyedMetricType = getValueFuncByMetricType(displayMetricTypes, state?.SelectedUsageType);
  const modifiedDailyBalances = prepareDataForDisplayChart(
    data,
    isSecondary ? 'groupBySecondary' : AppCommonFields.USAGE_DATE,
    AppCommonFields.GROUP_BY,
    currDisplyedMetricType,
  );
  return modifiedDailyBalances;
};

export const prepareDataToCauCostTable = (baseData, params, state) => {
  const { displayMetricTypes, usageType, currentGroupBy, ...rest } = params;
  const isSecondary = rest.secondaryGroupByLevel !== 'usagedate';
  const data = isSecondary ? groupDataBySecondaryGroupBy(baseData) : groupDataByUsageDate(baseData);
  const currDisplyedMetricType = getValueFuncByMetricType(displayMetricTypes, state?.SelectedUsageType);
  const modifiedDailyBalances = prepareDataForDisplayTable({
    data,
    entryAnchorFieldName: isSecondary ? 'groupBySecondary' : AppCommonFields.USAGE_DATE,
    keyFieldName: AppCommonFields.GROUP_BY,
    valueFieldName: currDisplyedMetricType,
    isShowTrendRow: false,
    isCumulative: false,
    usageType,
    splitResource: false,
    currentGroupBy,
    secondaryGroupByLevel: rest.secondaryGroupByLevel,
  });
  return modifiedDailyBalances;
};
export const calcTotalChartCost = (dataWithTotalCost, isCumulative) => {
  if (!dataWithTotalCost) {
    return 0;
  }
  if (isCumulative) {
    return dataWithTotalCost[dataWithTotalCost.length - 1].totalSum || 0;
  }
  let totalCostForPieChart = 0;
  dataWithTotalCost.forEach((row) => {
    totalCostForPieChart += row.totalSum || 0;
  });
  return totalCostForPieChart;
};
export const calcChartYAxisTopPadding = (maxValue) => {
  let pad = 100;
  if (maxValue <= 0.1) {
    pad = 0;
  } else if (maxValue <= 1) {
    pad = 0.1;
  } else if (maxValue <= 50) {
    pad = 1;
  } else if (maxValue <= 300) {
    pad = 10;
  } else if (maxValue <= 1000) {
    pad = 50;
  }
  return pad;
};
export const prepareDataToAssetsTable = (data) => data.data;
export const prepraeDataToK8sCauCostTable = (baseData) => baseData;
export const prepraeDataToCauResourceTable = (baseData) => baseData;
export const prepareDataToCloudWasteTable = (data) => data.opportunity;

export const formatFiltersToText = (filters, excludedFilters = []) => {
  if (!filters) {
    return { included: [], excluded: [] };
  }
  const excludedNames = excludedFilters.filter((excluded) => !!excluded[1]).map((excluded) => excluded[0]);
  const included = filters.filter((filter) => !excludedNames.includes(filter[0]) && filter[1] && filter[1].length > 0);
  const excluded = filters.filter((filter) => excludedNames.includes(filter[0]) && filter[1] && filter[1].length > 0);
  return { included, excluded };
};

export const isEmptyObj = (obj) => Object.keys(obj).length === 0;

export const countFilters = (fieldToFilterValuesMap, ...separateFilters) => {
  if (!fieldToFilterValuesMap && !separateFilters.length) {
    return 0;
  }
  const filtersValues =
    typeof fieldToFilterValuesMap.values === 'function'
      ? fieldToFilterValuesMap.values()
      : Object.values(fieldToFilterValuesMap);
  return [...filtersValues, ...separateFilters].filter((arr) => !isEmptyArray(arr)).length;
};

export const prepareTotalSumWithOthers = (data, filteredKeys, filteredDataKeys, isShowOthers, isSecondaryGroupBy) => {
  const showOnlyColumnWithValuesSortedBySum = (allData) => {
    const dataWithValues = allData.filter((item) => item.totalSum !== 0);
    return dataWithValues.sort((itemA, itemB) => itemB.totalSum - itemA.totalSum);
  };
  const dataKeysNamesArr = [];
  let maxValue = 0;
  let minValue = 0;
  let maxSingleValue = 0;
  updateDataIfOthersIsChecked(data, filteredKeys, filteredDataKeys, CostTrackingConstants.OTHERS, isShowOthers);
  filteredKeys.forEach((dataKey) => dataKeysNamesArr.push(dataKey.name));
  const dataWithTotalCost = data.map((dataObj) => {
    const newDataObj = {};
    Object.keys(dataObj).forEach((e) => {
      if (filteredKeys.includes(e) || e === CostTrackingConstants.OTHERS) {
        newDataObj[e] = dataObj[e];
      }
    });
    const dateTotalSum = Object.values(newDataObj).reduce((accumulator, currValue) => {
      maxSingleValue = currValue ? Math.max(maxSingleValue, parseFloat(currValue)) : maxSingleValue;
      return accumulator + (currValue ? parseFloat(currValue) : 0);
    }, 0);
    newDataObj.totalSum = dateTotalSum;
    if (isSecondaryGroupBy) {
      newDataObj.groupBySecondary = dataObj.groupBySecondary;
    } else {
      newDataObj.usageDate = dataObj.usageDate;
    }
    maxValue = Math.max(dateTotalSum, maxValue);
    minValue = Math.min(dateTotalSum, minValue);
    return newDataObj;
  });
  return {
    dataWithTotalCost: isSecondaryGroupBy ? showOnlyColumnWithValuesSortedBySum(dataWithTotalCost) : dataWithTotalCost,
    maxValue,
    minValue,
    maxSingleValue,
  };
};

export const modifiedDailyBalancesToAverage = (data, filteredKeys, isShowOthers, format = true) => {
  if (isEmptyArray(data)) {
    return null;
  }
  const { dataWithTotalCost: dataFilteredByLegends } = prepareTotalSumWithOthers(data, filteredKeys, [], isShowOthers);
  const avgArr = dataFilteredByLegends.map((row) => row.totalSum);
  const val = avgArr.reduce((acc, item) => acc + item, 0) / avgArr.length;
  return !format ? val : numberWithCommas(val, 2);
};

export const splitLinkedAccountNameAndId = (data, currentGroupBy, cloudType) => {
  try {
    if (currentGroupBy !== AwsCommonFields.LINKED_ACCOUNT_ID) {
      return data;
    }
    let fieldLabel = 'linkedAccountName';
    if (cloudType === CLOUD_TYPE_IDS.GCP) {
      fieldLabel = GCPCommonFieldLabels.get(currentGroupBy) || fieldLabel;
    }
    return data.map(({ groupBy, ...rest }) => {
      const [_, name, id] = groupBy.match(/(.*)\s\((.*)\)/) || ['', '', groupBy];
      return {
        groupBy: id,
        [fieldLabel]: name,
        ...rest,
      };
    });
  } catch (e) {
    console.log(e);
    return data;
  }
};

export const splitWorkloadValueAndType = (data, currentGroupBy) => {
  try {
    if (!currentGroupBy?.startsWith(AwsCommonFields.WORKLOAD)) {
      return data;
    }
    return data.map(({ groupBy, ...rest }) => {
      const [type, ...values] = groupBy.split(':');
      return {
        groupBy: values.join(':'),
        workloadType: type,
        ...rest,
      };
    });
  } catch (e) {
    console.log(e);
    return data;
  }
};

export const getCarbonEmissionsOptions = (currDispUserCloudAccountType) => {
  if (currDispUserCloudAccountType !== CLOUD_TYPE_IDS.AWS) {
    return null;
  }
  return Object.values(CARBON_EMISSIONS_ITEMS);
};

export const getCostReadOnlyOptions = ({
  isCurrentUserSharedReCustomer,
  isFocus,
  currDispUserCloudAccountType,
  isAzureUnblended,
}) => {
  return null;
  // if you return this code please search to see how to use the new OnboardingLinks with brand
  // if (!isFocus && currDispUserCloudAccountType === CLOUD_TYPE_IDS.AZURE) {
  //   const isUnblended = isAzureUnblended;
  //   const tooltip = (
  //     <>
  //       This account contains {isUnblended ? 'unblended' : 'amortized'} cost only. If you would like to see all of the
  //       cost types in one account, migrate your export to FOCUS.
  //       <br />
  //       <a href={OnboardingLinks.AZURE.focusGuide} target="_blank">
  //         Learn More
  //       </a>
  //     </>
  //   );
  //   const items = [
  //     {
  //       ...AMORTIZE_COST_TYPES_ITEMS.AMORTIZED,
  //       selected: !isUnblended,
  //       disabled: isUnblended,
  //       tooltip: isUnblended ? tooltip : undefined,
  //     },
  //     {
  //       ...AMORTIZE_COST_TYPES_ITEMS.UNBLENDED,
  //       selected: isUnblended,
  //       disabled: !isUnblended,
  //       tooltip: !isUnblended ? tooltip : undefined,
  //     },
  //   ];
  //   if (!isCurrentUserSharedReCustomer) {
  //     items.push({
  //       ...AZURE_FOCUS_COST_TYPES_ITEMS.LIST_UNIT_PRICE,
  //       selected: false,
  //       disabled: true,
  //       tooltip,
  //     });
  //     items.push({
  //       ...AZURE_FOCUS_COST_TYPES_ITEMS.NEGOTIATED_SAVINGS,
  //       selected: false,
  //       disabled: true,
  //       tooltip,
  //     });
  //   }
  //   return items;
  // }
  // return null;
};
