import React from 'react';
import { ReactComponent as ConnectK8sImg } from 'shared/img/images/connect-k8s.svg';
import styles from './ConnectK8s.module.scss';
import Button from 'shared/components/andtComponents/Button';
import { useBrand } from 'app/contexts/Brand/BrandContext';

const ConnectK8s: React.FC<{ onOpenWizard(): void }> = ({ onOpenWizard }) => {
  const brand = useBrand();
  return (
    <div className={styles.container}>
      <ConnectK8sImg />
      <div className={styles.landingFirstLine}>
        Follow the <Button isTextButton onClick={onOpenWizard} text="step-by-step wizard" /> to
        connect your Kubernetes data to {brand.name}
      </div>
      <div className={styles.landingSecondLine}>
        Once you’ve completed the agent installation, your Kubernetes metrics will start appearing here{' '}
        <b>up to 48 hours</b>.
      </div>
      <div className={styles.landingThirdLine}>
        For any assistance, reach out to our support team at{' '}
        <a href={`mailto:${brand.supportEmail}`}>{brand.supportEmail}</a>.
      </div>
    </div>
  );
};

export default ConnectK8s;
