import React from 'react';
import ColoredTabs from 'shared/components/tabs/ColoredTabs';
import { PageNames } from 'shared/constants/appConstants';
import PageHeader from 'shared/components/PageHeader';
import { Container } from 'reactstrap';
import WeightsPage from './components/WeightsPage';

const TABS = [
  { id: 0, name: 'Instance Weights' },
];

const K8SPreferences = (props) => (
  <Container>
    <PageHeader title={PageNames.K8S_PREFERENCES} />
    <div className="d-flex">
      <ColoredTabs arrOfNavItems={TABS} vertical>
        <WeightsPage {...props} />
      </ColoredTabs>
    </div>
  </Container>
);

export default K8SPreferences;
