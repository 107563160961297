import React, { useContext } from "react";
import Instructions from "shared/components/andtComponents/Instructions";
import styles from "./K8sOnboarding.module.scss";
import CopyCommandLine from "./CopyCommandLine.tsx";
import { OnboardingContext } from "./OnBoardingContext.tsx";
import { K8Cluster } from "./types.ts";
import classNames from "classnames";

const getCommandForCluster = (c: K8Cluster) =>
  `helm upgrade --install --create-namespace -n ${c.agentNamespace} k8s-metrics-collector anodot-cost/k8s-metrics-collector -f ${c.clusterName}.yaml`;

type Props = {
  className?: string;
};

const InstallationStep: React.FC<Props> = ({ className }) => {
  const { addedClusters } = useContext(OnboardingContext);
  return (
    <div className={classNames(styles.installationStep, className)}>
      <Instructions
        stepClassName={styles.installationsContainer}
        steps={[
          <div key={"a"} className={styles.codeContainer}>
            <p>Add anodot-cost Helm repository:</p>
            <CopyCommandLine text={'helm repo add anodot-cost https://pileus-cloud.github.io/charts'} />
            <p>If it was already added before, make sure it&apos;s updated by running:</p>
            <CopyCommandLine text={'helm repo update anodot-cost'} />
          </div>,
          <div key={"b"} className={styles.codeContainer}>
            {addedClusters.map((cluster) => (
              <div className={'mb-2'} key={cluster.id}>
                <p>Anodot Prometheus-Agent installation command for cluster {cluster.clusterName}</p>
                <CopyCommandLine text={getCommandForCluster(cluster)} />
              </div>
            ))}
          </div>,
        ]}
      />
      ;
    </div>
  );
};
export default InstallationStep;
