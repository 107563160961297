import React from 'react';
import getOnboardingLinks from 'app/containers/Onboarding/utils/getOnboardingLinks';
import { getConnectToFile } from '~/app/containers/Onboarding/AwsOnboarding/utils/constants.js';

const getBucketNotFoundError = (accountId) => {
  return `The file ${getConnectToFile(
    accountId,
  )} was not found in your bucket. Please ensure it is uploaded and that the file name has not been changed.`;
};

const getBucketWrongRegionError = () =>
  `The bucket region is wrong. Please update the region to match the bucket's actual location in the 'AWS Details' step.`;

const GetAssumeRoleError = (brand) => {
  return (
    <>
      <p>Failed to assume your role. Please check the following:</p>
      <ul>
        <li>
          Ensure you entered the correct <b>Role ARN</b> in the &apos;Grant Access&apos; step of onboarding.
        </li>
        <li>
          Verify that the <b>&apos;Trust Entity&apos; in the IAM</b> Role created for {brand.name} includes the contents
          of the <b>PileusRole.json</b> file downloaded in the &apos;Grant Access&apos; step.
        </li>
      </ul>
    </>
  );
};

const getBucketNoAccessError = () => {
  return `We cannot access your bucket. Please ensure the IAM Role and Policy were created correctly.`;
};

export const getOnboardingError = ({ code, accountId, brand }) => {
  return {
    // app-server errors
    BUCKET_FILE_WRONG_REGION: getBucketWrongRegionError(),
    BUCKET_FILE_NOT_FOUND: getBucketNotFoundError(accountId),
    BUCKET_NO_ACCESS: getBucketNoAccessError(),
    // airflow errors (key should match airflow error)
    ERR_HANDSHAKE_ASSUME_ROLE: GetAssumeRoleError(brand),
    ERR_HANDSHAKE_DOWNLOAD_FILE:
      'We are unable to download files from your bucket due to missing permissions.\n' +
      'Please ensure the IAM Policy you created in the AWS console includes the required permissions.' +
      " The policy should be created using the PileusPolicy.json file downloaded in the 'Grant Access' step of onboarding.",
    ERR_HANDSHAKE_VARIABLE_NOT_FOUND: 'Internal server error, please try again or contact support',
    ERR_HANDSHAKE_UPLOAD_FILE:
      'We are unable to copy files from your bucket due to missing permissions.\n' +
      'Please ensure the IAM Policy you created in the AWS console includes the required permissions. ' +
      "The policy should be created using the PileusPolicy.json file downloaded in the 'Grant Access' step of onboarding.",
    ERR_HANDSHAKE_LAMBDA_NOT_STARTED: (
      <>
        <p>We did not receive SNS. Please verify the following on the AWS side:</p>
        <ul>
          <li>In the event notification destination, the region should match the bucket region.</li>
          <li>
            Verify that you created the event notification as described{' '}
            <a href={getOnboardingLinks(brand).AWS.onboardingTypeEventNotification}>here</a>.
          </li>
        </ul>
      </>
    ),
    ERR_HANDSHAKE_FLAG_NOT_RECEIVED: (
      <>
        Please ensure all required information is provided, then start the validation by clicking the <b>Validate</b>{' '}
        button in the <b>Validate Access</b> step.
      </>
    ),
    ERR_HANDSHAKE_ACCOUNT_ONBOARDED: 'This account was already onboarded',
    ERR_HANDSHAKE_HANDLE_LINKED_ACCOUNTS:
      'Internal server error, please try again or contact our support team for assistance at support@anodot.com',
    ERR_HANDSHAKE_DB_WRITE:
      'Internal server error, please try again or contact our support team for assistance at support@anodot.com',
    ERR_HANDSHAKE_AWS_LINKED_ACCOUNTS_NOT_AVAILABLE:
      'Due to missing permission, we were unable to get your linked accounts list. ' +
      'Please verify that you created the IAM Policy using the file PileusPolicy.json permissions, which you' +
      ' downloaded in step #3 (Grant Access) of the onboarding.',
    ERR_INVOICE_RESOURCE_IDS_MISSING: (
      <>
        <p>
          Processing is paused because the CUR created in the AWS console is missing the option to “Include Resource
          IDs.”
        </p>
        <br />
        <p>To fix it, go to:</p>
        <p>
          <span>AWS console &gt; Account &gt; Cost & Usage Report and select “Include Resource IDs.”</span>
          Processing will automatically resume once you make this fix.
        </p>
      </>
    ),
    ERR_INVOICE_COST_TAGS_MISSING: (
      <>
        <p>
          Cost allocation tags are not activated on your AWS Console. This results in missing information when
          visualizing costs.
        </p>
        <br />
        <p>To fix it, go to:</p>
        <p>
          <span>AWS console &gt; Account &gt; Cost allocation tags. </span>
          Your fix will be reflected on the next processing cycle.
        </p>
      </>
    ),
    ERR_INVOICE_HOUR_GRANULARITY_MISSING: (
      <>
        <p>
          The CUR created in the AWS console is not set to “hour granularity.” This will prevent proper calculation of
          some metrics.
        </p>
        <br />
        <p>To fix it, go to:</p>
        <p>
          <span>AWS console &gt; Account &gt; Cost & usage reports.</span>
          Your fix will be reflected on the next processing cycle.
        </p>
      </>
    ),
    ERR_INVOICE_ACCOUNT_IS_NOT_PAYER: (
      <>
        <p>
          The payer account ID entered at the first step of onboarding does not match the payer account ID from the
          export file (it might have happened if you had entered the linked account ID instead of the payer account ID).
          You should delete this onboarding and recreate it using the correct payer account ID.
        </p>
      </>
    ),
  }[code];
};
