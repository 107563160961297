/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-typos */
/* eslint-disable padded-blocks */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/no-did-mount-set-state */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { StringToColor } from 'shared/utils/graphicUtil';
import { withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';
import { observer } from 'mobx-react';

class CostChart extends PureComponent {
  static propTypes = {
    data: PropTypes.object.isRequired,
    dataKeys: PropTypes.object.isRequired,
    xAxis: PropTypes.string.isRequired,
    yAxisRight: PropTypes.string.isRequired,
    yAxisLeft: PropTypes.string.isRequired,
    lineColor: PropTypes.string,
    barSize: PropTypes.number,
    barName: PropTypes.string.isRequired,
    lineName: PropTypes.string.isRequired,
    yLeftCost: PropTypes.bool,
    yRightCost: PropTypes.bool,
    height: PropTypes.number.isRequired,
    getCurrencyNumber: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      currKey: '',
    };
  }

  toolTipFormatter = (value, name, yRightCost, yLeftCost, yAxisLeft, yAxisRight, barName, lineName, dataKeys) => {
    const { getCurrencyNumber } = this.props;
    if (yRightCost && yLeftCost) {
      return getCurrencyNumber(value, 0, { roundNumber: true });
    }
    if (!yRightCost && yLeftCost) {
      if (name === yAxisLeft || name === barName) {
        return getCurrencyNumber(value, 0, { roundNumber: true });
      }
      return value;
    }
    if (yRightCost && !yLeftCost) {
      if (name === yAxisRight || name === lineName) {
        return getCurrencyNumber(value, 0, { roundNumber: true });
      }
      return value;
    }
    return value;
  };

  renderBar(dataKeys) {
    const { currKey } = this.state;
    // eslint-disable-next-line arrow-body-style
    return dataKeys.map((dataKey, index) => {
      const customMouseOver = (e) => {
        if (dataKey.projectId !== currKey) {
          this.setState({ currKey: dataKey.projectId });
        }
      };
      return (
        <Bar
          barSize={28}
          dataKey={dataKey.projectId}
          stackId="a"
          fill={StringToColor.next(dataKey.projectId)}
          onMouseOver={customMouseOver}
          onFocus={customMouseOver}
          yAxisId="left"
        />
      );
    });
  }

  render() {
    const {
      height,
      data,
      barSize = 20,
      xAxis,
      yAxisLeft,
      yAxisRight,
      yLeftCost,
      yRightCost,
      barName,
      lineName,
      lineColor = '#82ca9d',
      dataKeys,
      getCurrencyNumber,
    } = this.props;
    return (
      <ResponsiveContainer width="100%" height={height || 300}>
        <ComposedChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={xAxis} />
          <YAxis
            yAxisId="left"
            type="number"
            tickFormatter={(value) => (yLeftCost ? getCurrencyNumber(value, 0, { roundNumber: true }) : value)}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={(value) => (yRightCost ? getCurrencyNumber(value, 0, { roundNumber: true }) : value)}
          />
          <Tooltip
            formatter={(value, name) =>
              this.toolTipFormatter(
                value,
                name,
                yRightCost,
                yLeftCost,
                yAxisLeft,
                yAxisRight,
                barName,
                lineName,
                dataKeys,
              )
            }
          />
          <Legend />
          {this.renderBar(dataKeys)}
          <Line yAxisId="right" type="monotone" dataKey={yAxisRight} stroke={lineColor} name={lineName || yAxisRight} />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }
}

const ObserverCostChart = withUserSettingsConsumer(CostChart);
export default ObserverCostChart;
