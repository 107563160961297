import React, { useState, useMemo, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Grid, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';
import { SortingState, IntegratedSorting, DataTypeProvider } from '@devexpress/dx-react-grid';
import moment from 'moment';
import { groupByOptions, PANELS, PANELS_TYPES } from 'app/containers/MainDashboard/consts.js';
import { useMainDashboardContext } from 'app/containers/MainDashboard/contexts/mainDashboardContext.jsx';
import {
  aggregateCostsByGroup,
  compareFormatterNumber,
  getFirstDayOfMonth,
} from 'app/containers/MainDashboard/utils.js';
import PercentTag from 'app/containers/MainDashboard/components/PercentTag.jsx';
import Spinner, { SPINNER_SIZES } from 'shared/components/andtComponents/Spinner.jsx';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { useUserSettingsContext } from 'users/utils/contexts/UserSettingsContext.jsx';
import InfoPopover from 'shared/components/andtComponents/InfoPopover/index.jsx';
import { useRootStore } from 'app/contexts/RootStoreContext.jsx';
import {
  DisplayMetricTypes,
  GroupByLovToAttributes,
  GROUP_BY_LOV,
  AWS_GROUP_BY_LOV,
} from 'usage/constants/costAndUsageConstants.js';
import { CLOUD_TYPE_IDS } from 'users/constants/usersConstants.js';
import { GCPCommonFieldLabels } from 'shared/constants/gcpConstants.js';
import BarsChart from 'shared/components/dashboardCharts/BarsChart/BarsChart.jsx';
import { palette } from 'shared/constants/colorsConstants.js';
import { MtdTop10ChartTooltip } from 'app/containers/MainDashboard/panels/MtdTop10/MtdTop10ChartTooltip.jsx';
import useCueData from 'app/containers/MainDashboard/hooks/react-query/useCueData.js';
import useTable from 'shared/hooks/customHooks/useTable.jsx';
import DateFilter from 'shared/modules/dateFilter.js';
import LabelCoordinator from 'shared/modules/labelCoordinator.js';
import tooltipStyles from 'shared/components/andtComponents/Tooltip.module.scss';
import { isNumber } from 'lodash';
import HeaderRow from 'app/containers/MainDashboard/panels/MtdTop10/HeaderRow.tsx';
import mainStyles from '../../mainDashboardCommon.module.scss';
import styles from './mtdTop10.module.scss';

const modeSwitch = {
  daily: { id: 'daily', text: 'Daily' },
  mtd: { id: 'monthly', text: 'MTD' },
};

const getColumns = (
  getCurrencyNumber,
  currencySymbol,
  groupByHeader,
  isDailyMode,
  previousDates,
  currentDates,
  displayCostChange,
) => {
  return [
    { name: 'groupBy', title: groupByHeader },
    {
      name: 'previousMonthCost',
      title: `Previous ${isDailyMode ? 'Day' : 'MTD'} Costs`,
      getCellValue: (row) =>
        isNumber(row.previousMonthCost) ? `${getCurrencyNumber(row.previousMonthCost)}` : row.previousMonthCost,
      subTitle: previousDates,
    },
    {
      name: 'mtdCost',
      title: `${isDailyMode ? modeSwitch.daily.text : modeSwitch.mtd.text} Costs`,
      getCellValue: (row) => `${getCurrencyNumber(row.mtdCost)}`,
      subTitle: currentDates,
    },
    {
      name: 'change',
      title: displayCostChange ? `Change (${currencySymbol})` : 'Change (%)',
      getCellValue: (row) => {
        if (displayCostChange) {
          return getCurrencyNumber(+row.changeValue);
        }
        if (!row.previousMonthCost && row.mtdCost) {
          return row.mtdCost > 0 ? -100 : 100;
        }
        if (row.previousMonthCost === '-') {
          return 'N/A';
        }
        return row.previousMonthCost ? ((row.previousMonthCost - row.mtdCost) * 100) / row.previousMonthCost : 0;
      },
    },
  ];
};

const columnExtensions = [
  { columnName: 'groupBy', width: '220px' },
  { columnName: 'previousMonthCost', compare: compareFormatterNumber, width: '150px' },
  { columnName: 'mtdCost', compare: compareFormatterNumber, width: '150px' },
  {
    columnName: 'change',
    width: '100px',
    compare: (a, b) => {
      if (isNumber(a)) {
        return b - a;
      }
      const getNumericValue = (value) => (value ? parseFloat(value.replace(/[^0-9.-]+/g, '')) : 0);
      return getNumericValue(a) - getNumericValue(b);
    },
  },
];

const HeaderCell = ({ column, ...restProps }) => {
  const { children } = restProps;
  return (
    <TableHeaderRow.Cell column={column} className={styles.stickyHeader} {...restProps}>
      <div className={styles.columnHeaderWrapper}>
        <span className={styles.columnHeader}>
          {children}
          {column.info && (
            <InfoPopover
              isSimple
              mode="outline"
              className={{
                icon: styles.infoIcon,
                tooltip: tooltipStyles.whiteTooltip,
                arrow: tooltipStyles.whiteArrow,
              }}
            >
              <span className={tooltipStyles.tooltipText}>{column.info}</span>
            </InfoPopover>
          )}
        </span>
        {column.subTitle && <span className={styles.subTitle}>{column.subTitle}</span>}
      </div>
    </TableHeaderRow.Cell>
  );
};

const getGroupOptions = (usersStore) => {
  const {
    isCurrentAccountAllAccounts: isAllAccounts,
    currDispUserCloudAccountType: currentCloudType,
    isCurrentUserReCustomer: isReseller,
    rootStore,
  } = usersStore;
  const groups = [...groupByOptions[currentCloudType]];
  if (isAllAccounts && !groups.includes(GROUP_BY_LOV.PAYER_ACCOUNT)) {
    groups.push(GROUP_BY_LOV.PAYER_ACCOUNT);
  } else if (!isAllAccounts && groups.includes(GROUP_BY_LOV.PAYER_ACCOUNT)) {
    groups.splice(groups.indexOf(GROUP_BY_LOV.PAYER_ACCOUNT), 1);
  }
  if (isReseller && !rootStore.appStore.isKeyCloakManagement) {
    const divisionIndex = groups.indexOf(AWS_GROUP_BY_LOV.BY_DIVISION);
    if (divisionIndex !== -1) {
      groups.splice(divisionIndex, 1);
    }
  }
  return groups.map((group) => ({
    value: GroupByLovToAttributes.get(group),
    label:
      currentCloudType === CLOUD_TYPE_IDS.GCP && GCPCommonFieldLabels.get(group)
        ? GCPCommonFieldLabels.get(group)
        : LabelCoordinator.getFieldLabel(group),
  }));
};

const sortOptions = [
  { value: 'cost', label: 'Highest Cost' },
  { value: 'percentChange', label: '% of Change' },
  { value: 'costChange', label: 'Cost of Change ' },
];

const BarsGradient = (
  <defs>
    <linearGradient id="prevBarGradient" x1="0" y1="0" x2="0" y2="1">
      <stop offset="0%" stopColor="#51BCFF" stopOpacity={0.4} />
      <stop offset="95.5%" stopColor={palette.blue[450]} stopOpacity={0.4} />
    </linearGradient>
    <linearGradient id="currentBbarGradient" x1="0" y1="0" x2="0" y2="1" stopOpacity={0.4}>
      <stop offset="0%" stopColor="#51BCFF" />
      <stop offset="95.5%" stopColor={palette.blue[450]} />
    </linearGradient>
  </defs>
);

const dataKeys = ['previousMonthCost', 'mtdCost'];

const dataProperties = {
  previousMonthCost: { fill: 'url(#prevBarGradient)', stackId: 'a', displayValue: 'Previous MTD' },
  mtdCost: { fill: 'url(#currentBbarGradient)', stackId: 'b', displayValue: 'MTD' },
};

const ChangeColumnFormatter = ({ value, isPercent }) => {
  if (isPercent) return <PercentTag percent={value === 'N/A' ? value : value * -1} fullWidth />;
  return <span className={styles.tagText}>{value}</span>;
};

const ChangeFormatterComponent = (props, orderBy) => {
  return <ChangeColumnFormatter {...props} isPercent={orderBy[0].value !== 'costChange'} />;
};

const MtdTop10 = ({
  isFullWidth = false,
  isDailyMode = false,
  hideSaveDashboard = false,
  existingGroupBy,
  existingOrderBy,
  includeChart = false,
}) => {
  const { numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
  const { updatePanelLoadingState, getDynamicFilters } = useMainDashboardContext();
  const { NewTableWrapper } = useTable();
  const [selectedMode, setSelectedMode] = useState(isDailyMode ? modeSwitch.daily.id : modeSwitch.mtd.id);
  const cueHook = useCueData();
  const { appStore, usersStore } = useRootStore();
  const { getCurrencyNumber, currencySymbol } = useUserSettingsContext();
  const options = getGroupOptions(usersStore);

  const [groupBy, setGroupBy] = useState(existingGroupBy || [{ value: 'service', label: 'Service' }]);
  const [orderBy, setOrderBy] = useState(existingOrderBy || [{ value: 'percentChange', label: '% of Change' }]);

  const chartContainerRef = useRef(null);

  const lastFullDay = moment(DateFilter.getDate()).subtract(1, 'days');
  const lastDayInPrevMonth = moment(lastFullDay).subtract(1, 'months');

  const mtdDatesParams = {
    start: selectedMode === modeSwitch.mtd.id ? getFirstDayOfMonth(lastFullDay) : lastFullDay.format('YYYY-MM-DD'),
    end: lastFullDay.format('YYYY-MM-DD'),
  };
  const previousDatesParams = {
    start:
      selectedMode === modeSwitch.mtd.id
        ? getFirstDayOfMonth(lastDayInPrevMonth)
        : moment(DateFilter.getDate()).subtract(2, 'days').format('YYYY-MM-DD'),
    end:
      selectedMode === modeSwitch.mtd.id
        ? lastDayInPrevMonth.format('YYYY-MM-DD')
        : moment(DateFilter.getDate()).subtract(2, 'days').format('YYYY-MM-DD'),
  };

  const { data: mtdData, isLoading: isMtdLoading } = cueHook.fetchCueData({
    ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
    start: mtdDatesParams.start,
    end: mtdDatesParams.end,
    groupBy: groupBy?.[0]?.value,
    isPpApplied: appStore.isPpApplied,
    periodGranLevel: selectedMode === modeSwitch.daily.id ? 'day' : 'month',
    ...getDynamicFilters(),
  });

  const { data: previousMtdData, isLoading: isPreviousMtdLoading } = cueHook.fetchCueData({
    ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
    start: previousDatesParams.start,
    end: previousDatesParams.end,
    groupBy: groupBy?.[0]?.value,
    isPpApplied: appStore.isPpApplied,
    periodGranLevel: selectedMode === modeSwitch.daily.id ? 'day' : 'month',
    ...getDynamicFilters(),
  });

  const navigateCueParams = {
    ...PANELS[PANELS_TYPES.MTD_TOP_10].params,
    groupBy: groupBy?.[0]?.value,
    periodGranLevel: 'day',
    start: previousDatesParams.start,
    end: mtdDatesParams.end,
    ...getDynamicFilters(),
  };
  const isLoading = isPreviousMtdLoading || isMtdLoading;
  const [sorting, setSorting] = useState([{ columnName: 'mtdCost', direction: 'desc' }]);

  const totalCostData = useMemo(() => {
    if (!mtdData || !previousMtdData) {
      return null;
    }

    const mtdTotal = aggregateCostsByGroup(mtdData, groupBy?.[0]?.value === 'linkedaccid');
    const previousTotal = aggregateCostsByGroup(previousMtdData, groupBy?.[0]?.value === 'linkedaccid');

    return { mtdTotalData: mtdTotal, previousTotalData: previousTotal };
  }, [previousMtdData, mtdData, groupBy]);

  const panelData = useMemo(() => {
    if (!totalCostData) {
      return [];
    }
    let sortedMtdTotalData = [];
    if (totalCostData.mtdTotalData.size === 0) {
      const data = totalCostData.previousTotalData.entries();
      sortedMtdTotalData = [...data]
        .map(([groupBy, { totalCost, linkedAccountName }]) => ({
          groupBy: linkedAccountName || groupBy,
          previousMonthCost: totalCost || 0,
          mtdCost: 0,
          changeValue: -totalCost || 0,
          changePercent: -100,
        }))
        .filter((item) => +item.previousMonthCost.toFixed(2) !== 0);
    } else {
      sortedMtdTotalData = [...totalCostData.mtdTotalData.entries()]
        .map(([groupBy, { totalCost, linkedAccountName }]) => {
          const previousMonthCost = totalCostData.previousTotalData.get(groupBy) || 0;
          const prevMonthCost = previousMonthCost.totalCost ? parseFloat(previousMonthCost.totalCost.toFixed(2)) : 0;
          const currentMonthCost = totalCost ? parseFloat(totalCost.toFixed(2)) : 0;
          const changeFromPrevious = currentMonthCost - prevMonthCost;
          const fullPercent = currentMonthCost < 0 ? -100 : 100;
          return {
            groupBy: linkedAccountName || groupBy,
            previousMonthCost: totalCostData.previousTotalData.size > 0 ? prevMonthCost : '-',
            changeValue: changeFromPrevious,
            changePercent: prevMonthCost ? (changeFromPrevious * 100) / prevMonthCost : fullPercent,
            mtdCost: currentMonthCost,
          };
        })
        .filter((item) => item.mtdCost !== 0 || (item.previousMonthCost !== '-' && item.previousMonthCost !== 0));
    }
    if (orderBy[0].value === 'cost') {
      setSorting([{ columnName: 'mtdCost', direction: 'desc' }]);
      return sortedMtdTotalData.toSorted((a, b) => b.mtdCost - a.mtdCost).slice(0, 10);
    }
    setSorting([{ columnName: 'change', direction: 'desc' }]);
    if (orderBy[0].value === 'costChange') {
      return sortedMtdTotalData.toSorted((a, b) => b.changeValue - a.changeValue).slice(0, 10);
    } else {
      return sortedMtdTotalData.toSorted((a, b) => b.changePercent - a.changePercent).slice(0, 10);
    }
  }, [totalCostData, orderBy]);

  const getRangeDates = (dateParams) => {
    if (selectedMode === modeSwitch.mtd.id) {
      const formattedStartDate = moment(dateParams.start).format('MMM D');
      const formattedEndDate = moment(dateParams.end).format('D, YYYY');
      return `${formattedStartDate}-${formattedEndDate}`;
    }
    return moment(dateParams.start).format('MMM D, YYYY');
  };
  useEffect(() => {
    updatePanelLoadingState(PANELS_TYPES.MTD_TOP_10, isLoading);
  }, [isLoading]);

  return (
    <div
      className={classNames(
        mainStyles.panelWrapper,
        !isFullWidth && !includeChart ? mainStyles.halfWidthPanelWrapper : mainStyles.fullWidth,
      )}
    >
      <HeaderRow
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        options={options}
        sortOptions={sortOptions}
        selectedMode={selectedMode}
        setSelectedMode={setSelectedMode}
        hideSaveDashboard={hideSaveDashboard}
        navigateCueParams={navigateCueParams}
      />
      {isLoading ? (
        <Spinner className={mainStyles.spinner} size={SPINNER_SIZES.MEDIUM} />
      ) : (
        <div className={styles.flex}>
          <div className={classNames(includeChart && mainStyles.halfWidthPanelWrapper)}>
            <NewTableWrapper>
              <div className="sub-table">
                <Grid
                  rows={panelData}
                  columns={getColumns(
                    getCurrencyNumber,
                    currencySymbol,
                    groupBy?.[0]?.label,
                    selectedMode === modeSwitch.daily.id,
                    getRangeDates(previousDatesParams),
                    getRangeDates(mtdDatesParams),
                    orderBy[0].value === 'costChange',
                  )}
                >
                  <SortingState sorting={sorting} onSortingChange={setSorting} />
                  <IntegratedSorting columnExtensions={columnExtensions} />
                  <DataTypeProvider
                    for={['change']}
                    formatterComponent={(props) => ChangeFormatterComponent(props, orderBy)}
                  />
                  <TableWrapper virtual height="280px" columnExtensions={columnExtensions} />
                  <TableHeaderRow showSortingControls cellComponent={HeaderCell} />
                </Grid>
              </div>
            </NewTableWrapper>
          </div>
          {includeChart && (
            <div ref={chartContainerRef} className={styles.chartWrapper}>
              <BarsChart
                data={panelData}
                height={300}
                dataKeys={dataKeys}
                specialGradient={BarsGradient}
                xKey="groupBy"
                isStack={false}
                barWidth={isFullWidth ? 80 : 60}
                containerWidth={chartContainerRef?.current?.offsetWidth || window.innerWidth}
                dataProperties={dataProperties}
                tooltipComponent={<MtdTop10ChartTooltip tooltipData={dataProperties} />}
                axisSettings={{
                  yTickFormatter: (value) => numStrAbriviaionByDisplayMetric(value, value, DisplayMetricTypes.COST),
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MtdTop10;
