import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Paper from '@mui/material/Paper';
import { cloneDeep } from 'lodash';
import {
  IntegratedFiltering,
  IntegratedSorting,
  DataTypeProvider,
  FilteringState,
  SortingState,
  IntegratedSummary,
  SummaryState,
} from '@devexpress/dx-react-grid';
import { createDateDisplayStr, parseDateFormatFromDate } from 'shared/utils/dateUtil';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  TableFixedColumns,
  TableColumnResizing,
  TableSummaryRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  makeTableDataCumulative,
  addTrendRowToTableData,
  calculateTrendRows,
  prepareDataWithTrendsForExport,
} from 'shared/utils/dataPrepareUtil';
import Spinner from 'shared/components/andtComponents/Spinner';
import TableWrapper from 'shared/components/tables/TableWrapper';
import { getFormatterComponent, getNumToSizeFormatterComponent } from 'shared/utils/formatUtils';
import { trendLinesRowsSort } from 'shared/utils/sortUtil';
import { AwsQuantityTypes, AWS_QUANTITY_TYPE_SELECT } from 'shared/constants/awsConstants';
import CustomCSVDownload from 'shared/components/buttons/CustomCSVDownload';
import { useUserSettingsContext, withUserSettingsConsumer } from 'users/utils/contexts/UserSettingsContext';

function formatColumnTitleDate(column) {
  const format = parseDateFormatFromDate(column);
  if (format) {
    return {
      name: column,
      title: createDateDisplayStr(column, format),
    };
  }
  return { name: column, title: column };
}

function prepareTableHeaders(data, newWidths, currGroupBy) {
  const dataKeys = data.map((row) => Object.keys(row));
  const result = [...new Set([].concat(...dataKeys))];

  const columns = result.map(formatColumnTitleDate).sort((a, b) => a.name.localeCompare(b.name));

  const totalSummaryItems = result.map((elem) => {
    if (elem === 'groupBy' || elem === 'linkedAccountId') {
      return { columnName: elem, type: 'count' };
    }
    return { columnName: elem, type: 'sum' };
  });

  const columnWidths =
    newWidths && newWidths.length > 0 && newWidths.length === columns.length
      ? newWidths
      : result.map((elem) => ({
          columnName: elem,
          width: result.length > 5 ? 150 : 300,
        }));

  const tableColumnExtensions = result.map((elem) => ({
    columnName: elem,
    align: 'center',
  }));
  const indexOfGroupBy = columns.findIndex((col) => col.name === 'groupBy');
  if (indexOfGroupBy > -1) {
    const groupBy = columns.splice(indexOfGroupBy, 1)[0];
    groupBy.title = currGroupBy;
    columns.unshift(groupBy);
  }
  return { columns, columnWidths, tableColumnExtensions, totalSummaryItems };
}

const FilterIcon = ({ type, ...restProps }) => <TableFilterRow.Icon type={type} {...restProps} />;

const getCellColor = (value, currencySymbol) => {
  try {
    const valueAsNumber = value
      .split('(')[0]
      .replace(currencySymbol, '')
      .replace('K', '')
      .replace('M', '')
      .replace(' ', '')
      .split('\n')[0];
    let color = 'white';
    if (Number.isNaN(valueAsNumber)) {
      color = 'white';
    } else if (valueAsNumber > 0) {
      color = '#d99694';
    } else if (valueAsNumber <= 0) {
      color = '#c3d69b';
    }
    return color;
  } catch (error) {
    return 'white';
  }
};

const TrendCell = ({ value, style, selectedUsageType, ...restProps }) => {
  const { currencySymbol, numStrAbriviaionByDisplayMetric } = useUserSettingsContext();
  function getTrendDisplayValue(trendValue, numStrAbriviaionByDisplayMetric) {
    if (typeof trendValue === 'string') {
      return trendValue;
    }
    const { percent = '-' } = trendValue || {};
    return `${numStrAbriviaionByDisplayMetric(
      value.cost,
      value.cost,
      selectedUsageType === AWS_QUANTITY_TYPE_SELECT ? 'Cost' : selectedUsageType,
      null,
    )}
            ${typeof value.cost === 'string' ? value.cost.split(' ')[1] : ''}
             ${typeof percent === 'number' ? `(${percent.toFixed(2)}%)` : percent}`;
  }
  const displayVaue = getTrendDisplayValue(value, numStrAbriviaionByDisplayMetric);
  return (
    <Table.Cell
      {...restProps}
      style={{
        backgroundColor: getCellColor(displayVaue, currencySymbol),
        ...style,
      }}
    >
      <span>{displayVaue?.split('\n')[0] === '0 ' ? displayVaue.replace('Bytes', ' ') : displayVaue}</span>
    </Table.Cell>
  );
};
TrendCell.propTypes = {
  value: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  selectedUsageType: PropTypes.string.isRequired,
};

const Cell = (props) => {
  const { row } = props;
  try {
    if (row.groupBy && row.groupBy.includes('Trend')) {
      return <TrendCell {...props} />;
    }
    return <Table.Cell {...props} />;
  } catch (err) {
    return <Table.Cell {...props} />;
  }
};

class FilterTable extends Component {
  messages = {
    sum: ' ',
  };
  static propTypes = {
    data: PropTypes.object.isRequired,
    displayedMetric: PropTypes.object.isRequired,
    panelName: PropTypes.string.isRequired,
    isCumulative: PropTypes.bool,
    isTableTrendRow: PropTypes.bool,
    isTableOnlyTrendRow: PropTypes.bool,
    isShowSummaryRow: PropTypes.bool,
    selectedUsageType: PropTypes.string,
    isUsageChecked: PropTypes.bool,
    getCurrencyNumber: PropTypes.func.isRequired,
    numStrAbriviaionByDisplayMetric: PropTypes.func.isRequired,
    overrideCurrency: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      columnWidths: [],
      tableColumnExtensions: [],
      numericFilterOperations: [
        'equal',
        'notEqual',
        'greaterThan',
        'greaterThanOrEqual',
        'lessThan',
        'lessThanOrEqual',
      ],
      leftColumns: ['date'],
      totalSummaryItems: [],
    };
  }

  componentDidMount = () => {
    const { data } = this.props;
    const { columns, columnWidths, tableColumnExtensions, totalSummaryItems } = prepareTableHeaders(data);
    this.setState({
      columns,
      columnWidths,
      totalSummaryItems,
      tableColumnExtensions,
    });
  };

  changeColumnWidths = (columnWidths) => {
    this.setState({ columnWidths });
  };

  render() {
    const {
      data,
      panelName,
      isTableTrendRow,
      isTableOnlyTrendRow,
      isCumulative,
      isShowSummaryRow = true,
      displayedMetric,
      selectedUsageType = 'Usage',
      isUsageChecked,
      getCurrencyNumber,
      overrideCurrency,
    } = this.props;
    let copyData = cloneDeep(data);
    const { columns, columnWidths, tableColumnExtensions, leftColumns, numericFilterOperations, totalSummaryItems } =
      this.state;
    const isTableSummaryRow = isShowSummaryRow && !isTableTrendRow;
    if (!columns.length || !columnWidths.length) {
      return <Spinner />;
    }
    const sorting = columns.map((col) => {
      const extension = {
        columnName: col.name,
      };
      if (col.name !== 'groupBy') {
        extension.compare = trendLinesRowsSort;
      }
      return extension;
    });
    const arrOfColumnNames = columns
      .map((col) => col.name)
      .filter((name) => name !== 'groupBy' && name !== 'linkedAccountId');
    // order is important here - it should calculate summs before adding trend rows
    if (isCumulative) {
      makeTableDataCumulative(copyData);
    }
    if (isTableOnlyTrendRow) {
      copyData = calculateTrendRows(copyData, selectedUsageType);
    } else if (isTableTrendRow) {
      addTrendRowToTableData(copyData, selectedUsageType);
    }

    let dataForExport = copyData;
    if (isTableOnlyTrendRow || isTableTrendRow) {
      dataForExport = prepareDataWithTrendsForExport(copyData);
    }
    return (
      <>
        <CustomCSVDownload
          filesNumber={1}
          isLoading={false}
          data={[{ data: dataForExport, filename: `${panelName}.csv` }]}
        />
        <Paper>
          <Grid rows={copyData} columns={columns}>
            <FilteringState defaultFilters={[{}]} />
            <IntegratedFiltering />
            <SortingState
              defaultSorting={[
                { columnName: 'totalCost', direction: 'desc' },
                { columnName: 'totalUsage', direction: 'desc' },
              ]}
            />
            <IntegratedSorting columnExtensions={sorting} />
            {isTableSummaryRow ? <SummaryState totalItems={totalSummaryItems} /> : null}
            {isTableSummaryRow ? <IntegratedSummary /> : null}
            <DataTypeProvider
              for={arrOfColumnNames}
              formatterComponent={
                selectedUsageType === AwsQuantityTypes.BYTES
                  ? getNumToSizeFormatterComponent
                  : getFormatterComponent(
                      isUsageChecked,
                      selectedUsageType,
                      displayedMetric,
                      getCurrencyNumber,
                      overrideCurrency,
                    )
              }
              availableFilterOperations={numericFilterOperations}
            />
            <TableWrapper cellComponent={Cell} columnExtensions={tableColumnExtensions} virtual height={600} />
            <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={this.changeColumnWidths} />
            {isTableSummaryRow ? <TableSummaryRow messages={this.messages} /> : null}
            <TableHeaderRow showSortingControls style={{ padding: 0 }} />
            <TableFilterRow showFilterSelector iconComponent={FilterIcon} />
            <TableFixedColumns leftColumns={leftColumns} />
          </Grid>
        </Paper>
      </>
    );
  }
}

const ObserverFilterTable = withUserSettingsConsumer(FilterTable);
export default ObserverFilterTable;
