/* eslint-disable no-await-in-loop */ // We need to wait for each step to finish before moving on to the next
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ProgressLoader from 'shared/components/andtComponents/ProgressLoader';
import loaderStep1 from '../assets/gpt-loader-step1.gif';
import loaderStep2 from '../assets/gpt-loader-step2.gif';
import loaderStep3 from '../assets/gpt-loader-step3.gif';
import loaderStep4 from '../assets/gpt-loader-step4.gif';

const STEPS_DURATIONS = [4000, 10000, 32000, 1000];

export const GptLoader = ({ answerReceived, setIsLoading }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const timeoutIds = useRef([]);

  const nextStep = (currStepIdx, duration) =>
    new Promise((resolve) =>
      timeoutIds.current.push(
        setTimeout(() => {
          if (currStepIdx === STEPS_DURATIONS.length - 1) {
            return setIsLoading(false);
          }
          setCurrentStep(currStepIdx + 1);
          return resolve();
        }, duration),
      ),
    );

  useEffect(() => {
    setIsLoading(true);
    // Create random durations according to the median durations +- 25%
    const randomDurations = STEPS_DURATIONS.map((medianDuration) => (Math.random() / 2 + 0.75) * medianDuration);
    (async () => {
      for (let i = 0; i < randomDurations.length; i++) {
        await nextStep(i, randomDurations[i]);
      }
    })();

    return () => timeoutIds.current.forEach((id) => clearTimeout(id));
  }, [timeoutIds]);

  useEffect(() => {
    if (answerReceived) {
      // Clear all timeouts and initiate realistic completion of all steps
      timeoutIds.current.forEach((id) => clearTimeout(id));
      (async () => {
        for (let i = currentStep; i < STEPS_DURATIONS.length; i++) {
          const duration = Math.random() * 1300 + 1000; // Short duration of 1000-2300ms
          await nextStep(i, duration);
        }
      })();
    }
  }, [answerReceived, nextStep. setIsLoading]);
  return (
    <ProgressLoader
      steps={[
        {
          description: 'We have received your question',
          image: <img src={loaderStep1} alt="loader-step-1" />,
        },
        {
          description: 'Converting the question to our data corpus terms',
          image: <img src={loaderStep2} alt="loader-step-2" />,
        },
        {
          description: 'Retrieving data based on your question',
          image: <img src={loaderStep3} alt="loader-step-3" />,
        },
        {
          description: 'Preparing the response for viewing',
          image: <img src={loaderStep4} alt="loader-step-4" />,
        },
      ]}
      currentStep={currentStep}
    />
  );
};

GptLoader.propTypes = {
  answerReceived: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};
